/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';

@Component({
	selector: 'app-claims',
	templateUrl: './claims.component.html'
})

/**
 * A component representing an instance of the main claims page.
 *
 * @export
 * @class ClaimsComponent
 */
export class ClaimsComponent
{
}