<div class="drawer ui-g-12 no-padding">

	<div class="drawer-header ui-g-12">
		<div class="ui-g-2 drawer-icon-container no-padding">
			<a class="drawer-icon">
				<i [class]="drawerMenuItem.icon">
				</i>
			</a>
		</div>
		<div class="ui-g-8 drawer-title no-padding">
			<span class="ellipsis-text">
				{{drawerMenuItem.label}}
			</span>
		</div>
		<div class="ui-g-2 drawer-close-icon-container no-padding">
			<a class="drawer-close float-right cursor-pointer"
				(click)="closeDrawer()">
				<i class="drawer-close-icon pi pi-times">
				</i>
			</a>
			<a class="drawer-pin float-right cursor-pointer"
				[ngClass]="{
					'drawer-pinned-icon-container': drawerMenuItem.pinned === true
				}"
				*ngIf="drawerMenuItem.pinnable === true"
				(click)="pinDrawer()">
				<i class="drawer-pin-icon fa fa-thumb-tack">
				</i>
			</a>
		</div>
	</div>

	<div>
		<div class="drawer-content ui-g-12"
			*ngIf="drawerMenuItem.dynamicComponent != null"
			[ngClass]="{
				'pinned-drawer': drawerMenuItem.pinned === true
			}">
			<app-dynamic-component
				[context]="pageContext"
				[displayComponent]="drawerMenuItem.dynamicComponent">
			</app-dynamic-component>
		</div>

		<div #DrawerOverlay
			*ngIf="drawerMenuItem.displayDrawerOverlay === true"
			class="pinned-drawer-overlay {{drawerMenuItem.overlayClassName}}">
		</div>
	</div>
</div>