/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup
} from '@angular/forms';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	Subject,
	debounceTime,
	distinctUntilChanged
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'app-context-menu',
	templateUrl: './app-context-menu.component.html',
	styleUrls: [
		'./app-context-menu.component.scss'
	]
})

/**
 * A component representing an instance of a context
 * menu component. This component represents content
 * context specific navigation and actions.
 *
 * @export
 * @class AppContextMenuComponent
 */
export class AppContextMenuComponent
implements OnInit, OnDestroy
{
	/**
	 * Initializes a new instance of the context menu
	 * component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to use for responsive layouts.
	 * @param {FormBuilder} formBuilder
	 * The form builder used for the filter control.
	 * @memberof AppContextMenuComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		private readonly formBuilder: UntypedFormBuilder)
	{
	}

	/**
	* Gets or sets the page context.
	*
	* @type {IDynamicComponentContext<Component, any>}
	* @memberof AppContextMenuComponent
	*/
	@Input() public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the page context operation group name. This will
	 * be displayed as the second level of the context menu.
	 *
	 * @type {string}
	 * @memberof AppContextMenuComponent
	 */
	@Input() public pageContextOperationGroupName: string;

	/**
	 * Gets or sets the menu expansion event emitter. The event
	 * is emitted to listening components when the expanded state
	 * of the context menu changes.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof AppContextMenuComponent
	 */
	@Output() public menuExpansion: EventEmitter<boolean> =
		new EventEmitter();

	/**
	 * Gets or sets the content context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof AppContextMenuComponent
	 */
	public contentContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the active value of this context menu. When active
	 * the context menu is expanded.
	 *
	 * @type {boolean}
	 * @memberof AppContextMenuComponent
	 */
	public active: boolean = true;

	/**
	 * Gets or sets the reset value of the slide menu. When this
	 * is set to true, this will set the slide menu back to it's
	 * initial top load level.
	 *
	 * @type {boolean}
	 * @memberof AppContextMenuComponent
	 */
	public reset: boolean = false;

	/**
	 * Gets or sets the current context menu display mode. This is used for
	 * logic on defining what should occur on a site width change.
	 *
	 * @type {string}
	 * @memberof AppContextMenuComponent
	 */
	public currentDisplayMode: string;

	/**
	 * Gets or sets the current debounced menu filter value.
	 *
	 * @type {string}
	 * @memberof AppContextMenuComponent
	 */
	public menuFilter: string;

	/**
	 * Gets or sets the form group used in this search filter.
	 *
	 * @type {FormGroup}
	 * @memberof AppContextMenuComponent
	 */
	public formGroup: UntypedFormGroup = null;

	/**
	 * Gets or sets the filter display value.
	 *
	 * @type {boolean}
	 * @memberof AppContextMenuComponent
	 */
	public displayFilter: boolean = false;

	/**
	 * Gets or sets the value changed subject which is used to watch
	 * for and debounce filter changes and display a filtered set
	 * of menu items.
	 *
	 * @type {Subject<string>}
	 * @memberof AppContextMenuComponent
	 */
	public filterChanged: Subject<string> = new Subject<string>();

	/**
	 * Gets or sets the current window width.
	 *
	 * @type {number}
	 * @memberof AppContextMenuComponent
	 */
	public windowWidth: number;

	/**
	 * Gets or sets the width changed subject which is used to watch
	 * for and debounce overlay closes on width changes only.
	 *
	 * @type {Subject<number>}
	 * @memberof AppContextMenuComponent
	 */
	public widthChanged: Subject<number> = new Subject<number>();

	/**
	 * Gets the overlay menu identifier used when checking for associated
	 * close menu events.
	 *
	 * @type {string}
	 * @memberof AppContextMenuComponent
	 */
	private readonly overlayIdentifier: string = 'context-navigation';

	/**
	 * Gets the filter identifier in the form group.
	 *
	 * @type {string}
	 * @memberof AppContextMenuComponent
	 */
	private readonly contextMenuFilterControlIdentifier: string =
		'contextMenuFilterControl';

	/**
	 * Gets the debounce delay used when altering the filter value.
	 *
	 * @type {number}
	 * @memberof AppContextMenuComponent
	 */
	private readonly filterInputDebounceDelay: number = 400;

	/**
	 * Gets the debounce delay used when checking for changes in the
	 * site layout service width.
	 *
	 * @type {number}
	 * @memberof AppContextMenuComponent
	 */
	private readonly widthChangeDebounceDelay: number = 25;

	/**
	 * Gets the display modes available for a context menu display.
	 *
	 * @type {overlay: string, static: string}
	 * @memberof AppContextMenuComponent
	 */
	private readonly displayMode:
	{
		overlay: string;
		static: string;
	} = {
			overlay: 'overlay',
			static: 'static'
		};

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof AppContextMenuComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.widthChanged.next(
			this.siteLayoutService.windowWidth);

		this.currentDisplayMode =
			this.siteLayoutService.displayTabletView
				? this.displayMode.overlay
				: this.displayMode.static;
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close this list when an associated menu is
	 * opened, such as a sibling navigation menu.
	 *
	 * @param {string} controlIdentifer
	 * The identifier of the control sending this event.
	 * @memberof AppContextMenuComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(
		controlIdentifer: string): void
	{
		if (this.active === true
			&& controlIdentifer !== this.overlayIdentifier)
		{
			this.closeOverlays();
		}
	}

	/**
	 * Handles the set content context event.
	 *
	 * @param {IDynamicComponentContext<Component, any>} contentContext
	 * The main content context of the application.
	 * @memberof AppContextMenuComponent
	 */
	@HostListener(
		AppEventConstants.setContentContextEvent,
		[AppEventParameterConstants.contentContext])
	public handleSetContext(
		contentContext: IDynamicComponentContext<Component, any>): void
	{
		setTimeout(
			() => {
				this.contentContext = contentContext;
			});
	}

	/**
	 * Handles the on initialization event.
	 * This will set the default active value of the context menu
	 * to open in desktop or larger displays.
	 *
	 * @memberof AppContextMenuComponent
	 */
	public ngOnInit()
	{
		this.formGroup =
			this.formBuilder.group({
				contextMenuFilterControl: []
			});

		this.filterChanged.pipe(
			debounceTime(this.filterInputDebounceDelay),
			distinctUntilChanged())
			.subscribe(
				(newValue: string) =>
				{
					if (this.menuFilter !== newValue)
					{
						this.menuFilter = newValue;
					}
				});

		this.windowWidth = this.siteLayoutService.windowWidth;
		this.widthChanged.pipe(
			debounceTime(this.widthChangeDebounceDelay),
			distinctUntilChanged())
			.subscribe(
				(newValue: number) =>
				{
					if (this.windowWidth !== newValue)
					{
						this.closeOverlays();
					}

					this.windowWidth = newValue;
				});

		setTimeout(() =>
		{
			if (this.siteLayoutService.windowWidth
				>= AppConstants.layoutBreakpoints.desktop)
			{
				this.windowWidth = this.siteLayoutService.windowWidth;
				this.expansionButtonClick();
			}
			else
			{
				this.active = false;
			}
		},
		this.siteLayoutService.debounceDelay);
	}

	/**
	 * On destroy event.
	 * This method is used to complete the debounce on the
	 * filter changed value.
	 *
	 * @memberof AppContextMenuComponent
	 */
	public ngOnDestroy(): void
	{
		this.filterChanged.complete();
	}

	/**
	 * Handles the click the context menu directly. This will always
	 * open the context menu, and clicks here will allow this to remain open.
	 *
	 * @memberof AppContextMenuComponent
	 */
	public expansionButtonClick(): void
	{
		this.active = true;
		this.menuExpansion.emit(this.active);

		if (this.siteLayoutService.displayTabletView === true)
		{
			EventHelper.dispatchContextOverlayEvent(this.active);
		}
	}

	/**
	 * Handles the click the expansion arrow button attached to this
	 * context menu. This will open or close the context menu on click
	 * based on it's current state.
	 *
	 * @param {Event} event
	 * The event sent from the epansion arrow button click.
	 * @memberof AppContextMenuComponent
	 */
	public toggleExpansionButtonClick(
		event: Event): void
	{
		this.active = !this.active;

		if (this.active === false)
		{
			this.resetSlideMenu();
		}

		this.menuExpansion.emit(this.active);

		if (this.siteLayoutService.displayTabletView === true)
		{
			EventHelper.dispatchContextOverlayEvent(this.active);
		}

		event.preventDefault();
		event.stopPropagation();

		EventHelper.dispatchHideAssociatedMenusEvent(
			this.overlayIdentifier);
	}

	/**
	 * Handles the filter change event sent from the slide menu
	 * child component. This will handle the request to alter the
	 * value via the slide menu.
	 *
	 * @param {string} newFilterValue
	 * The filter string value to set this context menu filter as.
	 * @memberof AppContextMenuComponent
	 */
	public filterChange(
		newFilterValue: string): void
	{
		this.formGroup.get(
			this.contextMenuFilterControlIdentifier)
			.setValue(newFilterValue);

		this.filterChanged.next(newFilterValue);
	}

	/**
	 * Handles the step index event sent from the slide menu
	 * child component. This will handle the logic for displaying
	 * or hiding this filter.
	 *
	 * @param {number} newStepIndex
	 * The current step index value of the child slide menu.
	 * @memberof AppContextMenuComponent
	 */
	public stepIndexChange(
		newStepIndex: number): void
	{
		setTimeout(() =>
		{
			this.displayFilter = newStepIndex !== 0;
		});
	}

	/**
	 * Handles the filter control change event sent from
	 * the enclosed menu when the filter is updated.
	 *
	 * @memberof AppContextMenuComponent
	 */
	public filterControlChanged(): void
	{
		const currentEntityFilter: string =
			this.formGroup.get(
				this.contextMenuFilterControlIdentifier)
				?.value;

		this.filterChanged.next(currentEntityFilter);
	}

	/**
	 * Handles the close overlays event sent from the enclosed
	 * menu when an action is performed. This will close the
	 * overlay when in a minimal view or when changing from
	 * an overlay to static display mode.
	 *
	 * @memberof AppContextMenuComponent
	 */
	public closeOverlays(): void
	{
		// Always fire in a tablet display or when we switch from
		// a tablet display to a a static display.
		if (this.siteLayoutService.displayTabletView === true
			|| this.currentDisplayMode === this.displayMode.overlay)
		{
			this.active = false;
			this.resetSlideMenu();
			this.menuExpansion.emit(this.active);
			EventHelper.dispatchContextOverlayEvent(false);
		}
	}

	/**
	 * Handles altering the reset value for the slide menu and setting this
	 * back when complete. This will reset the root value of the slide menu.
	 *
	 * @memberof AppContextMenuComponent
	 */
	private resetSlideMenu(): void
	{
		this.reset = true;
		setTimeout(() =>
		{
			this.reset = false;
		});
	}
}