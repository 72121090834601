/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';

@Component({
	selector: 'app-bi',
	templateUrl: './bi.component.html'
})

/**
 * A component representing an instance of the main
 * bi page.
 *
 * @export
 * @class BiComponent
 */
export class BiComponent
{
}