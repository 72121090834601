<div class="ui-g-12 no-padding">
	<div class="ui-g-12 no-horizontal-padding wizard-content-title">
	</div>
	<ng-template
		[ngIf]="context.source.wizardStepLoading || currentData.adjustmentDetails === null"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>
	<ng-template #Content>
		<div class="ui-g-12 content-section">
			<div class="ui-g-6">
				<div
					*ngIf="hasTransactionNumber()"
					class="ellipsis-text adjustment-summary-field-text">
					<strong>Policy ID: </strong>
				</div>
				<div
					*ngIf="hasTransactionNumber()"
					class="ellipsis-text adjustment-summary-field-text">
					<strong>Transaction ID: </strong>
				</div>
				<div class="ellipsis-text adjustment-summary-field-text">
					<strong>Note: </strong>
				</div>
				<div class="ellipsis-text adjustment-summary-field-text">
					<strong>Prior Balance: </strong>
				</div>
				<div class="ellipsis-text adjustment-summary-field-text">
					<strong>Adjustment: </strong>
				</div>
				<div class="ellipsis-text adjustment-summary-field-text">
					<strong>New Balance: </strong>
				</div>
			</div>
			<div class="ui-g-6">
				<div
					*ngIf="hasTransactionNumber()"
					class="ellipsis-text adjustment-summary-field-value">
					{{currentData.policyNumber}}
				</div>
				<div
					*ngIf="hasTransactionNumber()"
					class="ellipsis-text adjustment-summary-field-value">
					{{currentData.transactionNumber}}
				</div>
				<div class="ellipsis-text adjustment-summary-field-value">
					{{currentData.note}}
				</div>
				<div class="ellipsis-text adjustment-summary-field-value">
					{{currentData?.adjustmentDetails?.priorBalance | currency}}
				</div>
				<div class="ellipsis-text adjustment-summary-field-value">
					{{currentData.amount | currency}}
				</div>
				<div class="ellipsis-text adjustment-summary-field-value">
					{{currentData?.adjustmentDetails?.newBalance | currency}}
				</div>
			</div>
		</div>
	</ng-template>
</div>