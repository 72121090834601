/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A constant library used to defined constants that are used to
 * setup work items.
 *
 * @export
 * @class WorkItemConstants
 */
export class WorkItemConstants
{
	/**
	 * Gets the identifiers used for work item business logic.
	 *
	 * @type {object}
	 * @memberof WorkItemConstants
	 */
	public static readonly workItemIdentifiers:
	{
		entityTypeIdentifier: string;
		parentNavigationEntityIdentifer: string;
		parentNavigationEntityType: string;
		workItemIdentifier: string;
	} = {
			entityTypeIdentifier: 'WorkItem.*',
			parentNavigationEntityIdentifer: 'ParentNavigationEntityId',
			parentNavigationEntityType: 'ParentNavigationEntityType',
			workItemIdentifier: 'WorkItemIdentifier'
		};

	/**
	 * Gets the work item priority options.
	 *
	 * @type {object}
	 * @memberof WorkItemConstants
	 */
	public static readonly workItemPriority:
	{
		high: string;
		normal: string;
		low: string;
	} = {
			high: 'High',
			normal: 'Normal',
			low: 'Low'
		};

	/**
	 * Gets the work item status options.
	 *
	 * @type {object}
	 * @memberof WorkItemConstants
	 */
	public static readonly workItemStatus:
	{
		active: string;
		done: string;
		ignored: string;
		overdue: string;
	} = {
			active: 'Active',
			done: 'Done',
			ignored: 'Ignored',
			overdue: 'Overdue'
		};

	/**
	 * Gets the work item action names.
	 *
	 * @type {object}
	 * @memberof WorkItemConstants
	 */
	public static readonly actions:
		{
			workItemUnblocked: string;
			workItemBlocked: string;
			workItemIgnored: string;
			workItemDone: string;
			workItemReset: string;
		} = {
			workItemUnblocked: 'WorkItemUnblocked',
			workItemBlocked: 'WorkItemBlocked',
			workItemIgnored: 'WorkItemIgnored',
			workItemDone: 'WorkItemDone',
			workItemReset: 'WorkItemReset',
		};
}