/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	minimumNumberValidationMessage,
	minimumNumberValidator
} from '@entity/entity-formly-definitions';
import {
	IValidator
} from '@shared/interfaces/application-objects/validator.interface';
import {
	BaseRuleValidator
} from '@shared/validators/base-rule-validator.validator';

/**
 * A class representing a minimum number validator.
 *
 * @export
 * @class MinimumNumberValidator
 * @extends {BaseRuleValidator}
 */
export class MinimumNumberValidator
	extends BaseRuleValidator
{
	/**
	 * Creates and returns a unique instance of a minimum number validator.
	 *
	 * @returns {IValidator}
	 * A validator ready for use in a Formly layout.
	 * @memberof MinimumNumberValidator
	 */
	public create(): IValidator
	{
		return <IValidator>
			{
				expression: minimumNumberValidator,
				message: minimumNumberValidationMessage
			};
	}
}