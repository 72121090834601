/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	HttpClient,
	HttpEvent
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	IAggregateDto
} from '@api/interfaces/common/aggregatedto.interface';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	ISecurityDefinitionDto
} from '@api/interfaces/security/security-definition.dto.interface';
import {
	ISecurityEntityTypeDefinitionDto
} from '@api/interfaces/security/security-entity-type-definition.dto.interface';
import {
	IActionResponseDto
} from '@api/interfaces/workflow/action-response.dto.interface';
import {
	IActionDto
} from '@api/interfaces/workflow/action.dto.interface';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ObserveType
} from '@shared/constants/enums/observe-type.enum';
import {
	ResponseType
} from '@shared/constants/enums/response-type.enum';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	Observable,
	lastValueFrom
} from 'rxjs';

/**
 * A class representing the logic and services of the entry
 * instance controller.
 *
 * @export
 * @class
 * @extends {BaseEntityApiService<IEntityInstanceDto>}
 */
@Injectable()
export class EntityInstanceApiService
	extends BaseEntityApiService<IEntityInstanceDto>
{
	/**
	 * Creates an instance of a EntityInstanceApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof EntityInstanceApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.entityInstances;
	}

	/**
	 * Gets the type group currently set. This defines
	 * the entity instance type group used for these api services.
	 *
	 * @type {string}
	 * @memberof EntityInstanceApiService
	 */
	public get entityInstanceTypeGroup(): string
	{
		return this.typeGroup;
	}

	/**
	 * Sets the entity instance type group. This defines
	 * the type of entity instance for these api services.
	 *
	 * @param {string} typeGroup
	 * The entity instance type group for api services.
	 * @memberof EntityInstanceApiService
	 */
	public set entityInstanceTypeGroup(typeGroup: string)
	{
		this.typeGroup = typeGroup;
	}

	/**
	 * Gets a new instance of the api service.
	 *
	 * @returns {EntityInstanceApiService}
	 * A new api service instance.
	 * @memberof EntityInstanceApiService
	 */
	public GetNewService(): EntityInstanceApiService
	{
		return new EntityInstanceApiService(
			this.httpClient,
			this.cacheService);
	}

	/**
	 * Gets a type IEntityInstanceDto with the specified identifier.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to get.
	 * @returns {Promise<IEntityInstanceDto>}
	 * The entity instance found via this get method.
	 * @memberof EntityInstanceApiService
	 */
	public async get(
		id: number): Promise<IEntityInstanceDto>
	{
		this.validateRequest();

		const url =
			this.getBaseUrl()
				+ `/${id}`;

		return lastValueFrom(this.httpClient.get<IEntityInstanceDto>(url));
	}

	/**
	 * Gets a collection of type IActionDto actions
	 * associated with the sent entity instance identifier.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to get the actions of.
	 * @returns {Promise<IActionDto[]>}
	 * The array of actions found via this get method.
	 * @memberof EntityInstanceApiService
	 */
	public async getActions(
		id: number): Promise<IActionDto[]>
	{
		this.validateRequest();

		const url =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.actions);

		return lastValueFrom(this.httpClient.get<IActionDto[]>(
			url));
	}

	/**
	 * Gets the consolidated security definitions (effective permissions)
	 * the current user has on the specified entity
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance on which to get the permissions.
	 * @returns {Promise<ISecurityDefinitionDto>}
	 * The security definition with the effective permissions
	 * @memberof EntityInstanceApiService
	 */
	public async getPermissions(
		id: number): Promise<ISecurityDefinitionDto>
	{
		this.validateRequest();

		const url =
			this.getBaseUrl()
				+ `/${id}/Permissions`;

		return lastValueFrom(this.httpClient
			.get<ISecurityDefinitionDto>(url));
	}

	/**
	 * Gets the consolidated security definitions (effective permissions)
	 * the current user has on the specified entity and the sent array
	 * of possible hierarchy types.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance on which to get the permissions.
	 * @param {string[]} hierarchyTypeGroups
	 * The set of type groups to gather security definitions for related
	 * to this entity instance.
	 * @returns {Promise<IEntityTypeSecurityDefinitionDto>}
	 * The security definition with the effective permissions
	 * @memberof EntityInstanceApiService
	 */
	public async getHierarchyPermissions(
		id: number,
		hierarchyTypeGroups: string[]):
		Promise<ISecurityEntityTypeDefinitionDto[]>
	{
		this.validateRequest();

		const url =
			this.formUrlParam(
				this.getBaseUrl()
					+ `/${id}/HierarchyPermissions`,
				{
					hierarchyTypeGroups: hierarchyTypeGroups
				});

		return lastValueFrom(
			this.httpClient
				.get<ISecurityEntityTypeDefinitionDto[]>(url));
	}

	/**
	 * Gets a collection of type IEntityInstanceDto history records
	 * associated with the sent entity instance identifier.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to get the history of.
	 * @param {string} [filter]
	 * A string representing the filters for the query.
	 * @param {string} [orderBy]
	 * A string representing the order by for the query.
	 * @param {number} [offset]
	 * A number representing the skip offset.
	 * @param {number} [limit]
	 * A number representing the top limit count.
	 * @returns {Promise<IEntityInstanceDto[]>}
	 * The array of entity instances found via this history
	 * query method.
	 * @memberof EntityInstanceApiService
	 */
	public async getHistory(
		id: number,
		filter?: string,
		orderBy?: string,
		offset?: number,
		limit?: number): Promise<IEntityInstanceDto[]>
	{
		this.validateRequest();

		const url =
			this.formUrlParam(
				this.getNestedUrl(
					id,
					AppConstants.nestedRouteTypes.history),
				{
					filter: filter,
					orderBy: orderBy,
					offset: offset,
					limit: limit
				});

		return lastValueFrom(this.httpClient.get<IEntityInstanceDto[]>(url));
	}

	/**
	 * Gets a collection of type IEntityInstanceDto children
	 * associated with the sent entity instance identifier.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to get the children of.
	 * @param {string} filter
	 * A string representing the filters for the query.
	 * @param {string} orderBy
	 * A string representing the order by for the query.
	 * @param {number} [offset]
	 * A number representing the skip offset.
	 * @param {number} [limit]
	 * A number representing the top limit count.
	 * @param {string} [childTypeGroup]
	 * A string representing a child type group to limit results to.
	 * @returns {Promise<IEntityInstanceDto[]>}
	 * The array of entity instances found via this children
	 * query method.
	 * @memberof EntityInstanceApiService
	 */
	public async getChildren(
		id: number,
		filter: string,
		orderBy: string,
		offset?: number,
		limit?: number,
		childTypeGroup?: string): Promise<IEntityInstanceDto[]>
	{
		this.validateRequest();

		const url =
			this.formUrlParam(
				this.getNestedUrl(
					id,
					AppConstants.nestedRouteTypes.children),
				{
					filter: filter,
					orderBy: orderBy,
					offset: offset,
					limit: limit,
					childTypeGroup: childTypeGroup
				});

		return lastValueFrom(this.httpClient.get<IEntityInstanceDto[]>(url));
	}

	/**
	 * Gets an aggregate of the children matching the filter criteria.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to get the child
	 * aggregates of.
	 * @param {string} method
	 * The aggregate method name to use.
	 * @param {string} property
	 * The property to aggregate. Pass null if calling count.
	 * @param {string} filter
	 * The filter or "where clause."
	 * @param {string} groupBy
	 * The properties to group by.
	 * @param {string} [childTypeGroup]
	 * A string representing a child type group to limit results to.
	 * @returns {Promise<IAggregateDto[]>}
	 * An array of aggregate results.
	 * @memberof EntityInstanceApiService
	 */
	public async getChildrenAggregate(
		id: number,
		method: string,
		property?: string,
		filter?: string,
		groupBy?: string,
		childTypeGroup?: string): Promise<IAggregateDto[]>
	{
		this.validateRequest();

		const url =
			this.formUrlParam(
				this.getNestedUrl(
					id,
					AppConstants.nestedRouteTypes.children
						+ `/${AppConstants.apiMethods.aggregate}`),
				{
					method: method,
					property: property,
					filter: filter,
					groupBy: groupBy,
					childTypeGroup: childTypeGroup
				});

		return lastValueFrom(this.httpClient.get<IAggregateDto[]>(url));
	}

	/**
	 * Gets a collection of type IEntityInstanceDto parents
	 * associated with the sent entity instance identifier.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to get the parent of.
	 * @param {string} filter
	 * A string representing the filters for the query.
	 * @param {string} orderBy
	 * A string representing the order by for the query.
	 * @param {number} [offset]
	 * A number representing the skip offset.
	 * @param {number} [limit]
	 * A number representing the top limit count.
	 * @param {string} [parentTypeGroup]
	 * A string representing a parent type group to limit results to.
	 * @returns {Promise<IEntityInstanceDto[]>}
	 * The array of entity instances found via this parents
	 * query method.
	 * @memberof EntityInstanceApiService
	 */
	public async getParents(
		id: number,
		filter: string,
		orderBy: string,
		offset?: number,
		limit?: number,
		parentTypeGroup?: string): Promise<IEntityInstanceDto[]>
	{
		this.validateRequest();

		const url =
			this.formUrlParam(
				this.getNestedUrl(
					id,
					AppConstants.nestedRouteTypes.parents),
				{
					filter: filter,
					orderBy: orderBy,
					offset: offset,
					limit: limit,
					parentTypeGroup: parentTypeGroup
				});

		return lastValueFrom(this.httpClient.get<IEntityInstanceDto[]>(url));
	}

	/**
	 * Gets an aggregate of the parents matching the filter criteria.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to get the parent
	 * aggregates of.
	 * @param {string} method
	 * The aggregate method name to use.
	 * @param {string} property
	 * The property to aggregate. Pass null if calling count.
	 * @param {string} filter
	 * The filter or "where clause."
	 * @param {string} groupBy
	 * The properties to group by.
	 * @param {string} [parentTypeGroup]
	 * A string representing a parent type group to limit results to.
	 * @returns {Promise<IAggregateDto[]>}
	 * An array of aggregate results.
	 * @memberof EntityInstanceApiService
	 */
	public async getParentsAggregate(
		id: number,
		method: string,
		property?: string,
		filter?: string,
		groupBy?: string,
		parentTypeGroup?: string): Promise<IAggregateDto[]>
	{
		this.validateRequest();

		const url =
			this.formUrlParam(
				this.getNestedUrl(
					id,
					AppConstants.nestedRouteTypes.parents
						+ `/${AppConstants.apiMethods.aggregate}`),
				{
					method: method,
					property: property,
					filter: filter,
					groupBy: groupBy,
					parentTypeGroup: parentTypeGroup
				});

		return lastValueFrom(this.httpClient.get<IAggregateDto[]>(url));
	}

	/**
	 * Gets a collection of type IEntityInstanceDto with the specified filters.
	 *
	 * @async
	 * @param {string} filter
	 * A string representing the filters for the query.
	 * @param {string} orderBy
	 * A string representing the order by for the query.
	 * @param {number} [offset]
	 * A number representing the skip offset.
	 * @param {number} [limit]
	 * A number representing the top limit count.
	 * @param {number} [last]
	 * A number representing the last count.
	 * @returns {Promise<IEntityInstanceDto[]>}
	 * The array of entity instances found via this query method.
	 * @memberof EntityInstanceApiService
	 */
	public async query(
		filter: string,
		orderBy: string,
		offset?: number,
		limit?: number,
		last?: number): Promise<IEntityInstanceDto[]>
	{
		this.validateRequest();

		const url =
			this.formUrlParam(
				this.getBaseUrl(),
				{
					filter: filter,
					orderBy: orderBy,
					offset: offset,
					limit: limit,
					last: last
				});

		return lastValueFrom(this.httpClient.get<IEntityInstanceDto[]>(url));
	}

	/**
	 * Gets an aggregate.
	 *
	 * @async
	 * @param {string} method
	 * The aggregate method name to use.
	 * @param {string} property
	 * The property to aggregate. Pass null if calling count.
	 * @param {string} filter
	 * The filter or "where clause."
	 * @param {string} groupBy
	 * The properties to group by.
	 * @returns {Promise<IAggregateDto[]>}
	 * An array of aggregate results.
	 * @memberof EntityInstanceApiService
	 */
	public async aggregate(
		method: string,
		property?: string,
		filter?: string,
		groupBy?: string): Promise<IAggregateDto[]>
	{
		this.validateRequest();

		const url =
			this.formUrlParam(
				this.getBaseUrl()
				+ `/${AppConstants.apiMethods.aggregate}`,
				{
					method: method,
					property: property,
					filter: filter,
					groupBy: groupBy
				});

		return lastValueFrom(this.httpClient.get<IAggregateDto[]>(url));
	}

	/**
	 * Gets the first returned value returned from a query
	 * response matching the sent parameters.
	 *
	 * @async
	 * @param {string} filter
	 * A string representing the filters for the query.
	 * @param {string} orderBy
	 * A string representing the order by for the query.
	 * @param {boolean} [allowNullReturn]
	 * If sent, this will not throw an error if a matching
	 * value is found.
	 * @returns {Promise<TEntity>}
	 * The first match of the query sent of type TEntity.
	 * @throws {Error}
	 * If no match was found for the query an error will be thrown describing
	 * the service and query used.
	 * @memberof BaseEntityApiService
	 */
	public async getSingleQueryResult(
		queryString: string,
		orderBy: string,
		allowNullReturn: boolean = false): Promise<IEntityInstanceDto>
	{
		this.validateRequest();

		const queryMatches: IEntityInstanceDto[] =
			await this.query(
				queryString,
				orderBy,
				0,
				1);

		if (!allowNullReturn
			&& (queryMatches == null
				|| queryMatches.length === 0))
		{
			throw new Error(
				`Unable to find a matching value from the ${typeof this}. `
					+ `Query: '${queryString}' found zero matching values.`);
		}

		return queryMatches[0];
	}

	/**
	 * Creates an IEntityInstanceDto with the specified information.
	 * Parent information is defined in the entity and is at times
	 * required.
	 *
	 * @async
	 * @param {IEntityInstanceDto} entity
	 * The entity instance to create.
	 * @param {string} [parentTypeGroup]
	 * The entity instance parent type group to create.
	 * @param {number} [parentId]
	 * The parent id to create this entity instance under.
	 * @param {object} [parameters]
	 * The context parameters needed to create the entity.
	 * @returns {Promise<number>}
	 * The newly created entity instance identifier.
	 * @memberof EntityInstanceApiService
	 */
	public async createEntityInstance(
		entity: IEntityInstanceDto,
		parentTypeGroup?: string,
		parentId?: number,
		parameters?: object): Promise<number>
	{
		this.validateRequest();

		const hasParentInformation: boolean =
			!AnyHelper.isNullOrEmpty(parentTypeGroup)
				&& !AnyHelper.isNullOrEmpty(parentId);

		const data: object =
			{
				...{
					parentTypeGroup: parentTypeGroup,
					parentId: parentId,
				},
				...parameters
			};

		const url =
			hasParentInformation
				? this.formUrlParam(
					this.getBaseUrl(),
					data)
				: this.getBaseUrl();

		await this.resetAssociatedCache(url);

		if (hasParentInformation === true)
		{
			const initialTypeGroup: string = this.entityInstanceTypeGroup;
			this.entityInstanceTypeGroup = parentTypeGroup;
			await this.resetAssociatedCache(
				this.getNestedUrl(
					parentId,
					AppConstants.nestedRouteTypes.children));

			this.entityInstanceTypeGroup = initialTypeGroup;
		}

		const response = await lastValueFrom(
			this.httpClient.post(
				url,
				entity,
				{ observe: 'response' }));

		const createdItemId: number =
			this.getCreatedAtRouteIdentifier(
				response.headers.get('location'));

		return Promise.resolve(createdItemId);
	}

	/**
	 * Creates an IEntityInstanceDto with the specified information.
	 * This method is not implemented in the API.
	 *
	 * @async
	 * @param {ISecuritySessionDto} _entity
	 * The entity instance to create.
	 * @returns {Promise<number>}
	 * The newly created entity instance identifier.
	 * @memberof EntityInstanceApiService
	 */
	public async create(
		_entity: IEntityInstanceDto): Promise<number>
	{
		throw new Error(
			this.getNotImplementedMessage(
				AppConstants.apiMethods.create,
				[
					AppConstants.apiMethods.createEntityInstance
				]));
	}

	/**
	 * Updates an IEntityInstanceDto with the specified identifier
	 * and information.
	 *
	 * @async
	 * @param {IEntityInstanceDto} entity
	 * The entity instance to update.
	 * @returns {Promise<object>}
	 * An observable of the put no-content response.
	 * @memberof EntityInstanceApiService
	 */
	public async update(
		id: number,
		entity: IEntityInstanceDto): Promise<object>
	{
		this.validateRequest();

		const baseUrl: string =
			this.getBaseUrl();
		const url =
			`${baseUrl}/${id}`;
		await this.resetAssociatedCache(baseUrl);

		return lastValueFrom(
			this.httpClient.patch(
				url,
				entity));
	}

	/**
	 * Deletes an IEntityInstanceDto with the specified identifier.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to delete.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof EntityInstanceApiService
	 */
	public async delete(
		id: number): Promise<object>
	{
		this.validateRequest();

		const baseUrl: string =
			this.getBaseUrl();
		const url =
			`${baseUrl}/${id}`;
		await this.resetAssociatedCache(baseUrl);

		return lastValueFrom(
			this.httpClient.delete(url));
	}

	/**
	 * Executes an action of type action name on the entity instance
	 * with the specified identifer.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to perform the action on.
	 * @param {string} actionName
	 * The action to perform on the identifier specified entity instance.
	 * @param {IEntityInstanceDto} [entity]
	 * If supplied this entity will be sent in the body of the request
	 * and used where applicable to the action.
	 * @param {string} query
	 * An optional query string of parameters.
	 * @param {ResponseType} responseType
	 * The type of response expected.
	 * @returns {Promise<T>}
	 * An observable of the execute action response.
	 * @memberof EntityInstanceApiService
	 */
	public async executeAction<T>(
		id: number,
		actionName: string,
		entity?: IEntityInstanceDto,
		query?: string,
		responseType: ResponseType = ResponseType.Json): Promise<T>
	{
		return lastValueFrom(
			this.executeActionAsObservable<T>(
				id,
				actionName,
				entity,
				query,
				responseType));
	}

	/**
	 * Executes an action of type action name on the entity instance
	 * with the specified identifer.
	* @param {number} id
	 * The identifier of the entity instance to perform the action on.
	 * @param {string} actionName
	 * The action to perform on the identifier specified entity instance.
	 * @param {IEntityInstanceDto} [entity]
	 * If supplied this entity will be sent in the body of the request
	 * and used where applicable to the action.
	 * @param {string} query
	 * An optional query string of parameters.
	 * @param {ResponseType} responseType
	 * The type of response expected.
	 * @param {Observable} observeType
	 * What the observer will observe.
	 * @returns {Observable<T>}
	 * An observable of the execute action response.
	 * @memberof EntityInstanceApiService
	 */
	public executeActionAsObservable<T>(
		id: number,
		actionName: string,
		entity?: IEntityInstanceDto,
		query?: string,
		responseType: ResponseType = ResponseType.Blob,
		observeType: ObserveType = ObserveType.Events): Observable<T>
	{
		const anyHttp: any = this.httpClient;

		this.validateRequest();

		let url: string =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.actions)
			+ `/${actionName}`;

		if (!AnyHelper.isNullOrEmpty(query))
		{
			url += query.startsWith('?')
				? query
				: '?' + query;
		}

		const observable: Observable<T> =
			anyHttp
				.post(
					url,
					entity,
					{
						reportProgress: true,
						observe: observeType,
						responseType: responseType,
						headers: this.queryOnlyGetHeaders
					});

		return observable;
	}

	/**
	 * Assigns a child to the hierarchy of the entity instance
	 * with the supplied identifier. This will insert the child
	 * with the supplied child identifier.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to perform the action on.
	 * @param {number} childId
	 * The identifier of the child to assign.
	 * @returns {Promise<object>}
	 * An observable of the put no-content response.
	 * @memberof EntityInstanceApiService
	 */
	public async assignChild(
		id: number,
		childId: number): Promise<object>
	{
		this.validateRequest();

		const url =
			this.formUrlParam(
				this.getNestedUrl(
					id,
					AppConstants.nestedRouteTypes.children),
				{
					childId: childId
				});
		await this.resetAssociatedCache(url);

		return lastValueFrom(
			this.httpClient.post(
				url,
				{},
				{
					headers: this.queryOnlyGetHeaders
				}));
	}

	/**
	 * Removes a child from the hierarchy of the entity instance
	 * with the supplied identifier. This will remove the child
	 * with the supplied child identifier.
	 *
	 * @async
	 * @param {number} id
	 * The identifier of the entity instance to perform the action on.
	 * @param {number} childId
	 * The identifier of the child to remove.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof EntityInstanceApiService
	 */
	public async unAssignChild(
		id: number,
		childId: number): Promise<object>
	{
		this.validateRequest();

		const nestedUrl: string =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.children);
		const url =
			this.formUrlParam(
				nestedUrl,
				{
					childId: childId
				});
		await this.resetAssociatedCache(nestedUrl);

		return lastValueFrom(
			this.httpClient.delete(
				url));
	}

	/**
	 * Uploads a file.
	 *
	 * @param {number} id
	 * The id of the entity instance that represents the file.
	 * @param {FormData} formData
	 * An optional form data that contains the file. Currently only
	 * one file is supported.
	 * @note This is optional because reference storage types cannot
	 * acccept a file as it is referenced.
	 * @param {string} uploadAction
	 * An optionmal workflow upload action to perform if
	 * different from the default.
	 * @returns {Observable<HttpEvent<IActionResponseDto>>}
	 * An observable of http events that, if successfull, will
	 * complete with a response containing the new file instance.
	 * @memberof EntityInstanceApiService
	 */
	public uploadFile(
		id: number,
		formData?: FormData,
		uploadAction: string = AppConstants.workflowActions
			.fileUpload): Observable<HttpEvent<IActionResponseDto>>
	{
		const url: string = this
			.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.actions)
				+ `/${uploadAction}`;

		return this.httpClient
			.post<IActionResponse>(
				url,
				formData,
				{
					reportProgress: true,
					observe: 'events',
					headers: this.queryOnlyGetHeaders
				});
	}

	/**
	 * Downloads a file.
	 *
	 * @param {number} id
	 * The file id.
	 * @param {string} downloadAction
	 * The download action name if different from default.
	 * @returns {Observable<HttpEvent<Blob>>}
	 * An observable of Http events which, if successful, will
	 * complete with a response event containing a file.
	 * @memberof EntityInstanceApiService
	 */
	public downloadFile(
		id: number,
		downloadAction: string = AppConstants
			.workflowActions.fileDownload): Observable<HttpEvent<Blob>>
	{
		const url: string = this
			.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.actions)
				+ `/${downloadAction}`;

		return this.httpClient
			.post(
				url,
				null,
				{
					reportProgress: true,
					observe: 'events',
					responseType: 'blob',
					headers: this.queryOnlyGetHeaders
				});
	}

	/**
	 * Executes the external report workflow action and returns
	 * external report configuration values.
	 *
	 * @param {number} id
	 * The entity instance id to perform this workflow action against.
	 * @param {string} reportType
	 * The report type parameter which will determine the returned
	 * configuration values.
	 * @returns {Promise<IActionResponseDto>}
	 * An observable of the execute action response.
	 * @memberof EntityInstanceApiService
	 */
	public getExternalReportConfiguration(
		id: number,
		reportType: string): Promise<IActionResponseDto>
	{
		const url: string = this
			.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.actions)
				+ `/${AppConstants
					.workflowActions
					.externalReportConfiguration}`;

		return lastValueFrom(
			this.httpClient
				.post<IActionResponse>(
					url,
					{},
					{
						headers: this.queryOnlyGetHeaders,
						params: {
							reportType: reportType
						}
					}));
	}

	/**
	 * Gets the really simple syndication feed data.
	 *
	 * @param {number} id
	 * The entity instance id to perform this workflow action against.
	 * @param {string} feedSourceUrl
	 * The feed source url to get the data from.
	 * @returns {Promise<IActionResponseDto>}
	 * An observable of the execute action response.
	 * @memberof EntityInstanceApiService
	*/
	public getReallySimpleSyndicationFeed(
		id: number,
		feedSourceUrl: string): Promise<IActionResponseDto>
	{
		const url: string = this
			.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.actions)
				+ `/${AppConstants
					.workflowActions
					.reallySimpleSyndicationFeed}`;

		return lastValueFrom(
			this.httpClient
				.post<IActionResponse>(
					url,
					{},
					{
						headers: this.queryOnlyGetHeaders,
						params: {
							feedSourceUrl: feedSourceUrl
						}
					}));
	}

	/**
	 * Validates that the entity instance required
	 * type group value is set.
	 *
	 * @throws {Error}
	 * Entity instance type group property not set.
	 * @memberof EntityInstanceApiService
	 */
	private validateRequest(): void
	{
		if (!AnyHelper.isNullOrEmpty(this.typeGroup))
		{
			return;
		}

		throw new Error(
			'The BaseEntityApiService typeGroup property must be set to '
				+ 'consume entity instance services. Please set this value '
				+ 'via the entityInstanceTypeGroup getter/setter.');
	}
}