/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	EntityManagerDirective
} from '@admin/directives/entity-manager.directive';
import {
	Component
} from '@angular/core';
import {
	ActivatedRoute
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

@Component({
	selector: 'app-entity-overview',
	templateUrl: './entity-overview.component.html'
})

/**
 * A component representing an instance of a entity overview
 * component.
 *
 * @export
 * @class EntityOverviewComponent
 */
export class EntityOverviewComponent
	extends EntityManagerDirective
{
	/**
	 * Creates an instance of an EntityOverviewComponent.
	 *
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type service used to populate get the entity type data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity instance service used to populate the entity definition data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityOverviewComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(
			route,
			activityService,
			resolver);
	}

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityOverviewComponent
	 */
	public async setContextData(): Promise<void>
	{
		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService.
				get(this.entityDefinition.typeId);

		this.saving = false;
		this.formValuesChanged = false;
	}
}