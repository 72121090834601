/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	InjectionToken
} from '@angular/core';
import {
	InsuranceService
} from '@insurance/services/insurance.service';

/*
 * Export all tokens to be used in the dependency injector for
 * string based resolution
*/
export const insuranceServiceToken =
	new InjectionToken<InsuranceService>(
		'InsuranceService');

/**
 * A lookup to be shared accross the application used
 * for classes available in the shared module.
 *
 * @export
 * @class InsuranceTokenLookup
 */
export class InsuranceTokenLookup
{
	/**
	 * Dictionary to be used in dependency injector for
	 * string based resolution.
	 *
	 * @static
	 * @memberof InsuranceTokenLookup
	 */
	public static tokens =
		{
			'InsuranceService':
				insuranceServiceToken
		};
}