/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Directive,
	HostListener
} from '@angular/core';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	Tooltip
} from 'primeng/tooltip';

@Directive({
	selector: '[mobileTooltip]',
	exportAs: 'mobileTooltip'
})

/**
 * A directive that handles mobile tap based tooltips and hides a tooltip
 * when the window is scrolled.
 *
 * @export
 * @class MobileTooltipDirective
 */
export class MobileTooltipDirective
{
	/**
	 * Creates an instance of the MobileTooltipDirective.
	 *
	 * @param {Tooltip} tooltip
	 * The tooltip element.
	 * @memberof MobileTooltipDirective
	 */
	public constructor(
		private readonly tooltip: Tooltip)
	{
	}

	/**
	 * Deactivates the tooltip when the window is scrolled.
	 *
	 * @memberof MobileTooltipDirective
	 */
	@HostListener(
		WindowEventConstants.scrollEvent)
	public onScroll()
	{
		this.tooltip.deactivate();
	}

	/**
	 * This will handle the tap event on the tooltip and toggle
	 * the display of the tooltip.
	 *
	 * @memberof MobileTooltipDirective
	 */
	public mobileTooltipToggle(): void
	{
		if (this.tooltip.active === true)
		{
			this.tooltip.deactivate();

			return;
		}

		this.tooltip.activate();
	}

	/**
	 * This method will remove the auto focus click event attached to
	 * primeNg tooltips.
	 *
	 * @param {MouseEvent} event
	 * The click event to be captured and halted.
	 * @memberof MobileTooltipDirective
	 */
	public preventDefault(
		event: MouseEvent): void
	{
		event.preventDefault();
		event.stopImmediatePropagation();
	}
}