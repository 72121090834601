<div class="ui-g-12 no-padding">
	<div class="ui-g-12 no-horizontal-padding wizard-content-title">
	</div>
	<ng-template
		[ngIf]="context.source.wizardStepLoading || context.source.activeMenuItem.currentData.data.paymentDetails == null"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12 content-section">
			<div class="ui-g-6 no-padding"
				[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView,
				'no-padding': siteLayoutService.displayTabletView}">
				<p-card header="Summary">
					<div class="card">
						<p-divider></p-divider>
						<div class="ui-g-12">
							<div class="ui-g-6">
								<div class="ellipsis-text">
									Premium
								</div>
								<div class="ellipsis-text">
									Fees
								</div>
								<div class="ellipsis-text">
									Taxes
								</div>
								<div class="ellipsis-text">
									Total
								</div>
							</div>
							<div class="ui-g-6">
								<div class="payment-details-field-value">
									{{context.source.activeMenuItem.currentData.data.paymentDetails.premium | currency}}
								</div>
								<div class="payment-details-field-value">
									{{context.source.activeMenuItem.currentData.data.paymentDetails.fees | currency}}
								</div>
								<div class="payment-details-field-value">
									{{context.source.activeMenuItem.currentData.data.paymentDetails.taxes | currency}}
								</div>
								<div class="payment-details-field-value">
									{{context.source.activeMenuItem.currentData.data.paymentDetails.total | currency}}
								</div>
							</div>
						</div>
						<p-divider></p-divider>
						<div class="ui-g-12">
							<div class="ui-g-6">
								<div class="ellipsis-text">
									Prior Term Transfer
								</div>
								<div class="ellipsis-text">
									Payments
								</div>
								<div class="ellipsis-text">
									Write Offs
								</div>
							</div>
							<div class="ui-g-6">
								<div class="payment-details-field-value">
									{{context.source.activeMenuItem.currentData.data.paymentDetails.priorTermTransfer | currency}}
								</div>
								<div class="payment-details-field-value">
									{{context.source.activeMenuItem.currentData.data.paymentDetails.payments | currency}}
								</div>
								<div class="payment-details-field-value">
									{{context.source.activeMenuItem.currentData.data.paymentDetails.policyTermWrittenOffBalance | currency}}
								</div>
							</div>
						</div>
						<p-divider></p-divider>
						<div class="ui-g-12">
							<div class="ui-g-6">
								<div class="ellipsis-text">
									Remaining Balance
								</div>
								<div class="ellipsis-text">
									Minimum Due
								</div>
							</div>
							<div class="ui-g-6">
								<div class="payment-details-field-value">
									{{context.source.activeMenuItem.currentData.data.paymentDetails.remainingBalance | currency}}
								</div>
								<div class="payment-details-field-value">
									{{context.source.activeMenuItem.currentData.data.paymentDetails.minimumDue | currency}}
								</div>
							</div>
						</div>
						<p-divider></p-divider>
					</div>
				</p-card>
			</div>

			<div class="ui-g-6 no-padding"
				[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView,
					'no-padding': siteLayoutService.displayTabletView}">
				<app-dynamic-formly
					[dataSet]="context.source.activeMenuItem.currentData.data"
					[layoutSchema]="dynamicFormlyLayout"
					[context]="context"
					(validityChanged)="validityChanged($event)">
				</app-dynamic-formly>
			</div>
		</div>

	</ng-template>
</div>