/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	DateTime
} from 'luxon';

/* eslint-enable max-len */

@Component({
	selector: 'app-action-history-expand',
	templateUrl: './action-history-expand.component.html',
	styleUrls: [
		'./action-history-expand.component.scss'
	]
})

/**
 * A component representing an instance of an action history expand.
 *
 * @export
 * @class SessionComponent
 * @implements {
 * 	IDynamicComponent<ActionHistoryExpandComponent, any>}
 */
export class ActionHistoryExpandComponent
implements IDynamicComponent<CommonTableComponent, any>, OnInit
{
	/**
	 * Initializes a new instance of the ActionHistoryExpandComponent class.
	 *
	 * @param {WorkflowActionDefinitionsApiService}
	 * workflowActionDefinitionApiService
	 * The api service used to gather workflow action definition data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to gather entity instance data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to gather entity type data.
	 * @param {Router} router
	 * The service that provides navigation among views.
	 * @param {ModuleService} moduleService
	 * The module service data.
	 * @memberof ActionHistoryExpandComponent
	 */
	public constructor(
		public workflowActionDefinitionApiService:
			WorkflowActionDefinitionsApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityTypeApiService: EntityTypeApiService,
		public router: Router,
		public moduleService: ModuleService)
	{
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof ActionHistoryExpandComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the formly layout definition.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof ActionHistoryExpandComponent
	 */
	public formlyLayoutDefinition: FormlyFieldConfig[];

	/**
	 * Gets or sets the loading state.
	 *
	 * @type {boolean}
	 * @memberof ActionHistoryExpandComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the action definition.
	 *
	 * @type {IWorkflowActionDefinitions}
	 * @memberof ActionHistoryExpandComponent
	 */
	public actionDefinition: IWorkflowActionDefinitions;

	/**
	 * Gets or sets the entity instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof ActionHistoryExpandComponent
	 */
	public entityInstance: IEntityInstance;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {IEntityType}
	 * @memberof ActionHistoryExpandComponent
	 */
	public entityType: IEntityType;

	/**
	 * Gets or sets the user.
	 *
	 * @type {IEntityInstance}
	 * @memberof ActionHistoryExpandComponent
	 */
	public user: IEntityInstance;

	/**
	 * Initializes the Action History Expand Component to define the page
	 * variables and formly definitions.
	 *
	 * @async
	 * @memberof ActionHistoryExpandComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.actionDefinition =
			await this.workflowActionDefinitionApiService
				.get(this.context.source.selectedItem.actionDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.actionDefinition.entityTypeId);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.entityType.group;

		this.entityInstance =
			!AnyHelper.isNullOrEmpty(
				this.context.source.selectedItem.entityInstanceId)
				? await this.entityInstanceApiService
					.get(this.context.source.selectedItem.entityInstanceId)
				: null;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;

		this.user = await this.entityInstanceApiService
			.get(this.context.source.selectedItem.userId);

		this.setFormlyLayoutDefinitions();

		this.loading = false;
	}

	/**
	 * Sets the formly layout definitions.
	 *
	 * @memberof ActionHistoryExpandComponent
	 */
	public setFormlyLayoutDefinitions(): void
	{
		const entityInstanceIdOptions =
			AnyHelper.isNull(this.context.source.selectedItem.entityInstanceId)
				? []
				: [
					{
						value:
							this.context.source.selectedItem.entityInstanceId,
						label:
							this.getEntityInstanceLabel()
					}
				];

		this.formlyLayoutDefinition =
			[
				{
					key: 'actionNameId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Action Name',
						disabled: true,
						options: [
							{
								value:
									this.context.source
										.selectedItem.actionDefinitionId,
								label:
									this.actionDefinition.name
							}],
						actionMenuItems: [
							{
								icon: 'fa fa-share-square-o',
								command:
									() =>
									{
										this.router.navigate(
											[
												`${this.moduleService
													.name.toLowerCase()}`,
												'workflowEngine',
												'actions',
												'definitions'
											]);
									}
							}
						]
					}
				},
				{
					key: 'entityInstanceId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Entity Instance',
						disabled: true,
						options: entityInstanceIdOptions,
						actionMenuItems: [
							{
								icon: 'fa fa-share-square-o',
								command: async() =>
								{
									this.router.navigate(
										[
											`${this.moduleService
												.name.toLowerCase()}/`
												+ 'entities',
											`${this.entityType.group}`,
											'view',
											this.context.source
												.selectedItem.entityInstanceId
										]);
								},
								disabled: AnyHelper.isNullOrEmpty(
									this.context.source
										.selectedItem.entityInstanceId)
							}
						]
					}
				},
				{
					key: 'userId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'User',
						disabled: true,
						options: [
							{
								value: this.context.source.selectedItem.userId,
								label: `${this.context.source
									.selectedItem.userId}
									(${this.user.data.userName})`
							}]
					}
				},
				{
					key: 'createDate',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Create Date',
						disabled: true,
						options: [
							{
								value: this.context.source
									.selectedItem.createDate,
								label: `${this.getCreateDateLabel()}`
							}]
					}
				},
				{
					key: 'stateId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Status',
						disabled: true,
						options: [
							{
								value: 1,
								label: 'Completed'
							},
							{
								value: 2,
								label: 'Failed'
							},
							{
								value: 3,
								label: 'Pending'
							}
						],
						addonMenuItems: [
							{
								getCustomIcon:
									(statusId: number) =>
									{
										switch (statusId)
										{
											case 1:
												return 'pi pi-check';
											case 2:
												return 'fa fa-fw '
													+ 'fa-exclamation';
											case 3:
												return 'pi pi-spin pi-spinner';
											default:
												return null;
										}
									},
								getCustomStyle:
									(statusId: number) =>
									{
										switch (statusId)
										{
											case 1:
												return 'color: #3e9018;';
											case 2:
												return 'color: #da2f31;';
											default:
												return null;
										}
									}
							}
						]
					}
				},
				{
					key: 'changeDate',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Change Date',
						disabled: true,
						options: [
							{
								value: this.context.source
									.selectedItem.changeDate,
								label: `${this.getChangeDateLabel()}`
							}]
					}
				}
			];
	}

	/**
	 * Gets the entity instance label string.
	 *
	 * @returns {string}
	 * The entity instance label string.
	 * @memberof ActionHistoryExpandComponent
	 */
	public getEntityInstanceLabel(): string
	{
		return !AnyHelper.isNullOrEmpty(
			this.context.source.selectedItem.entityInstanceId)
			? `${this.context.source.selectedItem.entityInstanceId}
				(${this.entityInstance?.entityType})`
			: AppConstants.empty;
	}

	/**
	 * Gets the create date label string.
	 *
	 * @returns {string}
	 * The create date label string.
	 * @memberof ActionHistoryExpandComponent
	 */
	public getCreateDateLabel(): string
	{
		const localDate: DateTime =
			DateTime.fromISO(
				this.context.source.selectedItem.createDate).toLocal();

		return `${localDate.toFormat(
			DateHelper.presetFormats.internationalFullDateFormat)}
			(${localDate.toRelative()})`;
	}

	/**
	 * Gets the change date label string.
	 *
	 * @returns {string}
	 * The change date label string.
	 * @memberof ActionHistoryExpandComponent
	 */
	public getChangeDateLabel(): string
	{
		const localDate: DateTime =
			DateTime.fromISO(
				this.context.source.selectedItem.changeDate).toLocal();

		const duration: number =
			DateTime.fromISO(
				this.context.source.selectedItem.changeDate)
				.diff(DateTime.fromISO(
					this.context.source.selectedItem.createDate)).milliseconds;

		return `${localDate.toFormat(
			DateHelper.presetFormats.internationalFullDateFormat)} `
			+ `(${this.context.source.tableDefinitions.actions.view
				.customContext.source.getAverageTimeDuration(duration)} `
			+ 'duration)';
	}
}