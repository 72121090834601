<div class="ui-g-12 no-padding">
	<ng-template
		[ngIf]="context.source.wizardStepLoading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12 no-padding dynamic-form-content"
			[ngClass]="{
				'centered-content': (context.source.activeWizardStep.stepLayout.length === 1 && context.source.activeWizardStep.stepLayout[0].props.displayCenter === true)
			}">
			<app-dynamic-formly
				[dataSet]="context.source.activeMenuItem.currentData"
				[layoutSchema]="context.source.activeWizardStep.stepLayout"
				[context]="context"
				(validityChanged)="validStepChanged($event)">
			</app-dynamic-formly>
		</div>
	</ng-template>
</div>