<div class="ui-g-12 no-padding text-left">

	<ng-template
		#BackNavigation>
		<div
			class="ui-g-12 no-padding text-left nested-display-header">
			<span
				class="text-link theme-color nested-display-back-container"
				(click)="displayMode = displayModes.list">
				<i class="fa fa-arrow-left nested-display-back-icon">
				</i>
				<span class="nested-display-back">
					Back
				</span>
			</span>
		</div>
	</ng-template>

	<ng-template
		[ngIf]="loading === true"
		[ngIfElse]="Loaded">
		<div
			class="text-center ui-g-12 no-horizontal-padding">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>
	</ng-template>

	<ng-template
		#Loaded>
		<ng-template
			#Invalid
			[ngIf]="isOwnershipAllowed === false"
			[ngIfElse]="Content">
			<div class="ui-g-12 text-center no-access-content">
				<div *ngIf="isOwnershipAllowed === true">
					This view is not currently available.
				</div>
				<div *ngIf="isOwnershipAllowed === false">
					<app-access-denied-content
						[drawerDisplay]="true"
						drawerName="Business Rules Report"
						[sessionIdentifier]="sessionIdentifier"
						[accessDeniedUrl]="accessDeniedUrl"
						[resources]="resources"
						[clientMessage]="clientMessage">
					</app-access-denied-content>
				</div>
			</div>
		</ng-template>

		<ng-template
			#Content>
			<div *ngIf="!deleteMode"
				class="ui-g-12 no-padding">
				<p-scrollPanel
					styleClass="bi-report-container">
					<div class="ui-g-12 no-padding">
						<span class="panel-sub-title">
							Mode
						</span>
						<p-selectButton
							[options]="drawerOptions"
							[(ngModel)]="drawerAction"
							styleClass="p-button-info p-bi-button-set setting-buttons"
							optionLabel="label"
							filterBy="label"
							optionValue="value"
							(onOptionClick)=setDrawerButtonAction()>
						</p-selectButton>
						<app-dynamic-formly
							[dataSet]="context.source.reportData"
							[layoutSchema]="dynamicFormlyLayout"
							[context]="context">
						</app-dynamic-formly>
					</div>
				</p-scrollPanel>
				<div class="ui-g-12 text-right button-set"
					*ngIf="pageMode === 'edit'">
					<a class="text-link theme-color secondary-action-button"
						(click)="cancelAction()">
						Cancel
					</a>
					<p-splitButton
						label="Save"
						(onClick)="updateEntity()"
						[model]="reportButtonActions"
						[disabled]="saveEnabled">
					</p-splitButton>
				</div>
			</div>
			<div *ngIf="deleteMode"
				class="ui-g-12 no-padding">
				<p-scrollPanel styleClass="bi-report-container">
					<div class="ui-g-12 no-padding">
						<div class="panel-sub-title">
							Delete: {{reportDefinition.reportName}}
						</div>
						<p class="m-0 text-left ellipsis-text"
							style="white-space: pre-line;">
							{{ deleteConfirmationMessage }}
						</p>
					</div>
				</p-scrollPanel>
				<div class="ui-g-12 no-padding text-right button-set">
					<a class="text-link theme-color secondary-action-button"
						(click)="cancelAction()">
						Cancel
					</a>
					<button
						pButton
						label="Confirm Delete"
						class="background-color-red no-margin-right"
						(click)="deleteReport()">
					</button>
				</div>
			</div>
		</ng-template>
	</ng-template>
</div>