<div class="context-menu"
	[ngClass]="{
		'collapsed': !active
	}"
	(click)="expansionButtonClick()"
	clickOutside
	(clickOutside)="closeOverlays()"
	[contentDisplayed]="active">

	<div class="context-menu-expand-icon-container"
		(click)="toggleExpansionButtonClick($event)">
		<a class="fa fa-angle-left"
			[ngClass]="{
				'fa-angle-right': !active,
				'fa-angle-left': active
			}">
		</a>
	</div>

	<div class="context-menu-container">
		<p-scrollPanel>
			<div class="context-menu-scroll-content">
				<div class="context-menu-topbar">
					<img src="assets/layout/images/company-logo.png"
						alt="Company logo"
						class="topbar-logo" />
				</div>

				<form [formGroup]="formGroup"
					*ngIf="displayFilter === true">
					<div class="context-menu-filter-container ui-g-12">
						<input
							type="search"
							pInputText
							id="contextMenuFilterControl"
							formControlName="contextMenuFilterControl"
							class="context-menu-filter"
							placeholder="Filter"
							(input)="filterControlChanged()" />
					</div>
				</form>

				<app-slide-menu
					[reset]="reset"
					[filter]="menuFilter"
					[pageContext]="pageContext"
					[pageContextOperationGroupName]="pageContextOperationGroupName"
					(filterChange)="filterChange($event)"
					(stepIndexChange)="stepIndexChange($event)">
				</app-slide-menu>
			</div>
		</p-scrollPanel>
	</div>

</div>
