/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	Output
} from '@angular/core';
import {
	DisplayAnimation,
	EaseAnimation
} from '@shared/app-animations';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	SelectItem
} from 'primeng/api';

@Component({
	selector: 'app-quick-filters',
	templateUrl: './quick-filters.component.html',
	styleUrls: ['./quick-filters.component.scss'],
	animations: [
		DisplayAnimation,
		EaseAnimation
	]
})

/**
 * A component representing an instance of the quick filters component.
 *
 * @export
 * @class QuickFiltersComponent
 */
export class QuickFiltersComponent
{
	/**
	 * Gets or sets the set of available quick filters for this object
	 * list display.
	 *
	 * @type {SelectItem[]}
	 * @memberof QuickFiltersComponent
	 */
	@Input() public filters: SelectItem[] = [];

	/**
	 * Gets or sets the selected quick filter value for this object list.
	 *
	 * @type {string | number | boolean}
	 * @memberof QuickFiltersComponent
	 */
	@Input() public selectedFilterValue: string | number | boolean = null;

	/**
	 * Gets or sets the event to emit to listening components signifying
	 * that a filter display has altered. This will send the value of the
	 * displayed or hidden filter.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof QuickFiltersComponent
	 */
	@Output() public quickFilterDisplayedEvent:
		EventEmitter<boolean> = new EventEmitter();

	/**
	 * Gets or sets the event to emit to listening components signifying
	 * that a filter value was selected. This will send the value of the
	 * selected filter.
	 *
	 * @type {EventEmitter<string | number | boolean>}
	 * @memberof QuickFiltersComponent
	 */
	@Output() public quickFilterSelectedEvent:
		EventEmitter<string | number | boolean> = new EventEmitter();

	/**
	 * Gets or sets the display value of the quick filter list.
	 *
	 * @type {boolean}
	 * @memberof QuickFiltersComponent
	 */
	public filterListActive: boolean = false;

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables
	 * or loading has completed.
	 *
	 * @memberof QuickFiltersComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.closeList();
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close this list when an associated menu is
	 * opened.
	 *
	 * @memberof QuickFiltersComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(
		_controlIdentifer: string): void
	{
		if (this.filterListActive === true)
		{
			this.closeList();
		}
	}

	/**
	 * Handles the filter item selected event. This will call the event
	 * emitter and output the selected filter value.
	 *
	 * @memberof QuickFiltersComponent
	 */
	public quickFilterSelected(): void
	{
		this.quickFilterSelectedEvent.emit(
			this.selectedFilterValue);
		this.filterListActive = false;
		this.quickFilterDisplayedEvent.emit(this.filterListActive);
	}

	/**
	 * Handles the filter list icon click, this will open or close the
	 * filter list display and unselect any current selection to allow
	 * the next selection event to propogate.
	 *
	 * @memberof QuickFiltersComponent
	 */
	public toggleFilterListDisplay(): void
	{
		this.filterListActive = !this.filterListActive;
		this.quickFilterDisplayedEvent.emit(this.filterListActive);
	}

	/**
	 * Handles the click outside event. This is used to close this filter
	 * list when clicking elsewhere on the page similar to an overlay.
	 *
	 * @memberof QuickFiltersComponent
	 */
	public closeList(): void
	{
		this.selectedFilterValue = null;
		this.filterListActive = false;
		this.quickFilterDisplayedEvent.emit(false);
	}
}