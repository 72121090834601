/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';
import {
	IDisplayComponentType
} from '@shared/interfaces/display-components/display-component-type.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-display-definitions',
	templateUrl: './display-definitions.component.html',
	styleUrls: [
		'./display-definitions.component.scss'
	]
})

/**
 * A component representing an instance of the display component definitions
 * component.
 *
 * @export
 * @class DisplayDefinitionsComponent
 * @extends {CommonTablePageDirective}
 */
export class DisplayDefinitionsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the DisplayDefinitionsComponent class.
	 *
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The api service used to load display component definition data.
	 * @param {DisplayComponentTypeApiService} displayComponentTypeApiService
	 * The api service used to load display component type data.
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The api service used to load display component instance data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof DisplayDefinitionsComponent
	 */
	public constructor(
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentTypeApiService: DisplayComponentTypeApiService,
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof DisplayDefinitionsComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the key for a nested display component type name value.
	 *
	 * @type {string}
	 * @memberof DisplayDefinitionsComponent
	 */
	private readonly displayTypeNameKey: string =
		'displayType.name';

	/**
	 * Gets or sets the key for a nested display component type name value.
	 *
	 * @type {string}
	 * @memberof DisplayDefinitionsComponent
	 */
	private readonly displayTypeDescriptionKey: string =
		'displayType.description';

	/**
	 * Gets or sets the key for a nested display component type public value.
	 *
	 * @type {string}
	 * @memberof DisplayDefinitionsComponent
	 */
	private readonly displayTypePublicKey: string =
		'displayType.public';

	/**
	 * Gets or sets the key for a nested display component type public value.
	 *
	 * @type {string}
	 * @memberof DisplayDefinitionsComponent
	 */
	private readonly displayTypeOwnershipSecurityGroupKey: string =
		'displayType.ownershipSecurityGroupId';

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof DisplayDefinitionsComponent
	 */
	public setupPageVariables(): void
	{
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: 1
				},
				{
					dataKey: this.displayTypeNameKey,
					columnHeader: 'Component Type',
					displayOrder: 2
				},
				{
					dataKey: 'componentName',
					columnHeader: 'Component Name',
					displayOrder: 3
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @async
	 * @memberof DisplayDefinitionsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions();

		this.tableDefinitions =
			{
				tableTitle: 'Definitions',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Display Component Definition'),
				objectSearch: {
					filter: AppConstants.empty,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						let displayDefinitions: any[] = [];
						const apiDefinitions: IDisplayComponentDefinition[] =
							await this.displayComponentDefinitionApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						const promiseArray: Promise<any>[] = [];
						for (const apiDefinition of apiDefinitions)
						{
							promiseArray.push(
								this.decorateViewModel(
									apiDefinition));
						}

						await Promise.all(promiseArray)
							.then(
								(decoratedDefinitions: any[]) =>
								{
									displayDefinitions = decoratedDefinitions;
								});

						return displayDefinitions;
					},
				decorateViewModel: async(model: any) =>
					this.decorateViewModel(model),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					create: {
						layout: [
							{
								key: this.displayTypeNameKey,
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Type',
									required: true
								},
								validators: {
									alphaNumeric: {
										expression:
											(control: UntypedFormControl) =>
												/^[a-zA-Z0-9.]*$/.test(
													control.value),
										message:
											'Must contain letters, '
												+ 'numbers or periods only.'
									}
								},
								asyncValidators: {
									uniqueType: {
										expression:
											(control: UntypedFormControl) =>
												this.isExistingType(
													control.value),
										message: 'Existing Type.'
									}
								}
							},
							{
								key: 'componentName',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Component Name'
								}
							},
							{
								...CommonFormlyFieldConstants
									.publicField,
								key: this.displayTypePublicKey,
								props: {
									...CommonFormlyFieldConstants
										.publicField
										.props,
									label: 'Type '
										+ CommonFormlyFieldConstants
											.publicField
											.props
											.label
								}
							},
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								key: this.displayTypeOwnershipSecurityGroupKey,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									options: securityGroupOptions,
									label: 'Type '
										+ CommonFormlyFieldConstants
											.ownershipSecurityGroupField
											.props
											.label
								}
							},
							{
								...CommonFormlyFieldConstants
									.publicField,
								props: {
									...CommonFormlyFieldConstants
										.publicField
										.props,
									label: 'Definition '
										+ CommonFormlyFieldConstants
											.publicField
											.props
											.label
								}
							},
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									options: securityGroupOptions,
									label: 'Definition '
										+ CommonFormlyFieldConstants
											.ownershipSecurityGroupField
											.props
											.label
								}
							},
							{
								key: this.displayTypeDescriptionKey,
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Description',
									required: true
								}
							},
							{
								key: 'jsonData',
								type: FormlyConstants
									.customControls.customTextArea,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Attributes',
									rows: FormlyConstants
										.textAreaRowSizes.standard,
									required: true
								},
								validators: {
									validJson: {
										expression: (
											control: UntypedFormControl,
											field: FormlyFieldConfig) =>
											this.isValidJson(control, field),
										message:
											AppConstants.empty
									}
								}
							}
						],
						items: [
							{
								label: 'Create',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.createDisplayDefinition()
							}]
					},
					view: {
						layout: [
							{
								key: this.displayTypeNameKey,
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Type',
									disabled: true
								}
							},
							{
								key: 'componentName',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Component Name',
									disabled: true
								}
							},
							{
								...CommonFormlyFieldConstants
									.publicField,
								key: this.displayTypePublicKey,
								props: {
									...CommonFormlyFieldConstants
										.publicField
										.props,
									disabled: true,
									label: 'Type '
										+ CommonFormlyFieldConstants
											.publicField
											.props
											.label
								}
							},
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								key: this.displayTypeOwnershipSecurityGroupKey,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									options: securityGroupOptions,
									disabled: true,
									label: 'Type '
										+ CommonFormlyFieldConstants
											.ownershipSecurityGroupField
											.props
											.label
								}
							},
							{
								...CommonFormlyFieldConstants
									.publicField,
								props: {
									...CommonFormlyFieldConstants
										.publicField
										.props,
									disabled: true,
									label: 'Definition '
										+ CommonFormlyFieldConstants
											.publicField
											.props
											.label
								}
							},
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									options: securityGroupOptions,
									disabled: true,
									label: 'Definition '
										+ CommonFormlyFieldConstants
											.ownershipSecurityGroupField
											.props
											.label
								}
							},
							{
								key: this.displayTypeDescriptionKey,
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Description',
									disabled: true
								}
							},
							{
								key: 'jsonData',
								type: FormlyConstants.customControls
									.customTextDisplay,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Attributes',
									useCodeBlock: true,
									codeBlockType:
										AppConstants.markdownLanguages.json,
									content: AppConstants.empty
								},
								expressions: {
									'props.content':
										'`${model.jsonData}`'
								}
							}
						],
						items: []
					},
					update: {
						layout: [
							{
								key: this.displayTypeNameKey,
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Type',
									required: true
								},
								validators: {
									alphaNumeric: {
										expression:
											(control: UntypedFormControl) =>
												/^[a-zA-Z0-9.]*$/.test(
													control.value),
										message:
											'Must contain letters, numbers or '
												+ 'periods only.'
									}
								},
								asyncValidators: {
									uniqueType: {
										expression:
											(control: UntypedFormControl) =>
												this.allowToUpdateType(
													control.value),
										message: 'Existing Type.'
									}
								}
							},
							{
								key: 'componentName',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Component Name'
								}
							},
							{
								...CommonFormlyFieldConstants
									.publicField,
								key: this.displayTypePublicKey,
								props: {
									...CommonFormlyFieldConstants
										.publicField
										.props,
									label: 'Type '
										+ CommonFormlyFieldConstants
											.publicField
											.props
											.label
								}
							},
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								key: this.displayTypeOwnershipSecurityGroupKey,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									options: securityGroupOptions,
									label: 'Type '
										+ CommonFormlyFieldConstants
											.ownershipSecurityGroupField
											.props
											.label
								}
							},
							{
								...CommonFormlyFieldConstants
									.publicField,
								props: {
									...CommonFormlyFieldConstants
										.publicField
										.props,
									label: 'Definition '
										+ CommonFormlyFieldConstants
											.publicField
											.props
											.label
								}
							},
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									options: securityGroupOptions,
									label: 'Definition '
										+ CommonFormlyFieldConstants
											.ownershipSecurityGroupField
											.props
											.label
								}
							},
							{
								key: this.displayTypeDescriptionKey,
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Description',
									required: true
								}
							},
							{
								key: 'jsonData',
								type: FormlyConstants
									.customControls.customTextArea,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Attributes',
									rows: FormlyConstants
										.textAreaRowSizes.standard,
									required: true
								},
								validators: {
									validJson: {
										expression: (
											control: UntypedFormControl,
											field: FormlyFieldConfig) =>
											this.isValidJson(control, field),
										message:
											AppConstants.empty
									}
								}
							}
						],
						items: [
							{
								label: 'Save',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.updateDisplayDefinition()
							}]
					},
					delete: {
						items: [
							{
								label: 'Confirm',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								disabled: false,
								command: () => this.deleteDisplayDefinition()
							}],
						deleteStatement: () => this.getDeleteStatement(),
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Decorates the view model for the display component definition object.
	 *
	 * @param {IDisplayComponentDefinition} model
	 * The display component definition object to be decorated as a view model.
	 * @returns {Promise<any>}
	 * The mapped view model for display in this component.
	 * @memberof DisplayDefinitionsComponent
	 */
	public async decorateViewModel(
		model: IDisplayComponentDefinition): Promise<any>
	{
		return <IDisplayComponentDefinition>
			{
				...model,
				displayType:
					await this.displayComponentTypeApiService
						.get(model.typeId)
			};
	}

	/**
	 * Creates the display definition.
	 *
	 * @async
	 * @memberof DisplayDefinitionsComponent
	 */
	private async createDisplayDefinition(): Promise<void>
	{
		const selectedItem: any = this.commonTableContext.source.selectedItem;

		const displayComponentTypeId: number =
			await this.displayComponentTypeApiService
				.create(
					<IDisplayComponentType>
					{
						name: selectedItem.displayType.name,
						description: selectedItem.displayType.description,
						public: selectedItem.displayType.public,
						ownershipSecurityGroupId:
							selectedItem.displayType.ownershipSecurityGroupId
					});

		const displayComponentDefinitionData: IDisplayComponentDefinition =
			<IDisplayComponentDefinition>
			{
				componentDefinition: selectedItem.componentDefinition,
				componentName: selectedItem.componentName,
				jsonData: selectedItem.jsonData,
				typeId: displayComponentTypeId,
				public: selectedItem.public,
				ownershipSecurityGroupId:
					selectedItem.ownershipSecurityGroupId
			};

		const createDisplayComponentDefinition: () => Promise<void> =
			async () =>
			{
				selectedItem.id =
					await this.displayComponentDefinitionApiService
						.create(
							displayComponentDefinitionData);

				this.commonTableContext.source.selectedItem =
					await this.displayComponentDefinitionApiService
						.get(selectedItem.id);

				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				createDisplayComponentDefinition(),
				'<strong>Creating Display Definition</strong>',
				'<strong>Created Display Definition</strong>',
				`Display Definition
					${this.commonTableContext.source.selectedItem.componentName}
					was successfully created.`,
				`Display Definition
					${this.commonTableContext.source.selectedItem.componentName}
					was not created.`));
	}

	/**
	 * Updates the display definition.
	 *
	 * @async
	 * @memberof DisplayDefinitionsComponent
	 */
	private async updateDisplayDefinition(): Promise<void>
	{
		const updateDisplayComponentDefinition: () => Promise<void> =
			async () =>
			{
				const selectedItem: any =
					this.commonTableContext.source.selectedItem;

				const displayComponentType: IDisplayComponentType =
					<IDisplayComponentType>
					{
						id: selectedItem.displayType.id,
						name: selectedItem.displayType.name,
						description: selectedItem.displayType.description,
						public: selectedItem.displayType.public,
						ownershipSecurityGroupId:
							selectedItem.displayType.ownershipSecurityGroupId,
						createdById: selectedItem.displayType.createdById
					};

				await this.displayComponentTypeApiService
					.update(
						selectedItem.displayType.id,
						displayComponentType);

				const displayComponentDefinition: IDisplayComponentDefinition =
					<IDisplayComponentDefinition>
					{
						id: selectedItem.id,
						componentName: selectedItem.componentName,
						componentDefinition: selectedItem.componentDefinition,
						jsonData: selectedItem.jsonData,
						typeId: selectedItem.typeId,
						public: selectedItem.public,
						ownershipSecurityGroupId:
							selectedItem.ownershipSecurityGroupId,
						createdById: selectedItem.createdById
					};

				await this.displayComponentDefinitionApiService
					.update(
						selectedItem.id,
						displayComponentDefinition);

				await this.commonTableContext.source.updateSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				updateDisplayComponentDefinition(),
				'<strong>Updating the Display Definition</strong>',
				'<strong>Updated the Display Definition</strong>',
				`Display Definition
					${this.commonTableContext.source.selectedItem.componentName}
					was successfully updated.`,
				`Display Definition
					${this.commonTableContext.source.selectedItem.componentName}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Gets the delete statement.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * The delete statement string.
	 * @memberof DisplayDefinitionsComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		const selectedItem: any = this.commonTableContext.source.selectedItem;
		const displayComponentInstanceRelationships:
			IDisplayComponentInstance[] =
			<IDisplayComponentInstance[]>
			await this.displayComponentInstanceApiService
				.query(
					`DefinitionId eq ${selectedItem.id}`,
					AppConstants.empty);

		this.tableDefinitions.actions.delete.items[0].disabled
			= displayComponentInstanceRelationships.length > 0;

		return displayComponentInstanceRelationships.length > 0
			? `Unable to delete Display Component Definition
				${selectedItem.id}
				${selectedItem.componentName}
				due to the existence of instances.`
			: `Confirm you are about to delete Display Component Definition
				${selectedItem.id}
				${selectedItem.componentName}.`;
	}

	/**
	 * Deletes display definition.
	 *
	 * @async
	 * @memberof DisplayDefinitionsComponent
	 */
	private async deleteDisplayDefinition(): Promise<void>
	{
		const deleteDisplayComponentDefinition: () => Promise<void> =
			async () =>
			{
				await this.displayComponentDefinitionApiService
					.delete(this.commonTableContext.source.selectedItem.id);

				await this.displayComponentTypeApiService
					.delete(this.commonTableContext.source.selectedItem.typeId);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteDisplayComponentDefinition(),
				'<strong>Deleting the Display Definition</strong>',
				'<strong>Deleted the Display Definition</strong>',
				`Display Definition
					${this.commonTableContext.source.selectedItem.componentName}
					was successfully deleted.`,
				`Display Definition
					${this.commonTableContext.source.selectedItem.componentName}
					was not deleted.`));
	}

	/**
	 * Defines and validates if the type is existing or not.
	 *
	 * @async
	 * @param {string} value
	 * The type value.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof DisplayDefinitionsComponent
	 */
	private async isExistingType(value: string): Promise<boolean>
	{
		const displayType =
			await this.displayComponentTypeApiService
				.query(
					`Name.ToLower() eq '${value.toLowerCase()}'`,
					AppConstants.empty);

		return Promise.resolve(displayType.length === 0);
	}

	/**
	 * Defines and validates if the updated value is existing or not.
	 *
	 * @async
	 * @param {string} value
	 * The type value.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof DisplayDefinitionsComponent
	 */
	private async allowToUpdateType(value: string): Promise<boolean>
	{
		let allowedToUpdate: boolean = true;
		const persistedGroup =
				await this.displayComponentTypeApiService
					.get(this.commonTableContext.source.selectedItem.typeId);

		if (persistedGroup.name !== value)
		{
			const displayComponentType =
				await this.displayComponentTypeApiService
					.query(
						`Name.ToLower() eq '${value.toLowerCase()}'`,
						AppConstants.empty);

			allowedToUpdate = displayComponentType.length <= 0;
		}

		return Promise.resolve(allowedToUpdate);
	}

	/**
	 * Checks if value is a valid JSON format.
	 *
	 * @param {FormControl} control
	 * The form contrl.
	 * @returns {FormlyFieldConfig}
	 * The formly field config.
	 * @memberof DisplayDefinitionsComponent
	 */
	private isValidJson(
		control: UntypedFormControl,
		field: FormlyFieldConfig): boolean
	{
		let isValidJson: boolean = true;

		try
		{
			JSON.parse(control.value);
		}
		catch
		{
			isValidJson = false;
			field.validators.validJson.message =
				'Not a valid JSON.';
		}

		return isValidJson;
	}
}