/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */

import {
	Directive
} from '@angular/core';
import {
	BaseOperationMenuDisplayDirective
} from '@operation/directives/base-operation-menu-display.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

/**
 * A base class used to handle session based user
 * interaction.
 *
 * @export
 * @class BaseSessionUserDirective
 * @extends {BaseOperationMenuDisplayDirective}
 */
@Directive()
export class BaseSessionUserDirective
	extends BaseOperationMenuDisplayDirective
{
	/**
	 * Creates an instance of a BaseSessionUserDirective.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layouts service to use for responsive layouts.
	 * @param {SessionService} sessionService
	 * The session service to use for profile menu based dynamic displays.
	 * @memberof BaseSessionUserDirective
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		private readonly sessionService: SessionService)
	{
		super(siteLayoutService);
	}

	/**
	 * Gets the user used in this session user display.
	 *
	 * @returns {IUser | null} The session service
	 * user displayed in this profile.
	 * @memberof BaseSessionUserDirective
	 */
	public get user(): IUser | null
	{
		return this.sessionService.user;
	}

	/**
	 * Gets the user email address MD5 hash for this session user.
	 *
	 * @readonly
	 * @type {string}
	 * @returns {string} A MD5 hash representing the user email.
	 * @memberof BaseSessionUserDirective
	 */
	public get userEmailHash(): string
	{
		return StringHelper.getHash(
			StringHelper.trim(
				this.user.data.email)
				.toLowerCase());
	}

	/**
	 * Gets the user display name for this session
	 * user display.
	 *
	 * @returns {string} The session service
	 * user display name for this profile.
	 * @memberof BaseSessionUserDirective
	 */
	public get userDisplayName(): string
	{
		if (this.user.data.firstName)
		{
			return this.user.data.firstName
				+ ' '
				+ this.user.data.lastName;
		}
		else
		{
			return this.user.data.userName;
		}
	}

	/**
	 * Gets the user display title for this profile
	 * menu display.
	 *
	 * @returns {string} The session service
	 * user display title for this profile.
	 * @memberof BaseSessionUserDirective
	 */
	public get userTitle(): string
	{
		if (this.user.membershipSecurityGroups == null)
		{
			return AppConstants.empty;
		}

		const mappedSecurityGroups: string[] =
			this.user.membershipSecurityGroups
				.map((securityGroup: ISecurityGroup) =>
					securityGroup.name);

		if (mappedSecurityGroups
			.indexOf(AppConstants.securityGroups.administrators) !== -1)
		{
			return AppConstants.securityGroups.administratorDisplay;
		}
		else if (mappedSecurityGroups
			.indexOf(AppConstants.securityGroups.supportUsers) !== -1)
		{
			return AppConstants.securityGroups.supportUserDisplay;
		}
		else
		{
			return AppConstants.securityGroups.userDisplay;
		}
	}
}
