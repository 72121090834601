/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit,
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	IEntityRelationshipGroup
} from '@entity/interfaces/entity-relationship-group.interface';
import {
	IEntitySearch
} from '@entity/interfaces/entity-search.interface';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	ISelectable
} from '@shared/interfaces/application-objects/selectable.interface';
import {
	IWizardStepMenuItem
} from '@shared/interfaces/application-objects/wizard-step-menu-item.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-parent-selection',
	templateUrl: './parent-selection.component.html',
	styleUrls: [
		'./parent-selection.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an entity parent selection wizard step.
 *
 * @export
 * @class ParentSelectionComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class ParentSelectionComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the parent selection component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition api service used in this component.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @memberof ParentSelectionComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof ParentSelectionComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the loading value of this table display.
	 *
	 * @type {boolean}
	 * @memberof ParentSelectionComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the selected entity type for creation.
	 *
	 * @type {IEntityType}
	 * @memberof ParentSelectionComponent
	 */
	public entityCreationType: IEntityType;

	/**
	 * Gets or sets the entity definition of the selected entity type.
	 *
	 * @type {EntityDefinition}
	 * @memberof ParentSelectionComponent
	 */
	public entityCreationDefinition: EntityDefinition;

	/**
	 * Gets or sets the entity search related with this wizard
	 * step if applicable.
	 *
	 * @type {IEntitySearch}
	 * @memberof BaseEntityCreationStep
	 */
	public entitySearch: IEntitySearch;

	/**
	 * Gets or sets the parent required value of this
	 * wizard step.
	 *
	 * @type {boolean}
	 * @memberof ParentSelectionComponent
	 */
	public parentRequired: boolean = false;

	/**
	 * Gets or sets the list of parent entity definitions that
	 * can be set for the entity creation type.
	 *
	 * @type {EntityDefinition[]}
	 * @memberof ParentSelectionComponent
	 */
	public allowedParents: EntityDefinition[] = [];

	/**
	 * Gets or sets the list of parent entity type associations that
	 * can or have been set via this wizard step.
	 *
	 * @type {IEntityRelationshipGroup[]}
	 * @memberof ParentSelectionComponent
	 */
	public parentGroups: IEntityRelationshipGroup[] = [];

	/**
	 * Gets the current parent group displayed in this wizard
	 * step. This can be altered via changing the parent entity
	 * type.
	 *
	 * @type {IEntityRelationshipGroup}
	 * @memberof ParentSelectionComponent
	 */
	public get currentParentGroup(): IEntityRelationshipGroup
	{
		return this.parentGroups.filter(
			(entityParentGroup: IEntityRelationshipGroup) =>
				entityParentGroup.typeGroup === this.entitySearch.group)[0];
	}

	/**
	 * Gets a truthy that identifies if a parent selection has been made.
	 *
	 * @type {boolean}
	 * @memberof ParentSelectionComponent
	 */
	public get parentSelectionExists(): boolean
	{
		return this.parentGroups.filter(
			(parentGroup: IEntityRelationshipGroup) =>
				parentGroup.relatedEntities.length > 0).length > 0;
	}

	/**
	 * Gets the number of parent items to display in the list.
	 *
	 * @type {number}
	 * @memberof ParentSelectionComponent
	 */
	private readonly listSize: number = 5;

	/**
	 * Gets the number of parent items to load per api call.
	 *
	 * @type {number}
	 * @memberof ParentSelectionComponent
	 */
	private readonly limit: number = 15;

	/**
	 * Gets the identifier of the entity type for creation sent to this step.
	 *
	 * @type {string}
	 * @memberof ParentSelectionComponent
	 */
	private readonly entityCreationTypeIdentifier: string =
		'entityCreationType';

	/**
	 * Implements the on initialization interface.
	 * This method is used to calculate and handle available parent selections
	 * for an entity with a selected entity type id.
	 *
	 * @memberof ParentSelectionComponent
	 */
	public ngOnInit(): void
	{
		this.entityCreationType =
			this.context.source.activeMenuItem
				.currentData.data[this.entityCreationTypeIdentifier];

		this.context.source
			.addToValidator(
				this.validateStep.bind(this));
		this.context.source
			.addToNext(
				this.createEntity.bind(this));

		this.parentGroups =
				AnyHelper.isNullOrEmpty(
					this.context.source.activeMenuItem
						.currentData.data.parentSelections)
					? []
					: this.mapParentGroupsFromUrlStorage(
						this.context.source.activeMenuItem
							.currentData.data.parentSelections);

		this.loadStepData();
	}

	/**
	 * Handles the selected category changed event sent from the entity-select.
	 *
	 * @param {string} selectedCategory
	 * The selected category of the entity-select.
	 * @memberof ParentSelectionComponent
	 */
	public categorySelected(
		selectedCategory: string): void
	{
		this.entitySearch.group = selectedCategory;
	}

	/**
	 * Sets the parent selection sent from the parent association
	 * list.
	 *
	 * @param {ISelectable} parentSelection
	 * The most up to date search criteria as defined by the UI.
	 * @memberof ParentSelectionComponent
	 */
	public parentSelected(
		parentSelection: ISelectable): void
	{
		const existingIndex: number =
			this.currentParentGroup.relatedEntities
				.findIndex(
					(relatedEntity: any) =>
						relatedEntity.id === parentSelection.id);

		if (parentSelection.selected === true
			&& existingIndex === AppConstants.negativeIndex)
		{
			this.currentParentGroup.relatedEntities =
				<IEntityInstance[]>
				[
					...this.currentParentGroup.relatedEntities,
					<IEntityInstance>parentSelection
				];
		}
		else if (existingIndex !== AppConstants.negativeIndex)
		{
			this.currentParentGroup
				.relatedEntities.splice(
					existingIndex,
					1);
		}

		this.currentParentGroup.relatedEntities =
			<IEntityInstance[]>
			[
				...this.currentParentGroup.relatedEntities
			];

		this.context.source.addOrUpdateStepData(
			<object>
			{
				...this.context.source.activeMenuItem.currentData.data,
				parentSelections: this.getParentGroupsForUrlStorage()
			});
		this.context.source.storeData(
			this.context.source.activeMenuItem.currentData);

		this.context.source.isValid =
			this.context.source.activeWizardStep.validator();
	}

	/**
	 * Loads step specific data.
	 *
	 * @async
	 * @memberof ParentSelectionComponent
	 */
	private async loadStepData(): Promise<void>
	{
		const creationEntityDefinitionData: IEntityDefinition =
			await this.entityDefinitionApiService.getSingleQueryResult(
				`${AppConstants.commonProperties.typeId} eq `
					+ `${this.entityCreationType.id}`,
				`${AppConstants.commonProperties.versionId} desc`);
		this.entityCreationDefinition =
			new EntityDefinition(creationEntityDefinitionData);

		this.parentRequired =
			this.entityCreationDefinition.requiresParent;
		this.context.data.label =
			`Create ${this.entityCreationDefinition.displayTitle}`;
		this.context.source.activeMenuItem.label =
			`${this.entityCreationDefinition.displayTitle} Settings`;
		this.context.data.displayedWizardMenuItems =
			<IWizardStepMenuItem[]>
			[
				...this.context.data.displayedWizardMenuItems
			];

		this.allowedParents =
			await this.getAllowedParentDefinitions();

		if (this.allowedParents.length === 0)
		{
			this.parentGroups = [];
			this.context.source.validStepChanged(
				this.context.source.activeWizardStep.validator());
			this.context.source.wizardStepLoading = false;

			return;
		}

		const entityTypes: IEntityType[] =
			await this.getAllowedParentEntityTypes();
		this.mapParentGroups(entityTypes);

		const entityTypeCategories: string =
			ObjectArrayHelper.commaSeparatedPropertyValues(
				entityTypes,
				AppConstants.commonProperties.group);
		this.entitySearch =
			<IEntitySearch>
			{
				category: entityTypeCategories,
				filter: AppConstants.empty,
				group: AppConstants.empty,
				offset: 0,
				limit: this.limit,
				name: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				typeId: 0,
				virtualPageSize: this.listSize
			};

		this.context.source.validStepChanged(
			this.context.source.activeWizardStep.validator());
		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Validates step specific variables.
	 *
	 * @returns {boolean}
	 * A truthy representing the validity of this step.
	 * @memberof ParentSelectionComponent
	 */
	private validateStep(): boolean
	{
		return this.parentRequired === false
			|| (this.parentRequired === true
				&& this.parentSelectionExists === true);
	}

	/**
	 * This will send the entity creation event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof ParentSelectionComponent
	 */
	private async createEntity(): Promise<void>
	{
		setTimeout(() =>
		{
			this.context.source.wizardStepLoading = true;
		});

		const displayName: string =
			new EntityType(this.entityCreationType)
				.displayName;
		const newEntityId: number =
			await this.activityService.handleActivity<number>(
				new Activity<number>(
					this.createEntityInstance(),
					`<strong>Creating</strong> ${displayName}`,
					`<strong>Created</strong> ${displayName}`,
					`${displayName} was created.`,
					`${displayName} was not created.`));

		this.moduleService.name =
			await this.entityService.getContextMenuModule(
				this.entityCreationType.name);

		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});
		this.context.source.storeData(
			this.context.source.activeMenuItem.currentData);

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				this.entityCreationType.group,
				AppConstants.viewTypes.edit,
				newEntityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Gathers parent selection information for the entity creation
	 * based on the available url parameters. This will parse the URL stored
	 * object and create or load existing groups depending on business rules.
	 *
	 * @param {{typeGroup: string,parentIds: number[]}[]} parentSelections
	 * The object holding data for current entity creation type parent
	 * selections.
	 * @returns {IEntityRelationshipGroup[]}
	 * The relationship groups associated and set from the url parameter to this
	 * entity type creation.
	 * @memberof ParentSelectionComponent
	 */
	private mapParentGroupsFromUrlStorage(
		parentSelections:
			{
				typeGroup: string;
				parentIds: number[];
			}[]): IEntityRelationshipGroup[]
	{
		const parentGroups =
			this.parentGroups || [];

		parentSelections.forEach(
			(parentSelection:
				{
					typeGroup: string;
					parentIds: number[];
				}) =>
			{
				const existingParentGroups =
					parentGroups.filter(
						(relationshipGroup: IEntityRelationshipGroup) =>
							relationshipGroup.typeGroup
								.indexOf(parentSelection.typeGroup) !== -1);
				const existingParentGroup: IEntityRelationshipGroup =
					existingParentGroups.length === 0
						? null
						: existingParentGroups[0];

				if (existingParentGroup == null)
				{
					const parentEntities: IEntityInstance[] = [];
					parentSelection.parentIds.forEach((id: number) =>
					{
						parentEntities.push(
							<IEntityInstance>
							{
								id: id
							});
					});

					parentGroups.push(
						<IEntityRelationshipGroup>
						{
							typeGroup: parentSelection.typeGroup,
							relatedEntities: parentEntities
						});
				}
			});

		return parentGroups;
	}

	/**
	 * Parses the existing parent type selections and stores this data in a
	 * compressed data format allowing for smaller url storage.
	 *
	 * @returns {{typeGroup: string,parentIds: number[]}[]}
	 * The object data required to store and load existing parent selections via
	 * the url.
	 * @memberof ParentSelectionComponent
	 */
	private getParentGroupsForUrlStorage(): {
			typeGroup: string;
			parentIds: number[];
		}[]
	{
		let parentTypeArray: number[] = [];
		const parentArray: {
				typeGroup: string;
				parentIds: number[];
			}[] = [];

		this.parentGroups.forEach(
			(relationshipGroup: IEntityRelationshipGroup) =>
			{
				parentTypeArray = [];

				relationshipGroup.relatedEntities.forEach(
					(entityInstance: IEntityInstance) =>
					{
						parentTypeArray.push(entityInstance.id);
					});

				parentArray.push(
					{
						typeGroup: relationshipGroup.typeGroup,
						parentIds: parentTypeArray
					});
			});

		return parentArray;
	}

	/**
	 * Uses a wildcard match for all allowed parents to load the allowed
	 * parent definitions.
	 *
	 * @async
	 * @returns {EntityDefinition[]}
	 * The set of allowed parent definitions for the selected entity creation
	 * type.
	 * @memberof ParentSelectionComponent
	 */
	private async getAllowedParentDefinitions(): Promise<EntityDefinition[]>
	{
		const wildcardFilter: string =
			this.createAllowedParentsWildcardFilter();
		const allowedParentData: IEntityDefinition[] =
			await this.entityDefinitionApiService.query(
				wildcardFilter,
				AppConstants.empty);

		return allowedParentData
			.map((data: IEntityDefinition) =>
				new EntityDefinition(data));
	}

	/**
	 * Uses a set of matching allowed parent type ids to find the set of
	 * allowed parent entity types for entity creation.
	 *
	 * @async
	 * @returns {IEntityType[]}
	 * The set of allowed parent types for the selected entity creation
	 * type.
	 * @memberof ParentSelectionComponent
	 */
	private async getAllowedParentEntityTypes(): Promise<IEntityType[]>
	{
		const commaSeparatedTypeIdArray: string =
			ObjectArrayHelper.commaSeparatedPropertyValues(
				this.allowedParents,
				AppConstants.commonProperties.typeId);

		return this.entityTypeApiService
			.query(
				`(${AppConstants.commonProperties.id} IN `
					+ `(${commaSeparatedTypeIdArray}))`,
				`${AppConstants.commonProperties.name} desc`);
	}

	/**
	 * Maps the available selected parent groups from existing or new
	 * selections.
	 *
	 * @param {IEntityType[]} entityTypes
	 * The set allowed parent types and child selections  to map into the
	 * parent groups value.
	 * @memberof ParentSelectionComponent
	 */
	private mapParentGroups(
		entityTypes: IEntityType[]): void
	{
		entityTypes.forEach(
			async(entityType: IEntityType) =>
			{
				let existingEntities: IEntityInstance[] = [];
				const entityTypeParentGroup: IEntityRelationshipGroup[] =
					this.parentGroups.filter(
						(parentGroup: IEntityRelationshipGroup) =>
							parentGroup.typeGroup === entityType.group);

				if (entityTypeParentGroup.length > 0)
				{
					existingEntities =
						entityTypeParentGroup[0].relatedEntities.filter(
							(entityInstance: IEntityInstance) =>
								!AnyHelper.isNullOrEmpty(
									entityInstance?.data));
					const requiredEntityInstanceData: IEntityInstance[] =
						entityTypeParentGroup[0].relatedEntities.filter(
							(entityInstance: IEntityInstance) =>
								AnyHelper.isNullOrEmpty(
									entityInstance?.data));

					if (requiredEntityInstanceData.length > 0)
					{
						const commaSeparatedInstanceIds: string =
							ObjectArrayHelper.commaSeparatedPropertyValues(
								requiredEntityInstanceData,
								AppConstants.commonProperties.id);
						this.entityInstanceApiService.entityInstanceTypeGroup =
							entityType.group;
						const requiredLoadEntities: IEntityInstance[] =
							await this.entityInstanceApiService.query(
								`(${AppConstants.commonProperties.id} IN `
									+ `(${commaSeparatedInstanceIds}))`,
								`${AppConstants.commonProperties.id} desc`);

						existingEntities =
							existingEntities
								.concat(requiredLoadEntities);
					}
				}

				const existingIndex =
					this.parentGroups.indexOf(entityTypeParentGroup[0]);
				if (existingIndex > AppConstants.negativeIndex)
				{
					this.parentGroups
						.splice(
							existingIndex,
							1);
				}

				this.parentGroups.push(
					<IEntityRelationshipGroup>
					{
						relatedEntities: existingEntities,
						typeGroup: entityType.group,
						entityDefinition:
							this.allowedParents.filter(
								(entityDefinition: EntityDefinition) =>
									entityDefinition.typeId ===
										entityType.id)[0]
					});
			});
	}

	/**
	 * Creates a wildcard filter specifically built to find allowed parents
	 * for the existing entity creation type.
	 *
	 * @returns {string}
	 * A filter available that will perform a reverse lookup on entity
	 * definitions that allow this entity creation type as a child.
	 * @memberof ParentSelectionComponent
	 */
	private createAllowedParentsWildcardFilter(): string
	{
		let wildcardFilter: string = AppConstants.empty;
		const wildcardSplit: string[] =
			this.getAllowedParentWildcards();

		for (let index: number = 0; index < wildcardSplit.length; index ++)
		{
			const newFilter: string =
				'DbFunctions.JsonQuery(JsonData, \'$.supportedChildTypes\')'
					+ `.Contains("${wildcardSplit[index]}") eq true`;

			wildcardFilter +=
				index === 0
					? newFilter
					: ` OR ${newFilter}`;
		}

		return wildcardFilter;
	}

	/**
	 * Creates a wildcard array for all possible parent selections
	 * for the existing entity creation type.
	 *
	 * @returns {string}
	 * An array available for definitions that allow this entity creation
	 * type as a child.
	 * @memberof ParentSelectionComponent
	 */
	private getAllowedParentWildcards(): string[]
	{
		let locationFilter: string = AppConstants.empty;
		const wildcards: string[] = [];
		const wildcardSplit: string[] =
			this.entityCreationType.name.split(
				AppConstants.characters.period);

		for (let index: number = 0; index < wildcardSplit.length; index ++)
		{
			const typeSection: string = wildcardSplit[index];
			const currentLocationFilter: string =
				AnyHelper.isNullOrWhitespace(
					locationFilter)
					? typeSection
					: `${locationFilter}.${typeSection}`;
			const currentWildcardFilter: string =
				wildcardSplit.length > index + 1
					? `${currentLocationFilter}.*`
					: currentLocationFilter;

			wildcards.push(currentWildcardFilter);
			locationFilter = currentLocationFilter;
		}

		return wildcards;
	}

	/**
	 * Creates an entity instance and all entity relationships.
	 *
	 * @returns {Promise<number>}
	 * The id of the newly created entity.
	 * @memberof ParentSelectionComponent
	 */
	private async createEntityInstance(): Promise<number>
	{
		let parentAssociationGroups: IEntityRelationshipGroup[] = [];
		if (this.parentSelectionExists)
		{
			parentAssociationGroups =
				this.parentGroups.filter(
					(parentGroup: IEntityRelationshipGroup) =>
						parentGroup.relatedEntities.length > 0);
		}

		let createdEntityId: number;
		let initialEntityCreated = false;
		if (parentAssociationGroups.length > 0)
		{
			for (const entityParentRelationship
				of parentAssociationGroups)
			{
				for (const entityParent
					of entityParentRelationship.relatedEntities)
				{
					if (initialEntityCreated === false)
					{
						this.entityInstanceApiService
							.entityInstanceTypeGroup =
								this.entityCreationType.group;
						createdEntityId =
							await this.entityInstanceApiService
								.createEntityInstance(
									null,
									entityParentRelationship.typeGroup,
									entityParent.id);
						initialEntityCreated = true;
					}
					else
					{
						this.entityInstanceApiService
							.entityInstanceTypeGroup =
								entityParentRelationship.typeGroup;
						await this.entityInstanceApiService
							.assignChild(
								entityParent.id,
								createdEntityId);
					}
				}
			}
		}
		else
		{
			if (this.parentRequired === true)
			{
				throw new Error('A parent association must exist to create '
					+ 'this item. Please select one from the available parent '
					+ 'associations list.');
			}

			this.entityInstanceApiService
				.entityInstanceTypeGroup =
					this.entityCreationType.group;
			createdEntityId =
				await this.entityInstanceApiService
					.createEntityInstance(
						null);
		}

		return createdEntityId;
	}
}