<div class="ui-g-12 no-padding text-center payment-batch-container"
	[ngClass]="{
		'no-margin': (displayMode === displayModes.list)
	}">

	<ng-template #BackNavigation
		[ngIf]="isDisplayModeAvailable() === false
			|| displayMode !== displayModes.list">
		<div class="ui-g-12 no-padding text-left nested-display-header">
			<span class="text-link theme-color nested-display-back-container"
				(click)="displayMode = displayModes.list">
				<i class="fa fa-arrow-left nested-display-back-icon">
				</i>
				<span class="nested-display-back">
					Back
				</span>
			</span>
		</div>
	</ng-template>

	<ng-template #Invalid
		[ngIf]="isValid() === false
			|| isDisplayModeAvailable() === false
			|| isOwnershipAllowed === false"
		[ngIfElse]="Content">
		<div class="ui-g-12 text-center">
			<div *ngIf="isOwnershipAllowed === true">
				This view is not currently available.
			</div>
			<div *ngIf="isOwnershipAllowed === false">
				<app-access-denied-content
					[drawerDisplay]="true"
					drawerName="Payment Batches"
					[sessionIdentifier]="sessionIdentifier"
					[accessDeniedUrl]="accessDeniedUrl"
					[resources]="resources"
					[clientMessage]="clientMessage">
				</app-access-denied-content>
			</div>
		</div>
	</ng-template>

	<ng-template #Content>
		<ng-template #List [ngIf]="displayMode === displayModes.list">
			<app-payment-batch-list [context]="context"
				[wildcardChildFilter]="wildcardChildFilter"
				[securityDefinitions]="securityDefinitions"
				(changeDisplayMode)="changeDisplayMode($event)"
				(changeSelectedItem)="changeSelectedItem($event)">
			</app-payment-batch-list>
		</ng-template>
	</ng-template>

</div>