/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';

/* eslint-enable max-len */

@Component({
	selector: 'app-action-definition',
	templateUrl: './action-definition.component.html',
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of an action definition
 * display.
 *
 * @export
 * @class ActionDefinitionComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class ActionDefinitionComponent
implements OnInit
{
	/**
	 * Initializes a new instance of the ActionDefinitionComponent class.
	 *
	 * @param {ActivatedRoute} route
	 * The site layout service to use for responsive layouts.
	 * @param {Router} router
	 * The router used in this component for site navigation.
	 * @param {WorkflowActionDefinitionsApiService} actionDefinitionsApiService
	 * The api service used to gather action definition data.
	 * @memberof ActionDefinitionComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public router: Router,
		public actionDefinitionsApiService: WorkflowActionDefinitionsApiService)
	{
	}

	/**
	 * Gets or sets the action definition currently displayed in this
	 * component.
	 *
	 * @type {IWorkflowActionDefinitions}
	 * @memberof ActionDefinitionComponent
	 */
	public actionDefinition: IWorkflowActionDefinitions =
		<IWorkflowActionDefinitions>{};

	/**
	 * Gets or sets the loading value for this component.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionComponent
	 */
	public loading: boolean;

	/**
	 * Gets the page level operation group name for this component's
	 * context menu.
	 *
	 * @type {string}
	 * @memberof ActionDefinitionComponent
	 */
	public readonly pageContextOperationGroupName: string =
		'WorkflowAction.PageContext';

	/**
	 * Gets the page context for this component which is passed
	 * to associated context components.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ActionDefinitionComponent
	 */
	public get pageContext(): IDynamicComponentContext<Component, any>
	{
		return <IDynamicComponentContext<Component, any>> {
			data: {
				actionDefinition: this.actionDefinition
			},
			source: this
		};
	}

	/**
	 * Gets or sets the name of the id route parameter.
	 *
	 * @type {string}
	 * @memberof ActionDefinitionComponent
	 */
	private readonly idRouteParameter: string = 'id';

	/**
	 * Implements the on initialization interface.
	 * This will load the action definition based on the route id sent to this
	 * component.
	 *
	 * @memberof ActionDefinitionComponent
	*/
	public async ngOnInit(): Promise<void>
	{
		this.loading = true;

		const actionDefinitionId: number =
			parseInt(
				this.route.snapshot.paramMap.get(
					this.idRouteParameter),
				10);

		this.actionDefinition =
			await this.actionDefinitionsApiService.get(
				actionDefinitionId);

		this.loading = false;
	}
}