<div class="ui-g-12 no-padding">
	<ng-template
		[ngIf]="allowedParents.length > 0">
		<div class="ui-g-12 no-horizontal-padding wizard-content-title">
			Parent Associations
		</div>
	</ng-template>

	<ng-template
		[ngIf]="context.source.wizardStepLoading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12 no-padding">
			<div *ngIf="!context.source.wizardStepLoading
				&& allowedParents.length > 0"
				class="search-list-content"
				[@contentAnimation]="state">

				<div class="parent-required-text"
					*ngIf="parentRequired
						&& !parentSelectionExists"
					[@contentAnimation]="state">
					<span>
						A parent is required to create this entity. Please select a minimum of one parent association from the list below.
					</span>
					<hr />
				</div>

				<div class="parent-list"
					*ngIf="parentSelectionExists"
					[@contentAnimation]="state">
					<span class="panel-sub-title">
						Parents
					</span>
					<ul *ngFor="let parentGroup of parentGroups"
						class="parent-type-list">
						<li *ngIf="parentGroup.entityDefinition != null && parentGroup.relatedEntities.length > 0"
							class="parent-entity-list"
							[@contentAnimation]="state">
							<div class="entity-type-title">
								{{parentGroup.entityDefinition.displayTitle}} ({{parentGroup.relatedEntities.length}})
							</div>

							<label class="visually-hidden"
								for="associatedParentsControl">
								Associated Parents
							</label>
							<p-chips inputId="associatedParentsControl"
								[(ngModel)]="parentGroup.relatedEntities"
								(onRemove)="parentSelected({ data: $event.value, selected: false})">
								<ng-template let-item pTemplate="item">
									<span class="entity-chip">
										{{parentGroup.entityDefinition.getItemTitle(item)}}
									</span>
								</ng-template>
							</p-chips>
						</li>
					</ul>
					<hr />
				</div>

				<entity-select
					[searchCriteria]="entitySearch"
					[createCriteria]="entitySearch"
					[selectedEntities]="currentParentGroup?.relatedEntities"
					(itemSelected)="parentSelected($event)"
					(categorySelected)="categorySelected($event)">
				</entity-select>
			</div>
		</div>
	</ng-template>
</div>