/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'manage-claim-owner-claim',
	templateUrl: './manage-claim-owner-claim.component.html'
})

/**
 * A component representing a wizard step for managing
 * a claim owner.
 *
 * @export
 * @class ManageClaimOwnerClaimComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class ManageClaimOwnerClaimComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the manage claim owner
	 * claim component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {ClaimsService} claimsService
	 * The claims service used in this component.
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public claimsService: ClaimsService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the message that will be set if the valid wizard step check
	 * returns false.
	 *
	 * @type {string}
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	private readonly invalidWizardStepMessage: string =
		'The claim owner is not allowed to be updated.';

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	private claimId: number;

	/**
	 * Gets or sets the claim entity instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	private claim: IEntityInstance;

	/**
	 * Sets the closed status string.
	 *
	 * @type {string}
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	private readonly closedStatus: string = 'Closed';

	/**
	 * Sets the select an option string.
	 *
	 * @type {string}
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	private readonly selectAnOption: string = 'Select an Option';

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.claimId =
			this.context.source.activeMenuItem
				.currentData.data.id;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		this.claim =
			await this.entityInstanceApiService.get(this.claimId);

		this.context.source.addToNext(this.confirmClaimOwner.bind(this));

		await this.performPostInitActions();

		this.isWizardStepValidForDisplay(
			this.claim);

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * This will send the assign claim owner event and navigate to
	 * the claim.
	 *
	 * @async
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	public async confirmClaimOwner(): Promise<void>
	{
		await this.assignClaimOwner();
		await this.navigateToClaim(
			this.claimId,
			ClaimConstants.claimEntityTypeGroups.claims
		);
	}

	/**
	 * Executes the assign claim owner process by executing the
	 * workflow action to assign the claim owner.
	 *
	 * @async
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	public async assignClaimOwner(): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const queryString: string =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					claimOwnerId: currentData.claimOwnerId
				});

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		return this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimId,
					ClaimConstants.workflowActions.assignClaimOwner,
					null,
					queryString),
				'<strong>Assigning Claim Owner</strong>',
				'<strong>Claim Owner Assigned</strong>',
				'Claim owner has been assigned.',
				'Claim owner has not been assigned.'));
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * Validates the organization.
	 *
	 * @return {boolean}
	 * The organization validity
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	public async validOrganization(field: FormlyFieldConfig): Promise<boolean>
	{
		let users: any[] = [];

		if (!AnyHelper.isNullOrEmpty(field.formControl.value))
		{
			const organization: any = field.formControl.value;

			const organizationTypeGroup =
				organization.entityType === ClaimConstants
					.serviceProviderEntityTypes.insuranceCompany
					? ClaimConstants.serviceProviderEntityTypeGroups
						.insuranceCompany
					: ClaimConstants.serviceProviderEntityTypeGroups
						.thirdPartyAdministrator;

			users = await this.claimsService.getClaimOwnerUsers(
				organization.id,
				organizationTypeGroup);
		}

		if (AnyHelper.isNullOrEmptyArray(users))
		{
			field.asyncValidators.organizationValidator.message =
				'No Claim Owner users exist for this organization.';
		}

		return Promise.resolve(!AnyHelper.isNullOrEmptyArray(users));
	}

	/**
	 * This will navigate to the claim layout using the
	 * claim id provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	private async navigateToClaim(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		const organizationPromise: string =
			'return async function(field) {let options = []; '
				+ 'const claimsService = '
				+ 'this.source.resolver.resolveClaims(\"ClaimsService\"); '
				+ 'options = await claimsService.getClaimOwnerOrganizations('
				+ 'this.source.activeMenuItem.currentData.data.id);'
				+ 'return options;}';

		const userPromise: string =
			'return async function(field) {let options = []; '
				+ 'let organization = this.source.activeMenuItem.currentData'
				+ '.data.organization;'
				+ 'if(organization!=null){'
				+ 'const organizationTypeGroup = organization.entityType === '
				+ '\"Organization.InsuranceCompany\" ? '
				+ '\"Organization.InsuranceCompanies\" '
				+ ': \"Organization.ThirdPartyAdministrators\";'
				+ 'const claimsService = '
				+ 'this.source.resolver.resolveClaims(\"ClaimsService\"); '
				+ 'options = await claimsService.getClaimOwnerUsers('
				+ 'organization.id,'
				+ 'organizationTypeGroup);'
				+ 'if (options.length == 0) {field.props.disabled = true; '
				+ 'field.props.placeholder = \"No Available Options\"; '
				+ 'field.formControl.setValue(null); '
				+ 'field.formControl.updateValueAndValidity();}'
				+ 'else {field.props.disabled = false; '
				+ 'field.props.placeholder = \"Select An Option\";}'
				+ 'return options;}'
				+ 'field.props.disabled = true; field.props.placeholder = '
				+ '\"No Available Options\";'
				+ 'return options;}';

		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.organization',
					type: FormlyConstants.customControls.customDataSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Organization',
						description: 'Claim organization.',
						placeholder: this.selectAnOption,
						dataPromise: organizationPromise,
						labelTemplate: '${item.data.name.legalName}',
						valueTemplate: '#{item}',
						required: true,
						disableAutoSetValue: true,
						change:
							(field: FormlyFieldConfig,
								_event: any) =>
							{
								if (!AnyHelper.isNullOrEmpty(
									field.formControl.value))
								{
									this.dynamicFormlyLayout[1]
										.formControl.setValue(null);
									this.dynamicFormlyLayout[1]
										.formControl.updateValueAndValidity();

									this.context.source.activeMenuItem
										.currentData.data.organization =
											field.formControl.value;

									this.dynamicFormlyLayout[1]
										.props.initializeDataOptions();
								}
							}
					},
					asyncValidators: {
						organizationValidator: {
							expression: (
								_formControl: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.validOrganization(field),
							message:
								AppConstants.empty
						}
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.claimOwnerId',
					type: FormlyConstants.customControls.customDataSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Claim Owner',
						description: 'Claim owner.',
						placeholder: this.selectAnOption,
						dataPromise: userPromise,
						labelTemplate: '${item.data.firstName} '
							+ '${item.data.lastName}',
						valueTemplate: '${item.id}',
						required: true
					}
				}
			];
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @param {number} claim
	 * The claim to validate.
	 * @memberof ManageClaimOwnerClaimComponent
	 */
	private isWizardStepValidForDisplay(
		claim: IEntityInstance): void
	{
		const claimStatus: string =
			claim.data.status;

		this.clientMessage =
			claimStatus !== this.closedStatus
				? AppConstants.empty
				: this.invalidWizardStepMessage;
	}
}