/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	LogComponent
} from '@admin/components/system/logs/log-expand/log.component';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	LogApiService
} from '@api/services/logs/log.api.service';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	ChartFactory
} from '@shared/factories/chart-factory';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ChartHelper
} from '@shared/helpers/chart.helper';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IChartDefinition
} from '@shared/interfaces/application-objects/chart-definition.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IChartContext
} from '@shared/interfaces/dynamic-interfaces/chart-context.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	DateTime
} from 'luxon';

/* eslint-enable max-len */

@Component({
	selector: 'app-logs',
	templateUrl: './logs.component.html',
	styleUrls: ['./logs.component.scss']
})

/**
 * A component representing an instance of the logs component.
 *
 * @export
 * @class LogsComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class LogsComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Initializes a new instance of the logs component and sets up
	 * the list columns to be displayed in the logs table.
	 *
	 * @param {LogApiService} logApiService
	 * The api service used to load log data.
	 * @param {ChartFactory} chartFactory
	 * The chart factory to use for charted information displays.
	 * @memberof LogsComponent
	 */
	public constructor(
		public logApiService: LogApiService,
		public chartFactory: ChartFactory,
		public resolver: ResolverService)
	{
		super(resolver);
		this.apiService = logApiService;
	}

	/**
	 * Gets or sets the string used to filter to occurrences today.
	 *
	 * @type {string}
	 * @memberof LogsComponent
	 */
	public startOfDayFilter: string;

	/**
	 * Gets or sets the label array to display in a by hour
	 * chart splitout for today.
	 *
	 * @type {DateTime[]}
	 * @memberof LogsComponent
	 */
	public todaysHourLabels: DateTime[] = [];

	/**
	 * Gets or sets the string used to filter by the last thirty days.
	 *
	 * @type {string}
	 * @memberof LogsComponent
	 */
	public lastThirtyDaysFilter: string;

	/**
	 * Gets or sets the label array to display in a last thirty dat
	 * chart splitout.
	 *
	 * @type {DateTime[]}
	 * @memberof LogsComponent
	 */
	public thirtyDayLabels: DateTime[] = [];

	/**
	 * Gets or sets the array of information menu items.
	 *
	 * @type {IInformationMenuItem<IAggregate[]>[]}
	 * @memberof LogsComponent
	 */
	public informationMenuItems: IInformationMenuItem<IAggregate[]>[] = [];

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {object}
	 * @memberof LogsComponent
	 */
	public logsTableDefinitions: ICommonTable;

	/**
	 * Gets the common definitions used for pie charts displayed
	 * information menu.
	 *
	 * @type {{
		chartColors: string[],
		chartLabels: string[],
		chartPivotProperty: string
		}}
	 * @memberof LogsComponent
	 */
	private readonly pieChartDefinition: {
		chartColors: string[];
		chartLabels: string[];
		chartPivotProperty: string;
	} = {
			chartColors: [
				ChartConstants.themeColors.indigo,
				ChartConstants.themeColors.lightGreen,
				ChartConstants.themeColors.orange,
				ChartConstants.themeColors.amber
			],
			chartLabels: [
				'Info',
				'Trace',
				'Warning',
				'Error'
			],
			chartPivotProperty: 'Level'
		};

	/**
	 * Sets logs last thirty days string literal.
	 *
	 * @type {string}
	 * @memberof LogsComponent
	 */
	private readonly logsLastThirtyDays: string = 'Logs Last 30 Days';

	/**
	 * Sets number of logs string literal.
	 *
	 * @type {string}
	 * @memberof LogsComponent
	 */
	private readonly numberOfLogs: string = 'Number of Logs';

	/**
	 * Sets up variables used in this component.
	 *
	 * @async
	 * @memberof LogsComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		const currentDate: DateTime = DateTime.local();

		this.startOfDayFilter =
			ApiFilterHelper.getStartOfDayFilter(
				'Time',
				currentDate);
		this.lastThirtyDaysFilter =
			ApiFilterHelper.getLastNumberOfDaysFilter(
				'Time',
				currentDate,
				AppConstants.days.thirtyDays);
		this.todaysHourLabels =
			ChartHelper.getHourLabelsByDay(
				currentDate);
		this.thirtyDayLabels =
			ChartHelper.getLastNumberOfDayLabels(
				currentDate,
				AppConstants.days.thirtyDays);

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'userName',
					columnHeader: 'User Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'requestHost',
					columnHeader: 'Request Host',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'requestMethod',
					columnHeader: 'Request Method',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'requestUrl',
					columnHeader: 'Request Url',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'requestQueryString',
					columnHeader: 'Request Query String',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'callsite',
					columnHeader: 'Callsite',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'exception',
					columnHeader: 'Exception',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'stackTrace',
					columnHeader: 'Stack Trace',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'level',
					columnHeader: 'Level',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'message',
					columnHeader: 'Message',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'time',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'Date & Time',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'logger',
					columnHeader: 'Logger',
					displayOrder: displayOrder
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up information menu items displayed in this component.
	 *
	 * @memberof LogsComponent
	 */
	public setupInformationMenuItems(): void
	{
		const primaryGroupByValues: string =
			'Time.ConvertToSystemTime().Year, '
				+ 'Time.ConvertToSystemTime().Month,'
				+ 'Time.ConvertToSystemTime().Day';

		this.informationMenuItems =
		[
			<IInformationMenuItem<IAggregate[]>>
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise: this.apiService.aggregate(
							AppConstants.aggregateMethods.count,
							null,
							this.lastThirtyDaysFilter,
							primaryGroupByValues),
						chartConfiguration: this.chartFactory.timeLineChart(
							this.logsLastThirtyDays,
							this.thirtyDayLabels,
							[],
							this.numberOfLogs,
							ChartConstants.timeUnits.day,
							1,
							ChartConstants.formats.day)
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data: <IChartContext<IAggregate[]>>
						{
							chartDefinition:
								<IChartDefinition<IAggregate[]>>
								{
									dataPromise: this.apiService.aggregate(
										AppConstants.aggregateMethods.count,
										null,
										this.lastThirtyDaysFilter,
										primaryGroupByValues),
									chartConfiguration:
										this.chartFactory.timeLineChart(
											this.logsLastThirtyDays,
											this.thirtyDayLabels,
											[],
											this.numberOfLogs,
											ChartConstants.timeUnits.day,
											1,
											ChartConstants.formats.day)
								},
							data: [],
							summaryCardDisplay: false
						},
						source: this
					},
				titleTemplate: this.logsLastThirtyDays,
				width: AppConstants.sizeIdentifiers.extraLarge
			},
			<IInformationMenuItem<IAggregate[]>>
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise: this.apiService.aggregate(
							AppConstants.aggregateMethods.count,
							null,
							this.lastThirtyDaysFilter,
							primaryGroupByValues
								+ ', Time.ConvertToSystemTime().Hour'),
						chartConfiguration: this.chartFactory.timeLineChart(
							'Today\'s Logs',
							this.todaysHourLabels,
							[],
							this.numberOfLogs,
							ChartConstants.timeUnits.hour,
							1,
							ChartConstants.formats.hour)
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data: <IChartContext<IAggregate[]>>
						{
							chartDefinition:
								<IChartDefinition<IAggregate[]>>
								{
									dataPromise: this.apiService.aggregate(
										AppConstants.aggregateMethods.count,
										null,
										this.lastThirtyDaysFilter,
										primaryGroupByValues
											+ ', Time.ConvertToSystemTime()'
											+ '.Hour'),
									chartConfiguration:
										this.chartFactory.timeLineChart(
											'Today\'s Logs',
											this.todaysHourLabels,
											[],
											this.numberOfLogs,
											ChartConstants.timeUnits.hour,
											1,
											ChartConstants.formats.hour)
								},
							data: [],
							summaryCardDisplay: false
						},
						source: this
					},
				titleTemplate: 'Logs Today',
				width: AppConstants.sizeIdentifiers.large
			},
			<IInformationMenuItem<IAggregate[]>>
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise: this.apiService.aggregate(
							AppConstants.aggregateMethods.count,
							null,
							this.lastThirtyDaysFilter,
							'Level'),
						chartColors:
							this.pieChartDefinition.chartColors,
						chartConfiguration: this.chartFactory.pieChart(
							'Log Splitouts last 30 days',
							this.pieChartDefinition.chartLabels,
							[],
							this.numberOfLogs),
						chartPivotProperty:
							this.pieChartDefinition.chartPivotProperty
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data: <IChartContext<IAggregate[]>>
						{
							chartDefinition:
								<IChartDefinition<IAggregate[]>>
								{
									dataPromise: this.apiService.aggregate(
										AppConstants.aggregateMethods.count,
										null,
										this.lastThirtyDaysFilter,
										'Level'),
									chartColors:
										this.pieChartDefinition.chartColors,
									chartConfiguration:
										this.chartFactory.pieChart(
											'Log Splitouts last 30 days',
											this.pieChartDefinition.chartLabels,
											[],
											this.numberOfLogs),
									chartPivotProperty:
										this.pieChartDefinition
											.chartPivotProperty
								},
							data: [],
							summaryCardDisplay: false
						},
						source: this
					},
				titleTemplate: 'Log Splitout Last 30 Days',
				width: AppConstants.sizeIdentifiers.extraLarge
			},
			<IInformationMenuItem<IAggregate[]>>
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise: this.apiService.aggregate(
							AppConstants.aggregateMethods.count,
							null,
							this.startOfDayFilter,
							'Level'),
						chartColors: this.pieChartDefinition.chartColors,
						chartConfiguration: this.chartFactory.pieChart(
							'Today\'s Log Splitout',
							this.pieChartDefinition.chartLabels,
							[],
							this.numberOfLogs),
						chartPivotProperty:
							this.pieChartDefinition.chartPivotProperty
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data: <IChartContext<IAggregate[]>>
						{
							chartDefinition:
								<IChartDefinition<IAggregate[]>>
								{
									dataPromise: this.apiService.aggregate(
										AppConstants.aggregateMethods.count,
										null,
										this.startOfDayFilter,
										'Level'),
									chartColors:
										this.pieChartDefinition.chartColors,
									chartConfiguration:
										this.chartFactory.pieChart(
											'Today\'s Log Splitout',
											this.pieChartDefinition.chartLabels,
											[],
											this.numberOfLogs),
									chartPivotProperty:
										this.pieChartDefinition
											.chartPivotProperty
								},
							data: [],
							summaryCardDisplay: false
						},
						source: this
					},
				titleTemplate: 'Log Splitout Today',
				width: AppConstants.sizeIdentifiers.large
			}
		];
	}

	/**
	 * Sets up the list column definitions for the current logs object
	 * list.
	 *
	 * @memberof LogsComponent
	 */
	public setupTableDefinitions(): void
	{
		this.logsTableDefinitions =
			{
				tableTitle: 'System Logs',
				expandTitle: () => 'View Log',
				objectSearch: {
					filter: this.tableFilterQuery,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					(objectSearch: IObjectSearch) =>
						this.logApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit
							),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					filter: {
						quickFilters:
						[
							{
								label: 'Latest Logs',
								value: AppConstants.empty
							},
							{
								label: 'Error Logs',
								value: 'level eq \'ERROR\''
							}
						],
						selectedFilterValue: this.tableFilterQuery
					},
					view: {
						component: LogComponent,
						layout: [
							{
								key: 'exception',
								type: FormlyConstants.customControls
									.customTextDisplay,
								props: {
									title: 'Exception',
									useMarkdown: false,
									usePanelDisplay: true,
									expanded: true,
									copyToClipboard: true,
									content: AppConstants.empty
								},
								expressions: {
									'props.content':
										'`${model.exception}`'
								}
							},
							{
								key: 'stackTrace',
								type: FormlyConstants.customControls
									.customTextDisplay,
								props: {
									title: 'Stack Trace',
									useMarkdown: false,
									usePanelDisplay: true,
									expanded: true,
									copyToClipboard: true,
									content: AppConstants.empty
								},
								expressions: {
									'props.content':
										'`${model.stackTrace}`'
								}
							}
						],
						items: []
					}
				}
			};
		this.loadingTableDefinitions = false;
	}
}