<div operation-subgroup
	clickOutside
	(clickOutside)="clickedOutside()"
	[contentDisplayed]="activeMenuItem != null"
	(menuItemClicked)="itemClicked($event)"
	[item]="model"
	[root]="true"
	[ngClass]="class"
	[reset]="reset"
	[context]="pageContext"
	[parentActive]="parentActive">
</div>