<div class="custom-checkbox-container">
	<label class="visually-hidden"
		[attr.for]="'AriaInput' + id">
		{{field.props.checkboxText || field.props.label}}
	</label>
	<p-checkbox
		binary="true"
		[label]="field.props.checkboxText || field.props.label"
		[formControl]="formControl"
		[formlyAttributes]="field"
		[inputId]="'AriaInput' + id"
		(onChange)="field.props.change && field.props.change(field, $event)">
	</p-checkbox>
</div>

<field-validation-message
	[field]="field">
</field-validation-message>