/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	AfterViewInit,
	Directive,
	ElementRef,
	ViewChild
} from '@angular/core';
import {
	TooltipDisplayDirective
} from '@shared/directives/tooltip-display.directive';
import {
	IRuleViolationWorkflowActionDefinition
} from '@shared/interfaces/rules/rule-violation-workflow-action-definition.interface';

/* eslint-enable max-len */

@Directive({
	selector: '[RuleWorkflowDisplay]'
})

/**
 * A directive representing shared logic for a component displaying a rule
 * workflow.
 *
 * @export
 * @extends {TooltipDisplayDirective}
 * @implements {AfterViewInit}
 * @class RuleWorkflowDisplayDirective
 */
export class RuleWorkflowDisplayDirective
	extends TooltipDisplayDirective
	implements AfterViewInit
{
	/**
	 * Gets or sets the blocking tooltip element reference.
	 *
	 * @type {ElementRef}
	 * @memberof RuleWorkflowDisplayDirective
	 */
	@ViewChild('BlockingTooltip')
	public blockingTooltip: ElementRef;

	/**
	 * Gets or sets the warning tooltip element reference.
	 *
	 * @type {ElementRef}
	 * @memberof RuleWorkflowDisplayDirective
	 */
	@ViewChild('WarningTooltip')
	public warningTooltip: ElementRef;

	/**
	 * Gets or sets the rule violation workflow action definitions.
	 *
	 * @type {IRuleViolationWorkflowActionDefinition[]}
	 * @memberof RuleWorkflowDisplayDirective
	 */
	public ruleViolationWorkflowActions:
		IRuleViolationWorkflowActionDefinition[] = [];

	/**
	 * Gets or sets the override allowed value.
	 *
	 * @type {boolean}
	 * @memberof RuleWorkflowDisplayDirective
	 */
	public overrideAllowed: boolean = false;

	/**
	 * Gets or sets the tooltip message to display for the blocked icon.
	 *
	 * @type {string}
	 * @memberof RuleWorkflowDisplayDirective
	 */
	public readonly blockedTooltipMessage: string =
		'This action is blocked from execution due to rule violations. '
			+ 'Please correct this rule violation or override to allow this '
			+ 'action.';

	/**
	 * Gets or sets the tooltip message to display for the warning icon.
	 *
	 * @type {string}
	 * @memberof RuleWorkflowDisplayDirective
	 */
	public readonly warningTooltipMessage: string =
		'This action is not blocking at this point but will be required '
			+ 'for future actions.';

	/**
	 * After view initialization event.
	 * Initializes the set of tooltips.
	 *
	 * @memberof RuleWorkflowDisplayDirective
	 */
	public ngAfterViewInit(): void
	{
		this.tooltips =
			[
				this.blockingTooltip,
				this.warningTooltip
			];
	}

	/**
	 * Calculates and returns a value signifying that this displayed
	 * rule violation allows override.
	 *
	 * @return {boolean}
	 * A boolean that defines whether or not an override is allowed.
	 * @memberof RuleWorkflowDisplayDirective
	 */
	public getOverrideAllowed(): boolean
	{
		return this.ruleViolationWorkflowActions
			.filter(
				(ruleViolationWorkflowAction:
					IRuleViolationWorkflowActionDefinition) =>
					ruleViolationWorkflowAction.blocked === true &&
						ruleViolationWorkflowAction.overridable ===
							true).length > 0;
	}
}