<app-base-page pageTitle="Work Items Management"
	utilityMenuOperationGroupName="">

	<ng-template
		[ngIf]="loading === true"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i
				class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12">
		</div>
	</ng-template>

</app-base-page>