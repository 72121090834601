/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	AccountComponent
} from '@account/account.component';
import {
	SecurityComponent
} from '@account/components/security/security.component';
import {
	SiteSettingsComponent
} from '@account/components/site-settings/site-settings.component';
import {
	UserProfileComponent
} from '@account/components/user-profile/user-profile.component';
import {
	CommonModule
} from '@angular/common';
import {
	NgModule
} from '@angular/core';
import {
	RouterModule
} from '@angular/router';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	SharedModule
} from '@shared/shared.module';

/* eslint-enable max-len */

@NgModule({
	declarations: [
		AccountComponent,
		SecurityComponent,
		SiteSettingsComponent,
		UserProfileComponent,
	],
	imports: [
		CommonModule,
		SharedModule,
		RouterModule.forChild([
			{
				path: 'account',
				component: AccountComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				data:
				{
					roles: [
						AppConstants.securityGroups.users
					]
				},
				children: [
					{
						path: 'security',
						component: SecurityComponent
					},
					{
						path: 'siteSettings',
						component: SiteSettingsComponent
					},
					{
						path: 'userProfile',
						component: UserProfileComponent
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					{
						path: '**',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					}
				]
			}])
	],
	exports: [
	],
	providers: [
	]
})

/**
 * A module used to hold profile route specific
 * logic and display resources.
 *
 * @export
 * @class AccountModule
 */
export class AccountModule
{
}