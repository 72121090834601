/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	BasePageDirective
} from '@shared/directives/base-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	isEqual
} from 'lodash-es';

/* eslint-disable max-len */

@Component({
	selector: 'app-display-component-parameter',
	templateUrl: './display-component-parameter.component.html',
	styleUrls: [
		'./display-component-parameter.component.scss'
	]
})

/**
 * A component representing an instance of the display component
 * parameter component.
 *
 * @export
 * @class DisplayComponentParameterComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {AfterViewInit}
 */
export class DisplayComponentParameterComponent
implements OnInit, OnChanges, AfterViewInit
{
	/**
	 * Initializes a new instance of the display component parameter
	 * component. This component is used to display dynamic formly
	 * content and alter display component parameter layout data
	 * items.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @memberof DisplayComponentParameterComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the value that will force cancel to always display
	 * regardless of form changes. If false, this will only display
	 * when a value has altered in the form.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Input() public alwaysDisplayCancel: boolean = true;

	/**
	 * Gets or sets the display component instance associated
	 * with this parameter set.
	 *
	 * @type {DisplayComponentInstance}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Input() public displayComponentInstance: DisplayComponentInstance;

	/**
	 * Gets or sets the layout schema displayed in the formly section
	 * of this component.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Input() public parameterLayoutSchema: FormlyFieldConfig[];

	/**
	 * Gets or sets the data associated with the layout schema and
	 * displayed in the formly component.
	 *
	 * @type {any}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Input() public parameterLayoutData: any;

	/**
	 * Gets or sets the dynamic component context associated with this data
	 * set.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Input() public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the truthy that defines if the form content should be
	 * displayed in a scroll panel.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Input() public useScrollPanel: boolean = false;

	/**
	 * Gets or sets the truthy that defines if the form content will be
	 * displayed in an overlay panel.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Input() public useOverlay: boolean = false;

	/**
	 * Gets or sets the truthy that defines if the settings control
	 * is currently displayed.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Input() public settingsActive: boolean = false;

	/**
	 * Gets or sets the truthy that defines if these parameters are being
	 * displayed in a page header.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Input() public pageHeaderParameters: boolean = false;

	/**
	 * Gets or sets the event emitter that will emit that the apply parameters
	 * action has been called in this component.
	 *
	 * @type {EventEmitter<any>}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Output() public appliedParameters: EventEmitter<any> =
		new EventEmitter<any>();

	/**
	 * Gets or sets the event emitter that will emit that the cancel action
	 * has been called in this component.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DisplayComponentParameterComponent
	 */
	@Output() public cancelClicked: EventEmitter<void> =
		new EventEmitter<void>();

	/**
	 * Gets or sets the scroll panel element reference.
	 *
	 * @type {ElementRef}
	 * @memberof DisplayComponentParameterComponent
	 */
	@ViewChild('ScrollPanel')
	public scrollPanel: ElementRef;

	/**
	 * Gets or sets the full height form element reference.
	 *
	 * @type {ElementRef}
	 * @memberof DisplayComponentParameterComponent
	 */
	@ViewChild('FullHeightFormContainer')
	public fullHeightForm: ElementRef;

	/**
	 * Gets or sets the initial value sent into this component for parameter layout
	 * data.
	 *
	 * @type {any}
	 * @memberof DisplayComponentParameterComponent
	 */
	public archivedParameterLayoutData: any = { data: {}};

	/**
	 * Gets or sets the valid value of the contained parameter form.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterComponent
	 */
	public isValid: boolean = false;

	/**
	 * Gets or sets the changed value of the parameter data. This is
	 * used to define if the apply button is available due to new data.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterComponent
	 */
	public valueChanged: boolean = false;

	/**
	 * Gets or sets the calculated dynamic form height along with the title
	 * and associated buttonset.
	 *
	 * @type {number}
	 * @memberof DisplayComponentParameterComponent
	 */
	public fullDynamicFormHeight: number;

	/**
	 * Gets or sets the truthy defining if we should display the dynamic form
	 * in a scroll panel.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterComponent
	 */
	public useFixedHeightScrollPanel: boolean = false;

	/**
	 * Gets or sets the truthy defining if this parameter container should
	 * display above the parameter header when clicked. This is only applicable
	 * to page header parameter sets.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterComponent
	 */
	public displayAbove: boolean = false;

	/**
	 * Gets or sets the last calendar target so that resizes will only occur
	 * once.
	 *
	 * @type {string}
	 * @memberof DisplayComponentParameterComponent
	 */
	public lastCalendarTarget: string;

	/**
	 * Gets the identifier used for a widget overlay.
	 *
	 * @type {string}
	 * @memberof DisplayComponentParameterComponent
	 */
	private readonly overlayIdentifier: string = 'widget-overlay';

	/**
	 * Gets the class sent to appended calendars in this parameter component.
	 *
	 * @type {string}
	 * @memberof DisplayComponentParameterComponent
	 */
	private readonly appendedCalendarPanelClass: string =
		'appended-calendar-panel';

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof DisplayComponentParameterComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		if (this.settingsActive === true)
		{
			setTimeout(() =>
			{
				this.settingsActive = false;
			});

			this.cancelClick();
		}

		if (this.pageHeaderParameters === true)
		{
			this.calculateHeaderParameterDisplay();
		}
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close this setting display when a non bubbled
	 * click event occurs outside of this element.
	 *
	 * @memberof DisplayComponentParameterComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(
		controlIdentifer: string): void
	{
		if (controlIdentifer !== this.overlayIdentifier)
		{
			this.siteLayoutChanged();
		}
	}

	/**
	 * On component initialization event.
	 * This method is used to set the inital parameter layout data value
	 * for future on change comparisons.
	 *
	 * @memberof DisplayComponentParameterComponent
	 */
	public ngOnInit(): void
	{
		this.archivedParameterLayoutData.data =
			{...this.parameterLayoutData?.data};
	}

	/**
	 * On component after view initialization event.
	 * This method is used to decorate input formly fields with
	 * a link to the append to container in this component.
	 *
	 * @memberof DisplayComponentParameterComponent
	 */
	public ngAfterViewInit(): void
	{
		setTimeout(
			() =>
			{
				if (AnyHelper.isNull(this.parameterLayoutSchema)
					|| this.useScrollPanel === false)
				{
					return;
				}

				this.parameterLayoutSchema
					.forEach((fieldConfig: FormlyFieldConfig) =>
					{
						fieldConfig.props.appendTo =
							FormlyConstants.appendToTargets.body;

						if (fieldConfig.type ===
							FormlyConstants.customControls.customCalendar)
						{
							fieldConfig.props.panelStyleClass =
								this.appendedCalendarPanelClass;
						}
					});
			});
	}

	/**
	 * On component changes event.
	 * This method is used to look for differences in the inital parameter
	 * layout data values and the current data values. If differences are
	 * found this will call the applied parameters click event so this
	 * can be handled.
	 *
	 * @param {SimpleChanges} simpleChanges
	 * The altered values that fired this on change event.
	 * @memberof DisplayComponentParameterComponent
	 */
	public ngOnChanges(
		simpleChanges: SimpleChanges): void
	{
		if (!AnyHelper.isNull(
			simpleChanges.parameterLayoutData?.previousValue?.data)
			&& !AnyHelper.isNull(
				simpleChanges.parameterLayoutData?.currentValue?.data)
			&& !isEqual(
				this.archivedParameterLayoutData.data,
				simpleChanges.parameterLayoutData.currentValue.data))
		{
			this.archivedParameterLayoutData.data =
				{...this.parameterLayoutData?.data};

			this.appliedParameters.emit();
		}

		if (this.pageHeaderParameters === true
			&& !AnyHelper.isNull(
				simpleChanges.settingsActive?.previousValue)
			&& !AnyHelper.isNull(
				simpleChanges.settingsActive?.currentValue)
			&& simpleChanges.settingsActive.previousValue === false
			&& simpleChanges.settingsActive.currentValue === true)
		{
			setTimeout(() =>
			{
				this.calculateHeaderParameterDisplay();
			});
		}
	}

	/**
	 * Handles a click on the apply action found in this component. This will
	 * emit the applied parameter click event to listening components.
	 *
	 * @memberof DisplayComponentParameterComponent
	 */
	public applyClick(
		event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.archivedParameterLayoutData.data =
			{...this.parameterLayoutData?.data};

		this.appliedParameters.emit();
		this.valueChanged = false;
	}

	/**
	 * Handles a click on the cancel action found in this component. This will
	 * emit the cancel click event to listening components.
	 *
	 * @memberof DisplayComponentParameterComponent
	 */
	public cancelClick(
		event: any = null): void
	{
		if (!AnyHelper.isNull(event))
		{
			event.preventDefault();
			event.stopPropagation();

			EventHelper.dispatchHideAssociatedMenusEvent(
				this.overlayIdentifier);
		}

		if (!isEqual(
			this.parameterLayoutData.data,
			this.archivedParameterLayoutData.data))
		{
			this.parameterLayoutData.data =
				{...this.archivedParameterLayoutData.data};
		}

		this.cancelClicked.emit();
		this.valueChanged = false;
	}

	/**
	 * Handles calculations and setting displays for this parameter display
	 * if shown in a page header. This sets a scroll panel or a display
	 * above the parameter header component if required.
	 *
	 * @memberof DisplayComponentParameterComponent
	 */
	public calculateHeaderParameterDisplay(): void
	{
		if (AnyHelper.isNull(this.fullHeightForm?.nativeElement)
			&& this.useScrollPanel === false)
		{
			return;
		}

		if (!AnyHelper.isNull(this.fullHeightForm?.nativeElement))
		{
			this.fullDynamicFormHeight =
				Math.ceil(this.fullHeightForm.nativeElement
					.getBoundingClientRect().height)
				+ AppConstants.staticLayoutSizes.parameterContainerTitleHeight
				+ AppConstants.staticLayoutSizes.parameterButtonSetHeight;
		}

		const pageTop: number =
			(DocumentHelper.getElementVerticalPositionById(
				document,
				BasePageDirective.contentTopIdentifier) || 0);

		this.displayAbove =
			this.siteLayoutService.windowHeight / 2 < pageTop;
		const availableHeight: number =
			this.siteLayoutService.windowHeight
				- pageTop
				- window.scrollY;

		const useScrollPanel: boolean =
			this.fullDynamicFormHeight >= availableHeight;

		if ((this.useScrollPanel === false
			&& useScrollPanel === true)
			|| (this.useScrollPanel === true
				&& useScrollPanel === false))
		{
			this.useScrollPanel = useScrollPanel;
			this.useFixedHeightScrollPanel = useScrollPanel;
		}
	}

	/**
	 * Validates and calls a widget container click event. This is used
	 * to handle attaching calendar input overlays as a width matching their
	 * respective element.
	 *
	 * @param {any} event
	 * The event that sent this click action.
	 * @memberof DisplayComponentParameterComponent
	 */
	public containerClicked(
		event: any): void
	{
		if (this.useScrollPanel === false)
		{
			return;
		}

		// Simulate a pointer event if a click event is sent.
		if (AnyHelper.isNull(event.path))
		{
			let parentElement: any = event.target;
			const clickEventPath: any[] = [];

			while (parentElement != null)
			{
				clickEventPath.push(parentElement);
				parentElement = parentElement.parentElement;
			}

			event.path = clickEventPath;
		}

		const calendarClicked: boolean =
			[
				...event.path[0].classList,
				...event.path[1].classList,
				...event.path[2].classList,
				...event.path[3].classList
			].filter(
				(value: string) =>
					AppConstants.clickOutsidePanelControl
						.includes(value)).length > 0;

		if (calendarClicked === false)
		{
			this.lastCalendarTarget = AppConstants.empty;

			return;
		}

		if (this.lastCalendarTarget === event.target.classList[0]
			|| this.siteLayoutService.contentWidth <
				AppConstants.layoutBreakpoints.smallPhone)
		{
			return;
		}

		this.lastCalendarTarget = event.target.classList[0];

		setTimeout(
			() =>
			{
				const appendedCalendarPanels: NodeListOf<Element> =
					document.querySelectorAll(
						`.${this.appendedCalendarPanelClass}`);

				appendedCalendarPanels.forEach(
					(appendedCalendarPanel: Element) =>
					{
						const boundingBox: DOMRect =
							appendedCalendarPanel.getBoundingClientRect();
						const finalWidth: number =
							boundingBox.width
								+ AppConstants.staticLayoutSizes
									.calendarInputIcon;

						appendedCalendarPanel.setAttribute(
							'style',
							appendedCalendarPanel.getAttribute('style')
								+ `;min-width: ${Math.round(finalWidth)}`
								+ 'px !important;');
					});
			});
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed parameter set.
	 * @memberof DisplayComponentParameterComponent
	 */
	public validParametersChanged(
		isValid: boolean): void
	{
		this.isValid = isValid;
	}

	/**
	 * Handles the parameter changed event sent from the child dynamic
	 * formly component. This will notify the component of changes to
	 * data in the formly display.
	 *
	 * @param {boolean} changed
	 * The changed truthy of the current displayed parameter set.
	 * @memberof DisplayComponentParameterComponent
	 */
	public parametersChanged(
		changed: boolean): void
	{
		this.valueChanged = changed;
	}
}
