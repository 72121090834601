/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SystemServiceConstants
} from '@shared/constants/system-service.constants';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	IChatSettings
} from '@shared/interfaces/application-objects/chat-settings';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	INameValuePair
} from '@shared/interfaces/application-objects/name-value-pair.interface';
import {
	ISystemServiceConfiguration
} from '@shared/interfaces/application-objects/system-service-configuration';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IChatService
} from '@shared/interfaces/services/chat-service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-cloud-chat',
	templateUrl: './cloud-chat.component.html'
})

/**
 * A component representing a cloud chat.
 *
 * @export
 * @class CloudChatComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @implements {IDynamicComponent<Component, any>}
 */
export class CloudChatComponent
implements OnInit, AfterViewInit, OnDestroy, IDynamicComponent<Component, any>
{
	/**
	 * Creates an instance of CloudChatComponent.
	 *
	 * @param {ResolverService} resolver
	 * The resolver for dependency injection.
	 * @param {SessionService} sessionService
	 * The session service for the current user.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used to interact with entity instances.
	 * @memberof CloudChatComponent
	 */
	public constructor(
		public resolver: ResolverService,
		public sessionService: SessionService,
		public entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Gets or sets the component container.
	 *
	 * @type {ElementRef<HTMLDivElement>}
	 * @memberof CloudChatComponent
	 */
	@ViewChild('CloudChat')
	public CloudChatContainer: ElementRef<HTMLDivElement>;

	/**
	 * Gets or sets the loading state of the component.
	 *
	 * @type {boolean}
	 * @memberof CloudChatComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the loading state of the component.
	 *
	 * @type {boolean}
	 * @memberof CloudChatComponent
	 */
	public destroyed: boolean = false;

	/**
	 * Gets or sets the chat service.
	 *
	 * @type {IChatService}
	 * @memberof CloudChatComponent
	 */
	public chatService: IChatService;

	/**
	 * Gets or sets a boolean signifying whether the chat is offline.
	 *
	 * @type {boolean}
	 * @memberof CloudChatComponent
	 */
	public chatOffline: boolean = false;

	/**
	 * Gets or sets the chat offline message.
	 *
	 * @type {string}
	 * @memberof CloudChatComponent
	 */
	public chatOfflineMessage: string =
		'Chat is currently offline.';

	/**
	 * Gets or sets the chat offline message.
	 *
	 * @type {string}
	 * @memberof CloudChatComponent
	 */
	public chatOfflineSecondaryMessage: string =
		'Please try again later.';

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof CloudChatComponent
	 */
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the drawer title.
	 *
	 * @type {string}
	 * @memberof CloudChatComponent
	 */
	private readonly drawerTitle: string = 'Chat';

	/**
	 * Handles the site layout change event.
	 * This will ensure the chat content remains accurately positioned.
	 *
	 * @memberof CloudChatComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.chatService.positionModalContent(
			this.getDrawerIndex());
	}

	/**
	 * Implements the OnInit lifecycle hook.
	 * Initializes the service provider modal chat service.
	 *
	 * @memberof CloudChatComponent
	 */
	public ngOnInit(): void
	{
		this.chatService =
			this.resolver.resolveShared(
				this.sessionService.systemSettings
					.chat
					.modalService);

		this.chatService.context =
				this.context;
	}

	/**
	 * Implements the AfterViewInit lifecycle hook.
	 * Initializes the component after the view has been initialized.
	 *
	 * @async
	 * @memberof CloudChatComponent
	 */
	public async ngAfterViewInit(): Promise<void>
	{
		await this.loadChatSettings();
		if (this.chatOffline === true)
		{
			this.loading = false;

			return;
		}

		// Handle race cases where destroy is called prior to init complete.
		if (this.destroyed === true)
		{
			this.loading = false;

			return;
		}

		this.chatService.openModalChat();
		this.chatService.positionModalContent(
			this.getDrawerIndex());

		this.loading = false;
	}

	/**
	 * Implements the OnDestroy lifecycle hook.
	 * Closes the modal chat if open.
	 *
	 * @memberof CloudChatComponent
	 */
	public ngOnDestroy(): void
	{
		this.destroyed = true;
		this.chatService.closeModalChat();
	}

	/**
	 * Loads the latest chat settings.
	 *
	 * @async
	 * @memberof CloudChatComponent
	 */
	private async loadChatSettings(): Promise<void>
	{
		const settings: IChatSettings =
			await this.getChatConfiguration();

		this.chatOffline =
			settings.offline;
		this.chatOfflineMessage =
			settings.offlineMessage ?? this.chatOfflineMessage;
	}

	/**
	 * Gets updated chat configuration settings.
	 *
	 * @returns {Promise<IChatSettings>}
	 * The chat configuration settings.
	 * @memberof CloudChatComponent
	 */
	private async getChatConfiguration(): Promise<IChatSettings>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.systems;
		const systemInstance: IEntityInstance =
			await this.entityInstanceApiService.get(
				parseInt(
					AppConstants.systemId,
					AppConstants.parseRadix));

		const systemService: ISystemServiceConfiguration =
			ObjectArrayHelper
				.findByProperty<ISystemServiceConfiguration>(
					systemInstance.data.settings?.services,
					AppConstants.commonProperties.name,
					SystemServiceConstants.chat)
					?? { name: AppConstants.empty, settings: [] };

		const chatSettings: IChatSettings =
			<IChatSettings>
			{
				offline:
					JSON.parse(
						ObjectArrayHelper
							.findByProperty<INameValuePair>(
								systemService.settings,
								AppConstants.commonProperties.name,
								SystemServiceConstants.offline)
							?.value ?? 'false'),
				offlineMessage:
					ObjectArrayHelper
						.findByProperty<INameValuePair>(
							systemService.settings,
							AppConstants.commonProperties.name,
							SystemServiceConstants.offlineMessage)
						?.value,
			};

		return chatSettings;
	}

	/**
	 * Gets the drawer index based on the current site layout.
	 *
	 * @returns {number}
	 * The drawer index.
	 * @memberof CloudChatComponent
	 */
	private getDrawerIndex(): number
	{
		const drawers: NodeListOf<HTMLElement> =
			document.querySelectorAll(
				`.${AppConstants.cssClasses.drawer}`);

		const drawerIndex: number =
			Array.from(drawers)
				.findIndex(
					(drawer: HTMLElement) =>
						drawer.childNodes[0].textContent.trim() ===
							this.drawerTitle);

		return drawers.length - drawerIndex;
	}
}