<div class="ui-g-12 no-padding text-center rule-list-container">
	<ng-template
		[ngIf]="loading"
		[ngIfElse]="CommonList">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</ng-template>

	<ng-template
		#CommonList>

		<div class="ui-g-12 nested-display-subtitle text-left">
			<div class="ellipsis-text">
				View: Rules
			</div>
		</div>

		<app-dynamic-component
			[context]="commonListContext"
			displayComponent="CommonListComponent">
		</app-dynamic-component>

	</ng-template>
</div>