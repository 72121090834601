/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component,
	HostListener
} from '@angular/core';
import {
	DomSanitizer
} from '@angular/platform-browser';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ClientMessage
} from '@shared/implementations/application-data/client-message';
import {
	MenuItem
} from 'primeng/api';

@Component({
	selector: 'app-full-screen-banner',
	templateUrl: './app-full-screen-banner.component.html',
	styleUrls: [
		'./app-full-screen-banner.component.scss'
	]
})

/**
 * A component representing a full screen banner display.
 *
 * @export
 * @class AppFullScreenBannerComponent
 */
export class AppFullScreenBannerComponent
{
	/**
	 * Initializes a new instance of the app full screen banner component.
	 *
	 * @param {DomSanitizer} sanitizer
	 * The dom sanitizer used to set safe html values for display
	 * in the message and title of this banner.
	 * @memberof AppFullScreenBannerComponent
	 */
	 public constructor(
		public sanitizer: DomSanitizer)
	{
	}

	/**
	 * Gets or sets the banner message currently displayed in this
	 * component.
	 *
	 * @type {ClientMessage}
	 * @memberof AppFullScreenBannerComponent
	 */
	public clientMessage: ClientMessage;

	/**
	 * Handles the client add full screen banner event.
	 * This will add and display this banner to the user.
	 *
	 * @param {ClientMessage} clientMessage
	 * The client message to be added to this banner component when creating
	 * the add banner event.
	 * @memberof AppFullScreenBannerComponent
	 */
	@HostListener(
		AppEventConstants.addFullScreenBannerEvent,
		[AppEventParameterConstants.clientMessage])
	public addBannerEvent(
		clientMessage: ClientMessage): void
	{
		clientMessage.display = true;
		clientMessage.safeTitle =
			this.sanitizer.bypassSecurityTrustHtml(
				clientMessage.title);
		clientMessage.safeContent =
			this.sanitizer.bypassSecurityTrustHtml(
				clientMessage.content);

		setTimeout(
			() =>
			{
				this.clientMessage = clientMessage;
			});
	}

	/**
	 * Handles the dismiss banner click which will remove the selected banner.
	 *
	 * @param {ClientMessage} clientMessage
	 * The client message to be dismissed.
	 * @param {Event} event
	 * The event for this dismiss banner click.
	 * @memberof AppFullScreenBannerComponent
	 */
	public performBannerActionAndClose(
		event: Event): void
	{
		if (!AnyHelper.isNull(this.clientMessage.actions)
			&& this.clientMessage.actions.length > 0)
		{
			const primaryAction: MenuItem = this.clientMessage.actions[0];
			primaryAction.command({});
			EventHelper.dispatchBannerEvent(
				primaryAction.label,
				primaryAction.queryParams?.details,
				AppConstants.activityStatus.info);
		}

		setTimeout(
			() =>
			{
				this.clientMessage = null;
			});

		event.preventDefault();
		event.stopPropagation();
	}
}