/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Injectable
} from '@angular/core';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	IUser
} from '@shared/interfaces/users/user.interface';

/**
 * An enum representing the menu orientation.
 *
 * @export
 * @enum {number}
 */
export enum MenuOrientation {
	OVERLAY,
	SLIM
}

/**
 * A singleton class representing logic and services for the site layout.

 *
 * @export
 * @class SiteLayoutService
 */
@Injectable({
	providedIn: 'root'
})
export class SiteLayoutService
{
	/**
	 * Creates an instance of SiteLayoutService. Content and site
	 * width based variables are set via the usage of the
	 * ResponsiveLayoutDirective.
	 *
	 * @memberof SiteLayoutService
	 */
	public constructor()
	{
		this.theme = this.getDefaultTheme();
		this.layout = this.getDefaultLayout();
		this.menuMode = MenuOrientation.SLIM;
	}

	/**
	 * Gets or sets the theme.
	 *
	 * @type {string}
	 * @memberof SiteLayoutService
	 */
	public theme: string;

	/**
	 * Gets or sets the layout.
	 *
	 * @type {string}
	 * @memberof SiteLayoutService
	 */
	public layout: string;

	/**
	 * Gets or sets the menu mode.
	 *
	 * @type {MenuOrientation}
	 * @memberof SiteLayoutService
	 */
	public menuMode: MenuOrientation;

	/**
	 * Gets or sets the window or overall display width.
	 *
	 * @type {number}
	 * @memberof SiteLayoutService
	 */
	public windowWidth: number = 0;

	/**
	 * Gets or sets the window or overall display height.
	 *
	 * @type {number}
	 * @memberof SiteLayoutService
	 */
	public windowHeight: number = 0;

	/**
	 * Gets or sets the content width available in layout-main.
	 *
	 * @type {number}
	 * @memberof SiteLayoutService
	 */
	public contentWidth: number = 0;

	/**
	 * Gets or sets the breakpoint width available when comparing
	 * layout breakpoints. This value accounts for an expanded
	 * non-overlay context menu.
	 *
	 * @type {number}
	 * @memberof SiteLayoutService
	 */
	public breakpointWidth: number = 0;

	/**
	 * Gets or sets the content width based css class.
	 * <= 500: 'ui-content-small-phone'
	 * > 500 && <= 640: 'ui-content-phone'
	 * > 640 && <= 800: 'ui-content-tablet'
	 * > 800 && <= 1024: 'ui-content-large-tablet'
	 * > 800 && <= 1360: 'ui-content-desktop'
	 * > 1360: 'ui-content-large-desktop'
	 *
	 * @type {string}
	 * @memberof SiteLayoutService
	 */
	public contentCssClass: string = '';

	/**
	 * Gets or sets the current count of pinned drawers.
	 *
	 * @type {number}
	 * @memberof SiteLayoutService
	 */
	public pinnedDrawerCount: number = 0;

	/**
	 * Gets or sets the context width used by an expanded
	 * context menu.
	 *
	 * @type {number}
	 * @memberof SiteLayoutService
	 */
	public contextMenuCapturedWidth: number = 0;

	/**
	 * Gets the delay used when checking for layout changes.
	 *
	 * @type {number}
	 * @memberof AppComponent
	 */
	public readonly debounceDelay: number = 50;

	/**
	 * Gets the display tablet view property. This defines that the site is
	 * displaying in tablet mode.
	 *
	 * @type {boolean}
	 * @memberof SiteLayoutService
	 */
	public get displayTabletView(): boolean
	{
		return this.windowWidth <= AppConstants.layoutBreakpoints.tablet;
	}

	/**
	 * Gets the default theme.
	 *
	 * @returns {string}
	 * @memberof SiteLayoutService
	 */
	public getDefaultTheme(): string
	{
		// We could add a DB look up or app config for defaults
		return 'darkgrey';
	}

	/**
	 * Gets the default layout.
	 *
	 * @returns {string}
	 * @memberof SiteLayoutService
	 */
	public getDefaultLayout(): string
	{
		// We could add a DB look up or app config for defaults
		return 'cyan';
	}

	/**
	 * Changes the theme css to the specified supported theme.
	 *
	 * @param {string} theme
	 * A string representing the theme to change the skin to.
	 * @memberof SiteLayoutService
	 */
	public changeTheme(
		theme: string): void
	{
		// To Do: Make sure version is in property still
		this.theme = theme.toLocaleLowerCase();
		const themeLink: HTMLLinkElement
			= <HTMLLinkElement>document
				.getElementById('theme-css');

		themeLink.href =
			'assets/theme/theme-'
				+ this.theme + '.css';

		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.themeChanged));
	}

	/**
	 * Changes the layout css to the specified support layout.
	 *
	 * @param {string} layout
	 * A string representing the layout to change the skin to.
	 * @param {boolean} [special]
	 * A value indicating whether the layout is a special skin.
	 * @memberof SiteLayoutService
	 */
	public changeLayout(
		layout: string): void
	{
		this.layout = layout.toLocaleLowerCase();
		const layoutLink: HTMLLinkElement
			= <HTMLLinkElement>document
				.getElementById('layout-css');

		layoutLink.href =
			'assets/layout/css/layout-'
				+ this.layout + '.css';
	}

	/**
	 * Changes the menu mode.
	 *
	 * @param {(string | MenuOrientation)} style
	 * A string or {MenuOrientation} representing the menu mode to change
	 * the skin to.
	 * @returns {void}
	 * @memberof SiteLayoutService
	 */
	public changeMenuMode(
		style: string | MenuOrientation): void
	{
		if (typeof(style) === typeof(MenuOrientation))
		{
			this.menuMode = <MenuOrientation>style;

			return;
		}

		switch ((<string>style).toLocaleLowerCase())
		{
			case 'overlay':
				this.changeToOverlayMenu();
				break;
			case 'slim':
				this.changeToSlimMenu();
				break;
		}
	}

	/**
	 * Changes the menu mode to overlay.
	 *
	 * @memberof SiteLayoutService
	 */
	public changeToOverlayMenu(): void
	{
		this.menuMode = MenuOrientation.OVERLAY;
	}

	/**
	 * Changes the menu mode to slim.
	 *
	 * @memberof SiteLayoutService
	 */
	public changeToSlimMenu(): void
	{
		this.menuMode = MenuOrientation.SLIM;
	}

	/**
	 * Resets the site layout back to defaults.
	 *
	 * @param {boolean} resetColors
	 * A value indicating whether to reset the skin colors.
	 * @memberof SiteLayoutService
	 */
	public reset(
		resetColors: boolean): void
	{
		this.changeToSlimMenu();

		if (resetColors)
		{
			this.changeTheme(this.getDefaultTheme());
			this.changeLayout(this.getDefaultLayout());
		}
	}

	/**
	 * Sets the site layout to the user settings.
	 *
	 * @param {IUser} user
	 * A {IUser} containing settings to set site layout skin to.
	 * @returns {void}
	 * @memberof SiteLayoutService
	 */
	public setFromUserSettings(
		user: IUser): void
	{
		this.reset(false);

		if (!user)
		{
			return;
		}

		const settings: string = 'settings';
		const layoutColor: string = 'layoutColor';
		const themeColor: string = 'themeColor';

		const userLayoutColor: string
			= user.data[settings][layoutColor]
				|| this.getDefaultLayout();

		const userThemeColor: string
			= user.data[settings][themeColor]
				|| this.getDefaultTheme();

		this.changeTheme(
			userThemeColor);
		this.changeLayout(
			userLayoutColor);
	}

	/**
	 * Gets the css class that is currently accurate with the content width.
	 * <= 500: 'ui-content-small-phone'
	 * > 500 && <= 640: 'ui-content-phone'
	 * > 640 && <= 800: 'ui-content-tablet'
	 * > 800 && <= 1024: 'ui-content-large-tablet'
	 * > 800 && <= 1360: 'ui-content-desktop'
	 * > 1360: 'ui-content-large-desktop'
	 *
	 * @returns {string}
	 * The css class that can be decorated into html elements of components
	 * extending the responsive layout directive.
	 * @memberof SiteLayoutService
	 */
	public getContentCssClass(): string
	{
		switch (true) {
			case (this.contentWidth
				<= AppConstants.layoutBreakpoints.smallPhone):
				return AppConstants.responsiveClasses.smallPhoneContent;
			case (this.contentWidth
				<= AppConstants.layoutBreakpoints.phone):
				return AppConstants.responsiveClasses.phoneContent;
			case (this.contentWidth
				<= AppConstants.layoutBreakpoints.tablet):
				return AppConstants.responsiveClasses.tabletContent;
			case (this.contentWidth
				<= AppConstants.layoutBreakpoints.desktop):
				return AppConstants.responsiveClasses.largeTabletContent;
			case (this.contentWidth
				<= AppConstants.layoutBreakpoints.largeDesktop):
				return AppConstants.responsiveClasses.desktopContent;
			default:
				return AppConstants.responsiveClasses.largeDesktopContent;
		}
	}
}