/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	InjectionToken,
	Injector,
	OnInit
} from '@angular/core';
import {
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators
} from '@angular/forms';
import {
	ApiTokenLookup
} from '@api/api-token.lookup';
import {
	ApiError
} from '@api/errors/api.error';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	PageContextDirective
} from '@shared/directives/page-context.directive';
import {
	IApplicationMessage
} from '@shared/interfaces/application-messages/application-message.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	DateTime
} from 'luxon';

@Component({
	selector: 'app-testbuilder',
	templateUrl: './test-builder.component.html',
	styleUrls: [
		'./test-builder.component.scss'
	]
})

/**
 * Used by QA to automate testing services
 *
 * @export
 * @class TestBuilderComponent
 * @extends {PageContextDirective}
 * @implements {OnInit}
 */
export class TestBuilderComponent
	extends PageContextDirective
	implements OnInit
{
	/**
	 * Creates an instance of TestBuilderComponent, and
	 * creates a reactive formBuilder object.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to use for responsive layouts.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @param {Injector} injector
	 * Injects a selected service.
	 * @param {FormBuilder} formBuilder
	 * Builds a form object.
	 * @param {OperationService} operationService
	 * The operation service used in this component.
	 * @memberof TestBuilderComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService,
		private readonly injector: Injector,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly operationService: OperationService)
	{
		super(resolver);

		this.servicesForm = this.formBuilder.group({
			apiService: [AppConstants.empty, Validators.required],
			typeGroup: [AppConstants.empty],
			apiMethod: [AppConstants.empty, Validators.required],
			params: this.formBuilder.array([
				this.createParamItem()
			])
		});
	}

	/**
	 * Gets or Sets the servicesForm.
	 *
	 * @type {FormGroup}
	 * @memberof TestBuilderComponent
	 */
	public servicesForm: UntypedFormGroup;

	/**
	 * Gets or Sets the servicesArray.
	 *
	 * @type {object[]}
	 * @memberof TestBuilderComponent
	 */
	public servicesArray: object[];

	/**
	 * Gets or Sets the injectedService.
	 *
	 * @type {*}
	 * @memberof TestBuilderComponent
	 */
	public injectedService: any;

	/**
	 * Gets or Setsthe methodsArray.
	 *
	 * @type {object[]}
	 * @memberof TestBuilderComponent
	 */
	public methodsArray: object[];

	/**
	 * Gets or Sets the results.
	 *
	 * @type {*}
	 * @memberof TestBuilderComponent
	 */
	public results: any;

	/**
	 * Gets or Sets the showTypeGroup, showing or hiding the
	 * typeGroup form control.
	 *
	 * @type {boolean}
	 * @memberof TestBuilderComponent
	 */
	public showTypeGroup: boolean = false;

	/**
	 * Load array of services from lookup
	 *
	 * @memberof TestBuilderComponent
	 */
	public ngOnInit(): void
	{
		const tempArray: object[] = [];
		Object.keys(ApiTokenLookup.tokens)
			.forEach(
				key => tempArray
					.push({name: key}));

		this.servicesArray = tempArray;

		// Add a blank entry for the dropdown
		this.servicesArray.unshift({ name: '' });
	}

	/**
	 * Temporary method used to throw an Angular error in the
	 * application environment, this is used to test the global
	 * application error handler.
	 *
	 * @memberof TestBuilderComponent
	 */
	public throwAngularError(): void
	{
		throw new Error('Expected Angular error.');
	}

	/**
	 * Temporary method used to throw an HttpResponseError in the
	 * application environment, this is used to test the global
	 * application error handler.
	 *
	 * @memberof TestBuilderComponent
	 */
	public throwHttpResponseError(): void
	{
		this.operationService.populateOperationGroup(
			'TestFailure',
			true);
	}

	/**
	 * Temporary method used to throw an ApiError in the
	 * application environment, this is used to test the global
	 * application error handler.
	 *
	 * @memberof TestBuilderComponent
	 */
	public throwApiError(): void
	{
		throw new ApiError(
			'Expected ApiError',
			[
				<IApplicationMessage>
				{
					title: 'Error Title 1',
					name: 'Error Name 1',
					description: 'Error Description 1',
					date: DateTime.utc().toISO(),
					message: 'Test Error 1',
					messages: []
				},
				<IApplicationMessage>
				{
					title: 'Error Title 2',
					name: 'Error Name 2',
					description: 'Error Description 2',
					date: DateTime.utc().toISO(),
					message: 'Test Error 2',
					messages: []
				}
			]);
	}

	/**
	 * Injects selected service to component and gets it's methods.
	 *
	 * @param {*} value // Comes from the dropdown.
	 * @memberof TestBuilderComponent
	 */
	public onSelectService(value: any): void
	{
		this.isEntityInstanceApiService(value.name);

		this.resetMethodsDropdown();

		this.resetParam();

		this.results = null;

		this.injectedService = this.injector.get(
			ApiTokenLookup.tokens[value.name] as InjectionToken<string>);

		// Get the Prototype of the Service and it's Base.
		const ownMethodsObject = Object.getPrototypeOf(this.injectedService);
		const parentMethodsObject = Object.getPrototypeOf(ownMethodsObject);

		// Merge the two methods lists, sort and remove duplicates
		this.methodsArray = this.getAllMethods(ownMethodsObject);
		this.methodsArray =
			this.methodsArray.concat(
				this.getAllMethods(parentMethodsObject));

		this.methodsArray.sort(
			(firstElement, secondElement) =>
			{
				const firstName = (<any>firstElement).name.toLowerCase();
				const secondName = (<any>secondElement).name.toLowerCase();

				if (firstName < secondName)
				{
					return -1;
				}
				if (firstName > secondName)
				{
					return 1;
				}

				return 0;
			});

		// Add blank option to dropdown.
		this.methodsArray.unshift({ name: '' });
	}

	/**
	 * Handle events for when a method is chosen.
	 *
	 * @memberof TestBuilderComponent
	 */
	public onSelectMethod(): void
	{
		// Reset the parameters input
		this.resetParam();

		this.results = null;
	}

	/**
	 * Submit the form, calling a service's method passing in parameters.
	 *
	 * @memberof TestBuilderComponent
	 */
	public onSubmit(): void
	{
		// If selected Service is EntityInstanceApiService
		// set the typegroup property from the text field
		const typeGroupValue = this.servicesForm.get('typeGroup').value;

		this.injectedService.typeGroup = (typeGroupValue)
			? typeGroupValue
			: null;

		this.results = null;

		const selectedMethod =
			this.servicesForm.get('apiMethod').value;

		// Massage the Params (converting to object if needed)
		const paramsList = (<any>this.servicesForm.get('params')).controls
			.map(
				(paramItem: any) =>
				{
					let tempParam: any = paramItem.controls.paramValue.value;
					if (paramItem.controls.isParamObject.value)
					{
						tempParam = JSON.parse(tempParam);
					}

					return tempParam;
				}
			);

		try
		{
			this.injectedService[selectedMethod.name]
				.apply(this.injectedService, paramsList || [])
				.then(
					(data: any) => this.results = data || 'SUCCESS!'
				)
				.catch(
					(error: any) =>
					{
						this.results =
							'ERROR. Unable to complete this request.';
						throw error;
					}
				);
		}
		catch (error)
		{
			this.results = 'ERROR. Choose a different API method call.';
			throw error;
		}
	}

	/**
	 * Add a param form control to the form.
	 *
	 * @memberof TestBuilderComponent
	 */
	public addParam(): void
	{
		const paramsArray: UntypedFormArray =
			this.servicesForm.get('params') as UntypedFormArray;
		paramsArray.push(this.createParamItem());
	}

	/**
	 * Remove a param form control from the form.
	 *
	 * @param {any} index
	 * The index of the selected control to remove.
	 * @memberof TestBuilderComponent
	 */
	public removeParam(index: any): void
	{
		const paramsArray: UntypedFormArray =
			this.servicesForm.get('params') as UntypedFormArray;
		paramsArray.removeAt(index);
	}

	/**
	 * Reset the params FormArray.
	 *
	 * @memberof TestBuilderComponent
	 */
	public resetParam(): void
	{
		const paramsArray: UntypedFormArray =
			this.servicesForm.get('params') as UntypedFormArray;
		paramsArray.clear();
		paramsArray.push(this.createParamItem());
	}

	/**
	 * Reset the api method dropdown.
	 *
	 * @memberof TestBuilderComponent
	 */
	public resetMethodsDropdown(): void
	{
		this.methodsArray = [];
		this.servicesForm.get('apiMethod')
			.setValue('');
	}

	/**
	 * Create FormGroup object for params FormArray
	 *
	 * @private
	 * @returns {FormGroup}
	 * @memberof TestBuilderComponent
	 */
	private createParamItem(): UntypedFormGroup
	{
		return this.formBuilder.group({
			isParamObject: false,
			paramValue: ''
		});
	}

	/**
	 * Gets all the methods of a class.
	 *
	 * @private
	 * @param {*} object The protoype of the class
	 * @returns array of strings
	 * @memberof TestBuilderComponent
	 */
	private getAllMethods(object: any): object[]
	{
		return Object.getOwnPropertyNames(object)
			.filter(function(property)
			{
				return typeof object[property] === 'function' &&
					property !== 'constructor';
			})
			.map((methodName: string) =>
				({ name: methodName })
			);
	}

	/**
	 * Checks if the selected service is EntityInstanceApiService.
	 *
	 * @private
	 * @param {string} serviceName Name from service dropdown.
	 * @memberof TestBuilderComponent
	 */
	private isEntityInstanceApiService(serviceName: string): void
	{
		this.showTypeGroup = (serviceName === 'EntityInstanceApiService')
			? true
			: false;

		if (!this.showTypeGroup)
		{
			this.servicesForm.get('typeGroup')
				.setValue('');
		}
	}
}