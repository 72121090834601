/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	ChangeDetectorRef,
	Component,
	OnInit
} from '@angular/core';
import {
	IBaseEntity
} from '@api/interfaces/base/base-entity.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'associated-entity-list',
	templateUrl: './associated-entity-list.component.html',
	styleUrls: [
		'./associated-entity-list.component.scss'
	]
})

/**
 * A component representing a list for displaying summary details
 * of objects.
 *
 * @export
 * @class AssociatedEntityListComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, IAssociatedEntityListContext}
 */
export class AssociatedEntityListComponent
implements OnInit,
	IDynamicComponent<Component, IAssociatedEntityListContext>
{
	/**
	 * Initializes a new instance of the associated entity list component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used for responsive layouts.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used to gather associated entities.
	 * @param {InsuranceService} insuranceService
	 * The insurance service component.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The change detector component.
	 * @memberof AssociatedEntityListComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public entityInstanceApiService: EntityInstanceApiService,
		public insuranceService: InsuranceService,
		public changeDetectorReference: ChangeDetectorRef)
	{
	}

	/**
	 * Gets or sets the loading value of this component.
	 *
	 * @type {boolean}
	 * @memberof AssociatedEntityListComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the list of associated entities to display
	 * in this list.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof AssociatedEntityListComponent
	 */
	public data: IEntityInstance[] = [];

	/**
	 * Gets or sets the association definition.
	 *
	 * @type {any[]}
	 * @memberof AssociatedEntityListComponent
	 */
	public associationDefinition: any[] = [];

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IAssociatedEntityListContext}
	 * @memberof AssociatedEntityListComponent
	 */
	public context:
		IDynamicComponentContext<Component, IAssociatedEntityListContext>;

	/**
	 * Gets or sets the no results found message.
	 *
	 * @type {string}
	 * @memberof AssociatedEntityListComponent
	 */
	public noResultsMessage: string = AppConstants.noResultsFoundMessage;

	/**
	 * Gets the limit to use when querying for associated entities.
	 *
	 * @type {number}
	 * @memberof AssociatedEntityListComponent
	 */
	private readonly listLimit: number = 100;

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @memberof AssociatedEntityListComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!AnyHelper.isNullOrEmpty(this.context.data.associationDataPromise))
		{
			this.data =	await StringHelper.transformToDataPromise(
				this.context.data.associationDataPromise,
				this.context);
		}
		else
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				this.context.data.entityTypeGroup;

			const entityRelationship: string =
				this.context.data.entityRelationship?.toLowerCase();

			const associatedEntityTypeGroup: string =
				!AnyHelper.isNullOrEmpty(
					this.context.data.associatedEntityTypeGroup)
					? this.context.data.associatedEntityTypeGroup
					: AppConstants.empty;

			const associatedEntities: IEntityInstance[] =
				entityRelationship === 'children'
					? await this.entityInstanceApiService.getChildren(
						this.context.data.entityId,
						null,
						null,
						null,
						this.listLimit,
						associatedEntityTypeGroup)
					: await this.entityInstanceApiService.getParents(
						this.context.data.entityId,
						null,
						null,
						null,
						this.listLimit,
						associatedEntityTypeGroup);

			if (associatedEntities?.length > 0
				&& !AnyHelper.isNullOrEmpty(associatedEntityTypeGroup))
			{
				const commaSeparatedIdArray: string =
					associatedEntities
						.map((item: IBaseEntity) => item.id)
						.join(AppConstants.characters.comma);

				this.entityInstanceApiService.entityInstanceTypeGroup =
					this.context.data.associatedEntityTypeGroup;
				this.data =
					await this.entityInstanceApiService.query(
						`(Id IN (${commaSeparatedIdArray}))`,
						'Id DESC',
						0,
						this.listLimit);
			}
			else
			{
				this.data = associatedEntities;
			}
		}

		this.associationDefinition =
			!AnyHelper.isNullOrEmpty(this.context.data.associationDefinition)
				? JSON.parse(this.context.data.associationDefinition)
				: this.associationDefinition;

		this.loading = false;
		this.changeDetectorReference.detectChanges();
	}

	/**
	 * Gets the item context for the child associated entity item
	 * component. Each list item is created from this context.
	 *
	 * @param {IEntityInstance} entityInstance
	 * The entity instance to gather a context for.
	 * @returns {IDynamicComponentContext<Component, {
			entityInstance: IEntityInstance,
			entityListContext: IAssociatedEntityListContext
		}>}
	 * A context ready for individual list item displays.
	 * @memberof AssociatedEntityListComponent
	 */
	public getItemContext(
		entityInstance: IEntityInstance): IDynamicComponentContext<Component, {
			entityInstance: IEntityInstance;
			entityListContext: IAssociatedEntityListContext;
			associationDefinition: any;
		}>
	{
		return <IDynamicComponentContext<Component, {
			entityInstance: IEntityInstance;
			entityListContext: IAssociatedEntityListContext;
			associationDefinition: any;
		}>>
			{
				data: {
					entityInstance: entityInstance,
					entityListContext: this.context.data,
					associationDefinition: this.associationDefinition
				},
				source: this.context.source
			};
	}
}