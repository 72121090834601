/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';

@Component({
	selector: 'custom-textarea',
	templateUrl: './custom-text-area.component.html',
	styleUrls: [
		'./custom-text-area.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Input Text Area.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomInputTextAreaComponent
 * @extends {FieldType
 */
export class CustomInputTextAreaComponent
	extends ExtendedCustomControlDirective
{
	/** Initializes a new instance of the CustomInputTextAreaComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomInputTextAreaComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}
}