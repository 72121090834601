<app-base-page pageTitle="Search Reports"
	[pageContext]="pageContext"
	utilityMenuOperationGroupName="">
	<div class="ui-g-6 search-filter"
		[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
		<div>
			<app-dynamic-formly
				#DynamicFormlyComponent
				[layoutSchema]="layoutSchema">
			</app-dynamic-formly>
		</div>
		<button type="button"
			pButton
			icon="fa fa-search"
			styleClass="p-button-primary"
			*ngIf="loadingTableDefinitions === false"
			[disabled]="!this.validForm"
			(click)="searchCriteria()">
		</button>
		<div class="search-tooltip-container"
			*ngIf="loadingTableDefinitions === false">
			<app-common-icon-tooltip
				content="<div>Search terms entered here will filter down to only the items that hold all of the search terms.<ul><li>In order to search for dates, please use the following format 'YYYY-MM-DD'.</li><li>In order to search for numerical values, please enter the number without commas or format characters.</li></ul></div>"
				[escape]="false">
			</app-common-icon-tooltip>
		</div>
	</div>

	<div class="ui-g-12 no-horizontal-padding text-center"
		*ngIf="loadingTableDefinitions === true"
		[@contentAnimation]="state">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>

	<div class="ui-g-12 report-search"
		*ngIf="loadingTableDefinitions === false"
		[@contentAnimation]="state">
		<app-common-table
			[tableDefinitions]="tableDefinition"
			[useIconBar]="true">
		</app-common-table>
	</div>

</app-base-page>