/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AfterViewInit,
	Component,
	ContentChild,
	ElementRef,
	HostListener,
	Input,
	OnChanges,
	SimpleChanges
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

@Component({
	selector: 'app-base-page',
	templateUrl: './base-page.component.html',
	styleUrls: ['./base-page.component.scss'],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of a base page.
 *
 * @export
 * @class BasePageComponent
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 */
export class BasePageComponent
implements OnChanges, AfterViewInit
{
	/**
	 * Initializes a new instance of the BasePageComponent.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used to define responsive
	 * layout variables.
	 * @param {ModuleService} moduleService
	 * The module service used to track and display module level data.
	 * @memberof BasePageComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public moduleService: ModuleService)
	{
	}

	/**
	 * Gets or sets the page title.
	 *
	 * @type {string}
	 * @memberof BasePageComponent
	 */
	@Input() public pageTitle: string;

	/**
	* Gets or sets the page context.
	*
	* @type {IDynamicComponentContext<Component, any>}
	* @memberof BasePageComponent
	*/
	@Input() public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the primary page loading value.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	@Input() public loading: boolean = false;

	/**
	 * Gets or sets the primary page title to display while loading.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	@Input() public loadingPageTitle: string;

	/**
	 * Gets or sets the page operation group display name. This will
	 * be displayed as the operation group is loading.
	 *
	 * @type {string}
	 * @memberof BasePageComponent
	 */
	@Input() public operationGroupDisplayName: string;

	/**
	 * Gets or sets the page operation group initial operation set.
	 *
	 * @type {MenuItem[]}
	 * @memberof BasePageComponent
	 */
	@Input() public operationGroupInitialModel: MenuItem[] = [];

	/**
	 * Gets or sets the page operation group to load.
	 *
	 * @type {string}
	 * @memberof BasePageComponent
	 */
	@Input() public operationGroupName: string;

	/**
	* Gets or sets the page context operation group name. This will
	* be displayed as the second level of the context menu.
	*
	* @type {string}
	* @memberof BasePageComponent
	*/
	@Input() public pageContextOperationGroupName: string;

	/**
	 * Gets or sets the utility menu operation group name.
	 *
	 * @type {string}
	 * @memberof BasePageComponent
	 */
	@Input() public utilityMenuOperationGroupName: string = AppConstants.empty;

	/**
	 * Gets or sets the reserve bottom right value of this base page header.
	 * This is primarily used for the filter parameter button and will reserve
	 * thirty two pixels at the bottom right. At desktop content size or larger,
	 * this will reserve the right bottom half of content for more details.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	@Input() public reserveHeaderBottomRight: boolean = false;

	/**
	 * Gets or sets the name identifying a desired active drawer component
	 * in the utility menu for this base page display.
	 *
	 * @type {string}
	 * @memberof BasePageComponent
	 */
	@Input() public activeDrawerComponent: string;

	/**
	 * Gets or sets the name identifying a desired active drawer item that will
	 * be available in the sent active drawer component.
	 *
	 * @type {string}
	 * @memberof BasePageComponent
	 */
	@Input() public activeDrawerItemDescription: string;

	/**
	 * Gets or sets the operation group redraw.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	@Input() public operationGroupRedraw: boolean = false;

	/**
	 * Gets or sets the additional header content.
	 * This is defined in calling components via the #AdditionalHeaderContent
	 * attribute. This is used to define if this content is found or not and
	 * used to show either a one or two column layout header depending.
	 *
	 * @type {ElementRef}
	 * @memberof BasePageComponent
	 */
	@ContentChild('AdditionalHeaderContent')
	public additionalHeaderContent: ElementRef;

	/**
	 * Gets or sets the additional header content.
	 * This is defined in calling components via the #HeaderContextContent
	 * attribute. This is used to define if this content is found.
	 *
	 * @type {ElementRef}
	 * @memberof BasePageComponent
	 */
	@ContentChild('HeaderContextContent')
	public headerContextContent: ElementRef;

	/**
	 * Gets or sets the operation group exists value which will define if
	 * this component should have an operation group to display.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	public operationGroupExists: boolean = false;

	/**
	 * Gets or sets the base page class. This is used to extend
	 * the content css class for accurate tablet calculations.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	public basePageCssClass: string;

	/**
	 * Gets or sets the expanded value of the context menu.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	public contextMenuExpanded: boolean = false;

	/**
	 * Gets or sets the expanded value of the page header.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	public headerExpanded: boolean = true;

	/**
	 * Gets or sets the displayed value of the button bar.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	public operationButtonBarDisplayed: boolean = false;

	/**
	 * Gets the value used by the UI to define at which content
	 * width we should switch to a two column page header layout.
	 *
	 * @type {number}
	 * @memberof BasePageComponent
	 */
	public readonly twoColumnHeaderBreakpoint: number =
		AppConstants.layoutBreakpoints.desktop;

	/**
	 * Handles the site layout changed event. This will calculate
	 * the current display mode of this base page as tablet or
	 * desktop.
	 *
	 * @memberof BasePageComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.basePageCssClass =
			this.siteLayoutService.displayTabletView === true
				? 'base-page-tablet-display'
				: AppConstants.empty;
	}

	/**
	 * Handles the display context overlay event. This will hide or
	 * show the mask related to an overlay displayed context menu.
	 * This will be the primary overlay.
	 *
	 * @param {boolean} overlay
	 * The value of whether or not the overlay should be displayed.
	 * @memberof BasePageComponent
	 */
	@HostListener(
		AppEventConstants.displayContextOverlayEvent,
		[AppEventParameterConstants.overlay])
	public displayContextOverlay(
		overlay: boolean): void
	{
		DocumentHelper.setElementDisplay(
			'.context-layout-mask',
			overlay);
	}

	/**
	 * Handles the display drawer overlay event. This will hide or
	 * show the mask related to an overlay displayed drawer.
	 *
	 * @param {boolean} overlay
	 * The value of whether or not the overlay should be displayed.
	 * @memberof BasePageComponent
	 */
	@HostListener(
		AppEventConstants.displayDrawerOverlayEvent,
		[AppEventParameterConstants.overlay])
	public displayDrawerOverlay(
		overlay: boolean): void
	{
		DocumentHelper.setElementDisplay(
			'.drawer-layout-mask',
			overlay);
	}

	/**
	 * Handles the on changes event.
	 * This event will watch for changes to the utility menu operation
	 * group and notify the utility menu when altered.
	 *
	 *
	 * @param {SimpleChanges} changes
	 * The change event sent to this on changes handler.
	 * @memberof BasePageComponent
	 */
	public ngOnChanges(
		changes: SimpleChanges): void
	{
		if (!AnyHelper.isNull(changes.utilityMenuOperationGroupName))
		{
			setTimeout(
				() =>
				{
					EventHelper.dispatchSetUtilityMenuEvent(
						changes.utilityMenuOperationGroupName.currentValue,
						this.activeDrawerComponent,
						this.activeDrawerItemDescription);
				});
		}

		if (!AnyHelper.isNull(changes.operationGroupName)
			&& !AnyHelper.isNullOrWhitespace(
				changes.operationGroupName.currentValue))
		{
			this.operationGroupExists = true;
		}
	}

	/**
	 * Handles the on after view init event.
	 * This will dispatch a change in the current page context
	 * to listening components such as the AppComponent.
	 *
	 * @type {boolean}
	 * @memberof BasePageComponent
	 */
	public ngAfterViewInit(): void
	{
		this.operationGroupExists =
			!AnyHelper.isNullOrWhitespace(this.operationGroupName)
				|| this.operationGroupInitialModel?.length > 0;

		EventHelper.dispatchSetContentContextEvent(
			this.pageContext);

		setTimeout(
			() =>
			{
				this.siteLayoutChanged();
			},
			this.siteLayoutService.debounceDelay);
	}

	/**
	 * Handles the context menu expansion event sent from the child
	 * context menu. This is used to set the context menu expanded
	 * value.
	 *
	 * @param {boolean} expanded
	 * The value of whether or not the context menu is expanded.
	 * @memberof BasePageComponent
	 */
	public contextMenuExpansion(
		expanded: boolean): void
	{
		if (this.contextMenuExpanded !== expanded)
		{
			this.contextMenuExpanded = expanded;
			EventHelper.dispatchContextMenuActiveEvent(
				this.contextMenuExpanded === true);
		}
	}

	/**
	 * Handles the click the expansion arrow button attached to the
	 * page header. This will open or close the header on click
	 * based on it's current state.
	 *
	 * @memberof BasePageComponent
	 */
	public headerExpansion(): void
	{
		this.headerExpanded = !this.headerExpanded;

		// Ensure watchers of site changes capture this layout change.
		setTimeout(
			() =>
			{
				EventHelper.dispatchSiteLayoutChangedEvent();
			},
			this.siteLayoutService.debounceDelay);
	}

	/**
	 * Handles the click the button bar item count changed event. This will
	 * set the displayed value of this button bar based on the existence
	 * of operations.
	 *
	 * @param {number} itemCount
	 * The current item count of operations displayed.
	 * @memberof BasePageComponent
	 */
	public buttonBarItemCountChanged(
		itemCount: number): void
	{
		this.operationButtonBarDisplayed = itemCount > 0;
	}
}