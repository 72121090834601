/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	InjectionToken
} from '@angular/core';
import {
	EmailValidator
} from '@shared/validators/email.validator';
import {
	MaximumLengthValidator
} from '@shared/validators/maximum-length.validator';
import {
	MaximumNumberValidator
} from '@shared/validators/maximum-number.validator';
import {
	MinimumLengthValidator
} from '@shared/validators/minimum-length.validator';
import {
	MinimumNumberValidator
} from '@shared/validators/minimum-number.validator';
import {
	PasswordValidator
} from '@shared/validators/password.validator';
import {
	RegularExpressionValidator
} from '@shared/validators/regular-expression.validator';
import {
	RequiredValidator
} from '@shared/validators/required.validator';

/*
 * Export all tokens to be used in the dependency injector for
 * string based resolution
*/
export const EmailValidatorToken =
	new InjectionToken<EmailValidator>(
		'Email');
export const MaximumLengthValidatorToken =
	new InjectionToken<MaximumLengthValidator>(
		'MaximumLength');
export const MinimumLengthValidatorToken =
	new InjectionToken<MinimumLengthValidator>(
		'MinimumLength');
export const MaximumNumberValidatorToken =
	new InjectionToken<MaximumNumberValidator>(
		'MaximumNumber');
export const MinimumNumberValidatorToken =
	new InjectionToken<MinimumNumberValidator>(
		'MinimumNumber');
export const PasswordValidatorToken =
	new InjectionToken<PasswordValidator>(
		'Password');
export const RegularExpressionValidatorToken =
	new InjectionToken<RegularExpressionValidator>(
		'RegularExpressionFormat');
export const RequiredValidatorToken =
	new InjectionToken<RequiredValidator>(
		'Required');

/**
 * A lookup to be shared accross the application used for presentation logic
 * available in the formly rules system.
 *
 * @export
 * @class RuleTokenLookup
 */
export class RuleTokenLookup
{
	/**
	 * Dictionary to be used in dependency injector for
	 * string based resolution.
	 *
	 * @static
	 * @memberof RuleTokenLookup
	 */
	public static tokens =
		{
			'Email':
				EmailValidatorToken,
			'MaximumLength':
				MaximumLengthValidatorToken,
			'MinimumLength':
				MinimumLengthValidatorToken,
			'MaximumNumber':
				MaximumNumberValidatorToken,
			'MinimumNumber':
				MinimumNumberValidatorToken,
			'Password':
				PasswordValidatorToken,
			'RegularExpressionFormat':
				RegularExpressionValidatorToken,
			'Required':
				RequiredValidatorToken
		};
}