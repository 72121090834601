/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'verify-coverage-claim',
	templateUrl: './verify-coverage-claim.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard step for verifying coverage.
 *
 * @export
 * @class VerifyCoverageClaimComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class VerifyCoverageClaimComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the verify coverage component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation..
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof VerifyCoverageClaimComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof VerifyCoverageClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof VerifyCoverageClaimComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof VerifyCoverageClaimComponent
	 */
	private claimId: number;

	/**
	 * Gets or sets the claim parent id.
	 *
	 * @type {number}
	 * @memberof VerifyCoverageClaimComponent
	 */
	private claimParentId: number;

	/**
	 * Gets or sets the claim parent name.
	 *
	 * @type {string}
	 * @memberof VerifyCoverageClaimComponent
	 */
	private claimParentName: string;

	/**
	 * Gets or sets the transaction id.
	 *
	 * @type {string}
	 * @memberof VerifyCoverageClaimComponent
	 */
	private transactionId: string;

	/**
	 * Gets or sets the transaction system.
	 *
	 * @type {string}
	 * @memberof VerifyCoverageClaimComponent
	 */
	private transactionSystem: string;

	/**
	 * Gets or sets the insurance company instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof VerifyCoverageClaimComponent
	 */
	private transactionInsuranceCompany: IEntityInstance;

	/**
	 * Gets or sets date of loss of the claim.
	 *
	 * @type {string}
	 * @memberof VerifyCoverageClaimComponent
	 */
	private lossDate: string;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof VerifyCoverageClaimComponent
	 */
	private currentData: any;

	/**
	 * Gets or sets the insurance company data.
	 *
	 * @type {object}
	 * @memberof VerifyCoverageClaimComponent
	 */
	private insuranceCompanyData: object;

	/**
	 * Implements the on initialization interface.
	 *
	 * @memberof VerifyCoverageClaimComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.claimId = this.currentData.data.id;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const claimParent: IEntityInstance[] =
			await this.entityInstanceApiService.getParents(
				this.claimId,
				AppConstants.empty,
				AppConstants.empty,
				0,
				1,
				ClaimConstants.serviceProviderEntityTypeGroups
					.insuranceCompany);

		this.claimParentId = claimParent[0].id;

		this.claimParentName = claimParent[0].data.name.legalName;

		this.lossDate = this.currentData.data.claim.keyDates.lossDate;

		this.transactionId = this.currentData.data.policy.identifier;

		this.transactionSystem = this.currentData.data.policy.system;

		this.insuranceCompanyData =
			await this.validateInsuranceCompany(
				this.claimParentId,
				this.claimParentName,
				this.transactionId,
				this.transactionSystem,
				this.lossDate
			);

		this.context.source.addToNext(this.create.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof VerifyCoverageClaimComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the claim create event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof VerifyCoverageClaimComponent
	 */
	public async create(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		await this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimId,
					ClaimConstants.workflowActions.verifyCoverage,
					null,
					`parentId=${this.claimId}`
						+ `&identifier=${this.transactionId}`
						+ `&system=${this.transactionSystem}`
						+ `&date=${this.lossDate}`),
				'<strong>Importing Policy</strong>',
				'<strong>Policy Imported</strong>',
				'Policy information has been imported.',
				'Policy information has not been imported.'));

		await this.navigateToClaimSummary(
			this.claimId,
			ClaimConstants.claimEntityTypeGroups.claims);
	}

	/**
	 * This will validate and return the correct insurance company
	 * for the claim.
	 *
	 * @async
	 * @param {number} claimParentId
	 * The claim parent entity id.
	 * @param {string} claimParentName
	 * The claim parent entity legal name.
	 * @param {string} transactionId
	 * The transaction entity id.
	 * @param {string} transactionSystem
	 * The transaction entity system name.
	 * @param {string} lossDate
	 * The date of loss of the claim.
	 * @memberof VerifyCoverageClaimComponent
	 */
	private async validateInsuranceCompany(
		claimParentId: number,
		claimParentName: string,
		transactionId: string,
		transactionSystem: string,
		lossDate: string): Promise<object>
	{
		this.insuranceCompanyData =
			{
				label: claimParentName,
				value: claimParentId
			};

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.serviceProviderEntityTypeGroups.insuranceCompany;

		const insuranceCompanies: IEntityInstance[] =
			await ApiHelper.getFullDataSet(
				this.entityInstanceApiService,
				AppConstants.empty,
				'name');

		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.systems;

		const actionResponse: IActionResponse =
			await this.entityInstanceApiService.executeAction(
				parseInt(
					AppConstants.systemId,
					AppConstants.parseRadix),
				'PolicyLocator',
				null,
				`identifier=${transactionId}`
					+ `&system=${transactionSystem}`
						+ `&action=Get&date=${lossDate}`
			);

		const transactionInsuranceCompanyId: number =
			parseInt(
				(<any>actionResponse).body.value.insuranceCompany.identifier,
				AppConstants.parseRadix);

		const transactionInsuranceCompanyName: string =
			(<any>actionResponse).body.value.insuranceCompany.name;

		this.transactionInsuranceCompany =
			insuranceCompanies.find(
				company =>
					company.id === transactionInsuranceCompanyId
						|| company.data?.name?.legalName ===
							transactionInsuranceCompanyName);

		if (!AnyHelper.isNullOrEmpty(this.transactionInsuranceCompany)
				&& claimParentId !== this.transactionInsuranceCompany.id)
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				ClaimConstants.serviceProviderEntityTypeGroups.insuranceCompany;

			await this.entityInstanceApiService
				.assignChild(
					this.transactionInsuranceCompany.id,
					this.claimId);

			await this.entityInstanceApiService
				.unAssignChild(
					this.claimParentId,
					this.claimId);

			this.insuranceCompanyData =
				{
					label: this.transactionInsuranceCompany.data.name.legalName,
					value: this.transactionInsuranceCompany.id
				};
		}

		return this.insuranceCompanyData;
	}

	/**
	 * This will navigate to the claim summary dashboard the claim id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof VerifyCoverageClaimComponent
	 */
	private async navigateToClaimSummary(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof VerifyCoverageClaimComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.insuranceCompanyId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						placeholder: 'Select an Option',
						showClear: true,
						disabled: true,
						options: [
							this.insuranceCompanyData
						]
					}
				}
			];
	}
}