<div class="ui-g-12 no-padding text-center policy-details-container"
	[ngClass]="{
		'no-margin': false
	}">
	<ng-template #Loading
		[ngIf]="loading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>
	<ng-template #Content>
		<ng-template #Invalid
			[ngIf]="isValid() === false
				|| isOwnershipAllowed === false">
			<div class="ui-g-12 text-center">
				<div *ngIf="isOwnershipAllowed === true">
					This view is not currently available.
				</div>
				<div *ngIf="isOwnershipAllowed === false">
					<app-access-denied-content
						[drawerDisplay]="true"
						drawerName="Policy Details"
						[sessionIdentifier]="sessionIdentifier"
						[accessDeniedUrl]="accessDeniedUrl"
						[resources]="resources"
						[clientMessage]="clientMessage">
					</app-access-denied-content>
				</div>
			</div>
		</ng-template>
		<ng-template #NoResults
			[ngIf]="!loading && claimPolicy.length === 0"
			[ngIfElse]="NestedContent">
			<div class="ui-g-12 text-center">
				No results found.
			</div>
		</ng-template>
		<ng-template #NestedContent>
			<div class="ui-g-12 text-left no-padding">
				<div class="ui-g-12 nested-display-header text-left">
					<span class="nested-display-actions">
						<ng-template
							ngFor
							let-action
							[ngForOf]="tabItems">
							<span [ngClass]="{
									'selected-bottom-border': (action.id === tab)
								}">
								<app-common-icon-tooltip
									content="{{action.label}}"
									iconClass="fa fa-fw {{action.icon}} theme-color cursor-pointer"
									position="left"
									styleClass="utility-menu-tooltip"
									(iconClick)="action.command($event)">
								</app-common-icon-tooltip>
							</span>
						</ng-template>
					</span>
				</div>
				<p-scrollPanel styleClass="entity-container">
					<ng-template #SummaryContent
						[ngIf]="tab === 'summary'">
						<div class="ui-g-12 no-padding">
							<div class="ui-g-12 no-padding dynamic-form-content"
								(click)="handleClick($event)">
								<app-dynamic-formly
									[dataSet]="context.data.claimPolicy"
									[layoutSchema]="dynamicFormlyLayoutSummary"
									[context]="context">
								</app-dynamic-formly>
							</div>
						</div>
					</ng-template>
					<ng-template #CoverageContent
						[ngIf]="tab === 'coverages'">
						<div class="ui-g-12 no-padding">
							<div class="ui-g-12 no-padding dynamic-form-content">
								<app-dynamic-formly
									[dataSet]="context.data.claimPolicy"
									[layoutSchema]="dynamicFormlyLayoutCoverages"
									[context]="context">
								</app-dynamic-formly>
							</div>
						</div>
					</ng-template>
					<ng-template #InterestsContent
						[ngIf]="tab === 'interests'">
						<div class="ui-g-12 no-padding">
							<div class="ui-g-12 no-padding dynamic-form-content">
								<app-dynamic-formly
									[dataSet]="context.data.claimPolicy"
									[layoutSchema]="dynamicFormlyLayoutInterests"
									[context]="context">
								</app-dynamic-formly>
							</div>
						</div>
					</ng-template>
					<ng-template #IncidentsContent
						[ngIf]="tab === 'incidents'">
						<div class="ui-g-12 no-padding">
							<div class="ui-g-12 no-padding dynamic-form-content">
								<app-dynamic-formly
									[dataSet]="context.data.claimPolicy"
									[layoutSchema]="dynamicFormlyLayoutIncidents"
									[context]="context">
								</app-dynamic-formly>
							</div>
						</div>
					</ng-template>
				</p-scrollPanel>
			</div>
		</ng-template>
	</ng-template>
</div>