<app-base-page [pageTitle]="pageTitle"
	[pageContext]="pageContext"
	[operationGroupName]="operationGroupName"
	[reserveHeaderBottomRight]="reserveHeaderBottomRight"
	[pageContextOperationGroupName]="pageContextOperationGroupName"
	[utilityMenuOperationGroupName]="utilityMenuOperationGroupName">

	<div #AdditionalHeaderContent
		additional-header-content
		*ngIf="informationMenuItems.length > 0">
		<div *ngIf="!loading"
			[@contentAnimation]="state">
			<app-information-menu
				[initialLoadComplete]="informationMenuItemsLoaded"
				[informationMenuItems]="informationMenuItems">
			</app-information-menu>
		</div>
	</div>

	<div #HeaderContextContent
		header-context-content
		*ngIf="loading === false && parameterLayoutSchema != null">
		<div class="base-page-filter-container {{siteLayoutService.contentCssClass}}">
			<app-display-component-parameter-header
				[parameterLayoutData]="parameterLayoutData"
				[parameterLayoutSchema]="parameterLayoutSchema"
				[displayParameterOption]="parameterLayoutSchema != null"
				[displaySecondaryOption]="false"
				[displayEditOption]="false"
				[display]="true"
				[displayingSettings]="settingsActive"
				(displaySettingsClick)="settingsClicked()">
			</app-display-component-parameter-header>

			<div class="header-parameter-container"
				[ngClass]="{
					'display-none': settingsActive === false
				}">
				<app-display-component-parameter
					[displayComponentInstance]="displayComponentInstance"
					[parameterLayoutData]="parameterLayoutData"
					[parameterLayoutSchema]="parameterLayoutSchema"
					[pageContext]="pageContext"
					[settingsActive]="settingsActive"
					[alwaysDisplayCancel]="false"
					[pageHeaderParameters]="true"
					(appliedParameters)="applyParameters()"
					(cancelClicked)="cancelClicked()">
				</app-display-component-parameter>
			</div>
		</div>
	</div>

	<div class="ui-g-12 generic-base-page no-padding {{siteLayoutService.contentCssClass}}">

		<div class="ui-g-12 text-center"
			*ngIf="loading === true"
			[@contentAnimation]="state">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>

		<div class="ui-g-12 generic-base-page-container no-padding"
			*ngIf="loading === false"
			[@contentAnimation]="state">

			<ng-template [ngIf]="redrawing === false">
				<div class="ui-g-12 dynamic-component-container">
					<app-dynamic-component
						[loading]="loading"
						[context]="pageContext"
						[displayComponent]="dynamicComponent">
					</app-dynamic-component>
				</div>
			</ng-template>
		</div>
	</div>

</app-base-page>