/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	HttpClient
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	ISecurityGroupDto
} from '@api/interfaces/security/security-group.dto.interface';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	lastValueFrom
} from 'rxjs';
import {
	AppConfig
} from 'src/app/app.config';

/**
 * A class representing the logic and services of the security
 * group controller.
 *
 * @export
 * @class SecurityGroupApiService
 * @extends {BaseEntityApiService<ISecurityGroupDto>}
 */
@Injectable()
export class SecurityGroupApiService
	extends BaseEntityApiService<ISecurityGroupDto>
{
	/**
	 * Creates an instance of a SecurityGroupApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof SecurityGroupApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.securityGroups;
	}

	/**
	 * Gets the associated user identifiers of the security group
	 * with the specified identifier.
	 *
	 * @param {number} id
	 * The identifier of the security group to get the users of.
	 * @returns {Promise<number[]>}
	 * The user identifiers found via this get method.
	 * @memberof SecurityGroupApiService
	 */
	public async getSecurityGroupUsers(
		id: number): Promise<number[]>
	{
		const url = this.getNestedUrl(
			id,
			AppConstants.nestedRouteTypes.users);

		return lastValueFrom(
			this.httpClient.get<number[]>(url));
	}

	/**
	 * Creates a user of the security group with the specified
	 * identifier with the supplied user identifier.
	 *
	 * @param {number} id
	 * The identifier of the security group to create a user of.
	 * @param {number} userId
	 * The user identifier to be added to the security group.
	 * @returns {Promise<number>}
	 * The identifier of the security group this user was added to.
	 * @memberof SecurityGroupApiService
	 */
	public async createSecurityGroupUser(
		id: number,
		userId: number): Promise<number>
	{
		const url: string =
			this.formUrlParam(
				this.getNestedUrl(
					id,
					AppConstants.nestedRouteTypes.users),
				{
					userId: userId
				});

		await this.resetAssociatedCache(url);
		await this.resetCachedUsersUrl();

		const response = await lastValueFrom(
			this.httpClient.post(
				url,
				{},
				{
					observe: 'response',
					headers: this.queryOnlyGetHeaders
				}));

		const createdItemId: number =
			this.getCreatedAtRouteIdentifier(
				response.headers.get('location'));

		return Promise.resolve(createdItemId);
	}

	/**
	 * Deletes a user of the security group with the specified
	 * identifier with the supplied user identifier.
	 *
	 * @param {number} id
	 * The identifier of the security group to delete a user from.
	 * @param {number} userId
	 * The user identifier to delete from the security group.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof SecurityGroupApiService
	 */
	public async deleteSecurityGroupUser(
		id: number,
		userId: number): Promise<object>
	{
		const nestedUrl: string =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.users);
		const url =
			`${nestedUrl}/${userId}`;
		await this.resetAssociatedCache(nestedUrl);
		await this.resetCachedUsersUrl();

		return lastValueFrom(
			this.httpClient.delete(
				url));
	}

	/**
	 * Resets any associated cached users url.
	 *
	 * @async
	 * @memberof SecurityGroupApiService
	 */
	private async resetCachedUsersUrl(): Promise<void>
	{
		const usersUrl: string =
			AppConfig.settings.webApi.rootUrl
				+ AppConfig.settings.webApi.version
				+ '/' + AppConstants.apiControllers.entityInstances
				+ '/' + AppConstants.nestedRouteTypes.users;

		await this.resetAssociatedCache(usersUrl);
	}
}