<custom-section-title
	[field]="{
		key: field.key,
		fieldGroup: field.fieldGroup,
		props: {
			label: field.props.label,
			singular: field.props.singular,
			disabled: !addItemVisible,
			tooltip: field.props.tooltip,
			attributes: field.props.attributes
		}
	}"
	(addAction)="addItem()">
</custom-section-title>

<div *ngFor="let customField of field.fieldGroup; let itemIndex = index;"
	class="ui-g-12 repeater-content"
	[ngClass]="{
		'disabled-repeater': (field.props.disabled === true)
	}">

	<p-accordion (onOpen)="onTabExpand(itemIndex)"
		*ngIf="customField.props.display === true">
		<p-accordionTab
			[transitionOptions]="'0ms'"
			[header]="getCustomTitle(customField)">
			<div class="ui-g-12 repeater-item">

				<formly-field
					[field]="customField">
				</formly-field>

				<div *ngIf="!field.props.disabled && !isDisabledItem(customField) && addItemVisible"
					class="ui-g-12 no-padding">
					<a class="text-link delete-icon-container"
						[ngClass]="{
							'disabled-link' : (field.props?.context?.source?.entityFormComponent?.saving == true)
						}"
						(click)="removeItem(customField, $event)">
						<i class="fa fa-trash">
						</i>
					</a>
				</div>

			</div>
		</p-accordionTab>
	</p-accordion>
</div>

<div class="ui-g-12 no-vertical-padding">
	<field-validation-message
		[field]="field">
	</field-validation-message>
</div>