/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	Injectable,
	Injector
} from '@angular/core';
import {
	IOperationAction
} from '@operation/actions/interfaces/operation-action.interface';
import {
	IOperationTypeParameter
} from '@operation/interfaces/operation-type-parameter.interface';
import {
	OperationTokenLookup
} from '@operation/operation-token.lookup';

/**
 * A class used to create operations based on the stored
 * operation type name.
 *
 * @export
 * @class OperationFactory
 */
@Injectable()
export class OperationFactory
{
	/**
	 * Creates an instance of an OperationFactory.
	 *
	 * @param {Injector} injector
	 * The injector used to instantiate dynamic operation
	 * actions.
	 * @memberof OperationFactory
	 */
	public constructor(
		private readonly injector: Injector)
	{
	}

	/**
	 * Creates the the defined operation type dynamically
	 * and assigns the passed in parameters.
	 *
	 * @param {string} operationTypeName
	 * The operation type to instantiate.
	 * @param {IOperationTypeParameter[]} parameters
	 * The operation type parameters to set for this operation
	 * action.
	 * @memberof OperationFactory
	 */
	public create(
		operationTypeName: string,
		parameters: IOperationTypeParameter[]): IOperationAction
	{
		let operationAction: IOperationAction;

		try
		{
			operationAction =
				this.injector.get<IOperationAction>(
					OperationTokenLookup.tokens[operationTypeName]);
		}
		catch (error)
		{
			throw new Error(
				'The operation factory was unable to create the operation '
					+ `type: '${operationTypeName}' because the class does `
					+ 'not exist. Please see the OperationTokenLookup to '
					+ 'ensure this has been defined and ensure this is '
					+ 'provided in the OperationModule.');
		}

		parameters.forEach((operationTypeParameter) =>
		{
			if (operationTypeParameter.name in operationAction)
			{
				operationAction[operationTypeParameter.name] =
					operationTypeParameter.definitionParameterValue;
			}
			else
			{
				throw new Error(
					'The operation factory was unable to set the parameter '
						+ `'${operationTypeParameter.name}' because that `
						+ 'property does not exist in the defined operation '
						+ `type: ${operationTypeName}.`);
			}
		});

		return operationAction;
	}
}