<div class="ui-g-12 no-padding">
	<div class="ui-g-12 no-horizontal-padding wizard-content-title">
	</div>
	<ng-template
		[ngIf]="context.source.wizardStepLoading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<ng-template
			[ngIf]="clientMessage !== ''"
			[ngIfElse]="EffectiveDate">
			<div class="ui-g-12 no-horizontal-padding text-center">
				<span class="panel-sub-title">
					{{clientMessage}}
				</span>
			</div>
		</ng-template>

		<ng-template #EffectiveDate>
			<div class="ui-g-12 no-padding effective-date-container">
				<app-dynamic-formly
					[dataSet]="context.source.activeMenuItem.currentData"
					[layoutSchema]="staticFormlyLayout"
					[context]="context"
					(validityChanged)="validityChanged($event)">
				</app-dynamic-formly>
			</div>
		</ng-template>
	</ng-template>
</div>