/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	CommonDashboardComponent
} from '@shared/components/common-dashboard/common-dashboard.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	JsonSchemaHelper
} from '@shared/helpers/json-schema.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	IDescriptionDisplayDefinition
} from '@shared/interfaces/application-objects/description-display-definition.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	get,
	setWith
} from 'lodash';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-coverages-deductibles',
	templateUrl: './transaction-coverages-deductibles.component.html',
	styleUrls: [
		'./transaction-coverages-deductibles.component.scss'
	]
})

/**
 * A component representing an associated company used
 * in the associated entity list.
 *
 * @export
 * @class TransactionCoveragesDeductiblesComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, {
		entityInstance: IEntityInstance,
		entityListContext: IAssociatedEntityListContext
	}>}
 */
export class TransactionCoveragesDeductiblesComponent
implements IDynamicComponent<Component, {
		entityInstance: IEntityInstance;
		entityListContext: IAssociatedEntityListContext;
	}>, OnInit
{
	/**
	 * Creates an instance of the Transaction
	 * Coverages And Deductibles component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition api service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {InsuranceService} insuranceService
	 * The entity instance api service.
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public insuranceService: InsuranceService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IEntityInstance>}
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public context: IDynamicComponentContext<Component, {
			entityInstance: IEntityInstance;
			entityListContext: IAssociatedEntityListContext;
		}>;

	/**
	 * Gets or sets the coverages and deductibles used in this component.
	 *
	 * @type {object}
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public coveragesAndDeductibles: any = {};

	/**
	 * Gets or sets this component loading state.
	 *
	 * @type {boolean}
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets the coverages and deductibles workflow action name.
	 *
	 * @type {string}
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	private readonly getCoveragesAndDeductiblesAction: string =
		'TransactionGetCoveragesAndDeductibles';

	/**
	 * Gets the policy scope name.
	 *
	 * @type {string}
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	private readonly policyScope: string =
		'Policy';

	/**
	 * Initiates the component to get and dynamically process the
	 * coverages and deductibles data on a generic format
	 *
	 * @async
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		const entityInstanceId: number =
			(<any>this.context.source)
				.entityContext?.data.entityInstance.id;

		if (AnyHelper.isNull(entityInstanceId))
		{
			this.loading = false;

			return;
		}

		const policyTerm: IEntityInstance =
			await this.insuranceService
				.getPolicyTerm(entityInstanceId);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const response: any =
			await this.entityInstanceApiService
				.executeAction(
					policyTerm.id,
					this.getCoveragesAndDeductiblesAction,
					policyTerm);

		this.setCoveragesAndDeductibles(response.body.value);

		if (!AnyHelper.isNull(
			(<CommonDashboardComponent>this.context.source).finishedLoading))
		{
			(<CommonDashboardComponent>this.context.source)
				.finishedLoading.emit(
					true);
		}
	}

	/**
	 * Sets the coverages and deductibles displayable object used
	 * at thes component view.
	 *
	 * @async
	 * @param {any} responseValue
	 * The response value returned from the workflow action.
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public async setCoveragesAndDeductibles(responseValue: any): Promise<void>
	{
		if (AnyHelper.isNullOrEmpty(responseValue)
			|| AnyHelper.isNullOrEmpty(
				responseValue.transaction)
			|| (AnyHelper.isNullOrEmpty(responseValue.coverages)
				&& AnyHelper.isNullOrEmpty(responseValue.deductibles)))
		{
			this.loading = false;

			return;
		}

		const coveragesAndDeductiblesItems: any[] =
			[
				...responseValue.coverages,
				...responseValue.deductibles
			];

		for (const responseItem of coveragesAndDeductiblesItems)
		{
			const category: string =
				responseItem.category.toLowerCase();
			const resourceIdentifier: string =
				responseItem.resourceIdentifier;
			let path: string;

			if (responseItem.scope === this.policyScope)
			{
				path = `policy.${category}`;
				this.setCoverageAndDeductibleValues(
					`policy.${category}`,
					responseItem);
			}
			else
			{
				if (AnyHelper.isNull(this.coveragesAndDeductibles.nonPolicy))
				{
					this.coveragesAndDeductibles.nonPolicy = {};
				}

				const existingScope: any[] =
					get(
						this.coveragesAndDeductibles,
						`nonPolicy.${responseItem.propertyName}`)
							?? [];

				const existingItemIndex: number =
					existingScope.findIndex(
						(typeItem) =>
							typeItem.type === responseItem.type
								&& typeItem.resourceIdentifier
									=== resourceIdentifier);

				const nonPolicyItemPath: string =
					`nonPolicy.${responseItem.propertyName}`;

				if (existingItemIndex !== -1)
				{
					path =
						nonPolicyItemPath
							+ `[${existingItemIndex}].${category}`;

					this.setCoverageAndDeductibleValues(
						path,
						responseItem);
				}
				else
				{
					const existingNonPolicyData: any =
						this.coveragesAndDeductibles.nonPolicy[
							`${responseItem.propertyName}`] ?? [];

					this.coveragesAndDeductibles.nonPolicy[
						`${responseItem.propertyName}`] =
							[
								...existingNonPolicyData,
								...[
									await this.getNonPolicyObject(
										responseValue.transaction,
										responseItem)
								]
							];

					const newItemIndex: number = this.coveragesAndDeductibles
						.nonPolicy[
							`${responseItem.propertyName}`]
						.findIndex((item: any) =>
							item.type === responseItem.type
								&& item.resourceIdentifier
									=== resourceIdentifier);

					path =
						nonPolicyItemPath
							+ `[${newItemIndex}].${category}`;

					this.setCoverageAndDeductibleValues(
						path,
						responseItem);
				}
			}
		}

		this.coveragesAndDeductibles.nonPolicy =
			!AnyHelper.isNullOrEmpty(this.coveragesAndDeductibles.nonPolicy)
				? Array.from(
					Object.entries(this.coveragesAndDeductibles.nonPolicy))
				: null;

		this.loading = false;
	}

	/**
	 * Gets a non-policy object.
	 *
	 * @async
	 * @param {IEntityInstance} transaction
	 * The transaction entity instance.
	 * @param {any} responseItem
	 * The coverage and deductible item data
	 * @returns {Promise<any>}
	 * The new non policy object.
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public async getNonPolicyObject(
		transaction: IEntityInstance,
		responseItem: any): Promise<any>
	{
		return {
			resourceIdentifier:
				responseItem.resourceIdentifier,
			type: responseItem.type,
			description:
				await this.getCoverageAndDeductibleDescription(
					transaction,
					responseItem),
			coverages: [],
			deductibles: []
		};
	}

	/**
	 * Gets the coverage and deductible description.
	 *
	 * @async
	 * @param {IEntityInstance} transaction
	 * The transaction entity instance.
	 * @param {any} responseItem
	 * The coverage and deductible item data
	 * @returns {Promise<string>}
	 * The coverage and deductible description.
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public async getCoverageAndDeductibleDescription(
		transaction: IEntityInstance,
		responseItem: any): Promise<string>
	{
		const matchingItem: any =
			(<any[]>transaction.data[responseItem.propertyName])
				?.find((item: any) =>
					item.resourceIdentifier
						=== responseItem.resourceIdentifier);

		if (AnyHelper.isNull(matchingItem))
		{
			return AppConstants.empty;
		}

		if (!AnyHelper.isNullOrEmpty(
			matchingItem.characteristics?.displayDescription))
		{
			return matchingItem.characteristics.displayDescription;
		}
		else
		{
			return this.getObjectDescription(
				matchingItem,
				responseItem,
				transaction.entityType);
		}
	}

	/**
	 * Gets the object description.
	 *
	 * @async
	 * @param {any} matchingItem
	 * The matching data item.
	 * @param {any} responseItem
	 * The response data item.
	 * @param {string} entityTypeGroup
	 * The entity type group.
	 * @returns {Promise<string>}
	 * The object description.
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public async getObjectDescription(
		matchingItem: any,
		responseItem: any,
		entityTypeGroup: string): Promise<string>
	{
		const entityType: IEntityType =
			await this.entityTypeApiService.
				getSingleQueryResult(
					`Name eq '${entityTypeGroup}'`,
					null);

		const matchingDefinition: IEntityDefinition =
			await this.entityDefinitionApiService
				.getSingleQueryResult(
					`typeId eq ${entityType.id}`,
					null);

		const entityDefinition: EntityDefinition =
			new EntityDefinition(matchingDefinition);

		const propertyValue: any =
			JsonSchemaHelper.getArrayItemDefinition(
				JsonSchemaHelper.getSchemaDefinition(
					entityDefinition.dereferencedDataProperties,
					responseItem.propertyName),
				responseItem.type);

		const descriptionPropertyKeys: IDescriptionDisplayDefinition[] =
			propertyValue.descriptionPropertyKeys;

		return !AnyHelper.isNullOrEmptyArray(descriptionPropertyKeys)
			? ObjectHelper.getObjectDescription(
				matchingItem,
				descriptionPropertyKeys)
			: AppConstants.empty;
	}

	/**
	 * Sets the coverage and deductible name and amount values.
	 *
	 * @param {string} path
	 * The path where to set the values.
	 * @param {any} responseItem
	 * The coverages and deductibles response data item.
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public setCoverageAndDeductibleValues(
		path: string,
		responseItem: any): void
	{
		const existingList: any[] =
			get(
				this.coveragesAndDeductibles,
				path);

		const index = !AnyHelper.isNullOrEmpty(existingList)
			? existingList.length
			: 0;

		setWith(
			this.coveragesAndDeductibles,
			`${path}[${index}]`,
			{
				name: responseItem.name,
				amount: responseItem.amountValue
			},
			(value) => value);
	}

	/**
	 * Gets the amount value formatted in currency type.
	 *
	 * @param {string} amount
	 * The original amount value.
	 * @returns {string}
	 * The currency amount.
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public getCurrencyAmount(amount: string): string
	{
		return StringHelper
			.numberToCurrency(parseFloat(amount));
	}

	/**
	 * Gets the scope formatted to normalize camel case.
	 *
	 * @param {string} scope
	 * The original scope name.
	 * @returns {string}
	 * The scope title
	 * @memberof TransactionCoveragesDeductiblesComponent
	 */
	public getScopeTitle(scope: string): string
	{
		return StringHelper
			.normalizeCamelcase(scope);
	}
}