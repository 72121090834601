/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A constant library used to defined available window
 * emitted events that can be consumed in listening components
 * via the host listener.
 *
 * @export
 * @const WindowEventConstants
 */
export const WindowEventConstants:
{
	beforeUnloadEvent: string;
	click: string;
	message: string;
	messageEvent: string;
	resizeEvent: string;
	scroll: string;
	scrollEvent: string;
	focus: string;
	keyup: string;
	storage: string;
} = {
	beforeUnloadEvent: 'window:beforeunload',
	click: 'click',
	message: 'message',
	messageEvent: 'window:message',
	resizeEvent: 'window:resize',
	scroll: 'scroll',
	scrollEvent: 'window:scroll',
	focus: 'focus',
	keyup: 'keyup',
	storage: 'storage'
};