<operation-menu
	#PrimaryNav
	[operationGroupName]="primaryNavigationGroupName"
	displayName="primary navigation"
	displayType="left-menu"
	id="primary-navigation"
	class="layout-menu layout-main-menu clearfix"
	[pageContext]="pageContext"
	[initialModel]="initialModel"
	(closeList)="closeNavigationMenu(PrimaryNav)"
	(closeOverlays)="closeOverlaysEvent()">
</operation-menu>