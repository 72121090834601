/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import {
	FormlyFieldConfig,
	FormlyFormOptions
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	Subject,
	debounceTime,
	distinctUntilChanged,
	startWith
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'app-dynamic-formly',
	templateUrl: './dynamic-formly.component.html',
	styleUrls: [
		'./dynamic-formly.component.scss'
	]
})

/**
 * A component that generates a formly field view.
 *
 * @export
 * @class DynamicFormlyComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {IDynamicComponent<DynamicFormlyComponent, any>}
 */
export class DynamicFormlyComponent implements OnInit, OnDestroy,
	IDynamicComponent<DynamicFormlyComponent, any>
{
	/**
	 * Gets or sets the object containing the data for this formly display.
	 *
	 * @type {any}
	 * @memberof DynamicFormlyComponent
	 */
	@Input() public dataSet: any;

	/**
	 * Gets or sets the initial data values sent to this component.
	 *
	 * @type {any}
	 * @memberof DynamicFormlyComponent
	 */
	@Input() public initialData: any;

	/**
	 * Gets or sets the object containing the layout schema for this formly
	 * display.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof DynamicFormlyComponent
	 */
	@Input() public layoutSchema: FormlyFieldConfig[];

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic formly component.
	 *
	 * @type {IDynamicComponentContext<DynamicFormlyComponent, any>}
	 * @memberof DynamicFormlyComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicFormlyComponent,
		any>;

	/**
	 * Gets or sets the validity changed event emitter. This will notify
	 * listening components of a change in the display form validity.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof DynamicFormlyComponent
	 */
	@Output() public validityChanged: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	/**
	 * Gets or sets the values changed event emitter. This will notify
	 * listening components of a change in input data values.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof DynamicFormlyComponent
	 */
	@Output() public valuesChanged: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	/**
	 * Gets or sets the form group to be displayed in this component.
	 *
	 * @type {FormGroup}
	 * @memberof DynamicFormlyComponent
	 */
	public formGroup: UntypedFormGroup = new UntypedFormGroup({});

	/**
	 * Gets or sets the formly options to be provided to formly field.
	 *
	 * @type {FormlyFormOptions}
	 * @memberof DynamicFormlyComponent
	 */
	public formlyOptions: FormlyFormOptions = {};

	/**
	 * Gets or sets the observer of form validation changes.
	 *
	 * @type {Subject<boolean>}
	 * @memberof DynamicFormlyComponent
	 */
	public formValidityChanged: Subject<boolean> = new Subject<boolean>();

	/**
	 * Gets or sets the observer of form value changes.
	 *
	 * @type {Subject<void>}
	 * @memberof DynamicFormlyComponent
	 */
	public formValueChanged: Subject<void> = new Subject<void>();

	/**
	 * Gets or sets the delay for a debounced subject change.
	 *
	 * @type {number}
	 * @memberof DynamicFormlyComponent
	 */
	private readonly debounceDelay: number = 50;

	/**
	 * Implements the on initialization interface.
	 * This method is used to watch for changes to the overall form validity.
	 *
	 * @memberof DynamicFormlyComponent
	 */
	public ngOnInit(): void
	{
		this.formValidityChanged.pipe(
			startWith(false),
			debounceTime(this.debounceDelay),
			distinctUntilChanged())
			.subscribe(
				(isValid: boolean) =>
				{
					this.validityChanged.emit(isValid);
				});

		this.formGroup.statusChanges.subscribe(
			(status: string) =>
			{
				this.formValidityChanged.next(
					status === AppConstants.formControlStatus.valid
						|| status === AppConstants.formControlStatus.disabled);
			});

		if (!AnyHelper.isNullOrEmpty(this.initialData?.data))
		{
			this.valuesChanged.emit(
				!ObjectHelper.checkBusinessLogicEquality(
					this.initialData.data,
					this.dataSet.data));

			this.formValueChanged.pipe(
				debounceTime(this.debounceDelay))
				.subscribe(
					() =>
					{
						this.valuesChanged.emit(
							!ObjectHelper.checkBusinessLogicEquality(
								this.initialData.data,
								this.dataSet.data));
					});

			this.formGroup.valueChanges.subscribe(
				(_newData: any) =>
				{
					this.formValueChanged.next();
				});
		}

		this.initializeCustomDataControls();

		setTimeout(() =>
		{
			this.formGroup.markAllAsTouched();
			this.formGroup.updateValueAndValidity();
			FormlyHelper.fireViewCheck(
				this.layoutSchema);
		},
		this.debounceDelay);
	}

	/**
	 * Handles the on destroy interface.
	 * This completes any watched subjects to free memory.
	 *
	 * @memberof DynamicFormlyComponent
	 */
	public ngOnDestroy(): void
	{
		this.formValidityChanged.complete();
		this.formValueChanged.complete();
	}

	/**
	 * Given a sent formly layout this will set the context in all fields
	 * and nested fields found in this layout.
	 *
	 * @memberof DynamicFormlyComponent
	 */
	public initializeCustomDataControls(): void
	{
		this.layoutSchema.forEach(
			(field: FormlyFieldConfig) =>
			{
				field.props =
					field.props || {};

				field.props.context = this.context;

				if (!AnyHelper.isNullOrEmpty(field.asyncValidators))
				{
					for (const asyncValidator
						of Object.keys(field.asyncValidators))
					{
						if (!AnyHelper.isFunction(
							field.asyncValidators[asyncValidator]
								.expression))
						{
							const asyncValidationPromise =
								StringHelper.transformToFunction(
									field.asyncValidators[
										asyncValidator].expression,
									this.context);

							field.asyncValidators[asyncValidator]
								.expression =
									(control: UntypedFormControl) =>
										asyncValidationPromise(control, field);
						}
					}
				}

				this.setContextInChildren(
					field);
			});
	}

	/**
	 * Given a sent formly layout this will set the context of all fields and
	 * nested fields found in this layout. This set context will be called
	 * recursively for each child of the sent field.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field to be set the context for along with all children.
	 * @memberof DynamicFormlyComponent
	 */
	private setContextInChildren(
		field: FormlyFieldConfig): void
	{
		field.fieldGroup?.forEach(
			(fieldConfig: FormlyFieldConfig) =>
			{
				fieldConfig.props =
					fieldConfig.props || {};

				fieldConfig.props.context = this.context;

				if (!AnyHelper.isNullOrEmpty(fieldConfig.asyncValidators))
				{
					for (const asyncValidator
						of Object.keys(fieldConfig.asyncValidators))
					{
						if (!AnyHelper.isFunction(
							fieldConfig.asyncValidators[asyncValidator]
								.expression))
						{
							const asyncValidationPromise =
								StringHelper.transformToFunction(
									fieldConfig.asyncValidators[
										asyncValidator].expression,
									this.context);

							fieldConfig.asyncValidators[asyncValidator]
								.expression =
									(control: UntypedFormControl) =>
										asyncValidationPromise(control, field);
						}
					}
				}

				this.setContextInChildren(
					fieldConfig);
			});
	}
}