/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	IDrawerMenuItem
} from '@shared/interfaces/application-objects/drawer-menu-item.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';

/* eslint-enable max-len */

@Component({
	selector: 'app-drawer',
	templateUrl: './app-drawer.component.html',
	styleUrls: ['./app-drawer.component.scss']
})

/**
 * A component representing an instance of a drawers component.
 *
 * @export
 * @class AppDrawerComponent
 */
export class AppDrawerComponent
{
	/**
	 * Gets or sets the drawer menu item displayed in this drawer.
	 *
	 * @type {IDrawerMenuItem}
	 * @memberof AppDrawerComponent
	 */
	@Input() public drawerMenuItem: IDrawerMenuItem;

	/**
	 * Gets or sets the page context used for linking drawer displays to
	 * page content.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof AppDrawerComponent
	 */
	@Input() public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the event that will be emitted to all listening components
	 * when a pin drawer button is clicked.
	 *
	 * @type {EventEmitter<IDrawerMenuItem>}
	 * @memberof AppDrawerComponent
	 */
	@Output() public drawerPinButtonClicked: EventEmitter<IDrawerMenuItem> =
		new EventEmitter();

	/**
	 * Gets or sets the event that will be emitted to all listening components
	 * when a close drawer button is clicked.
	 *
	 * @type {EventEmitter<IDrawerMenuItem>}
	 * @memberof AppDrawerComponent
	 */
	@Output() public drawerCloseButtonClicked: EventEmitter<IDrawerMenuItem> =
		new EventEmitter();

	/**
	 * Handles the close drawer click by bubbling this event up to the
	 * containing drawers component.
	 *
	 * @param {IDrawerMenuItem} drawerMenuItem
	 * The menu item that received the close click event.
	 * @memberof AppDrawerComponent
	 */
	public closeDrawer(): void
	{
		this.drawerCloseButtonClicked.emit(
			this.drawerMenuItem);
	}

	/**
	 * Handles the pin drawer click by bubbling this event up to the containing
	 * drawers component.
	 *
	 * @param {IDrawerMenuItem} drawerMenuItem
	 * The menu item that received the pin click event.
	 * @memberof AppDrawerComponent
	 */
	public pinDrawer(): void
	{
		if (this.drawerMenuItem.pinnable === true)
		{
			this.drawerPinButtonClicked.emit(
				this.drawerMenuItem);
		}
	}
}