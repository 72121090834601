<ng-template
	[ngIf]="loading"
	[ngIfElse]="Content">
	<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>

<ng-template #Content>
	<div class="ui-g-12 no-padding drill-in-entity-display">
		<div class="ui-g-12 text-left no-padding">
			<div class="ui-g-12 nested-display-subtitle text-left">
				<div class="ellipsis-text">
					Edit: {{entityDisplayName}}
				</div>
				<span class="nested-display-actions">
					<ng-template
						ngFor
						let-action
						[ngForOf]="itemActions">
						<span [ngClass]="{
								'selected-bottom-border': (action.id === 'Update')
							}">
							<app-common-icon-tooltip
								content="{{action.label}}"
								iconClass="fa fa-fw {{action.icon}} theme-color cursor-pointer"
								position="left"
								styleClass="utility-menu-tooltip"
								(iconClick)="action.command($event)">
							</app-common-icon-tooltip>
						</span>
					</ng-template>
				</span>
			</div>

			<p-scrollPanel styleClass="entity-container">
				<div class="ui-g-12 no-padding data-panel">
					<ng-template
						[ngIf]="formlyEntityLayout != null">
						<app-dynamic-formly
							[context]="context"
							[dataSet]="selectedItem"
							[layoutSchema]="formlyEntityLayout"
							(validityChanged)="validDataChanged($event)">
						</app-dynamic-formly>
					</ng-template>
				</div>
			</p-scrollPanel>
		</div>

		<div class="ui-g-12 no-padding text-right button-set">
			<a class="text-link theme-color secondary-action-button"
				(click)="changeDisplayMode.emit('List')">
				Cancel
			</a>

			<span class="nested-operation-button-bar"
				*ngIf="operationGroupName || '' != ''">
				<operation-button-bar
					displayName="actions"
					[pageContext]="context"
					[operationGroupName]="operationGroupName">
				</operation-button-bar>
			</span>

			<button pButton
				type="button"
				label="Save"
				class="no-margin-right"
				(click)="updateEntity()"
				[disabled]="isValid === false || saving === true">
			</button>
		</div>
	</div>
</ng-template>