/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	IEntityTypeDto
} from '@api/interfaces/entities/entity-type.dto.interface';
import {
	ISecurityDefinitionDto
} from '@api/interfaces/security/security-definition.dto.interface';
import {
	ISecurityEntityTypeDefinitionDto
} from '@api/interfaces/security/security-entity-type-definition.dto.interface';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FileHelper
} from '@shared/helpers/file.helper';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';

/**
 * A class that represents file categories
 *
 * @class FileCategory
 * @export
 */
export class FileCategory
{
	/**
	 * Initializes a new instance of this class
	 * @param {IEntityTypeDto} entityType
	 * An entity type.
	 * @param {EntityDefinition} definition
	 * The entity definition for this entity type.
	 * @param {ISecurityEntityTypeDefinitionDto} entityTypeSecurityDefinition
	 * The entity type security definition.
	 * @memberof FileCategory
	 */
	public constructor(
		public readonly entityType: IEntityTypeDto,
		public readonly definition: EntityDefinition,
		entityTypeSecurityDefinition: ISecurityEntityTypeDefinitionDto)
	{
		this.name = FileHelper
			.getCategoryFromType(this
				.entityType
				.name);

		this.subTypes = <string[]>
			this.definition
				.properties
				.find(prop =>
					prop.key === AppConstants.commonProperties.subType)
				.value
				.enum ?? [];

		this.securityDefinition =
			entityTypeSecurityDefinition.securityDefinition;
	}

	/**
	 * A name of the category
	 *
	 * @type {string}
	 * @memberof FileCategory
	 */
	public readonly name: string;

	/**
	 * A sub types/categories
	 *
	 * @type {string[]}
	 * @memberof FileCategory
	 */
	public readonly subTypes: string[];

	/**
	 * The security definition.
	 *
	 * @type {ISecurityDefinitionDto}
	 * @memberof FileCategory
	 */
	public readonly securityDefinition: ISecurityDefinitionDto;

	/**
	 * The label display name of the category
	 *
	 * @type {string}
	 * @memberof FileCategory
	 */
	public get label(): string
	{
		return this.name;
	}

	/**
	 * The value (entity type)
	 *
	 * @type {string}
	 * @memberof FileCategory
	 */
	public get value(): string
	{
		return this.entityType.name;
	}
}