/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	BaseOperationGroupDirective
} from '@operation/directives/base-operation-group.directive';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WorkItemConstants
} from '@shared/constants/work-item-constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

@Component({
	selector: 'app-work-item-expand',
	templateUrl: './work-item-expand.component.html',
	styleUrls: [
		'./work-item-expand.component.scss'
	]
})

/**
 * A component representing an instance of the work item expand component.
 *
 * @export
 * @class WorkItemExpandComponent
 * @implements {IDynamicComponent<any, any>}
 */
export class WorkItemExpandComponent
	extends BaseOperationGroupDirective
	implements IDynamicComponent<CommonTableComponent, any>, OnInit
{
	/**
	 * Initializes a new instance of the work bench expand component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {LoggerService} loggerService
	 * The logger service.
	 * @param {OperationService} operationService
	 * The oepration service.
	 * @param {operationExecutionService} OperationExecutionService
	 * The operation execution service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type API service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service.
	 * @memberof WorkItemExpandComponent
	 */
	public constructor(
		public entityService: EntityService,
		public activityService: ActivityService,
		public siteLayoutService: SiteLayoutService,
		public loggerService: LoggerService,
		public operationService: OperationService,
		public operationExecutionService: OperationExecutionService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService)
	{
		super(
			loggerService,
			operationService,
			operationExecutionService,
			siteLayoutService);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof WorkItemExpandComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets whether or not this component is loading.
	 *
	 * @type {boolean}
	 * @memberof WorkItemExpandComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets whether or not this component is saving.
	 *
	 * @type {boolean}
	 * @memberof WorkItemExpandComponent
	 */
	public saving: boolean = false;

	/**
	 * Gets or sets the operation group which will be displayed in
	 * the action menu for this work item.
	 *
	 * @type {string}
	 * @memberof WorkItemExpandComponent
	 */
	public operationGroupName: string = AppConstants.empty;

	/**
	 * Gets or sets the work item that has been expanded.
	 *
	 * @type {IEntityInstance}
	 * @memberof WorkItemExpandComponent
	 */
	public selectedItem: IEntityInstance;

	/**
	 * Gets or sets the work item entity type.
	 *
	 * @type {IEntityType}
	 * @memberof WorkItemExpandComponent
	 */
	public selectedEntityType: IEntityType;

	/**
	 * Gets or sets the work item entity definition.
	 *
	 * @type {EntityDefinition}
	 * @memberof WorkItemExpandComponent
	 */
	public selectedEntityDefinition: EntityDefinition;

	/**
	 * Gets or sets the formly layout for the work item.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof WorkItemExpandComponent
	 */
	public formlyEntityLayout: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the work item's primary parent entity instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof WorkItemExpandComponent
	 */
	public parentEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the work item parent's formly layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof WorkItemExpandComponent
	 */
	public parentFormlyEntityLayout: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the operation group which will be displayed in
	 * the action menu for this work item.
	 *
	 * @type {string}
	 * @memberof WorkItemExpandComponent
	 */
	public assigneeName: string = AppConstants.empty;

	/**
	 * Gets or sets the created on date.
	 *
	 * @type {string}
	 * @memberof WorkItemExpandComponent
	 */
	public createdOn: string = AppConstants.empty;

	/**
	 * Gets the wildcard value that will be replaced in expand actions with an
	 * explicit entity type.
	 *
	 * @type {string}
	 * @memberof WorkItemExpandComponent
	 */
	private readonly actionLabelWildcard: string = 'Entity';

	/**
	 * Gets the identifier to display when the work item is not assigned.
	 *
	 * @type {string}
	 * @memberof WorkItemExpandComponent
	 */
	private readonly unassigned: string = 'Unassigned';

	/**
	 * Handles the on initialization event.
	 * This method will initialize data, layouts, and actions for use in this
	 * expand component.
	 *
	 * @async
	 * @memberof WorkItemExpandComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		// Set context level items for calculated operation group functions.
		this.selectedItem = this.context.source.selectedItem;
		this.selectedEntityType =
			await this.getEntityType(
				this.selectedItem.entityType);
		this.context.data =
			{
				...this.context.data,
				itemDisplayComponent: this
			};

		// Gather the assignee full name.
		this.assigneeName = await this.getAssigneeDisplayName();

		this.createdOn =
			new Date(this.context.source.selectedItem.createDate)
				.toLocaleString();

		// Set up the primary parent data and layout if applicable.
		const parentNavigationReference: any =
			this.selectedItem.data.references.find(
				(reference: any) =>
					reference.type ===
						WorkItemConstants.workItemIdentifiers
							.parentNavigationEntityIdentifer);

		const parentEntityTypeReference: any =
				this.selectedItem.data.references.find(
					(reference: any) =>
						reference.type ===
							WorkItemConstants.workItemIdentifiers
								.parentNavigationEntityType);

		if (!AnyHelper.isNull(parentNavigationReference)
			&& !AnyHelper.isNull(parentEntityTypeReference))
		{
			const parentId: number =
				Number(parentNavigationReference.identifier);

			const parentEntityType: IEntityType =
				await this.getEntityType(
					`${parentEntityTypeReference.identifier}`);

			this.entityInstanceApiService.entityInstanceTypeGroup =
				parentEntityType.group;

			this.parentEntityInstance =
				await this.entityInstanceApiService
					.get(parentId);

			this.parentFormlyEntityLayout =
				await this.entityService.getFormlyLayout(
					this.pageContext,
					parentEntityType,
					AppConstants.layoutTypes.summary,
					this.parentEntityInstance.id,
					true);
		}

		// Set up the layout for the selected entity type.
		this.formlyEntityLayout =
			await this.entityService.getFormlyLayout(
				this.pageContext,
				this.selectedEntityType,
				AppConstants.layoutTypes.summary,
				this.selectedItem.id,
				true);

		// Load the operation group via the shared directive.
		this.pageContext =
			<IDynamicComponentContext<Component, any>>this.context;

		const entityDefinition: IEntityDefinition =
			await this.entityService.getEntityDefinition(
				this.selectedEntityType.id,
				this.selectedItem.versionNumber);
		this.selectedEntityDefinition =
			new EntityDefinition(entityDefinition);
		this.operationGroupName =
			this.selectedEntityDefinition.actionMenuOperationGroupName;

		super.ngOnInit();
	}

	/**
	 * Performs actions after the operation group has loaded. This operation
	 * group is added as an action menu if applicable to the standard
	 * expand actions.
	 *
	 * @memberof WorkItemExpandComponent
	 */
	public performPostOperationLoadActions(): void
	{
		this.context.source.expandActions =
			[
				...this.model,
				...this.context.source.expandActions
			];

		if (!AnyHelper.isNull(this.parentEntityInstance))
		{
			this.context.source.expandActions.forEach(
				(expandAction: MenuItem) =>
				{
					if (expandAction.label.indexOf(
						this.actionLabelWildcard) !== -1)
					{
						expandAction.label =
							expandAction.label.replace(
								this.actionLabelWildcard,
								this.getDisplayName(
									this.parentEntityInstance.entityType));
						expandAction.visible = true;
					}
				});
		}

		this.context.source.updateExpandActions();

		this.loading = false;
	}

	/**
	 * Updates an entity displayed in the work item expand component. This value
	 * is used in operation actions such as mark as complete.
	 *
	 * @async
	 * @memberof WorkItemExpandComponent
	 */
	public async updateEntity(): Promise<void>
	{
		this.saving = true;

		const updateEntity: Function =
			async() =>
			{
				await this.entityInstanceApiService.update(
					this.selectedItem.id,
					this.selectedItem);

				this.context.source.selectedItem = this.selectedItem;
				this.context.source.updateSelectedItem();
			};

		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.selectedEntityType.group;

		let createDisplayName: string =
			StringHelper.beforeCapitalSpaces(
				StringHelper.getLastSplitValue(
					this.selectedEntityType.name,
					AppConstants.characters.period));

		createDisplayName =
			createDisplayName + ' entity';

		await this.activityService.handleActivity<object>(
			new Activity<object>(
				updateEntity(),
				`<strong>Updating</strong> ${createDisplayName}`,
				`<strong>Updated</strong> ${createDisplayName}`,
				`${createDisplayName} was updated.`,
				`${createDisplayName} was not updated.`));

		this.saving = false;
	}

	/**
	 * Gets an entity type matching the sent name from api service.
	 *
	 * @async
	 * @param {string} entityTypeName
	 * The name of the entity type to gather data for.
	 * @returns {Promise<IEntityType>}
	 * An awaitable promise that will hold the entity type matching the sent
	 * name.
	 * @memberof WorkItemExpandComponent
	 */
	public async getEntityType(
		entityTypeName: string): Promise<IEntityType>
	{
		return this.entityTypeApiService
			.getSingleQueryResult(
				`${AppConstants.commonProperties.name} eq `
					+ `'${entityTypeName}'`,
				`${AppConstants.commonProperties.id} `
					+ AppConstants.sortDirections.descending);
	}

	/**
	 * This method will calculate a display name based on a period delimited
	 * name of an entity type.
	 *
	 * @async
	 * @param {string} input
	 * The value to get a display name for.
	 * @returns {string}
	 * A display name ready for use in the user interface.
	 * @memberof WorkItemExpandComponent
	 */
	public getDisplayName(
		input: string): string
	{
		return StringHelper.beforeCapitalSpaces(
			StringHelper.getLastSplitValue(
				input,
				AppConstants.characters.period));
	}

	/**
	 * This method will calculate an assignee display name based on the selected
	 * user.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * A string holding the assignee display name.
	 * @memberof WorkItemExpandComponent
	 */
	public async getAssigneeDisplayName(): Promise<string>
	{
		if (AnyHelper.isNullOrWhitespace(this.selectedItem.data.assignedTo))
		{
			return this.unassigned;
		}

		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;

		const user: IEntityInstance =
			await this.entityInstanceApiService.getSingleQueryResult(
				`${AppConstants.commonProperties.userName} eq ` +
					`'${this.selectedItem.data.assignedTo}'`,
				AppConstants.empty);

		return AnyHelper.isNullOrEmpty(user)
			? `User ${this.selectedItem.data.assignedTo} not found`
			: `${user.data.firstName} ${user.data.lastName}`;
	}

	/**
	 * Handles an update of the current item given an update promise. This
	 * is used in operation functions that have multiple update methods
	 * for different entities.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItemExpandComponent
	 */
	public async handleUpdate(
		updatePromise: Promise<any>): Promise<void>
	{
		this.saving = true;
		await updatePromise;

		// Force a reload to re-apply the filter to altered data.
		this.context.source.filterCriteriaChanged(
			this.context.source.tableDefinitions.objectSearch.filter);

		this.saving = false;
	}
}