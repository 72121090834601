<div class="ui-g-12 no-padding text-center">
	<ng-template
		[ngIf]="loading"
		[ngIfElse]="CommonList">
		<i
			class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</ng-template>

	<ng-template
		#CommonList>

		<app-dynamic-component
			[context]="commonListContext"
			displayComponent="CommonListComponent">
		</app-dynamic-component>

	</ng-template>
</div>