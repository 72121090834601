<ng-template
	[ngIf]="loading"
	[ngIfElse]="Content">
	<i
		class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>

<ng-template
	#Content>

	<ng-template
		[ngIf]="(supportedNoteTypes?.length || 0) === 0"
		[ngIfElse]="Panel">
		<div
			class="ui-g-12 text-center">
			No available note types to add.
		</div>
	</ng-template>

	<ng-template
		#Panel>
		<div
			class="ui-g-12 text-left no-padding">
			<div
				class="ui-g-12 nested-display-subtitle text-left">
				<div class="ellipsis-text">
					Add: Note
				</div>
			</div>

			<p-scrollPanel styleClass="note-container">
				<div class="ui-g-12 no-padding">
					<div
						class="ui-g-12 custom-field-wrapper text-left">
						<div class="input-label">
							<label
								for="noteType">
								<strong>
									Type *
								</strong>
							</label>
						</div>
						<p-dropdown
							styleClass="full-width"
							inputId="noteType"
							[options]="noteTypes"
							placeholder="Select a Note Type"
							showClear=true
							[filter]="true"
							optionLabel="label"
							optionValue="value"
							filterBy="label"
							filterPlaceholder="Filter"
							[autoDisplayFirst]="false"
							[(ngModel)]="selectedNoteType"
							(onChange)="handleNoteTypeChange($event)"
							[disabled]="(supportedNoteTypes?.length || 1) === 1">
						</p-dropdown>
					</div>

					<ng-template
						[ngIf]="(selectedNoteType || null !== null)
							&& (noteLayout || null !== null)">
						<app-dynamic-formly
							[dataSet]="noteData"
							[layoutSchema]="formlyNoteLayout"
							(validityChanged)="validDataChanged($event)">
						</app-dynamic-formly>
					</ng-template>
				</div>
			</p-scrollPanel>
		</div>

		<div
			class="ui-g-12 no-padding text-right button-set">
			<a class="text-link theme-color secondary-action-button"
				(click)="changeDisplayMode.emit('List')">
				Cancel
			</a>
			<button
				pButton
				type="button"
				label="Add"
				class="no-margin-right"
				(click)="createNote()"
				[disabled]="isValid === false || saving === true">
			</button>
		</div>
	</ng-template>

</ng-template>