<div class="ui-g-12 no-padding">

	<div #HistoryTimelineHeader
		class="ui-g-12 no-padding">
		<div class="ui-g-9 history-timeline-header">
			<div class="text-left p-inputgroup search-filter">
				<input
					#searchText
					pInputText
					type="text"
					class="ui-g-12"
					placeholder="Filter"
					(keyup.enter)="handleSearchTextAdd(searchText)" />
				<button
					pButton
					type="button"
					icon="pi pi-search"
					class="p-button-icon"
					(click)="handleSearchTextAdd(searchText)">
				</button>
			</div>

			<div class="filter-add-container theme-color"
				(click)="QuickFilterMenu.toggle($event)">
				<i class="fa fa-filter fa-fw text-link">
				</i>
				<span class="filter-plus-icon text-link">
					+
				</span>
			</div>
			<div class="quick-filter-context-menu-container">
				<p-menu #QuickFilterMenu
					styleClass="quick-filter-context-menu"
					[popup]="true"
					[model]="quickFilterMenuItems">
				</p-menu>
			</div>
		</div>

		<div class="ui-g-12 text-left history-timeline-chips"
			*ngIf="(enabledFilters?.length ?? 0) > 0">
			<div class="filters p-chips p-component">
				<ul #FilterChipsContainer
					class="p-chips-multiple-container">
					<ng-template
						ngFor
						let-enabledFilter
						[ngForOf]="enabledFilters">
						<li class="p-chips-token inverse-theme-container">
							<div class="ui-g-12 chip-container">
								<div class="p-chips-token-label ellipsis-text">
									<i class="fa {{enabledFilter.icon}}"
										*ngIf="enabledFilter.icon != null">
									</i>
									{{enabledFilter?.label}}
								</div>
								<div class="p-chips-token-icon text-right cursor-pointer"
									(click)="handleRemoveFilterEvent(enabledFilter?.label, enabledFilter?.icon)">
									<i class="pi pi-fw pi-times">
									</i>
								</div>
							</div>
						</li>
					</ng-template>
				</ul>
			</div>

			<div *ngIf="(enabledFilters?.length ?? 0) > 1"
				class="multiple-filters-warning theme-color-gray text-center font-italic">
				Items have to meet all conditions to be returned.
			</div>
		</div>

		<div class="ui-g-12 no-padding text-left history-timeline-sorter"
			[ngClass]="{
				'no-border-bottom': ((filteredEvents?.length ?? 0) === 0 || (this.keyDateEvents.length === 0 && this.keyActivityEvents.length === 0 && this.changeEvents.length === 0))
			}">
			<div class="sorter">
				<span class="text-link theme-color">
					{{selectedSorter.name}}
				</span>

				<span class="icon">
					<ng-template
						[ngIf]="selectedSorter.direction === 'asc'"
						[ngIfElse]="SortDesc">
						<i class="{{selectedSorter.iconAsc}} fa-fw text-link theme-color"
							(click)="handleSortChangeEvent()">
						</i>
					</ng-template>
					<ng-template
						#SortDesc>
						<i class="{{selectedSorter.iconDesc}} fa-fw text-link theme-color"
							(click)="handleSortChangeEvent()">
						</i>
					</ng-template>
				</span>
			</div>
		</div>
	</div>

	<div class="ui-g-12 no-padding text-center"
		*ngIf="loading === true">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>

	<p-scrollPanel #HistoryTimelineScroller
		styleClass="history-timeline-scroll-content"
		class="history-timeline-scroll-container"
		*ngIf="loading === false">

		<div class="ui-g-12 no-horizontal-padding history-timeline-container {{siteLayoutService.contentCssClass}}">
			<ng-template #Timeline
				[ngIf]="filteredEvents.length > 0"
				[ngIfElse]="NoEventsDisplay">
				<p-timeline [value]="filteredEvents">
					<ng-template pTemplate="opposite" let-event>
						<div class="timeline-text">
							<small class="p-text-secondary">
								{{ event.dateTime | dateTimeToFormat:'D h:mm a' }}
							</small>
						</div>
					</ng-template>
					<ng-template pTemplate="marker" let-event>
						<span class="p-timeline-event-marker"
							[ngClass]="{
								'cursor-pointer': (event.icon.indexOf('calendar') === -1),
								'theme-color': (event.icon.indexOf('calendar') === -1),
								'theme-border-color': (event.icon.indexOf('calendar') === -1)
							}"
							(click)="selectItem(event)">
							<i [class]="event.icon">
							</i>
						</span>
					</ng-template>
					<ng-template pTemplate="content" let-event>
						<div class="timeline-text"
							[pTooltip]="event.summary"
							tooltipPosition="left"
							mobileTooltip
							#tooltipDirective="mobileTooltip"
							(tap)="tooltipDirective.mobileTooltipToggle()"
							(click)="tooltipDirective.preventDefault($event)">
							<div class="ellipsis-text">
								<div class="ellipsis-text">
									{{ event.summary }}
								</div>
							</div>
						</div>
					</ng-template>
				</p-timeline>
			</ng-template>

			<ng-template #NoEventsDisplay>
				<div class="ui-g-12 no-padding text-center">
					<ng-template #NoData
						[ngIf]="this.keyDateEvents.length === 0 && this.keyActivityEvents.length === 0 && this.changeEvents.length === 0"
						[ngIfElse]="DataFiltered">
						No history data found.
					</ng-template>
					<ng-template #DataFiltered>
						No results found.
					</ng-template>
				</div>
			</ng-template>
		</div>

	</p-scrollPanel>
</div>
