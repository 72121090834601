/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AdminModule
} from '@admin/admin.module';
import {
	APP_BASE_HREF,
	LocationStrategy,
	PathLocationStrategy
} from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClientModule
} from '@angular/common/http';
import {
	APP_INITIALIZER,
	ApplicationRef,
	ErrorHandler,
	Injector,
	NgModule
} from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule
} from '@angular/forms';
import {
	BrowserModule,
	HAMMER_GESTURE_CONFIG,
	HammerModule
} from '@angular/platform-browser';
import {
	RouteReuseStrategy
} from '@angular/router';
import {
	ServiceWorkerModule,
	SwUpdate
} from '@angular/service-worker';
import {
	ApiModule
} from '@api/api.module';
import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	AppBannerDetailComponent
} from '@appComponents/app-banner/app-banner-detail/app-banner-detail.component';
import {
	AppBannerComponent
} from '@appComponents/app-banner/app-banner/app-banner.component';
import {
	AppDrawerComponent
} from '@appComponents/app-drawers/app-drawer/app-drawer.component';
import {
	AppDrawersComponent
} from '@appComponents/app-drawers/app-drawers.component';
import {
	AppFooterComponent
} from '@appComponents/app-footer/app-footer.component';
import {
	AppFullScreenBannerComponent
} from '@appComponents/app-full-screen-banner/app-full-screen-banner.component';
import {
	AppMenuComponent
} from '@appComponents/app-menu/app-menu.component';
import {
	AppProfileComponent
} from '@appComponents/app-profile/app-profile.component';
import {
	AppTopBarComponent
} from '@appComponents/app-topbar/app-topbar.component';
import {
	AppUtilityMenuComponent
} from '@appComponents/app-utility-menu/app-utility-menu.component';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	LoginChangePasswordComponent
} from '@appComponents/login/login-change-password.component';
import {
	LoginDialogComponent
} from '@appComponents/login/login-dialog.component';
import {
	LoginResetComponent
} from '@appComponents/login/login-reset.component';
import {
	LoginVerifyComponent
} from '@appComponents/login/login-verify.component';
import {
	LoginComponent
} from '@appComponents/login/login.component';
import {
	BiModule
} from '@bi/bi.module';
import {
	ClaimsModule
} from '@claims/claims.module';
import {
	EntityModule
} from '@entity/entity.module';
import {
	InsuranceModule
} from '@insurance/insurance.module';
import {
	OperationModule
} from '@operation/operation.module';
import {
	AppCacheHttpInterceptor
} from '@shared/app-cache-http.interceptor';
import {
	AppDataTransformHttpInterceptor
} from '@shared/app-data-transform-http.interceptor';
import {
	AppErrorHandler
} from '@shared/app-error-handler';
import {
	AppHammerConfig
} from '@shared/app-hammer.config';
import {
	AppSecurityHttpInterceptor
} from '@shared/app-security-http.interceptor';
import {
	DisplayComponentParameterDirective
} from '@shared/directives/display-component-parameter.directive';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	SharedModule
} from '@shared/shared.module';
import {
	WorkItemsModule
} from '@workItems/work-items.module';
import {
	AccountModule
} from 'src/app/account/account.module';
import {
	AppComponent
} from 'src/app/app.component';
import {
	AppConfig
} from 'src/app/app.config';
import {
	AppRoutes
} from 'src/app/app.routes';
import {
	environment
} from 'src/environments/environment';
import {
	AppRouteReuseStrategy
} from './app.route-reuse-strategy';

/* eslint-enable max-len */

export function initializeApp()
{
	return async () =>
		AppConfig.settings = await AppConfig.load();
}

@NgModule({
	imports: [
		AccountModule,
		AdminModule,
		BiModule,
		ApiModule,
		AppRoutes,
		BrowserModule,
		ClaimsModule,
		EntityModule,
		FormsModule,
		HammerModule,
		HttpClientModule,
		InsuranceModule,
		OperationModule,
		ReactiveFormsModule,
		ServiceWorkerModule.register(
			'ngsw-worker.js',
			{
				enabled: environment.production
			}),
		SharedModule,
		WorkItemsModule
	],
	declarations: [
		AccessDeniedComponent,
		AppBannerComponent,
		AppBannerDetailComponent,
		AppComponent,
		AppDrawerComponent,
		AppDrawersComponent,
		AppFooterComponent,
		AppFullScreenBannerComponent,
		AppMenuComponent,
		AppProfileComponent,
		AppUtilityMenuComponent,
		AppTopBarComponent,
		DisplayComponentParameterDirective,
		GenericBasePageComponent,
		GenericDashboardComponent,
		LoginChangePasswordComponent,
		LoginComponent,
		LoginDialogComponent,
		LoginResetComponent,
		LoginVerifyComponent
	],
	providers: [
		AppAuthGuard,
		AppConfig,
		ApplicationRef,
		SwUpdate,
		{
			provide: APP_BASE_HREF,
			useValue: '/'
		},
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [AppConfig],
			multi: true
		},
		{
			provide: ErrorHandler,
			useClass: AppErrorHandler
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: AppHammerConfig
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppSecurityHttpInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppCacheHttpInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppDataTransformHttpInterceptor,
			multi: true
		},
		{
			provide: LocationStrategy,
			useClass: PathLocationStrategy
		},
		{
			provide: RouteReuseStrategy,
			useClass: AppRouteReuseStrategy
		}
	],
	bootstrap: [
		AppComponent
	]
})

/**
 * The primary application module.
 *
 * @export
 * @class SharedModule
 */
export class AppModule
{
	/**
	 * Initializes a new instance of the AppModule class.
	 *
	 * @param {Injector} injector The injector.
	 * @memberof AppModule
	 */
	public constructor(
		injector: Injector)
	{
		AppModule.injector = injector;
	}

	/**
	* Allows for retrieving singletons using `AppModule.injector.get(MyService)`
	* This is good to prevent injecting the service as constructor parameter.
	*/
	public static injector: Injector;
}

/**
 * Global declaration for the module.
 */
declare global
{
	/**
	 * An interface to expand the window accessiability.
	 *
	 * @interface Window
	 */
	interface Window
	{
		/**
		 * The Genesys object.
		 *
		 * @type {any}
		 * @memberof Window
		 */
		Genesys: any;

		/**
		 * The stonly widget id.
		 *
		 * @type {string}
		 * @memberof Window
		 */
		STONLY_WID: string;

		/**
		 * The stonly widget object.
		 *
		 * @type {any}
		 * @memberof Window
		 */
		StonlyWidget: any;
	}
}