<div class="custom-data-select-container"
	[ngClass]="{
		'filter-api-loading': filterApiLoading === true
	}">

	<ng-template
		[ngIf]="loading"
		[ngIfElse]="Loaded">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</ng-template>

	<ng-template #Loaded>
		<ng-template [ngIf]="filterApiLoading === true">
			<span class="filter-spinner-container">
				<i class="filter-spinner theme-color fa fa-fw fa-spin fa-spinner">
				</i>
			</span>
		</ng-template>

		<ng-template #Select
			[ngIf]="this.field.props.useMultiSelect != true"
			[ngIfElse]="MultiSelect">
			<custom-select
				[field]="field"
				[dataOptions]="dataOptions"
				(onfilterEvent)="onFilter($event)">
			</custom-select>
		</ng-template>
		<ng-template #MultiSelect>
			<custom-multi-select
				[field]="field"
				[dataOptions]="dataOptions"
				(onfilterEvent)="onFilter($event)">
			</custom-multi-select>
		</ng-template>
	</ng-template>

	<field-validation-message
		[field]="field">
	</field-validation-message>

</div>