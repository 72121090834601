/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IRuleViolationWorkflowActionDefinition
} from '@shared/interfaces/rules/rule-violation-workflow-action-definition.interface';

/* eslint-disable max-len */

/**
 * A class containing static helper methods for rules.
 *
 * @export
 * @class RuleHelper
 */
export class RuleHelper
{
	/**
	 * Given a set of populated rule violations, this will filter down to find
	 * if any violations are blocking, not overridden, and a match for the
	 * sent workflow action name.
	 *
	 * @static
	 * @param {IEntityInstanceRuleViolation[]} data
	 * The set of rule violations to check for a matching blocking violation.
	 * @param {string} workflowActionName
	 * The workflow action name to check for a blocking match.
	 * @returns {boolean}
	 * A value signifying whether or not the sent rule violations contain
	 * a matching blocked rule violation for this action.
	 * @memberof RuleHelper
	 */
	public static isActionBlocked(
		populatedRuleViolations: IEntityInstanceRuleViolation[],
		workflowActionName: string): boolean
	{
		return populatedRuleViolations.filter(
			(populatedRuleViolation: IEntityInstanceRuleViolation) =>
				populatedRuleViolation.ruleDefinition
					.ruleViolationWorkflowActionDefinitions
					.filter(
						(ruleViolationWorkflowActionDefinition:
							IRuleViolationWorkflowActionDefinition) =>
							ruleViolationWorkflowActionDefinition
								.blocked === true
								&& ruleViolationWorkflowActionDefinition
									.overridden === false
								&& ruleViolationWorkflowActionDefinition
									.workflowActionDefinition.name ===
										workflowActionName)
					.length > 0)
			.length > 0;
	}
}