/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActionDefinitionExpandComponent
} from '@admin/components/workflow-engine/action-definitions/action-definitions/action-definition-expand/action-definition-expand.component';
import {
	Component
} from '@angular/core';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	WorkflowActionRequisitesApiService
} from '@api/services/workflow/workflow-action-requisites.api.service';
import {
	WorkflowFailureActionsApiService
} from '@api/services/workflow/workflow-failure-actions.api.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-action-definitions',
	templateUrl: './action-definitions.component.html',
	styleUrls: ['./action-definitions.component.scss']
})

/**
 * A component representing an instance of the workflow engine action
 * definitions.
 *
 * @export
 * @class ActionDefinitionsComponent
 * @extends {CommonTablePageDirective}
 */
export class ActionDefinitionsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the ActionDefinitionComponent class.
	 *
	 * @param {WorkflowActionDefinitionsApiService}
	 *	workflowActionDefinitionsApiService
	 * The api service used to gather action definition data.
	 * @param {WorkflowActionRequisitesApiService}
	 * workflowActionRequisitesApiService
	 * The api service used to gather action requisite data.
	 * @param {WorkflowFailureActionsApiService}
	 * workflowFailureActionsApiService
	 * The api service used to gather action failure data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to gather entity type data.
	 * @param {ActivityService} activityService
	 * The Activity Service.
	 * @memberof ActionDefinitionsComponent
	 */
	public constructor(
		public workflowActionDefinitionsApiService:
			WorkflowActionDefinitionsApiService,
		public workflowActionRequisitesApiService:
			WorkflowActionRequisitesApiService,
		public workflowFailureActionsApiService:
			WorkflowFailureActionsApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the display management context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ActionDefinitionsComponent
	 */
	public actionDefinitionsContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {object}
	 * @memberof ActionDefinitionsComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the table filter.
	 *
	 * @type {string}
	 * @memberof ActionDefinitionsComponent
	 */
	public tableFilter: string = AppConstants.empty;

	/**
	 * Gets or sets the full set of entity types.
	 *
	 * @type {IEntityType[]}
	 * @memberof ActionDefinitionsComponent
	 */
	public entityTypes: IEntityType[] = [];

	/**
	 * Gets or sets the full set of entity versions.
	 *
	 * @type {IEntityVersion[]}
	 * @memberof ActionDefinitionsComponent
	 */
	public entityVersions: IEntityVersion[] = [];

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ActionDefinitionsComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'entityTypeName',
					columnHeader: 'Entity Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'entityVersionNumber',
					columnHeader: 'Entity Version',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'classReference',
					columnHeader: 'Class Reference',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'failureActionId',
					columnHeader: 'Failure Action ',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'createDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'Create Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'startDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'Start Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'endDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'End Date',
					displayOrder: displayOrder
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for the standard table
	 *
	 * @async
	 * @memberof ActionDefinitionsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.entityTypes =
			await ApiHelper.getFullDataSet(
				this.entityTypeApiService,
				AppConstants.empty,
				AppConstants.empty);
		this.entityVersions =
			await ApiHelper.getFullDataSet(
				this.entityVersionApiService,
				AppConstants.empty,
				AppConstants.empty);

		this.actionDefinitionsContext =
			<IDynamicComponentContext<Component, any>>
			{
				source: this
			};

		const entityTypeFilterOptions =
			this.entityTypes
				.map((type: IEntityType) =>
					({
						label: type.name,
						value: `EntityTypeId eq ${type.id}`
					}));

		entityTypeFilterOptions.unshift(
			{
				label: 'All Entity Types',
				value: AppConstants.empty
			});

		this.tableDefinitions =
			{
				tableTitle: 'Definitions',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Workflow Action Definition'),
				objectSearch: {
					filter: this.tableFilter,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const workflowDefinitions:
							IWorkflowActionDefinitions[] =
							await this.workflowActionDefinitionsApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						const definitionViewModels: any[] = [];
						for (const definition of workflowDefinitions)
						{
							definitionViewModels.push(
								await this.decorateViewModel(definition));
						}

						return definitionViewModels;
					},
				decorateViewModel:
					async(definition: any) =>
						this.decorateViewModel(definition),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					create: {
						customContext: this.actionDefinitionsContext,
						component: ActionDefinitionExpandComponent,
						items:
							[
								{
									label: 'Create',
									styleClass:
										AppConstants.cssClasses.pButtonPrimary,
									command: async() =>
										this.createActionDefinition()
								}
							]
					},
					update: {
						customContext: this.actionDefinitionsContext,
						component: ActionDefinitionExpandComponent,
						items:
							[
								{
									label: 'Save',
									styleClass:
										AppConstants.cssClasses.pButtonPrimary,
									command: () => this.updateActionDefinition()
								}
							]
					},
					delete: {
						items:
							[
								{
									label: 'Confirm',
									styleClass:
										AppConstants.cssClasses.pButtonDanger,
									disabled: false,
									command: () => this.deleteActionDefinition()
								}
							],
						deleteStatement: () => this.getDeleteStatement(),
					},
					view: {
						customContext: this.actionDefinitionsContext,
						component: ActionDefinitionExpandComponent,
						items: []
					},
					filter: {
						quickFilters: entityTypeFilterOptions,
						selectedFilterValue: this.tableFilter
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Decorates the view model for the object.
	 *
	 * @param {IWorkflowActionDefinitions} definition
	 * The object to be decorated as a view model.
	 * @returns {Promise<any>}
	 * The mapped view model for display in this component.
	 * @memberof ActionDefinitionsComponent
	 */
	public decorateViewModel(
		definition: IWorkflowActionDefinitions): Promise<any>
	{
		const entityVersion: IEntityVersion =
			this.entityVersions.find(
				(version: IEntityVersion) =>
					version.id === definition.entityVersionId);
		const entityType: IEntityType =
			this.entityTypes.find(
				(type: IEntityType) =>
					type.id === definition.entityTypeId);

		const viewModel: any =
			<any>
			{
				id: definition.id,
				entityTypeId: definition.entityTypeId,
				entityVersionId: definition.entityVersionId,
				entityTypeName: entityType.name,
				entityVersionNumber: entityVersion.number,
				name: definition.name,
				description: definition.description,
				classReference: definition.classReference,
				classDefinition: definition.classDefinition,
				failureActionId: definition.failureActionId,
				createDate: definition.createDate,
				startDate: definition.startDate,
				endDate: definition.endDate
			};

		return viewModel;
	}

	/**
	 * Creates an action definition.
	 *
	 * @async
	 * @memberof ActionDefinitionsComponent
	 */
	private async createActionDefinition(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const createActionDefinition: Function =
			async() =>
			{
				const workflowActionDefinition: IWorkflowActionDefinitions =
					<IWorkflowActionDefinitions>
					{
						entityTypeId: selectedItem.entityTypeId,
						entityVersionId: selectedItem.entityVersionId,
						name: selectedItem.name,
						description: selectedItem.description,
						classReference:
							AnyHelper.isNullOrWhitespace(
								selectedItem.classDefinition)
								? selectedItem.classReference
								: null,
						classDefinition:
							AnyHelper.isNullOrWhitespace(
								selectedItem.classReference)
								? selectedItem.classDefinition
								: null,
						failureActionId: selectedItem.failureActionId
					};

				const createdId: number =
					await this.workflowActionDefinitionsApiService
						.create(workflowActionDefinition);

				this.commonTableContext.source.selectedItem =
					await this.workflowActionDefinitionsApiService.get(
						createdId);

				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				createActionDefinition(),
				'<strong>Creating Action Definition</strong>',
				'<strong>Created Action Definition</strong>',
				`Action Definition
					${selectedItem.name}
					was successfully created.`,
				`Action Definition
					${selectedItem.name}
					was not created.`));
	}

	/**
	 * Updates an action definition.
	 *
	 * @async
	 * @memberof ActionDefinitionsComponent
	 */
	private async updateActionDefinition(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const updateActionDefinition: Function =
			async() =>
			{
				const workflowActionDefinition: IWorkflowActionDefinitions =
					<IWorkflowActionDefinitions>
					{
						id: selectedItem.id,
						entityTypeId: selectedItem.entityTypeId,
						entityVersionId: selectedItem.entityVersionId,
						name: selectedItem.name,
						description: selectedItem.description,
						classReference:
							AnyHelper.isNullOrWhitespace(
								selectedItem.classDefinition)
								? selectedItem.classReference
								: null,
						classDefinition:
							AnyHelper.isNullOrWhitespace(
								selectedItem.classReference)
								? selectedItem.classDefinition
								: null,
						failureActionId: selectedItem.failureActionId
					};

				await this.workflowActionDefinitionsApiService
					.update(
						selectedItem.id,
						workflowActionDefinition);

				await this.commonTableContext.source.updateSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				updateActionDefinition(),
				'<strong>Updating Action Definition</strong>',
				'<strong>Updated Action Definition</strong>',
				`Action Definition
					${this.commonTableContext.source.selectedItem.name}
					was successfully updated.`,
				`Action Definition
					${this.commonTableContext.source.selectedItem.name}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Deletes an action definition.
	 *
	 * @async
	 * @memberof ActionDefinitionsComponent
	 */
	private async deleteActionDefinition(): Promise<void>
	{
		const deleteActionDefinition: Function =
			async() =>
			{
				await this.workflowActionDefinitionsApiService
					.delete(
						this.commonTableContext.source.selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteActionDefinition(),
				'<strong>Deleting Action Definition</strong>',
				'<strong>Deleted Action Definition</strong>',
				`Action Definition
					${this.commonTableContext.source.selectedItem.name}
					was successfully deleted.`,
				`Action Definition
					${this.commonTableContext.source.selectedItem.name}
					was not deleted.`));
	}

	/**
	 * Gets the delete statement.
	 *
	 * @async
	 * @return {Promise<string>}
	 * The delete statement string.
	 * @memberof ActionDefinitionsComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		const existingRequisites =
			await this.workflowActionRequisitesApiService
				.query(
					'ActionDefinitionId eq '
						+ `${this.commonTableContext.source.selectedItem.id} `
						+ 'Or RequisiteActionDefinitionId eq '
						+ `${this.commonTableContext.source.selectedItem.id}`,
					AppConstants.empty);

		if (existingRequisites.length > 0)
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[0].disabled = true;

			return `Unable to delete Worflow Action Definition
				${this.commonTableContext.source.selectedItem.id}
				${this.commonTableContext.source.selectedItem.name}
				due to existing requisite relationship
				action definition id
				${existingRequisites[0].actionDefinitionId}
				and requisite action definition id
				${existingRequisites[0].requisiteActionDefinitionId}.`;
		}
		else
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[0].disabled = false;

			return `Confirm you are about to delete Workflow Action Definition
				${this.commonTableContext.source.selectedItem.id}
				${this.commonTableContext.source.selectedItem.name}.`;
		}
	}
}