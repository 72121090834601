<div class="entity-select">
	<div class="ui-g-12">
		<div class="ui-g-12 no-padding search-filter-container"
			*ngIf="categoryOptions.length === categories.length">
			<app-search-filter
				[searchOptions]="categoryOptions"
				[loadedFilterCriteria]="filterValue"
				[loadedCategoryOption]="entityTypeGroup"
				[alignCentered]="true"
				(filterCriteriaChanged)="filterCriteriaChanged($event)"
				(categoryChanged)="selectedCategoryChanged($event)">
			</app-search-filter>
		</div>

		<div *ngIf="loadingTableDefinitions || categoryOptions.length !== categories.length"
			class="text-center ui-g-12">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>

		<div class="ui-g-12 table-container"
			*ngIf="!loadingEntityType && !loadingTableDefinitions"
			[@contentAnimation]="state">
			<app-common-table
				[tableDefinitions]="entitySelectTableDefinitions"
				[selectedItems]="selectedEntities"
				[displayKeywordMessage]="displayKeywordMessage">
			</app-common-table>
		</div>
	</div>
</div>