/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	IRuleDefinitionDto
} from '@api/interfaces/rules/rule-definition.dto.interface';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	RuleDefinitionApiService
} from '@api/services/rules/rule-definition.api.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IRuleDefinition
} from '@shared/interfaces/rules/rule-definition.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-entity-rules',
	templateUrl: './entity-rules.component.html'
})

/**
 * A component representing an instance of the system entity rules
 * component.
 *
 * @export
 * @class EntityRulesComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class EntityRulesComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Creates an instance of an EntityRulesComponent.
	 *
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The api service used to get the entity definition data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {RuleDefinitionApiService} ruleDefinitionApiService
	 * The api service used to get rule definition data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router service.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityRulesComponent
	 */
	public constructor(
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public ruleDefinitionApiService: RuleDefinitionApiService,
		public route: ActivatedRoute,
		public router: Router,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntityRulesComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {IEntityType}
	 * @memberof EntityRulesComponent
	 */
	public entityType: IEntityType;

	/**
	 * Gets or sets the table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityRulesComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the entity definition id.
	 *
	 * @type {number}
	 * @memberof EntityRulesComponent
	 */
	public entityDefinitionId: number;

	/**
	 * Initializes the component to set the page variables
	 * and setup the table definitions.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		this.setupTableDefinitions();
	}

	/**
	 * Sets the page variables needed for this component.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.tableFilterQuery = 'EntityTypeId'
			+ ` eq ${this.entityDefinition.typeId}`
			+ ' and EntityVersionId eq'
			+ ` ${this.entityDefinition.versionId}`;

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'displayName',
					columnHeader: 'Display Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'overridable',
					columnHeader: 'Overridable',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'order',
					columnHeader: 'Order',
					displayOrder: displayOrder
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets the common table definitions needed for this component.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.tableDefinitions =
			{
				tableTitle: 'Rule Definitions',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Rule Definition'),
				hideTableTitle: true,
				objectSearch: {
					filter: this.tableFilterQuery,
					orderBy: `Order ${AppConstants.sortDirections.ascending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
						this.ruleDefinitionApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					create: {
						layout: [
							{
								key: 'name',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Name',
									required: true
								},
								asyncValidators: {
									uniqueName: {
										expression: (
											control: UntypedFormControl) =>
											this.uniqueName(control),
										message: 'Existing Rule Name.'
									}
								}
							}
						],
						items: [
							{
								label: 'Create',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: async() => this.createAction()
							}]
					},
					update: {
						disabledExpandItem: true,
						items: [
							{
								command: () =>
								{
									const routeData: string =
										ObjectHelper.mapRouteData(
											{
												ruleDefinitionId:
													this.commonTableContext
														.source.selectedItem.id
											});

									this.router.navigate(
										[
											'admin/entity/ruleDefinition/edit',
											this.entityDefinitionId
										],
										{
											queryParams:
												{
													routeData: routeData
												}
										});
								}
							}
						]
					},
					delete: {
						items: [
							{
								label: 'Confirm',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: async() => this.deleteAction(),
							}],
						deleteStatement: () => this.getDeleteStatement(),
					},
					updateIndex:
					[
						{
							id: 'updateIndexUp',
							command: async(
								selectedItem: any) =>
								this.updateOrderIndex(
									selectedItem,
									-1)
						},
						{
							id: 'updateIndexDown',
							command: async(
								selectedItem: any) =>
								this.updateOrderIndex(
									selectedItem,
									1)
						}
					],
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Creates a new entity layout.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	private async createAction(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const createRuleAction:
			Function = async() =>
			{
				let order: number = 10;
				if (!AnyHelper.isNull(
					this.commonTableContext.source.virtualData)
					&& this.commonTableContext.source.virtualData.length > 0)
				{
					order =
						this.commonTableContext.source.virtualData[
							this.commonTableContext.source
								.virtualData.length - 1].order;
				}

				const newRule: IRuleDefinitionDto =
						<IRuleDefinitionDto>
						{
							name: selectedItem.name,
							entityTypeId: this.entityDefinition.typeId,
							entityVersionId: this.entityDefinition.versionId,
							displayName: selectedItem.name,
							overridable: false,
							description: null,
							order: order
						};

				const newRuleId: number =
						await this.ruleDefinitionApiService
							.create(newRule);

				this.router.navigate(
					[
						'admin/entity/ruleDefinition/edit',
						this.entityDefinitionId
					],
					{
						queryParams:
							{
								routeData:
									ObjectHelper.mapRouteData(
										{
											ruleDefinitionId:
												newRuleId
										})
							}
					});
			};

		await this.activityService.handleActivity(
			new Activity(
				createRuleAction(),
				'<strong>Creating Rule Definition</strong>',
				'<strong>Created Rule Definition</strong>',
				`Rule Definition ${selectedItem.name}`
					+ ` was created to Entity ${this.entityType.name}.`,
				`Rule Definition ${selectedItem.name}`
					+ ` was not created to Entity ${this.entityType.name}.`));
	}

	/**
	 * Gets the common table delete statement.
	 *
	 * @async
	 * @returns {string}
	 * The delete statement
	 * @memberof EntityLayoutsComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		return `Confirm you are about to delete Rule Definition
			${this.commonTableContext.source.selectedItem.id}
			${this.commonTableContext.source.selectedItem.name}.`;
	}

	/**
	 * Deletes an existing entity access policy.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	private async deleteAction(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const deleteAction: Function =
			async() =>
			{
				await this.ruleDefinitionApiService
					.delete(selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteAction(),
				'<strong>Deleting Rule Definition</strong>',
				'<strong>Deleted Rule Definition</strong>',
				`Rule Definition ${selectedItem.name}`
					+ ` was deleted from Entity ${this.entityType.name}.`,
				`Rule Definition ${selectedItem.name}`
					+ ` was not deleted from Entity ${this.entityType.name}.`));
	}

	/**
	 * Validates the name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Form Control used to get the input value
	 * @returns {Promise<boolean>}
	 * The Validator promise result.
	 * @memberof EntityRulesComponent
	 */
	private async uniqueName(control: UntypedFormControl): Promise<boolean>
	{
		const existingRules: IRuleDefinitionDto[] =
			await this.ruleDefinitionApiService
				.query(
					`name eq '${control.value}'`
						+ ' and entityTypeId'
						+ ` eq ${this.entityDefinition.typeId}`
						+ ' and entityVersionId'
						+ ` eq '${this.entityDefinition.versionId}'`,
					AppConstants.empty);

		return Promise.resolve(existingRules.length === 0);
	}

	/**
	 * Updates the order index of the selected row item
	 * up or down based on the indexOperator.
	 *
	 * @async
	 * @param {any} selectedItem
	 * The selected item to update the index of.
	 * @param {number} indexReference
	 * The index reference to add or substract to the current
	 * selected order index.
	 * @memberof EntityRulesComponent
	 */
	private async updateOrderIndex(
		selectedItem: any,
		indexReference: number): Promise<void>
	{
		this.commonTableContext.source.loadingNextDataset = true;
		this.commonTableContext.source.selectedItem = selectedItem;

		const updateOrderIndex: Function =
			async() =>
			{
				const orderedRequisites: any[] =
					this.commonTableContext.source.virtualData
						.filter((data) => data !== undefined)
						.sort((itemOne: any, itemTwo: any) =>
							itemOne.order - itemTwo.order);

				const neighborOrderIndex: number =
						this.findSelectedChildIndex(
							orderedRequisites,
							selectedItem) + indexReference;

				const neighbor: any =
					orderedRequisites[neighborOrderIndex];

				await this.ruleDefinitionApiService
					.update(
						neighbor.id,
						<IRuleDefinition>
						{
							id: neighbor.id,
							entityTypeId: neighbor.entityTypeId,
							entityVersionId: neighbor.entityVersionId,
							name: neighbor.name,
							displayName: neighbor.displayName,
							description: neighbor.description,
							overridable: neighbor.overridable,
							order: 1000
						});

				await this.ruleDefinitionApiService
					.update(
						selectedItem.id,
						<IRuleDefinition>
						{
							id: selectedItem.id,
							entityTypeId: selectedItem.entityTypeId,
							entityVersionId: selectedItem.entityVersionId,
							name: selectedItem.name,
							displayName: selectedItem.displayName,
							description: selectedItem.description,
							overridable: selectedItem.overridable,
							order: neighbor.order
						});

				await this.ruleDefinitionApiService
					.update(
						neighbor.id,
						<IRuleDefinition>
						{
							id: neighbor.id,
							entityTypeId: neighbor.entityTypeId,
							entityVersionId: neighbor.entityVersionId,
							name: neighbor.name,
							displayName:
								neighbor.displayName,
							description: neighbor.description,
							overridable: neighbor.overridable,
							order: selectedItem.order
						});

				this.commonTableContext.source.virtualData[neighborOrderIndex] =
					{
						...orderedRequisites[neighborOrderIndex],
						order: selectedItem.order
					};
				this.commonTableContext.source.virtualData[
					neighborOrderIndex - indexReference] =
					{
						...this.commonTableContext.source.selectedItem,
						order: orderedRequisites[neighborOrderIndex].order
					};

				this.sortCommonTableByOrder();
				this.commonTableContext.source.loadingNextDataset = false;
			};

		await this.activityService.handleActivity(
			new Activity(
				updateOrderIndex(),
				'<strong>Updating Rule Definition Order</strong>',
				'<strong>Updated Rule Definition Order</strong>',
				'Rule Definition Order was successfully updated.',
				'Rule Definition Order was not updated.'),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Finds the selected child index.
	 *
	 * @param {any[]} children
	 * The children object array.
	 * @param {any} selectedChild
	 * The selected child.
	 * @returns {number}
	 * The child index selected.
	 * @memberof EntityRulesComponent
	 */
	private findSelectedChildIndex(
		children: any[],
		selectedChild: any): number
	{
		for (let index = 0; index < children.length; index++)
		{
			if (children[index].id === selectedChild.id
				&& children[index].order === selectedChild.order)
			{
				return index;
			}
		}

		return -1;
	}

	/**
	 * Sorts the common table context data by order.
	 *
	 * @memberof EntityRulesComponent
	 */
	private sortCommonTableByOrder(): void
	{
		this.commonTableContext.source.virtualData
			.sort((itemOne: any, itemTwo: any) =>
				itemOne.order - itemTwo.order);
		this.commonTableContext.source.virtualData =
			[
				...this.commonTableContext.source.virtualData
			];
	}
}