/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component,
	ElementRef,
	Injectable,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import {
	Router
} from '@angular/router';
import {
	ApiError
} from '@api/errors/api.error';
import {
	LoginComponent
} from '@appComponents/login/login.component';
import {
	ShakeAnimation
} from '@shared/app-animations';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	SessionRefreshService
} from '@shared/services/session-refresh.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	AppConfig
} from 'src/app/app.config';

/**
 * A class representing an instance of the login verify component.
 *
 * @export
 * @class LoginResetComponent
 * @implements {OnInit}
 */
@Component({
	selector: 'app-login-verify',
	templateUrl: './login-verify.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		ShakeAnimation
	]
})
@Injectable()
export class LoginVerifyComponent implements OnInit
{
	/**
	 * Creates an instance of LoginVerifyComponent.
	 *
	 * @param {SessionService} sessionService
	 * The session service for this component.
	 * @param {Router} router
	 * The router for this component.
	 * @param {LoggerService} logger
	 * The logger service for this component.
	 * @param {LoginComponent} loginComponent
	 * The primary login component.
	 * @param {FormBuilder} formBuilder
	 * The form builder for this component.
	 * @param {SessionRefreshService} sessionRefreshService
	 * The session refresh service for this component.
	 */
	public constructor(
		private readonly sessionService: SessionService,
		private readonly router: Router,
		private readonly logger: LoggerService,
		private readonly loginComponent: LoginComponent,
		private readonly formBuilder: UntypedFormBuilder,
		private readonly sessionRefreshService: SessionRefreshService)
	{
	}

	/**
	 * Gets or sets the verify form group.
	 *
	 * @public
	 * @type {FormGroup}
	 * @memberof LoginVerifyComponent
	 */
	public verifyForm: UntypedFormGroup;

	/**
	 * Gets or sets a value indicating whether a form submit is in progress.
	 *
	 * @public
	 * @type {boolean}
	 * @memberof LoginVerifyComponent
	 */
	public submitInProgress: boolean = false;

	/**
	 * Gets a value indicating whether the login button is disabled.
	 *
	 * @type {boolean} A combination of status of whether the verifyForm is
	 * valid and submitInProgress.
	 * @memberof LoginDialogComponent
	 */
	public get verifyIsDisabled(): boolean
	{
		return !this.verifyForm.valid || this.submitInProgress;
	}

	/**
	 * Gets or sets the code element.
	 *
	 * @protected
	 * @type {ElementRef}
	 * @memberof LoginVerifyComponent
	 */
	@ViewChild('code', { static: true })
	protected codeElement: ElementRef;

	/**
	 * Gets a {string} value representing the code from the form.
	 *
	 * @private
	 * @type {string}
	 * @memberof LoginVerifyComponent
	 */
	private get code(): string
	{
		return this.verifyForm.controls.code.value;
	}

	/**
	 * Sets a {string} value representing the code to the form.
	 *
	 * @private
	 * @memberof LoginVerifyComponent
	 */
	private set code(
		value: string)
	{
		this.verifyForm.controls.code.setValue(value);
	}

	/**
	 * On initialization event.
	 * Configures the verify component.
	 *
	 * @returns {void}
	 * @memberof LoginVerifyComponent
	 */
	public ngOnInit(): void
	{
		this.verifyForm = this.formBuilder.group(
			{
				'code': new UntypedFormControl('')
			}
		);

		this.codeElement.nativeElement.focus();
	}

	/**
	 * Gets the currently logged in user first name or user name.
	 *
	 * @returns {string}
	 * @memberof LoginVerifyComponent
	 */
	public displayName(): string
	{
		return this.sessionService.user.data.firstName
			|| this.sessionService.user.data.userName;
	}

	/**
	 * Gets the verification method for the user.
	 *
	 * @returns {string}
	 * @memberof LoginVerifyComponent
	 */
	public verifyMethod(): string
	{
		switch (this.sessionService.multiFactorMethod)
		{
			case 'email':
				return 'e-mail';
			case 'cellphone':
				return 'cell phone';
			default:
				return this.sessionService.multiFactorMethod;
		}
	}

	/**
	 * Gets the masked verification destination value.
	 *
	 * @returns {string}
	 * @memberof LoginVerifyComponent
	 */
	public maskedVerifyDestination(): string
	{
		switch (this.sessionService.multiFactorMethod)
		{
			case 'email':
				return this.getMaskedEmail(
					this.sessionService.user.data.email);
			case 'cellphone':
				return this.getMaskedPhone(
					this.sessionService.user.data.cellphone);
			default:
				return null;
		}
	}

	/**
	 * Performs the cancellation event.
	 *
	 * @returns {Promise<void>}
	 * @memberof LoginVerifyComponent
	 */
	public async cancel(): Promise<void>
	{
		this.sessionService.logOut();
		this.loginComponent.displayLogin();
	}

	/**
	 * Performs the verify event.
	 *
	 * @returns {Promise<void>}
	 * @memberof LoginVerifyComponent
	 */
	public async verify(): Promise<void>
	{
		try
		{
			this.submitInProgress = true;
			await this.sessionService.verify(
				this.sessionService.token,
				this.code);
		}
		catch (exception)
		{
			this.logger.logWarning(
				`Verification failure for code ${this.code}`);
			if (exception.status === AppConstants.httpStatusCodes.unauthorized)
			{
				this.loginComponent.addMessage(
					AppConstants.messageLevel.warn,
					AppConstants.loginStatus.invalid,
					'The verification code is incorrect.');

				this.code = AppConstants.empty;
				this.codeElement.nativeElement.focus();

				return;
			}

			if (exception instanceof ApiError)
			{
				this.loginComponent.addMessage(
					AppConstants.messageLevel.error,
					exception.message,
					exception.messages[0].description);
			}
			else
			{
				this.loginComponent.addMessage(
					AppConstants.messageLevel.error,
					AppConstants.loginStatus.failure,
					exception.message);
			}

			return;
		}
		finally
		{
			this.submitInProgress = false;
		}

		this.sessionRefreshService
			.start(AppConfig.settings
				.clientExpirationInterval);

		this.router.navigate([AppConstants.route.dashboardPage]);
	}

	/**
	 * Gets a masked phone number from a {string} value.
	 *
	 * @private
	 * @param {string} value
	 * @returns {string}
	 * @memberof LoginVerifyComponent
	 */
	private getMaskedPhone(
		value: string): string
	{
		return '***-***-**'
			+ value[value.length - 2]
			+ value[value.length - 1];
	}

	/**
	 * Gets a masked email from a {string} value.
	 *
	 * @private
	 * @param {string} value
	 * @returns {string}
	 * @memberof LoginVerifyComponent
	 */
	private getMaskedEmail(
		value: string): string
	{
		const parts = value.split('@');
		const firstPart = parts[0];

		return firstPart[0]
			+ firstPart[1]
			+ '*****'
			+ firstPart[firstPart.length - 2]
			+ firstPart[firstPart.length - 1]
			+ '@'
			+ parts[1];
	}
}