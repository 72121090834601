/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';
import {
	FieldType
} from '@ngx-formly/core';

@Component({
	selector: 'custom-message',
	templateUrl: './custom-message.component.html',
	styleUrls: [
		'./custom-message.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Message.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomMessageComponent
 * @extends {FieldType}
 */
export class CustomMessageComponent
	extends FieldType
{
}