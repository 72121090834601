/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	HttpEvent,
	HttpEventType
} from '@angular/common/http';
import {
	FileProgressType
} from '@shared/constants/enums/file-progress-type.enum';
import {
	IFileProgress
} from '@shared/interfaces/files/file-progress.interface';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	Subscriber
} from 'rxjs';

/**
 * Send progress data to the subsriber that includes server responses.
 * This is required for real time reporting.
 */
export class FileProgressReporter
{
	/**
	 * Sends a progress report to the subscriber.
	 *
	 * @param {HttpEvent} event
	 * The http event from the http client request.
	 * @param {Subscriber<IFileProgress>} subscriber
	 * A subscriber to which the report should be sent.
	 * @param {string} message
	 * A message.
	 * @memberof FileProgressReporter
	 */
	public reportEvent(
		event: HttpEvent<IActionResponse>,
		subscriber: Subscriber<IFileProgress>,
		message?: string): void
	{
		const fileProgress: IFileProgress =
			<IFileProgress>
			{
				type: null,
				message: null,
				value: event
			};

		switch (event.type)
		{
			case HttpEventType.Sent:

				fileProgress.type =
					FileProgressType.UploadStarted;
				fileProgress.message = message ??
					'Upload Started';

				break;

			case HttpEventType.UploadProgress:

				fileProgress.type = FileProgressType
					.UploadProgress;

				fileProgress.message = message ??
					'Upload progress update';

				break;

			case HttpEventType.DownloadProgress:

				fileProgress.type = FileProgressType
					.DownloadProgress;

				fileProgress.message = message ??
					'Download progress update';

				break;

			case HttpEventType.ResponseHeader:

				fileProgress.type = FileProgressType
					.ResponseStatus;

				fileProgress.message = message ??
					`${event.status}:${event.statusText}`;

				break;

			case HttpEventType.Response:

				fileProgress.type = FileProgressType
					.Response;

				fileProgress.message = message ??
					'Response returned.';

				break;

			default:

				fileProgress.type = FileProgressType
					.Unhandled;

				fileProgress.message = message ??
					'Unhandled http event';

				break;
		}

		subscriber
			.next(fileProgress);
	}
}