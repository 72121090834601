/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Injectable
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';

/**
 * A singleton service representing the ability to interact with the Stonly
 * widget.
 *
 * @export
 * @class StonlyService
 */
@Injectable({
	providedIn: 'root'
})
export class StonlyService
{
	/**
	 * Gets the Stonly commands that can be executed.
	 *
	 * @type {{
	 * 	addEventListener: string;
	 * 	closeWidget: string;
	 * 	removeEventListener: string;
	 * }}
	 * @memberof StonlyService
	 */
	private readonly stonlyCommands: {
		addEventListener: string;
		closeWidget: string;
		removeEventListener: string;
	} = {
			addEventListener: 'addEventListener',
			closeWidget: 'closeWidget',
			removeEventListener: 'removeEventListener'
		};

	/**
	 * Gets the Stonly events that can be listened to.
	 *
	 * @type {{
	 * 	guidedTourStart: string;
	 * 	guidedTourEnd: string;
	 * }}
	 * @memberof StonlyService
	 */
	private readonly stonlyEvents: {
		guidedTourStart: string;
		guidedTourEnd: string;
	} = {
			guidedTourStart: 'guidedTourStart',
			guidedTourEnd: 'guidedTourEnd'
		};

	/**
	 * Gets the script for the stonly widget.
	 * See: https://stonly.com/kb/en/.
	 *
	 * @type {string}
	 * @memberof StonlyService
	 */
	private readonly stonlyWidgetScript: string =
		`!function (s, t, o, n, l, y, w, g, d, e)
		{
			s.StonlyWidget ||
				((d = s.StonlyWidget =
					function ()
					{
						d._api
							? d._api.apply(d, arguments)
							: d.queue.push(arguments);
					}).scriptPath = n,
				d.apiPath = l,
				d.sPath = y,
				d.queue = [],
				(g = t.createElement(o)).async = !0,
				(e = new XMLHttpRequest).open(
					'GET', n + 'version?v=' + Date.now(), !0),
				e.onreadystatechange = function ()
				{
					4 === e.readyState
						&& (g.src = n + 'stonly-widget.js?v='
							+(200 === e.status
								? e.responseText :
								Date.now()),
					(w = t.getElementsByTagName(o)[0])
						.parentNode.insertBefore(g, w));
				},
				e.send());
		}(window,
			document,
			'script',
			'https://stonly.com/js/widget/v2/');`;

	/**
	 * Installs the stonly widget javascript if not already existing.
	 *
	 * @param {string} widgetId
	 * The widget id to point the install to.
	 * @memberof StonlyService
	 */
	public installStonly(
		widgetId: string)
	{
		window.STONLY_WID = widgetId;

		const stonlyWidgetInterface: Function =
			window.StonlyWidget;

		if (!AnyHelper.isNull(stonlyWidgetInterface))
		{
			return;
		}

		const script: HTMLScriptElement =
			<HTMLScriptElement>document.createElement(
				AppConstants.documentElementTypes.script);
		script.type = AppConstants.scriptTypes.javascript;
		script.text = this.stonlyWidgetScript;
		document.head.appendChild(script);
	}

	/**
	 * Initializes the stonly widget with watchers for a guided tour start
	 * or end.
	 *
	 * @param {Function} eventAction
	 * The action to perform when either event is triggered. This is not
	 * required if only the default events are needed.
	 * @memberof StonlyService
	 */
	public initializeWidget(
		eventAction: Function = null): void
	{
		const mappedAction: Function =
			eventAction ?? (() => { });
		const stonlyWidgetInterface: Function =
			window.StonlyWidget;

		if (AnyHelper.isNull(stonlyWidgetInterface))
		{
			return;
		}

		stonlyWidgetInterface(
			this.stonlyCommands.addEventListener,
			this.stonlyEvents.guidedTourStart,
			() =>
			{
				mappedAction();
				EventHelper.dispatchWalkthroughStartEvent();
			});
		stonlyWidgetInterface(
			this.stonlyCommands.addEventListener,
			this.stonlyEvents.guidedTourEnd,
			() =>
			{
				mappedAction();
				EventHelper.dispatchWalkthroughEndEvent();
			});
	}

	/**
	 * Closes the stonly widget and removes all event listeners.
	 *
	 * @memberof StonlyService
	 */
	public closeWidget(): void
	{
		const stonlyWidgetInterface: Function =
			window.StonlyWidget;

		if (AnyHelper.isNull(stonlyWidgetInterface))
		{
			return;
		}

		stonlyWidgetInterface(
			this.stonlyCommands.removeEventListener,
			this.stonlyEvents.guidedTourStart);
		stonlyWidgetInterface(
			this.stonlyCommands.removeEventListener,
			this.stonlyEvents.guidedTourEnd);

		stonlyWidgetInterface(
			this.stonlyCommands.closeWidget);
	}
}