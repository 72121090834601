<span [innerHTML]="extendedDetail.description">
</span>

<ul *ngIf="extendedDetail.messages
	&& extendedDetail.messages.length > 0">
	<ng-template
		ngFor
		let-detail
		[ngForOf]="extendedDetail.messages">

		<li>
			<app-banner-detail
				[extendedDetail]="detail">
			</app-banner-detail>
		</li>
	</ng-template>
</ul>