/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	HttpClient,
	HttpXhrBackend
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	IAppConfig
} from '@shared/interfaces/app-config.interface';
import {
	lastValueFrom
} from 'rxjs';
import {
	environment
} from 'src/environments/environment';

@Injectable()
export class AppConfig
{
	/**
	 * Gets or sets the application config settings.
	 *
	 * @static
	 * @type {IAppConfig}
	 * @memberof AppConfig
	 */
	public static settings: IAppConfig;

	/**
	 * Loads the correct application config.
	 *
	 * @returns {Promise<void>}
	 * @memberof AppConfig
	 */
	public static async load(): Promise<IAppConfig>
	{
		const jsonFile
			= `assets/config/config.${environment.name}.json`;

		const http: HttpClient = new HttpClient(
			new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

		return lastValueFrom(http.get<IAppConfig>(jsonFile));
	}
}