/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable,
	Injector
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	ApiTokenLookup
} from '@api/api-token.lookup';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	RelatedContextMenuConfiguration
} from '@shared/interfaces/application-objects/related-context-menu-configuration';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	DateTime
} from 'luxon';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

/**
 * A class representing the action to gather and label a
 * set of related object based context menu links.
 *
 * @export
 * @class AddRelatedContextMenuAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class AddRelatedContextMenuAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a AddRelatedContextMenuAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {Router} router
	 * The router used for this action.
	 * @param {Injector} injector
	 * The injector for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof AddRelatedContextMenuAction
	 */
	public constructor(
		public activityService: ActivityService,
		public router: Router,
		public injector: Injector,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public operationName: string =
		'Add Related Context Menu';

	/**
	 * Gets or sets the api service.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public apiService: string = AppConstants.empty;

	/**
	 * Gets or sets the end point.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public endPoint: string = AppConstants.empty;

	/**
	 * Gets or sets the filter value.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public filter: string = AppConstants.empty;

	/**
	 * Gets or sets the order by value.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public orderBy: string = AppConstants.empty;

	/**
	 * Gets or sets the page context data name.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public pageContextDataName: string = AppConstants.empty;

	/**
	 * Gets or sets the associated entity type group.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public associatedEntityTypeGroup: string = AppConstants.empty;

	/**
	 * Gets or sets the translation api service.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public translationApiService: string = AppConstants.empty;

	/**
	 * Gets or sets the translation base navigation url.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public translationBaseNavigationUrl: string = AppConstants.empty;

	/**
	 * Gets or sets the translation label format.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public translationLabelFormat: string = AppConstants.empty;

	/**
	 * Gets or sets the translation label function.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public translationLabelFunction: string = AppConstants.empty;

	/**
	 * Gets or sets the translation fallback label.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public translationFallbackLabel: string = AppConstants.empty;

	/**
	 * Gets or sets the translation relationship key.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public translationRelationshipKey: string = AppConstants.empty;

	/**
	 * Gets or sets the route data to send on linked navigation actions.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public routeData: string = AppConstants.empty;

	/**
	 * Gets or sets the route data promise to run and calculate specific
	 * navigation actions.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public routeDataPromise: string = AppConstants.empty;

	/**
	 * Gets or sets the identifier used for this action to determine
	 * the query method for related items.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public readonly getChildrenMethodIdentifier: string = 'GetChildren';

	/**
	 * Gets or sets the identifier used for this action to determine
	 * the query method for a related child item.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public readonly getChildMethodIdentifier: string = 'GetChild';

	/**
	 * Gets or sets the identifier used for this action to determine
	 * the query method for related items.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public readonly getParentsMethodIdentifier: string = 'GetParents';

	/**
	 * Gets or sets the identifier used for this action to determine
	 * the query method for a related parent item.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public readonly getParentMethodIdentifier: string = 'GetParent';

	/**
	 * Gets or sets the identifier used for this action to determine
	 * the query method for a related non-entity item.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public readonly getSingleQueryResultMethodIdentifier: string =
		'getSingleQueryResult';

	/**
	 * Gets or sets the set of allowed endpoints that will return a single
	 * item result.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	public singularEndpoints: string[] =
		<string[]>
		[
			this.getChildMethodIdentifier,
			this.getParentMethodIdentifier,
			this.getSingleQueryResultMethodIdentifier
		];

	/**
	 * Gets the identifier in the source component for the
	 * entity type being displayed.
	 *
	 * @type {string}
	 * @memberof AddRelatedContextMenuAction
	 */
	private readonly entityTypeGroupPropertyIdentifier: string =
		'entityTypeGroup';

	/**
	 * Gets the limit to call when querying for related context menu items.
	 *
	 * @type {number}
	 * @memberof AddRelatedContextMenuAction
	 */
	private readonly relatedQueryLimit: number = 50;

	/**
	 * Gets the limit to call when querying for a related context menu item.
	 *
	 * @type {number}
	 * @memberof AddRelatedContextMenuAction
	 */
	private readonly relatedSingularQueryLimit: number = 1;

	/**
	 * Gets the calculated query limit value to call based on a singular
	 * or multiple value endpoint request.
	 *
	 * @type {number}
	 * @memberof AddRelatedContextMenuAction
	 */
	private get queryLimit(): number
	{
		return this.singularEndpoints.indexOf(this.endPoint) === -1
			? this.relatedQueryLimit
			: this.relatedSingularQueryLimit;
	}

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @returns {Promise<MenuItem[]>}
	 * The menu item array of mapped related objects or entities.
	 * @memberof AddRelatedContextMenuAction
	 */
	public async execute(): Promise<MenuItem[]>
	{
		if (this.pageContext == null)
		{
			return [];
		}

		const configurationOrderBy: string =
			AnyHelper.isNull(
				this.orderBy)
				? AppConstants.empty
				: this.orderBy;
		const configurationAssociatedEntityTypeGroup: string =
			AnyHelper.isNull(
				this.associatedEntityTypeGroup)
				? AppConstants.empty
				: this.associatedEntityTypeGroup;
		const configurationTranslationLabelFormat: string =
			AnyHelper.isNull(
				this.translationLabelFormat)
				? AppConstants.empty
				: this.translationLabelFormat;

		const configuration: RelatedContextMenuConfiguration =
			<RelatedContextMenuConfiguration>
			{
				apiService: this.apiService,
				endPoint: this.endPoint,
				filter: this.filter,
				orderBy: configurationOrderBy,
				pageContextDataName: this.pageContextDataName,
				routeData: this.routeData,
				routeDataPromise: this.routeDataPromise,
				associatedEntityTypeGroup:
					configurationAssociatedEntityTypeGroup,
				translationService:
					{
						apiService: this.translationApiService,
						baseNavigationUrl: this.translationBaseNavigationUrl,
						fallbackLabel: this.translationFallbackLabel,
						labelFormat: configurationTranslationLabelFormat,
						labelFunction: this.translationLabelFunction,
						relationshipKey: this.translationRelationshipKey
					}
			};

		this.endPoint = configuration.endPoint;

		const apiService: BaseEntityApiService<any> =
			this.injector.get<BaseEntityApiService<any>>(
				ApiTokenLookup.tokens[configuration.apiService]);

		const pageObject: any =
			this.pageContext.data[configuration.pageContextDataName];
		configuration.filter = this.replacePlaceholderText(
			configuration.filter);
		const formattedFilter: string =
			StringHelper.interpolate(
				configuration.filter,
				pageObject);

		let matches: any[];
		let useTranslationService: boolean = false;
		switch (configuration.endPoint)
		{
			case this.getChildrenMethodIdentifier:
			case this.getParentsMethodIdentifier:
			case this.getChildMethodIdentifier:
			case this.getParentMethodIdentifier:
			{
				matches = await this.getAllEntityFamilyMatches(
					pageObject,
					formattedFilter,
					configuration,
					this.pageContext.source[
						this.entityTypeGroupPropertyIdentifier],
					(<EntityInstanceApiService>apiService),
					0);

				break;
			}
			default:
			{
				matches = await this.getAllQueryMatches(
					formattedFilter,
					configuration,
					apiService,
					0);

				useTranslationService = true;

				break;
			}
		}

		return this.getRelatedItems(
			matches,
			configuration,
			useTranslationService);
	}

	/**
	 * Calls out to the get query of an api service and returns
	 * the full matching matching set of items.
	 *
	 * @async
	 * @param {string} formattedFilter
	 * The filter value to query with.
	 * @param {RelatedContextMenuConfiguration} configuration
	 * The configuration value for this related context action.
	 * @param {BaseEntityApiService<any>} apiService
	 * The api service holding a query endpoint.
	 * @param {number} offset
	 * The offset value to start gathering data from.
	 * @returns {Promise<any[]>}
	 * The menu item array of related entities or objects.
	 * @memberof AddRelatedContextMenuAction
	 */
	public async getAllQueryMatches(
		formattedFilter: string,
		configuration: RelatedContextMenuConfiguration,
		apiService: BaseEntityApiService<any>,
		offset: number): Promise<any[]>
	{
		let currentOffset: number = offset;

		let matches: any[] =
			await apiService.query(
				formattedFilter,
				configuration.orderBy,
				currentOffset,
				this.queryLimit);

		// Continually query until we receive less than the requested limit
		while (this.queryLimit !== this.relatedSingularQueryLimit
			&& matches.length === currentOffset + this.queryLimit)
		{
			matches =
				[
					...matches,
					...await apiService.query(
						formattedFilter,
						configuration.orderBy,
						currentOffset,
						currentOffset + this.queryLimit)
				];

			currentOffset = currentOffset + this.queryLimit;
		}

		return matches;
	}

	/**
	 * Calls out to the get children or parents endpoint of an entity
	 * instance api service and returns all of the family members with
	 * the sent specified type group.
	 *
	 * @async
	 * @param {any} pageObject
	 * The page context object to use to find related items of.
	 * @param {string} formattedFilter
	 * The filter value to query with.
	 * @param {RelatedContextMenuConfiguration} configuration
	 * The configuration value for this related context action.
	 * @param {string} entityTypeGroup
	 * The entity type group of the desired family set.
	 * @param {number} offset
	 * The offset value to start gathering data from.
	 * @param {EntityInstanceApiService} apiService
	 * The entity api service holding a get family endpoint.
	 * @returns {Promise<IEntityInstance[]>}
	 * The menu item array of related entities or objects.
	 * @memberof AddRelatedContextMenuAction
	 */
	public async getAllEntityFamilyMatches(
		pageObject: any,
		formattedFilter: string,
		configuration: RelatedContextMenuConfiguration,
		entityTypeGroup: string,
		apiService: EntityInstanceApiService,
		offset: number): Promise<IEntityInstance[]>
	{
		let currentOffset: number = offset;

		apiService.entityInstanceTypeGroup = entityTypeGroup;
		let matches: any[] = [];
		switch (this.endPoint)
		{
			case this.getChildMethodIdentifier:
			case this.getChildrenMethodIdentifier:
			{
				matches = await apiService
					.getChildren(
						pageObject.id,
						formattedFilter,
						configuration.orderBy,
						currentOffset,
						this.queryLimit,
						configuration.associatedEntityTypeGroup);
				break;
			}
			case this.getParentMethodIdentifier:
			case this.getParentsMethodIdentifier:
			{
				matches = await apiService
					.getParents(
						pageObject.id,
						formattedFilter,
						configuration.orderBy,
						currentOffset,
						this.queryLimit,
						configuration.associatedEntityTypeGroup);
				break;
			}
			default:
			{
				throw new Error(
					`Unsupported endpoint '${this.endPoint}' `
						+ 'for entity query.');
			}
		}

		// Continually query until we receive less than the requested limit
		while (this.queryLimit !== this.relatedSingularQueryLimit
			&& matches.length === currentOffset + this.queryLimit)
		{
			matches =
				[
					...matches,
					...await this.getAllEntityFamilyMatches(
						pageObject,
						formattedFilter,
						configuration,
						entityTypeGroup,
						apiService,
						currentOffset + this.queryLimit)
				];

			currentOffset = currentOffset + this.queryLimit;
		}

		return matches;
	}

	/**
	 * Gathers related item data and returns a menu item array for related
	 * items.
	 *
	 * @async
	 * @param {any[]} matches
	 * The set of related items to be mapped into menu items.
	 * @param {RelatedContextMenuConfiguration} configuration
	 * The configuration for this related context menu action.
	 * @param {boolean} useTranslationApiService
	 * If true, this will call out to the secondary api service to
	 * gather additional values needed for menu item mapping.
	 * @returns {Promise<MenuItem[]>}
	 * A menu item array holding the related items to the sent page context.
	 * @memberof AddRelatedContextMenuAction
	 */
	public async getRelatedItems(
		matches: any[],
		configuration: RelatedContextMenuConfiguration,
		useTranslationApiService: boolean): Promise<MenuItem[]>
	{
		const relatedItems: MenuItem[] = [];

		let translationApiService: BaseEntityApiService<any>;
		if (useTranslationApiService === true)
		{
			translationApiService =
				this.injector.get<BaseEntityApiService<any>>(
					ApiTokenLookup.tokens[
						configuration.translationService.apiService]);
		}

		return new Promise(async(resolve) =>
		{
			for (const match of matches)
			{
				const relatedItem: any =
					(useTranslationApiService === true)
						? await translationApiService.get(
							match[configuration.translationService
								.relationshipKey])
						: match;

				let label: string;

				if (!AnyHelper.isNullOrWhitespace(
					configuration.translationService.labelFunction))
				{
					const labelFunction: Function =
						StringHelper.transformToFunction(
							StringHelper.interpolate(
								configuration.translationService.labelFunction,
								relatedItem),
							{... this.pageContext, relatedItem: relatedItem});

					label = labelFunction();
				}
				else
				{
					label = StringHelper.interpolate(
						configuration.translationService.labelFormat,
						relatedItem);
				}

				relatedItems.push(
					<MenuItem>
					{
						label: (AnyHelper.isNullOrWhitespace(label)
							|| label.indexOf(AppConstants.undefined) !== -1)
							? StringHelper.interpolate(
								configuration.translationService.fallbackLabel,
								relatedItem)
							: label,
						icon: null,
						id: configuration.associatedEntityTypeGroup,
						items: null,
						command: async() =>
						{
							await this.routeDataCommand(
								configuration,
								relatedItem);
						}
					});
			}

			resolve(relatedItems);
		});
	}

	/**
	 * Executes the route data command related to the context menu action.
	 *
	 * @async
	 * @param {RelatedContextMenuConfiguration} configuration
	 * The configuration for this related context menu action.
	 * @param {any} relatedItem
	 * The related context menu item.
	 * @memberof AddRelatedContextMenuAction
	 */
	private async routeDataCommand(
		configuration: RelatedContextMenuConfiguration,
		relatedItem: any): Promise<void>
	{
		history.pushState(
			null,
			AppConstants.empty,
			this.router.url
		);

		const routeDataPromise =
			AnyHelper.isNullOrWhitespace(
				configuration.routeDataPromise)
				? null
				: await StringHelper
					.transformToDataPromise(
						StringHelper.interpolate(
							configuration.routeDataPromise,
							this.pageContext),
						this.pageContext);

		const routeData =
			AnyHelper.isNullOrWhitespace(
				configuration.routeData)
				? routeDataPromise
				: JSON.parse(
					StringHelper.interpolate(
						configuration.routeData,
						this.pageContext));

		const queryParams: any =
			AnyHelper.isNull(routeData)
				? {}
				: {
					routeData: ObjectHelper
						.mapRouteData(
							routeData)
				};

		this.router.navigate(
			[
				StringHelper.interpolate(
					configuration
						.translationService
						.baseNavigationUrl,
					relatedItem),
				relatedItem.id
			],
			{
				replaceUrl: true,
				queryParams: queryParams
			});
	}

	/**
	 * Handles commonly used query filter formatting for local site variables.
	 * These variables will be available in every related context menu filter
	 * defined in data storage.
	 *
	 * @param {string} queryFilter
	 * The filter from the configuration query used when replacing
	 * known placeholders.
	 * @returns {string}
	 * A clean query filter string with populated site data for a filter
	 * matching the db storage definition.
	 * @memberof AddRelatedContextMenuAction
	 */
	private replacePlaceholderText(
		queryFilter: string): string
	{
		if (AnyHelper.isNull(queryFilter))
		{
			return AppConstants.empty;
		}
		const utcCurrentDate: string =
			DateTime.local().toISO();

		const currentDatePlaceholder: RegExp =
			/[$]{currentDatePlaceholder}/g;

		return queryFilter.replace(
			currentDatePlaceholder,
			utcCurrentDate);
	}
}