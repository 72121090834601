/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ActivatedRouteSnapshot,
	BaseRouteReuseStrategy
} from '@angular/router';

/**
 * The custom route reuse strategy that will reload the route when
 * the same base urls is used. This is used for url parameter based components.
 *
 * @export
 * @class AppRouteReuseStrategy
 * @extends {BaseRouteReuseStrategy}
 */
export class AppRouteReuseStrategy
	extends BaseRouteReuseStrategy
{
	/**
	 * Implements the should reuse route interface.
	 * This method will ensure that same route navigation will cause
	 * a page reload when providing this custom reuse strategy.
	 *
	 * @param {ActivatedRouteSnapshot} future
	 * The future activated route snapshot.
	 * @param {ActivatedRouteSnapshot} current
	 * The current activated route snapshot.
	 * @returns {boolean}
	 * A value signifying whether or not the route change should reload
	 * the component.
	 * @memberof {AppRouteReuseStrategy}
	 */
	public shouldReuseRoute(
		future: ActivatedRouteSnapshot,
		current: ActivatedRouteSnapshot): boolean
	{
		return future.data.refreshComponent
			? false
			: future.routeConfig === current.routeConfig;
	}
}