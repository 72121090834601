/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

@Directive({
	selector: '[clickOutside]'
})

/**
 * A directive representing shared logic for the click outside event.
 * This directive can be attached to html elements to define if a click
 * outside of this element occurred.
 *
 * @export
 * @class ClickOutsideDirective
 */
export class ClickOutsideDirective
implements OnChanges
{
	/**
	* Initializes a new instance of the click outside directive.
	*
	* @param {ElementRef} elementRef
	* The html element that has this attached directive.
	* @memberof ClickOutsideDirective
	*/
	public constructor(
		public elementRef: ElementRef)
	{
	}

	/**
	 * Gets or sets the content displayed value, which can be
	 * sent if you want this click outside event to be conditional.
	 *
	 * @type {boolean}
	 * @memberof ClickOutsideDirective
	 */
	@Input() public contentDisplayed: boolean = true;

	/**
	 * Gets or sets a value defining whether or not this click outside
	 * should ignore formly panel based body containers.
	 *
	 * @type {boolean}
	 * @memberof ClickOutsideDirective
	 */
	@Input() public ignoreAppendedBodyPanelClicks: boolean = false;

	/**
	 * Gets or sets the click outside event that will be attached
	 * to html elements via (clickOutside)="componentMethod()".
	 *
	 * @type {EventEmitter<any>}
	 * @memberof ClickOutsideDirective
	 */
	@Output() public clickOutside: EventEmitter<any> =
		new EventEmitter();

	/**
	 * Gets the depth of classlists used to find if the clicked control
	 * is in an appended panel.
	 *
	 * @type {number}
	 * @memberof ClickOutsideDirective
	 */
	private readonly appendedPanelClickDepth: number = 5;

	/**
	 * Checks for click outside the element when
	 * the element is active, otherwise removes
	 * the window event listener.
	 *
	 * @type {EventListener}
	 * @memberof ClickOutsideDirective
	 */
	public onClick: EventListener =
		(clickEvent: any) =>
		{
			if (this.contentDisplayed === true)
			{
				const clickedInside =
					this.elementRef.nativeElement
						.contains(clickEvent.target);

				let panelClicked: boolean = false;
				if (!clickedInside
					&& this.ignoreAppendedBodyPanelClicks === true)
				{
					let includedClassLists: any[] = [];
					for (const includedPath of
						clickEvent.composedPath().slice(
							0,
							this.appendedPanelClickDepth))
					{
						includedClassLists =
							[
								...includedClassLists,
								...includedPath.classList || []
							];
					}

					panelClicked =
						includedClassLists.filter(
							(value: string) =>
								AppConstants.clickOutsidePanelControl
									.includes(value)).length > 0;
				}

				if (!panelClicked
					&& !clickedInside)
				{
					this.clickOutside.emit(clickEvent);
				}
			}
			else
			{
				window.removeEventListener(
					WindowEventConstants.click,
					this.onClick,
					true);
			}
		};

	/**
	 * Implements the on changes interface.
	 * Adds a listener for any click event
	 * on the window only if the content
	 * is displayed.
	 *
	 * @memberof ClickOutsideDirective
	 */
	public ngOnChanges(): void
	{
		if (this.contentDisplayed)
		{
			window.addEventListener(
				WindowEventConstants.click,
				this.onClick,
				true);
		}
	}
}
