/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Component
} from '@angular/core';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ClientMessage
} from '@shared/implementations/application-data/client-message';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

/**
 * A class containing static helper methods
 * for sending application events.
 *
 * @export
 * @class EventHelper
 */
export class EventHelper
{
	/**
	 * Dispatches a drawer add event and adds it to the specified target
	 * component.
	 *
	 * @static
	 * @param {Component | string} contentComponent
	 * The dynamic component to be displayed in this drawer.
	 * This can be either a string with the component name or the component
	 * class sent directly.
	 * @param {string} targetComponent
	 * The drawer display component to add this drawer to.
	 * @note 'UtilityMenuComponent' is the right side utility drawer display.
	 * @param {string} label
	 * The label to be displayed for this drawer.
	 * @param {string} icon
	 * The icon to be displayed for this drawer.
	 * @param {number} order
	 * The order to display this drawer.
	 * @param {boolean} startOpen
	 * If true, this will add the drawer and open it immediately in overlay
	 * mode.
	 * @param {boolean} disabled
	 * If true, this will add the drawer but disable the open drawer action.
	 * @param {string} disabledMessage
	 * If sent, this will be the message displayed in the drawer utility menu
	 * tooltip when disabled.
	 * @memberof EventHelper
	 */
	public static dispatchAddDrawerEvent(
		contentComponent: Component | string,
		targetComponent: string,
		label: string,
		icon: string,
		order: number,
		startOpen: boolean,
		disabled: boolean = false,
		disabledMessage: string = null,
		displayDrawerOverlay: boolean = false ,
		overlayClassName: string = null): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.addDrawer,
				{
					detail: {
						contentComponent: contentComponent,
						targetComponent: targetComponent,
						label: label,
						icon: icon,
						order: order,
						startOpen: startOpen,
						disabled: disabled,
						disabledMessage: disabledMessage,
						displayDrawerOverlay: displayDrawerOverlay,
						overlayClassName: overlayClassName
					}
				}
			));
	}

	/**
	 * Dispatches a utility title alter event.
	 *
	 * @static
	 * @param {string} utilityId
	 * The utility id to alter the title for.
	 * @param {string} title
	 * The new title to set for the utility.
	 * @memberof EventHelper
	 */
	public static dispatchAlterUtilityTitleEvent(
		utilityId: string,
		title: string): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.alterUtilityTitle,
				{
					detail: {
						utilityId: utilityId,
						title: title
					}
				}
			));
	}

	/**
	 * Dispatches a refresh component event that will notify the target
	 * component of the need to redraw the sent content component.
	 *
	 * @static
	 * @param {Component | string} contentComponent
	 * The dynamic component that needs to be refreshed.
	 * This can be either a string with the component name or the component
	 * class sent directly.
	 * @param {string} targetComponent
	 * The display component to be targetted that holds the content component.
	 * @memberof EventHelper
	 */
	public static dispatchRefreshComponentEvent(
		contentComponent: Component | string,
		targetComponent: string): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.refreshComponent,
				{
					detail: {
						contentComponent: contentComponent,
						targetComponent: targetComponent
					}
				}
			));
	}

	/**
	 * Dispatches a badge promise event that will notify the target
	 * component of the need to set a badge promise against the content
	 * component.
	 *
	 * @static
	 * @param {Component | string} contentComponent
	 * The dynamic component that needs to hold a badge promise.
	 * This can be either a string with the component name or the component
	 * class sent directly.
	 * @param {string} targetComponent
	 * The display component to be targetted that holds the content component.
	 * @param {string} badgePromise
	 * The badge promise to set against the target component.
	 * This will be the promise as a string that returns a badge
	 * item.
	 * @memberof EventHelper
	 */
	public static dispatchSetBadgePromiseEvent(
		contentComponent: Component | string,
		targetComponent: string,
		badgePromise: string): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.setBadgePromise,
				{
					detail: {
						contentComponent: contentComponent,
						targetComponent: targetComponent,
						badgePromise: badgePromise
					}
				}
			));
	}

	/**
	 * Dispatches a refresh badge promise event that will notify the target
	 * component of the need to recalculate the badge promise value for
	 * display.
	 *
	 * @static
	 * @param {Component | string} contentComponent
	 * The dynamic component that needs to have a badge refreshed.
	 * This can be either a string with the component name or the component
	 * class sent directly.
	 * @param {string} targetComponent
	 * The display component to be targetted that holds the content component.
	 * @memberof EventHelper
	 */
	public static dispatchRefreshBadgePromiseEvent(
		contentComponent: Component | string,
		targetComponent: string): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.refreshBadgePromise,
				{
					detail: {
						contentComponent: contentComponent,
						targetComponent: targetComponent
					}
				}
			));
	}

	/**
	 * Dispatches a refresh badge promise events that will notify the target
	 * component of the need to recalculate the badge promises value for
	 * display.
	 *
	 * @static
	 * @param {Component[] | string[]} contentComponents
	 * The dynamic components that needs to have a badge refreshed.
	 * This can be either an array of strings with the component name or
	 * the component classes sent directly.
	 * @param {string} targetComponent
	 * The display component to be targetted that holds the content component.
	 * @memberof EventHelper
	 */
	public static dispatchRefreshBadgePromiseEvents(
		contentComponents: Component[] | string[],
		targetComponent: string): void {
		contentComponents.forEach(
			component =>
			{
				window.dispatchEvent(
					new CustomEvent(
						AppEventConstants.refreshBadgePromise,
						{
							detail: {
								contentComponent: component,
								targetComponent: targetComponent
							}
						}
					));
			});
	}

	/**
	 * Dispatches a login message display event.
	 * @note This will fire on a half second timeout to allow the login
	 * screen time to display before displaying this message.
	 *
	 * @static
	 * @param {string} title
	 * The login primary or title message.
	 * @param {string} content
	 * The login message details.
	 * @param {string} status
	 * The banner status. Available options found in AppConstants.messageLevels.
	 * @memberof EventHelper
	 */
	 public static dispatchLoginMessageEvent(
		title: string,
		content: string,
		status: string): void
	{
		setTimeout(
			() =>
			{
				this.dispatchMessage(
					AppEventConstants.addLoginMessage,
					title,
					content,
					status);
			},
			AppConstants.time.halfSecond);
	}

	/**
	 * Dispatches a banner display event.
	 *
	 * @static
	 * @param {string} title
	 * The banner primary or title message (Html allowed).
	 * @param {string} content
	 * The banner content (Html allowed).
	 * @param {string} status
	 * The banner status. Available options found in
	 * AppConstants.activityStatus.
	 * @param {any} data
	 * The banner message data.
	 * @memberof EventHelper
	 */
	public static dispatchBannerEvent(
		title: string,
		content: string,
		status: string,
		data: any = null): void
	{
		this.dispatchMessage(
			AppEventConstants.addBanner,
			title,
			content,
			status,
			data);
	}

	/**
	 * Dispatches a full screen banner display event.
	 *
	 * @static
	 * @param {string} title
	 * The banner primary or title message (Html allowed).
	 * @param {string} content
	 * The banner content (Html allowed).
	 * @param {string} status
	 * The banner status. Available options found in
	 * AppConstants.activityStatus.
	 * @param {MenuItem[]} actions
	 * The banner message actions.
	 * @memberof EventHelper
	 */
	public static dispatchFullScreenBannerEvent(
		title: string,
		content: string,
		status: string,
		actions: MenuItem[] = []): void
	{
		this.dispatchMessage(
			AppEventConstants.addFullScreenBanner,
			title,
			content,
			status,
			null,
			actions);
	}

	/**
	 * Dispatches a context menu active event. This is used
	 * for site layout service calculations.
	 *
	 * @static
	 * @param {boolean} active
	 * The truthy defining if the context menu is displayed.
	 * @memberof EventHelper
	 */
	public static dispatchContextMenuActiveEvent(
		active: boolean): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.contextMenuActive,
				{
					detail: {
						active: active
					}
				}
			));
	}

	/**
	 * Dispatches a chat modal displayed event. This is used to determine
	 * if we need to add a mask over the modal IFrame minimize action and close
	 * the overlay drawer on minimize.
	 *
	 * @static
	 * @param {boolean} displayed
	 * The truthy defining if the chat modal is displayed.
	 * @memberof EventHelper
	 */
	public static dispatchChatModalDisplayedEvent(
		displayed: boolean): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.chatModalDisplayed,
				{
					detail: {
						displayed: displayed
					}
				}
			));
	}

	/**
	 * Dispatches a context overlay display event. This
	 * is the primary overlay.
	 *
	 * @static
	 * @param {boolean} overlay
	 * The truthy defining if the context overlay
	 * should be displayed.
	 * @memberof EventHelper
	 */
	public static dispatchContextOverlayEvent(
		overlay: boolean): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.displayContextOverlay,
				{
					detail: {
						overlay: overlay
					}
				}
			));
	}

	/**
	 * Dispatches a drawer overlay display event.
	 *
	 * @static
	 * @param {boolean} overlay
	 * The truthy defining if the drawer overlay
	 * should be displayed.
	 * @memberof EventHelper
	 */
	public static dispatchDrawerOverlayEvent(
		overlay: boolean): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.displayDrawerOverlay,
				{
					detail: {
						overlay: overlay
					}
				}
			));
	}

	/**
	 * Dispatches a hide associated menus event. This will
	 * ensure that relational overlays are able to close
	 * when a sibling is opened.
	 *
	 * @static
	 * @param {string} identifier
	 * The overlay identifier sending this event.
	 * @memberof EventHelper
	 */
	public static dispatchHideAssociatedMenusEvent(
		identifier: string): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.hideAssociatedMenus,
				{
					detail: {
						id: identifier
					}
				}));
	}

	/**
	 * Dispatches a redraw dashboard event.
	 *
	 * @static
	 * @memberof EventHelper
	 */
	public static dispatchRedrawDashboardEvent(): void
	{
		window.dispatchEvent(
			new Event(
				AppEventConstants.redrawDashboard));
	}

	/**
	 * Dispatches a reload product settings event.
	 *
	 * @static
	 * @memberof EventHelper
	 */
	public static dispatchReloadProductSettingsEvent(): void
	{
		window.dispatchEvent(
			new Event(
				AppEventConstants.reloadProductSettings));
	}

	/**
	 * Dispatches a shared repeater item altered event which is used to ensure
	 * shared repeaters are updated to accurately reflect the altered
	 * data.
	 *
	 * @static
	 * @param {string} repeaterKey
	 * The repeater's data location key.
	 * @param {string} repeaterFilter
	 * The repeaters data filter for shared repeaters.
	 * @param {number} itemIndex
	 * The item index removed from the repeater. If this value is not sent
	 * this event will be handled to update an on add event.
	 * @memberof EventHelper
	 */
	 public static dispatchRepeaterItemAlteredEvent(
		repeaterKey: string,
		repeaterFilter: string,
		repeaterAction: string,
		itemIndex: number = null): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.repeaterItemAltered,
				{
					detail: {
						repeaterKey: repeaterKey,
						repeaterFilter: repeaterFilter,
						itemIndex: itemIndex,
						repeaterAction: repeaterAction
					}
				}
			));
	}

	/**
	 * Dispatches a shared repeater clean model event which is used to ensure
	 * the data is in sync with the latest cleaned model.
	 *
	 * @static
	 * @memberof EventHelper
	 */
	public static dispatchRepeaterCleanModelEvent(): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.repeaterCleanModel));
	}

	/**
	 * Dispatches a set content context event which is captured at the
	 * application level and sent to listening children.
	 *
	 * @static
	 * @param {IDynamicComponentContext<Component, any>} contentContext
	 * The main site level content section context.
	 * @memberof EventHelper
	 */
	public static dispatchSetContentContextEvent(
		contentContext: IDynamicComponentContext<Component, any>): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.setContentContext,
				{
					detail: {
						contentContext: contentContext
					}
				}
			));
	}

	/**
	 * Dispatches a set utility menu event which is captured at
	 * the utility menu level.
	 *
	 * @static
	 * @param {string} utilityMenuOperationGroupName
	 * The utlity menu to display.
	 * @param {string | Component} activeDrawerComponent
	 * If sent, this will be the name or component to show as active when
	 * the utility menu is loaded.
	 * @param {string} activeDrawerItemDescription
	 * If sent, this will be the unique string of the item in the utility menu
	 * component to show as active when the active drawer is opened.
	 * @memberof EventHelper
	 */
	public static dispatchSetUtilityMenuEvent(
		utilityMenuOperationGroupName: string,
		activeDrawerComponent: string | Component = AppConstants.empty,
		activeDrawerItemDescription: string = AppConstants.empty): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.setUtilityMenu,
				{
					detail: {
						utilityMenuOperationGroupName:
							utilityMenuOperationGroupName,
						activeDrawerComponent:
							activeDrawerComponent,
						activeDrawerItemDescription:
							activeDrawerItemDescription
					}
				}
			));
	}

	/**
	 * Dispatches a site layout change event which notifies listening
	 * components of changes in the site layout service values.
	 *
	 * @static
	 * @memberof EventHelper
	 */
	public static dispatchSiteLayoutChangedEvent(): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.siteLayoutChanged));
	}

	/**
	 * Dispatches an event that signals to all listeners that a rule has been
	 * overrode.
	 *
	 * @static
	 * @memberof EventHelper
	 */
	public static ruleOverrideEvent(): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.ruleOverride));
	}

	/**
	 * refreshes components.
	 *
	 * @param {string} component
	 * the component name.
	 *  @param {string} targetType
	 * @memberof EventHelper
	 */
	public static refreshComponents(
		components: string[],
		targetType: string)
	{
		components.forEach(
			(componenent: string) => {
				this.dispatchRefreshComponentEvent(
					componenent,
					targetType
				);
			});
	}

	/**
	 * Navigates to the access denied page. This page should be accessed
	 * in cases where the view is not available to the user due to
	 * security or ownership level permissions.
	 *
	 * @param {string} clientMessage
	 * The details to display as additional information in content.
	 * @memberof DisplayComponentParameterDirective
	 */
	public static dispatchNavigateToAccessDeniedEvent(
		route: string,
		resources: string[],
		clientMessage: string = AppConstants.empty): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.navigateToAccessDenied,
				{
					detail: {
						route: route,
						resources: resources,
						clientMessage: clientMessage
					}
				}
			));
	}

	/**
	 * Dispatches a walkthrough start event.
	 *
	 * @static
	 * @memberof EventHelper
	 */
	public static dispatchWalkthroughStartEvent(): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.walkthroughStart));
	}

	/**
	 * Dispatches a walkthrough end event.
	 *
	 * @static
	 * @memberof EventHelper
	 */
	public static dispatchWalkthroughEndEvent(): void
	{
		window.dispatchEvent(
			new CustomEvent(
				AppEventConstants.walkthroughEnd));
	}

	/**
	 * Dispatches a message event of the sent event type.
	 *
	 * @static
	 * @param {string} event
	 * The event type to send the message to.
	 * @param {string} title
	 * The primary or title message (Html allowed).
	 * @param {string} content
	 * The message content (Html allowed).
	 * @param {string} status
	 * The message status. Available options found in
	 * AppConstants.activityStatus for banners or AppConstants.messageLevel for
	 * the login message handler.
	 * @param {any} data
	 * The message data value if applicable.
	 * @param {MenuItem[]} actions
	 * The banner message actions.
	 * @memberof EventHelper
	 */
	 private static dispatchMessage(
		event: string,
		title: string,
		content: string,
		status: string,
		data: any = null,
		actions: MenuItem[] = []): void
	{
		const clientMessage: ClientMessage =
			new ClientMessage();
		clientMessage.content = content;
		clientMessage.data = data;
		clientMessage.status = status;
		clientMessage.title = title;
		clientMessage.actions = actions;

		window.dispatchEvent(
			new CustomEvent(
				event,
				{
					detail: {
						clientMessage: clientMessage
					}
				}
			));
	}
}