/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

@Component({
	selector: 'adjustment-write-off-comments',
	templateUrl: './adjustment-write-off-comments.component.html'
})

/**
 * A component representing a wizard step to select a note for
 * a term based adjustment write off.
 *
 * @export
 * @class AdjustmentWriteOffCommentsComponent
 * @extends {StatusReasonsDirective}
 */
export class AdjustmentWriteOffCommentsComponent
	extends StatusReasonsDirective
{
	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof AdjustmentWriteOffCommentsComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[] =
		<FormlyFieldConfig[]>
		[
			{
				key: 'data.comments',
				type: FormlyConstants.customControls.customTextArea,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				props: {
					label: 'Comments',
					required: true,
					rows: FormlyConstants.textAreaRowSizes.small
				}
			}
		];

	/**
	 * Gets the workflow action being executed to update the PolicyTerm
	 * data.
	 *
	 * @type {string}
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	public readonly workflowActionName: string =
		'WriteOffCreateLedgerTransaction';

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @async
	 * @memberof AdjustmentWriteOffCommentsComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		this.context.source.addOrUpdateStepData(
			{
				comments: currentData.comments
					|| AppConstants.empty
			});

		this.context.source.addToNext(this.writeOff.bind(this));

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * This will send the write off event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof AdjustmentWriteOffCommentsComponent
	 */
	public async writeOff(): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const queryString: string =
			`?writeOffType=${currentData.type}`
				+ `&amount=${currentData.amount}`
				+ `&comments=${currentData.comments}`;

		await this.writeOffExecution(
			this.policyTermEntityInstance,
			queryString);

		this.navigateToPolicyTermDashboard(
			this.policyTermEntityInstance.id);
	}

	/**
	 * Executes the make payment process, which executes the MakePayment
	 * workflow service with the collected data from the make payment wizard,
	 * then creates the payment information.
	 *
	 * @async
	 * @param {IEntityInstance} policyTermInstanceData
	 * The PolicyTerm entity instance data to be updated.
	 * @param {string} queryString
	 * The extra parameters sent as query string parameters.
	 * @memberof AdjustmentWriteOffCommentsComponent
	 */
	private async writeOffExecution(
		policyTermInstanceData: IEntityInstance,
		queryString: string): Promise<void>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		await this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, _reject: any) =>
				{
					this.entityInstanceApiService.entityInstanceTypeGroup =
						InsuranceConstants.insuranceEntityTypeGroups
							.policyTerms;

					await this.entityInstanceApiService
						.executeAction(
							policyTermInstanceData.id,
							this.workflowActionName,
							policyTermInstanceData,
							queryString);

					resolve();
				}),
				'<strong>Processing write off</strong>',
				'<strong>Write off processed</strong>',
				'A write off has been applied to the policy.',
				'A write off has not been applied to the policy.'));
	}

	/**
	 * This will navigate to the policy term dashboard using the policy term id
	 * provided.
	 *
	 * @param {number} entityId
	 * The policy term entity id to navigate to.
	 * @memberof AdjustmentWriteOffCommentsComponent
	 */
	private navigateToPolicyTermDashboard(
		entityId: number): void
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}