/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	isArray
} from 'lodash-es';

/**
 * A class containing static helper methods
 * for the any type.
 *
 * @export
 * @class AnyHelper
 */
export class AnyHelper
{
	/**
	 * Checks if the specified item is null, undefined, or an empty string
	 *  after a trim.
	 *
	 * @static
	 * @param {any} item
	 * The item to check if null, undefined, or an empty string after a trim.
	 * @returns {boolean}
	 * A value indicating whether the specified item is null, undefined, or an
	 * empty string after performing a trim.
	 * @memberof AnyHelper
	 */
	public static isNullOrWhitespace(
		item: any): boolean
	{
		if (this.isNull(item)
			|| (typeof(item) === AppConstants.variableTypes.string
				&& item.replace(/\s/g, '') === AppConstants.empty))
		{
			return true;
		}

		return false;
	}

	/**
	 * Checks if the specified item is null, undefined or empty.
	 *
	 * @static
	 * @param {any} item
	 * The item to check if null, undefined or empty.
	 * @returns {boolean}
	 * A value indicating whether the specified item is null, undefined or
	 * empty.
	 * @memberof AnyHelper
	 */
	public static isNullOrEmpty(
		item: any): boolean
	{
		if (this.isNull(item)
			|| item === AppConstants.empty)
		{
			return true;
		}

		return false;
	}

	/**
	 * Checks if the specified item is null or undefined.
	 *
	 * @static
	 * @param {any} item
	 * The item to check if null or undefined.
	 * @returns {boolean}
	 * A value indicating whether the specified item is null or undefined.
	 * @memberof AnyHelper
	 */
	public static isNull(
		item: any): boolean
	{
		if (item == null
			|| item === AppConstants.undefined
			|| item === 'null')
		{
			return true;
		}

		return false;
	}

	/**
	 * Checks if the specified item is null or empty array.
	 *
	 * @static
	 * @param {any} item
	 * The item to check.
	 * @returns {boolean}
	 * A value indicating whether the specified item is an empty array or null.
	 * @memberof AnyHelper
	 */
	public static isNullOrEmptyArray(
		item: any): boolean
	{
		return this.isNull(item)
			|| (isArray(item) && item.length === 0);
	}

	/**
	 * Determines whether the supplied object is of type function.
	 *
	 * @static
	 * @param {object} object
	 * An object to check if its type is function.
	 * @returns {boolean}
	 * A value indicating whether the object is a function.
	 * @memberof AnyHelper
	 */
	public static isFunction(
		object: object): boolean
	{
		if (typeof object === 'function')
		{
			return true;
		}

		return false;
	}

	/**
	 * Determines whether the supplied object is of type string.
	 *
	 * @static
	 * @param {any} object
	 * An object to check if its type is string.
	 * @returns {boolean}
	 * A value indicating whether the object is a string.
	 * @memberof AnyHelper
	 */
	public static isString(
		object: any): boolean
	{
		if (typeof object === AppConstants.variableTypes.string)
		{
			return true;
		}

		return false;
	}

	/**
	 * Checks if the specified item is null, undefined, or an empty string
	 * after a trim and returns an empty string if true.
	 *
	 * @static
	 * @param {any} item
	 * The item to check if null, undefined, or an empty string after a trim.
	 * @returns {string}
	 * An empty string value if the specified item is null, undefined, or an
	 * empty string after performing a trim, otherwise returns the original
	 * item.
	 * @memberof AnyHelper
	 */
	public static nullOrWhitespaceToEmptyString(
		item: any): string
	{
		if (this.isNullOrWhitespace(item))
		{
			return AppConstants.empty;
		}

		return item;
	}
}