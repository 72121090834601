<div class="ui-g-12 no-padding text-center">
	<ng-template
		[ngIf]="loading"
		[ngIfElse]="CommonList">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</ng-template>

	<ng-template #CommonList>
		<ng-template
			[ngIf]="allowedUserAccess === true"
			[ngIfElse]="NoAccess">
			<app-dynamic-component
				[context]="commonListContext"
				displayComponent="CommonListComponent">
			</app-dynamic-component>
		</ng-template>

		<ng-template #NoAccess>
			<div class="no-access-content">
				<app-access-denied-content
					[drawerDisplay]="true"
					drawerName="Product Settings"
					[sessionIdentifier]="sessionIdentifier"
					[accessDeniedUrl]="accessDeniedUrl"
					[resources]="resources"
					[clientMessage]="clientMessage">
				</app-access-denied-content>
			</div>
		</ng-template>

	</ng-template>
</div>