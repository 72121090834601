<ng-template [ngIf]="clientMessage?.display === true">

	<div class="full-screen-banner-container"
		[ngClass]="{
			'info-shadow': (clientMessage.status === 'Info'),
			'success-shadow': (clientMessage.status === 'Complete'),
			'danger-shadow': (clientMessage.status === 'Error')
		}"
		(click)="performBannerActionAndClose($event)">
		<p-card>
			<div class="ui-g full-screen-banner no-vertical-padding"
				pButton
				[ngClass]="{
					'p-button-primary': (clientMessage.status === 'Info'),
					'p-button-success': (clientMessage.status === 'Complete'),
					'p-button-danger': (clientMessage.status === 'Error')
				}">
				<div class="ui-g-11 banner-messages">
					<div class="title-container">
						<span class="banner-title"
							[innerHTML]="clientMessage.safeTitle">
						</span>
					</div>
					<div class="content-container">
						<span class="banner-content"
							[innerHTML]="clientMessage.safeContent">
						</span>
					</div>
				</div>
				<div class="ui-g-1 no-padding dismiss-container">
					<div class="dismiss-icon">
					</div>
				</div>
			</div>
		</p-card>
	</div>

</ng-template>