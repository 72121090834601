/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Location
} from '@angular/common';
import {
	Component
} from '@angular/core';
import {
	ISecurityDefinitionDto
} from '@api/interfaces/security/security-definition.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SecurityRightCategory
} from '@shared/constants/enums/security-right-category.enum';
import {
	ProductSettingDirective
} from '@shared/directives/product-setting.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	IProductSetting
} from '@shared/interfaces/application-objects/product-setting.interface';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'app-product-setting-list',
	templateUrl: './product-setting-list.component.html',
	styleUrls: [
		'./product-setting-list.component.scss'
	]
})

/**
 * A component representing context level product settings list view.
 *
 * @export
 * @class ProductSettingListComponent
 * @extends {ProductSettingDirective}
 * @implements {IDynamicComponent<Component, any>}
 * @implements {IOwnershipGuardComponent}
 */
export class ProductSettingListComponent
	extends ProductSettingDirective
	implements IDynamicComponent<Component, any>, IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the product settings list component.
	 *
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @memberof ProductSettingListComponent
	 */
	public constructor(
		public sessionService: SessionService,
		public location: Location,
		public entityInstanceApiService: EntityInstanceApiService)
	{
		super();
	}

	/**
	 * Gets or sets the product setting filter.
	 *
	 * @type {ICommonListSort[]}
	 * @memberof ProductSettingListComponent
	 */
	public productSettingFilter: ICommonListFilter[] = [];

	/**
	 * Gets or sets the allowed user access.
	 *
	 * @type {boolean}
	 * @memberof ProductSettingListComponent
	 */
	public allowedUserAccess: boolean = false;

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof ProductSettingListComponent
	 */
	public sessionIdentifier: string = AppConstants.empty;

	/**
	 * Gets or sets the access denied url.
	 *
	 * @type {string}
	 * @memberof ProductSettingListComponent
	 */
	public accessDeniedUrl: string = AppConstants.empty;

	/**
	 * Gets or sets list of required resources.
	 *
	 * @type {string[]}
	 * @memberof ProductSettingListComponent
	 */
	public resources: string[] =
		[
			'Security.AccessPolicyDefinition'
		];

	/**
	 * Gets or sets the client message if insufficient resources exist.
	 *
	 * @type {string}
	 * @memberof ProductSettingListComponent
	 */
	public clientMessage: string =
		'Agency product settings access is required.';

	/**
	 * Gets or sets the product settings path.
	 *
	 * @type {string}
	 * @memberof ProductSettingListComponent
	 */
	private readonly productSettingsPath: string =
		'$.data.productSettings';

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @memberof ProductSettingListComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			this.accessDeniedUrl = this.location.path();
			this.sessionIdentifier = this.sessionService.sessionId;
			this.loading = false;

			return;
		}

		this.itemActions = SecurityHelper
			.scrubMenuItems(
				this.itemActions,
				InsuranceConstants.insuranceEntityTypes.product,
				this.securityDefinitions);

		this.refreshCommonListContext(
			this.loadProductSettings(this.productSettingFilter),
			this);
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate drawer ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this drawer.
	 * @memberof ProductSettingListComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			entityInstanceComponent.entityTypeGroup;

		const userEffectivePermissions: ISecurityDefinitionDto =
			await this.entityInstanceApiService
				.getPermissions(entityInstanceComponent.entityInstance.id);

		this.allowedUserAccess = SecurityHelper
			.getDataSecurityRights(
				this.productSettingsPath,
				userEffectivePermissions.data)
			.rights
			.update;

		return this.allowedUserAccess;
	}

	/**
	 * A method to generate the proper data promise for loading notes data.
	 *
	 * @async
	 * @param {ICommonListFilter[]} [activeFilters]
	 * A value representing the enabled filters to apply to the data promise
	 * query.
	 * @returns {Promise<IEntityInstanceDto[]>}
	 * A data promise with applied filters and sorter.
	 * @memberof ProductSettingListComponent
	 */
	 public async loadProductSettings(
		activeFilters: ICommonListFilter[]): Promise<any>
	{
		let productSettings: IProductSetting[] = [];
		if (this.context.source instanceof EntityInstanceComponent)
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				this.context.source.entityTypeGroup;
			const entityInstance =
				await this.entityInstanceApiService
					.get(this.context.source.entityInstance.id);

			productSettings =
				await this.filterProductSettings(
					[
						...entityInstance.data.productSettings
					],
					activeFilters);
		}

		DocumentHelper.setElementDisplay(
			`${AppConstants.cssClasses.pinnedDrawerOverlay}`
				+ `${AppConstants.cssClasses.productSettingsOverlay}`,
			false);

		return productSettings;
	}

	/**
	 * Generates a common list context that can be consumed by a common list
	 * displayed in this component.
	 *
	 * @param {ICommonListItem<IEntityInstance>[]} data
	 * The collection of data that will be displayed in this list.
	 * @returns {ICommonListContext<IEntityInstance>}
	 * The common list context to display.
	 * @memberof ProductSettingListComponent
	 */
	public generateCommonListContext(
		data: ICommonListItem<IEntityInstance>[]):
		ICommonListContext<IEntityInstance>
	{
		const listFilters: ICommonListFilter[] =
			<ICommonListFilter[]>
			[
				{
					name: this.productAuthorityTypes.active,
					value: this.productAuthorityTypes.active
				},
				{
					name: this.productAuthorityTypes.inactive,
					value: this.productAuthorityTypes.inactive
				},
				{
					name: this.productAuthorityTypes.serviceExisting,
					value: this.productAuthorityTypes.serviceExisting
				}
			];

		const scrubbedActions: ISecureMenuItem[] =
			SecurityHelper
				.scrubMenuItems(
					[
						<ISecureMenuItem>
						{
							icon: 'fa fa-plus-circle',
							securityRightCategory: SecurityRightCategory.Action,
							securityRightPath: AppConstants.apiMethods.create,
							command: (event: any) =>
							{
								this.changeDisplayMode.emit(
									AppConstants.displayMode.create);

								event.stopPropagation();
							}
						}
					],
					InsuranceConstants.insuranceEntityTypes.product,
					this.securityDefinitions);

		const generatedCommonListContext:
			ICommonListContext<IEntityInstance> =
				<ICommonListContext<IEntityInstance>>
				{
					data: data,
					supportMarkdown: true,
					searchable: true,
					sortable: false,
					searchFilterFormat: '${inputValue}',
					actions: scrubbedActions,
					filters: listFilters,
					onFilterChange: (
						source: any,
						filters: ICommonListFilter[]) =>
						this.handleFilterChange(source, filters)
				};

		return generatedCommonListContext;
	}

	/**
	 * Filters the Product Settings.
	 *
	 * @param {IProductSetting[]} productSettings
	 * The entity instance product settings.
	 * @param {ICommonListFilter[]} activeFilters
	 * The active filters.
	 * @returns {Promise<IProductSetting[]>}
	 * The filtered product settings.
	 * @memberof ProductSettingListComponent
	 */
	public async filterProductSettings(
		productSettings: IProductSetting[],
		activeFilters: ICommonListFilter[]): Promise<IProductSetting[]>
	{
		for (let productSettingIndex = 0;
			productSettingIndex < productSettings.length;
			productSettingIndex ++)
		{
			let productInstance: IEntityInstance = null;
			const productSetting: any =
				productSettings[productSettingIndex];
			try
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
					InsuranceConstants.insuranceEntityTypeGroups.products;
				productInstance =
					await this.entityInstanceApiService
						.getSingleQueryResult(
							`name eq "${productSetting.name}"`,
							AppConstants.empty);
			}
			catch (exception)
			{
				productInstance = null;
			}
			finally
			{
				if (!AnyHelper.isNull(productInstance))
				{
					productSetting.itemIndex = productSettingIndex;
					productSetting.productInstance =
						productInstance;
					productSetting.state =
						productInstance?.data?.state
							|| AppConstants.empty;
					productSetting.insuranceType =
						productInstance?.data?.insuranceType
							|| AppConstants.empty;
					productSetting.informationIcon =
						this.getInformationIcon(productSetting.authority);
				}
			}
		}

		const validProductSettings: IProductSetting[] =
			productSettings.filter((productSetting) =>
				(!AnyHelper.isNull((<any>productSetting).productInstance)));

		return activeFilters.length > 0
			? validProductSettings.filter((productSetting: any) =>
			{
				let insideFilterCriteria: boolean = true;
				activeFilters.forEach((activeFilter) =>
				{
					const filterValue: string =
						activeFilter.value.toLowerCase();

					if (filterValue !== productSetting.authority.toLowerCase()
						&& filterValue !== productSetting.name.toLowerCase()
						&& filterValue !== productSetting.state.toLowerCase()
						&& filterValue !== productSetting.productInstance
							.data.insuranceType.toLowerCase())
					{
						insideFilterCriteria = false;
					}
				});

				return insideFilterCriteria;
			})
			: validProductSettings;
	}

	/**
	 * Gets the information icon.
	 *
	 * @async
	 * @param {string} [authority]
	 * The level of authority.
	 * @returns {string[]}
	 * The information icons
	 * @memberof ProductSettingListComponent
	 */
	public getInformationIcon(authority: string): string[]
	{
		const informationIcon: string[] = [];

		switch (authority)
		{
			case this.productAuthorityTypes.active:
				informationIcon.push(
					'fa fa-check theme-color-green');
				break;

			case this.productAuthorityTypes.inactive:
				informationIcon.push(
					'fa fa-exclamation theme-color-red');
				break;

			case this.productAuthorityTypes.serviceExisting:
				informationIcon.push(
					'fa fa-exclamation theme-color-dark-orange');
				break;

			default:
				informationIcon.push(null);
		}

		return informationIcon;
	}

	/**
	 * Maps and returns a list item that represents
	 * a product setting entity instance.
	 *
	 * @param {any} productSetting
	 * The product settings that exist on an instance.
	 * @returns {ICommonListItem<IEntityInstance>}
	 * The common list item to display that represents this entity instance.
	 * @memberof ProductSettingListComponent
	 */
	public mapToListItem(
		productSetting: any):
		ICommonListItem<any>
	{
		return <ICommonListItem<any>> {
			item: productSetting,
			descriptionLineFormat:
				`**${productSetting.name}** - `
					+ `${productSetting.authority}`,
			informationLineFormat:
				`${productSetting.state} `
					+ `${productSetting.insuranceType}`,
			informationIcons: productSetting.informationIcon,
			actions: this.itemActions
		};
	}

	/**
	 * A method that handles any filter change and refreshes data.
	 *
	 * @param {any} source
	 * A value representing the source component triggering the sort change
	 * event.
	 * @param {ICommonListFilter[]} filters
	 * A collection of enabled and active filters.
	 * @memberof ProductSettingListComponent
	 */
	public handleFilterChange(
		source: any,
		filters: ICommonListFilter[]): void
	{
		this.enabledFilters = filters;
		this.refreshCommonListContext(
			this.loadProductSettings(
				this.enabledFilters),
			source);
	}
}
