/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Component,
	Directive
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';

/* eslint-enable max-len */

@Directive({
	selector: '[DrawerListDirective]'
})

/**
 * A class representing the common code for a list displayed in a base
 * drawer.
 *
 * @export
 * @class DrawerListDirective
 * @typeparam {TEntity} The generic type that will be displayed in this
 * drawer list.
 */
export class DrawerListDirective<TEntity>
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof DrawerListDirective
	 */
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * An object containing the possible display modes for the notes component.
	 *
	 * @type {{
	 * 		view: string;
	 * 		create: string;
	 * 		update: string;
	 * 		delete: string;
	 * 		list: string;
	 * 		keyDates: string;
	 * 		timeline: string;
	 * 		secondaryList: string;
	 * 		secondaryView: string;
	 * 	}}
	 * @memberof DrawerListDirective
	 */
	public readonly displayModes: {
		view: string;
		create: string;
		update: string;
		delete: string;
		list: string;
		keyDates:  string;
		timeline: string;
		secondaryList: string;
		secondaryView: string;
	} = {
			view: AppConstants.displayMode.view,
			create: AppConstants.displayMode.create,
			update: AppConstants.displayMode.update,
			delete: AppConstants.displayMode.delete,
			list: AppConstants.displayMode.list,
			keyDates: AppConstants.displayMode.keyDates,
			timeline: AppConstants.displayMode.timeline,
			secondaryList: AppConstants.displayMode.secondaryList,
			secondaryView: AppConstants.displayMode.secondaryView
		};

	/**
	 * Gets or sets a set of item level actions that are used in this drawer
	 * list.
	 *
	 * @type {MenuItem[]}
	 * @memberof DrawerListDirective
	 */
	public itemActions: ISecureMenuItem[] = [];

	/**
	 * Gets or sets a value representing the active view mode.
	 *
	 * @type {string}
	 * @memberof DrawerListDirective
	 */
	public displayMode: string = this.displayModes.list;

	/**
	 * Gets or sets a value representing a selected item.
	 *
	 * @type {TEntity}
	 * @memberof DrawerListDirective
	 */
	public selectedItem: TEntity = null;

	/**
	 * Gets or sets a value representing the display name of this list item.
	 *
	 * @type {string}
	 * @memberof DrawerListDirective
	 */
	public entityDisplayName: string = AppConstants.empty;

	/**
	 * Gets or sets a value representing the wildcard child filter which is
	 * used to find allowed child types that can be created in this list.
	 *
	 * @type {string}
	 * @memberof DrawerListDirective
	 */
	public wildcardChildFilter: string = AppConstants.empty;

	/**
	 * Gets or sets a value representing an initial create set of data that will
	 * be set into a newly created instance made from this drawer list.
	 *
	 * @type {any}
	 * @memberof DrawerListDirective
	 */
	public initialCreateData: any = {};

	/**
	 * Gets or sets list of required resources.
	 *
	 * @type {boolean}
	 * @memberof DrawerListDirective
	 */
	public isOwnershipAllowed: boolean = true;

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof DrawerListDirective
	 */
	public sessionIdentifier: string = AppConstants.empty;

	/**
	 * Gets or sets the access denied url.
	 *
	 * @type {string}
	 * @memberof DrawerListDirective
	 */
	public accessDeniedUrl: string = AppConstants.empty;

	/**
	 * Gets or sets list of required resources.
	 *
	 * @type {string[]}
	 * @memberof DrawerListDirective
	 */
	public resources: string[] = [];

	/**
	 * Gets or sets the client message if insufficient resources exist.
	 *
	 * @type {string}
	 * @memberof DrawerListDirective
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the security definitions.
	 *
	 * @type {ISecurityEntityTypeDefinition[]}
	 * @memberof DrawerListDirective
	 */
	public securityDefinitions: ISecurityEntityTypeDefinition[];

	/**
	 * Gets a value indicating whether the view is valid.
	 *
	 * @returns {boolean}
	 * A boolean value representing if the view is valid.
	 * @memberof DrawerListDirective
	 */
	public isValid(): boolean
	{
		return !AnyHelper.isNull(this.context?.source)
			&& (this.context.source instanceof EntityInstanceComponent);
	}

	/**
	 * Gets a value indicating whether the display mode is available.
	 *
	 * @returns {boolean}
	 * A boolean value representing if the display mode is available.
	 * @memberof DrawerListDirective
	 */
	public isDisplayModeAvailable(): boolean
	{
		return Object.keys(this.displayModes)
			.map(
				(mode: string) =>
					mode.toLocaleLowerCase())
			.includes(
				this.displayMode.toLocaleLowerCase());
	}

	/**
	 * Changes the display mode to the requested mode.
	 *
	 * @param {string} mode
	 * A string representing the display mode to change to.
	 * @memberof DrawerListDirective
	 */
	public changeDisplayMode(
		mode: string): void
	{
		this.displayMode = mode;
	}

	/**
	 * Changes the selected item value to the requested item.
	 *
	 * @param {TEntity} item
	 * A item to changed the selected item value to.
	 * @memberof DrawerListDirective
	 */
	public changeSelectedItem(
		item: TEntity): void
	{
		this.selectedItem = item;
	}

	/**
	 * Gets a collection of strings representing note types filtered from the
	 * list of input types.
	 *
	 * @protected
	 * @param {number} parentId
	 * The parent id of the note.
	 * @param {string} parentTypeGroup
	 * the parent type group.
	 * @param {EntityDefinition} parentEntityDefinition
	 * The parent Entity Definition.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * the entity instance API service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The Entity Type API service.
	 * @returns {Promise<void>}
	 * A collection of suppported and authorized entity types notes.
	 * @memberof DrawerListDirective
	 */
	protected async setSecurityDefinitions(
		parentId: number,
		parentTypeGroup: string,
		parentEntityDefinition: EntityDefinition,
		entityInstanceApiService: EntityInstanceApiService,
		entityTypeApiService: EntityTypeApiService):
		Promise<void>
	{
		this.securityDefinitions =
			await SecurityHelper
				.getSupportedChildPermissions(
					parentId,
					parentTypeGroup,
					this.wildcardChildFilter,
					parentEntityDefinition,
					entityInstanceApiService,
					entityTypeApiService);
	}
}