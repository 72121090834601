<div class="floating-header {{siteLayoutService.contentCssClass}}"
	*ngIf="display === true"
	[@contentAnimation]="state">
	<div class="parameter-summary no-scrollbar"
		*ngIf="displayParameters === true">
		<p-chips [(ngModel)]="parameterLayoutSchema"
			[disabled]="true">
			<ng-template let-item pTemplate="item">
				<div class="setting-chip ellipsis-text">
					<span class='setting-chip-label'>
						{{item.props.label}}:
					</span>
					{{getChipValue(item)}}
				</div>
			</ng-template>
		</p-chips>
	</div>

	<div class="inline-display cursor-pointer"
		*ngIf="displayEditOption === true">
		<app-common-icon-tooltip
			[content]="editDisplayTooltip"
			iconClass="fa fa-fw fa-edit theme-color"
			[position]="iconTooltipPosition"
			[styleClass]="iconStyleClass"
			allowTooltipActivation="false"
			(iconClick)="displayEditClicked($event)">
		</app-common-icon-tooltip>
	</div>

	<div class="inline-display cursor-pointer"
		[ngClass]="{
			'active': displayingSecondary === true && displayingSettings === false
		}"
		*ngIf="displaySecondaryOption === true && useClickOnDisplay !== true && flipCard !== true">
		<app-common-icon-tooltip
			[content]="alternateDisplayTooltip"
			iconClass="fa fa-fw fa-exchange theme-color"
			[position]="iconTooltipPosition"
			[styleClass]="iconStyleClass"
			allowTooltipActivation="false"
			(iconClick)="displaySecondaryClicked($event)">
		</app-common-icon-tooltip>
	</div>

	<div class="inline-display cursor-pointer"
		[ngClass]="{
			'active': displayingSettings === true
		}"
		*ngIf="displayParameterOption === true">
		<app-common-icon-tooltip
			[content]="alterDataTooltip"
			iconClass="fa fa-fw fa-filter theme-color"
			[position]="iconTooltipPosition"
			[styleClass]="iconStyleClass"
			allowTooltipActivation="false"
			(iconClick)="displaySettingsClicked()">
		</app-common-icon-tooltip>
	</div>

	<div class="position-relative cursor-pointer">
		<div class="filter-badge position-absolute inverse-theme-color"
			*ngIf="displayParameterOption === true && differenceCount != null">
			<span class="filter-badge-text"
				[pTooltip]="alterDataTooltip"
				[tooltipPosition]="iconTooltipPosition"
				[tooltipStyleClass]="iconStyleClass">
				{{differenceCount}}
			</span>
		</div>
	</div>
</div>