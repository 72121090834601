
<ng-template
	[ngIf]="loading === true"
	[ngIfElse]="Content">
	<div class="flex-center">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>
</ng-template>

<ng-template #Content>
	<div class="summary-card-container"
		*ngIf="claimPaymentId === null || additionalPayeeNames.length === 0">
		<div class="ui-g-12 summary-card-main-description text-center summary-font-size vertical-centered">
			No Data
		</div>
	</div>
	<div class="summary-card-container"
		*ngIf="claimPaymentId != null && additionalPayeeNames.length > 0">
		<div class="ui-g-12 summary-card text-center">
			<div class="summary-card-main-description summary-font-size ellipsis-text">
				<span>
					Additional Payees
				</span>
			</div>
			<p-scrollPanel styleClass="section-container">
				<div class="ui-g-12 no-padding"
					*ngFor="let payeeName of additionalPayeeNames; let payeeIndex=index">
					<div class="ui-g-12 panel-sub-title ellipsis-text">
						{{payeeIndex + 1}}. {{payeeName}}
					</div>
				</div>
			</p-scrollPanel>
		</div>
	</div>
</ng-template>