/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	ViewChild
} from '@angular/core';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ReportConstants
} from '@shared/constants/report.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IPowerBiReportDefinition
} from '@shared/interfaces/reports/power-bi/power-bi-report-definition.interface';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	PowerBiService
} from '@shared/services/power-bi.service';
import {
	DateTime
} from 'luxon';
import {
	Embed
} from 'powerbi-client';
import {
	IEventHandler
} from 'service';

/* eslint-enable max-len */

@Component({
	selector: 'app-external-report',
	templateUrl: './external-report.component.html',
	styleUrls: [
		'./external-report.component.scss'
	]
})

/**
 * A component representing context level external reports.
 *
 * @export
 * @class ExternalReportComponent
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @implements {IDynamicComponent<Component, any>}
 */
export class ExternalReportComponent
implements AfterViewInit, OnDestroy,
		IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the external report component.
	 *
	 * @param {PowerBiService} powerBiService
	 * The power bi display service used for external power bi reports.
	 * @param {PowerBiApiService} powerBiApiService
	 * The power bi api service used for external power bi reports.
	 * @memberof ExternalReportComponent
	 */
	public constructor(
		public powerBiService: PowerBiService,
		public powerBiApiService: PowerBiApiService)
	{
	}

	/**
	 * Gets or sets the element reference container.
	 *
	 * @type {ElementRef}
	 * @memberof ExternalReportComponent
	 */
	@ViewChild('ElementReferenceContainer')
	public elementReferenceContainer: ElementRef;

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ExternalReportComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the report type being displayed in this external report
	 * component.
	 *
	 * @type {string}
	 * @memberof ExternalReportComponent
	 */
	public reportType: string;

	/**
	 * Gets or sets the creation mode identifier if a new report is to be
	 * created and viewed.
	 *
	 * @type {boolean}
	 * @memberof ExternalReportComponent
	 */
	public creationMode: boolean = false;

	/**
	 * Gets or sets the interval timer reference returned when
	 * applicable authentication update timers are running.
	 *
	 * @type {NodeJS.Timeout}
	 * @memberof ExternalReportComponent
	 */
	public authenticationRefreshTimeout: NodeJS.Timeout;

	/**
	 * Gets or sets the power bi embed report reference if this
	 * is running a report type of power bi.
	 *
	 * @type {Embed}
	 * @memberof ExternalReportComponent
	 */
	public powerBiReportReference: Embed;

	/**
	 * Gets the list of event types that are being handled in a power bi
	 * external report.
	 *
	 * @type {object}
	 * @memberof ExternalReportComponent
	 */
	private readonly powerBiEventConstants:
	{
		dataSelected: string;
		loaded: string;
		selectionChanged: string;
		visualClicked: string;
	} = {
			dataSelected: 'dataSelected',
			loaded: 'loaded',
			selectionChanged: 'selectionChanged',
			visualClicked: 'visualClicked'
		};

	/**
	 * Gets the list of event types that when sent from a power
	 * bi report will call out to close overlays.
	 *
	 * @type {string[]}
	 * @memberof ExternalReportComponent
	 */
	private readonly closeOverlayEvents: string[] =
		[
			this.powerBiEventConstants.dataSelected,
			this.powerBiEventConstants.selectionChanged,
			this.powerBiEventConstants.visualClicked
		];

	/**
	 * Handles a set of click like event messages sent from a
	 * power bi report iframe. This will hide overlay displays
	 * as though a click occurred in the application.
	 *
	 * @param {{ origin: string; data: any;}} event
	 * The sent post message event from a captured iFrame.
	 * @returns {IEventHandler<void>}
	 * A handled event response.
	 * @memberof ExternalReportComponent
	 */
	@HostListener(
		WindowEventConstants.messageEvent,
		[AppEventParameterConstants.event])
	public onMessage(
		event: {
			origin: string;
			data: any;
		}): void
	{
		if (event.origin === ReportConstants.powerBiEmbedEndpoint
			&& (!AnyHelper.isNull(event.data?.params?.eventName)
				&& this.closeOverlayEvents.indexOf(
					event.data.params.eventName) !== -1))
		{
			EventHelper.dispatchHideAssociatedMenusEvent(
				AppConstants.empty);
		}
	}

	/**
	 * On after view initialization event.
	 * Handles report type logic and displays the desired external report.
	 *
	 * @memberof ExternalReportComponent
	 */
	public ngAfterViewInit(): void
	{
		this.reportType =
			this.context.data.reportDefinition.externalReportType;
		this.creationMode = this.context.data.createReport === true;

		switch (this.reportType)
		{
			case ReportConstants.externalReportTypes.powerBi:
				this.powerBiReportReference =
					this.creationMode === true
						? this.powerBiService
							.createReport(
								this.elementReferenceContainer.nativeElement,
								this.context.data.reportDefinition
									.embedConfiguration)
						: this.powerBiService
							.embed(
								this.elementReferenceContainer.nativeElement,
								this.context.data.reportDefinition
									.embedConfiguration);
				break;
			default:
				throw new Error(
					`'${this.reportType}' external report. This requires `
						+ AppConstants.messages.switchCaseNotHandled);
		}

		this.setAuthenticationUpdates();
		EventHelper.dispatchSiteLayoutChangedEvent();
	}

	/**
	 * On after component destroy event.
	 * This will clear any timer based authentication interval actions
	 * running on this report.
	 *
	 * @memberof ExternalReportComponent
	 */
	public ngOnDestroy(): void
	{
		switch (this.reportType)
		{
			case ReportConstants.externalReportTypes.powerBi:
				clearTimeout(
					this.authenticationRefreshTimeout);
				break;
		}
	}

	/**
	 * Sets intervals needed in order for any existing external report
	 * to remain authenticated as long as the user is logged in and this
	 * report is displayed.
	 *
	 * @memberof ExternalReportComponent
	 */
	public setAuthenticationUpdates(): void
	{
		switch (this.reportType)
		{
			case ReportConstants.externalReportTypes.powerBi:
				this.powerBiReportReference.on(
					this.powerBiEventConstants.loaded,
					() =>
					{
						this.updatePowerBiAuthentication();
						EventHelper.dispatchSiteLayoutChangedEvent();
					});
				break;
		}
	}

	/**
	 * Handles the on load event of a current report in order to set an
	 * access token update on the preconfigured authentication expiry.
	 *
	 * @returns {IEventHandler<void>}
	 * A handled event response.
	 * @memberof ExternalReportComponent
	 */
	public updatePowerBiAuthentication(): IEventHandler<void>
	{
		// Clear any existing timers.
		this.ngOnDestroy();

		// Find when the current access token used for embed expires.
		const accessTokenRefreshDelay: number =
			this.powerBiApiService.accessTokenExpiry
				.diff(
					DateTime.utc())
				.milliseconds;

		// On expiration, refresh the embed token and reset for the next azure
		// token expiration.
		this.authenticationRefreshTimeout =
			setTimeout(
				async() =>
				{
					const reportDefinition: IPowerBiReportDefinition =
						<IPowerBiReportDefinition>
							this.context.data.reportDefinition;

					const embedToken: any =
						await this.powerBiApiService
							.generateEmbedToken(
								reportDefinition.reportId,
								reportDefinition.groupId,
								reportDefinition.datasetId,
								reportDefinition.datasetGroupId,
								reportDefinition.reportType,
								reportDefinition.tileId,
								reportDefinition.viewOnly);

					await this.powerBiReportReference
						.setAccessToken(embedToken.token);

					this.updatePowerBiAuthentication();
				},
				accessTokenRefreshDelay);

		return null;
	}
}