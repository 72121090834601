<div class="layout-footer">
	<span class="footer-text-left">
		<img src="assets/layout/images/logo-dark.png"
			alt="Site logo" />
	</span>
	<span class="footer-text-right">
		<span class="text-link double-font-size">
			<i class="fa fa-facebook">
			</i>
			&nbsp;
		</span>
		<span class="text-link double-font-size">
			<i class="fa fa-twitter">
			</i>
			&nbsp;
		</span>
		<span class="text-link double-font-size">
			<i class="fa fa-github">
			</i>
			&nbsp;
		</span>
	</span>
</div>