/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';

/**
 * A class representing static constant and read only values
 * associated with site permissions.
 *
 * @export
 * @class PermissionConstants
 */
export class PermissionConstants
{
	public static readonly editPowerBiReportRoles: string [] =
		[
			AppConstants.securityGroups.administrators,
			AppConstants.securityGroups.supportUsers
		];
}