/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	HostListener
} from '@angular/core';
import {
	DomSanitizer
} from '@angular/platform-browser';
import {
	BooleanFadeAnimation,
	DropInAnimation
} from '@shared/app-animations';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ClientMessage
} from '@shared/implementations/application-data/client-message';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	debounceTime,
	distinctUntilChanged,
	startWith
} from 'rxjs';

@Component({
	selector: 'app-banner',
	templateUrl: './app-banner.component.html',
	styleUrls: ['./app-banner.component.scss'],
	animations: [
		DropInAnimation,
		BooleanFadeAnimation
	]
})

/**
 * A component representing an instance of the banner component.
 *
 * @export
 * @class AppBannerComponent
 */
export class AppBannerComponent
{
	/**
	 * Initializes a new instance of the app banner component.
	 *
	 * @param {DomSanitizer} sanitizer
	 * The dom sanitizer used to set safe html values for display
	 * in the message and title of this banner.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to get the pinned drawers available.
	 * @memberof AppBannerComponent
	 */
	public constructor(
		public sanitizer: DomSanitizer,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the current banner id which will be set incrementally
	 * for each new banner item allowing distinct gets.
	 *
	 * @type {number}
	 * @memberof AppBannerComponent
	 */
	public currentMaxId: number = 1;

	/**
	 * Gets or sets the display time for informational or completed messages
	 * that do not require user interaction.
	 *
	 * @type {number}
	 * @memberof AppBannerComponent
	 */
	public fadeTime: number = 3000;

	/**
	 * Gets or sets the animation time for the simple fade animation. This is
	 * used to remove this banner item from the list when fully hidden.
	 *
	 * @type {number}
	 * @memberof AppBannerComponent
	 */
	public fadeAnimationTime: number = 500;

	/**
	 * Gets or sets the list of all banner messages currently displayed in this
	 * component.
	 *
	 * @type {ClientMessage[]}
	 * @memberof AppBannerComponent
	 */
	public clientMessages: ClientMessage[] = [];

	/**
	 * Handles the client add banner event.
	 * This will add a new banner to this banner component. Depending on the
	 * banner status this will fade out if a success or info message and will
	 * require a dismiss click if of type error.
	 *
	 * @async
	 * @param {ClientMessage} clientMessage
	 * The client message to be added to this banner component when creating
	 * the add banner event.
	 * @memberof AppBannerComponent
	 */
	@HostListener(
		AppEventConstants.addBannerEvent,
		[AppEventParameterConstants.clientMessage])
	public async addBannerEvent(
		clientMessage: ClientMessage): Promise<void>
	{
		try
		{
			clientMessage.id = ++this.currentMaxId;
			clientMessage.display = true;
			clientMessage.safeTitle =
				this.sanitizer.bypassSecurityTrustHtml(
					clientMessage.title);
			clientMessage.safeContent =
				this.sanitizer.bypassSecurityTrustHtml(
					clientMessage.content);
			await clientMessage.setExtendedDetails();

			this.clientMessages =
				[
					...this.clientMessages,
					clientMessage
				];

			if (clientMessage.status === AppConstants.activityStatus.complete
				|| clientMessage.status === AppConstants.activityStatus.info)
			{
				clientMessage.displayChanged.pipe(
					startWith(clientMessage.display),
					debounceTime(this.fadeTime),
					distinctUntilChanged())
					.subscribe((newValue: boolean) =>
					{
						clientMessage.display = newValue;

						if (clientMessage.display === false)
						{
							this.fadeBanner(clientMessage);
						}
					});

				clientMessage.displayChanged.next(false);
			}
		}
		catch (error)
		{
			this.clientMessages =
				[
					...this.clientMessages,
					<ClientMessage>
					{
						id: ++this.currentMaxId,
						display: true,
						title: 'Unexpected Banner Display Error',
						content: error.message,
						status: AppConstants.activityStatus.error
					}
				];
		}
	}

	/**
	 * Handles the dismiss banner click which will remove the selected banner.
	 *
	 * @param {ClientMessage} clientMessage
	 * The client message to be dismissed.
	 * @param {Event} event
	 * The event for this dismiss banner click.
	 * @memberof AppBannerComponent
	 */
	public dismissBanner(
		clientMessage: ClientMessage,
		event: Event): void
	{
		this.clientMessages =
			this.clientMessages.filter((message: ClientMessage) =>
				clientMessage.id !== message.id);

		event.preventDefault();
		event.stopPropagation();
	}

	/**
	 * Fades out the banner item if called and removes this from the
	 * list after fully hidden.
	 *
	 * @param {ClientMessage} clientMessage
	 * The client message to fade out.
	 * @memberof AppBannerComponent
	 */
	public fadeBanner(
		clientMessage: ClientMessage)
	{
		clientMessage.display = false;

		setTimeout(
			() =>
			{
				this.clientMessages =
					this.clientMessages.filter(
						(message: ClientMessage) =>
							clientMessage.id !== message.id);
			},
			this.fadeAnimationTime);
	}

	/**
	 * This gets the exact banner x position based on if there are any
	 * pinned drawers, and the available content with.
	 *
	 * @returns {string}
	 * The banner x position in pixels.
	 * @memberof AppBannerComponent
	 */
	public getBannerXPosition(): string
	{
		return `${(AppConstants.staticLayoutSizes.standardPadding
			+ AppConstants.staticLayoutSizes.utilityMenuWidth)
			+ (AppConstants.staticLayoutSizes.drawerWidth
				* this.siteLayoutService.pinnedDrawerCount)}px`;
	}

	/**
	 * Handles the banner hover, focus, or click event.
	 * This will send a debounced change event to ensure that the fade timer is
	 * reset on hover. This ensures that informational or completed banners
	 * will not fade out if this is the user's current focus.
	 *
	 * @param {ClientMessage} clientMessage
	 * The client message currently handling the hover or click event.
	 * @memberof AppBannerComponent
	 */
	public bannerMouseIn(
		clientMessage: ClientMessage): void
	{
		clientMessage.displayChanged.next(true);
	}

	/**
	 * Handles the banner hover out or focus out event.
	 * This will send a debounced change event to ensure that the fade timer is
	 * started again on hover out or a loss of focus. This ensures that
	 * informational or completed banners will fade out appropriately after
	 * resetting on a hover, focus, or click event.
	 *
	 * @param {ClientMessage} clientMessage
	 * The client message currently handling the hover or click event.
	 * @memberof AppBannerComponent
	 */
	public bannerMouseOut(
		clientMessage: ClientMessage): void
	{
		clientMessage.displayChanged.next(false);
	}
}