/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	CommonModule
} from '@angular/common';
import {
	NgModule
} from '@angular/core';
import {
	RouterModule
} from '@angular/router';
import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	BiComponent
} from '@bi/bi.component';
import {
	ReportsComponent
} from '@bi/components/reports/reports.component';
import {
	ClonePowerBiReportComponent
} from '@bi/components/wizard-steps/clone-power-bi-report/clone-power-bi-report.component';
import {
	SelectExistingPowerBiReportComponent
} from '@bi/components/wizard-steps/select-existing-power-bi-report/select-existing-power-bi-report.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	SharedModule
} from '@shared/shared.module';

/* eslint-enable max-len */

@NgModule({
	declarations: [
		BiComponent,
		ClonePowerBiReportComponent,
		ReportsComponent,
		SelectExistingPowerBiReportComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		RouterModule.forChild([
			{
				path: 'bi',
				component: BiComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				data:
				{
					roles: [
						AppConstants.securityGroups.biModuleAccess
					]
				},
				children: [
					{
						path: 'accessDenied',
						component: AccessDeniedComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'dashboard',
						component: GenericDashboardComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'dashboard/:dashboardType',
						component: GenericDashboardComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'display',
						children: [
							{
								path: ':displayComponent/view',
								component: GenericBasePageComponent,
								data: {
									refreshComponent: true
								}
							},
							{
								path: ':displayComponent/edit',
								component: GenericBasePageComponent,
								canActivate: [
									AppAuthGuard
								],
								data:
								{
									roles: [
										AppConstants.securityGroups
											.biModuleAccess
									],
									refreshComponent: true
								}
							}
						]
					},
					{
						path: 'reports',
						children: [
							{
								path: '',
								component: ReportsComponent
							}
						]
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					{
						path: '**',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					}
				]
			}])
	],
	exports: [
		ClonePowerBiReportComponent,
		SelectExistingPowerBiReportComponent
	],
	providers: [
		ClonePowerBiReportComponent,
		SelectExistingPowerBiReportComponent
	]
})

/**
 * A module used to hold bi route specific
 * logic and display resources.
 *
 * @export
 * @class BiModule
 */
export class BiModule
{
}