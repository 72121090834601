/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';

@Component({
	selector: 'custom-checkbox',
	templateUrl: './custom-checkbox.component.html',
	styleUrls: [
		'./custom-checkbox.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Checkbox.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomCheckboxComponent
 * @extends {ExtendedCustomControlDirective}
 */
export class CustomCheckboxComponent
	extends ExtendedCustomControlDirective
{
	/** Initializes a new instance of the CustomCheckboxComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomCheckboxComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets the checkbox text to be displayed.
	 *
	 * @type {String}
	 * @memberof CustomCheckboxComponent
	 */
	 public checkboxText: string;
}