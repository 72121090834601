<div class="ui-g-12 no-padding">
	<div class="ui-g-12 no-horizontal-padding wizard-content-title">
	</div>
	<ng-template
		[ngIf]="context.source.wizardStepLoading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<make-payment-details
			[context]="context"
			title="Make Payment">
		</make-payment-details>
	</ng-template>
</div>