/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ManageUserComponent
} from '@admin/components/security/users/manage-user-expand/manage-user-expand.component';
import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	AuthenticateApiService
} from '@api/services/security/authenticate.api.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IActionTableConfiguration
} from '@shared/interfaces/application-objects/actions-table-configuration.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	ISecuritySession
} from '@shared/interfaces/security/security-session.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-manage-users',
	templateUrl: './manage-users.component.html',
	styleUrls: [
		'./manage-users.component.scss'
	]
})

/**
 * A component representing an instance of the security applications
 * component.
 *
 * @export
 * @class ManageUsersComponent
 */
export class ManageUsersComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the ManageUsersComponent class.
	 *
	 * @param {SecurityApplicationApiService} securityApplicationApiService
	 * The api service used to load security application data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to load entity instance data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ManageUsersComponent
	 */
	public constructor(
		public securityGroupApiService: SecurityGroupApiService,
		public securitySessionApiService: SecuritySessionApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public authenticateApiService: AuthenticateApiService,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ManageUsersComponent
	 */
	public manageUsersTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the security groups.
	 *
	 * @type {ISecurityGroup[]}
	 * @memberof ManageUsersComponent
	 */
	public securityGroups: ISecurityGroup[];

	/**
	 * Gets or sets the filter criteria.
	 *
	 * @type {string}
	 * @memberof ManageUsersComponent
	 */
	public filterCriteria: string;

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ManageUsersComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'userName',
					columnHeader: 'Username',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'firstName',
					columnHeader: 'First Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'lastName',
					columnHeader: 'Last Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'email',
					columnHeader: 'Email',
					displayOrder: displayOrder
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @memberof ManageUsersComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.securityGroups =
			await ApiHelper.getFullDataSet(
				this.securityGroupApiService,
				AppConstants.empty,
				AppConstants.empty);

		this.manageUsersTableDefinitions =
			{
				tableTitle: AppConstants.securityGroups.users,
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'User'),
				fullPageReservedHeight:
					AppConstants.staticLayoutSizes.tableSearchHeight,
				objectSearch: {
					filter: !AnyHelper.isNullOrEmpty(this.filterCriteria)
						? `Keywords.Contains('${this.filterCriteria}') eq true`
						: AppConstants.empty,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						this.entityInstanceApiService.entityInstanceTypeGroup =
							AppConstants.typeGroups.users;
						const entityInstances: object[] =
								await this.entityInstanceApiService
									.query(
										objectSearch.filter,
										objectSearch.orderBy,
										objectSearch.offset,
										objectSearch.limit);

						const users: object[] = [];
						entityInstances.forEach(
							async(entityInstance: IEntityInstance) =>
							{
								users.push(
									this.decorateViewModel(
										entityInstance));
							});

						return users;
					},
				decorateViewModel:
					(model: any) =>
						this.decorateViewModel(model),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: this.getActions()
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Applies the filter criteria event to the table display. This will set
	 * the object search filter to the provided filter value.
	 *
	 * @async
	 * @param {string} filterCriteria
	 * The filter event sent from the search component.
	 * @memberof ManageUsersComponent
	 */
	public filterCriteriaChanged(
		filterCriteria: string): void
	{
		this.filterCriteria = filterCriteria;

		this.commonTableContext.source.filterCriteriaChanged(
			!AnyHelper.isNullOrEmpty(this.filterCriteria)
				? `Keywords.Contains('${this.filterCriteria}') eq true`
				: AppConstants.empty);
	}

	/**
	 * Decorates the view model for the object.
	 *
	 * @param {any} model
	 * The object to be decorated as a view model.
	 * @returns {any}
	 * The mapped view model for display in this component.
	 * @memberof ManageUsersComponent
	 */
	public decorateViewModel(
		model: any): any
	{
		const viewModel: any =
			<any>
			{
				id: model.id,
				userName:
					model.data.userName,
				firstName:
					model.data.firstName,
				lastName:
					model.data.lastName,
				email:
					model.data.email
			};

		return viewModel;
	}

	/**
	 * Creates a new Entity Instance.
	 *
	 * @async
	 * @memberof ManageUsersComponent
	 */
	private async createEntityInstance(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const createObject: IEntityInstance =
			{
				id: 0,
				versionNumber: 1,
				data: {
					email: selectedItem.email,
					firstName: selectedItem.firstName,
					lastName: selectedItem.lastName,
					userName: selectedItem.userName
				},
				entityType: 'User'
			};

		const createEntityInstance: Function =
			async() =>
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
					AppConstants.typeGroups.users;
				const createdId: number =
					await this.entityInstanceApiService
						.createEntityInstance(
							createObject,
							AppConstants.typeGroups.systems,
							parseInt(
								AppConstants.systemId,
								AppConstants.parseRadix));

				this.entityInstanceApiService.entityInstanceTypeGroup =
					AppConstants.typeGroups.users;
				this.commonTableContext.source.selectedItem =
					await this.entityInstanceApiService.get(
						createdId);

				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				createEntityInstance(),
				'<strong>Creating User</strong>',
				'<strong>Created User</strong>',
				'User was created.',
				'User was not created.'));
	}

	/**
	 * Updates an existing Entity Instance.
	 *
	 * @async
	 * @memberof ManageUsersComponent
	 */
	private async updateEntityInstance(): Promise<void>
	{
		const updateUser: Function =
			async () =>
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
					AppConstants.typeGroups.users;
				const entityInstance: IEntityInstance =
					await this.entityInstanceApiService
						.get(this.commonTableContext.source.selectedItem.id);

				entityInstance.data =
					{
						...entityInstance.data,
						...this.commonTableContext.source.selectedItem
					};

				delete entityInstance.data.id;

				entityInstance.data.taxId =
					parseInt(
						entityInstance.data.taxId,
						AppConstants.parseRadix);

				entityInstance.data.password = AppConstants.empty;

				const updateInstanceObject: any =
					{
						id: this.commonTableContext.source.selectedItem.id,
						resourceIdentifier: entityInstance.resourceIdentifier,
						entityType: entityInstance.entityType,
						versionNumber: entityInstance.versionNumber,
						data: entityInstance.data
					};

				const selectedItem: any =
					this.commonTableContext.source.selectedItem;

				delete updateInstanceObject.data.password;

				this.entityInstanceApiService.entityInstanceTypeGroup =
					AppConstants.typeGroups.users;
				await this.entityInstanceApiService
					.update(
						selectedItem.id,
						updateInstanceObject);

				this.commonTableContext.source.selectedItem =
					await this.entityInstanceApiService.get(
						selectedItem.id);

				await this.commonTableContext.source.updateSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				updateUser(),
				'<strong>Updating User</strong>',
				'<strong>Updated User</strong>',
				`User ${this.commonTableContext.source.selectedItem.id} `
					+ 'was updated.',
				`User ${this.commonTableContext.source.selectedItem.id} `
					+ 'was not updated.'),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Deletes an existing Entity Instance.
	 *
	 * @async
	 * @memberof ManageUsersComponent
	 */
	private async deleteEntityInstance(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const deleteEntityInstance: Function =
			async() =>
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
					AppConstants.typeGroups.users;
				await this.entityInstanceApiService
					.delete(selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteEntityInstance(),
				'<strong>Deleting User</strong>',
				'<strong>Deleted User</strong>',
				`User ${selectedItem.id} was deleted.`,
				`User ${selectedItem.id} was not deleted.`));
	}

	/**
	 * Checks if user name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof ManageUsersComponent
	 */
	private async uniqueUserName(control: UntypedFormControl): Promise<boolean>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
		const userName: IEntityInstance[] =
			await this.entityInstanceApiService.query(
				`userName eq '${control.value}'`,
				AppConstants.empty);

		return Promise.resolve(
			AnyHelper.isNullOrEmpty(userName[0]));
	}

	/**
	 * Gets the delete statement to be displayed on the
	 * delete expand view.
	 *
	 * @async
	 * @return {Promise<string>}
	 * The delete statement string.
	 * @memberof ManageUsersComponent
	 */
	private async deleteStatement(): Promise<string>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const securitySessions: ISecuritySession[] =
			await this.securitySessionApiService
				.query(
					`entityInstanceId eq ${selectedItem.id}`,
					AppConstants.empty);

		if (!AnyHelper.isNullOrEmpty(securitySessions[0]))
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[0].disabled = true;

			return 'Unable to delete user. User has already logged in.';
		}

		this.commonTableContext.source.tableDefinitions.actions
			.delete.items[0].disabled = false;

		return 'Confirm you are about to delete User '
			+ `${selectedItem.userName}`;
	}

	/**
	 * Gets the actions for the table.
	 *
	 * @returns {any}
	 * The actions for the table.
	 * @memberof ManageUsersComponent
	 */
	private getActions(): any
	{
		const actionTableConfiguration: IActionTableConfiguration =
			<IActionTableConfiguration>
			{
				create: {
					layout: [
						{
							key: 'userName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Username',
								disabled: false,
								required: true
							},
							asyncValidators: {
								uniqueUsername: {
									expression:
										(control: UntypedFormControl) =>
											this.uniqueUserName(control),
									message: 'Username not available.'
								}
							}
						},
						{
							key: 'firstName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'First Name',
								disabled: false,
								required: true
							}
						},
						{
							key: 'lastName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Last Name',
								disabled: false,
								required: true
							}
						},
						{
							key: 'email',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Email',
								disabled: false,
								required: true
							},
							validators: {
								validation: ['email'],
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createEntityInstance()
						}]
				},
				view: {
					component: ManageUserComponent,
					layout: [
						{
							key: 'userName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Username',
								disabled: true
							}
						},
						{
							key: 'firstName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'First Name',
								disabled: true
							}
						},
						{
							key: 'lastName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Last Name',
								disabled: true
							}
						},
						{
							key: 'email',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Email',
								disabled: true
							},
							validators: {
								validation: ['email']
							}
						}
					],
					items: []
				},
				update: {
					component: ManageUserComponent,
					layout: [
						{
							key: 'firstName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'First Name',
								required: true
							}
						},
						{
							key: 'lastName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Last Name',
								required: true
							}
						},
						{
							key: 'email',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Email',
								required: true,
								gridColumns: 12
							},
							validators: {
								validation: ['email']
							}
						}
					],
					definition: null,
					items: [
						{
							label: 'Actions',
							visible: true,
							items: [
								{
									label: 'Reset Password',
									command: () => this.resetPassword()
								},
								{
									label: 'Re-Send Welcome Letter',
									styleClass:
										AppConstants.cssClasses.pButtonPrimary,
									command: () => this.sendWelcomeLetter()
								}
							]
						},
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.updateEntityInstance()
						}
					]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							disabled: false,
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: () => this.deleteEntityInstance()
						}],
					deleteStatement: () => this.deleteStatement()
				}
			};

		return actionTableConfiguration;
	}

	/**
	 * Resets the user password.
	 *
	 * @async
	 * @memberof ManageUsersComponent
	 */
	private async resetPassword(): Promise<void>
	{
		const userName: string =
			this.commonTableContext.source.selectedItem.userName;

		const resetUserPassword: Function =
			async () =>
			{
				await this.authenticateApiService.reset(userName);

				this.commonTableContext.source.displayTable();
			};

		await this.activityService.handleActivity(
			new Activity(
				resetUserPassword(),
				'<strong>Resetting User</strong>',
				'<strong>User was successfully reset</strong>',
				`User ${userName} was reset.`,
				`User ${userName} was not reset.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Sends the user welcome letter.
	 *
	 * @async
	 * @memberof ManageUsersComponent
	 */
	private async sendWelcomeLetter(): Promise<void>
	{
		const userName: string =
			this.commonTableContext.source.selectedItem.userName;
		const userId: string =
			this.commonTableContext.source.selectedItem.id;

		const sendUserWelcomeLetter: Function =
			async () =>
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
					AppConstants.typeGroups.systems;
				const systemInstance: IEntityInstance =
					await this.entityInstanceApiService
						.get(
							parseInt(
								AppConstants.systemId,
								AppConstants.parseRadix));

				this.entityInstanceApiService.entityInstanceTypeGroup =
					AppConstants.typeGroups.systems;
				await this.entityInstanceApiService.executeAction(
					systemInstance.id,
					'CreateSystemWelcomeLetter',
					null,
					'UserId=' +  userId);

				this.commonTableContext.source.displayTable();
			};

		await this.activityService.handleActivity(
			new Activity(
				sendUserWelcomeLetter(),
				`<strong>Re-Sending Welcome Letter for ${userName}</strong>`,
				`<strong>User ${userName} welcome letter</strong>`,
				'Welcome letter was successfully re-send.',
				'Welcome letter was not re-send.'),
			AppConstants.activityStatus.complete,
			true);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
	}
}