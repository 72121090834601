/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	FileDirective
} from '@shared/directives/file.directive';

@Component({
	selector: 'app-file-details',
	templateUrl: './file-details.component.html',
	styleUrls: [
		'./file-details.component.scss'
	]
})

/**
 * A class for diplaying file details
 *
 * @export
 * @class FileDetailsComponent
 * @extends {FileDirective}
 */
export class FileDetailsComponent
	extends FileDirective
{
}