/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Directive,
	HostListener,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	ActivatedRoute
} from '@angular/router';
import {
	EntityEventConstants
} from '@entity/shared/entity-event.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	OperationEventParameterConstants
} from '@operation/shared/operation-event-parameter.constants';
import {
	OperationEventConstants
} from '@operation/shared/operation-event.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	cloneDeep
} from 'lodash-es';
import {
	Observable,
	Subject,
	Subscription,
	debounceTime,
	distinctUntilChanged,
	startWith
} from 'rxjs';

/* eslint-enable max-len */

@Directive({
	selector: '[EntityManager]'
})

/**
 * A directive representing shared logic for a component interacting
 * with account.
 *
 * @export
 * @class EntityManagerDirective
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class EntityManagerDirective
implements OnInit, OnDestroy
{
	/**
	 * Creates an instance of an EntityManagerDirective.
	 *
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityManagerDirective
	 */
	public constructor(
		public route: ActivatedRoute,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
	}

	/**
	 * Gets or sets the entity definition id.
	 *
	 * @type {number}
	 * @memberof EntityManagerDirective
	 */
	public entityDefinitionId: number;

	/**
	 * Gets or sets the layout fields.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof EntityManagerDirective
	 */
	public layoutFields: FormlyFieldConfig[];

	/**
	 * Gets or sets the definition data.
	 *
	 * @type {any}
	 * @memberof EntityManagerDirective
	 */
	public contextData: any;

	/**
	 * Gets or sets the loading definitions state.
	 *
	 * @type {boolean}
	 * @memberof EntityManagerDirective
	 */
	public loadingDefinitions: boolean = true;

	/**
	 * Gets or sets the entity type api data.
	 *
	 * @type {IEntityType}
	 * @memberof EntityManagerDirective
	 */
	public entityType: IEntityType;

	/**
	 * Gets or sets the entity definition api data.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntityManagerDirective
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * Gets or sets the entity version api data.
	 *
	 * @type {IEntityVersion}
	 * @memberof EntityManagerDirective
	 */
	public entityVersion: IEntityVersion;

	/**
	 * Gets or sets the flag that a value has changed in the entity instance
	 * displayed and is not saved.
	 *
	 * @type {boolean}
	 * @memberof EntityManagerDirective
	 */
	public formValuesChanged: boolean = null;

	/**
	 * Gets or sets the observer of form validation changes.
	 *
	 * @type {Subject<boolean>}
	 * @memberof EntityManagerDirective
	 */
	public formValidityChanged: Subject<boolean> = new Subject<boolean>();

	/**
	 * Gets or sets the is saving value of this component.
	 *
	 * @type {boolean}
	 * @memberof EntityManagerDirective
	 */
	public saving: boolean = false;

	/**
	 * Gets or sets the data as it exists
	 * in the database.
	 *
	 * @type {any}
	 * @memberof EntityManagerDirective
	 */
	public databaseData: any;

	/**
	 * Gets or sets the form validity state.
	 *
	 * @type {boolean}
	 * @memberof EntityManagerDirective
	 */
	public formValidity: boolean = true;

	/**
	 * Gets or sets the initial load flag.
	 *
	 * @type {boolean}
	 * @memberof EntityManagerDirective
	 */
	public initialLoad: boolean = true;

	/**
	 * Gets or sets the save title.
	 *
	 * @type {string}
	 * @memberof EntityManagerDirective
	 */
	public saveTitle: string;

	/**
	 * Gets or sets the save content.
	 *
	 * @type {string}
	 * @memberof EntityManagerDirective
	 */
	public saveContent: string;

	/**
	 * Gets or sets the subscriptions used in this component.
	 *
	 * @type {Subscription}
	 * @memberof EntityManagerDirective
	 */
	public subscriptions: Subscription = new Subscription();

	/**
	 * Gets or sets the common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof EntityManagerDirective
	 */
	public commonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets the page context sent to associated context utilities
	 * and menus.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof EntityRulePresentationComponent
	 */
	public get pageContext(): IDynamicComponentContext<Component, any>
	{
		return <IDynamicComponentContext<Component, any>> {
			source: this,
			data: null
		};
	}

	/**
	 * Gets or sets the delay for a debounced subject change.
	 *
	 * @type {number}
	 * @memberof EntityManagerDirective
	 */
	protected readonly debounceDelay: number =
		AppConstants.time.twentyFiveMilliseconds;

	/**
	 * Handles the before unload event sent from the current window based
	 * on any action that will change the page.
	 *
	 * @memberof EntityManagerDirective
	 * @returns {Observable<boolean> | boolean}
	 * The value that will allow the router to know if the data in this form
	 * is altered or not saved to the database. This implements the
	 * AppCanDeactivateGuard interface.
	 */
	@HostListener(
		WindowEventConstants.beforeUnloadEvent)
	public canDeactivate(): Observable<boolean> | boolean
	{
		return this.saving === false
			&& this.formValuesChanged === false;
	}

	/**
	 * Handles the submit event sent from the angular schema form
	 * or from a save EmitEvent operation action. This updates
	 * the server to match the schema form data.
	 *
	 * @async
	 * @memberof EntityManagerDirective
	 */
	@HostListener(
		OperationEventConstants.saveEntityEvent,
		[OperationEventParameterConstants.event])
	 public async save(): Promise<void>
	 {
		if (this.saving === true)
		{
			return;
		}

		this.saving = true;

		await this.activityService.handleActivity(
			new Activity(
				this.saveAction(),
				`<strong>Saving ${this.saveTitle}</strong>`,
				`<strong>Saved ${this.saveTitle}</strong>`,
				`${this.saveContent} was successfully saved.`,
				`${this.saveContent} was not saved.`));

		await this.setFormlyDefinitions();
		this.formValidityChanged.next(false);

		this.saving = false;
	}

	/**
	 * Handles the on initialization event.
	 * This will load and initialize the lookups available for report creation.
	 *
	 * @async
	 * @memberof EntityManagerDirective
	 */
	public async ngOnInit(): Promise<void>
	{
		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		await this.setFormlyDefinitions();

		await this.setTableDefinitions();

		this.formValidityChanged.pipe(
			startWith(false),
			debounceTime(this.debounceDelay),
			distinctUntilChanged())
			.subscribe((enabled: boolean) =>
			{
				this.emitFormValidityChangedEvent(enabled);
			});
	}

	/**
	 * On component destroy event.
	 * This method is used to complete the debounce on the
	 * form validity changed value.
	 *
	 * @memberof EntityManagerDirective
	 */
	public ngOnDestroy(): void
	{
		this.formValidityChanged.complete();
	}

	/**
	 * Sets the Formly Definitions.
	 *
	 * @async
	 * @memberof EntityManagerDirective
	 */
	public async setFormlyDefinitions(): Promise<void>
	{
		await this.setContextData();
		this.updateLastSavedData();
		this.setLayoutFields();

		this.loadingDefinitions = false;
	}

	/**
	 * This function is used to save the data in the last database loaded
	 * or saved entity instance. This data is then used for comparisons
	 * to the current entity form to validate if page changes are allowed
	 * or similar business rules.
	 *
	 * @memberof AccountDirective
	 */
	public updateLastSavedData(): void
	{
		this.databaseData =
			cloneDeep(this.contextData);
	}

	/**
	 * Captures value changes sent from the dynamic formly component.
	 *
	 * @param {boolean} valueChanged
	 * Whether or not the current form is valid.
	 * @memberof EntityManagerDirective
	 */
	public formValuesChange(
		valueChanged: boolean): void
	{
		this.formValuesChanged = valueChanged;
		this.handleFormChangesAndValidity();
	}

	/**
	 * Captures validity changes sent from the dynamic formly component.
	 *
	 * @param {boolean} valid
	 * Whether or not the current form is valid.
	 * @memberof EntityManagerDirective
	 */
	public formValidityChange(
		valid: boolean): void
	{
		this.formValidity = valid;
		this.handleFormChangesAndValidity();
	}

	/**
	 * Handles any form changes and validity.
	 *
	 * @memberof EntityManagerDirective
	 */
	public handleFormChangesAndValidity()
	{
		setTimeout(
			() =>
			{
				this.formValidityChanged
					.next(this.formValuesChanged && this.formValidity);
			},
			AppConstants.time.halfSecond);
	}

	/**
	 * Emits the form validity changed event to any listening components.
	 * This event signifies whether this current profile form is valid
	 * or not.
	 *
	 * @param {boolean} enabled
	 * Whether or not save or update actions should be enabled. If they
	 * are enabled, we expect any saves or updates to work without error.
	 * @memberof EntityManagerDirective
	 */
	public emitFormValidityChangedEvent(
		enabled: boolean): void
	{
		window.dispatchEvent(
			new CustomEvent(
				EntityEventConstants.enableEntitySave,
				{
					detail: {
						enabled: enabled
					}
				}
			));
	}

	/**
	 * Gets the dropdown options.
	 *
	 * @param {any} apiData
	 * The api resolved with data for the dropdown options.
	 * @param {string} label
	 * The dropdown label to map with the data promise results.
	 * @param {string} value
	 * The dropdown value to map with the data promise results.
	 * @returns {IDropdownOption[]}
	 * The dropdown options object
	 * @memberof EntityManagerDirective
	 */
	public getDropdownOptions(
		apiData: any,
		label: string,
		value: string): IDropdownOption[]
	{
		return (apiData)
			.map((item: any) =>
			<IDropdownOption>
					{
						label: item[label],
						value: item[value]
					});
	}

	/**
	 * Sets up the page variables.
	 *
	 * @async
	 * @memberof EntityManagerDirective
	 */
	public async saveAction(): Promise<void>
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets the formly layout fields.
	 *
	 * @memberof EntityManagerDirective
	 */
	public setLayoutFields(): void
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityManagerDirective
	 */
	public async setContextData(): Promise<void>
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets the Table Definitions.
	 *
	 * @async
	 * @memberof EntityManagerDirective
	 */
	public async setTableDefinitions(): Promise<void>
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}
}