/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IWizardStep
} from '@shared/interfaces/dynamic-interfaces/wizard-step-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-cancel-reasons',
	templateUrl: './transaction-cancel-reasons.component.html'
})

/**
 * A component representing a wizard step for the cancel transaction.
 *
 * @export
 * @class TransactionCancelReasonsComponent
 * @extends {StatusReasonsDirective}
 */
export class TransactionCancelReasonsComponent
	extends StatusReasonsDirective
{
	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public clientMessage: string =
		'Cancel is not allowed on a non-active policy term, '
			+ 'a policy term that is not the most recent, when '
			+ 'pending transactions exist in this policy term, when '
			+ 'the latest is a cancellation transaction, or when '
			+ 'future transactions exist.';

	/**
	 * Gets or sets true/false if the current policy term is allowing the
	 * cancel process.
	 *
	 * @type {boolean}
	 * @memberof TransactionCancelReasonsComponent
	 */
	public allowedTransactionCancel: boolean;

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @memberof TransactionCancelReasonsComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.allowedTransactionCancel =
			await this.isWizardStepValidForDisplay();

		const currentData: any =
			this.context.source.activeMenuItem.currentData;

		const selectedReasons: any =
			AnyHelper.isNull(currentData.data.selectedReasons)
				? []
				: currentData.data.selectedReasons;

		const availableReasons: any[] =
			this.getReasons(
				this.productEntityInstance.data.reasons,
				InsuranceConstants.statusReasonTypes.rejection)
				.filter(reason => reason.isCancelReason === true);

		availableReasons.forEach(
			(cancelReason: any) =>
			{
				const description: string =
					AnyHelper.isNullOrEmpty(cancelReason.description)
						? ''
						: ' - ' + cancelReason.description;

				const cancelReasonType: string =
					StringHelper.beforeCapitalSpaces(
						cancelReason.cancelReasonType);

				cancelReason.detailedDescription =
					cancelReasonType + description;
			});

		this.context.source.addOrUpdateStepData(
			{
				availableReasons: availableReasons,
				selectedReasons: selectedReasons,
				statusEntityId: this.entityInstanceId,
				statusEntityTypeGroup: this.entityType.group,
				isCalculatedEffectiveDate: true
			});

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * This will validate if the selected cancel reasons has the same cancel
	 * reason type.
	 *
	 * @async
	 * @returns {boolean}
	 * True if all the selected cancel reasons has the same cancel reason type,
	 * otherwise will return false.
	 * @memberof TransactionCancelReasonsComponent
	 */
	public hasValidSelectedReasons(): boolean
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData;

		if (currentData.data.selectedReasons.length === 0)
		{
			return false;
		}

		const cancelReasons: any[] =
			currentData.data.selectedReasons;

		const uniqueObject =
			[
				...new Set(cancelReasons.map((cancelReason: any) =>
					cancelReason.cancelReasonType))
			];

		const result: boolean =
			uniqueObject.length === 1;

		const activeWizardStep: IWizardStep =
			this.context.source.activeWizardStep;

		activeWizardStep.nextTooltipLabel =
			result === false
				? 'You can only add multiple reasons of the same '
					+ 'cancel reason type'
				: null;

		return result;
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * An awaitable promise that returns a value signifying whether or not
	 * the wizard step is valid for display.
	 * @memberof TransactionCancelReasonsComponent
	 */
	public async isWizardStepValidForDisplay(): Promise<boolean>
	{
		const policy: IEntityInstance =
			await this.insuranceService.getPolicyByPolicyTermId(
				this.policyTermEntityInstance.id);

		if (AnyHelper.isNull(policy))
		{
			return false;
		}

		const activePolicyTerm: IEntityInstance =
			await this.insuranceService.getActivePolicyTerm(
				policy.id);

		const latestTransaction: IEntityInstance =
			await this.insuranceService
				.getLatestPolicyTermTransactionByPolicyTerm(
					activePolicyTerm.id);

		const result: boolean =
			!(this.policyTermEntityInstance.data.status !==
				InsuranceConstants.policyTermStatusTypes.active
					|| activePolicyTerm.data.renewalTerm >
						this.policyTermEntityInstance.data.renewalTerm
					|| latestTransaction.data.type ===
						InsuranceConstants.transactionTypes.cancellation
					|| (latestTransaction.data.status !==
						InsuranceConstants.transactionStatusTypes.issued
						&& latestTransaction.data.status !==
							InsuranceConstants.transactionStatusTypes.declined)
					|| latestTransaction.data.effectiveDate >
						DateHelper.getSystemDateTime());

		return result;
	}
}