/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	EntityFormComponent
} from '@entity/components/entity-form/entity-form.component';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';

/**
 * A class representing the action to save an entity instance from the page
 * context.
 *
 * @export
 * @class SaveEntityAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class SaveEntityAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a SaveEntityAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof SaveEntityAction
	 */
	public constructor(
		protected activityService: ActivityService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof SaveEntityAction
	 */
	public operationName: string =
		'Save';

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof SaveEntityAction
	 */
	public async execute(): Promise<void>
	{
		if (!(this.pageContext.source instanceof EntityInstanceComponent))
		{
			throw new Error(
				'This save entity source has not been implemented.');
		}

		const entityFormComponent: EntityFormComponent =
			this.pageContext.source.entityFormComponent;

		if (entityFormComponent.saving === true)
		{
			return;
		}

		entityFormComponent.saving = true;

		DocumentHelper.setElementDisplay(
			`${AppConstants.cssClasses.pinnedDrawerOverlay}`
				+ `${AppConstants.cssClasses.productSettingsOverlay}`,
			true);

		entityFormComponent.entityInstanceApiService.entityInstanceTypeGroup =
			entityFormComponent.entityType.group;
		await this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, reject: any) =>
				{
					try
					{
						await entityFormComponent.entityInstanceApiService
							.update(
								entityFormComponent.entityInstance.id,
								entityFormComponent.entityInstance);

						resolve();
					}
					catch (exception)
					{
						reject(exception);
					}
				}),
				`<strong>Saving</strong> ${entityFormComponent.entityType
					.displayName}`,
				`<strong>Saved</strong> ${entityFormComponent.entityType
					.displayName}`,
				`${entityFormComponent.entityIdentifier} was saved.`,
				`${entityFormComponent.entityIdentifier} was not saved.`));

		entityFormComponent.saving = false;
	}
}