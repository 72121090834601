/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';

/* eslint-enable max-len */

/**
 * A class representing the available methods (business logic) for a
 * display component instance.
 *
 * @export
 * @class DisplayComponentDefinition
 * @implements {DisplayComponentDefinition}
 */
export class DisplayComponentDefinition
implements IDisplayComponentDefinition
{
	/**
	 * Creates an instance of a display component instance.
	 *
	 * @param {DisplayComponentDefinition} iDisplayComponentDefinition
	 * The display component definition interface to create this
	 * new object from.
	 * @memberof DisplayComponentDefinition
	 */
	public constructor(
		public iDisplayComponentDefinition: IDisplayComponentDefinition)
	{
		Object.assign(this, iDisplayComponentDefinition);
	}

	/**
	 * Gets or sets the component definition.
	 * This is an ivy creatable component.
	 *
	 * @type {string}
	 * @memberof DisplayComponentDefinition
	 */
	public componentDefinition: string;

	/**
	 * Gets or sets the component name.
	 * This is a display or generic component.
	 *
	 * @type {string}
	 * @memberof DisplayComponentDefinition
	 */
	public componentName: string;

	/**
	 * Gets or sets the id.
	 *
	 * @type {number}
	 * @memberof DisplayComponentDefinition
	 */
	public id: number;

	/**
	 * Gets or sets the json data.
	 * This is the required configuration schema.
	 *
	 * @type {string}
	 * @memberof DisplayComponentDefinition
	 */
	public jsonData: string;

	/**
	 * Gets or sets the display component type id.
	 *
	 * @type {number}
	 * @memberof DisplayComponentDefinition
	 */
	public typeId: number;

	/**
	* Gets or sets the ownership public or read allowed value.
	*
	* @type {boolean}
	* @memberof DisplayComponentDefinition
	*/
	public public: boolean;

	/**
	* Gets or sets the ownership security group or alter allowed value.
	*
	* @type {number}
	* @memberof DisplayComponentDefinition
	*/
	public ownershipSecurityGroupId: number;

	/**
	* Gets or sets the created by id. This user will have read and alter
	* permissions.
	*
	* @type {number}
	* @memberof DisplayComponentDefinition
	*/
	public createdById: number;

	/**
	 * Returns the definition json data object.
	 *
	 * @type {any[] | any}
	 * @memberof DisplayComponentDefinition
	 */
	public get jsonDefinition(): any[] | any
	{
		return this.parsedJson[
			this.definitionKey];
	}

	/**
	 * Returns the interpolation json data object.
	 *
	 * @type {any}
	 * @memberof DisplayComponentDefinition
	 */
	public get jsonInterpolationSchema(): any
	{
		return this.parsedJson[
			this.interpolationSchemaKey];
	}

	/**
	 * Returns the parsed json object from the display component
	 * instance json data.
	 *
	 * @type {any}
	 * The json parsed json data object.
	 * @memberof DisplayComponentDefinition
	 */
	public get parsedJson(): any
	{
		return JSON.parse(this.jsonData);
	}

	/**
	 * Gets the key used to find the definition data
	 * from the display component instance json.
	 *
	 * @type {string}
	 * @memberof DisplayComponentDefinition
	 */
	private readonly definitionKey: string = 'definition';

	/**
	 * Gets the key used to find the interpolation schema data
	 * from the display component instance json.
	 *
	 * @type {string}
	 * @memberof DisplayComponentDefinition
	 */
	private readonly interpolationSchemaKey: string =
		'interpolationSchema';

	/**
	 * Sets a value in the definition instance json data.
	 *
	 * @param {string} propertyName
	 * The property to set in the definition instance.
	 * @param {any} newValue
	 * The value to set the property as.
	 * @memberof DisplayComponentDefinition
	 */
	public setDefinitionProperty(
		propertyName: string,
		newValue: any): void
	{
		const updatedJsonData: any =
			this.parsedJson;
		updatedJsonData[this.definitionKey][propertyName] =
			newValue === AppConstants.undefined
				? null
				: newValue;

		this.jsonData = JSON.stringify(updatedJsonData);
	}
}