<div class="list-item-container ui-g ui-g-12"
	*ngIf="forms.length === 0; else formData">
	<span class="panel-sub-title">
		No Data
	</span>
</div>

<ng-template #formData>
	<div class="list-item-container ui-g ui-g-12">
		<div *ngFor="let form of forms">
			<strong>
				{{form.data.name}}
			</strong>
			<ul class="square-bullets">
				<li *ngFor="let item of filterMetadata(form)">
					{{item.key}}: {{item.value}}
				</li>
			</ul>
		</div>
	</div>
</ng-template>