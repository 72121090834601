<ng-template #CurrencyInput
	[ngIf]="field.props.useCurrency === true">
	<p-inputNumber
		[formControl]="formControl"
		[formlyAttributes]="field"
		[inputStyleClass]="inputStyleClass"
		[min]="field.props.min"
		[max]="field.props.max"
		[step]="field.props.multipleOf"
		[showButtons]="field.props.multipleOf != null"
		mode="currency"
		currency="USD"
		locale="en-US"
		(onInput)="onChange($event)"
		(onBlur)="onBlur()">
	</p-inputNumber>
</ng-template>
<ng-template #PercentInput
	[ngIf]="field.props.usePercent === true">
	<input type="text"
		pInputText
		[placeholder]="field.props.placeholder"
		[(ngModel)]="percentDisplay"
		[disabled]="field.props.disabled"
		(keydown)="onPercentKeyDown($event)"
		(keyup)="setPercentDisplay($event)">
</ng-template>
<ng-template #NumberInput
	[ngIf]="(field.props.useCurrency ?? false) == false && (field.props.usePercent ?? false) == false">
	<p-inputNumber
		[formControl]="formControl"
		[formlyAttributes]="field"
		[inputStyleClass]="inputStyleClass"
		mode="decimal"
		[min]="field.props.min"
		[max]="field.props.max"
		[step]="field.props.multipleOf || 1"
		[showButtons]="true"
		[minFractionDigits]="field.props.minFractionDigits || 0"
		[maxFractionDigits]="field.props.maxFractionDigits || 0"
		[useGrouping]="field.props.useGrouping !== false"
		(onInput)="onChange($event)"
		(onBlur)="onBlur()">
	</p-inputNumber>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>