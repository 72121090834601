/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ChangeDetectorRef,
	Directive,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	FieldType
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	MouseEventConstants
} from '@shared/constants/mouse-event.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';

@Directive({
	selector: '[ExtendedCustomControl]'
})

/**
 * A directive representing shared logic for a formly custom control.
 *
 * @export
 * @extends {FieldType}
 * @implements {OnInit}
 * @class ExtendedCustomControlDirective
 */
export class ExtendedCustomControlDirective
	extends FieldType
	implements OnInit
{
	/** Initializes a new instance of the ExtendedCustomControlDirective.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof ExtendedCustomControlDirective
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super();
	}

	/**
	 * Gets or sets the tooltip element reference.
	 *
	 * @type {ElementRef}
	 * @memberof ExtendedCustomControlDirective
	 */
	@ViewChild('Tooltip')
	public tooltip: ElementRef;

	/**
	 * Gets or sets the input style class value.
	 *
	 * @type {string}
	 * @memberof ExtendedCustomControlDirective
	 */
	public inputStyleClass: string = AppConstants.empty;

	/**
	 * On component initialization event.
	 * This method is used to extend the update value and validity method
	 * of this form control to include change detection.
	 * @note If overriding this in an implementing component, super.ngOnInit()
	 * should be called at the end of the on initialization override.
	 * @memberof ExtendedCustomControlDirective
	 */
	public ngOnInit(): void
	{
		FormlyHelper.extendUpdateValueAndValidity(
			this.field.formControl,
			this.updateCustomDisplay.bind(this));

		if (!AnyHelper.isNull(this.field.props?.default)
			&& AnyHelper.isNull(this.field.formControl.value))
		{
			setTimeout(
				() =>
				{
					this.field.formControl.setValue(
						this.field.props.default);

					this.validateControl();

					if (!AnyHelper.isNullOrWhitespace(
						this.field.props.change))
					{
						this.field.props.change(
							this.field,
							{});
					}
				});

			return;
		}

		this.validateControl();

	}

	/**
	 * Validates the existing control with a set as touched and a call to update
	 * value and validity.
	 *
	 * @memberof ExtendedCustomControlDirective
	 */
	public validateControl(): void
	{
		this.field.formControl.markAsTouched();
		this.field.formControl.updateValueAndValidity();

		this.fireChanges();
	}

	/**
	 * Updates and sets the input style class to be used in this control for
	 * invalid control decorations.
	 *
	 * @memberof ExtendedCustomControlDirective
	 */
	public updateCustomDisplay(): void
	{
		this.inputStyleClass =
			this.field.formControl.status ===
				FormlyConstants.controlStatus.invalid
				? AppConstants.cssClasses.invalid
				: AppConstants.empty;

		this.fireChanges();
	}

	/**
	 * Fires a change detection cycle allowing the form to broadcast changes
	 * to the listening component.
	 * @note This can likely be removed with an upgraded Formly as this is
	 * a workaround to the view not aligning to the state of the formcontrol.
	 * This was required starting with Formly v6.0.0-next.1.
	 *
	 * @memberof ExtendedCustomControlDirective
	 */
	public fireChanges(): void
	{
		this.changeDetector.detectChanges();
	}

	/**
	 * This will handle the tap mobile only event on the tooltip icon
	 * and toggle the display of the tooltip.
	 *
	 * @memberof ExtendedCustomControlDirective
	 */
	public mobileTooltipToggle(): void
	{
		this.tooltip.nativeElement.dispatchEvent(
			new Event(MouseEventConstants.mouseEnter));
	}

	/**
	 * This method will remove the auto focus click event attached to
	 * primeNg tooltips.
	 *
	 * @param {MouseEvent} event
	 * The click event to be captured and halted.
	 * @memberof ExtendedCustomControlDirective
	 */
	public preventDefault(
		event: MouseEvent): void
	{
		event.preventDefault();
		event.stopImmediatePropagation();
	}
}