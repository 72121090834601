/**
 * @copyright WaterStreet. All rights reserved.
*/

/**
 * An enum for designating the response type from
 * an http request.
 * @see HttpClient
 */
export enum ResponseType
{
	/**
	 * Used when the body is expected to be an ArrayBuffer.
	 *
	 * @type {string}
	 * @memberof ResponseType
	 */
	ArrayBuffer = 'arraybuffer',

	/**
	 * Used when the body is expected to be a Blob.
	 *
	 * @type {string}
	 * @memberof ResponseType
	 */
	Blob = 'blob',

	/**
	 * Used when the body is expected to be an object.
	 *
	 * @type {string}
	 * @memberof ResponseType
	 */
	Json = 'json',

	/**
	 * Used when the body is expected to be a string.
	 *
	 * @type {string}
	 * @memberof ResponseType
	 */
	Text = 'text'
}