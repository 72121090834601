/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ModuleService
} from '@shared/services/module.service';

@Component({
	selector: 'make-payment-receipt',
	templateUrl: './make-payment-receipt.component.html'
})

/**
 * A component representing a wizard step for displaying payment results.
 *
 * @export
 * @class MakePaymentReceiptComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class MakePaymentReceiptComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the Make Payment Receipt component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {InsuranceService} insuranceService
	 * The service used to get Insurance related entities.
	 * @memberof MakePaymentReceiptComponent
	 */
	public constructor(
		public router: Router,
		public moduleService: ModuleService,
		public insuranceService: InsuranceService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, IWizardContext>}
	 * @memberof MakePaymentReceiptComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof MakePaymentReceiptComponent
	 */
	public currentData: any;

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof MakePaymentReceiptComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context
				.source
				.activeMenuItem
				.currentData
				.data;

		this.currentData.insured =
			await this.getInsured();

		this.context.source.addToNext(
			this.navigateToPolicyTermDashboard
				.bind(
					this,
					this.currentData.id,
					this.currentData.entityTypeGroup));

		this.context.source.activeWizardStep.backLabel = null;

		this.context.source.activeWizardStep.alwaysVerify = true;
		this.context.source.wizardStepLoading = false;
		this.context.source.changeDetectorReference.detectChanges();

		await this.context.source.validStepChanged(true);
	}

	/**
	 * Gets the Named Insured for the current Policy Term.
	 *
	 * @async
	 * @memberof MakePaymentReceiptComponent
	 */
	private async getInsured(): Promise<any>
	{
		const policyTermTransaction: IEntityInstance =
			await this.insuranceService
				.getLatestPolicyTermTransactionByPolicyTerm(
					this.currentData.id);

		const insured: any =
			policyTermTransaction
				.data
				.interests
				.find(
					(interest: any) =>
						interest.type
							=== InsuranceConstants
								.interestTypes
								.namedInsured
							&& interest.subType
								=== InsuranceConstants
									.interestSubTypes
									.primary)
				?.characteristics;

		return insured;
	}

	/**
	 * This will navigate to the policy term dashboard using the policy term id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The policy term entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof MakePaymentReceiptComponent
	 */
	private async navigateToPolicyTermDashboard(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}