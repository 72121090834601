/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	HostListener,
	OnInit
} from '@angular/core';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	OperationEventParameterConstants
} from '@operation/shared/operation-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WorkItemConstants
} from '@shared/constants/work-item-constants';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

@Component({
	selector: 'app-product-settings',
	templateUrl: './product-settings.component.html'
})

/**
 * A component representing context level product settings.
 *
 * @export
 * @class ProductSettingsComponent
 * @extends {DrawerListDirective<IEntityInstance>}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class ProductSettingsComponent
	extends DrawerListDirective<IEntityInstance>
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Gets or sets the selected item index.
	 *
	 * @type {number}
	 * @memberof ProductSettingsComponent
	 */
	public selectedItemIndex: number = null;

	/**
	 * Gets or sets the child filter.
	 *
	 * @type {string}
	 * @memberof ProductSettingsComponent
	 */
	public override wildcardChildFilter: string =
		InsuranceConstants.insuranceEntityTypes.product;

	/**
	 * Gets or sets the value indicating if the component is still loading.
	 *
	 * @type {boolean}
	 * @memberof ProductSettingsComponent
	 */
	public loading: boolean = true;

	/**
	 * Handles the reload product settings event.
	 *
	 * @memberof ProductSettingsComponent
	 */
	@HostListener(
		AppEventConstants.reloadProductSettingsEvent,
		[OperationEventParameterConstants.event])
	public reloadProductSettings(): void
	{
		DocumentHelper.setElementDisplay(
			`${AppConstants.cssClasses.pinnedDrawerOverlay}`
				+ `${AppConstants.cssClasses.productSettingsOverlay}`,
			true);

		this.changeDisplayMode(AppConstants.empty);
		setTimeout(
			() =>
			{
				this.changeDisplayMode(AppConstants.displayMode.list);
			});
	}

	 /**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @memberof ProductSettingsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		const entityInstanceComponent: EntityInstanceComponent =
			 <EntityInstanceComponent>this.context.source;

		 await this.setSecurityDefinitions(
			entityInstanceComponent.id,
			entityInstanceComponent.entityType.group,
			entityInstanceComponent.entityDefinition,
			entityInstanceComponent.entityInstanceApiService,
			entityInstanceComponent.entityTypeApiService);

		this.initialCreateData =
			{
				type:
					entityInstanceComponent
						.entityType.name,
				references: [
					{
						type: WorkItemConstants.workItemIdentifiers
							.parentNavigationEntityIdentifer,
						identifier:
							entityInstanceComponent
								.entityInstance.id
					}
				]
			};

		this.context.data =
			{
				...this.context.data,
				drawerComponent: this
			};

		this.loading = false;
	}
}