/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */

const MILLISECONDS_IN_A_SECOND: number = 1000;
const SECONDS_IN_A_MINUTE: number = 60;
const MINUTES_IN_AN_HOUR: number = 60;
const HOURS_IN_A_DAY: number = 24;
const DAYS_IN_A_WEEK: number = 7;

const MILLISECONDS_IN_A_MINUTE = MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE;
const MILLISECONDS_IN_AN_HOUR = MILLISECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR;
const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_AN_HOUR * HOURS_IN_A_DAY;
const MILLISECONDS_IN_A_WEEK = MILLISECONDS_IN_A_DAY * DAYS_IN_A_WEEK;

/**
 * A class containing definitions for an application
 * level time span.
 *
 * @export
 * @class AppTimeSpan
 */
export class AppTimeSpan
{
	/**
	 * Creates an instance of AppTimeSpan.
	 *
	 * @memberof AppTimeSpan
	 */
	public constructor(
		input: number | any)
	{
		this._seconds = 0;
		this._minutes = 0;
		this._hours = 0;
		this._days = 0;

		if (typeof input === 'number')
		{
			this.milliseconds = input;
		}

		if (typeof input === 'string')
		{
			// Parse string and populate
			this.milliseconds
				= AppTimeSpan.Parse(input).totalMilliSeconds;
		}
	}

	/**
	 * Gets the day value of the current
	 * application timespan.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	 public get days(): number
	 {
		 return this._days;
	 }

	/**
	 * Sets the day value of the current
	 * application timespan.
	 *
	 * @param {number} value
	 * The value to set.
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public set days(
		value: number)
	{
		this._days =
			isNaN(value)
				? 0
				: value;
		this.calcMilliSeconds();
	}

	/**
	 * Gets the hour value of the current
	 * application timespan.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public get hours(): number
	{
		return this._hours;
	}

	/**
	 * Sets the hour value of the current
	 * application timespan.
	 *
	 * @param {number} value
	 * The value to set.
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public set hours(
		value: number)
	{
		this._hours =
			isNaN(value)
				? 0
				: value;
		this.calcMilliSeconds();
	}

	/**
	 * Gets the minutes value of the current
	 * application timespan.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public get minutes(): number
	{
		return this._minutes;
	}

	/**
	 * Sets the minute value of the current
	 * application timespan.
	 *
	 * @param {number} value
	 * The value to set.
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public set minutes(
		value: number)
	{
		this._minutes =
			isNaN(value)
				? 0
				: value;
		this.calcMilliSeconds();
	}

	/**
	 * Gets the seconds value of the current
	 * application timespan.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public get seconds(): number
	{
		return this._seconds;
	}

	/**
	 * Sets the seconds value of the current
	 * application timespan.
	 *
	 * @param {number} value
	 * The value to set.
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public set seconds(
		value: number)
	{
		this._seconds =
			isNaN(value)
				? 0
				: value;
		this.calcMilliSeconds();
	}

	/**
	 * Gets the milliseconds value of the current
	 * application timespan.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public get milliseconds(): number
	{
		return this._milliseconds;
	}

	/**
	 * Sets the milliseconds value of the current
	 * application timespan.
	 *
	 * @param {number} value
	 * The value to set.
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public set milliseconds(
		value: number)
	{
		this._milliseconds =
			isNaN(value)
				? 0
				: value;
		this.calcMilliSeconds();
	}

	/**
	 * Gets the total milliseconds value of the current
	 * application timespan.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public get totalMilliSeconds(): number
	{
		return this._totalMilliSeconds;
	}

	/**
	 * Gets the total seconds value of the current
	 * application timespan.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public get totalSeconds(): number
	{
		return Math.round(this._totalMilliSeconds / MILLISECONDS_IN_A_SECOND);
	}

	/**
	 * Gets the total minutes value of the current
	 * application timespan.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public get totalMinutes(): number
	{
		return Math.round(this._totalMilliSeconds / MILLISECONDS_IN_A_MINUTE);
	}

	/**
	 * Gets the total hours value of the current
	 * application timespan.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	public get totalHours(): number
	{
		return Math.round(this._totalMilliSeconds / MILLISECONDS_IN_AN_HOUR);
	}

	/**
	 * Gets or sets the milliseconds.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	private _milliseconds: number;

	/**
	 * Gets or sets the total milliseconds.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	private _totalMilliSeconds: number;

	/**
	 * Gets or sets the seconds.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	private _seconds: number;

	/**
	 * Gets or sets the minutes.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	private _minutes: number;

	/**
	 * Gets or sets the hours.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	private _hours: number;

	/**
	 * Gets or sets the days.
	 *
	 * @type {number}
	 * @memberof AppTimeSpan
	 */
	private _days: number;

	/**
	 * Parses a string into an application time span.
	 * @see
	 * Input regular expression expected:
	 * new RegExp('^((?:-?0*\d+\.)?(?:0*)(?:2[0-3]|1[0-9]|[0-9]))'
			+ '(?::0*([0-5]?[0-9]))?(?::0*((?:[0-5]?[0-9])(?:\.\d{0,7})?))?$');
	 *
	 * @param {string} input
	 * The string to be parsed into an application time span.
	 * @returns {AppTimeSpan}
	 * The parsed application time span.
	 * @memberof AppTimeSpan
	 */
	public static Parse(
		input: string): AppTimeSpan
	{
		const timeSpan: AppTimeSpan = new AppTimeSpan(0);
		const bigParts = input.split('.');
		for (let i = 0; i < bigParts.length; i++)
		{
			const smallPart = bigParts[i];
			if (smallPart.indexOf(':') >= 0)
			{
				const parts = smallPart.split(':');

				timeSpan.hours = Number(parts[0] || 0);
				timeSpan.minutes = Number(parts[1] || 0);
				timeSpan.seconds = Number(parts[2] || 0);
			}
			else
			{
				if (i === 0)
				{
					// Days
					timeSpan.days = Number(smallPart || 0);
				}
				else if (i === 2)
				{
					// Milliseconds
					timeSpan.milliseconds = Number(smallPart || 0);
				}
			}
		}

		return timeSpan;
	}

	/**
	 * Finds the differences in time between the two
	 * comparison dates.
	 *
	 * @param {any} date1
	 * The first comparison date.
	 * @param {any} date2
	 * The second comparison date.
	 * @returns {AppTimeSpan}
	 * The time span describing the difference of these two dates.
	 * @memberof AppTimeSpan
	 */
	public static Subtract(
		date1: any,
		date2: any): AppTimeSpan
	{
		const milliSeconds: number = date1 - date2;

		return new AppTimeSpan(milliSeconds);
	}

	/**
	 * Returns the day component of the current
	 * application time span.
	 *
	 * @returns {AppTimeSpan}
	 * The day value of the current time span.
	 * @memberof AppTimeSpan
	 */
	public static Day(): AppTimeSpan
	{
		return new AppTimeSpan(MILLISECONDS_IN_A_DAY);
	}

	/**
	 * Returns the hour component of the current
	 * application time span.
	 *
	 * @returns {AppTimeSpan}
	 * The hour value of the current time span.
	 * @memberof AppTimeSpan
	 */
	public static Hour(): AppTimeSpan
	{
		return new AppTimeSpan(MILLISECONDS_IN_AN_HOUR);
	}

	/**
	 * Returns the week component of the current
	 * application time span.
	 *
	 * @returns {AppTimeSpan}
	 * The week value of the current time span.
	 * @memberof AppTimeSpan
	 */
	public static Week(): AppTimeSpan
	{
		return new AppTimeSpan(MILLISECONDS_IN_A_WEEK) ;
	}

	/**
	 * Returns the month component of the current
	 * application time span.
	 *
	 * @returns {AppTimeSpan}
	 * The month value of the current time span.
	 * @memberof AppTimeSpan
	 */
	public static Month(): AppTimeSpan
	{
		const now: any = new Date();
		const aMonthAgo: any = new Date();
		aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);

		return new AppTimeSpan(now - aMonthAgo);
	}

	/**
	 * Returns the string representation of the current
	 * application time span.
	 *
	 * @returns {string}
	 * The string representation of the current
	 * application time span.
	 * @memberof AppTimeSpan
	 */
	public toString(): string
	{
		return `${this.days}`
			+ `.${this.hours}`
			+ `:${this.minutes}`
			+ `:${this.seconds}`
			+ `.${this.milliseconds}`;
	}

	/**
	 * Adds the sent date value to the current
	 * application time span.
	 *
	 * @param {Date} date
	 * The date to add to current application time span.
	 * @returns {Date}
	 * The date representation of the current
	 * application time span after the date is added.
	 * @memberof AppTimeSpan
	 */
	public addTo(
		date: Date): Date
	{
		date.setMilliseconds(
			date.getMilliseconds() + this.totalMilliSeconds);

		return date;
	}

	/**
	 * Subtracts the sent date value from the current
	 * application time span.
	 *
	 * @param {Date} date
	 * The date to subtract from current application time span.
	 * @returns {Date}
	 * The date representation of the current
	 * application time span after the date is subtracted.
	 * @memberof AppTimeSpan
	 */
	public subtractFrom(
		date: Date): Date
	{
		date.setMilliseconds(
			date.getMilliseconds() - this.totalMilliSeconds);

		return date;
	}

	/**
	 * Rounds the send original value up to the
	 * maximum value provided.
	 *
	 * @param {number} originalValue
	 * The original value to be rounded.
	 * @param {number} maximumValue
	 * The maximum value to round to.
	 * @returns {any}
	 * The round values used to round an applicaiton time span.
	 * @memberof AppTimeSpan
	 */
	public roundValue(
		originalValue: number,
		maximumValue: number): any
	{
		return {
			modulu: originalValue % maximumValue,
			addition: Math.round(originalValue / maximumValue)
		};
	}

	/**
	 * Calculates the number of milliseconds in the
	 * current application time span.
	 *
	 * @memberof AppTimeSpan
	 */
	public calcMilliSeconds(): void
	{
		const newMilliSecond
			= this.roundValue(
				this._milliseconds,
				MILLISECONDS_IN_A_SECOND);

		this._milliseconds = newMilliSecond.modulu;
		this._seconds += newMilliSecond.addition;

		const newSecond
			= this.roundValue(
				this._seconds,
				SECONDS_IN_A_MINUTE);

		this._seconds = newSecond.modulu;
		this._minutes += newSecond.addition;

		const newminutes
			= this.roundValue(
				this._minutes,
				MINUTES_IN_AN_HOUR);

		this._minutes = newminutes.modulu;
		this._hours += newminutes.addition;

		const newDays
			= this.roundValue(
				this._hours,
				HOURS_IN_A_DAY);

		this._hours = newDays.modulu;
		this._days += newDays.addition;

		this._totalMilliSeconds
			= this.days
				* MILLISECONDS_IN_A_DAY
				+ this.hours * MILLISECONDS_IN_AN_HOUR
				+ this.minutes
				* MILLISECONDS_IN_A_MINUTE
				+ this.seconds
				* MILLISECONDS_IN_A_SECOND
				+ this.milliseconds;
	}
}