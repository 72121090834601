/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	FieldType
} from '@ngx-formly/core';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	Guid
} from 'guid-typescript';

@Component({
	selector: 'custom-resource-identifier',
	templateUrl: './custom-resource-identifier.component.html'
})

/**
 * A component representing an instance of a Custom Resource Identifier.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomResourceIdentifierComponent
 * @extends {FieldType}
 * @implements {OnInit}
 */
export class CustomResourceIdentifierComponent
	extends FieldType
	implements OnInit
{
	/**
	 * On component initialization event.
	 * This method is used to set a resource identifier value if it is currently
	 * undefined in the model.
	 *
	 * @memberof CustomResourceIdentifierComponent
	 */
	public ngOnInit(): void
	{
		if (AnyHelper.isNullOrWhitespace(
			this.field.formControl.value))
		{
			this.field.formControl.setValue(
				Guid.raw());
		}
	}
}