/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	EntityManagerDirective
} from '@admin/directives/entity-manager.directive';
import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	ActivatedRoute,
	Params
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	SecurityAccessPolicyDefinitionApiService
} from '@api/services/security/security-access-policy-definition.api.service';
import {
	SecurityAccessPolicyApiService
} from '@api/services/security/security-access-policy.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	ISecurityAccessPolicyDefinition
} from '@shared/interfaces/security/security-access-policy-definition.interface';
import {
	ISecurityAccessPolicy
} from '@shared/interfaces/security/security-access-policy.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-entity-access-policy-definition',
	templateUrl: './entity-access-policy-definition.component.html',
	styleUrls: [
		'./entity-access-policy-definition.component.scss'
	]
})

/**
 * A component representing an instance of the entity access policy definition
 * component.
 *
 * @export
 * @class EntityAccessPolicyDefinitionComponent
 * @extends {EntityManagerDirective}
 */
export class EntityAccessPolicyDefinitionComponent
	extends EntityManagerDirective
{
	/**
	 * Creates an instance of an EntityAccessPolicyDefinitionComponent.
	 *
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {securityAccessPolicyDefinitionApiService}
	 * securityAccessPolicyDefinitionApiService
	 * The api service used to get security access policy definition data.
	 * @param {securityAccessPolicyApiService}
	 * SecurityAccessPolicyDefinitionApiService
	 * The api service used to get security access policy data.
	 * @param {SiteLayoutService} siteLayoutService
	 * The service used to get the site layout data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityAccessPolicyDefinitionComponent
	 */
	public constructor(
		public entityTypeApiService: EntityTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityVersionApiService: EntityVersionApiService,
		public securityAccessPolicyDefinitionApiService:
			SecurityAccessPolicyDefinitionApiService,
		public securityAccessPolicyApiService:
			SecurityAccessPolicyApiService,
		public siteLayoutService: SiteLayoutService,
		public route: ActivatedRoute,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(
			route,
			activityService,
			resolver);
	}

	/**
	 * Gets or sets the access policy definition.
	 *
	 * @type {ISecurityAccessPolicyDefinition}
	 * @memberof EntityAccessPolicyDefinitionComponent
	 */
	public accessPolicyDefinition: ISecurityAccessPolicyDefinition;

	/**
	 * Gets or sets the access policy id.
	 *
	 * @type {number}
	 * @memberof EntityAccessPolicyDefinitionComponent
	 */
	public accessPolicyId: number;

	/**
	 * Gets or sets the access policy.
	 *
	 * @type {ISecurityAccessPolicy}
	 * @memberof EntityAccessPolicyDefinitionComponent
	 */
	public accessPolicy: ISecurityAccessPolicy;

	/**
	 * Gets the page context sent to associated context utilities
	 * and menus.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof EntityAccessPolicyDefinitionComponent
	 */
	 public get pageContext(): IDynamicComponentContext<Component, any>
	 {
		const pageContext: IDynamicComponentContext<Component, any> =
			<IDynamicComponentContext<Component, any>>
			 {
				 source: this,
				 data: null
			 };

		 return pageContext;
	 }

	/**
	 * Sets the access policy id query parameter.
	 *
	 * @type {string}
	 * @memberof EntityAccessPolicyDefinitionComponent
	 */
	private readonly accessPolicyIdQueryParameter: string =
		AppConstants.commonProperties.accessPolicyId;

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityAccessPolicyDefinitionComponent
	 */
	public async setContextData(): Promise<void>
	{
		this.subscriptions.add(
			this.route.queryParams.subscribe((parameters: Params) =>
			{
				const mappedRouteData: any =
						ObjectHelper.mapFromRouteData(
							parameters);

				this.accessPolicyId =
					mappedRouteData[this.accessPolicyIdQueryParameter];
			}));

		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.entityVersion =
			await this.entityVersionApiService
				.get(this.entityDefinition.versionId);

		this.accessPolicyDefinition =
			await this.securityAccessPolicyDefinitionApiService
				.getSingleQueryResult(
					`AccessPolicyId eq ${this.accessPolicyId}`
						+ ' and EntityVersionId eq '
						+ `${this.entityDefinition.versionId}`,
					AppConstants.empty);

		this.accessPolicy =
			await this.securityAccessPolicyApiService
				.get(this.accessPolicyId);

		this.contextData =
			{
				id: this.accessPolicyDefinition.id,
				definition: this.accessPolicyDefinition.jsonData
			};

		this.saveTitle = 'Access Policy Definition';
		this.saveContent = 'Access Policy Definition'
			+ ` ${this.accessPolicy.name}`;
	}

	/**
	 * Saves the updated entity data.
	 *
	 * @async
	 * @memberof EntityAccessPolicyDefinitionComponent
	 */
	public async saveAction(): Promise<void>
	{
		const accessPolicyDefinitionDataObject:
			ISecurityAccessPolicyDefinition =
			<ISecurityAccessPolicyDefinition>
			{
				id: this.accessPolicyDefinition.id,
				accessPolicyId: this.accessPolicyDefinition.accessPolicyId,
				entityVersionId: this.accessPolicyDefinition.entityVersionId,
				jsonData: this.contextData.definition,
				createDate: this.accessPolicyDefinition.createDate,
				startDate: this.accessPolicyDefinition.startDate,
				endDate: this.accessPolicyDefinition.endDate
			};

		await this.securityAccessPolicyDefinitionApiService
			.update(
				this.accessPolicyDefinition.id,
				accessPolicyDefinitionDataObject);
	}

	/**
	 * Sets the formly layout fields.
	 *
	 * @async
	 * @memberof EntityAccessPolicyDefinitionComponent
	 */
	public async setLayoutFields(): Promise<void>
	{
		this.layoutFields =
			<FormlyFieldConfig[]>
			[
				{
					key: 'definition',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Definition',
						required: true,
						rows: FormlyConstants.textAreaRowSizes.fullSize
					},
					validators: {
						validDefinition: {
							expression: ((
								control: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.definitionValidator(
									control,
									field)),
							message:
								AppConstants.empty
						}
					},
				}
			];
	}

	/**
	 * Validates the definition is a correct input.
	 *
	 * @param {FormControl} control
	 * The form control to get the input value.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof EntityLayoutDefinitionComponent
	 */
	public definitionValidator(
		control: UntypedFormControl,
		field: FormlyFieldConfig): boolean
	{
		// Checks if entry is an object type.
		try
		{
			JSON.parse(control.value);
		}
		catch
		{
			field.validators.validDefinition.message =
				'Not a valid JSON format.';

			return false;
		}

		return true;
	}
}