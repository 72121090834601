
<ng-template
	[ngIf]="loading === true"
	[ngIfElse]="Content">
	<div class="flex-center">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>
</ng-template>

<ng-template #Content>
	<div class="summary-card-container"
		*ngIf="claimPaymentId === null">
		<div class="ui-g-12 summary-card text-center">
			<div class="summary-card-main-description summary-font-size ellipsis-text">
				No Data
			</div>
		</div>
	</div>
	<div class="summary-card-container"
		*ngIf="claimPaymentId != null">
		<div class="ui-g-12 summary-card text-center">
			<div class="summary-card-main-description summary-font-size ellipsis-text">
				<span>
					Payee Information
				</span>
			</div>
			<div class="section-container">
				<div class="ui-g-12 no-padding">
					<div class="ui-g-12 panel-sub-title ellipsis-text">
						Name: {{payeeName}}
					</div>
				</div>
				<div class="ui-g-12 no-padding">
					<div class="ui-g-12 panel-sub-title ellipsis-text">
						Mailing Address: {{payeeAddress}}
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>