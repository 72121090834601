/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';

/* eslint-enable max-len */

@Component({
	selector: 'final-wizard-layout-step',
	templateUrl: './final-wizard-layout-step.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard for a final step layout.
 *
 * @export
 * @class FinalWizardLayoutStepComponent
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class FinalWizardLayoutStepComponent
implements IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent,IWizardContext>}
	 * @memberof FinalWizardLayoutStepComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Handles the formly changes to set the step validity.
	 *
	 * @param {boolean} valid
	 * The field validity
	 * @memberof FinalWizardLayoutStepComponent
	 */
	public validStepChanged(valid: boolean): void
	{
		this.context.source.validStepChanged(valid);
	}
}