<ng-template [ngIf]="externalLink == null"
	[ngIfElse]="ExternalLink">
	<span *ngIf="!tooltipRedraw"
		clickOutside
		(clickOutside)="deactivateTooltip()"
		[contentDisplayed]="activeTooltip">
		<i #Tooltip
			[id]="tooltipId"
			[pTooltip]="content"
			[escape]="escape"
			[tooltipPosition]="position"
			tooltipStyleClass="{{styleClass}} {{siteLayoutService.contentCssClass}}"
			class="{{iconClass}} tooltip-icon-cursor"
			(click)="handleTooltipClickEvent($event)"
			(tap)="toogleTooltipDisplay($event)">
		</i>
	</span>
	<span #RedrawTooltip *ngIf="tooltipRedraw">
		<i class="{{iconClass}}">
		</i>
	</span>
</ng-template>

<ng-template #ExternalLink>
	<i #Tooltip
		[id]="tooltipId"
		[pTooltip]="content"
		[escape]="escape"
		tooltipPosition="top"
		tooltipStyleClass="label-tooltip"
		class="fa fa-external-link theme-color external-link tooltip-icon-cursor"
		(click)="accessExternalLink()">
	</i>
</ng-template>