<label class="visually-hidden"
	[attr.for]="'AriaInput' + id">
	{{field.props.label}}
</label>
<ng-template
	[ngIf]="loading"
	[ngIfElse]="Loaded">
	<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>
<ng-template #Loaded>
	<p-multiSelect
		[options]="this.dataOptions"
		[formControl]="formControl"
		[formlyAttributes]="field"
		[placeholder]="field.props.placeholder"
		[ariaFilterLabel]="'Filter ' + field.props.label"
		[inputId]="'AriaInput' + id"
		[appendTo]="field.props.appendTo"
		[emptyFilterMessage]="field.props.emptyFilterMessage || 'No results found'"
		filterPlaceHolder="Filter"
		(onFilter)="onFilter($event)"
		(onChange)="change($event)">
	</p-multiSelect>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>