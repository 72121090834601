/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Component,
	Directive,
	HostListener,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	EntityEventConstants
} from '@entity/shared/entity-event.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	OperationEventParameterConstants
} from '@operation/shared/operation-event-parameter.constants';
import {
	OperationEventConstants
} from '@operation/shared/operation-event.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	cloneDeep
} from 'lodash-es';
import {
	Observable,
	Subject,
	debounceTime,
	distinctUntilChanged,
	startWith
} from 'rxjs';

/* eslint-enable max-len */

@Directive({
	selector: '[Account]'
})

/**
 * A directive representing shared logic for a component interacting
 * with account.
 *
 * @export
 * @class AccountDirective
 * @implements {OnInit}
 */
export class AccountDirective
implements OnInit, OnDestroy
{
	/**
	 * Gets or sets the is saving value of this component.
	 *
	 * @type {boolean}
	 * @memberof AccountDirective
	 */
	public saving: boolean = false;

	/**
	 * Gets or sets the formly layout definitions.
	 *
	 * @type {FormlyFieldConfig}
	 * @memberof AccountDirective
	 */
	public formlyLayoutDefinitions: FormlyFieldConfig[];

	/**
	 * Gets or sets the formly layout definitions.
	 *
	 * @type {IEntityInstance}
	 * @memberof AccountDirective
	 */
	public userEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the is loading flag.
	 *
	 * @type {boolean}
	 * @memberof AccountDirective
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the flag that a value has changed in the entity instance
	 * displayed and is not saved.
	 *
	 * @type {boolean}
	 * @memberof AccountDirective
	 */
	public formValuesChanged: boolean = false;

	/**
	 * Gets or sets the observer of form validation changes.
	 *
	 * @type {Subject<boolean>}
	 * @memberof AccountDirective
	 */
	public formValidityChanged: Subject<boolean> = new Subject<boolean>();

	/**
	 * Gets or sets the data for the entity as it exists
	 * in the database.
	 *
	 * @type {IEntityInstance}
	 * @memberof AccountDirective
	 */
	public databaseEntityInstance: IEntityInstance;

	/**
	 * Gets the page context of this component.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof AccountDirective
	 */
	public get pageContext(): IDynamicComponentContext<Component, any>
	{
		return <IDynamicComponentContext<Component, any>>
			{
				source: this,
				data: {}
			};
	}

	/**
	 * Gets or sets the delay for a debounced subject change.
	 *
	 * @type {number}
	 * @memberof AccountDirective
	 */
	protected readonly debounceDelay: number = 25;

	/**
	 * Handles the submit event sent from the angular schema form
	 * or from a save EmitEvent operation action. This updates
	 * the server to match the schema form data.
	 *
	 * @param {object} _event
	 * The event captured by the host listener.
	 * @memberof AccountDirective
	 */
	@HostListener(
		OperationEventConstants.saveEntityEvent,
		[OperationEventParameterConstants.event])
	public async save(_event: object): Promise<void>
	{
		if (this.saving === true)
		{
			return;
		}

		this.saving = true;
		await this.saveActivityAction();
		this.saving = false;
	}

	/**
	 * Handles the before unload event sent from the current window based
	 * on any action that will change the page.
	 *
	 * @memberof AccountDirective
	 * @returns {Observable<boolean> | boolean}
	 * The value that will allow the router to know if the data in this form
	 * is altered or not saved to the database. This implements the
	 * AppCanDeactivateGuard interface.
	 */
	@HostListener(
		WindowEventConstants.beforeUnloadEvent)
	public canDeactivate(): Observable<boolean> | boolean
	{
		return this.saving === false
			&& this.formValuesChanged === false;
	}

	/**
	 * Handles the on initialization event.
	 * This will load and initialize the lookups available for report creation.
	 *
	 * @memberof AccountDirective
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		this.formValidityChanged.pipe(
			startWith(false),
			debounceTime(this.debounceDelay),
			distinctUntilChanged())
			.subscribe((enabled: boolean) =>
			{
				this.emitFormValidityChangedEvent(
					enabled);
			});

		this.formValidityChanged.next(false);
		this.loading = false;
	}

	/**
	 * On component destroy event.
	 * This method is used to complete the debounce on the
	 * form validity changed value.
	 *
	 * @memberof AccountDirective
	 */
	public ngOnDestroy(): void
	{
		this.formValidityChanged.complete();
	}

	/**
	 * Emits the form validity changed event to any listening components.
	 * This event signifies whether this current profile form is valid
	 * or not.
	 *
	 * @param {boolean} enabled
	 * Whether or not save or update actions should be enabled. If they
	 * are enabled, we expect any saves or updates to work without error.
	 * @memberof AccountDirective
	 */
	public emitFormValidityChangedEvent(
		enabled: boolean): void
	{
		window.dispatchEvent(
			new CustomEvent(
				EntityEventConstants.enableEntitySave,
				{
					detail: {
						enabled: enabled
					}
				}
			));
	}

	/**
	 * Sets up the page variables.
	 *
	 * @async
	 * @memberof AccountDirective
	 */
	public async setupPageVariables(): Promise<void>
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets up save activity action.
	 *
	 * @async
	 * @memberof AccountDirective
	 */
	public async saveActivityAction(): Promise<void>
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Captures value changes sent from the dynamic formly component.
	 *
	 * @param {boolean} valueChanged
	 * Whether or not the current form is valid.
	 * @memberof AccountDirective
	 */
	public formValuesChange(
		valueChanged: boolean): void
	{
		this.formValuesChanged = valueChanged;
		this.formValidityChanged.next(this.formValuesChanged);
	}

	/**
	 * Captures validity changes sent from the dynamic formly component.
	 *
	 * @param {boolean} valid
	 * Whether or not the current form is valid.
	 * @memberof AccountDirective
	 */
	public formValidityChange(
		valid: boolean): void
	{
		if (this.formValuesChanged === false)
		{
			return;
		}

		this.formValidityChanged.next(valid);
	}

	/**
	 * This function is used to save the data in the last database loaded
	 * or saved entity instance. This data is then used for comparisons
	 * to the current entity form to validate if page changes are allowed
	 * or similar business rules.
	 *
	 * @memberof AccountDirective
	 */
	public updateLastSavedData(): void
	{
		this.databaseEntityInstance =
			cloneDeep(this.userEntityInstance);
	}

	/**
	 * This function is used to set the backup form validity
	 * to always be as false.
	 *
	 * @memberof AccountDirective
	 */
	public formValidityBackup(): void
	{
		this.emitFormValidityChangedEvent(false);
	}
}