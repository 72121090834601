<div class="ui-g-12 no-padding text-center">
	<div class="panel-sub-title">
		Business Locations
	</div>
	<ul class="layout-menu address-list ui-g-12">
		<ng-template ngFor
			let-location
			[ngForOf]="context.data">
			<li>
				<div class="address-container ui-g-12 no-padding text-left">
					<div class="ui-g-9">
						<div class="address-line-one ellipsis-text">
							{{getAddressLineOne(location)}}
						</div>
						<div class="address-line-two ellipsis-text">
							{{getAddressLineTwo(location)}}
						</div>
					</div>
					<div class="ui-g-3">
						<span class="float-right">
							<button pButton
								title="Copy"
								type="button"
								icon="copy-address-icon fa fa-fw fa-clipboard"
								(click)="copyAddressToClipboard(location)">
							</button>
						</span>
					</div>
				</div>
			</li>
		</ng-template>
	</ul>
</div>