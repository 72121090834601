/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	HttpErrorResponse
} from '@angular/common/http';
import {
	ApiError
} from '@api/errors/api.error';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IApplicationMessage
} from '@shared/interfaces/application-messages/application-message.interface';
import {
	ILogEntry
} from '@shared/interfaces/logs/log-entry.interface';
import {
	LogLevel
} from '@shared/services/logger.service';

/**
 * A class containing static helper methods
 * for handling errors that can be thrown.
 *
 * @export
 * @class ErrorHelper
 */
export class ErrorHelper
{
	/**
	 * Returns a log entry holding extended error details for a common
	 * error interface.
	 *
	 * @async
	 * @static
	 * @param {ApiError | Error | HttpErrorResponse} error
	 * The error being thrown.
	 * @returns {Promise<ILogEntry>}
	 * A promise that will resolve into a log entry ready for generic use.
	 * @memberof ErrorHelper
	 */
	public static async getGenericErrorLogEntry(
		error: ApiError | Error | HttpErrorResponse): Promise<ILogEntry>
	{
		let logEntry: ILogEntry;

		if (error
			&& error.message
			&& error.message.indexOf('"name":"HttpErrorResponse"') !== -1)
		{
			const promiseErrorStart: number =
				error.message.indexOf('{');
			const httpError: HttpErrorResponse =
				<HttpErrorResponse> JSON.parse(
					error.message.substring(
						promiseErrorStart));

			logEntry =
				await this.getHttpErrorResponseLogEntry(httpError);
		}
		else if (error
			&& error.message
			&& error.message
				.indexOf('Uncaught (in promise):') !== -1)
		{
			const errorMessageLines: string[] =
				error.message.split(/\r?\n/);

			const promiseErrorStart: number =
				errorMessageLines[0].indexOf('Error:');
			const promiseErrorEnd: number =
				errorMessageLines[0].length;

			logEntry =
				this.getErrorLogEntry(
					<Error>
					{
						message:
							error.message.substring(
								promiseErrorStart,
								promiseErrorEnd),
						stack:
							error.message.substring(
								promiseErrorEnd)
					}
				);
		}
		else if (error instanceof ApiError)
		{
			logEntry =
				this.getApiErrorLogEntry(error);
		}
		else if (error instanceof HttpErrorResponse)
		{
			logEntry =
				await this.getHttpErrorResponseLogEntry(error);
		}
		else if (error instanceof Error)
		{
			logEntry =
				this.getErrorLogEntry(error);
		}

		return logEntry;
	}

	/**
	 * Gets a mapped ILogEntry with properties from an error of
	 * type ApiError.
	 *
	 * @static
	 * @param {ApiError} error
	 * The error being thrown.
	 * @returns {ILogEntry}
	 * The log entry for this ApiError.
	 * @memberof ErrorHelper
	 */
	private static getApiErrorLogEntry(
		error: ApiError): ILogEntry
	{
		const errorMessages: string =
			error.messages
				.map((applicationMessage) =>
					applicationMessage.description)
				.join(', ');

		return <ILogEntry>
			{
				level: LogLevel[LogLevel.Error],
				message: error.title,
				details: errorMessages,
				exception: errorMessages
			};
	}

	/**
	 * Gets a mapped ILogEntry with properties from an error of
	 * type Error.
	 *
	 * @static
	 * @param {Error} error
	 * The error being thrown.
	 * @returns {ILogEntry}
	 * The log entry for this Error.
	 * @memberof ErrorHelper
	 */
	private static getErrorLogEntry(
		error: Error): ILogEntry
	{
		return <ILogEntry>
			{
				level: LogLevel[LogLevel.Error],
				message: error.message,
				exception: error.stack
			};
	}

	/**
	 * Gets a mapped ILogEntry with properties from an error of
	 * type HttpErrorResponse.
	 *
	 * @async
	 * @static
	 * @param {HttpErrorResponse} error
	 * The error being thrown.
	 * @returns {Promise<ILogEntry>}
	 * The log entry for this HttpErrorResponse.
	 * @memberof ErrorHelper
	 */
	private static async getHttpErrorResponseLogEntry(
		error: HttpErrorResponse): Promise<ILogEntry>
	{
		// Network down messages are objects of type { isTrusted: boolean }
		const errorObjectMessage: string =
			AnyHelper.isNullOrEmpty(error.error.isTrusted)
				? error.error
				: 'Unable to contact the server.';

		const errorMessage: string =
			errorObjectMessage +
				` ${error.status} - ${error.statusText}`;

		const errorDetails: string =
			error.error instanceof Blob
				? JSON.parse(await new Blob([error.error]).text())
					.map(
						(applicationMessage: IApplicationMessage) =>
							this.formatApplicationMessage(
								applicationMessage))
				: error.message;

		return <ILogEntry>
			{
				level: LogLevel[LogLevel.Error],
				message: errorMessage,
				details: errorDetails,
				exception:
					`Name: '${error.name}', `
					+ `Type: '${error.type || ''}', `
					+ `Url: '${error.url}', `
					+ `Status: '${error.status} - ${error.statusText}'`
			};
	}

	/**
	 * Gets an html formatted application message string representation.
	 *
	 * @static
	 * @param {IApplicationMessage} applicationMessage
	 * The application message to be parsed.
	 * @returns {string}
	 * An html formatted string representation for this application message
	 * and child messages.
	 * @memberof ErrorHelper
	 */
	private static formatApplicationMessage(
		applicationMessage: IApplicationMessage): string
	{
		return `${applicationMessage.description}<br/>`
			+ (applicationMessage.messages?.length > 0
				? '<ul>'
					+ applicationMessage.messages
						.map(
							(applicationMessageDetail: IApplicationMessage) =>
								this.formatDetailDescription(
									applicationMessageDetail.description))
						.join(AppConstants.empty)
					+ '</ul>'
				: AppConstants.empty);
	}

	/**
	 * Gets a formatted description for an application messages nested details
	 * application message array item.
	 *
	 * @static
	 * @param {string} description
	 * The application message description to be parsed.
	 * @returns {string}
	 * An html formatted string representation for this application message
	 * description.
	 * @memberof ErrorHelper
	 */
	private static formatDetailDescription(
		description: string): string
	{
		switch (true)
		{
			case description.indexOf('AggregateException') !== -1:
				return '<li>AggregateException: One or more errors '
					+ 'occurred.</li>';
			case description.indexOf('Custom Xml request failure') !== -1:
				return '<li>WebException: Custom Xml request failure.</li>';
		}

		return `<li>${description}</li>`;
	}
}