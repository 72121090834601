<app-base-page pageTitle="Site Settings"
	[pageContext]="pageContext"
	operationGroupName="CommonSaveAction.Operations"
	utilityMenuOperationGroupName=""
	[loading]="loading">
	<div *ngIf="loading"
		class="text-center ui-g-12">
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>
	<div *ngIf="!loading"
		class="ui-g-12 no-padding profile-section">
		<app-dynamic-formly
			[dataSet]="userEntityInstance"
			[initialData]="databaseEntityInstance"
			[layoutSchema]="formlyLayoutDefinitions"
			(valuesChanged)="formValuesChange($event)"
			(validityChanged)="formValidityChange($event)">
		</app-dynamic-formly>
	</div>
</app-base-page>