<app-base-page pageTitle="Edit {{entityType?.name}} {{workflowActionDefinition?.name}} Action"
	[pageContext]="pageContext"
	[loading]="loadingDefinitions"
	pageContextOperationGroupName="AdminEntity.PageContext"
	operationGroupName="CommonSaveAction.Operations"
	utilityMenuOperationGroupName="">

	<div *ngIf="loadingDefinitions"
		class="text-center ui-g-12">
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>

	<div *ngIf="!loadingDefinitions"
		class="ui-g-12 no-padding">
		<app-dynamic-formly
			[dataSet]="contextData"
			[initialData]="databaseData"
			[layoutSchema]="layoutFields"
			(valuesChanged)="formValuesChange($event)"
			(validityChanged)="formValidityChange($event)">
		</app-dynamic-formly>
	</div>

	<div class="ui-g-12 no-padding">
		<div *ngIf="loadingPreRequisiteTableDefinitions && !loadingDefinitions"
			class="text-center ui-g-6"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>

		<div class="ui-g-6 table-container"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}"
			*ngIf="!loadingPreRequisiteTableDefinitions">
			<app-common-table
				[tableDefinitions]="preRequisiteTableDefinitions">
			</app-common-table>
		</div>

		<div *ngIf="loadingPostRequisiteTableDefinitions && !loadingDefinitions"
			class="text-center ui-g-6"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>

		<div class="ui-g-6 table-container"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}"
			*ngIf="!loadingPostRequisiteTableDefinitions">
			<app-common-table
				[tableDefinitions]="postRequisiteTableDefinitions">
			</app-common-table>
		</div>
	</div>

</app-base-page>