/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	HttpClient
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	IEntityInstanceRuleViolationDto
} from '@api/interfaces/entities/entity-instance-rule-violation.dto.interface';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CacheService
} from '@shared/services/cache.service';

/**
 * A class representing the logic and services of the rule presentation
 * definition association controller.
 *
 * @export
 * @class EntityInstanceRuleViolationApiService
 * @extends {BaseEntityApiService<IEntityInstanceRuleViolationDto>}
 */
@Injectable()
export class EntityInstanceRuleViolationApiService
	extends BaseEntityApiService<
		IEntityInstanceRuleViolationDto>
{
	/**
	 * Creates an instance of an EntityInstanceRuleViolationApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof EntityInstanceRuleViolationApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers
				.entityInstanceRuleViolations;
	}

	/**
	 * Creates an entity instance rule violation with the specified information.
	 * This method is not implemented in the API.
	 *
	 * @param {IEntityInstanceRuleViolationDto} _entity
	 * The entity instance rule violation to create.
	 * @returns {Promise<number>}
	 * The newly created item identifier.
	 * @memberof SecuritySessionApiService
	 */
	public async create(
		_entity: IEntityInstanceRuleViolationDto): Promise<number>
	{
		throw new Error(
			this.getNotImplementedMessage(
				AppConstants.apiMethods.create));
	}

	/**
	 * Updates an entity instance rule violation with the specified identifier
	 * and information.
	 *
	 * @param {number} _id
	 * The identifier of the entity instance rule violation to update.
	 * @param {IEntityInstanceRuleViolationDto} _entity
	 * The entity instance rule violation to update.
	 * @returns {Promise<object>}
	 * An observable of the put no-content response.
	 * @memberof BaseEntityApiService
	 */
	public async update(
		_id: number,
		_entity: IEntityInstanceRuleViolationDto): Promise<object>
	{
		throw new Error(
			this.getNotImplementedMessage(
				AppConstants.apiMethods.update));
	}

	/**
	 * Deletes an entity instance rule violation with the specified identifier.
	 * This method is not implemented in the API.
	 *
	 * @param {number} id
	 * The identifier of the entity instance rule violation to delete.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof SecuritySessionApiService
	 */
	public async delete(
		_id: number): Promise<object>
	{
		throw new Error(
			this.getNotImplementedMessage(
				AppConstants.apiMethods.delete));
	}
}