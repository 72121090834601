/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	DateTime
} from 'luxon';

/* eslint-enable max-len */

@Component({
	selector: 'non-renew-policy',
	templateUrl: './non-renew-policy.component.html'
})

/**
 * A component representing a wizard step for binding a policy term transaction.
 *
 * @export
 * @class NonRenewPolicyComponent
 * @extends {StatusReasonsDirective}
 */
export class NonRenewPolicyComponent
	extends StatusReasonsDirective
{
	/**
	 * Gets or sets true/false if the current policy is allowing the non-renew
	 * process.
	 *
	 * @type {boolean}
	 * @memberof NonRenewPolicyComponent
	 */
	public allowedNonRenew: boolean;

	/**
	 * Gets or sets the client message displayed if not allowed to non-renew.
	 * process.
	 *
	 * @type {string}
	 * @memberof NonRenewPolicyComponent
	 */
	public clientMessage: string;

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @memberof NonRenewPolicyComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.allowedNonRenew =
			await this.nonRenewIsAllowed();

		this.workflowActionName =
			InsuranceConstants.workflowActions.policyNonRenew;

		this.context.source.addOrUpdateStepData(
			{
				availableReasons:
					this.getReasons(
						this.productEntityInstance.data.reasons,
						InsuranceConstants.statusReasonTypes.rejection)
						.filter(reason => reason.isNonRenewalReason === true),
				selectedReasons: [],
				comments: AppConstants.empty
			});

		this.context.source.addToNext(this.nonRenew.bind(this));
	}

	/**
	 * This will send the policy non-renew event and navigate to the policy
	 * dashboard.
	 *
	 * @async
	 * @memberof NonRenewPolicyComponent
	 */
	public async nonRenew(): Promise<void>
	{
		await this.policyNonRenew();
		await this.navigateToPolicyDashboard();
	}

	/**
	 * Executes the policy non-renew process by executing the policyNonRenew
	 * workflow action to update the associated Policy entity data with the
	 * collected data from the Non-Renew Wizard.
	 *
	 * @async
	 * @memberof NonRenewPolicyComponent
	 */
	public async policyNonRenew(): Promise<void>
	{
		const displayName: string =
			new EntityType(this.entityType)
				.displayName;
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const selectedReasons: string =
			ObjectArrayHelper.commaSeparatedPropertyValues(
				currentData.selectedReasons,
				AppConstants.commonProperties.id);

		this.entityInstance.data.reasons = [];

		const queryString: string =
			`?comments=${currentData.comments}`
				+ `&reasons=${selectedReasons}`;

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		return this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, _reject: any) =>
				{
					await this.executeWorkflowAction(
						this.entityType,
						this.entityInstance,
						queryString);

					resolve();
				}),
				'<strong>Processing non-renewal</strong>',
				'<strong>Non-renewal processed</strong>',
				`${displayName} has been set to non-renew.`,
				`${displayName} has not been set to non-renew.`));
	}

	/**
	 * This will validate if the non-renew process is allowed by calculating
	 * the expiration date and the allowed days to non-renew the policy.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * True if the non-renew process is allowed, otherwise will return false.
	 * @memberof NonRenewPolicyComponent
	 */
	private async nonRenewIsAllowed(): Promise<boolean>
	{
		if (this.entityInstance.data.isRenewable === false
			|| this.entityInstance.data.status
				!== InsuranceConstants.policyStatusTypes.active)
		{
			this.clientMessage =
				'Non-Renew is not allowed from the current policy state.';

			return false;
		}

		const daysFromExpirationToAllowNonRenew: number =
			this.productEntityInstance.data.daysFromExpirationToAllowNonRenew;

		const policyTermInstance: IEntityInstance =
			await this.insuranceService.getActivePolicyTerm(
				this.entityInstanceId);

		const expirationDate: DateTime =
			DateTime.fromISO(
				policyTermInstance.data.expirationDate);

		const today: DateTime =
			DateHelper.startOf(
				DateTime.local());

		const nonRenewDate: DateTime =
			DateHelper.addTimeUnit(
				expirationDate,
				-daysFromExpirationToAllowNonRenew);

		this.clientMessage =
			'You are unable to Non-Renew this '
				+ 'policy as today\'s date is within '
				+ `${daysFromExpirationToAllowNonRenew} `
				+ 'days of the current term\'s expiration date.';

		return today < nonRenewDate;
	}

	/**
	 * This will navigate to the policy dashboard using the policy term id
	 * provided.
	 *
	 * @async
	 * @memberof NonRenewPolicyComponent
	 */
	private async navigateToPolicyDashboard()
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				this.entityInstanceTypeGroup,
				AppConstants.viewTypes.edit,
				this.entityInstanceId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}