/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DomSanitizer,
	SafeResourceUrl
} from '@angular/platform-browser';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'make-payment-details',
	templateUrl: './make-payment-details.component.html',
	styleUrls: [
		'./make-payment-details.component.scss'
	]
})

/**
 * A component representing the details of the Make Payment and Receipt steps.
 *
 * @export
 * @class MakePaymentDetailsComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class MakePaymentDetailsComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the Make Payment Details component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The service used to customize the UI.
	 * @param {DomSanitizer} sanitizer
	 * The DOM Sanitizer class that prevents script injection.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof MakePaymentDetailsComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public sanitizer: DomSanitizer,
		public entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, IWizardContext>}
	 * @memberof MakePaymentDetailsComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the title of the component.
	 *
	 * @type {string}
	 * @memberof MakePaymentDetailsComponent
	 */
	@Input() public title: string;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof MakePaymentDetailsComponent
	 */
	public currentData: any;

	/**
	 * Gets or sets a boolean value that determines
	 * if the component is loading.
	 *
	 * @type {boolean}
	 * @memberof MakePaymentDetailsComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the processor object that contains
	 * the payment processor data.
	 *
	 * @type {any}
	 * @memberof MakePaymentDetailsComponent
	 */
	public processor: any;

	/**
	 * Gets or sets the object that contains
	 * the payment method settings.
	 *
	 * @type {any}
	 * @memberof MakePaymentDetailsComponent
	 */
	public paymentMethodSettings: any;

	/**
	 * Gets or sets the collection of payment items
	 * that are displayed as the payment details.
	 *
	 * @type {any[]}
	 * @memberof MakePaymentDetailsComponent
	 */
	public paymentItems: any[] = [];

	/**
	 * Gets or sets the total payment amount which includes
	 * the entered payment amount plus any applicable fees.
	 *
	 * @type {number}
	 * @memberof MakePaymentDetailsComponent
	 */
	public totalPaymentAmount: number = 0;

	/**
	 * Gets or sets url of the Processor logo
	 * that is displayed in this component.
	 *
	 * @type {string}
	 * @memberof MakePaymentDetailsComponent
	 */
	public logoUrl: SafeResourceUrl;

	/**
	 * Gets or sets the payment method disclaimer
	 * that will be displayed in this component.
	 *
	 * @type {string}
	 * @memberof MakePaymentDetailsComponent
	 */
	public disclaimer: string;

	/**
	 * Gets or sets the list of fee disclaimers
	 * that will be displayed in this component.
	 *
	 * @type {string[]}
	 * @memberof MakePaymentDetailsComponent
	 */
	public feeDisclaimers: string[];

	/**
	 * Gets or sets the payment method description
	 * that is displayed to the user.
	 *
	 * @type {string}
	 * @memberof MakePaymentDetailsComponent
	 */
	public paymentMethodDescription: string;

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof MakePaymentDetailsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context
				.source
				.activeMenuItem
				.currentData
				.data;

		await this.initializeData();

		this.loading = false;

		this.context.source.wizardStepLoading = false;
		this.context.source.changeDetectorReference.detectChanges();
	}

	/**
	 * Gets CSS class that displays the icon
	 * for the selected payment method type.
	 *
	 * @return {string}
	 * The name of the CSS class.
	 * @memberof MakePaymentDetailsComponent
	 */
	public getPaymentMethodClass(): string
	{
		if (this.currentData.paymentMethod.type
			!== AppConstants.paymentMethodTypes.creditCard)
		{
			return AppConstants.cssClasses.fontAwesomePrefix
				+ AppConstants.cssIcons.bank;
		}

		const cardType: string =
			StringHelper.toCamelCase(
				this.currentData.paymentMethod.cardType);

		return AppConstants.cssClasses.fontAwesomePrefix
			+ (AppConstants.cssIcons[cardType]
				|| AppConstants.cssIcons.creditCard);
	}

	/**
	 * Interpolates a given text by replacing the template fields
	 * with the data from the current context.
	 *
	 * @param {string} value
	 * The string to interpolate.
	 * @return {string}
	 * Returns the interpolated string.
	 * @memberof MakePaymentDetailsComponent
	 */
	public interpolate(
		value: string): string
	{
		if (AnyHelper.isNullOrEmpty(value))
		{
			return value;
		}

		return StringHelper.interpolate(
			value,
			this.context.source.activeMenuItem.currentData.data);
	}

	/**
	 * Initializes the data used in this wizard step.
	 *
	 * @async
	 * @memberof MakePaymentDetailsComponent
	 */
	private async initializeData(): Promise<void>
	{
		const systemSettings: any =
			await this.getSystemSettings();

		const processorName: string =
			StringHelper
				.toCamelCase(
					this.currentData
						.paymentMethod
						.processor);

		this.processor =
			systemSettings[processorName];

		this.paymentMethodSettings =
			systemSettings
				.paymentMethods
				.find(
					(paymentMethod: any) =>
						paymentMethod.processor
							=== this.currentData
								.paymentMethod
								.processor
							&& paymentMethod.type
								=== this.currentData
									.paymentMethod
									.type);

		this.paymentItems = [];
		this.paymentItems
			.push(
				<any>
				{
					description: 'Payment Amount',
					amount: this.currentData.paymentAmount
				});

		this.paymentItems
			.push(
				...this.paymentMethodSettings
					.fees
					.map(
						(fee: any) =>
						<any>
						{
							description: fee.name,
							amount:
								fee.type === AppConstants.feeTypes.percent
									? fee.value * this.currentData.paymentAmount
									: fee.value
						}));

		this.totalPaymentAmount =
			this.paymentItems
				.reduce(
					(partialSum: number, item: any) =>
						partialSum + item.amount,
					0);

		this.logoUrl =
			this.sanitizer.bypassSecurityTrustResourceUrl(
				`data:image/png;base64,${this.processor.logo}`);

		this.disclaimer =
			this.interpolate(
				this.paymentMethodSettings
					.disclaimer);

		this.feeDisclaimers =
			this.paymentMethodSettings
				.fees
				.map(
					(fee: any) =>
						this.interpolate(
							fee.disclaimer));

		this.paymentMethodDescription =
			this.paymentMethodSettings.name
				+ (this.currentData
					.paymentMethod
					.type !== AppConstants.paymentMethodTypes.creditCard
					? ' account'
					: AppConstants.empty);
	}

	/**
	 * Gets the system settings.
	 *
	 * @returns {Promise<any>}
	 * Returns the settings object from the System entity.
	 * @memberof MakePaymentDetailsComponent
	 */
	private async getSystemSettings(): Promise<any>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.systems;

		const systemEntityInstance: IEntityInstance =
			await this.entityInstanceApiService
				.get(
					parseInt(
						AppConstants.systemId,
						AppConstants.parseRadix));

		return systemEntityInstance.data.settings;
	}
}