/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';
import {
	FieldWrapper
} from '@ngx-formly/core';

@Component({
	selector: 'custom-tab-content',
	templateUrl: './custom-tab-content.component.html',
	styleUrls: [
		'./custom-tab-content.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Tab Content Wrapper.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomTabContentComponent
 * @extends {FieldWrapper}
 */
export class CustomTabContentComponent
	extends FieldWrapper
{
}