<ng-template
	[ngIf]="loading">
	<div class="text-center"
		[ngClass]="{
			'radial-gauge-container': radialGaugeChart && !summaryCardDisplay
		}">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>
</ng-template>

<div *ngIf="displayMissingDataMessage && !loading && !radialGaugeChart"
	class="text-center chart-empty-content-message"
	[ngClass]="{
		'smaller-text ui-g-12 no-horizontal-padding': (!summaryCardDisplay),
		'chart-empty-content-message': !radialGaugeChart,
		'radial-gauge-no-data': radialGaugeChart
	}">
	<ng-template [ngIf]="summaryCardDisplay"
		[ngIfElse]="FullMessage">
		No Data
	</ng-template>
	<ng-template #FullMessage>
		<span *ngIf="radialGaugeChart !== true">
			No Chart Data for {{chartConfiguration.options.plugins.title.text}}
		</span>
	</ng-template>
</div>

<div #ChartContainer
	class="chart-container"
	*ngIf="!displayMissingDataMessage && !loading"
	[ngClass]="{
		'summary-card-chart': (summaryCardDisplay && !loading),
		'square-card-chart': (squareCardDisplay && !loading),
		'radial-gauge-chart': (radialGaugeChart && !loading),
		'circular-chart': ((chartConfiguration.type === 'pie' || chartConfiguration.type === 'doughnut') && !loading)
	}">
	<p-chart
		[type]="chartConfiguration.type"
		[data]="chartConfiguration.data"
		[options]="chartConfiguration.options">
	</p-chart>
</div>

<app-color-swatch #ColorSwatch>
</app-color-swatch>