
<div class="ui-g-12">
	<div class="ui-g-6 data-container"
		[ngClass]="{
			'ui-g-12': siteLayoutService.displayTabletView,
			'no-padding': siteLayoutService.displayTabletView
		}">
		<strong>
			Group Details
		</strong>
		<div class="ellipsis-text section-margin">
			<strong class="information-header">
				Name
			</strong>
			<span class="data-format">
				{{context.source.selectedItem.name}}
			</span>
		</div>
		<div>
			<app-dynamic-formly
				[dataSet]="context.source.selectedItem"
				[layoutSchema]="dynamicFormlyLayout"
				(validityChanged)="context.source.validExpandComponentChanged($event)">
			</app-dynamic-formly>
		</div>
	</div>

	<div class="ui-g-6 table-container"
		[ngClass]="{
			'ui-g-12': siteLayoutService.displayTabletView,
			'standard-top-padding': siteLayoutService.displayTabletView
		}">
		<span *ngIf="!loadingTableDefinitions">
			<div *ngIf="context.source.displayMode === 'View'">
				<app-common-table
					[tableDefinitions]="manageUserGroupTableDefinitionsView">
				</app-common-table>
			</div>
			<div *ngIf="context.source.displayMode === 'Update'">
				<app-common-table
					[tableDefinitions]="manageUserGroupTableDefinitionsUpdate">
				</app-common-table>
			</div>
		</span>
		<div *ngIf="loadingTableDefinitions"
			class="text-center ui-g-12">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>
	</div>
</div>