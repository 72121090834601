/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */
@Component({
	selector: 'app-entity-manager-search',
	templateUrl: './entity-manager-search.component.html',
	styleUrls: ['./entity-manager-search.component.scss'],
})

/**
 * A component representing an instance of the system entity manager search
 * component.
 *
 * @export
 * @class EntityManagerSearchComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class EntityManagerSearchComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Initializes a new instance of the EntityManagerSearchComponent class.
	 *
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The api service used to load emtoty definition data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to load entity type data.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The api service used to load entity version data.
	 * @param {Router} router
	 * The service that provides navigation on row clickage.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityManagerSearchComponent
	 */
	public constructor(
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public router: Router,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the search input element.
	 *
	 * @type {ElementRef}
	 * @memberof EntityManagerSearchComponent
	 */
	@ViewChild('SearchInput')
	public searchInputElement: ElementRef;

	/**
	 * Gets or sets the table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityManagerSearchComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the search input value.
	 *
	 * @type {string}
	 * @memberof EntityManagerSearchComponent
	 */
	public searchInputValue: string = AppConstants.empty;

	/**
	 * Gets or sets the table filter.
	 *
	 * @type {string}
	 * @memberof EntityManagerSearchComponent
	 */
	public tableFilter: string = null;

	/**
	 * Gets or sets the full set of entity types.
	 *
	 * @type {IEntityType[]}
	 * @memberof EntityManagerSearchComponent
	 */
	public entityTypes: IEntityType[] = [];

	/**
	 * Gets or sets the full set of entity versions.
	 *
	 * @type {IEntityVersion[]}
	 * @memberof EntityManagerSearchComponent
	 */
	public entityVersions: IEntityVersion[] = [];

	/**
	 * Initializes the entity manager search component by seting up page
	 * variables and table definitions.
	 *
	 * @async
	 * @memberof EntityManagerSearchComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'group',
					columnHeader: 'Group',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'version',
					columnHeader: 'Version',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'enabled',
					columnHeader: 'Enabled',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'startDate',
					columnHeader: 'Start Date',
					displayOrder: displayOrder++,
					dataFormatType: AppConstants.dataFormatTypes.dateTime
				},
				{
					dataKey: 'endDate',
					columnHeader: 'End Date',
					displayOrder: displayOrder,
					dataFormatType: AppConstants.dataFormatTypes.dateTime
				}
			];

		this.selectedColumns = this.availableColumns;

		await this.setupTableDefinitions();
	}

	/**
	 * Handles the Enter key press event to excecution the search accion.
	 *
	 * @param {KeyboardEvent} event
	 * The key press event data object.
	 * @memberof EntityManagerSearchComponent
	 */
	public searchOnEnterKeyPress(event: KeyboardEvent): void
	{
		if (event.key === AppConstants.keyBoardKeyConstants.enter)
		{
			this.searchEntity();
		}
	}

	/**
	 * Applies a filter to search by entity type.
	 * This will reload the common table with the entity type data that contains
	 * the input criteria.
	 *
	 * @memberof EntityManagerSearchComponent
	 */
	public searchEntity(): void
	{
		if (this.searchInputElement.nativeElement.value ===
			this.searchInputValue)
		{
			return;
		}

		this.searchInputValue = this.searchInputElement.nativeElement.value;

		this.commonTableContext.source.filterCriteriaChanged(
			AppConstants.empty);
	}

	/**
	 * Sets up the table definitions for the current admin page.
	 *
	 * @async
	 * @memberof EntityManagerSearchComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.entityTypes =
			await ApiHelper.getFullDataSet(
				this.entityTypeApiService,
				AppConstants.empty,
				AppConstants.empty);
		this.entityVersions =
			await ApiHelper.getFullDataSet(
				this.entityVersionApiService,
				AppConstants.empty,
				AppConstants.empty);

		this.tableDefinitions =
			{
				tableTitle: 'Entity Search',
				hideTableTitle: true,
				fullPageReservedHeight:
					AppConstants.staticLayoutSizes.tableSearchHeight,
				objectSearch: {
					filter: AppConstants.empty,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const entityDefinitions: IEntityDefinition[] =
							await this.entityDefinitionApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						const dataPromise: object[] = [];

						for (const entityDefinition of entityDefinitions)
						{
							const decorateViewModel: any =
								this.decorateViewModel(entityDefinition);

							if (!AnyHelper.isNull(decorateViewModel))
							{
								dataPromise.push(
									this.decorateViewModel(entityDefinition));
							}
						}

						return dataPromise;
					},
				decorateViewModel: async(model: any) =>
					this.decorateViewModel(model),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					filter: {
						quickFilters:
							[
								{
									label: 'All Entities',
									value: AppConstants.empty
								},
								{
									label: 'Enabled Entities',
									value: 'true'
								},
								{
									label: 'Disabled Entities',
									value: 'false'
								}
							],
						selectedFilterValue: this.tableFilter
					},
					view: {
						disabledExpandItem: true,
						items: [
							{
								command: async() =>
								{
									this.router.navigate(
										[
											'admin/entity/definition/'
												+ 'overview/view',
											this.commonTableContext.source
												.selectedItem.id
										]);
								}
							}
						]
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Decorates the view model for the object.
	 *
	 * @param {IWorkflowActionDefinitions} entityDefinition
	 * The object to be decorated as a view model.
	 * @returns {any}
	 * The mapped view model for display in this component.
	 * @memberof EntityManagerSearchComponent
	 */
	public decorateViewModel(
		entityDefinition: IEntityDefinition): any
	{
		const entityType: IEntityType =
			this.entityTypes.find((type: IEntityType) =>
				type.id === entityDefinition.typeId);
		const typeName: string =
			entityType.name;
		const typeGroup: string =
			entityType.group;

		const entityVersion: IEntityVersion =
			this.entityVersions.find((version: IEntityVersion) =>
				version.id === entityDefinition.versionId);
		const versionNumber: number =
			entityVersion.number;
		const versionEnabled: boolean =
			entityVersion.enabled;
		const versionStartDate: string =
			entityVersion.startDate;
		const versionEndDate: string =
			entityVersion.endDate;

		if ((AnyHelper.isNull(this.tableFilter)
			|| versionEnabled === JSON.parse(this.tableFilter))
				&& (AnyHelper.isNullOrEmpty(
					this.searchInputValue)
					|| typeName.toLowerCase()
						.includes(
							this.searchInputValue
								.toLowerCase())))
		{
			const viewModel: any =
				{
					id: entityDefinition.id,
					name: typeName,
					group: typeGroup,
					version: versionNumber,
					enabled: versionEnabled,
					startDate: versionStartDate,
					endDate: versionEndDate,
				};

			return viewModel;
		}

		return null;
	}
}
