/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	Location
} from '@angular/common';
import {
	SessionService
} from '@shared/services/session.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';

@Component({
	selector: 'app-commissions',
	templateUrl: './commissions.component.html',
	styleUrls: [
		'./commissions.component.scss'
	]
})

/**
 * A component representing context level work items.
 *
 * @export
 * @class RulesComponent
 * @extends {DrawerListDirective<IEntityInstance>}
 * @implements {IDynamicComponent<Component, any>}
 * @implements {IOwnershipGuardComponent}
 */
export class CommissionsComponent
	extends DrawerListDirective<IEntityInstance>
	implements OnInit, IDynamicComponent<Component, any>,
		IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the work items component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	 * @memberof CommissionsComponent
	 */
	public constructor(
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public sessionService: SessionService,
		public location: Location)
	{
		super();
	}

	/**
	 * Gets or sets the group wildcard type.
	 *
	 * @type {string}
	 * @memberof CommisionsListComponent
	 */
	public groupWildCard: string = InsuranceConstants
		.insuranceEntityTypeGroups
		.commissionDisbursements;

	/**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @async
	 * @memberof CommissionsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.entityDisplayName = 'Commission Disbursements';
		this.wildcardChildFilter = InsuranceConstants
			.insuranceEntityTypes
			.commissionDisbursement;

		if (!await this.isPageOwnershipAllowed()) {
			this.accessDeniedUrl = this.location.path();
			this.sessionIdentifier = this.sessionService.sessionId;
			this.resources =
				[
					this.wildcardChildFilter
				];
			this.clientMessage =
				'Access is required to at least one commission '
					+ 'disbursement item entity type and version.';
			this.isOwnershipAllowed = false;

			return;
		}

		this.context.data =
			{
				...this.context.data,
				drawerComponent: this
			};

		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;

		await this.setSecurityDefinitions(
			entityInstanceComponent.id,
			entityInstanceComponent.entityType.group,
			entityInstanceComponent.entityDefinition,
			entityInstanceComponent.entityInstanceApiService,
			entityInstanceComponent.entityTypeApiService);
	}

	/**
	* Implements the ownership guard interface.
	* This will calculate drawer ownership permissions.
	*
	* @async
	* @returns {Promise<boolean>}
	* A value signifying whether or not access is allowed to this drawer.
	* @memberof WorkItemsComponent
	*/
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const commissionTypes: IEntityType[] =
			await this.entityTypeApiService.query(
				AppConstants.commonProperties.group
				+ `.StartsWith('${this.groupWildCard}') eq true`,
				AppConstants.empty);

		if (commissionTypes.length === 0)
		{
			return false;
		}

		const initialPromiseArray: Promise<any>[] = [];
		commissionTypes.forEach(
			(entityType: IEntityType) =>
			{
				initialPromiseArray.push(
					this.entityService.verifyEntityTypeAccess(
						entityType));
			});

		const allowedEntities: boolean[] =
		await Promise.all(initialPromiseArray);

		return allowedEntities.some(
			(allowed: boolean) =>
				allowed === true);
	}

	/**
	 * Handles the entity altered event which is sent to this component when
	 * the drawer entity update or entity create components have altered this
	 * lists data. This method will refresh the badge promise to match the
	 * updated information.
	 *
	 * @memberof WorkItemsComponent
	 */
	public entityAltered()
	{
		EventHelper.dispatchRefreshBadgePromiseEvent(
			DynamicComponentLookup.supportedTypes.workItemsComponent,
			DynamicComponentLookup.targetComponents.utilityMenuComponent);
	}
}