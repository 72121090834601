/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	ActivityService
} from '@shared/services/activity.service';

/**
 * A class representing the action to run a string based promise on the
 * context of the action component.
 *
 * @export
 * @class ExecuteOperationPromiseAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class ExecuteOperationPromiseAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of an ExecuteOperationPromiseAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof ExecuteOperationPromiseAction
	 */
	public constructor(
		protected activityService: ActivityService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof ExecuteOperationPromiseAction
	 */
	public operationName: string =
		'Execute Operation Promise';

	/**
	 * Gets or sets the operation promise to be ran during execute.
	 *
	 * @type {string}
	 * @memberof ExecuteOperationPromiseAction
	 */
	public operationPromise: string = AppConstants.empty;

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof ExecuteOperationPromiseAction
	 */
	public async execute(): Promise<void>
	{
		await StringHelper.transformToDataPromise(
			this.operationPromise,
			this.pageContext);
	}
}