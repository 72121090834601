/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	ApiError
} from '@api/errors/api.error';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IApplicationMessage
} from '@shared/interfaces/application-messages/application-message.interface';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	SessionRefreshService
} from '@shared/services/session-refresh.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	DateTime
} from 'luxon';
import {
	catchError,
	Observable,
	tap
} from 'rxjs';
import {
	AppConfig
} from 'src/app/app.config';

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * A class representing the middleware logic to intercept and modify
 * API requests.
 *
 * @export
 * @class AppSecurityHttpInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AppSecurityHttpInterceptor
implements HttpInterceptor
{

	/**
	 * Creates an instance of a AppSecurityHttpInterceptor.
	 *
	 * @param {SessionService} sessionService
	 * The session service used for sesion validation.
	 * @param {SessionRefreshService} sessionService
	 * The session refresh service used for keep alive and session refresh
	 * functionality.
	 * @param {loggerService} LoggerService
	 * The logger service used to log api actions.
	 * @memberof AppSecurityHttpInterceptor
	 */
	public constructor(
		private readonly sessionService: SessionService,
		private readonly sessionRefreshService: SessionRefreshService,
		private readonly loggerService: LoggerService)
	{
	}

	/**
	 * intercepts and handles all XHR request
	 *
	 * @param {HttpRequest<any>} request
	 * The request to be sent.
	 * @param {HttpHandler} next
	 * The http handler for synchronizing the requests.
	 * @returns {Observable<HttpEvent<any>>}
	 * The return of event of this intercepted http action.
	 * @memberof AppSecurityHttpInterceptor
	 */
	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler): Observable<HttpEvent<any>>
	{
		if (this.sessionService.expiryDate <= new Date()
			&& this.sessionService.isValid)
		{
			this.sessionService.logOut();

			EventHelper.dispatchLoginMessageEvent(
				AppConstants.messages.sessionExpired,
				AppConstants.messages.pleaseEnterCredentials,
				AppConstants.messageLevel.error);
		}

		const token: string = this.sessionService.token;
		let headers: HttpHeaders = request.headers;

		if (!AnyHelper.isNull(token)
			&& token !== AppConstants.empty)
		{
			this.sessionService.resetExpiryDate();

			headers =
				headers.append(
					AppConstants.webApi.tokenKey,
					token);
		}

		const requestWithCredentials =
			request.clone(
				{
					withCredentials: true,
					headers: headers
				});

		if (!requestWithCredentials.urlWithParams.endsWith('/Logs'))
		{
			this.loggerService.logTrace(
				`Calling api: ${requestWithCredentials.method} `
					+ '\r\n	with the url '
					+ requestWithCredentials.urlWithParams + '.');
		}

		return next.handle(requestWithCredentials)
			.pipe(
				tap((response: HttpEvent<any>) =>
				{
					if (response instanceof HttpResponse
						&& requestWithCredentials.url.indexOf(
							AppConfig.settings.webApi.rootUrl) !== -1
						&& !requestWithCredentials.url.endsWith(
							SessionRefreshService.refreshApiEndpoint))
					{
						this.sessionRefreshService.resetIntervals();
					}
				}),
				catchError((error: HttpErrorResponse) =>
					this.handleError(
						error,
						requestWithCredentials)));
	}

	/**
	 * intercepts and handles an error from an XHR request.
	 *
	 * @param {HttpErrorResponse} error
	 * The http error response to be handled.
	 * @param {HttpRequest<any>} request
	 * The request that threw this error.
	 * @returns {Observable<HttpEvent<any>>}
	 * The return of event of this intercepted http action.
	 * @memberof AppSecurityHttpInterceptor
	 */
	private handleError(
		error: HttpErrorResponse,
		request: HttpRequest<any>): Observable<HttpEvent<any>>
	{
		let apiError: ApiError;

		if (error.status ===
			AppConstants.httpStatusCodes.unauthorized
			&& request.url.indexOf(
				`/${AppConstants.apiControllers.authenticate}`) === -1
			&& request.url.indexOf(AppConfig.settings.webApi.rootUrl) !== -1)
		{
			// If not authenticated and not authenticate controller.
			this.sessionService.logOut();

			const applicationMessage: IApplicationMessage =
				{
					description: error.statusText,
					date: DateTime.utc().toISO()
				};

			const applicationMessages: IApplicationMessage[] = [];
			applicationMessages.push(applicationMessage);

			apiError =
				new ApiError(
					'Security Error',
					applicationMessages);
			apiError.status = error.status;
		}

		if (!AnyHelper.isNull(error.error)
			&& !AnyHelper.isNull(error.error.constructor)
			&& typeof error.error === 'object'
			&& error.error.constructor.name === 'Array')
		{
			// Likely this return is an array of application
			// messages.
			apiError =
				new ApiError(
					'Api Error',
					<IApplicationMessage[]>error.error);
			apiError.status = error.status;
		}

		if (apiError)
		{
			throw apiError;
		}

		throw error;
	}
}