/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	IStoredVariableDefinition
} from '@shared/interfaces/application-objects/stored-variable-definition';
import {
	IStoredVariableService
} from '@shared/interfaces/application-objects/stored-variable-service.interface';

/* eslint-enable max-len */

/**
 * A class representing logic for handling common stored variable service
 * based logic.
 *
 * @export
 * @abstract
 * @class BaseStoredVariableService
 */
@Injectable()
export abstract class BaseStoredVariableService
implements IStoredVariableService
{
	/**
	 * Gets or sets the lock value for when this is setting local storage
	 * values.
	 *
	 * @type {boolean}
	 * @memberof BaseStoredVariableService
	 */
	public settingLocalStorage: boolean = false;

	/**
	 * Gets or sets the local variable that allows this to query for the
	 * max result set. If this value is set as false, this will instead call
	 * for the api helper get full data set.
	 *
	 * @type {boolean}
	 * @memberof BaseStoredVariableService
	 */
	public allowedMaxResultSet: boolean = true;

	/**
	 * Gets or sets the storage variables that will be stored in this
	 * singleton service.
	 *
	 * @type {IStoredVariableDefinition[]}
	 * @memberof BaseStoredVariableService
	 */
	public storedVariables: IStoredVariableDefinition[] = [];

	/**
	 * Clears the set of stored variables used in this rule service.
	 *
	 * @memberof BaseStoredVariableService
	 */
	public clearStoredVariables(): void
	{
		this.settingLocalStorage = true;

		this.storedVariables.forEach(
			(storedVariable: IStoredVariableDefinition) =>
			{
				this[storedVariable.storageProperty] = [];
			});

		this.settingLocalStorage = false;
	}

	/**
	 * Populates the set of stored variables used in this rule service.
	 *
	 * @async
	 * @memberof BaseStoredVariableService
	 */
	public async setStoredVariables(): Promise<void>
	{
		if (this.settingLocalStorage === true)
		{
			return new Promise(
				async(resolve: any) =>
				{
					setTimeout(
						async() =>
						{
							await this.setStoredVariables();

							resolve();
						},
						AppConstants.time.oneHundredMilliseconds);
				});
		}

		this.settingLocalStorage = true;

		const storedPromiseArray: Promise<void>[] = [];
		this.storedVariables
			.forEach(
				(storedVariable: IStoredVariableDefinition) =>
				{
					if (this[storedVariable.storageProperty].length > 0)
					{
						return;
					}

					const promise: Promise<any[]> =
						this.allowedMaxResultSet
							? storedVariable.apiService.query(
								AppConstants.empty,
								AppConstants.empty,
								0,
								AppConstants.dataLimits.maxResultSet)
							: ApiHelper.getFullDataSet(
								storedVariable.apiService,
								AppConstants.empty,
								AppConstants.empty);

					storedPromiseArray.push(
						new Promise(
							async(resolve: any) =>
							{
								this[storedVariable.storageProperty] =
									await promise;

								resolve();
							}));
				});

		await Promise.all(storedPromiseArray);

		this.settingLocalStorage = false;

		return null;
	}
}