<app-base-page pageTitle="Display Component Definitions"
	[pageContext]="pageContext"
	utilityMenuOperationGroupName="">

	<div *ngIf="loadingTableDefinitions"
		class="text-center ui-g-12">
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>

	<div *ngIf="!loadingTableDefinitions"
		class="ui-g-12 table-container">
		<app-common-table
			[tableDefinitions]="tableDefinitions">
		</app-common-table>
	</div>
</app-base-page>