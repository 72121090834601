<ng-template
	[ngIf]="field.props.loadingDashboard === true"
	[ngIfElse]="Content">
	<div class="ui-g-12 no-padding flex-center">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>
</ng-template>

<ng-template #Content>
	<app-common-dashboard
		[dashboardDisplayComponentInstanceName]="field.props.dashboardDisplayComponentInstanceName"
		[entityContext]="field.props.context"
		[useRouteUrl]="false"
		(finishedLoading)="customDashboardChanges()"
		[field]="field">
	</app-common-dashboard>
</ng-template>

