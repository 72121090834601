/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component,
	HostListener,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	AbstractControl,
	UntypedFormArray
} from '@angular/forms';
import {
	FieldArrayType,
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	FormlyFieldConfigCache,
	FormlyValueChangeEvent
} from '@ngx-formly/core/lib/models';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IKeyValuePair
} from '@shared/interfaces/application-objects/key-value-pair.interface';
import {
	each,
	isArray,
	isObject,
	unset
} from 'lodash-es';
import {
	DateTime
} from 'luxon';
import {
	Subject,
	concatAll,
	debounceTime,
	from,
	interval,
	map,
	take
} from 'rxjs';

@Component({
	selector: 'custom-repeater',
	templateUrl: './custom-repeater.component.html',
	styleUrls: ['./custom-repeater.component.scss']
})

/**
 * A component representing an instance of a Custom Repeater.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomRepeaterComponent
 * @extends {FieldArrayType}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class CustomRepeaterComponent
	extends FieldArrayType
	implements OnInit, OnDestroy
{
	/**
	 * Initializes a new instance of the CustomRepeaterComponent. This
	 * explicit constructor was requested during an upgrade process and
	 * should be left as implemented.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomRepeaterComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super();
	}

	/**
	 * Gets or sets the value used to define if the add
	 * item is visible in the section title.
	 *
	 * @type {boolean}
	 * @memberof CustomRepeaterComponent
	 */
	public addItemVisible: boolean = true;

	/**
	 * Gets or sets the array change observer subject which is called when an
	 * item is added or removed.
	 *
	 * @type {Subject<void>}
	 * @memberof CustomRepeaterComponent
	 */
	public arrayChangeObserver:
		Subject<void> = new Subject<void>();

	/**
	 * Handles a shared repeater item event by ensuring that any shared
	 * repeaters are updated to hold the same values as the altered repeater.
	 *
	 * @param {string} repeaterKey
	 * The repeater's data location key.
	 * @param {string} repeaterFilter
	 * The repeaters data filter for shared repeaters.
	 * @param {string} repeaterAction
	 * The add or remove repeater action.
	 * @param {number} itemIndex
	 * The item index added/removed to/from the repeater.
	 * @memberof CustomRepeaterComponent
	 */
	@HostListener(
		AppEventConstants.repeaterItemAlteredEvent,
		[
			AppEventParameterConstants.repeaterKey,
			AppEventParameterConstants.repeaterFilter,
			AppEventParameterConstants.repeaterAction,
			AppEventParameterConstants.itemIndex
		])
	public repeaterItemAltered(
		repeaterKey: string,
		repeaterFilter: string,
		repeaterAction: string,
		itemIndex: number = null): void
	{
		if (this.field.key !== repeaterKey)
		{
			return;
		}

		if (AnyHelper.isNull(this.field.props.filter)
			|| this.field.props.filter === repeaterFilter)
		{
			if (!AnyHelper.isNull(itemIndex)
				&& !AnyHelper.isNull(this.model))
			{
				// Cleaning the field item from model.
				this.cleanModelField(
					this.model[itemIndex],
					itemIndex,
					(<any>this.field.fieldArray)?.fieldGroup);
			}

			// Cleaning existing field items from model.
			this.cleanExistingModelFields(this.field);

			this.InitializeFormControls();

			return;
		}

		if (repeaterAction === FormlyConstants.repeaterActions.add)
		{
			this.options.build(this.field.parent);
			this.options.fieldChanges.next(
				<FormlyValueChangeEvent>
				{
					field: this.field.parent,
					value: {...this.field.parent.formControl.value},
					type: FormlyConstants.valueChangeTypes.valueChanges
				});
		}
		else
		{
			this.removeFromArray(
				itemIndex,
				false);
		}

		this.updateNestedRepeaterContext(this.field);
		this.validateControl();

		// Cleaning existing field items from model.
		this.cleanExistingModelFields(this.field);
	}

	/**
	 * This will sync up the model for all existing repeater fields.
	 *
	 * @memberof CustomRepeaterComponent
	 */
	@HostListener(
		AppEventConstants.repeaterCleanModelEvent)
	public repeaterModelSync(): void
	{
		this.cleanExistingModelFields(this.field);
	}

	/**
	 * Handles the on initialization event.
	 * This method will setup minimum and max length validations depending
	 * on the sent template options.
	 *
	 * @memberof CustomRepeaterComponent
	 */
	public ngOnInit(): void
	{
		const canCreate: boolean =
			this.field
				.props
				?.securityAccessPolicy
				?.rights
				?.create
				?? false;

		this.addItemVisible =
			!this.field.props.disabled
				&& canCreate;

		this.field.props.removeAddItem =
			this.removeAddItem.bind(this);

		this.updateNestedRepeaterContext(
			this.field);

		// Run an overall validation check after adds of multiple items.
		this.arrayChangeObserver.pipe(
			debounceTime(AppConstants.time.quarterSecond))
			.subscribe(
				() =>
				{
					this.addItemVisible =
						!this.field.props.disabled
							&& canCreate;

					FormlyHelper.fireViewCheck(
						this.field.fieldGroup);

					this.fireChanges();
				});

		this.field.fieldGroup.forEach(
			(fieldGroup: FormlyFieldConfig) =>
			{
				fieldGroup.props.display =
					!AnyHelper.isNullOrWhitespace(
						this.field.props.filter)
						? StringHelper
							.transformToFunction(
								this.field.props.filter,
								this.field.props.context)(fieldGroup)
						: true;
			});

		this.cleanExistingModelFields(this.field);

		if (!AnyHelper.isNullOrEmpty(
			this.field.props.minimumNumber)
			|| !AnyHelper.isNullOrEmpty(
				this.field.props.maximumNumber))
		{
			this.validateControl();

			return;
		}

		if (!(this.field.props.minItems > 0
			|| this.field.props.maxItems > 0))
		{
			this.validateControl();

			return;
		}

		this.setRepeaterCountValidity();
		this.validateControl();
	}

	/**
	 * Removes the add item button from the section title.
	 *
	 * @memberof CustomRepeaterComponent
	 */
	public removeAddItem(): void
	{
		this.addItemVisible = false;
	}

	/**
	 * On destroy event.
	 * This will complete and observables or subscriptions on this component.
	 *
	 * @memberof CustomRepeaterComponent
	 */
	public ngOnDestroy(): void
	{
		this.arrayChangeObserver.complete();
	}

	/**
	 * Reaches into the field currently being displayed and sets the
	 * context value for each nested fieldgroup.
	 *
	 * @param {FormlyFieldConfig} field
	 * The field value used to set nested repeater context values.
	 * @memberof CustomRepeaterComponent
	 */
	public updateNestedRepeaterContext(
		field: FormlyFieldConfig): void
	{
		// Make sure new controls are not linked by name as we want
		// each field to validate standalone.
		if (!AnyHelper.isNull((<any>field.formControl)?._fields))
		{
			delete (<any>field.formControl)._fields;
		}

		if (!AnyHelper.isNull((<any>field.formControl)?._controls)
			&& isArray((<any>field.formControl)?._controls))
		{
			for (const nestedField of (<any>field.formControl)?._controls)
			{
				if (!AnyHelper.isNull(nestedField.formControl?._fields))
				{
					delete nestedField.formControl._fields;
				}
			}
		}

		field.fieldGroup?.forEach(
			(fieldGroupItem: FormlyFieldConfig) =>
			{
				fieldGroupItem.props.context =
					this.field.props.context;

				this.updateNestedRepeaterContext(fieldGroupItem);
			});

		(<any>field.fieldArray)?.fieldGroup.forEach(
			(fieldArrayItem: FormlyFieldConfig) =>
			{
				fieldArrayItem.props.context =
					this.field.props.context;

				this.updateNestedRepeaterContext(fieldArrayItem);
			});
	}

	/**
	 * Handles the on tab expand event sent from the accordion component.
	 * This will fire a view check on the expanded item to ensure the latest
	 * business rules are applied.
	 *
	 * @param {number} itemIndex
	 * The item index that received the expand event.
	 * @memberof CustomRepeaterComponent
	 */
	public onTabExpand(
		itemIndex: number): void
	{
		this.updateViewValidations(itemIndex);
	}

	/**
	 * A decoration method to the formly add function allowing
	 * this component to mark the form as dirty on adding an
	 * array data item.
	 *
	 * @memberof CustomRepeaterComponent
	 */
	public addItem(): void
	{
		this.addToArray(
			null,
			this.field.props.initialItemValue);

		const newItemIndex: number = this.field.fieldGroup.length - 1;
		this.updateNestedRepeaterContext(this.field);
		this.field.fieldGroup[newItemIndex]
			.props.display = true;

		this.updateNestedRepeaterContext(
			this.field.fieldGroup[newItemIndex]);
		this.validateControl();

		this.updateViewValidations(newItemIndex);
	}

	/**
	 * A decoration method to the formly add function allowing
	 * this component to update all listening shared repeaters.
	 *
	 * @param {number} itemIndex
	 * If sent this will add the item to the sent index, this value defaults
	 * to the last item in the array.
	 * @param {any} initialModel
	 * If sent this will create the new item with this sent object as it's
	 * data structure. This value will default to an empty object.
	 * @memberof CustomRepeaterComponent
	 */
	public addToArray(
		itemIndex?: number,
		initialModel?: any)
	{
		const newItemIndex: number =
			AnyHelper.isNullOrEmpty(itemIndex)
				? this.field.fieldGroup.length
				: itemIndex;

		if (AnyHelper.isNull(this.model))
		{
			this.field.formControl.setValue([]);
		}

		this.model.splice(
			newItemIndex,
			0,
			initialModel
				? {...initialModel}
				: undefined);

		this.options.build(this.field.parent);
		this.options.fieldChanges.next(
			<FormlyValueChangeEvent>
			{
				field: this.field.parent,
				value: {...this.field.parent.formControl.value},
				type: FormlyConstants.valueChangeTypes.valueChanges
			});

		this.alignInitialItemValues(
			newItemIndex,
			initialModel);

		EventHelper.dispatchRepeaterItemAlteredEvent(
			this.field.key.toString(),
			this.field.props.filter,
			FormlyConstants.repeaterActions.add,
			newItemIndex);
	}

	/**
	 * A decoration method to the formly delete function allowing
	 * this component to mark the form as dirty on deleting an
	 * array data item.
	 *
	 * @param {FormlyFieldConfig} fieldToRemove
	 * The item being deleted.
	 * @param {Event} event
	 * The remove item event.
	 * @memberof CustomRepeaterComponent
	 */
	public removeItem(
		fieldToRemove: FormlyFieldConfig,
		event: Event): void
	{
		const itemIndex: number =
			this.field.fieldGroup.indexOf(fieldToRemove);

		this.removeFromArray(itemIndex);
		this.updateNestedRepeaterContext(this.field);
		this.validateControl(event);

		EventHelper.dispatchRepeaterItemAlteredEvent(
			this.field.key.toString(),
			this.field.props.filter,
			FormlyConstants.repeaterActions.remove,
			itemIndex);
	}

	/**
	 * Returns the validity of the current repeater if a maximum item
	 * or minimum item property exists or true if not defined.
	 *
	 * @returns {boolean}
	 * The current validity of the repeater length.
	 * @memberof CustomRepeaterComponent
	 */
	public hasAValidRepeaterCount(
		control: AbstractControl): boolean
	{
		const minItems: number =
			this.field.props.minItems;
		const maxItems: number =
			this.field.props.maxItems;

		return (AnyHelper.isNull(minItems)
			? true
			: control.value.length >= minItems)
			&& (AnyHelper.isNull(maxItems)
				? true
				: control.value.length <= maxItems);
	}

	/**
	 * Returns the disabled value of the current repeater item as true if all
	 * fields in the item are disabled. This is used to hide or show the
	 * delete action on the item.
	 *
	 * @param {FormlyFieldConfig} customField
	 * The field configuration for the repeater item.
	 * @returns {boolean}
	 * The current disabled value of the repeater item.
	 * @memberof CustomRepeaterComponent
	 */
	public isDisabledItem(
		customField: FormlyFieldConfig): boolean
	{
		return customField.fieldGroup.filter(
			(item: FormlyFieldConfig) =>
				item.key !== AppConstants.commonProperties.resourceIdentifier
					&& item.props.disabled !== true)
			.length === 0;
	}

	/**
	 * Gets a custom title to be used for displaying this repeater item.
	 * This can be customized via the props.titleFormat value in
	 * the entity layout. This performs similar to string interpolation
	 * except only if values exist for all requested properties will the
	 * titleFormat result be returned.
	 * @summary
	 * A title format of '{city}, {state}' will find the controls in this
	 * repeater item with these matching keys and replace the bracketed
	 * property names with their value. This will then prepend the singular
	 * item type as well as the item index as a word.
	 * ie. Location One: Butte, MT.
	 *
	 * @param {number} itemIndex
	 * The item index for which the header is being generated for.
	 * @param {FormlyFieldConfig} currentField
	 * The current field group for which the header is being generated for.
	 * @returns {string}
	 * The title with values parsed from the object matching the title
	 * format of this repeater type.
	 * @memberof CustomRepeaterComponent
	 */
	public getCustomTitle(
		currentField: FormlyFieldConfig): string
	{
		if (AnyHelper.isNullOrEmpty(this.field))
		{
			return AppConstants.empty;
		}

		const customFieldGroup: FormlyFieldConfig[] =
			this.field.fieldGroup.filter(
				(field: FormlyFieldConfig) =>
					field.props.display === true);
		const itemIndex: number =
			customFieldGroup.indexOf(currentField);

		const indexNumberAsWord: string =
			StringHelper.numberToWords(
				(itemIndex + 1).toString(),
				true);
		const itemIdentifier: string =
			this.field.props.singular
				+ ' '
				+ indexNumberAsWord;

		if (AnyHelper.isNullOrEmpty(
			this.field.props.titleFormat))
		{
			return itemIdentifier;
		}

		const words: IKeyValuePair[] =
			this.getCurrentItemTitleValues(
				currentField,
				this.field.props.titleFormat);

		const isPartialTitleMatch: boolean =
			words.some((keyValuePair: IKeyValuePair) =>
				!AnyHelper.isNullOrWhitespace(keyValuePair.value));

		if (isPartialTitleMatch === false)
		{
			return itemIdentifier;
		}

		let formattedTitle: string =
			this.field.props.titleFormat;

		words.forEach(
			(keyValuePair: IKeyValuePair) =>
			{
				if (AnyHelper.isNullOrWhitespace(keyValuePair.value))
				{
					formattedTitle = formattedTitle.replace(
						keyValuePair.key,
						AppConstants.empty);
				}
				else
				{
					const fieldKey: string =
						StringHelper
							.getCleanedValue(
								keyValuePair.key,
								['{', '}']);

					const formlyField: FormlyFieldConfig =
						currentField.get(fieldKey);

					if (formlyField
						&& formlyField.type
							=== FormlyConstants.customControls.customSelect)
					{
						const options: IDropdownOption[] =
							<IDropdownOption[]>
							formlyField.props.options;

						const option: IDropdownOption =
							options.find((dropdownOption: IDropdownOption) =>
								dropdownOption.value === keyValuePair.value);

						formattedTitle = formattedTitle.replace(
							keyValuePair.key,
							option.label);
					}
					else
					{
						formattedTitle = formattedTitle.replace(
							keyValuePair.key,
							keyValuePair.value);
					}
				}
			});

		formattedTitle =
			formattedTitle
				.replace(
					/(^\s-\s|^\s*|\s-\s+$|\s+$)/g,
					AppConstants.empty);

		return `${itemIdentifier}: ${formattedTitle}`;
	}

	/**
	 * Given a current item in the repeater set, this will return
	 * the cleaned set of keys and values that match the title format.
	 * @summary
	 * A title format of '{city}, {state}' will find the controls in this
	 * repeater item with these matching keys and return an array of
	 * key value pairs with a key of {city}, and a value of the form controls
	 * value in the UI. If the value is an empty string or null, null is
	 * returned.
	 *
	 * @param {FormlyFieldConfig} currentItem
	 * The current repeater item.
	 * @param {string} titleFormat
	 * The title format being used to return the set of associated key value
	 * pairs of data for this item's custom title.
	 * @returns {IKeyValuePair[]}
	 * The set of key value pairs with the key word and value matching the title
	 * format word tokens.
	 * @memberof CustomRepeaterComponent
	 */
	private getCurrentItemTitleValues(
		currentItem: FormlyFieldConfig,
		titleFormat: string): IKeyValuePair[]
	{
		const wordTokens: RegExpMatchArray =
			titleFormat.match(/{[\w.\d]+}/g);
		const words: IKeyValuePair[] = [];

		wordTokens.forEach(
			(wordToken: string) =>
			{
				const titleControl: FormlyFieldConfig[] =
					currentItem.fieldGroup
						.filter(
							(item: FormlyFieldConfig) =>
								AnyHelper.isNullOrWhitespace(item.key)
									? AppConstants.empty
									: (item.key.toString())
										.indexOf(
											StringHelper
												.getCleanedValue(
													wordToken,
													['{', '}'])) === 0);

				const titleControlValue: string =
					!AnyHelper.isNullOrEmpty(titleControl)
						&& titleControl.length > 0
						? titleControl[0].formControl.value
						: null;

				let wordValue: string = titleControlValue;
				if (DateTime.fromISO(titleControlValue).isValid === true
					&& titleControl[0].type ===
						FormlyConstants.customControls.customCalendar)
				{
					wordValue =
						DateTime
							.fromISO(
								titleControlValue)
							.toFormat(
								DateHelper.presetFormats.longDateFormat);
				}
				else if (!AnyHelper.isNullOrWhitespace(titleControlValue)
					&& titleControl[0].type ===
						FormlyConstants.customControls.customInputNumber
					&& titleControl[0].props.useCurrency === true)
				{
					wordValue =
						StringHelper.format(
							titleControlValue,
							AppConstants.formatTypes.currency);
				}

				words.unshift(
					<IKeyValuePair>
					{
						key: wordToken,
						value: !AnyHelper.isNullOrEmpty(wordValue)
							? wordValue
							: null
					});
			});

		return words;
	}

	/**
	 * Attaches a minimum and maximum-length validator to the repeater control
	 * based on the sent minItems and maxItems properties.
	 *
	 * @memberof CustomRepeaterComponent
	 */
	private setRepeaterCountValidity(): void
	{
		this.field.validators = this.field.validators || {};

		const minItems: number =
			this.field.props.minItems;
		const maxItems: number =
			this.field.props.maxItems;
		const minItemMessage: string = minItems > 1
			? 'items are'
			: 'item is';
		const maxItemMessage: string = maxItems > 1
			? 'items are'
			: 'item is';

		this.field.validators.arrayLength = {
			expression: (control: AbstractControl) =>
				this.hasAValidRepeaterCount(control),
			message: AnyHelper.isNull(maxItems)
				? `At least ${minItems} ${minItemMessage} required.`
				: `Between ${minItems || 0} `
					+ `and ${maxItems} ${maxItemMessage} required.`
		};

		this.validateControl();
	}

	/**
	 * Validates the existing control with a set as touched and a call to update
	 * value and validity.
	 *
	 * @param {Event} event
	 * The validate control item event.
	 * @memberof CustomRepeaterComponent
	 */
	private validateControl(event?: Event): void
	{
		this.field.formControl.markAsDirty();
		this.field.formControl.markAllAsTouched();
		this.field.formControl.updateValueAndValidity();
		this.fireChanges(event);
	}

	/**
	 * Fires a change detection cycle allowing the form to broadcast changes
	 * to the listening component.
	 * @note This can likely be removed with an upgraded Formly as this is
	 * a workaround to the view not aligning to the state of the formcontrol.
	 * This was required starting with Formly v6.0.0-next.1.
	 *
	 * @param {Event} event
	 * The fire changes item event.
	 * @memberof CustomRepeaterComponent
	 */
	private fireChanges(event?: Event): void
	{
		this.changeDetector.detectChanges();

		if (AnyHelper.isFunction(this.field.props.change))
		{
			this.field.props.change(
				this.field,
				event);
		}
	}

	/**
	 * Implements a limited set of the existing formly remove method that
	 * ensures compatibility with our any of repeaters.
	 *
	 * @param {number} itemIndex
	 * The array item index to be removed.
	 * @param {boolean} removeFromModel
	 * A value signifying whether or not this remove call should remove from
	 * the component level model property.
	 * @memberof CustomRepeaterComponent
	 */
	private removeFromArray(
		itemIndex: number,
		removeFromModel: boolean = true): void
	{
		if (removeFromModel === true)
		{
			this.model.splice(itemIndex, 1);
		}

		this.unregisterControl(
			this.field.fieldGroup[itemIndex],
			true);

		this.field.fieldGroup.splice(
			itemIndex,
			1);

		this.field.fieldGroup.forEach(
			(field: FormlyFieldConfig,
				key: string | number) =>
				field.key = `${key}`);
	}

	/**
	 * A shared set of logic that will un-register a control similar to the
	 * unregister on the field array type this component extends.
	 *
	 * @param {FormlyFieldConfigCache} field
	 * The formly field config that should be unregistered.
	 * @param {boolean} emitEvent
	 * A value signifying whether or not this event should be emitted when
	 * complete.
	 * @memberof CustomRepeaterComponent
	 */
	private unregisterControl(
		field: FormlyFieldConfigCache,
		emitEvent: boolean = false): void
	{
		const control: any = field.formControl;
		const fieldIndex =
			(<any>control)._fields
				? (<any>control)._fields.indexOf(field)
				: -1;
		if (fieldIndex !== -1)
		{
			(<any>control)._fields.splice(
				fieldIndex,
				1);
		}

		const formArray =
			control.parent as UntypedFormArray;

		if (AnyHelper.isNull(formArray)
			|| AnyHelper.isNull(formArray.controls.findIndex))
		{
			return;
		}

		const key =
			formArray.controls.findIndex(
				(formControl: AbstractControl) =>
					formControl === control);

		if (key !== -1)
		{
			if (AnyHelper.isNull(formArray.removeAt))
			{
				return;
			}

			formArray.removeAt(
				key,
				{ emitEvent });
		}

		control.setParent(null);
	}

	/**
	 * This method will run a validation check on the sent item index in this
	 * displayed repeater.
	 *
	 * @param {number} itemIndex
	 * The item index that will need to run an explicit validation check.
	 * @memberof CustomRepeaterComponent
	 */
	private updateViewValidations(
		itemIndex: number): void
	{
		from([0, .25])
			.pipe(
				map((intervalTime: number) =>
					interval(intervalTime * 1000)
						.pipe(take(1))),
				concatAll())
			.subscribe(
				() =>
				{
					if (AnyHelper.isNull(this.field.fieldGroup[itemIndex])
						|| this.field.fieldGroup[itemIndex]
							.props.display === false)
					{
						return;
					}

					this.updateNestedRepeaterContext(
						this.field.fieldGroup[itemIndex]);

					FormlyHelper.fireViewCheck(
						<FormlyFieldConfig[]>
						[
							this.field.fieldGroup[itemIndex]
						]);
					this.fireChanges();
				});
	}

	/**
	 * This method will run the logic required to make sure that a newly added
	 * item will hold the same properties as the sent initial model. This value
	 * exists in the fields template options under the property name of
	 * 'initialItemValue'.
	 *
	 * @param {number} newItemIndex
	 * The new item index that needs to be aligned with the initial model.
	 * @param {any} initialModel
	 * The initial model value that we need to set.
	 * @memberof CustomRepeaterComponent
	 */
	private alignInitialItemValues(
		newItemIndex: number,
		initialModel: any): void
	{
		if (AnyHelper.isNull(this.model[newItemIndex]))
		{
			return;
		}

		this.model[newItemIndex] =
			{
				...this.model[newItemIndex] || {},
				...initialModel
			};
	}

	/**
	 * Determines if there are any existing repeater fields displayed
	 * and clean performs a clean model action to each.
	 *
	 * @param {FormlyFieldConfig} field
	 * The repeater field to look for existing related repeater items to clean.
	 * @memberof CustomRepeaterComponent
	 */
	private cleanExistingModelFields(field: FormlyFieldConfig): void
	{
		const existingFieldItems: FormlyFieldConfig[] =
			field?.fieldGroup.filter((item) =>
				item.props.display === true);

		if (AnyHelper.isNull(existingFieldItems)
			|| existingFieldItems.length === 0
			|| AnyHelper.isNull(this.model))
		{
			return;
		}

		existingFieldItems.forEach((existingFieldItem) =>
		{
			this.cleanModelField(
				this.model[<number>existingFieldItem.key],
				<number>existingFieldItem.key,
				existingFieldItem.fieldGroup);
		});
	}

	/**
	 * Performs a model clean for the repeater field based
	 * on the existing formGroup schema properties by removing
	 * from the model array those data items that do not match.
	 *
	 * @param {object} model
	 * The given model to check.
	 * @param {object} fieldIndexInModel
	 * The index of the model that relates
	 * with the field repeater item.
	 * @param {FormlyFieldConfig[]} fieldGroup
	 * The to check if any field matches the key path in the model.
	 * This will unset/remove the build model item in case
	 * the key path is found as part of the fieldFormGroup.
	 * @param {string} parentKey
	 * In case of nested object this will add the key predecesor.
	 * @memberof CustomRepeaterComponent
	 */
	private cleanModelField(
		model: object,
		fieldIndexInModel: number,
		fieldGroup: FormlyFieldConfig[],
		parentKey: string = AppConstants.empty)
	{
		if (AnyHelper.isNull(model))
		{
			return;
		}

		each(Object.keys(model),
			(item: any) =>
			{
				const nestedObjectKey: string =
					!AnyHelper.isNullOrWhitespace(parentKey)
					 	? `${parentKey}.${item}`
						: item;

				const dataKey: string =
					!isNaN(parseInt(
						item,
						AppConstants.parseRadix))
						? `${parentKey}[${item}]`
						: nestedObjectKey;

				if (isObject(model[item]))
				{
					if (isArray(model[item]))
					{

						if (!fieldGroup.some((field) =>
							field.key?.toString().indexOf(dataKey) >= 0))
						{
							unset(
								this.model[fieldIndexInModel],
								dataKey);

							return;
						}
					}

					this.cleanModelField(
						model[item],
						fieldIndexInModel,
						fieldGroup,
						dataKey);
				}
			});
	}

	/**
	 * Performs a form controls initialization for the repeater.
	 * This makes sure that form controls are synch within the model.
	 * This fixes an internal error when using the same model key value on a
	 * repeater but also on plain formly layout controls.
	 *
	 * @memberof CustomRepeaterComponent
	 */
	private InitializeFormControls(): void
	{
		if (this.field.formControl.controls != null)
		{
			this.field.formControl.controls = [];
		}
	}
}