<div class="difference-display-wrapper">

	<div class="ui-g-12 no-padding difference-display-container {{siteLayoutService.contentCssClass}}"
		[ngClass]="{
			'drawer-display': useMinimumWidth === true
		}">

		<div class="ui-g-12 no-padding difference-display-labels"
		*ngIf="loading === false"
			[ngClass]="{
				'wide-initial-labels': (scrollPanelDisplayed === false)
			}">
			<div class="difference-key-column initial-key-column">
				&nbsp;
			</div>
			<div class="difference-key-value">
				<span class="panel-sub-title initial-key-value ellipsis-text">
					Previous
				</span>
			</div>
			<div class="difference-key-value">
				<span class="panel-sub-title initial-key-value ellipsis-text">
					New
				</span>
			</div>
		</div>

		<p-scrollPanel #DifferencesDisplayScroller
			styleClass="differences-display-scroll-content"
			class="differences-display-scroll-container">
			<ng-template
				ngFor
				let-mappedDifference
				[ngForOf]="mappedDifferences">

				<app-difference-display
					[mappedDifference]="mappedDifference"
					[context]="context"
					(detectChanges)="handleChanges()">
				</app-difference-display>

			</ng-template>
		</p-scrollPanel>

	</div>

</div>
