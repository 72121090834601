/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';

// tslint:enable: max-line-length

@Component({
	selector: 'select-disbursement-organizations',
	templateUrl: './select-disbursement-organizations.component.html',
	styleUrls: [
		'./select-disbursement-organizations.component.scss'
	]
})

/**
 * A component representing a wizard step for selecting organizations to
 * be incuded in a disbursement.
 *
 * @export
 * @class SelectDisbursementOrganizationsComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class SelectDisbursementOrganizationsComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the select disbursement component.
	 *
	 * The module service used to set module changes on entity creation.
	 * @param {Router} router
	 * TThe router for navigation.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {EntityService} entityService
	 * The entity service used in this component.
	 * @param {ActivityService} activityService
	 * The activity service for this component
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	public constructor(
		public router: Router,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityService: EntityService,
		public activityService: ActivityService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, IWizardContext>}
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the validity change event emitter that will signal to all
	 * listening components that this component has had a change in validity.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	@Output() public validityChange: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	public currentData: any;

	/**
	 * The list of organizations receiving commissions.
	 *
	 * @type {IEntityInstance[]}
	 */
	public organizations: IEntityInstance[] = [];

	/**
	 * Gets or sets the selected organizations.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	public selectedOrganizations: IEntityInstance[] = [];

	/**
	 * Gets or sets the flag indicating if a detailed view should be used.
	 *
	 * @type {boolean}
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	public detailedView: boolean = true;

	/**
	 * Gets or sets the flag indicating the component is loading.
	 *
	 * @type {boolean}
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	public loading: boolean = true;

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.source.wizardStepLoading = true;
		this.currentData =
			this.context.source.activeMenuItem.currentData.data;

		this.organizations = await this.getAgencies();

		this.context.source.addToNext(
			this.createDisbursement
				.bind(this));

		this.validityChanged();

		this.context.source.wizardStepLoading = false;
		this.loading = false;

		this.context.source.changeDetectorReference.detectChanges();
	}

	/**
	 * creates a disbursement dto.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	public async createDisbursement(): Promise<void>
	{
		const disbursements: any[] = [];

		this.selectedOrganizations.forEach(
			(organization: IEntityInstance) =>
			{
				disbursements.push(
					{
						organizationId: organization.id
					});
			});

		const disbursmentInstance: IEntityInstance =
			{
				id: 0,
				versionNumber: 1,
				entityType: InsuranceConstants.insuranceEntityTypes.commissionDisbursement,
				data: {
					disbursements: disbursements
				}
			};

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.commissionDisbursements;

		const createPromise = this.entityInstanceApiService
			.createEntityInstance(
				disbursmentInstance,
				this.currentData.organizationGroup,
				this.currentData.organizationId,
				{
					disbursementDate: this.currentData.snapshotDate,
					commissionEntityTypes:
						this.currentData.commissionEntityTypes.join(
							AppConstants.characters.comma)
				});

		const id: number =
			await this.activityService.handleActivity<number>(
				new Activity<number>(
					createPromise,
					'<strong>Creating Disbursement</strong>',
					'<strong>Disbursement created</strong>',
					'Disbursement has been created.',
					'Disbursement has not been created.'));

		this.navigateToComissionDisbursementPage(id);
	}

	/**
	 * Handles the validity changed event.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	public async validityChanged(): Promise<void>
	{
		const validity: boolean =
			this.selectedOrganizations.length > 0;

		this.context.source.validStepChanged(validity);
	}

	/**
	 * Handles the validity changed event.
	 *
	 * @param {number} id
	 * The id of the entity to navigate to.
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	private navigateToComissionDisbursementPage(
		id: number)
	{
		this.router.navigate(
			[
				`${AppConstants.moduleNames.organizations}/entities`
				+ '/Commission.Disbursements/'
				+ AppConstants.viewTypes.edit
				+ '/' + id
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Gets the agencies.
	 *
	 * @async
	 * @returns {Promise<IEntityInstance[]>}
	 * @memberof SelectDisbursementOrganizationsComponent
	 */
	private async getAgencies(): Promise<IEntityInstance[]>
	{
		const data: any = this.currentData;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			data.organizationGroup;

		const query: string =
			`?commissionOrganizationTypes=${data.commissionEntityTypes.join(
				AppConstants.characters.comma)}`;

		const response: any = await this.entityInstanceApiService
			.executeAction(
				data.organizationId,
				'GetCommissionableAgencies',
				null,
				query);

		const instances: IEntityInstance[] =
			<IEntityInstance[]>response.body.value;

		return instances ?? [];
	}
}