/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './app-footer.component.html'
})

/**
 * A component representing an instance of the application
 * footer.
 *
 * @export
 * @class AppFooterComponent
 */
export class AppFooterComponent
{
}