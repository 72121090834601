<div class="ui-g-12 text-center loading-spinner-container"
	*ngIf="loading === true || dashboardDisplayComplete === false"
	[@contentAnimation]="state">
	<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</div>

<div class="ui-g-12 no-padding {{siteLayoutService.contentCssClass}}"
	*ngIf="loading === false"
	[@contentAnimation]="state">

	<ng-template ngFor let-section
		[ngForOf]="dashboardSections">
		<app-dashboard-section
			[section]="section"
			[parentParameterLayoutData]="parameterLayoutData"
			[pageContext]="pageContext"
			[dashboardDisplayCompleted]="dashboardDisplayComplete"
			(sectionParametersApplied)="updateRouteData.emit($event)"
			(sectionFullyDisplayed)="sectionComponentDisplayed()">
		</app-dashboard-section>
	</ng-template>
</div>