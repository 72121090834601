/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	SessionService
} from '@shared/services/session.service';

/**
 * A class representing the action to log out of the
 * current application and reset the session.
 *
 * @export
 * @class LogOutAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class LogOutAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a LogOutAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof LogOutAction
	 */
	public constructor(
		protected activityService: ActivityService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard,
		private readonly sessionService: SessionService)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof LogOutAction
	 */
	public operationName: string =
		'Logout';

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof LogOutAction
	 */
	public async execute(): Promise<void>
	{
		this.sessionService.logOut();
	}
}