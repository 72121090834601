/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	ModuleWithProviders
} from '@angular/core';
import {
	RouterModule,
	Routes
} from '@angular/router';
import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	LoginComponent
} from '@appComponents/login/login.component';
import {
	InsurancePolicySearchComponent
} from '@insurance/components/insurance-policy-search/insurance-policy-search.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	ModuleGuard
} from '@shared/guards/module.guard';

/* eslint-enable max-len */

export const routes: Routes = [
	{
		path: 'accessDenied',
		component: AccessDeniedComponent,
		canActivate: [
			AppAuthGuard
		],
		data: {
			refreshComponent: true
		}
	},
	{
		path: 'dashboard',
		component: GenericDashboardComponent,
		canActivate: [
			AppAuthGuard,
			ModuleGuard
		],
		data: {
			refreshComponent: true
		}
	},
	{
		path: 'organization',
		canActivate: [
			AppAuthGuard,
			ModuleGuard
		],
		children: [
			{
				path: 'accessDenied',
				component: AccessDeniedComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'dashboard',
				component: GenericDashboardComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'dashboard/:dashboardType',
				component: GenericDashboardComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'display',
				children: [
					{
						path: ':displayComponent/view',
						component: GenericBasePageComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: ':displayComponent/edit',
						component: GenericBasePageComponent,
						canActivate: [
							AppAuthGuard
						],
						data:
						{
							roles: [
								AppConstants
									.securityGroups
									.organizationModuleAccess
							],
							refreshComponent: true
						}
					}
				]
			}
		]
	},
	{
		path: 'test',
		canActivate: [
			AppAuthGuard,
			ModuleGuard
		],
		children: [
			{
				path: 'accessDenied',
				component: AccessDeniedComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'dashboard',
				component: GenericDashboardComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'dashboard/:dashboardType',
				component: GenericDashboardComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'display',
				children: [
					{
						path: ':displayComponent/view',
						component: GenericBasePageComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: ':displayComponent/edit',
						component: GenericBasePageComponent,
						canActivate: [
							AppAuthGuard
						],
						data:
						{
							roles: [
								AppConstants.securityGroups.administrators,
								AppConstants.securityGroups.users
							],
							refreshComponent: true
						}
					}
				]
			}
		]
	},
	{
		path: 'policy',
		canActivate: [
			AppAuthGuard,
			ModuleGuard
		],
		children: [
			{
				path: 'accessDenied',
				component: AccessDeniedComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'dashboard',
				component: GenericDashboardComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'dashboard/:dashboardType',
				component: GenericDashboardComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'policySearch',
				component: InsurancePolicySearchComponent,
				data: {
					refreshComponent: true
				}
			},
			{
				path: 'display',
				children: [
					{
						path: ':displayComponent/view',
						component: GenericBasePageComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: ':displayComponent/edit',
						component: GenericBasePageComponent,
						canActivate: [
							AppAuthGuard
						],
						data:
						{
							roles: [
								AppConstants.securityGroups.policyModuleAccess
							],
							refreshComponent: true
						}
					}
				]
			}
		]
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: '',
		pathMatch: 'full',
		component: GenericDashboardComponent,
		canActivate: [
			AppAuthGuard,
			ModuleGuard
		],
		data: {
			refreshComponent: true
		}
	},
	{
		path: '**',
		pathMatch: 'full',
		component: GenericDashboardComponent,
		canActivate: [
			AppAuthGuard,
			ModuleGuard
		],
		data: {
			refreshComponent: true
		}
	}
];

export const AppRoutes: ModuleWithProviders<RouterModule> =
	RouterModule.forRoot(routes);