<div *ngIf="loading"
	class="text-center ui-g-12 vertical-centered">
	<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
	</span>
</div>

<div *ngIf="!loading && !permissionGranted">
	<ng-template [ngIf]="locationPermissionsState === locationPermissionStates.prompt
		|| locationPermissionsState === locationPermissionStates.unknown"
		[ngIfElse]="permissionDenied">
		<div class="background-image weather-image">
		</div>

		<div class="ui-g-12 location-section font-size flex-display vertical-centered text-center">
			We need your permission to use your location in order to display your local weather. Click "Allow"  to enable.
		</div>
		<div class="ui-g-12 allow-location-button">
			<operation-button-bar
				[initialModel]="allowLocationAction">
			</operation-button-bar>
		</div>
	</ng-template>

	<ng-template #permissionDenied>
		<div class="background-image storm-image">
		</div>
		<div class="ui-g-12 location-section font-size flex-display vertical-centered text-center">
			You have blocked the permission to use your location.
			We need your permission to use your location in order to display your local weather.
			Please go to the browser settings to allow permission.
		</div>
	</ng-template>
</div>

<div *ngIf="!loading && permissionGranted">

	<ng-template [ngIf]="!somethingWentWrong"
		[ngIfElse]="somethingWentWrongView">
		<div class="location-section font-size flex-display">
			<em class="fa fa-map-marker">
			</em>
			{{geographicLocation}}
		</div>

		<div class="ui-g-12 no-padding current-weather-section flex-display">
			<div class="ui-g-4 no-vertical-padding">
				<div class="temperature-section text-center">
					{{currentWeatherForecast.temperature}}°
				</div>
			</div>
			<div class="ui-g-4 no-vertical-padding">
				<div class="image-section flex-display">
					<img [src]="currentWeatherForecast.iconSource"
						alt="Current Weather">
				</div>
			</div>
			<div class="ui-g-4 no-vertical-padding">
				<div class="current-date-section text-center">
					<ng-template [ngIf]="smallWidgetDisplay()"
						[ngIfElse]="desktopView">
						{{currentWeatherForecast.currentUtcDateTime | dateTimeFromIso | dateTimeToTrueLocal | dateTimeToFormat:'ccc' }}
					</ng-template>

					<ng-template #desktopView>
						{{currentWeatherForecast.currentUtcDateTime | dateTimeFromIso | dateTimeToTrueLocal | dateTimeToFormat:'cccc' }}
					</ng-template>
					<br/>
					{{currentWeatherForecast.currentUtcDateTime | dateTimeFromIso | dateTimeToTrueLocal | dateTimeToFormat:'d' }}
				</div>
			</div>
		</div>

		<div class="ui-g-12 no-padding additional-information-container font-size flex-display">
			<span class="ui-g-4 no-padding text-center ellipsis-text">
				<em class="fa fa-location-arrow">
				</em>
				{{currentWeatherForecast.windSpeed}}
			</span>
			<span class="ui-g-4 no-padding text-center ellipsis-text">
				{{weatherDescription}}
			</span>
			<span class="ui-g-4 no-padding text-center ellipsis-text">
				<em class="fa fa-tint">
				</em>
				{{currentWeatherForecast.humidity}}
			</span>
		</div>

		<p-divider>
		</p-divider>

		<div class="ui-g-12 no-padding">
			<ng-template ngFor
				let-hourly
				[ngForOf]="hourlySplitOut">
				<div class="ui-g-2 forecast-section font-size flex-display"
					[ngClass]="{'ui-g-4': smallWidgetDisplay()}">
					<span class="forecast-content flex-display">
						{{hourly.currentUtcDateTime | dateTimeFromIso | dateTimeToTrueLocal | dateTimeToFormat:'h a' }}
						<img [src]="hourly.iconSource"
							alt="Hourly Forecast">
						{{hourly.temperature}}°
					</span>
				</div>
			</ng-template>
		</div>

		<p-divider>
		</p-divider>

		<div class="ui-g-12 no-padding">
			<ng-template ngFor
				let-daily
				[ngForOf]="dailySplitOut">
				<div class="ui-g-2 forecast-section font-size"
					[ngClass]="{'ui-g-4': smallWidgetDisplay()}">
					<span class="forecast-content flex-display">
						{{daily.currentUtcDateTime | dateTimeFromIso | dateTimeToTrueLocal | dateTimeToFormat:'ccc' }}
						<img [src]="daily.iconSource"
							alt="Daily Forecast">
						{{daily.temperature.max}}°
						<span class="min-temperature">
							{{daily.temperature.min}}°
						</span>
					</span>
				</div>
			</ng-template>
		</div>
	</ng-template>

	<ng-template #somethingWentWrongView>
		<div class="background-image storm-image">
		</div>
		<div class="ui-g-12 location-section font-size flex-display vertical-centered text-center">
			Your local weather is not available at this time,
			<br>
			Click "Retry" to enable.
		</div>
		<div class="ui-g-12 allow-location-button">
			<operation-button-bar
				[initialModel]="retryAction">
			</operation-button-bar>
		</div>
	</ng-template>


</div>