<div class="ui-g-12 no-padding"
	*ngIf="!loading; else loadingOrganizations">
	<p-pickList
		sourceHeader="Available"
		targetHeader="Selected"
		[source]="organizations"
		[target]="selectedOrganizations"
		[dragdrop]="false"
		[responsive]="true"
		[sourceStyle]="{'height':'30rem'}"
		[targetStyle]="{'height':'30rem'}"
		[showSourceControls]="false"
		[showTargetControls]="false"
		filterBy="data.name.legalName"
		sourceFilterPlaceholder="Search by Legal Name"
		targetFilterPlaceholder="Search by Legal Name"
		(onMoveToTarget)="validityChanged()"
		(onMoveToSource)="validityChanged()"
		(onMoveAllToSource)="validityChanged()"
		(onMoveAllToTarget)="validityChanged()">
		<ng-template let-organization pTemplate="item">
			<div class="organization-item">
				<div *ngIf="detailedView; else simpleView"
					class="organization-list-detail">
					<span class="panel-sub-title organization-title">
						{{ organization.data.name.legalName || organization.entityType + ' - ' + organization.id }}
					</span>
					<div *ngIf="organization.data.addresses && organization.data.addresses.length > 0"
						class="organization-address">
						{{ organization.data.addresses[0].address }}, {{ organization.data.addresses[0].city }} {{ organization.data.addresses[0].state }}
					</div>
				</div>
				<ng-template #simpleView>
					<div class="organization-list-detail">
						<span class="panel-sub-title organization-title">
							{{ organization.data.name.legalName || organization.entityType + ' - ' + organization.id }}
						</span>
					</div>
				</ng-template>
			</div>
		</ng-template>
	</p-pickList>
	<br/>
</div>

<ng-template #loadingOrganizations>
	<div class="ui-g-12 no-padding text-center">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner"></i>
		<p>
			Loading all commissionable organizations. This may take a couple minutes..
		</p>
	</div>
</ng-template>