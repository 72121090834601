/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * The types of setting preferences stored in the settings service.
 */
export enum SettingPreferenceType
{
	/**
	 * The preference for session settings identifying existing data in
	 * chat sessions.
	 */
	chatDataPreference = 'chatDataPreference',

	/**
	 * The preference for session settings identifying pinned
	 * utility drawers.
	 */
	utilityMenuPinPreference = 'UtilityMenuPinPreference'
}