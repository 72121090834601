<div class="ui-g-12 no-horizontal-padding text-center"
	*ngIf="loading === true"
	[@booleanFadeAnimation]="loading === true">
	<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</div>

<div #StonlyHelp
	class="ui-g-12 no-padding text-center stonly-help-container"
	[@booleanFadeAnimation]="loading === false">
	<iframe
		#StonlyIFrame
		title="Knowledge Base"
		[src]="knowledgeBaseUrl">
	</iframe>
</div>