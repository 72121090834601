<div class="color-swatch">
	<span class="theme-color-primary">
	</span>
	<span class="theme-color-amber">
	</span>
	<span class="theme-color-blue">
	</span>
	<span class="theme-color-purple">
	</span>
	<span class="theme-color-orange">
	</span>
	<span class="theme-color-light-blue">
	</span>
	<span class="theme-color-light-green">
	</span>
	<span class="theme-color-pink">
	</span>
	<span class="theme-color-indigo">
	</span>
	<span class="theme-color-green">
	</span>
	<span class="theme-color-red">
	</span>
	<span class="theme-color-teal">
	</span>
	<span class="theme-color-black">
	</span>
	<span class="theme-color-yellow">
	</span>
	<span class="theme-color-light-gray">
	</span>
	<span class="theme-color-gray">
	</span>
	<span class="theme-color-dark-gray">
	</span>
	<span class="theme-color-success">
	</span>
	<span class="theme-color-info">
	</span>
	<span class="theme-color-warning">
	</span>
	<span class="theme-color-error">
	</span>
</div>