<p-inputMask
	#maskedInput
	[autoClear]="true"
	[mask]="field.props.mask"
	[styleClass]="inputStyleClass"
	[formControl]="formControl"
	[formlyAttributes]="field"
	[placeholder]="field.props.placeholder"
	[ariaLabel]="field.props.label">
</p-inputMask>

<field-validation-message
	[field]="field">
</field-validation-message>
