<div class="information-menu"
	clickOutside
	(clickOutside)="closeOverlayPanel()"
	[contentDisplayed]="overlayActive">

	<div class="information-menu-container">
		<a class="information-menu-button left-button"
			(click)="scrollLeft()"
			[@booleanFadeAnimation]="displayLeftMask === true">
			<span class="information-menu-button-icon fa fa-fw fa-arrow-circle-left">
			</span>
		</a>
		<ul #InformationMenuItems
			class="information-menu-items no-scrollbar"
			[ngClass]="{
				'left-right-mask': (displayLeftMask === true && displayRightMask === true),
				'left-mask': (displayLeftMask === true && displayRightMask === false),
				'right-mask': (displayLeftMask === false && displayRightMask === true),
				'draggable': (displayLeftMask === true || displayRightMask === true)
			}"
			(swipeleft)="swipe($event)"
			(swiperight)="swipe($event)"
			(press)="press()"
			(pressup)="pressUp()"
			(mouseup)="pressUp()">
			<li class="information-menu-item {{item.width}}"
				*ngFor="let item of informationMenuItems">
				<app-summary-card
					[chartDefinition]="item.chartDefinition"
					[groupByCount]="item.chartDefinition?.groupByCount || item.groupByCount"
					[summaryTemplate]="item.summaryTemplate"
					[summaryFunction]="item.chartDefinition?.chartSummaryFunction || item.summaryFunction"
					[summaryPromise]="item.summaryPromise"
					[titleTemplate]="item.titleTemplate"
					[titlePromise]="item.titlePromise"
					[dataPromise]="item.chartDefinition?.dataPromise || item.dataPromise"
					[overlayDynamicComponent]="item.overlayDynamicComponent"
					[overlayDynamicContext]="item.overlayDynamicContext"
					[overlayMenuActive]="overlayActive"
					[summaryCardDisplay]="true"
					(summaryCardClick)="summaryCardClicked($event)">
				</app-summary-card>
			</li>
		</ul>
		<a class="information-menu-button right-button"
			(click)="scrollRight()"
			[@booleanFadeAnimation]="displayRightMask === true">
			<span class="information-menu-button-icon fa fa-fw fa-arrow-circle-right">
			</span>
		</a>
	</div>

	<div class="information-menu-overlay-panel {{siteLayoutService.contentCssClass}}"
		*ngIf="overlayActive && overlayDynamicComponent != null"
		[@easeAnimation]="state"
		[@displayAnimation]="state">
		<div class="overlay-scroll-panel ui-g-12">
			<app-dynamic-component
				[context]="overlayContext"
				[displayComponent]="overlayDynamicComponent">
			</app-dynamic-component>
		</div>
	</div>
</div>