/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IRuleDefinition
} from '@shared/interfaces/rules/rule-definition.interface';

/**
 * A class representing the available methods (business logic) for an
 * entity instance rule violation.
 *
 * @export
 * @class EntityInstanceRuleViolation
 * @implements {IEntityInstanceRuleViolation}
 */
export class EntityInstanceRuleViolation implements IEntityInstanceRuleViolation
{
	/**
	 * Creates an instance of an EntityInstanceRuleViolation.
	 *
	 * @param {IEntityInstanceRuleViolation} iEntityInstanceRuleViolation
	 * The entity instance rule violation to create this new
	 * object from.
	 * @memberof EntityInstanceRuleViolation
	 */
	public constructor(
		public iEntityInstanceRuleViolation: IEntityInstanceRuleViolation)
	{
		Object.assign(this, iEntityInstanceRuleViolation);
	}

	/**
	 * Gets or sets the entity instance rule violation id.
	 *
	 * @type {number}
	 * @memberof EntityInstanceRuleViolation
	 */
	public id: number;

	/**
	 * Gets or sets the entity instance id.
	 *
	 * @type {number}
	 * @memberof EntityInstanceRuleViolation
	 */
	public instanceId: number;

	/**
	 * Gets or sets the rule definition id.
	 *
	 * @type {number}
	 * @memberof EntityInstanceRuleViolation
	 */
	public ruleDefinitionId: number;

	/**
	 * Gets or sets the json data.
	 *
	 * @type {string}
	 * @memberof EntityInstanceRuleViolation
	 */
	public jsonData: string;

	/**
	 * Gets or sets the resource identifier.
	 *
	 * @type {string}
	 * @memberof EntityInstanceRuleViolation
	 */
	public resourceIdentifier: string;

	/**
	 * Gets or sets the rule definition.
	 *
	 * @type {IRuleDefinition}
	 * @memberof EntityInstanceRuleViolation
	 */
	public ruleDefinition: IRuleDefinition;

	/**
	 * Returns the parsed json object from the entity instance rule violation
	 * json data.
	 *
	 * @type {any}
	 * The parsed json data object.
	 * @memberof EntityInstanceRuleViolation
	 */
	public get parsedJson(): any
	{
		return JSON.parse(this.jsonData);
	}

	/**
	 * Returns the message value of the json data.
	 *
	 * @type {string}
	 * @memberof EntityInstanceRuleViolation
	 */
	public get message(): string
	{
		return this.parsedJson[
			this.messageKey];
	}

	/**
	 * Gets the key used to find the message from the
	 * entity instance rule violation.
	 *
	 * @type {string}
	 * @memberof DisplayComponentDefinition
	 */
	private readonly messageKey: string = 'message';
}