<app-base-page pageTitle="Search {{categoryLabel}}"
	[pageContext]="pageContext"
	utilityMenuOperationGroupName="">

	<div class="ui-g">
		<div *ngIf="!loadingCategories">
			<app-search-filter
				[searchOptions]="categoryOptions"
				[loadedFilterCriteria]="filterValue"
				[loadedCategoryOption]="entityTypeGroup"
				(filterCriteriaChanged)="filterCriteriaChanged($event)"
				(categoryChanged)="selectedCategoryChanged($event)">
			</app-search-filter>
		</div>

		<div *ngIf="loadingTableDefinitions"
			class="text-center ui-g-12">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>

		<div class="ui-g-12 table-container"
			*ngIf="!loadingEntityType && !loadingTableDefinitions && entitySearchTableDefinitions != null"
			[@contentAnimation]="state">
			<app-common-table
				[tableDefinitions]="entitySearchTableDefinitions"
				[displayKeywordMessage]="displayKeywordMessage">
			</app-common-table>
		</div>
	</div>

</app-base-page>