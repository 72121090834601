/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	Component,
	ElementRef,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
	selector: 'common-list',
	templateUrl: './common-list.component.html',
	styleUrls: [
		'./common-list.component.scss'
	]
})

/**
 * A component representing a list for displaying summary details
 * of objects.
 *
 * @export
 * @class CommonListComponent
 * @implements {OnInit}
 */
export class CommonListComponent
implements OnInit
{
	/**
	 * Initializes a new instance of the common list component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used for responsive layouts.
	 * @memberof CommonListComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets a collection of enabled list filters to display chips
	 * for within the filter section of the common list view and also used
	 * as the event value for the onFilterChange context method.
	 *
	 * @type {ICommonListFilter[]}
	 * @memberof CommonListComponent
	 */
	@Input()
	public enabledFilters: ICommonListFilter[] = [];

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
			Component,
			ICommonListContext<ICommonListItem<any>[]>>}
	 * @memberof CommonListComponent
	 */
	@Input() public context:
		IDynamicComponentContext<
			Component,
			ICommonListContext<ICommonListItem<any>[]>>;

	/**
	 * Gets or sets the filter chips container.
	 *
	 * @type {ElementRef}
	 * @memberof ExternalReportComponent
	 */
	@ViewChild('FilterChipsContainer')
	public filterChipsContainer: ElementRef;

	/**
	 * Gets or sets the sort menu container.
	 *
	 * @type {ElementRef}
	 * @memberof ExternalReportComponent
	 */
	@ViewChild('SortMenuContainer')
	public sortMenuContainer: ElementRef;

	/**
	 * Gets or sets an offset amount used to calculate remaining screen height
	 * for a full screen height list.
	 * @note
	 * This value defaults to the reserved drawer height value of 126px. This
	 * is the value reserved in larger than tablet mode as mobile calculations
	 * are handled in code.
	 *
	 * @type {number}
	 * @memberof CommonListComponent
	 */
	@Input()
	public listOffset: number = 124;

	/**
	 * Gets or sets a value indicating whether this component is loading data.
	 *
	 * @type {boolean}
	 * @memberof CommonListComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a collection of quick filter menu items to display within
	 * the common list level quick filter menu.
	 *
	 * @type {MenuItem[]}
	 * @memberof CommonListComponent
	 */
	public quickFilterMenuItems: MenuItem[] = [];

	/**
	 * Gets or sets a collection of menu items to add to the context menu
	 * pop out for ordering and sorting.
	 *
	 * @type {MenuItem[]}
	 * @memberof CommonListComponent
	 */
	public sorterMenuItems: MenuItem[] = [];

	/**
	 * Gets or sets the selected and active sorter for the common list view.
	 * Also used as the event value for the onSortChange context method.
	 *
	 * @type {ICommonListSort}
	 * @memberof CommonListComponent
	 */
	public selectedSorter: ICommonListSort;

	/**
	 * Gets or sets the no results found message.
	 *
	 * @type {string}
	 * @memberof CommonListComponent
	 */
	public noResultsMessage: string = AppConstants.noResultsFoundMessage;

	/**
	 * Gets a {string} value representing the item container query selector.
	 *
	 * @private
	 * @type {string}
	 * @memberof CommonListComponent
	 */
	private readonly itemContainerSelector: string = 'div.item-container';

	/**
	 * Gets a {string} value representing the more details query selector.
	 *
	 * @private
	 * @type {string}
	 * @memberof CommonListComponent
	 */
	private readonly moreSelector: string = '.more';

	/**
	 * Gets a {string} value representing the common list container selector.
	 *
	 * @private
	 * @type {string}
	 * @memberof CommonListComponent
	 */
	private readonly commonListSelector: string = '.common-list';

	/**
	 * Gets a {string} value representing the line one common list item
	 * selector.
	 *
	 * @private
	 * @type {string}
	 * @memberof CommonListComponent
	 */
	private readonly line1Selector: string = '.line1';

	/**
	 * Gets a {string} value representing the theme color for
	 * highlighting.
	 *
	 * @private
	 * @type {string}
	 * @memberof CommonListComponent
	 */
	private readonly themeHighlightColor: string = 'primary';

	/**
	 * Gets a {string} value representing the theme color opacity for
	 * highlighting.
	 *
	 * @private
	 * @type {string}
	 * @memberof CommonListComponent
	 */
	private readonly themeHighlightColorOpacity: string = '.1';

	/**
	 * Gets a {string} value representing the selected style class name.
	 *
	 * @private
	 * @type {string}
	 * @memberof CommonListComponent
	 */
	private readonly selectedClass: string = 'selected';

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof CommonListComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		// Map filters to our quick filter menu items.
		this.context.data.filters
			?.forEach((item: ICommonListFilter) =>
				this.addQuickFilter(item));

		// Map sorters to our sorting menu items.
		if (this.context.data.sortable === true
			&& (this.context.data.sorters?.length || 0) > 0)
		{
			this.selectedSorter = this.context.data.sorters[0];

			this.context.data.sorters
				.forEach((sorter: ICommonListSort) =>
					this.addSorter(sorter));
		}

		this.loading = false;
	}

	/**
	 * A method to handle the sort change event.
	 *
	 * @param {Event} event
	 * A value representing the event that the triggered the sort change.
	 * @param {string} name
	 * A value representing the name of the sorter to change to.
	 * @returns {void}
	 * @memberof CommonListComponent
	 */
	public handleSortChangeEvent(
		event: Event,
		name: string): void
	{
		event
			.stopImmediatePropagation();

		this.changeSort(name);
	}

	/**
	 * A method to handle the add filter event.
	 *
	 * @param {Event} event
	 * A value representing the event that triggered the add filter action.
	 * @param {string} name
	 * A value representing the name of the filter to add to the active filter
	 * list.
	 * @returns {void}
	 * @memberof CommonListComponent
	 */
	public handleAddFilterEvent(
		event: Event,
		name: string): void
	{
		event
			.stopImmediatePropagation();

		this.addFilter(
			name,
			null);
	}

	/**
	 * A method to handle the remove filter event.
	 *
	 * @param {Event} event
	 * A value representing the event that triggered the remove filter action.
	 * @param {string} name
	 * A value representing the name of the filter to remove from the active
	 * filter list.
	 * @returns {void}
	 * @memberof CommonListComponent
	 */
	public handleRemoveFilterEvent(
		event: Event,
		name: string): void
	{
		event
			.stopImmediatePropagation();

		this.removeFilter(
			name);
	}

	/**
	 * A method to handle the event of adding a new user input custom
	 * filter to the enabled filters list.
	 *
	 * @note
	 * If a searchFilterFormat is provided, the input value will be interpolated
	 * into that format as the filter value. Otherwise, a default format that
	 * search Keywords column is utilized as the filter value.
	 *
	 * @param {HTMLInputElement} element
	 * A value representing the input box element.
	 * @returns {void}
	 * @memberof CommonListComponent
	 */
	public handleSearchTextAdd(
		element: HTMLInputElement): void
	{
		const inputValue: string = element.value;

		if (AnyHelper.isNullOrWhitespace(inputValue))
		{
			element.focus();

			return;
		}

		if (AnyHelper.isNullOrEmpty(
			this.context.data.searchFilterFormat) === true)
		{
			this.addFilter(
				inputValue,
				`Keywords.Contains('${inputValue}') eq true`);
		}
		else
		{
			this.addFilter(
				inputValue,
				this.interpolate(
					this.context.data.searchFilterFormat,
					{
						inputValue: inputValue
					}));
		}

		element.value = null;
	}

	/**
	 * A method to select an individual list item row and apply styling changes.
	 *
	 * @param {MouseEvent} event
	 * A value representing the event that triggered the selected item change.
	 * @returns {void}
	 * @memberof CommonListComponent
	 */
	public selectItem(
		event: MouseEvent): void
	{
		const target: Element
			= event.target as Element;
		const itemContainer: HTMLElement
			= target.closest(this.itemContainerSelector);
		const commonListContainer: HTMLElement
			= itemContainer.closest(this.commonListSelector);
		const line1Container: HTMLElement
			= itemContainer.querySelector(this.line1Selector);

		const alreadySelected: boolean
			= itemContainer.classList.contains(this.selectedClass);

		this.deselectItems(commonListContainer);

		if (alreadySelected === true)
		{
			return;
		}

		itemContainer.classList.add(this.selectedClass);
		itemContainer.style.backgroundColor
			= this.getThemeColor(
				this.themeHighlightColor,
				this.themeHighlightColorOpacity);

		const maxLine1Height: number = 75;
		if (line1Container.clientHeight === maxLine1Height)
		{
			// Show more if line one is larger than 4 lines.
			line1Container
				.querySelector(this.moreSelector)
				.classList
				.remove(AppConstants.cssClasses.displayNone);
		}
	}

	/**
	 * A method to deselect all selected list items from within the common list.
	 *
	 * @param {HTMLElement} commonListElement
	 * A value representing the common list element.
	 * @returns {void}
	 * @memberof CommonListComponent
	 */
	public deselectItems(
		commonListElement: HTMLElement): void
	{
		commonListElement
			.querySelectorAll(this.itemContainerSelector)
			.forEach((item: Element) =>
			{
				const element: HTMLElement = item as HTMLElement;
				element.style.backgroundColor = null;
				element.classList.remove(this.selectedClass);
				element
					.querySelector(this.moreSelector)
					.classList
					.add(AppConstants.cssClasses.displayNone);
			});
	}

	/**
	 * A method to perform string interpolation based on a input format
	 * and an object.
	 *
	 * @param {string} format
	 * A value representing a string interpolation format to resolve using the
	 * inputted item values.
	 * @param {*} item
	 * A value representing the object to resolve the interpolation format with.
	 * @returns {string}
	 * A fully resolved string interpolation value.
	 * @memberof CommonListComponent
	 */
	public interpolate(
		format: string,
		item: any): string
	{
		return StringHelper
			.interpolate(
				format,
				item);
	}

	/**
	 * Handles changes in the displayed data post data load
	 * or refresh. This will calculate and display the list
	 * in an accurate scroll panel based on filter changes.
	 *
	 * @memberof CommonListComponent
	 */
	public performPostLoadActions(): void
	{
		const mobileOffset: number = 48;
		const chipRowHeight: number = 30;
		const firstRowOffset: number = 5;
		const emptyChipRowOffset: number = 18;
		const commonListContainerSelector: string =
			'.common-list-container';

		setTimeout(() =>
		{
			const offsetHeight =
				this.filterChipsContainer
					?.nativeElement.clientHeight
						|| 0;
			const chipRowCount: number =
				Math.ceil(offsetHeight / chipRowHeight);
			const newOffset: number =
				(chipRowCount === 1
					? firstRowOffset
					: 0)
					+ (chipRowCount * emptyChipRowOffset);

			// Set the scroll panel height.
			const initialListOffset: number =
				this.siteLayoutService.displayTabletView
					? this.listOffset + mobileOffset
					: this.listOffset;
			const scrollPanel: HTMLElement =
				document.querySelector(
					commonListContainerSelector);

			if (!AnyHelper.isNull(scrollPanel))
			{
				scrollPanel.style.height =
					`calc(100vh - ${initialListOffset + newOffset}px)`;
			}
		},
		this.siteLayoutService.debounceDelay);
	}

	/**
	 * Translates a list item into a context ready for use in the sent
	 * listItemComponent.
	 *
	 * @param {any} listItem
	 * The list item to create a context for.
	 * @returns {IDynamicComponentContext<Component, any>}
	 * The dynamic component context for the list item.
	 * @memberof CommonListComponent
	 */
	public getItemContext(
		listItem: any): IDynamicComponentContext<
			Component,
			any>
	{
		return <IDynamicComponentContext<Component, any>>
			{
				data: listItem,
				source: this.context.source
			};
	}

	/**
	 * Allows a click through event to be sent beyond the container handling
	 * this click action.
	 *
	 * @param {any} event
	 * The click event sent from the control.
	 * @memberof CommonListComponent
	 */
	public allowClickThrough(
		event: any): void
	{
		event.preventDefault();
		event.stopPropagation();
	}

	/**
	 * Gets a themed color value computed from the color swatch based
	 * on the current theme colors.
	 *
	 * @param {string} color
	 * The theme based color value to get the computed rgba based
	 * color value of. See: ChartConstants.themeColors.
	 * @returns {string}
	 * The rgba theme color computed from the supplied theme color.
	 * @memberof ChartTransform
	 */
	private getThemeColor(
		color: string,
		opacity: string = '1'): string
	{
		let rawColor: string = getComputedStyle(
			document.querySelector(
				`.theme-color-${color}`))
			.color;

		rawColor = StringHelper.trimRight(rawColor, ')')
			+ `, ${opacity})`;

		return rawColor;
	}

	/**
	 * A method that adds a ICommonListSort object as a MenuItem
	 * to the sorter menu items collection.
	 *
	 * @param {ICommonListSort} sorter
	 * A value to convert to a menu item and add to the sorter menu items
	 * collection.
	 * @memberof CommonListComponent
	 */
	private addSorter(
		sorter: ICommonListSort): void
	{
		this.sorterMenuItems.push(
			<MenuItem>{
				label: sorter.name,
				command: (sortEvent: any) =>
					this.handleSortChangeEvent(
						sortEvent.originalEvent,
						sortEvent.item.label)
			}
		);
	}

	/**
	 * A method that changes the selected sorter for the common list based
	 * on the name of the sorter.
	 *
	 * On sorter change and if specified within the context, the onSortChange
	 * event method will be called with the newly changed selected sorter value.
	 *
	 * @note
	 * If the already selected sorter matches the provided sorter name, then the
	 * direction of the selected sorter is flipped.
	 *
	 * @private
	 * @param {string} name
	 * @memberof CommonListComponent
	 */
	private changeSort(
		name: string): void
	{
		if (this.selectedSorter.name === name)
		{
			// just flip the direction
			this.selectedSorter.direction
				= this.selectedSorter.direction ===
					AppConstants.sortDirections.ascending
					? AppConstants.sortDirections.descending
					: AppConstants.sortDirections.ascending;
		}
		else
		{
			this.selectedSorter = this.context.data.sorters.find(
				(item: ICommonListSort) =>
					item.name === name)
				|| this.context.data.sorters[0];
		}

		if (AnyHelper.isFunction(
			this.context.data.onSortChange))
		{
			this.context.data.onSortChange(
				this,
				this.selectedSorter);
		}
	}

	/**
	 * A method that adds a ICommonListFilter to the quick filter
	 * menu items.
	 *
	 * @private
	 * @param {ICommonListFilter} filter
	 * A value to add to the quick filter menu items.
	 * @memberof CommonListComponent
	 */
	private addQuickFilter(
		filter: ICommonListFilter): void
	{
		this.quickFilterMenuItems.push(
			<MenuItem>{
				label: filter.name,
				command: (actionEvent: any) =>
					this.handleAddFilterEvent(
						actionEvent.originalEvent,
						actionEvent.item.label)
			});
	}

	/**
	 * A method that adds a new filter to the enabled filters list based on the
	 * filter name, or filter name and value specified.
	 *
	 * @note
	 * If a value is not provided, then a lookup will be performed on the
	 * context filters for a filter that matches the provided name. If one is
	 * found (in the case of a quick filter for instance), then that filter will
	 * be added to the enabled filters list with the quick filter value.
	 *
	 * @note
	 * If the context onFilterChange method is specified, this method will
	 * trigger it with the latest collection of enabled filters.
	 *
	 * @private
	 * @param {string} name
	 * A value representing the filter name to add to the enabled filters list.
	 * @param {string} [value]
	 * A value representing the filter value. If not provided, an attempt is
	 * made to find the proper value.
	 * @returns
	 * @memberof CommonListComponent
	 */
	private addFilter(
		name: string,
		value?: string): void
	{
		if (!AnyHelper.isNull(
			this.enabledFilters.find((item: ICommonListFilter) =>
				item.name === name)))
		{
			// Item already exists in the filter array.
			return;
		}

		let filterValue: string = value;
		if (value === null)
		{
			filterValue = this.context.data.filters
				.filter(((item: ICommonListFilter) =>
					item.name === name))[0]?.value;
		}

		this.enabledFilters.push(
			<ICommonListFilter>{
				name: name,
				value: filterValue
			});

		if (AnyHelper.isFunction(
			this.context.data.onFilterChange))
		{
			this.context.data.onFilterChange(
				this,
				this.enabledFilters);
		}
	}

	/**
	 * A method to remove a specific filter from the enabled filters list.
	 *
	 * @note
	 * If the context onFilterChange method is specified, this method will
	 * trigger it with the latest collection of enabled filters.
	 *
	 * @private
	 * @param {string} name
	 * A value representing the name of the filter to remove from the enabled
	 * filter list.
	 * @memberof CommonListComponent
	 */
	private removeFilter(
		name: string): void
	{
		this.enabledFilters
			= this.enabledFilters.filter(
				(item: ICommonListFilter) =>
					item.name !== name);

		if (AnyHelper.isFunction(
			this.context.data.onFilterChange))
		{
			this.context.data.onFilterChange(
				this,
				this.enabledFilters);
		}
	}
}