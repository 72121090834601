/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	IBaseEntity
} from '@api/interfaces/base/base-entity.interface';
import {
	OperationDefinitionParameterApiService
} from '@api/services/operations/operation-definition-parameter.api.service';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	OperationTypeParameterApiService
} from '@api/services/operations/operation-type-parameter.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IOperationDefinitionParameter
} from '@operation/interfaces/operation-definition-parameter.interface';
import {
	IOperationGroup
} from '@operation/interfaces/operation-group.interface';
import {
	IOperationTypeParameter
} from '@operation/interfaces/operation-type-parameter.interface';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-operation-definition',
	templateUrl: './operation-definition.component.html',
	styleUrls: [
		'./operation-definition.component.scss'
	]
})

/**
 * A component representing an instance of the
 * operation definition expand component.
 *
 * @export
 * @class OperationDefinitionComponent
 * @extends {CommonTablePageDirective}
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 */
export class OperationDefinitionComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<CommonTableComponent, any>
{
	/**
	 * Initializes a new instance of the OperationDefinitionComponent class.
	 *
	 * @param {OperationDefinitionParameterApiService}
	 * 	operationDefinitionParameterApiService
	 * The api service used to load operation definition parameter data.
	 * @param {OperationTypeParameterApiService}
	 * 	operationTypeParameterApiService
	 * The api service used to load operation type parameter data.
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The api service used to load operation group data.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The api service used to load operation definition data.
	 * @param {OperationService} operationService
	 * The operation service used for bulk operation service calls.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component to get its data.
	 * @param {ResolverService} resolver
	 * The resolver service used to handle shared logic.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The base class used to detect tree changes.
	 * @memberof OperationDefinitionComponent
	 */
	public constructor(
		public operationDefinitionParameterApiService:
			OperationDefinitionParameterApiService,
		public operationTypeParameterApiService:
			OperationTypeParameterApiService,
		public operationGroupApiService: OperationGroupApiService,
		public operationDefinitionApiService: OperationDefinitionApiService,
		public operationService: OperationService,
		public activityService: ActivityService,
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService,
		private readonly changeDetectorReference: ChangeDetectorRef)
	{
		super(resolver);
		this.loadingTableDefinitions = true;
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof OperationDefinitionComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the table definitions for the parameters common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersTableDefinitionsView: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersViewAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersViewSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the table definitions for the
	 * operation groups common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsTableDefinitionsView: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsViewAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsViewSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the table definitions for the
	 * parameters common table update view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersTableDefinitionsUpdate: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersUpdateAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersUpdateSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the table definitions for the
	 * operation groups common table update view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsTableDefinitionsUpdate: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsUpdateAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsUpdateSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the error message displayed on the formly async validator.
	 *
	 * @type {string}
	 * @memberof OperationDefinitionComponent
	 */
	public asyncValidatorErrorMessage: string;

	/**
	 * Gets or sets the operation groups.
	 *
	 * @type {IOperationGroup[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroups: IOperationGroup[];

	/**
	 * Gets or sets the operation group options.
	 *
	 * @type {object[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupOptions: object[] = [];

	/**
	 * Gets or sets the operation type parameters.
	 *
	 * @type {IOperationTypeParameter[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationTypeParameters: IOperationTypeParameter[];

	/**
	 * Gets or sets the operation type parameter options.
	 *
	 * @type {object[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationTypeParameterOptions: object[] = [];

	/**
	 * Gets or sets the loading operations definition flag.
	 *
	 * @type {boolean}
	 * @memberof OperationDefinitionComponent
	 */
	public loadingOperationDefinition: boolean = true;

	/**
	 * Gets or sets the type id.
	 *
	 * @type {number}
	 * @memberof OperationDefinitionComponent
	 */
	public typeId: number;

	/**
	 * Gets the operation group friendly name.
	 *
	 * @type {string}
	 * @memberof OperationDefinitionComponent
	 */
	private readonly operationGroupName: string =
		'Operation Group';

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof OperationDefinitionComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.parametersViewAvailableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'value',
					columnHeader: 'Value',
					displayOrder: displayOrder
				}
			];
		this.parametersViewSelectedColumns =
			this.parametersViewAvailableColumns;

		this.parametersUpdateAvailableColumns =
			[
				...this.parametersViewSelectedColumns
			];
		this.parametersUpdateSelectedColumns =
			this.parametersUpdateAvailableColumns;

		displayOrder = 1;
		this.operationGroupsViewAvailableColumns =
			[
				{
					dataKey: 'operationGroupName',
					columnHeader: this.operationGroupName,
					displayOrder: displayOrder
				}
			];
		this.operationGroupsViewSelectedColumns =
			this.operationGroupsViewAvailableColumns;

		this.operationGroupsUpdateAvailableColumns =
			[
				...this.operationGroupsViewAvailableColumns
			];
		this.operationGroupsUpdateSelectedColumns =
			this.operationGroupsUpdateAvailableColumns;
	}

	/**
	 * Sets the available parameter options.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	public async setAvailableParameterOptions(): Promise<void>
	{
		const selectedItem: any =
			this.context.source.selectedItem;

		await this.operationService.setStoredVariables();
		this.operationTypeParameterOptions = [];
		this.operationService.operationTypeParameters
			.filter(
				(operationTypeParameter) =>
					operationTypeParameter.typeId === selectedItem.typeId)
			.forEach(
				(operationTypeParameter) =>
				{
					this.operationTypeParameterOptions
						.push(
							{
								value: operationTypeParameter.name,
								label: operationTypeParameter.name
							});
				});
	}

	/**
	 * Sets up the table definitions for a common table
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		if (this.context.source.displayMode !==
			AppConstants.displayMode.create
			&& this.context.source.displayMode !==
				AppConstants.displayMode.delete)
		{
			await this.setupParameterTableDefinitions();
			await this.setupOperationGroupsTableDefinitions();
			await this.getAssociatedSecurityGroup();
		}

		this.loadingOperationDefinition = false;
		this.loadingTableDefinitions = false;

		this.changeDetectorReference.detectChanges();
	}

	/**
	 * Sets up the table definitions for common operation group tables.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	public async setupOperationGroupsTableDefinitions(): Promise<void>
	{
		const selectedItem: any =
			this.context.source.selectedItem;
		await this.dataSetup();

		this.operationGroupsTableDefinitionsView =
			{
				nestedTable: true,
				hideSettings: true,
				tableTitle: 'Associated Operation Groups',
				objectSearch:
					{
						filter: AppConstants.empty,
						orderBy: `Id ${AppConstants.sortDirections.descending}`,
						offset: 0,
						limit: AppConstants.dataLimits.large,
						virtualIndex: 0,
						virtualPageSize: 3
					},
				apiPromise:
					async () =>
						this.getOperationGroupParents(),
				availableColumns:
					this.operationGroupsViewAvailableColumns,
				selectedColumns:
					this.operationGroupsViewSelectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				}
			};

		const operationGroupLayout: FormlyFieldConfig[] =
			[
				{
					key: 'data.operationGroupId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls
							.customFieldWrapper
					],
					props: {
						required: true,
						label: this.operationGroupName,
						placeholder: 'Select an Operation Group',
						options: this.operationGroupOptions,
						showClear: true,
						appendTo: FormlyConstants.appendToTargets.body
					},
					asyncValidators: {
						uniqueOperationGroup: {
							expression: (control: UntypedFormControl) =>
								this.associatedGroup(control),
							message: 'Existing Operation Group.'
						}
					}
				}
			];

		this.operationGroupsTableDefinitionsUpdate =
			{
				tableTitle: 'Associated Operation Groups',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						this.operationGroupName),
				nestedTable: true,
				hideSettings: true,
				actions: {
					create: {
						layout: operationGroupLayout,
						items: [
							{
								label: 'Save',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command:
									async() =>
										this.createGroupAssociation()
							}],
					},
					delete: {
						deleteStatement: async () =>
							'Confirm to remove parent group '
									+ 'association '
									+ selectedItem.operationGroupName
									+ `from operation definition
									${selectedItem.name}.`,
						items: [
							{
								label: 'Remove',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command:
									async() =>
										this.deleteGroupAssociation()
							}]
					}
				},
				objectSearch:
					{
						filter: AppConstants.empty,
						orderBy: `Id ${AppConstants.sortDirections.descending}`,
						offset: 0,
						limit: AppConstants.dataLimits.large,
						virtualIndex: 0,
						virtualPageSize: 3
					},
				apiPromise:
					async () =>
						this.getOperationGroupParents(),
				availableColumns:
					this.operationGroupsUpdateAvailableColumns,
				selectedColumns:
					this.operationGroupsUpdateSelectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				}
			};
	}

	/**
	 * Sets up the table definitions for common parameter tables.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	public async setupParameterTableDefinitions(): Promise<void>
	{
		const selectedItem: any =
			this.context.source.selectedItem;

		this.parametersTableDefinitionsView =
			{
				tableTitle: 'Parameters',
				nestedTable: true,
				hideSettings: true,
				objectSearch: {
					filter: AppConstants.empty,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: 5
				},
				apiPromise: async () =>
				{
					const displayParameters: any[] = [];
					const operationTypeParameters: IOperationTypeParameter[] =
						<IOperationTypeParameter[]>
						await this.operationTypeParameterApiService.query(
							`typeId eq ${selectedItem.typeId}`,
							AppConstants.empty);

					for (const operationTypeParameter
						of operationTypeParameters)
					{
						const operationDefinitionFilterString =
							(!AnyHelper
								.isNullOrEmpty(
									this.parametersTableDefinitionsView
										.objectSearch.filter))
								? this.parametersTableDefinitionsView
									.objectSearch.filter
										+ 'and typeParameterId eq '
										+ operationTypeParameter.id
										+ 'and definitionId eq '
										+ selectedItem.id
								: 'typeParameterId eq '
										+ operationTypeParameter.id
										+ 'and definitionId eq '
										+ selectedItem.id;

						const operationDefinitionParameter:
							IOperationDefinitionParameter =
							await this.operationDefinitionParameterApiService
								.getSingleQueryResult(
									operationDefinitionFilterString,
									AppConstants.empty,
									true);

						if (!AnyHelper.isNull(operationDefinitionParameter))
						{
							displayParameters.unshift(
								this.mapDisplayParameter(
									operationDefinitionParameter,
									operationTypeParameter));
						}
					}

					return displayParameters;
				},
				availableColumns: this.parametersViewAvailableColumns,
				selectedColumns: this.parametersViewSelectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				}
			};

		await this.setAvailableParameterOptions();
		this.parametersTableDefinitionsUpdate =
			{
				tableTitle: 'Parameters',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Operation Parameter'),
				nestedTable: true,
				hideSettings: true,
				actions: {
					create: {
						layout: [
							{
								key: 'data.name',
								type:
									FormlyConstants.customControls.customSelect,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Parameter Type',
									required: true,
									showClear: true,
									placeholder: 'Select a Parameter Type',
									options: this.operationTypeParameterOptions,
									appendTo:
										FormlyConstants.appendToTargets.body
								},
								asyncValidators: {
									uniqueCombination: {
										expression: async (
											control: UntypedFormControl,
											field: FormlyFieldConfig) =>
											this.isExistingType(
												control,
												field),
										message: 'Existing Parameter.'
									}
								}
							},
							{
								key: 'data.value',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Parameter Value',
									required: true
								}
							}
						],
						definition: null,
						items: [
							{
								label: 'Save',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command:
									async() =>
										this.createNewParameter()
							}]
					},
					delete: {
						deleteStatement: async () =>
							`Confirm to remove parameter
								${this.commonTableContext.data.name}
								from operation definition
								${selectedItem.name}.`,
						items: [
							{
								label: 'Remove',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command:
									async() =>
									{
										await this.removeParameter();
									}
							}],
					}
				},
				objectSearch:
					{
						filter: AppConstants.empty,
						orderBy: `Id ${AppConstants.sortDirections.descending}`,
						offset: 0,
						limit: AppConstants.dataLimits.large,
						virtualIndex: 0,
						virtualPageSize: 5
					},
				apiPromise:
					async () =>
					{
						const displayParameters: any[] = [];
						const operationTypeParameters:
							IOperationTypeParameter[] =
							<IOperationTypeParameter[]>
							await this.operationTypeParameterApiService
								.query(
									`typeId eq ${selectedItem.typeId}`,
									AppConstants.empty);

						for (const operationTypeParameter
							of operationTypeParameters)
						{
							const operationDefinitionFilterString = (
								!AnyHelper
									.isNullOrEmpty(
										this.parametersTableDefinitionsUpdate
											.objectSearch.filter))
								? `${this.parametersTableDefinitionsUpdate
									.objectSearch.filter} `
										+ 'and typeParameterId eq '
										+ `${operationTypeParameter.id} `
										+ 'and definitionId eq '
										+ `${selectedItem.id}`
								: 'typeParameterId eq '
									+ `${operationTypeParameter.id} `
									+ 'and definitionId eq '
									+ `${selectedItem.id}`;

							const operationDefinitionParameter:
								IOperationDefinitionParameter =
								await this
									.operationDefinitionParameterApiService
									.getSingleQueryResult(
										operationDefinitionFilterString,
										AppConstants.empty,
										true);

							if (!AnyHelper.isNull(operationDefinitionParameter))
							{
								displayParameters.unshift(
									this.mapDisplayParameter(
										operationDefinitionParameter,
										operationTypeParameter));
							}
						}

						return displayParameters;
					},
				availableColumns: this.parametersUpdateAvailableColumns,
				selectedColumns: this.parametersUpdateSelectedColumns,
				commonTableContext:
					(commonTableContext:
						IDynamicComponentContext<CommonTableComponent, any>) =>
					{
						this.commonTableContext = commonTableContext;
					}
			};
	}

	/**
	 * Gets the security groups associated by operation
	 * definition to be displayed in the update view.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async getAssociatedSecurityGroup(): Promise<void>
	{
		const securityGroups: number[] =
			await this.operationDefinitionApiService
				.getSecurityGroups(
					this.context.source.selectedItem.id);

		this.context.source.selectedItem.securityGroups =
			securityGroups;
	}

	/**
	 * Gets the the validity for an unique
	 * data combination set.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationDefinitionComponent
	 */
	private async isExistingType(
		control: UntypedFormControl,
		field: FormlyFieldConfig): Promise<boolean>
	{
		const typeParameter =
			await this.operationTypeParameterApiService
				.query(
					`Name eq '${control.value}'`,
					AppConstants.empty);
		const operationDefinitionParameter =
			await this.operationDefinitionParameterApiService
				.query(
					'DefinitionId eq '
						+ this.context.source.selectedItem.id
						+ ' and TypeParameterId eq ' + typeParameter[0].id,
					AppConstants.empty);

		const persistedData =
			await this.operationDefinitionApiService
				.query(
					`Id eq ${this.context.source.selectedItem.id}`,
					AppConstants.empty);

		if (persistedData[0].typeId !==
			this.context.source.selectedItem.typeId)
		{
			field.asyncValidators.uniqueCombination.message =
				'Save the updated type prior.';
		}

		return Promise.resolve(
			persistedData[0].typeId ===
				 this.context.source.selectedItem.typeId
				&& operationDefinitionParameter.length === 0);
	}

	/**
	 * Creates and adds a new operation parameter
	 * associated to the operation definition in play.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async createNewParameter(): Promise<void>
	{
		const selectedItem: any =
			this.context.source.selectedItem;
		const typeParameterName =
			await this.operationTypeParameterApiService
				.query(
					`name eq '${selectedItem.name}'`,
					AppConstants.empty);
		const name: string = selectedItem.data.name;

		await this.activityService.handleActivity(
			new Activity(
				this.operationDefinitionParameterApiService
					.create(
						<IBaseEntity>
						{
							definitionId: selectedItem.id,
							typeParameterId: typeParameterName[0].id,
							value: selectedItem.value
						}),
				`<strong>Adding Parameter ${selectedItem.name} `
					+ `to Operation Definition ${name}</strong>`,
				`<strong>Added Parameter ${selectedItem.name} `
					+ `to Operation Definition ${name}</strong>`,
				`Parameter ${selectedItem.name} was added to `
					+ `Operation Definition ${name}.`,
				`Parameter ${selectedItem.name} was not added to `
					+ `Operation Definition ${name}.`));
	}

	/**
	 * Removes an operation parameter
	 * associated to the operation definition in play.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async removeParameter(): Promise<void>
	{
		const selectedItem: any =
			this.context.source.selectedItem;
		const name: string = selectedItem.data.name;

		await this.activityService.handleActivity(
			new Activity(
				this.operationDefinitionParameterApiService
					.delete(
						selectedItem.id),
				`<strong>Removing Parameter ${selectedItem.name} `
					+ `from Operation Definition ${name}</strong>`,
				`<strong>Removed Parameter ${selectedItem.name} `
					+ `from Operation Definition ${name}</strong>`,
				`Parameter ${selectedItem.name} `
					+ 'was removed from Operation Definition '
					+ `${name}.`,
				`Parameter ${selectedItem.name} `
					+ 'was not removed from Operation Definition '
					+ `${name}.`));
	}

	/**
	 * Adds an operation group
	 * associated as the parent of an
	 * operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async createGroupAssociation(): Promise<void>
	{
		const selectedItem: any =
			this.context.source.selectedItem;
		const name: string = selectedItem.data.name;

		await this.activityService.handleActivity(
			new Activity(
				this.operationGroupApiService
					.createChild(
						selectedItem.operationGroupId,
						{
							id: selectedItem.id,
							order: 0,
							type: 'OperationDefinition'
						}),
				`<strong>Adding</strong> Group Association
					${selectedItem.operationGroupName}
					to Operation Definition ${name}`,
				'<strong>Added</strong> Group Association '
					+ `${selectedItem.operationGroupName}`
					+ ` to Operation Definition ${name}`,
				'Group Association '
					+ `${selectedItem.operationGroupName}`
					+ ' was added to Operation Definition '
					+ `${name}.`,
				'Group Association '
					+ `${selectedItem.operationGroupName}`
					+ 'was not added to Operation Definition'
					+ `${name}.`));
	}

	/**
	 * Removes an operation group
	 * associated as the parent of an
	 * operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async deleteGroupAssociation(): Promise<void>
	{
		const selectedItem: any =
			this.context.source.selectedItem;

		await this.activityService.handleActivity(
			new Activity(
				this.operationGroupApiService
					.deleteChild(
						selectedItem.operationGroupId,
						{
							id: selectedItem.id,
							type: 'OperationDefinition'
						}),
				`<strong>Removing</strong> Parent Group Association
					${selectedItem.operationGroupName}
					from Operation Definition ${selectedItem.data.name}`,
				`<strong>Removed</strong> Parent Group Association
					${selectedItem.operationGroupName}
					from Operation Definition ${selectedItem.data.name}`,
				`Parent Group Association
					${selectedItem.operationGroupName}
					was removed from Operation Definition
					${selectedItem.data.name}.`,
				`Parent Group Association
					${selectedItem.operationGroupName}
					was not removed from Operation Definition
					${selectedItem.data.name}.`));
	}

	/**
	 * Validates if there is any
	 * associated Operation Group existing.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationDefinitionComponent
	 */
	private async associatedGroup(
		control: UntypedFormControl): Promise<boolean>
	{
		let isNotExisting = true;

		const childOperationGroupHierarchies =
			await this.operationGroupApiService
				.query(
					'ChildOperationGroupHierarchies.Any(ChildDefinitionId eq ' +
						this.context.source.selectedItem.id + ')',
					AppConstants.empty);

		childOperationGroupHierarchies.forEach(
			(operationGroup: IBaseEntity) =>
			{
				if (control.value === operationGroup.id)
				{
					isNotExisting = false;
				}
			});

		return Promise.resolve(isNotExisting);
	}

	/**
	 * Maps a Display Parameter object.
	 *
	 * @param {IOperationDefinitionParameter} operationDefinitionParameter
	 * The operation definition parameter.
	 * @param {IOperationTypeParameter} operationTypeParameter
	 * The operation type parameter.
	 * @returns {any[]}
	 * The display parameter.
	 * @memberof OperationDefinitionComponent
	 */
	private mapDisplayParameter(
		operationDefinitionParameter: IOperationDefinitionParameter,
		operationTypeParameter: IOperationTypeParameter): any
	{
		return <any> {
			id: operationDefinitionParameter.id,
			name: operationTypeParameter.name,
			value: operationDefinitionParameter.value
		};
	}

	/**
	 * Sets up the data needed for the common table display.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async dataSetup(): Promise<void>
	{
		await this.operationService.setStoredVariables();
		this.operationService.operationGroups.forEach(
			(operationGroup) =>
			{
				this.operationGroupOptions
					.push(
						{
							value: operationGroup.id,
							label: operationGroup.name
						});
			});
	}

	/**
	 * Gets operation group parents.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async getOperationGroupParents(): Promise<any[]>
	{
		const operationGroupsParents: any[] = [];

		const childOperationGroupHierarchies =
			await this.operationGroupApiService
				.query(
					'ChildOperationGroupHierarchies.Any('
						+ 'ChildDefinitionId eq '
						+ this.context.source.selectedItem.id + ')',
					AppConstants.empty);

		childOperationGroupHierarchies
			.forEach(
				(childOperationGroupHierarchy) =>
				{
					operationGroupsParents.push(
						{
							id:
								childOperationGroupHierarchy.id,
							operationGroupId:
								childOperationGroupHierarchy.id,
							operationGroupName:
								childOperationGroupHierarchy.name
						}
					);
				});

		return operationGroupsParents;
	}
}