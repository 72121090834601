<span>
	<ng-template
		[ngIf]="context.source.displayMode === 'View'"
		[ngIfElse]="displayUpdate">
		<div class="ui-g-6 no-padding"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div class="section-margin">
				<app-dynamic-formly
					[dataSet]="context.source.selectedItem"
					[layoutSchema]="context.source.formlyDefinitions.layout"
					(validityChanged)="context.source.validExpandComponentChanged($event)">
				</app-dynamic-formly>
			</div>
		</div>
		<div class="ui-g-6"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div *ngIf="!loadingTableDefinitions">
				<app-common-table
					[tableDefinitions]="childrenTableDefinitionsView">
				</app-common-table>
			</div>
		</div>
		<div *ngIf="loadingTableDefinitions"
			class="ui-g-6"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<span class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</span>
		</div>
	</ng-template>

	<ng-template #displayUpdate>
		<div class="ui-g-6 no-padding"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div class="section-margin">
				<app-dynamic-formly
					[dataSet]="context.source.selectedItem"
					[layoutSchema]="context.source.formlyDefinitions.layout"
					(validityChanged)="context.source.validExpandComponentChanged($event)">
				</app-dynamic-formly>
			</div>
		</div>
		<div class="ui-g-6"
			*ngIf="!loadingTableDefinitions"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div>
				<app-common-table
					[tableDefinitions]="childrenTableDefinitionsUpdate">
				</app-common-table>
			</div>
		</div>
		<div class="ui-g-6 spinner-section"
			*ngIf="loadingTableDefinitions"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<span class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</span>
		</div>
	</ng-template>
</span>
