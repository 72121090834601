/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	DomSanitizer,
	SafeResourceUrl
} from '@angular/platform-browser';
import {
	BooleanFadeAnimation
} from '@shared/app-animations';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	MouseEventConstants
} from '@shared/constants/mouse-event.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	SessionService
} from '@shared/services/session.service';
import {
	StonlyService
} from '@shared/services/stonly.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-stonly-help',
	templateUrl: './stonly-help.component.html',
	styleUrl: './stonly-help.component.scss',
	animations: [
		BooleanFadeAnimation
	]
})

/**
 * A component representing a stonly help component.
 *
 * @export
 * @class StonlyHelpComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @implements {IDynamicComponent<Component, any>}
 */
export class StonlyHelpComponent
implements OnInit, AfterViewInit, OnDestroy, IDynamicComponent<Component, any>
{
	/**
	 * Creates an instance of StonlyHelpComponent.
	 *
	 * @param {StonlyService} stonlyService
	 * The Stonly service.
	 * @param {DomSanitizer} sanitizer
	 * The dom sanitizer used to set safe html values for the knowledge base
	 * source.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @memberof StonlyHelpComponent
	 */
	public constructor(
		public stonlyService: StonlyService,
		public sanitizer: DomSanitizer,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the component container.
	 *
	 * @type {ElementRef<HTMLDivElement>}
	 * @memberof StonlyHelpComponent
	 */
	@ViewChild('StonlyHelp')
	public stonlyHelpContainer: ElementRef<HTMLDivElement>;

	/**
	 * Gets or sets the knowledge base iFrame.
	 *
	 * @type {ElementRef<HTMLIFrameElement>}
	 * @memberof StonlyHelpComponent
	 */
	@ViewChild('StonlyIFrame')
	public stonlyIFrame: ElementRef<HTMLIFrameElement>;

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof StonlyHelpComponent
	 */
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the knowledge base url for the stonly widget.
	 *
	 * @type {SafeResourceUrl}
	 * @memberof StonlyHelpComponent
	 */
	public knowledgeBaseUrl: SafeResourceUrl;

	/**
	 * Gets or sets the loading value of the iframe component.
	 *
	 * @type {boolean}
	 * @memberof StonlyHelpComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the mutation observer for the stonly widget.
	 *
	 * @type {MutationObserver}
	 * @memberof StonlyHelpComponent
	 */
	public observer: MutationObserver;

	/**
	 * Gets or sets the initial load complete value for the stonly widget.
	 *
	 * @type {boolean}
	 * @memberof StonlyHelpComponent
	 */
	private initialLoadComplete: boolean = false;

	/**
	 * Gets the cutom tooltip class for the stonly widget tooltip.
	 * See: https://stonly.com/kb/en/.
	 *
	 * @type {string}
	 * @memberof StonlyHelpComponent
	 */
	private readonly customTooltipClass: string =
		'custom-help-tooltip';

	/**
	 * Handles the site layout change event.
	 * This will ensure the stonly widget remains accurately positioned.
	 *
	 * @memberof StonlyHelpComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		const stonlyWidget: HTMLElement =
			this.getStonlyWidget();

		if (AnyHelper.isNull(stonlyWidget))
		{
			return;
		}

		this.positionStonlyWidget(
			document.querySelector(
				`.${AppConstants.cssClasses.stonlyWidget}`));
	}

	/**
	 * Implements the OnInit lifecycle method.
	 * Handles the stonly library.
	 *
	 * @memberof StonlyHelpComponent
	 */
	public ngOnInit(): void
	{
		this.knowledgeBaseUrl =
			this.sanitizer.bypassSecurityTrustResourceUrl(
				this.sessionService.systemSettings
					.knowledgeBase
					.knowledgeBaseUrl);

		this.installStonly();
	}

	/**
	 * Implements the AfterViewInit lifecycle method.
	 * Handles the display of the stonly widget.
	 *
	 * @memberof StonlyHelpComponent
	 */
	public ngAfterViewInit(): void
	{
		// Capture the iframe load event.
		this.stonlyIFrame.nativeElement.onload =
			this.IFrameLoaded.bind(this);

		let stonlyWidget: HTMLElement =
			this.getStonlyWidget();

		// Handle existing stonly widget instances.
		if (!AnyHelper.isNull(stonlyWidget))
		{
			this.initialLoadComplete = true;

			this.initializeStonlyWidget(
				stonlyWidget);

			return;
		}

		// Handle a new stonly widget instance.
		this.observer =
			new MutationObserver(
				(mutationsList: MutationRecord[]) =>
				{
					for (const mutation of mutationsList)
					{
						if (mutation.type ===
							AppConstants.mutationRecordTypes.childList)
						{
							stonlyWidget =
								this.getStonlyWidget();
							if (!AnyHelper.isNull(stonlyWidget))
							{
								this.initialLoadComplete = false;

								this.initializeStonlyWidget(
									stonlyWidget);
							}
						}
					}
				});

		this.observer.observe(
			document,
			{
				childList: true,
				subtree: true
			});
	}

	/**
	 * Implements the onDestroy lifecycle method.
	 * Clears existing subscriptions and hides the stonly widget.
	 *
	 * @memberof StonlyHelpComponent
	 */
	public ngOnDestroy(): void
	{
		this.cleanupStonlyWidget();

		if (!AnyHelper.isNull(this.observer))
		{
			this.observer.disconnect();
		}
	}

	/**
	 * Handles the iframe loaded event.
	 *
	 * @memberof StonlyHelpComponent
	 */
	public IFrameLoaded(): void
	{
		this.loading = false;
	}

	/**
	 * Installs the stonly widget javascript if not already existing.
	 *
	 * @memberof StonlyHelpComponent
	 */
	private installStonly(): void
	{
		const enabled: boolean =
			!AnyHelper.isNull(
				this.sessionService.systemSettings
					?.walkthrough
					?.enabled)
				&& this.sessionService.systemSettings
					.walkthrough
					.enabled === true;

		if (enabled === false)
		{
			return;
		}

		this.stonlyService.installStonly(
			this.sessionService.systemSettings
				.walkthrough
				.widgetId);
	}

	/**
	 * Cleans up the stonly widget.
	 *
	 * @memberof StonlyHelpComponent
	 */
	private cleanupStonlyWidget(): void
	{
		this.displayStonlyWidget(
			this.getStonlyWidget(),
			false);

		// Notify listeners that the guided tour is now closed.
		EventHelper
			.dispatchWalkthroughEndEvent();
	}

	/**
	 * Gets the stonly widget.
	 *
	 * @returns {any}
	 * The stonly widget.
	 * @memberof StonlyHelpComponent
	 */
	private getStonlyWidget(): any
	{
		return document.querySelector(
			`.${AppConstants.cssClasses.stonlyWidget}`);
	}

	/**
	 * Initializes the stonly widget.
	 *
	 * @param {HTMLElement} stonlyWidget The stonly widget to initialize.
	 * @memberof StonlyHelpComponent
	 */
	private initializeStonlyWidget(
		stonlyWidget: HTMLElement): void
	{
		this.positionStonlyWidget(
			stonlyWidget);

		this.displayStonlyWidget(
			stonlyWidget);

		this.initialLoadComplete = true;
	}

	/**
	 * Displays or hides the stonly widget.
	 *
	 * @param {HTMLElement} stonlyWidget
	 * The stonly widget to display or hide.
	 * @param {boolean} display
	 * A value indicating whether to display the stonly widget. This value
	 * defaults to true.
	 * @memberof StonlyHelpComponent
	 */
	private displayStonlyWidget(
		stonlyWidget: HTMLElement,
	 	display: boolean = true): void
	{
		if (AnyHelper.isNull(stonlyWidget))
		{
			return;
		}

		stonlyWidget.style.display =
			display === true
				? AppConstants.displayTypes.block
				: AppConstants.displayTypes.none;

		if (display === true)
		{
			this.stonlyService.initializeWidget(
				this.hideTooltip.bind(this));
		}
		else
		{
			this.hideTooltip();
			this.stonlyService.closeWidget();
		}
	}

	/**
	 * Positions the stonly widget in the help drawer.
	 *
	 * @param {HTMLElement} stonlyWidget
	 * The stonly widget to position.
	 * @memberof StonlyHelpComponent
	 */
	private positionStonlyWidget(
		stonlyWidget: HTMLElement): void
	{
		const boundingContainer: DOMRect =
			this.stonlyHelpContainer
				?.nativeElement
				?.getBoundingClientRect();
		if (AnyHelper.isNull(boundingContainer))
		{
			return;
		}

		const widgetContainer: any =
			stonlyWidget?.childNodes[0]?.childNodes[0];
		if (AnyHelper.isNull(widgetContainer))
		{
			return;
		}

		const paddingOffset: number =
			AppConstants.staticLayoutSizes.helpIconWidth
				+ AppConstants.staticLayoutSizes.helpIconPadding;
		const drawerOverlayUsed: boolean =
			this.initialLoadComplete === true
				&& !AnyHelper.isNull(
					this.stonlyHelpContainer?.nativeElement
						.closest(
							`.${AppConstants.cssClasses.overlayDrawer}`));
		const offset: number =
			window.innerWidth
				- boundingContainer.left
				- paddingOffset
				+ (drawerOverlayUsed
					? AppConstants.staticLayoutSizes.drawerWidth
					: 0);
		widgetContainer.style.right =
			`${offset}px`;

		this.createStonlyWidgetTooltip(
			stonlyWidget,
			offset);
	}

	/**
	 * Creates a stonly widget tooltip.
	 *
	 * @param {HTMLElement} stonlyWidget
	 * The stonly widget to create a tooltip for.
	 * @param {number} widgetOffset
	 * The widget offset to create a tooltip for.
	 * @memberof StonlyHelpComponent
	 */
	private createStonlyWidgetTooltip(
		stonlyWidget: HTMLElement,
		widgetOffset: number): void
	{
		const existingTooltip: HTMLElement =
			document.querySelector(
				`.${this.customTooltipClass}`);
		const rightOffset: number =
			widgetOffset
				+ AppConstants.staticLayoutSizes.helpIconWidth
				+ AppConstants.staticLayoutSizes.standardPadding;

		if (!AnyHelper.isNull(existingTooltip))
		{
			existingTooltip.style.right =
				`${rightOffset}px`;

			return;
		}

		const tooltip: HTMLElement =
			document.createElement(
				AppConstants.documentElementTypes.div);
		tooltip.innerText = 'View Page Guide';
		tooltip.style.display = AppConstants.displayTypes.none;
		tooltip.style.position = 'fixed';
		tooltip.style.bottom =
			`${AppConstants.staticLayoutSizes.helpIconPadding
				+ AppConstants.staticLayoutSizes.tinyPadding}px`;
		tooltip.style.right =
			`${rightOffset}px`;
		tooltip.className =
			`${this.customTooltipClass} ` +
				AppConstants.cssClasses.customTooltip;
		document.body.appendChild(tooltip);

		stonlyWidget.addEventListener(
			MouseEventConstants.mouseOver,
			() =>
			{
				tooltip.style.display = AppConstants.displayTypes.block;
			});
		stonlyWidget.addEventListener(
			MouseEventConstants.mouseOut,
			() =>
			{
				tooltip.style.display = AppConstants.displayTypes.none;
			});
	}

	/**
	 * Hides the stonly widget tooltip.
	 *
	 * @memberof StonlyHelpComponent
	 */
	private hideTooltip(): void
	{
		const tooltip: HTMLElement =
			document.querySelector(
				`.${this.customTooltipClass}`);

		if (!AnyHelper.isNull(tooltip))
		{
			tooltip.style.display = AppConstants.displayTypes.none;
		}
	}
}