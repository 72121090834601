
<div class="app-profile"
	clickOutside
	(clickOutside)="closeList()"
	[contentDisplayed]="active">
	<div class="profile layout-menu"
		[ngClass]="{
			'profile-expanded': active === true
		}"
		*ngIf="this.user != null"
		(tap)="profileDisplayClick()">
		<a>
			<img class="profile-image"
				alt="User image"
				src="https://www.gravatar.com/avatar/{{this.userEmailHash}}?d=identicon&s=45" />
			<div class="user-details-container">
				<div class="profile-name">
					{{this.userDisplayName}}
				</div>
				<div class="profile-title">
					{{this.userTitle}}
				</div>
			</div>
			<div class="layout-menu-tooltip">
				<div class="layout-menu-tooltip-arrow">
				</div>
				<div class="layout-menu-tooltip-text">
					{{this.userDisplayName}}
				</div>
			</div>
		</a>
	</div>
</div>