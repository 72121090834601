/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDifferenceDefinition
} from '@shared/interfaces/application-objects/difference-definition.interface';
import {
	IEntityInstanceHistory
} from '@shared/interfaces/entities/entity-instance-history.interface';

/**
 * A class containing static helper methods for entity based logic.
 *
 * @export
 * @class EntityArrayHelper
 */
export class EntityArrayHelper
{
	/**
	 * Given a set of entities this will decorate the primary business
	 * logic differences found from the previous history record
	 * in the given array order.
	 *
	 * @static
	 * @param {string} comparisonObjectName
	 * The business logic item name.
	 * @param {IEntityInstanceHistory[]} entityInstances
	 * The loaded set of entity instance history data items, these will
	 * be converted to history records in this method.
	 * @param {string[]} excludedDifferenceProperties
	 * The set of differences to exclude from this collection.
	 * @returns {IEntityInstance[]}
	 * A decorated set of entity instance records with business logic history
	 * differences from their previous set.
	 * Note: These will be decorated as entityInstance.previousHistoryRecord,
	 * entityInstance.previousHistoryRecordDifferences,
	 * and entityInstance.previousHistoryRecordDifferenceSummary.
	 * @memberof EntityArrayHelper
	 */
	public static decorateBusinessLogicDifferences(
		comparisonObjectName: string,
		entityInstances: IEntityInstanceHistory[],
		excludedDifferenceProperties: string[]): IEntityInstanceHistory[]
	{
		if (entityInstances.length === 0)
		{
			return [];
		}

		let previousEntityInstance: IEntityInstanceHistory =
			<IEntityInstanceHistory>{};
		for (const entityInstance of entityInstances)
		{
			const mappedDifferences: IDifferenceDefinition[] =
				ObjectHelper.getBusinessLogicDifferences(
					comparisonObjectName,
					previousEntityInstance,
					entityInstance,
					excludedDifferenceProperties);

			const differenceProperties: string[] = [];
			mappedDifferences.forEach(
				(difference: IDifferenceDefinition) =>
				{
					const splitKey: string[] =
						difference.key.split(
							AppConstants.characters.period);
					differenceProperties.push(
						StringHelper.beforeCapitalSpaces(
							StringHelper.toProperCase(
								splitKey.length < 3
									? splitKey[1]
									: splitKey[2])));
				});

			const differenceSummary: string =
				[...new Set(differenceProperties)]
					.join(AppConstants.characters.comma
						+ AppConstants.characters.space);

			if (!AnyHelper.isNullOrWhitespace(differenceSummary))
			{
				entityInstance.previousHistoryRecord =
					previousEntityInstance;
				entityInstance.previousHistoryRecordDifferences =
					mappedDifferences;
				entityInstance.previousHistoryRecordDifferenceSummary =
					differenceSummary;
			}

			previousEntityInstance = entityInstance;
		}

		return entityInstances;
	}
}