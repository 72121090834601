/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	HostListener,
	OnInit
} from '@angular/core';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IWeatherForecast
} from '@shared/interfaces/weather/weather-forecast.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	WeatherForecastService
} from '@shared/services/weather-forecast.service';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

@Component({
	selector: 'app-weather-forecast',
	templateUrl: './weather-forecast.component.html',
	styleUrls: [
		'./weather-forecast.component.scss'
	]
})

/**
 * A component representing an instance of the
 * weather forecast component.
 *
 * @export
 * @class WeatherForecastComponent
 * @implements {OnInit}
 * The data type returned in the data promise.
 */
export class WeatherForecastComponent
implements OnInit
{
	/** Initializes a new instance of the WeatherForecastComponent.
	 *
	 * @param {WeatherForecastService} weatherForecastService
	 * The weather forecast service to get the data for this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to handle responsive views.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof WeatherForecastComponent
	 */
	public constructor(
		public weatherForecastService: WeatherForecastService,
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService)
	{
	}

	/**
	 * Gets or sets the current weather forecast.
	 *
	 * @type {IWeatherForecast}
	 * @memberof WeatherForecastComponent
	 */
	public currentWeatherForecast: IWeatherForecast;

	/**
	 * Gets or sets the formatted weather description.
	 *
	 * @type {string}
	 * @memberof WeatherForecastComponent
	 */
	public weatherDescription: string;

	/**
	 * Gets or sets the hourly weather forecast.
	 *
	 * @type {IWeatherForecast[]}
	 * @memberof WeatherForecastComponent
	 */
	public hourlyWeatherForecast: IWeatherForecast[];

	/**
	 * Gets or sets the hourly split out.
	 *
	 * @type {IWeatherForecast[]}
	 * @memberof WeatherForecastComponent
	 */
	public hourlySplitOut: IWeatherForecast[];

	/**
	 * Gets or sets the daily weather forecast.
	 *
	 * @type {IWeatherForecast[]}
	 * @memberof WeatherForecastComponent
	 */
	public dailyWeatherForecast: IWeatherForecast[];

	/**
	 * Gets or sets the daily split out.
	 *
	 * @type {IWeatherForecast[]}
	 * @memberof WeatherForecastComponent
	 */
	public dailySplitOut: IWeatherForecast[];

	/**
	 * Gets or sets the geographic location response.
	 *
	 * @type {string}
	 * @memberof WeatherForecastComponent
	 */
	public geographicLocation: string;

	/**
	 * Gets or sets the loading truthy for this component.
	 *
	 * @type {boolean}
	 * @memberof WeatherForecastComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the permissions granted truthy.
	 *
	 * @type {boolean}
	 * @memberof WeatherForecastComponent
	 */
	public permissionGranted: boolean;

	/**
	 * Gets or sets if something went wrong.
	 *
	 * @type {boolean}
	 * @memberof WeatherForecastComponent
	 */
	public somethingWentWrong: boolean = false;

	/**
	 * Gets or sets allow location menu
	 * item action.
	 *
	 * @type {MenuItem[]}
	 * @memberof WeatherForecastComponent
	 */
	public allowLocationAction: MenuItem[];

	/**
	 * Gets or sets retry action menu iem.
	 *
	 * @type {MenuItem[]}
	 * @memberof WeatherForecastComponent
	 */
	public retryAction: MenuItem[];

	/**
	 * Gets or sets the current location permission state
	 *
	 * @type {string}
	 * @memberof WeatherForecastComponent
	 */
	public locationPermissionsState: string;

	/**
	 * Sets the location permission available states.
	 *
	 * @type {{
		prompt: string;
		granted: string;
		denied: string;
		unknown: string;
	}}
	 * @memberof WeatherForecastComponent
	 */
	public readonly locationPermissionStates:
	{
		prompt: string;
		granted: string;
		denied: string;
		unknown: string;
	} = {
			prompt: 'prompt',
			granted: 'granted',
			denied: 'denied',
			unknown: 'unknown'
		};

	/**
	 * Sets the daily splitout end index.
	 *
	 * @type {{
		small: number;
		large: number;
	}}
	 * @memberof WeatherForecastComponent
	 */
	private readonly dailySplitoutEndIndex:
	{
		small: number;
		large: number;
	} = {
			small: 3,
			large: 6
		};

	/**
	 * Sets the hourly splitout end index.
	 *
	 * @type {{
		small: number;
		large: number;
	}}
	 * @memberof WeatherForecastComponent
	 */
	private readonly hourlySplitoutEndIndex:
	{
		small: number;
		large: number;
	} = {
			small: 4,
			large: 7
		};

	/**
	 * Handles the site layout changes.
	 * This will slice the hourly and daily objects
	 * based on the available breakpoint width.
	 *
	 * @async
	 * @memberof WeatherForecastComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.setHourlySplitOut();
		this.setDailySplitOut();
	}

	 /**
	 * Handles the on initilazation event for this component.
	 * This will set the current, hourly and
	 * daily forecast data.
	 *
	 * @async
	 * @memberof WeatherForecastComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.setAllowLocationMenuAction();
		await this.handleLocationPermissions();
		if (this.permissionGranted !== true)
		{
			this.loading = false;

			return;
		}

		await this.buildWeatherContent();
	}

	/**
	 * Handles the location permissions for this component.
	 *
	 * @async
	 * @memberof WeatherForecastComponent
	 */
	private setAllowLocationMenuAction(): void
	{
		const commandAction: () => {} =
			async () =>
			{
				this.loading = true;
				await this.buildWeatherContent();
			};
		this.allowLocationAction =
			[
				{
					label: 'Allow',
					id: 'allowLocationAction',
					command: commandAction
				}
			];

		this.retryAction =
			[
				{
					label: 'Retry',
					id: 'retryAction',
					command: commandAction
				}
			];
	}

	/**
	 * Handles the location permissions for this component.
	 *
	 * @async
	 * @memberof WeatherForecastComponent
	*/
	private async handleLocationPermissions(
		state = AppConstants.navigatorPermissionStates.unknown): Promise<void>
	{
		this.locationPermissionsState =
			await this.weatherForecastService.getGeolocationPermissions(state);

		this.permissionGranted =
			this.locationPermissionsState ===
				this.locationPermissionStates.granted;
	}

	 /**
	 * Builds the weather content for this component.
	 *
	 * @async
	 * @memberof WeatherForecastComponent
	 */
	private async buildWeatherContent(): Promise<void>
	{
		try
		{
			await this.setCurrentForecast();
			await this.setDailyForecast();
			await this.setHourlyForecast();
			this.weatherDescription =
				StringHelper.capitalizeAllWords(
					this.currentWeatherForecast.weatherDescription);
			const geographicLocations: any =
				await this.weatherForecastService
					.getGeographicLocation();
			this.geographicLocation =
				geographicLocations.length > -1
					? geographicLocations[0]?.name
					: 'unknown';

			await this.handleLocationPermissions(
				this.locationPermissionStates.granted);
		}
		catch
		{
			await this.handleLocationPermissions(
				this.locationPermissionStates.denied);
		}

		this.somethingWentWrong =
			AnyHelper.isNullOrEmpty(this.currentWeatherForecast)
				|| AnyHelper.isNullOrEmpty(this.geographicLocation)
				|| AnyHelper.isNullOrEmpty(this.hourlySplitOut)
				|| AnyHelper.isNullOrEmpty(this.dailySplitOut);

		this.loading = false;
	}

	 /**
	 * Sets the current forecast.
	 *
	 * @async
	 * @memberof WeatherForecastComponent
	 */
	private async setCurrentForecast(): Promise<void>
	{
		this.currentWeatherForecast =
			await this.weatherForecastService.getCurrentForecast();

		this.currentWeatherForecast.iconSource =
			this.weatherForecastService.getWeatherIconSource(
				this.currentWeatherForecast.weatherIcon,
				AppConstants.sizeIdentifiers.medium);

		this.currentWeatherForecast.temperature =
			parseInt(
				this.currentWeatherForecast.temperature.toFixed(0),
				AppConstants.parseRadix);
	}

	 /**
	 * Sets the hourly forecast.
	 *
	 * @async
	 * @memberof WeatherForecastComponent
	 */
	private async setHourlyForecast(): Promise<void>
	{
		this.hourlyWeatherForecast =
			await this.weatherForecastService.getHourlyForecast();

		for (const hourlyForecast of this.hourlyWeatherForecast)
		{
			hourlyForecast.iconSource =
				this.weatherForecastService.getWeatherIconSource(
					hourlyForecast.weatherIcon);

			hourlyForecast.temperature =
				parseInt(
					hourlyForecast.temperature.toFixed(0),
					AppConstants.parseRadix);
		}

		this.setHourlySplitOut();
	}

	 /**
	 * Sets the daily forecast.
	 *
	 * @async
	 * @memberof WeatherForecastComponent
	 */
	private async setDailyForecast(): Promise<void>
	{
		this.dailyWeatherForecast =
			await this.weatherForecastService.getDailyForecast();

		for (const dailyForecast of this.dailyWeatherForecast)
		{
			dailyForecast.iconSource =
				this.weatherForecastService.getWeatherIconSource(
					dailyForecast.weatherIcon);

			dailyForecast.temperature.min =
				parseInt(
					dailyForecast.temperature.min.toFixed(0),
					AppConstants.parseRadix);

			dailyForecast.temperature.max =
				parseInt(
					dailyForecast.temperature.max.toFixed(0),
					AppConstants.parseRadix);
		}

		this.setDailySplitOut();
	}

	 /**
	 * Sets the daily split out.
	 *
	 * @memberof WeatherForecastComponent
	 */
	private setDailySplitOut(): void
	{
		const dailyForecastEndIndex =
			this.getForecastEndIndex(
				this.dailySplitoutEndIndex.small,
				this.dailySplitoutEndIndex.large);

		this.dailySplitOut =
			this.dailyWeatherForecast?.slice(
				0,
				dailyForecastEndIndex);
	}

	 /**
	 * Sets the hourly split out.
	 *
	 * @memberof WeatherForecastComponent
	 */
	private setHourlySplitOut(): void
	{
		const hourlyForecastEndIndex =
			this.getForecastEndIndex(
				this.hourlySplitoutEndIndex.small,
				this.hourlySplitoutEndIndex.large);

		this.hourlySplitOut =
			this.hourlyWeatherForecast?.slice(
				1,
				hourlyForecastEndIndex);
	}

	 /**
	 * Gets the forecast end index.
	 * This is calculated based on the available screen width.
	 *
	 * @param {number} smallIndex
	 * The lowest numberic index.
	 * @param {number} largeIndex
	 * The largest numberic index.
	 * @returns {number}
	 * The numeric end index.
	 * @memberof WeatherForecastComponent
	 */
	private getForecastEndIndex(
		smallIndex: number,
		largeIndex: number): number
	{
		return this.smallWidgetDisplay() === true
			? smallIndex
			: largeIndex;
	}

	 /**
	 * The truthy definition of a small widget display.
	 *
	 * @returns {boolean}
	 * True or false if the widget is small or not to display.
	 * @memberof WeatherForecastComponent
	 */
	private smallWidgetDisplay(): boolean
	{
		return (this.siteLayoutService.breakpointWidth
			< AppConstants.layoutBreakpoints.desktop
				&& this.siteLayoutService.displayTabletView !== true
				&& this.siteLayoutService.contextMenuCapturedWidth === 0)
			|| (this.siteLayoutService.breakpointWidth
				< AppConstants.layoutBreakpoints.pinnableDrawerWidth
				&& (this.siteLayoutService.breakpointWidth
					> AppConstants.layoutBreakpoints.widgetfullWidth)
				&& this.siteLayoutService.contextMenuCapturedWidth > 0)
			|| this.siteLayoutService.breakpointWidth
				< AppConstants.layoutBreakpoints.phone;
	}
}