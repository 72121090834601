/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'exposure-payments',
	templateUrl: './exposure-payments.component.html',
	styleUrls: [
		'./exposure-payments.component.scss'
	]
})

/**
 * A component representing an exposure payments component.
 *
 * @export
 * @class ExposurePaymentsComponent
 * @implements {IDynamicComponent<Component, any>}
 * @implements {OnInit}
 */
export class ExposurePaymentsComponent
implements IDynamicComponent<Component, any>, OnInit
{
	/**
	 * Creates an instance of an exposure payments
	 * component.
	 *
	 * @param {ClaisService} claimsService
	 * The claims service for this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service for this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service for this component.
	 * @memberof ExposurePaymentsComponent
	 */
	public constructor(
		public claimsService: ClaimsService,
		public entityInstanceApiService: EntityInstanceApiService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ExposurePaymentsComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the entity instance data.
	 *
	 * @type {any}
	 * @memberof ExposurePaymentsComponent
	 */
	public entityInstanceData: any;

	/**
	 * Gets or sets the entity instance id.
	 *
	 * @type {number}
	 * @memberof ExposurePaymentsComponent
	 */
	public entityInstanceId: number;

	/**
	 * Gets or sets the ledger.
	 *
	 * @type {IEntityInstance}
	 * @memberof ExposurePaymentsComponent
	 */
	public ledger: IEntityInstance;

	/**
	 * Gets or sets the ledger transactions.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof ExposurePaymentsComponent
	 */
	public ledgerTransactions: IEntityInstance[];

	/**
	 * Gets or sets the loss transactions.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof ExposurePaymentsComponent
	 */
	public lossTransactions: IEntityInstance[];

	/**
	 * Gets or sets the ao transactions.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof ExposurePaymentsComponent
	 */
	public aoTransactions: IEntityInstance[];

	/**
	 * Gets or sets the dcc transactions.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof ExposurePaymentsComponent
	 */
	public dccTransactions: IEntityInstance[];

	/**
	 * Gets or sets the loss amount.
	 *
	 * @type {number}
	 * @memberof ExposurePaymentsComponent
	 */
	public loss: number;

	/**
	 * Gets or sets the adjusting and other amount.
	 *
	 * @type {number}
	 * @memberof ExposurePaymentsComponent
	 */
	public adjustingAndOther: number;

	/**
	 * Gets or sets the defense and cost containment amount.
	 *
	 * @type {number}
	 * @memberof ExposurePaymentsComponent
	 */
	public defenseAndCostContainment: number;

	/**
	 * Gets or sets the value signifying whether or not this component is
	 * loading.
	 *
	 * @type {boolean}
	 * @memberof ExposurePaymentsComponent
	 */
	public loading: boolean = true;

	/**
	 * Initiates the component to get the data object.
	 *
	 * @async
	 * @memberof ExposurePaymentsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.entityInstanceId =
			(<any>this.context.source)
				.entityContext?.source.entityInstance.id;
		this.entityInstanceData =
			(<any>this.context.source)
				.entityContext?.source.entityInstance.data;

		const exposureIndex: number =
			(<any>this.context.source).
				field.parent.index;

		const exposureResourceIdentifier: string =
			this.entityInstanceData.exposures[exposureIndex]
				.resourceIdentifier;

		await this.getLedgerData(exposureResourceIdentifier);
		await this.setDisplayData();

		this.loading = false;
		(<any>this.context.source).finishedLoading?.emit();
	}

	/**
	 * This gets the necessary ledger data.
	 *
	 * @async
	 * @param {string} exposureResourceIdentifier
	 * The claim exposure resource identifier.
	 * @memberof ExposurePaymentsComponent
	 */
	public async getLedgerData(
		exposureResourceIdentifier: string)
	{
		this.ledger =
			await this.claimsService.getLedger(
				this.entityInstanceId);

		this.ledgerTransactions =
			await this.claimsService.getLedgerTransactions(
				this.ledger.id,
				'metadata.exposureId eq "'
						+ exposureResourceIdentifier + '"');
	}

	/**
	 * This sets the data to be displayed.
	 *
	 * @async
	 * @memberof ExposurePaymentsComponent
	 */
	public async setDisplayData()
	{
		if (!AnyHelper.isNullOrEmptyArray(this.ledgerTransactions))
		{
			this.lossTransactions = this.ledgerTransactions.filter(
				(ledgerTransaction) =>
					ledgerTransaction.data?.type ===
						ClaimConstants.ledgerTransactionClaimTypes.loss
						&& ledgerTransaction.data?.metadata?.claimPaymentId !=
							null);

			this.aoTransactions = this.ledgerTransactions.filter(
				(ledgerTransaction) =>
					ledgerTransaction.data?.type ===
						ClaimConstants.ledgerTransactionClaimTypes
							.adjustingAndOther
						&& ledgerTransaction.data?.metadata?.claimPaymentId !=
							null);

			this.dccTransactions = this.ledgerTransactions.filter(
				(ledgerTransaction) =>
					ledgerTransaction.data?.type ===
						ClaimConstants.ledgerTransactionClaimTypes
							.defenseAndCostContainment
						&& ledgerTransaction.data?.metadata?.claimPaymentId !=
							null);
		}

		this.loss =
			!AnyHelper.isNullOrEmptyArray(this.lossTransactions)
				? this.claimsService.sumLedgerTransactions(
					this.lossTransactions) * -1
				: 0;

		this.adjustingAndOther =
			!AnyHelper.isNullOrEmptyArray(this.aoTransactions)
				? this.claimsService.sumLedgerTransactions(
					this.aoTransactions) * -1
				: 0;

		this.defenseAndCostContainment =
			!AnyHelper.isNullOrEmptyArray(this.dccTransactions)
				? this.claimsService.sumLedgerTransactions(
					this.dccTransactions) * -1
				: 0;
	}
}