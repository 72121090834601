/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	DomSanitizer,
	SafeResourceUrl
} from '@angular/platform-browser';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	FileUpload
} from 'primeng/fileupload';

@Component({
	selector: 'custom-image-input',
	templateUrl: './custom-image-input.component.html',
	styleUrls: ['./custom-image-input.component.scss']
})

/**
 * A component representing an instance of a Custom Image Input.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomImageInputComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {OnInit}
 */
export class CustomImageInputComponent
	extends ExtendedCustomControlDirective
	implements OnInit
{
	/**
	 * Creates an instance of an CustomImageInputComponent.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used for responsive layouts.
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @param {DomSanitizer} sanitizer
	 * The sanitizer that helps to avoid XSS bugs.
	 * @memberof CustomImageInputComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetector: ChangeDetectorRef,
		private readonly sanitizer: DomSanitizer)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets file upload control.
	 *
	 * @type {FileUpload}
	 * @memberof CustomImageInputComponent
	 */
	@ViewChild('fileUpload')
	public fileUploadRef: FileUpload;

	/**
	 * Gets or sets the image for the lightbox.
	 *
	 * @type {any[]}
	 * @memberof CustomImageInputComponent
	 */
	public images: any[] = [];

	/**
	 * Gets or sets the image url to upload.
	 *
	 * @type {any}
	 * @memberof CustomImageInputComponent
	 */
	public fileUrl: any;

	/**
	 * On initialization event.
	 * Displays to the developer what is allowed in a FieldType Component
	 * above and beyond normal component interactions.
	 *
	 * @memberof CustomImageInputComponent
	 */
	public ngOnInit(): void
	{
		this.getImageData();
		super.ngOnInit();
	}

	/**
	 * A function that returns the Base64 image path as a Safe resource.
	 *
	 * @returns {SafeResourceUrl}
	 * A Safe Resource Url for the Base64 image.
	 * @memberof CustomImageInputComponent
	 */
	public getImageData(): SafeResourceUrl
	{
		this.images = [];

		const imageData: SafeResourceUrl =
			this.sanitizer.bypassSecurityTrustResourceUrl(
				`data:image/jpeg;base64,${this.field.formControl.value}`);

		this.images.push(
			{
				source: imageData,
				thumbnail: imageData,
				title: this.field.props.description,
				alt: this.field.props.altText
			});

		return imageData;
	}

	/**
	 * A function to know if there is an active image uploaded.
	 *
	 * @returns {boolean}
	 * A boolean that shows whether or not a logo exists.
	 * @memberof CustomImageInputComponent
	 */
	public logoExists(): boolean
	{
		return !AnyHelper.isNullOrEmpty(this.field.formControl.value);
	}

	/**
	 * A custom upload handler for uploading images.
	 *
	 * @memberof CustomImageInputComponent
	 */
	public imageCustomUploader(
		event: any): void
	{
		if (event.files.length !== 0)
		{
			this.fileUrl = event.files[0]
				.objectURL
				.changingThisBreaksApplicationSecurity;

			const xmlHttpRequest: XMLHttpRequest = new XMLHttpRequest();
			let base64String: string;

			xmlHttpRequest.onload =
				function ()
				{
					try
					{
						base64String = btoa(new Uint8Array(
							xmlHttpRequest.response)
							.reduce((data, byte) =>
								data + String.fromCharCode(byte), ''));
					}
					catch (exception)
					{
						throw new Error(
							'There is a problem uploading the image.');
					}

					this.field.formControl.setValue(base64String);
					this.getImageData();
				}.bind(this);

			xmlHttpRequest.open(
				AppConstants.httpRequestTypes.get,
				this.fileUrl);
			xmlHttpRequest.responseType = 'arraybuffer';
			xmlHttpRequest.send();

			xmlHttpRequest.onloadend =
				function ()
				{
					if (this.field.props.alwaysDisplayUploader &&
						xmlHttpRequest.status === 200)
					{
						this.fileUploadRef.clear();
					}
				}.bind(this);

			this.validateControl();

			if (AnyHelper.isFunction(this.field.props.change))
			{
				this.field.props.change(
					this.field,
					event);
			}
		}
	}

	/**
	 * A custom handler for clicking a button.
	 *
	 * @param {Event} event
	 * The remove button clicked event.
	 * @memberof CustomImageInputComponent
	 */
	public removeButtonClicked(event: Event): void
	{
		this.field.formControl.setValue(null);
		this.validateControl();
		if (AnyHelper.isFunction(this.field.props.change))
		{
			this.field.props.change(
				this.field,
				event);
		}
	}
}