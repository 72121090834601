/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';

/* eslint-enable max-len */

@Component({
	selector: 'app-application-search',
	templateUrl: './application-search.component.html'
})

/**
 * A component representing application level searches.
 *
 * @export
 * @class ApplicationSearchComponent
 * @implements {IDynamicComponent<Component, any>}
 */
export class ApplicationSearchComponent
implements IDynamicComponent<Component, any>
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ApplicationSearchComponent
	 */
	public context: IDynamicComponentContext<Component, any>;
}