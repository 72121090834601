/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ManageUserGroupComponent
} from '@admin/components/security/users/manage-user-group-expand/manage-user-group.component';
import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-manage-user-groups',
	templateUrl: './manage-user-groups.component.html',
	styleUrls: [
		'./manage-user-groups.component.scss'
	]
})

/**
 * A component representing an instance of the security applications
 * component.
 *
 * @export
 * @class ManageUserGroupsComponent
 */
export class ManageUserGroupsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the ManageUserGroupsComponent class.
	 *
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The api service used to load security group data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ManageUserGroupsComponent
	 */
	public constructor(
		public securityGroupApiService: SecurityGroupApiService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ManageUserGroupsComponent
	 */
	public manageUserGroupsTableDefinitions: ICommonTable;

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ManageUserGroupsComponent
	 */
	public setupPageVariables(): void
	{
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: 1
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: 2
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: 3
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @async
	 * @memberof ManageUserGroupsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions();

		this.manageUserGroupsTableDefinitions = {
			tableTitle: 'Groups',
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Group'),
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise: (objectSearch: IObjectSearch) =>
				this.securityGroupApiService
					.query(
						objectSearch.filter,
						objectSearch.orderBy,
						objectSearch.offset,
						objectSearch.limit),
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			commonTableContext:
				(commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
			actions: {
				create: {
					layout: [
						{
							key: 'name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Name',
								disabled: false,
								required: true
							},
							asyncValidators: {
								validGroupName: {
									expression: (control: UntypedFormControl) =>
										this.validGroupName(control),
									message: 'Group name not available.'
								}
							}
						},
						{
							key: 'description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Description',
								disabled: false
							}
						},
						CommonFormlyFieldConstants.publicField,
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							props: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.props,
								options: securityGroupOptions,
								appendTo: FormlyConstants.appendToTargets.body
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createSecurityGroup()
						}]
				},
				view: {
					component: ManageUserGroupComponent,
					items: []
				},
				update: {
					component: ManageUserGroupComponent,
					layout: [],
					definition: null,
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.updateSecurityGroup()
						}]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							disabled: false,
							command: () => this.deleteSecurityGroup()
						}],
					deleteStatement: () => this.deleteStatement(),
				}
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Creates a new Security Group.
	 *
	 * @async
	 * @memberof ManageUserGroupComponent
	 */
	private async createSecurityGroup(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const createSecurityGroup: Function =
			async () =>
			{
				const createdId: number =
					await this.securityGroupApiService
						.create(
							selectedItem);

				// Refresh the created by id.
				this.commonTableContext.source.selectedItem =
					await this.securityGroupApiService
						.get(createdId);
				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				createSecurityGroup(),
				'<strong>Creating Group</strong>',
				'<strong>Created Group</strong>',
				'Group was created.',
				'Group was not created.'));
	}

	/**
	 * Updates a new Security Group.
	 *
	 * @async
	 * @memberof ManageUserGroupComponent
	 */
	private async updateSecurityGroup(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const updateSecurityGroup: Function =
			async () =>
			{
				await this.securityGroupApiService
					.update(
						selectedItem.id,
						selectedItem);

				await this.commonTableContext.source.updateSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				updateSecurityGroup(),
				'<strong>Updating Group</strong>',
				'<strong>Updated Group</strong>',
				`Group ${selectedItem.id} was updated.`,
				`Group ${selectedItem.id} was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Deletes a new Security Group.
	 *
	 * @async
	 * @memberof ManageUserGroupComponent
	 */
	private async deleteSecurityGroup(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const deleteSecurityGroup: Function =
			async () =>
			{
				await this.securityGroupApiService
					.delete(
						selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteSecurityGroup(),
				'<strong>Deleting Group</strong>',
				'<strong>Deleted Group</strong>',
				`Group ${selectedItem.id} was deleted.`,
				`Group ${selectedItem.id} was not deleted.`));
	}

	/**
	 * Checks if group name is valid.
	 *
	 * @async
	 * @param {FormControl} control
	 * The form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof ManageUserGroupsComponent
	 */
	private async validGroupName(
		control: UntypedFormControl): Promise<boolean>
	{
		const userGroup: ISecurityGroup[] =
			await this.securityGroupApiService
				.query(
					`Name eq '${control.value}'`,
					AppConstants.empty );

		return Promise.resolve(
			AnyHelper.isNullOrEmpty(userGroup[0]));
	}

	/**
	 * Gets the delete statement to be displayed on the
	 * delete expand view.
	 *
	 * @async
	 * @return {Promise<string>}
	 * The delete statement string.
	 * @memberof ManageUserGroupComponent
	 */
	private async deleteStatement(): Promise<string>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;
		const users: number[] =
			await this.securityGroupApiService
				.getSecurityGroupUsers(
					selectedItem.id);

		if (selectedItem.name ===
			AppConstants.securityGroups.waterStreetStandard
			|| selectedItem.name ===
				AppConstants.securityGroups.supportUsers
			|| selectedItem.name ===
				AppConstants.securityGroups.users
			|| selectedItem.name ===
				AppConstants.securityGroups.waterStreetStandard)
		{

			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[0].disabled = true;

			return 'Unable to delete system groups.';
		}

		this.commonTableContext.source.tableDefinitions.actions
			.delete.items[0].disabled = false;

		return 'Confirm you are about to delete Group '
			+ `${selectedItem.id} `
			+ `${selectedItem.name} `
			+ `which has ${users.length} users.`;
	}
}