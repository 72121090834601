/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Injectable
} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	SessionService
} from '@shared/services/session.service';

/**
 * A class containing logic for whether a route can be active based
 * on conditions.
 *
 * @export
 * @class AppAuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AppAuthGuard
{
	/**
	 * Creates an instance of AppAuthGuard.
	 *
	 * @param {SessionService} sessionService
	 * @param {Router} router
	 * @memberof AppAuthGuard
	 */
	public constructor(
		private readonly sessionService: SessionService,
		private readonly router: Router,
		private readonly loggerService: LoggerService)
	{
	}

	/**
	 * Determines whether a route can and should be active based on conditions.
	 *
	 * @param {ActivatedRouteSnapshot} _route
	 * @param {RouterStateSnapshot} _state
	 * @returns {boolean}
	 * A value indicating whether the route should be active for the session.
	 * @memberof AppAuthGuard
	 */
	public canActivate(
		route: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot): boolean
	{
		if (!this.sessionService.isLoggedIn)
		{
			// Replace alert with a toast or other window notification.
			// Window.alert('You don\'t have permission to view this page');

			const messageText: string
				= `You must be logged in to access '${ route.url }'.`;

			EventHelper.dispatchBannerEvent(
				'Authorization Message',
				messageText,
				AppConstants.activityStatus.info);

			this.loggerService.logInformation(messageText);

			// Route to login page.
			this.router.navigate([AppConstants.route.loginPage]);

			// Return that the user cannot activate the route.
			return false;
		}

		if (route.data.roles)
		{
			const user: IUser = this.sessionService.user;
			const userSecurityGroups: string[] =
				user.membershipSecurityGroups.map(
					(item: ISecurityGroup) => item.name);

			if ((<string[]>route.data.roles)
				.filter((routeRole: string) =>
					userSecurityGroups.includes(routeRole))
				.length === 0)
			{
				const messageText: string
					= 'User does not have a required role to access '
						+ `'${ route.url }'.`;

				EventHelper.dispatchBannerEvent(
					'Authorization Message',
					messageText,
					AppConstants.activityStatus.info);

				this.loggerService.logInformation(messageText);

				// Route to dashboard page.
				this.router.navigate([AppConstants.route.dashboardPage]);

				return false;
			}
		}

		return true;
	}
}