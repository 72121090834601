/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AbstractControl
} from '@angular/forms';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormEventConstants
} from '@shared/constants/form-event.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	StringHelper
} from './string.helper';

/**
 * A class containing static helper methods
 * for formly interactions.
 *
 * @export
 * @class FormlyHelper
 */
export class FormlyHelper
{
	/**
	 * Gets or sets the section index.
	 *
	 * @type {number}
	 * @memberof FormlyHelper
	 */
	private static sectionIndex: number;

	/**
	 * Gets or sets the tab index.
	 *
	 * @type {number}
	 * @memberof FormlyHelper
	 */
	private static tabIndex: number;

	/**
	 * This will add the sent additional method to the existing form controls
	 * update value and validity function.
	 * @note The fields logic check can likely be removed with an upgraded
	 * Formly.
	 * This was required starting with Formly v6.0.0-next.1.
	 *
	 * @static
	 * @param {AbstractControl} formControl
	 * The formly based form control that implements update value and validity.
	 * @param {Function} additionalMethod
	 * The additional method to run as part of the update value and validity
	 * chain of the sent control.
	 * @memberof FormlyHelper
	 */
	public static extendUpdateValueAndValidity(
		formControl: AbstractControl,
		additionalMethod: Function): void
	{
		// Ensure we are not cloning validations if the same field is displayed
		// in multiple locations due to anyOf displays.
		if ((<any>formControl)._fields?.length > 1)
		{
			delete (<any>formControl)._fields;
		}

		const currentValidityMethod: Function =
			formControl.updateValueAndValidity.bind(formControl);

		formControl.updateValueAndValidity =
			() =>
			{
				currentValidityMethod();
				additionalMethod();
			};
	}

	/**
	 * Fires a view check on a displayed formly form. This will reach into
	 * each form control in the layout and update the value and validity.
	 *
	 * @static
	 * @param {FormlyFieldConfig} formlyEntityLayout
	 * The formly field array to be recursively checked for validity.
	 * @param {boolean} dipatchRepeaterCleanModelEvent
	 * To dispatch the repeater clean model event. Defaults to false.
	 * @memberof FormlyHelper
	 */
	public static fireViewCheck(
		formlyEntityLayout: FormlyFieldConfig[],
		dipatchRepeaterCleanModelEvent: boolean = false): void
	{
		formlyEntityLayout.forEach(
			(field: FormlyFieldConfig) =>
			{
				if (AnyHelper.isNullOrWhitespace(
					field.formControl?.value)
					&& field.expressions?.hide?.valueOf() === true
					&& !AnyHelper.isNull(
						(<any>field.formControl)?.defaultValue))
				{
					field.formControl.setValue(
						(<any>field.formControl)?.defaultValue);
				}

				field.formControl?.markAsDirty();
				field.formControl?.updateValueAndValidity();
				this.updateChildren(field);
			});

		if (dipatchRepeaterCleanModelEvent === true)
		{
			EventHelper.dispatchRepeaterCleanModelEvent();
		}
	}

	/**
	 * Returns the parsed json object from the layout json data
	 * as Formly consumable layout fields. This is used for
	 * translations from the valid JSON in the database
	 * into a format the Formly expects.
	 *
	 * @static
	 * @param {FormlyFieldConfig[]} jsonLayout
	 * The formly field configuration to be created as a formly layout.
	 * @param {object} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @returns {FormlyFieldConfig[]}
	 * A formly field configuration array ready for display in a formly
	 * layout.
	 * @memberof FormlyHelper
	 */
	public static getFormlyLayout(
		jsonLayout: FormlyFieldConfig[],
		context: object): FormlyFieldConfig[]
	{
		let tabAdded: boolean = false;
		this.sectionIndex = 0;
		this.tabIndex = 0;

		return jsonLayout.map(
			(field: FormlyFieldConfig,
				fieldIndex: number) =>
			{
				this.mapField(
					field,
					context,
					fieldIndex);

				if (this.isTabWrapper(
					field) === true)
				{
					field.props.visible = !tabAdded;

					if (tabAdded === false)
					{
						tabAdded = true;
					}
				}

				return field;
			});
	}

	/**
	 * This will find the existing formly field config with a key
	 * matching the sent fieldKey.
	 *
	 * @param {FormlyFieldConfig[]} formlyEntityLayout
	 * The formly entity layout.
	 * @param {string} fieldKey
	 * The key of the field to acquire.
	 * @memberof FormlyHelper
	 * @returns {FormlyFieldConfig}
	 * If found, this will return the field config with the matching key.
	 */
	public static getMatchingFieldConfigurations(
		formlyEntityLayout: FormlyFieldConfig[],
		fieldKey: string): FormlyFieldConfig[]
	{
		let fields: FormlyFieldConfig[] = [];

		for (const layoutField of formlyEntityLayout)
		{
			const nestedFields: FormlyFieldConfig[] =
				this.getMatchingChildFieldConfigurations(
					layoutField,
					fieldKey);

			fields = fields.concat(nestedFields);
		}

		return fields;
	}

	/**
	 * This will find the existing child formly field config with a key
	 * matching the sent fieldKey.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field.
	 * @param {string} fieldKey
	 * The key of the field to acquire.
	 * @memberof FormlyHelper
	 * @returns {FormlyFieldConfig}
	 * If found, this will return the field config with the child matching key.
	 */
	public static getMatchingChildFieldConfigurations(
		field: FormlyFieldConfig,
		fieldKey: string): FormlyFieldConfig[]
	{
		let fields: FormlyFieldConfig[] = [];

		if (!AnyHelper.isNull(field.props?.attributes)
			&& field.props.attributes[
				FormlyConstants.attributeKeys.dataKey]?.toString()
				=== fieldKey
			|| field.key?.toString() === fieldKey)
		{
			fields.push(field);
		}

		const fieldGroups: any[] =
			(field.fieldGroup || []);

		for (const fieldGroup of fieldGroups)
		{
			fields = fields.concat(this.getMatchingChildFieldConfigurations(
				fieldGroup,
				fieldKey));
		}

		return fields;
	}

	/**
	 * This will check a formly field to see if this field is describing a tab
	 * content wrapper.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration to check for a tab content wrapper.
	 * @return {boolean}
	 * A value signifying whether or not this field is a tab content wrapper.
	 * @memberof FormlyHelper
	 */
	public static isTabWrapper(
		field: FormlyFieldConfig): boolean
	{
		const wrapperTypes: string[] =
			<string[]>
			[
				FormlyConstants.customControls.customTabContent
			];

		return wrapperTypes.some(
			(wrapperType: string) =>
				field.wrappers?.indexOf(wrapperType) >= 0);
	}

	/**
	 * Determines if the feild should be visible.
	 *
	 * @param {FormlyFieldConfig} field
	 * The field to check.
	 * @param {any} context
	 * the page context.
	 * @returns {Promise<boolean>}
	 * A promise containg a bollean of true if the field should be visible.
	 * @async
	 * @memberof FormlyHelper
	 */
	public static async visible(
		field: FormlyFieldConfig,
		context: any): Promise<boolean>
	{
		if (AnyHelper.isNull(field.props))
		{
			return true;
		}

		if (AnyHelper.isNullOrEmpty(
			field.props.displayPromise))
		{
			return AnyHelper.isNullOrEmpty(field.props.display)
				? true
				: field.props.display;
		}

		return StringHelper
			.transformToDataPromise(
				field.props.displayPromise,
				context);
	}

	/**
	 * This will check a formly field to see if this field is describing a
	 * custom empty wrapper
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration to check for a tab content wrapper.
	 * @return {boolean}
	 * A value signifying whether or not this field is a tab content wrapper.
	 * @memberof FormlyHelper
	 */
	public static isEmptyWrapper(
		field: FormlyFieldConfig): boolean
	{
		const wrapperTypes: string[] =
			<string[]>
			[
				FormlyConstants.customControls.customEmptyWrapper
			];

		return wrapperTypes.some(
			(wrapperType: string) =>
				field.wrappers?.indexOf(wrapperType) >= 0);
	}

	/**
	 * This will check a formly field to see if this field is describing a
	 * custom field wrapper.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration to check for a custom field wrapper.
	 * @return {boolean}
	 * A value signifying whether or not this field is a custom field wrapper.
	 * @memberof FormlyHelper
	 */
	public static isCustomFieldWrapper(
		field: FormlyFieldConfig): boolean
	{
		const wrapperTypes: string[] =
			<string[]>
			[
				FormlyConstants.customControls.customFieldWrapper
			];

		return wrapperTypes.some(
			(wrapperType: string) =>
				field.wrappers?.indexOf(wrapperType) >= 0);
	}

	/**
	 * This will check a formly field to see if this field is describing
	 * nested field group.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration to check for a nested field group.
	 * @return {boolean}
	 * A value signifying whether or not this field has a nested field group.
	 * @memberof FormlyHelper
	 */
	public static hasNestedFieldGroup(
		field: FormlyFieldConfig): boolean
	{
		return (!AnyHelper.isNullOrEmptyArray(
			(<any>field.fieldArray)?.fieldGroup)
			|| !AnyHelper.isNullOrEmptyArray(field.fieldGroup));
	}

	/**
	 * This will find and set the provided value to the formly field config with
	 * a key matching the sent fieldKey into the formly layout.
	 *
	 * @param {FormlyFieldConfig[]} jsonLayout
	 * The formly field configuration to be created as a formly layout.
	 * @param {string} fieldKey
	 * The key of the field to acquire.
	 * @param {any} fieldValue
	 * The value of the field to acquire.
	 * @param {boolean} fireValidityChange
	 * Executes the validity of the form if true, otherwise the
	 * validity is skipped.
	 * @memberof FormlyHelper
	 */
	public static setFieldValue(
		jsonLayout: FormlyFieldConfig[],
		fieldKey: string,
		fieldValue: any = AppConstants.empty,
		fireValidityChange: boolean = false): void
	{
		const field: FormlyFieldConfig = jsonLayout
			.find((fieldConfig) =>
				fieldConfig.key === fieldKey);

		field.formControl?.setValue(fieldValue);

		if (fireValidityChange)
		{
			field.formControl?.markAsDirty();
			field.formControl?.markAsTouched();
			field.formControl?.updateValueAndValidity();
		}

		const element: any = document.getElementById(
			field.id);

		element?.dispatchEvent(new Event(FormEventConstants.onChangeEvent));
	}

	/**
	 * Translates string based synchronous or asynchronous validations
	 * into runnable functions available in formly.
	 *
	 * @static
	 * @param {any} validationSet
	 * The synchronous or asynchronous validations that need mapped.
	 * @param {object} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @returns {any}
	 * The mapped and formly available validator set running as functions
	 * again the sent context.
	 * @memberof FormlyHelper
	 */
	public static getValidatorFunctions(
		validationSet: any,
		context: object): any
	{
		if (!AnyHelper.isNullOrEmpty(validationSet))
		{
			Object.keys(validationSet)
				.forEach((key: string) =>
				{
					if (validationSet[key].expression
						&& typeof validationSet[key].expression ===
							AppConstants.variableTypes.string)
					{
						validationSet[key].expression =
							Function(
								'control',
								'field',
								validationSet[key].expression)
								.bind(context);
					}
				});
		}

		return validationSet;
	}

	/**
	 * Given a sent formly layout this will disable all fields and nested
	 * fields found in this layout.
	 *
	 * @static
	 * @param {FormlyFieldConfig[]} formlyEntityLayout
	 * The formly field array to have all fields and nested fields set as
	 * disabled.
	 * @param {boolean} disabled
	 * The set to disable all fields or if false then enable.
	 * Default to true.
	 * @returns {FormlyFieldConfig[]}
	 * The formly field array with all fields and nested fields set as the
	 * parameter disabled.
	 * @memberof FormlyHelper
	 */
	public static disableAllFields(
		formlyEntityLayout: FormlyFieldConfig[],
		disabled: boolean = true): FormlyFieldConfig[]
	{
		formlyEntityLayout.forEach(
			(field: FormlyFieldConfig) =>
			{
				field.props.disabled = disabled;
				this.disableChildren(
					field,
					disabled);
			});

		return formlyEntityLayout;
	}

	/**
	 * Given a sent formly layout this will disable all fields and nested
	 * fields found in this layout. This disable will be called recursively
	 * for each child of the sent field.
	 *
	 * @static
	 * @param {FormlyFieldConfig} field
	 * The formly field to be set as disabled along with all children.
	 * @param {boolean} disabled
	 * The set to disable all fields or if false then enable.
	 * Default to true.
	 * @memberof FormlyHelper
	 */
	private static disableChildren(
		field: FormlyFieldConfig,
		disabled: boolean): void
	{
		field.fieldGroup?.forEach(
			(fieldGroup: FormlyFieldConfig) =>
			{
				fieldGroup.props.disabled = disabled;
				this.disableChildren(
					fieldGroup,
					disabled);
			});

		(<FormlyFieldConfig[]>(<any>field.fieldArray)?.fieldGroup)?.forEach(
			(fieldGroup: FormlyFieldConfig) =>
			{
				fieldGroup.props.disabled = disabled;
				this.disableChildren(
					fieldGroup,
					disabled);
			});
	}

	/**
	 * Recursively calls an update of value and validity for each nested
	 * set of form controls.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field to be recursively checked for validity.
	 * @memberof FormlyHelper
	 */
	private static updateChildren(
		field: FormlyFieldConfig): void
	{
		field.fieldGroup?.forEach(
			(fieldGroup: FormlyFieldConfig) =>
			{
				if (AnyHelper.isNullOrWhitespace(
					fieldGroup.formControl?.value)
					&& fieldGroup.expressions?.hide?.valueOf() === true
					&& !AnyHelper.isNull(
						(<any>fieldGroup.formControl)?.defaultValue))
				{
					fieldGroup.formControl.setValue(
						(<any>fieldGroup.formControl)?.defaultValue);
				}

				fieldGroup.formControl?.markAsDirty();
				fieldGroup.formControl?.updateValueAndValidity();
				this.updateChildren(fieldGroup);
			});
	}

	/**
	 * Recursively maps children into a converted formly field.
	 *
	 * @static
	 * @param {FormlyFieldConfig} field
	 * The formly field to be recursively converted and mapped.
	 * @param {object} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @param {number} fieldIndex
	 * field index position in the array.
	 * @param {string} nestedLocation
	 * If sent this will handle the convert with the nested field identifier.
	 * @memberof FormlyHelper
	 */
	private static mapField(
		field: FormlyFieldConfig,
		context: object,
		fieldIndex: number,
		nestedLocation: string = null): void
	{
		if (FormlyHelper.isTabWrapper(field)
			|| (!FormlyHelper.isCustomFieldWrapper(field)
				&& FormlyHelper.hasNestedFieldGroup(field))
			|| field.type === FormlyConstants.customControls.customSectionTitle)
		{
			this.sectionIndex ++;
		}

		if (field.type !== FormlyConstants.customControls.customSectionTitle
			&& (!FormlyHelper.isEmptyWrapper(field)
				|| FormlyHelper.isEmptyWrapper(field)
					&& AnyHelper.isNull(field.props))
			&& AnyHelper.isNull(field.key)
			&& !FormlyHelper.isTabWrapper(field))
		{
			return;
		}

		this.convertToFormlyField(
			field,
			context,
			fieldIndex,
			nestedLocation);

		const repeaterKey: string =
			AnyHelper.isNullOrWhitespace(nestedLocation)
				? field.key?.toString() || AppConstants.empty
				: `${nestedLocation}.${field.key?.toString()}`;

		this.setActionPerFieldGroup(
			field?.fieldGroup,
			context,
			repeaterKey);

		this.setActionPerFieldGroup(
			(<any>field.fieldArray)?.fieldGroup,
			context,
			repeaterKey);

		if (FormlyHelper.isTabWrapper(field))
		{
			this.tabIndex ++;
		}
	}

	/**
	 * For a sent formly field pulled from the layout, this will
	 * alter stored JSON into a format that Formly expects.
	 *
	 * @static
	 * @param {FormlyFieldConfig} field
	 * The layout field to be cast into a Formly field for display.
	 * @param {object} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @param {number} fieldIndex
	 * field index position in the array.
	 * @param {string} nestedLocation
	 * If sent this will handle the convert with the nested field identifier.
	 * @memberof FormlyHelper
	 */
	private static convertToFormlyField(
		field: FormlyFieldConfig,
		context: object,
		fieldIndex: number,
		nestedLocation: string = null): FormlyFieldConfig
	{
		field.props = field.props ?? {};

		if (!AnyHelper.isNullOrWhitespace(field.key))
		{
			field.props.attributes =
				{
					'data-key':
						((!AnyHelper.isNullOrWhitespace(nestedLocation)
							? `${nestedLocation}.`
							: AppConstants.empty)
								+ field.key)
							.replace(
								/\[\d+\]/g,
								AppConstants.empty)
				};
		}

		field.props.attributes =
			{
				...field.props.attributes,
				...{
					'section-index': this.sectionIndex,
					'tab-index': this.tabIndex,
					'field-index': fieldIndex
				}
			};

		field.validators =
			this.getValidatorFunctions(
				field.validators,
				context);
		field.asyncValidators =
			this.getValidatorFunctions(
				field.asyncValidators,
				context);

		if (!AnyHelper.isNullOrWhitespace(field.props.change)
			&& !AnyHelper.isFunction(field.props.change))
		{
			field.props.change =
				Function(
					'field',
					'$event',
					field.props.change.toString())
					.bind(context);
		}

		return field;
	}

	/**
	 * For a sent formly field pulled from the layout, this will
	 * alter stored JSON into a format that Formly expects.
	 *
	 * @static
	 * @param {FormlyFieldConfig[]} fieldGroups
	 * The layout field groups.
	 * @param {object} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @param {string} repeaterKey
	 * The repeater key.
	 * @memberof FormlyHelper
	 */
	 private static setActionPerFieldGroup(
		fieldGroups: FormlyFieldConfig[],
		context: object,
		repeaterKey: string): void
	{
		fieldGroups?.forEach(
			(fieldGroup: FormlyFieldConfig,
				fieldIndex: number) =>
			{
				this.convertToFormlyField(
					fieldGroup,
					context,
					fieldIndex,
					repeaterKey);

				this.mapField(
					fieldGroup,
					context,
					fieldIndex,
					repeaterKey);
			});
	}
}