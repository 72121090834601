/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * The emdedded file encoding types supported.
 */
export enum EmbeddedFileEncoding
{
	/**
	 * A Base64 encoding type.
	 */
	Base64 = 'Base64'
}