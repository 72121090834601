<div [attr.data-key]="field.key">
	<div *ngFor="let inputGroupItem of field.props.inputGroupItems"
		class="input-radio-button">
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon radio-button-title ellipsis-text">
				{{inputGroupItem.name}}
			</span>
			<input type="text" *ngIf="field.props.formatType !== 'currency'"
				pInputText
				[placeholder]="inputGroupItem.name"
				[(ngModel)]="inputGroupItem.optionValue"
				[disabled]="inputGroupItem.disabledInput"
				(keyup)="setInput(inputGroupItem)">
			<p-inputNumber
				*ngIf="field.props.formatType === 'currency'"
				[(ngModel)]="inputGroupItem.optionValue"
				inputId="currency-us"
				mode="currency"
				currency="USD"
				locale="en-US"
				[placeholder]="inputGroupItem.placeholder"
				[disabled]="inputGroupItem.disabledInput"
				(onBlur)="setInput(inputGroupItem)">
			</p-inputNumber>
			<span class="p-inputgroup-addon radio-button">
				<p-radioButton
					[name]="inputGroupItem.key"
					[value]="inputGroupItem.key"
					[formControl]="formControl"
					(onFocus)="focusedRadioButton(inputGroupItem)">
				</p-radioButton>
			</span>
		</div>
	</div>
</div>

<field-validation-message
	[field]="field">
</field-validation-message>