/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	DateTime
} from 'luxon';

/* eslint-enable max-len */

@Component({
	selector: 'app-log',
	templateUrl: './log.component.html',
	styleUrls: ['./log.component.scss']
})

/**
 * A component representing an instance of the log component.
 *
 * @export
 * @class LogComponent
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 */
export class LogComponent
implements IDynamicComponent<CommonTableComponent, any>, OnInit
{
	/**
	 * Initializes a new instance of the LogComponent class.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component to get its data.
	 * @memberof ManageUserComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof LogComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the request url.
	 *
	 * @type {string}
	 * @memberof LogComponent
	 */
	public requestUrl: string;

	/**
	 * Gets or sets the time log.
	 *
	 * @type {string}
	 * @memberof LogComponent
	 */
	public timeLog: string;

	/**
	 * Gets or sets the method type.
	 *
	 * @type {string}
	 * @memberof LogComponent
	 */
	public method: string;

	/**
	 * Initialices the component by executing a format
	 * request url to be displayed into this view.
	 *
	 * @memberof LogComponent
	 */
	public ngOnInit(): void
	{
		this.formatRequestUrl();
		this.formatTime();
		this.getMethodClassName();
	}

	/**
	 * Sets the specific api url location from the request url
	 * given by the log api service response.
	 *
	 * @memberof LogComponent
	 */
	public formatRequestUrl(): void
	{
		if (!AnyHelper.isNullOrEmpty(
			this.context.source.selectedItem.requestHost))
		{
			const requestHost = this.context.source.selectedItem.requestHost
				.replace(':8080', AppConstants.empty);
			this.requestUrl = (this.context.source.selectedItem.requestUrl
				.split('//'))[1]
				.replace(requestHost, AppConstants.empty);
		}
	}

	/**
	 * Sets and formats the time log to local time in order
	 * to be displayed within the expanded view component.
	 *
	 * @memberof LogComponent
	 */
	public formatTime(): void
	{
		this.timeLog =
			DateTime.fromISO(this.context.source.selectedItem.time)
				.toLocal()
				.toFormat(DateHelper.presetFormats.dateAndHourFormat);
	}

	/**
	 * Sets and method class name to be set into
	 * the scss style and define the background
	 * color for the request method section.
	 *
	 * @memberof LogComponent
	 */
	public getMethodClassName(): void
	{
		const requestMethod: string =
			(this.context.source.selectedItem.requestMethod)
				.toUpperCase();

		this.method = (requestMethod === AppConstants.httpRequestTypes.post
			|| requestMethod === AppConstants.httpRequestTypes.get
			|| requestMethod === AppConstants.httpRequestTypes.delete
			|| requestMethod === AppConstants.httpRequestTypes.put
			|| requestMethod === AppConstants.httpRequestTypes.patch
			|| requestMethod === AppConstants.httpRequestTypes.head)
			? this.context.source.selectedItem.requestMethod
				.toLowerCase()
			: 'other';
	}
}
