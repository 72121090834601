<p-scrollPanel
	styleClass="note-container">
	<div
		class="ui-g-12 text-left description-container">
		<div
			class="label-wrapper">
			<label>
				<strong>Description:</strong>
			</label>
		</div>
		<div
			class="ui-g-12 no-padding panel-text markdown-element">
			<markdown
				[data]="selectedItem.data.content">
			</markdown>
		</div>

		<div class="ui-g-12 no-horizontal-padding"
			*ngFor="let propertyName of getNoteCustomData(selectedItem)">
			<strong>
				{{getPropertyDisplayValue(propertyName)}}:
			</strong>
			{{selectedItem.data[propertyName]}}
		</div>

		<div
			class="ui-g-12 no-horizontal-padding">
			<strong>
				Note Type:
			</strong>
			{{getDisplayName(selectedItem.entityType)}}
		</div>
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong>
				Created:
			</strong>
			{{ selectedItem.createDate | dateTimeFromIso | dateTimeToFormat:'D h:mm a' }} ({{ selectedItem.createDate | dateTimeFromIso | dateTimeToRelative:{ style: 'long' } }})
		</div>
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong>
				Changed:
			</strong>
			{{ selectedItem.changeDate | dateTimeFromIso | dateTimeToFormat:'D h:mm a' }} ({{ selectedItem.changeDate | dateTimeFromIso | dateTimeToRelative:{ style: 'long' } }})
		</div>
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong>
				Changed By:
			</strong>
			{{ selectedItem.data.userName || 'User ' + selectedItem.changedById }}
		</div>
	</div>
</p-scrollPanel>