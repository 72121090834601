/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html'
})

/**
 * A component representing an instance of the main
 * admin page.
 *
 * @export
 * @class AdminComponent
 */
export class AdminComponent
{
}