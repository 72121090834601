/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	IEntityInstanceRuleViolationOverrideDto
} from '@api/interfaces/entities/entity-instance-rule-violation-override.dto.interface';
import {
	EntityInstanceRuleViolationOverrideApiService
} from '@api/services/entities/entity-instance-rule-violation-override.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonListDirective
} from '@shared/directives/common-list-directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IEntityInstanceRuleViolationOverride
} from '@shared/interfaces/entities/entity-instance-rule-violation-override.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IRuleDefinition
} from '@shared/interfaces/rules/rule-definition.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

@Component({
	selector: 'app-rule-override-list',
	templateUrl: './rule-override-list.component.html',
	styleUrls: [
		'./rule-override-list.component.scss'
	]
})

/**
 * A component representing context level rule overrides list view.
 *
 * @export
 * @class RuleOverrideListComponent
 * @extends {RuleOverrideListComponent<
 * 	IEntityInstanceRuleViolationOverride,
 * 	IEntityInstanceRuleViolationOverrideDto>
 * }
 * @implements {IDynamicComponent<Component, any>}
 */
export class RuleOverrideListComponent
	extends CommonListDirective<
		IEntityInstanceRuleViolationOverride,
		IEntityInstanceRuleViolationOverrideDto>
	implements IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the rule override list component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service, used to get user names.
	 * @param {EntityInstanceRuleViolationOverrideApiService}
	 * entityInstanceRuleViolationOverrideApiService
	 * The entity instance rule violation API service, used to get children.
	 * @param {RuleService} ruleService
	 * The rule service used in this component.
	 * @param {EntityService} entityService
	 * The entity service used in this component.
	 * @memberof RuleOverrideListComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityInstanceRuleViolationOverrideApiService:
			EntityInstanceRuleViolationOverrideApiService,
		public ruleService: RuleService,
		public entityService: EntityService)
	{
		super();
		this.apiService = entityInstanceRuleViolationOverrideApiService;
	}

	/**
	 * Gets or sets the generated common list context for the rule list.
	 *
	 * @type {ICommonListContext<IEntityInstanceRuleViolationOverride>}
	 * @memberof RuleOverrideListComponent
	 */
	public generatedCommonListContext:
		ICommonListContext<IEntityInstanceRuleViolationOverride>;

	/**
	 * Gets or sets the list of common sorters for the list view.
	 *
	 * @type {ICommonListSort[]}
	 * @memberof RuleOverrideListComponent
	 */
	public sorters: ICommonListSort[] =
		[
			<ICommonListSort>
			{
				name: 'Create Date',
				value: AppConstants.commonProperties.id,
				direction: AppConstants.sortDirections.ascending,
				iconAsc: AppConstants.cssClasses.sortAscending,
				iconDesc: AppConstants.cssClasses.sortDescending
			}
		];

	/**
	 * Gets or sets the list of users associated to violation overrides in
	 * this view.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof RuleOverrideListComponent
	 */
	public overrideUsers: IEntityInstance[] = [];

	/**
	 * A method to generate an initial filter for all calls to this list.
	 *
	 * @rule
	 * This filter will be used for all calls, including those in the
	 * list context filter set.
	 *
	 * @returns {string}
	 * A filter value used for all data loads in this list.
	 * @memberof RuleOverrideListComponent
	 */
	public getPrimaryFilter(): string
	{
		return 'InstanceId eq ' +
			(<EntityInstanceComponent>this.context.source).entityInstance.id;
	}

	/**
	 * A method to generate the proper data promise for loading data.
	 *
	 * @param {string} filter
	 * A value representing the compiled filters from the common list filter
	 * array.
	 * @param {string} orderBy
	 * A value representing the compiled order by from the common list sort.
	 * @returns {Promise<IEntityInstanceRuleViolation[]>}
	 * A data promise with an applied filter, order, and offset.
	 * @memberof RuleOverrideListComponent
	 */
	public async getData(
		filter: string,
		orderBy: string): Promise<IEntityInstanceRuleViolationOverride[]>
	{
		await this.ruleService.setStoredVariables();

		const data: IEntityInstanceRuleViolationOverride[] =
			await ApiHelper.getFullDataSet(
				this.entityInstanceRuleViolationOverrideApiService,
				filter,
				orderBy);

		const changedByIds: string[] =
			[...new Set(
				data.map(
					(listItem: IEntityInstanceRuleViolationOverride) =>
						listItem.userId.toString()))];

		if (changedByIds.length === 0)
		{
			this.overrideUsers = [];

			return data;
		}

		const changedByIdFilter: string =
			AppConstants.commonProperties.id
				+ ' in ('
				+ ApiFilterHelper.commaSeparatedStringValues(
					changedByIds,
					AppConstants.empty)
				+ ')';
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
		this.overrideUsers =
			await ApiHelper.getFullDataSet(
				this.entityInstanceApiService,
				changedByIdFilter,
				AppConstants.empty);

		return data;
	}

	/**
	 * A method that generates the base rules common list context.
	 *
	 * @rule
	 * This method will generate on firstLoad the default common list context
	 * with properties used for rules.
	 *
	 * @param {ICommonListItem<IEntityInstanceRuleViolationOverride>[]} data
	 * A value representing the data to load within the common list context.
	 * @returns {ICommonListContext<IEntityInstanceRuleViolationOverride>}
	 * A value representing the common list context for first load.
	 * @memberof RuleOverrideListComponent
	 */
	public generateCommonListContext(
		data: ICommonListItem<IEntityInstanceRuleViolationOverride>[]):
		ICommonListContext<IEntityInstanceRuleViolationOverride>
	{
		this.generatedCommonListContext =
			<ICommonListContext<IEntityInstanceRuleViolationOverride>>
			{
				data: data,
				supportMarkdown: true,
				searchable: true,
				sortable: true,
				sorters: this.sorters,
				searchFilterFormat: '${inputValue}',
				actions: [],
				filters: [],
				onFilterChange: (source, filters) =>
					this.handleFilterChange(source, filters),
				onSortChange: (source, sorter) =>
					this.handleSortingChange(source, sorter)
			};

		return this.generatedCommonListContext;
	}

	/**
	 * A method that maps entity instance data to a common list item.
	 *
	 * @param {IEntityInstanceRuleViolationOverride} ruleViolationOverride
	 * A value representing the entity instance rule violation override to map
	 * to a common list item.
	 * @returns {ICommonListItem<IEntityInstanceRuleViolationOverride>}
	 * A value representing the mapped common list item from the instance.
	 * @memberof RuleOverrideListComponent
	 */
	public mapToListItem(
		ruleViolationOverride:
			IEntityInstanceRuleViolationOverride):
		ICommonListItem<IEntityInstanceRuleViolationOverride>
	{
		const ruleDefinition: IRuleDefinition =
			this.ruleService.ruleDefinitions.find(
				(ruleDefinitionItem: IRuleDefinition) =>
					ruleDefinitionItem.id ===
						ruleViolationOverride.ruleDefinitionId);
		const workflowActionDefinition: IWorkflowActionDefinitions =
			this.ruleService.workflowActionDefinitions.find(
				(workflowActionDefinitionItem: IWorkflowActionDefinitions) =>
					workflowActionDefinitionItem.id ===
						ruleViolationOverride.workflowActionDefinitionId);
		const overrideUser: IEntityInstance =
			this.overrideUsers.find(
				(user: IEntityInstance) =>
					user.id === ruleViolationOverride.userId);
		const overrideUserDisplayName: string =
			StringHelper.toNameString(
				overrideUser.data.firstName,
				overrideUser.data.lastName);

		const deletedRuleSummary: string =
			'[Deleted Rule Definition]';
		ruleViolationOverride.ruleDefinitionName =
			ruleDefinition?.displayName
				?? ruleDefinition?.name
				?? deletedRuleSummary;
		ruleViolationOverride.ruleDescription =
			ruleDefinition?.description
				?? deletedRuleSummary;
		ruleViolationOverride.workflowActionDefinitionName =
			workflowActionDefinition?.name
				?? '[Deleted Workflow Action]';
		ruleViolationOverride.overrideUserDisplayName =
			AnyHelper.isNullOrWhitespace(overrideUserDisplayName)
				? '[Deleted User]'
				: overrideUserDisplayName;
		ruleViolationOverride.overrideDateIsoString =
			ruleViolationOverride.startDate;

		const listItem: ICommonListItem<IEntityInstanceRuleViolationOverride> =
			<ICommonListItem<IEntityInstanceRuleViolationOverride>>
			{
				item: ruleViolationOverride,
				descriptionLineFormat:
					`**Rule Name**: ${ruleViolationOverride.ruleDefinitionName}`
						+ '<br/>'
						+ '<div class="override-action-name">Action Name: '
							+ `${ruleViolationOverride
								.workflowActionDefinitionName}</div>`,
				informationLineRelativeDateTime:
					ruleViolationOverride.overrideDateIsoString,
				informationLineFormat:
					` by ${ruleViolationOverride.overrideUserDisplayName}`,
				informationIcons: [],
				actions: [
					<MenuItem>
					{
						icon:
							`fa fa-${AppConstants.cssIcons.infoCircle}`,
						command: (event: any) => {
							this.changeSelectedItem.emit(
								ruleViolationOverride);
							this.changeDisplayMode.emit(
								AppConstants.displayMode.secondaryView);
							event.stopPropagation();
						}
					}
				]
			};

		return listItem;
	}

	/**
	 * A method to generate the data promise for loading rules data.
	 *
	 * @async
	 * @param {ICommonListFilter[]} [filters]
	 * A value representing the enabled filters to apply to the data promise
	 * query.
	 * @param {ICommonListSort} [sorter]
	 * A value representing the enabled sorter to apply to the data promise
	 * query.
	 * @returns {Promise<IEntityInstanceRuleViolationOverride[]>}
	 * A data promise with a filter, order, and offset.
	 * @memberof RuleOverrideListComponent
	 */
	public async loadItems(
		filters?: ICommonListFilter[],
		sorter?: ICommonListSort):
		Promise<IEntityInstanceRuleViolationOverride[]>
	{
		this.enabledFilters = filters;

		let orderBy: string;
		if (AnyHelper.isNull(sorter) === false)
		{
			orderBy = `${sorter.value} ${sorter.direction}`;
		}

		if (this.context.source instanceof EntityInstanceComponent)
		{
			return this.getData(
				this.getPrimaryFilter(),
				orderBy);
		}

		return null;
	}

	/**
	 * This action is called to filter items after the rules are initially
	 * loaded and generate dynamic filters based on the data available.
	 *
	 * @memberof RuleOverrideListComponent
	 */
	public performPostLoadActions(): void
	{
		// Generate filters dynamically based on rule and action names.
		const uniqueFiltersMap: Map<string, ICommonListFilter> =
			new Map<string, ICommonListFilter>();

		this.generatedCommonListContext.data
			.forEach(
				(listItem: ICommonListItem<
					IEntityInstanceRuleViolationOverride>) =>
				{
					const ruleName: string =
						listItem.item.ruleDefinitionName;
					uniqueFiltersMap.set(
						ruleName,
						{
							name: ruleName,
							value: ruleName
						});
				});

		this.generatedCommonListContext.data
			.forEach(
				(listItem: ICommonListItem<
					IEntityInstanceRuleViolationOverride>) =>
				{
					const workflowActionDefinitionName: string =
						listItem.item.workflowActionDefinitionName;
					uniqueFiltersMap.set(
						workflowActionDefinitionName,
						{
							name: workflowActionDefinitionName,
							value: workflowActionDefinitionName
						});
				});

		this.generatedCommonListContext.filters =
			Array.from(
				uniqueFiltersMap.values());

		// Filter the data after each load.
		this.commonListContext.data.data =
			this.filterItems();
	}

	/**
	 * This filter items method is used to return all rules with
	 * entries matching the current filter set.
	 *
	 * @returns {ICommonListItem<IEntityInstanceRuleViolationOverride>[]}
	 * The set of filtered items matching the current filter set.
	 * @memberof RuleOverrideListComponent
	 */
	public filterItems():
		ICommonListItem<IEntityInstanceRuleViolationOverride>[]
	{
		let combinedFilters: string[] = [];
		if (AnyHelper.isNull(this.enabledFilters) === false)
		{
			combinedFilters =
				this.enabledFilters.map(
					(item: ICommonListFilter) =>
						item.value);
		}

		const filteredData:
			ICommonListItem<IEntityInstanceRuleViolationOverride>[] = [];

		for (const commonListItem of this.commonListContext.data.data)
		{
			const combinedFilterValue: string =
				this.getCombinedFilterValue(commonListItem)
					.toLowerCase();

			let matchingItem: boolean = true;
			combinedFilters.forEach(
				(filterValue: string) =>
				{
					matchingItem =
						matchingItem === true
							&& combinedFilterValue.indexOf(
								filterValue.toLowerCase()) !== -1;
				});

			if (matchingItem === true)
			{
				filteredData.push(commonListItem);
			}
		}

		return filteredData;
	}

	/**
	 * This method will get and return a combined filter value per item
	 * calculated based on the currently displayed rule override list item.
	 *
	 * @param {ICommonListItem<IEntityInstanceRuleViolationOverride>}
	 * ruleViolationListItem
	 * The rule violation override list item to be mapped for a combined
	 * filter value.
	 * @returns {string}
	 * The combined string of available values found in the list item used
	 * to check for matching filters.
	 * @memberof RuleOverrideListComponent
	 */
	public getCombinedFilterValue(
		ruleViolationListItem:
			ICommonListItem<IEntityInstanceRuleViolationOverride>): string
	{
		const combinedFilterValue: string =
			[
				ruleViolationListItem.item.ruleDefinitionName,
				ruleViolationListItem.item.ruleDescription,
				ruleViolationListItem.item.workflowActionDefinitionName,
				ruleViolationListItem.item.overrideUserDisplayName,
				ruleViolationListItem.item.reason
			].join(AppConstants.empty);

		return combinedFilterValue;
	}
}