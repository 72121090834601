/**
 * @copyright WaterStreet. All rights reserved.
*/

/**
 * An enum for designating what an observable should observe in
 * an http request.
 * @see HttpClient
 */
export enum ObserveType
{
	/**
	 * Observe the body
	 * @type {string}
	 * @memberof ObserveType
	 */
	Body = 'body',

	/**
	 * Observe events.
	 * @type {string}
	 * @memberof ObserveType
	 */
	Events = 'events',

	/**
	 * Observe the response.
	 * @type {string}
	 * @memberof ObserveType
	 */
	Response = 'response'
}