/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';

@Component({
	selector: 'app-entity',
	templateUrl: './entity.component.html'
})

/**
 * A component representing an instance of the main
 * entity page.
 *
 * @export
 * @class EntityComponent
 */
export class EntityComponent
{
}
