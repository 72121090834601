/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Injectable
} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	User
} from '@shared/implementations/users/user';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/**
 * A class representing an instance of a module guard.
 * This is used to ensure that the module based route prefix
 * accurately points to a defined application module.
 *
 * @export
 * @class ModuleGuard
 * @implements {CanActivate}
 */
@Injectable({
	providedIn: 'root'
})
export class ModuleGuard
{
	/**
	 * Initializes a new instance of the ModuleGuard class.
	 *
	 * @param {Router} router
	 * The router used for site navigation.
	 * @param {ModuleService} moduleService
	 * The module service used for this guard.
	 * @param {CacheService} cacheService
	 * The cache service used for this guard.
	 * @param {SessionService} sessionService
	 * The session service for this guard.
	 * @memberof ModuleGuard
	 */
	public constructor(
		private readonly router: Router,
		private readonly moduleService: ModuleService,
		private readonly cacheService: CacheService,
		private readonly sessionService: SessionService)
	{
	}

	/**
	 * Implements the can activate interface for this guard.
	 * This will accurately define if the url entered first segment
	 * for a module is defined in our system. If this is not found
	 * a message will be displayed to the user that the url is impoperly
	 * formed and route them to the dashboard.
	 *
	 * @param {ActivatedRouteSnapshot} next
	 * The expected next value for the activated route.
	 * @param {RouterStateSnapshot} _state
	 * The expected current value for the activated route.
	 * @returns {Promise<boolean>}
	 * A truthy defining if this guard will allow activation of the sent
	 * url based navigation.
	 * @memberof ModuleGuard
	 */
	public async canActivate(
		next: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot): Promise<boolean>
	{
		this.cacheService.clearAllSubscriptions();

		if (AnyHelper.isNull(next.url[0]))
		{
			this.router.navigate([AppConstants.route.dashboardPage]);

			return Promise.resolve(false);
		}

		this.moduleService.name =
			StringHelper.toProperCase(next.url[0].path);

		const securityGroup: string =
			`${this.moduleService.name}_Module_Access`;

		const lowerCaseModuleName: string =
			this.moduleService.name.toLocaleLowerCase();

		if (lowerCaseModuleName === AppConstants.moduleNames.dashboard
			|| lowerCaseModuleName === AppConstants.moduleNames.account)
		{
			return true;
		}

		return new Promise(
			async(resolve) =>
			{
				const user: User = new User(this.sessionService.user);

				const hasMembership: boolean =
					user.hasMembership([securityGroup]);

				if (!hasMembership)
				{
					EventHelper.dispatchBannerEvent(
						'Access Denied',
						`You don't have access to '${this.moduleService.name}'.`
							+ ' Redirecting to the dashboard.',
						AppConstants.activityStatus.error);

					this.router.navigate([AppConstants.route.dashboardPage]);
				}

				resolve(hasMembership);
			});
	}
}