<ng-template ngFor let-child let-i="index"
	[ngForOf]="(root ? item : item.items)">
	<li [@divState]="state"
		[ngClass]="{
			'active-menuitem': isActive(i)
		}"
		[class]="child.badgeStyleClass"
		*ngIf="child.visible === false ? false : true">

		<a [href]="child.url||'#'"
			(click)="itemClick($event)"
			(tap)="itemClick($event,child,i)"
			class="ripplelink"
			*ngIf="!child.routerLink"
			[attr.tabindex]="!visible ? '-1' : null"
			[attr.target]="child.target"
			[ngClass]="{
				'disabled-operation': (child.disabled)
			}">

			<div #RootOperation
				class="ui-g no-padding"
				[pTooltip]="child.tooltipOptions?.tooltipLabel"
				tooltipStyleClass="operation-menu-tooltip"
				(click)="preventDefault($event, child.tooltipOptions?.tooltipLabel)"
				(tap)="mobileTooltipToggle(RootOperation)">
				<div class="ui-g-12 no-padding">
					<div class="ui-g-2 no-padding"
						*ngIf="child.icon">
						<i [ngClass]="child.icon">
						</i>
					</div>
					<div class="no-padding"
						[ngClass]="{
							'ui-g-8': (child.items) && (child.icon),
							'ui-g-10': (!(child.items) && (child.icon)) || ((child.items) && !(child.icon)),
							'ui-g-12': !(child.items) && !(child.icon)
						}">
						<span class="ellipsis-text">
							<span *ngIf="root != true"
								class="subgroup-spacing">
							</span>
							{{child.label}}
						</span>
					</div>
					<div class="ui-g-2 no-padding"
						*ngIf="child.items != null && child.items.length > 0">
						<i class="fa fa-fw fa-angle-down menuitem-toggle-icon">
						</i>
					</div>
				</div>
			</div>
			<span class="menuitem-badge" *ngIf="child.badge">
				{{child.badge}}
			</span>
		</a>

		<a (click)="itemClick($event)"
			(tap)="itemClick($event,child,i)"
			class="ripplelink"
			*ngIf="child.routerLink"
			[routerLink]="child.routerLink"
			routerLinkActive="active-menuitem-routerlink"
			[routerLinkActiveOptions]="{exact: true}"
			[attr.tabindex]="!visible ? '-1' : null"
			[attr.target]="child.target"
			[ngClass]="{
				'disabled-operation': (child.disabled)
			}">

			<div #ChildOperation
				class="ui-g no-padding"
				[pTooltip]="child.tooltipOptions?.tooltipLabel"
				tooltipStyleClass="operation-menu-tooltip"
				(click)="preventDefault($event, child.tooltipOptions?.tooltipLabel)"
				(tap)="mobileTooltipToggle(ChildOperation)">
				<div class="ui-g-12 no-padding">
					<div class="ui-g-2 no-padding"
						*ngIf="child.icon">
						<i [ngClass]="child.icon">
						</i>
					</div>
					<div class="no-padding"
						[pTooltip]="child.tooltipOptions?.tooltipLabel"
						[ngClass]="{
							'ui-g-8': (child.items) && (child.icon),
							'ui-g-10': (!(child.items) && (child.icon)) || ((child.items) && !(child.icon)),
							'ui-g-12': !(child.items) && !(child.icon)
						}">
						<span class="ellipsis-text">
							{{child.label}}
						</span>
					</div>
					<div class="ui-g-2 no-padding"
						*ngIf="child.items">
						<i class="fa fa-fw fa-angle-down menuitem-toggle-icon">
						</i>
					</div>
				</div>
			</div>
			<span class="menuitem-badge"
				*ngIf="child.badge">
				{{child.badge}}
			</span>
		</a>
		<div class="layout-menu-tooltip">
			<div class="layout-menu-tooltip-arrow">
			</div>
			<div class="layout-menu-tooltip-text">
				{{child.label}}
			</div>
		</div>
		<div class="submenu-arrow"
			*ngIf="child.items">
		</div>

		<p-scrollPanel #layoutMenuScroller>
			<ul operation-subgroup
				[ngClass]="{
					'parent-group': root
				}"
				[item]="child"
				*ngIf="child.items"
				[visible]="isActive(i)"
				[reset]="reset"
				[parentActive]="isActive(i)"
				(menuItemClicked)="itemClicked($event)"
				[@easeAnimation]="isActive(i) ? 'visible' : 'hidden'"
				[@displayAnimation]="isActive(i) ? 'visible' : 'hidden'"
				[@children]="
					root
						? isActive(i)
							? 'visible'
							: 'hidden'
						: isActive(i)
							? 'visibleAnimated'
							: 'hiddenAnimated'">
			</ul>
		</p-scrollPanel>
	</li>
</ng-template>