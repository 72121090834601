/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A constant library used to defined available form
 * emitted events that can be consumed in listening components
 * via the host listener.
 *
 * @export
 * @const FormEventConstants
 */
export const FormEventConstants:
{
	onChangeEvent: string;
} = {
	onChangeEvent: 'onChange'
};