/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A constant library used to defined available mouse events.
 *
 * @export
 * @const MouseEventConstants
 */
export const MouseEventConstants:
{
	click: string;
	mouseEnter: string;
	mouseLeave: string;
	mouseOut: string;
	mouseOver: string;
	tap: string;
} = {
	click: 'click',
	mouseEnter: 'mouseenter',
	mouseLeave: 'mouseleave',
	mouseOut: 'mouseout',
	mouseOver: 'mouseover',
	tap: 'tap'
};