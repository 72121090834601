<div class="custom-image-input">
	<div class="p-inputgroup">
		<input class="display-none"
			type="text"
			aria-label="customimageinput"
			pInputText
			[formControl]="formControl"
			[formlyAttributes]="field" />
		<span *ngIf="logoExists()">
			<p-image [src]="images[0].source"
				[alt]="images[0].alt ?? 'Custom Image'"
				[title]="images[0].title ?? 'Custom Image'"
				[preview]="true">
			</p-image>
		</span>
		<span
			*ngIf="!field.props.disabled && logoExists()">
			<p-button
				class="removeButton"
				icon="pi pi-times"
				(click)="removeButtonClicked($event)">
			</p-button>
		</span>
	</div>

	<div *ngIf="field.props.alwaysDisplayUploader || !logoExists()">
		<div *ngIf="field.props.disabled; else uploader">
			No image/logo uploaded
		</div>
		<ng-template #uploader>
			<p-fileUpload
				#fileUploader
				[url]="images"
				accept="image/*"
				customUpload="true"
				[auto]="field.props.autoUpload ?? true"
				chooseLabel="Browse"
				maxFileSize="1000000"
				[multiple]="false"
				[styleClass]="inputStyleClass"
				(uploadHandler)="imageCustomUploader($event)">
				<ng-template pTemplate="content">
					<div class="drag-and-drop-file-prompt">
						Drag the file here to upload
					</div>
				</ng-template>
			</p-fileUpload>
		</ng-template>
	</div>
</div>

<field-validation-message
	[field]="field">
</field-validation-message>