<div class="login-body"
	*ngIf="visible === true"
	[@contentAnimation]="state">
	<div class="login-image"
		style="vertical-align: bottom;">
	</div>
	<div class="login-messages">
		<p-messages
			[(value)]="loginMessages">
		</p-messages>
	</div>
	<div class="card login-panel p-fluid">
		<div class="login-panel-content">
			<app-login-dialog
				*ngIf="this.showLogin">
			</app-login-dialog>
			<app-login-change-password
				*ngIf="this.showChangePassword">
			</app-login-change-password>
			<app-login-verify
				*ngIf="this.showVerify">
			</app-login-verify>
			<app-login-reset
				*ngIf="this.showResetUser">
			</app-login-reset>
		</div>
	</div>
</div>