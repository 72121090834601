/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Injectable
} from '@angular/core';
import {
	LogApiService
} from '@api/services/logs/log.api.service';
import {
	ILogEntry
} from '@shared/interfaces/logs/log-entry.interface';
import {
	ILogMessage
} from '@shared/interfaces/logs/log-message.interface';
import {
	SessionService
} from '@shared/services/session.service';
import {
	AppConfig
} from 'src/app/app.config';

/**
 * An enum representing the different log levels.
 *
 * @export
 * @enum {number}
 */
export enum LogLevel
{
	None = 0,
	Error = 1,
	Warning = 2,
	Information = 3,
	Debug = 4,
	Trace = 5
}

/**
 * A class representing a Logger Service.
 *
 * @export
 * @class LoggerService
 */
@Injectable(
	{
		providedIn: 'root'
	})
export class LoggerService
{
	/**
	 * Initializes a new instance of the LoggerService class.
	 *
	 * @param {NGXLogger} logger An NGXLogger used for logging.
	 * information.
	 *
	 * @memberof LoggerService
	 */
	public constructor(
		private readonly logApiService: LogApiService,
		private readonly sessionService: SessionService)
	{
		this.consoleLogEnabled =
			AppConfig.settings.logging.consoleLogEnabled
				.toLowerCase() === 'true';
		this.consoleLogLevel =
			LogLevel[AppConfig.settings.logging.consoleLogLevel];
		this.serverLogLevel =
			LogLevel[AppConfig.settings.logging.serverLogLevel];
	}

	/**
	 * Gets or sets the log level that is sent to the server to be logged.
	 *
	 * @private
	 * @type {string}
	 * @memberof LoggerService
	 */
	public serverLogLevel: LogLevel;

	/**
	 * Gets or sets a value indicating whether console logging should occur.
	 *
	 * @private
	 * @type {string}
	 * @memberof LoggerService
	 */
	private readonly consoleLogEnabled: boolean;

	/**
	 * Gets or sets the log level that is used for logging to client logs
	 * such as the console.
	 *
	 * @private
	 * @type {string}
	 * @memberof LoggerService
	 */
	private readonly consoleLogLevel: LogLevel;

	/**
	 * Gets the available log messages.
	 *
	 * @async
	 * @param {string} filter
	 * A string representing the filters for the query.
	 * @param {string} orderBy
	 * A string representing the order by for the query.
	 * @param {number} [offset]
	 * A number representing the skip offset.
	 * @param {number} [limit]
	 * A number representing the top limit count.
	 * @param {number} [last]
	 * A number representing the last count.
	 * @returns {Promise<ILogMessageDto[]>}
	 * A promise representing the set of available log messages.
	 * @memberof LoggerService
	 */
	public async getLogMessages(
		filter: string,
		orderBy: string,
		offset?: number,
		limit?: number,
		last?: number): Promise<ILogMessage[]>
	{
		return this.logApiService.getLogMessages(
			filter,
			orderBy,
			offset,
			limit,
			last);
	}

	/**
	 * Delegates a debug message to this.logger.
	 *
	 * @param {string} message The message to log.
	 * @param {any[]} additional Any additional arguments to format into message
	 *
	 * @memberof LoggerService
	 */
	public logDebug(
		message: string | ILogEntry): void
	{
		this.logEntry(
			message,
			LogLevel.Debug);
	}

	/**
	 * Delegates a error message to this.logger.
	 *
	 * @param {string} message The message to log.
	 * @param {any[]} additional Any additional arguments to format into message
	 *
	 * @memberof LoggerService
	 */
	public logError(
		message: string | ILogEntry): void
	{
		this.logEntry(
			message,
			LogLevel.Error);
	}

	/**
	 * Delegates a info message to this.logger.
	 *
	 * @param {string} message The message to log.
	 * @param {any[]} additional Any additional arguments to format into message
	 *
	 * @memberof LoggerService
	 */
	public logInformation(
		message: string | ILogEntry): void
	{
		this.logEntry(
			message,
			LogLevel.Information);
	}

	/**
	 * Delegates a trace message to this.logger.
	 *
	 * @param {string} message The message to log.
	 * @param {any[]} additional Any additional arguments to format into message
	 *
	 * @memberof LoggerService
	 */
	public logTrace(
		message: string | ILogEntry): void
	{
		this.logEntry(
			message,
			LogLevel.Trace);
	}

	/**
	 * Delegates a warn message to this.logger.
	 *
	 * @param {string} message The message to log.
	 * @param {any[]} additional Any additional arguments to format into message
	 *
	 * @memberof LoggerService
	 */
	public logWarning(
		message: string | ILogEntry): void
	{
		this.logEntry(
			message,
			LogLevel.Warning);
	}

	/**
	 * Logs a single entry to the console and to the server.
	 *
	 * @private
	 * @param {(string | ILogEntry)} log - Either an ILogEntry or a string.
	 * If it is a string, then an ILogEntry is creaated and sent to the
	 * appropriate log.
	 *
	 * @param {LogLevel} [level=LogLevel.Debug] - The level the log should
	 * be.  If it is not provided then the default is debug.
	 *
	 * @memberof LoggerService
	 */
	public logEntry(
		log: string | ILogEntry,
		level: LogLevel = LogLevel.Debug): void
	{
		if (typeof log === 'string')
		{
			const logEntry = <ILogEntry>
				{
					'level': LogLevel[level],
					'message': log
				};
			this.sendEntryToConsole(logEntry);
			this.sendEntryToServer(logEntry);
		}
		else
		{
			this.sendEntryToConsole(log);
			this.sendEntryToServer(log);
		}
	}

	/**
	 * Sends the @see logEntry to the console.
	 *
	 * @private
	 * @param {ILogEntry} logEntry - The entry to send to the console.
	 *
	 * @memberof LoggerService
	 */
	private sendEntryToConsole(
		logEntry: ILogEntry): void
	{
		const shouldLog: boolean = this.shouldLog(
			logEntry,
			this.consoleLogLevel);

		if (this.consoleLogEnabled
			&& shouldLog)
		{
			const logLevel: LogLevel = LogLevel[logEntry.level];
			switch (logLevel)
			{
				case LogLevel.Trace:
					console.log(logEntry.message);
					break;
				case LogLevel.Debug:
					console.log(logEntry.message);
					break;
				case LogLevel.Information:
					console.log(logEntry.message);
					break;
				case LogLevel.Warning:
					console.warn(logEntry.message);
					break;
				case LogLevel.Error:
					console.error(logEntry.message);
					break;
			}
		}
	}

	/**
	 * Sends the @see logEntry to the server.
	 *
	 * @private
	 * @param {ILogEntry} logEntry - The entry to send to the server.
	 *
	 * @memberof LoggerService
	 */
	private sendEntryToServer(
		logEntry: ILogEntry): void
	{
		let shouldLog: boolean =
			this.sessionService.isValid
			&& this.shouldLog(
				logEntry,
				this.serverLogLevel);

		if (!AppConfig.settings.logging.serverLogUrl)
		{
			shouldLog = false;
		}

		if (shouldLog)
		{
			this.logApiService.addLogEntry(logEntry)
				.catch((error) =>
				{
					this.sendEntryToConsole(<ILogEntry>{
						level: 'Error',
						message: 'Unable to send log to server.',
						exception: error.message
					});
				});
		}
	}

	/**
	 * Gets whether the @see logEntry should be logged.  The decision is
	 * based on the @see configuredLevel and the level of @see logEntry.
	 *
	 * @private
	 * @param {ILogEntry} logEntry - The entry to test
	 * @param {boolean} configuredLogLevel - The level from the config file to
	 * compare against.
	 * @returns {boolean}
	 *
	 * @memberof LoggerService
	 */
	private shouldLog(
		logEntry: ILogEntry,
		configuredLogLevel: LogLevel): boolean
	{
		const entryLevel: number = LogLevel[logEntry.level];
		const shouldLog: boolean = entryLevel <= configuredLogLevel;

		return shouldLog;
	}
}