/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	CommonDashboardComponent
} from '@shared/components/common-dashboard/common-dashboard.component';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	FileHelper
} from '@shared/helpers/file.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IKeyValuePair
} from '@shared/interfaces/application-objects/key-value-pair.interface';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-forms',
	templateUrl: './transaction-forms.component.html'
})

/**
 * A component representing an associated company used
 * in the associated entity list.
 *
 * @export
 * @class TransactionFormsComponent
 * @implements {IDynamicComponent<Component, {
		entityInstance: IEntityInstance,
		entityListContext: IAssociatedEntityListContext
	}>}
 */
export class TransactionFormsComponent
implements IDynamicComponent<Component, {
	entityInstance: any;
	entityListContext: IAssociatedEntityListContext;
}>, OnInit
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof TransactionFormsComponent
	 */
	public context: IDynamicComponentContext<Component, {
		entityInstance: any;
			entityListContext: IAssociatedEntityListContext;
		}>;

	public forms: IFileEntity[] = [];

	/**
	 * Initiates the component to get the data object.
	 *
	 * @memberof TransactionFormsComponent
	 */
	public ngOnInit(): void
	{
		this.forms =
			this.context.data.entityInstance?.forms;

		if (!AnyHelper.isNull(
			(<CommonDashboardComponent>this.context.source).finishedLoading))
		{
			(<CommonDashboardComponent>this.context.source)
				.finishedLoading.emit(
					true);
		}
	}

	/**
	 * Gets filetered file custom metadata.
	 *
	 * @param {IFileEntity} form
	 *
	 * @returns {string}
	 * @memberof TransactionFormsComponent
	 */
	public filterMetadata(
		form: IFileEntity): IKeyValuePair[]
	{
		return FileHelper
			.getCustomMetadata(
				form,
				[
					'formCode',
					'formEdition'
				]
			);
	}
}