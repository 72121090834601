/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AbstractControl,
	ValidationErrors,
	Validators
} from '@angular/forms';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	isArray
} from 'lodash-es';

/**
 * When the formly field config array is set into the
 * form, this code is called on each array entry and
 * allows for default options to be set on each.
 *
 * @param {FormlyFieldConfig} field
 * The formly field that will have default options set.
 */
export function applyDefaultOptions(
	field: FormlyFieldConfig): void
{
	if (field.key && !field.modelOptions)
	{
		field.modelOptions = {
			debounce: {
				default: 25
			}
		};
	}
}

/**
 * Validates thats the control has a valid email format.
 *
 * @param {AbstractControl} control
 * The formly control to validate this event.
 * @param {FormlyFieldConfig} _field
 * The formly field firing this validation event.
 * @returns {ValidationErrors}
 * The validation result.
 */
export function emailValidator(
	control: AbstractControl,
	_field: FormlyFieldConfig): ValidationErrors
{
	return !AnyHelper.isNull(
		Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)(control))
		? {
			email: true
		}
		: null;
}

/**
 * Returns the error message displayed on a formly field
 * when email format based regex validation fails.
 *
 * @param {any} _error
 * The validation error.
 * @param {FormlyFieldConfig} field
 * The formly field firing this validation event.
 * @returns {string}
 * The validation error message to be displayed.
 */
export function emailValidatorMessage(
	_error: any,
	field: FormlyFieldConfig): string
{
	return `${field.formControl.value} is not a valid email format.`;
}

/**
 * Validates thats the control has a valid required value.
 *
 * @param {AbstractControl} control
 * The formly control to validate this event.
 * @param {FormlyFieldConfig} _field
 * The formly field firing this validation event.
 * @returns {ValidationErrors}
 * The validation result.
 */
export function requiredValidator(
	control: AbstractControl,
	_field: FormlyFieldConfig): ValidationErrors
{
	return AnyHelper.isNull(
		Validators.required(control))
		? {
			required: true
		}
		: null;
}

/**
 * Validates thats the control has a valid minimum length value.
 *
 * @param {AbstractControl} control
 * The formly control to validate this event.
 * @param {FormlyFieldConfig} _field
 * The formly field firing this validation event.
 * @returns {ValidationErrors}
 * The validation result.
 */
export function minimumLengthValidator(
	control: AbstractControl,
	field: FormlyFieldConfig): ValidationErrors
{
	return !AnyHelper.isNull(Validators.minLength(
		field.props.minimumLength)(control))
		? {
			minimumLength: true
		}
		: null;
}

/**
 * Returns the error message displayed on a formly field
 * when minimum string length validation fails.
 *
 * @param {any} _error
 * The validation error.
 * @param {FormlyFieldConfig} field
 * The formly field firing this validation event.
 * @returns {string}
 * The validation error message to be displayed.
 */
export function minimumStringLengthValidationMessage(
	_error: any,
	field: FormlyFieldConfig): string
{
	const minimumLength: number =
		field.props.minLength || field.props.minimumLength;

	return `This value should have at least ${minimumLength} characters.`;
}

/**
 * Validates thats the control has a valid maximum length value.
 *
 * @param {AbstractControl} control
 * The formly control to validate this event.
 * @param {FormlyFieldConfig} _field
 * The formly field firing this validation event.
 * @returns {ValidationErrors}
 * The validation result.
 */
export function maximumLengthValidator(
	control: AbstractControl,
	field: FormlyFieldConfig): ValidationErrors
{
	return AnyHelper.isNull(
		Validators.maxLength(
			field.props.maximumLength)(control))
		? {
			maximumLength: true
		}
		: null;
}

/**
 * Returns the error message displayed on a formly field
 * when maximum string length validation fails.
 *
 * @param {any} _error
 * The validation error.
 * @param {FormlyFieldConfig} field
 * The formly field firing this validation event.
 * @returns {string}
 * The validation error message to be displayed.
 */
export function maximumStringLengthValidationMessage(
	_error: any,
	field: FormlyFieldConfig): string
{
	const maximumLength: number =
		field.props.maxLength || field.props.maximumLength;

	return `This value should be less than ${maximumLength} characters.`;
}

/**
 * Validates thats the control has a valid minimum number value.
 *
 * @param {AbstractControl} control
 * The formly control to validate this event.
 * @param {FormlyFieldConfig} _field
 * The formly field firing this validation event.
 * @returns {ValidationErrors}
 * The validation result.
 */
export function minimumNumberValidator(
	control: AbstractControl,
	field: FormlyFieldConfig): ValidationErrors
{
	return (isArray(control.value)
		&& control.value.length >= field.props.minimumNumber)
		|| (!isArray(control.value)
			&& AnyHelper.isNull(Validators.min(
				field.props.minimumNumber)(control)))
		? {
			minimumNumber: true
		}
		: null;
}

/**
 * Returns the error message displayed on a formly field
 * when the minimum number validation fails.
 *
 * @param {any} _error
 * The validation error.
 * @param {FormlyFieldConfig} field
 * The formly field firing this validation event.
 * @returns {string}
 * The validation error message to be displayed.
 */
export function minimumNumberValidationMessage(
	_error: any,
	field: FormlyFieldConfig): string
{
	const minimumNumber: number =
		field.props.min || field.props.minimumNumber;
	const maximumNumber: number =
		field.props.max || field.props.maximumNumber;
	const minItemMessage: string = minimumNumber > 1
		? 'items are'
		: 'item is';
	const maxItemMessage: string = maximumNumber > 1
		? 'items are'
		: 'item is';
	const repeaterStatement: string =
		AnyHelper.isNull(maximumNumber)
			? `At least ${minimumNumber} ${minItemMessage} required.`
			: `Between ${minimumNumber || 0} `
				+ `and ${maximumNumber} ${maxItemMessage} required.`;

	return isArray(field.formControl.value)
		? repeaterStatement
		: 'This value should be greater than or equal to ' +
			minimumNumber.toLocaleString() + AppConstants.characters.period;
}

/**
 * Validates thats the control has a valid maximum number value.
 *
 * @param {AbstractControl} control
 * The formly control to validate this event.
 * @param {FormlyFieldConfig} _field
 * The formly field firing this validation event.
 * @returns {ValidationErrors}
 * The validation result.
 */
export function maximumNumberValidator(
	control: AbstractControl,
	field: FormlyFieldConfig): ValidationErrors
{
	return (isArray(control.value)
		&& control.value.length <= field.props.maximumNumber)
		|| (!isArray(control.value)
			&& AnyHelper.isNull(Validators.max(
				field.props.maximumNumber)(control)))
		? {
			maximumNumber: true
		}
		: null;
}

/**
 * Returns the error message displayed on a formly field
 * when the maximum number validation fails.
 *
 * @param {any} _error
 * The validation error.
 * @param {FormlyFieldConfig} field
 * The formly field firing this validation event.
 * @returns {string}
 * The validation error message to be displayed.
 */
export function maximumNumberValidationMessage(
	_error: any,
	field: FormlyFieldConfig): string
{
	const minimumNumber: number =
		field.props.min || field.props.minimumNumber;
	const maximumNumber: number =
		field.props.max || field.props.maximumNumber;
	const repeaterStatement: string =
		AnyHelper.isNull(minimumNumber)
			? `This repeater requires no more than ${maximumNumber} `
				+ 'item(s).'
			: `This repeater requires between ${minimumNumber} `
				+ `and ${maximumNumber} item(s).`;

	return isArray(field.formControl.value)
		? repeaterStatement
		: 'This value should be less than or equal to ' +
			maximumNumber.toLocaleString() + AppConstants.characters.period;
}

/**
 * Validates thats the control has a valid regular expression value.
 *
 * @param {AbstractControl} control
 * The formly control to validate this event.
 * @param {FormlyFieldConfig} _field
 * The formly field firing this validation event.
 * @returns {ValidationErrors}
 * The validation result.
 */
export function regularExpressionValidator(
	control: AbstractControl,
	field: FormlyFieldConfig): ValidationErrors
{
	return AnyHelper.isNull(Validators.pattern(
		field.props.regularExpression)(control))
		? {
			regularExpression: true
		}
		: null;
}

/**
 * Returns the error message displayed on a formly field
 * when a format based regex validation fails.
 *
 * @param {any} _error
 * The validation error.
 * @param {FormlyFieldConfig} field
 * The formly field firing this validation event.
 * @returns {string}
 * The validation error message to be displayed.
 */
export function invalidFormatValidationMessage(
	_error: any,
	field: FormlyFieldConfig): string
{
	return `${field.formControl.value} is not a valid format.`;
}

/**
 * Validates thats the formly control has a valid password based on the system
 * password restrictions.
 *
 * @param {AbstractControl} control
 * The abstract control to be validated.
 * @param {FormlyFieldConfig} field
 * The formly field control that contains the system security restrictions.
 * @returns {ValidationErrors}
 * The validation result.
 */
export function passwordValidator(
	control: AbstractControl,
	field: FormlyFieldConfig): ValidationErrors
{
	const securityRestrictions: any =
		field.props?.securityRestrictions;

	return !SecurityHelper.isValidPassword(
		control.value,
		securityRestrictions,
		false)
		? { 'invalidPassword': true }
		: null;
}

/**
 * Validates thats the reactive form control has a valid password based on the
 * system password restrictions.
 *
 * @param {any} securityRestrictions
 * The system password security restrictions.
 * @returns {ValidationErrors}
 * The validation result.
 */
export function passwordValidatorReactiveForms(
	securityRestrictions: any): ValidationErrors
{
	return (control: AbstractControl): { [key: string]: any } | null =>
	{
		const password: string =
			control.value;

		const isValidPassword: boolean =
			SecurityHelper.isValidPassword(
				password,
				securityRestrictions);

		return !isValidPassword
			? { 'invalidPassword': true }
			: null;
	};
}

/**
 * Returns the error message displayed on a formly field
 * when a passowrd definition fails.
 *
 * @param {any} _error
 * The validation error.
 * @param {FormlyFieldConfig} field
 * The formly field firing this validation event.
 * @returns {string}
 * The validation error message to be displayed.
 */
export function passwordValidatorMessage(
	_error: any,
	_field: FormlyFieldConfig): string
{
	return 'Password is invalid.';
}