/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input
} from '@angular/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';

// tslint:disable: max-line-length

@Component({
	selector: 'commission-disbursement-list-expand',
	templateUrl: './commission-disbursement-list-expand.component.html'
})

/**
 * A component representing a commissions disbursement expand component.
 *
 * @export
 * @class CommissionDisbursementListExpandComponent
 * @extends CommonTablePageDirective
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 */
export class CommissionDisbursementListExpandComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<CommonTableComponent, any>
{
	/**
	 * Instantiates the class.
	 *
	 * @param {ResolverService} resolver
	 * The resolver service for this class.
	 * @memberof CommissionDisbursementListExpandComponent
	 */
	public constructor(
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the parent context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof CommissionDisbursementListExpandComponent
	 */
	@Input() public parentContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the containing component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof CommissionDisbursementListExpandComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the loading table definitions state.
	 *
	 * @type {boolean}
	 * @memberof CommissionDisbursementListExpandComponent
	 */
	public loadingTableDefinition: boolean = true;

	/**
	 * Gets or sets the common table component.
	 *
	 * @type {ICommonTable}
	 * @memberof CommissionDisbursementListExpandComponent
	 */
	public commonTable: ICommonTable;

	/**
	 * Gets or sets the available columnss
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof CommissionDisbursementListExpandComponent
	 */
	public availableColumns: ICommonTableColumn[] =
		[
			{
				columnHeader: 'Memo',
				displayOrder: 1,
				dataKey: 'ledgerTransactionCommission.note'
			},
			{
				columnHeader: 'Type',
				displayOrder: 2,
				dataKey: 'ledgerTransactionCommission.type'
			},
			{
				columnHeader: 'Commissionable Amount',
				displayOrder: 3,
				dataKey: 'ledgerTransactionCommission.metadata.commissionableAmount',
				dataFormatType: 'Currency',
			},
			{
				columnHeader: 'Schedule',
				displayOrder: 4,
				dataKey: 'ledgerTransactionCommission.metadata.commissionScheduleName'
			},
			{
				columnHeader: 'Rate',
				displayOrder: 5,
				dataKey: 'ledgerTransactionCommission.metadata.commissionRate',
				dataFormatType: 'Percent',
			},
			{
				columnHeader: 'Amount',
				displayOrder: 6,
				dataKey: 'ledgerTransactionCommission.amount',
				dataFormatType: 'Currency',
			}
		];

	/**
	 * sets up the table definitions
	 *
	 * @memberof CommissionDisbursementListExpandComponent
	 */
	public setupTableDefinitions(): void
	{
		this.commonTable =
			{
				tableTitle: 'Commission Detail',
				hideSettings: true,
				nestedTable: true,
				objectSearch: {
					filter: AppConstants.empty,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					sortField: 'accountingDate',
					virtualPageSize: 5
				},
				apiPromise: (_: IObjectSearch) =>
					Promise.resolve(
						this.context.source.selectedItem.commissions),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
					this.commonTableContext = commonTableContext
			};

		this.loadingTableDefinition = false;
	}
}