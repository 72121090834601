/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Location
} from '@angular/common';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SecurityRightCategory
} from '@shared/constants/enums/security-right-category.enum';
import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'app-rules',
	templateUrl: './rules.component.html',
	styleUrls: [
		'./rules.component.scss'
	]
})

/**
 * A component representing context level rules.
 *
 * @export
 * @class RulesComponent
 * @extends {DrawerListDirective<IEntityInstanceRuleViolation>}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 * @implements {IOwnershipGuardComponent}
 */
export class RulesComponent
	extends DrawerListDirective<IEntityInstanceRuleViolation>
	implements OnInit, IDynamicComponent<Component, any>,
	IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the rules component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	 * @memberof RulesComponent
	 */
	public constructor(
		public entityService: EntityService,
		public sessionService: SessionService,
		public location: Location)
	{
		super();
	}

	/**
	 * Gets or sets a value representing the active list mode.
	 *
	 * @type {string}
	 * @memberof RulesComponent
	 */
	public listDisplayMode: string = this.displayModes.list;

	/**
	 * Gets or sets a set of nested item level actions that are used in
	 * this drawer list.
	 *
	 * @type {ISecureMenuItem[]}
	 * @memberof RulesComponent
	 */
	public nestedItemActions: ISecureMenuItem[] = [];

	/**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @async
	 * @memberof RulesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!(this.context.source instanceof EntityInstanceComponent))
		{
			return;
		}

		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;

		if (!await this.isPageOwnershipAllowed())
		{
			this.accessDeniedUrl = this.location.path();
			this.sessionIdentifier = this.sessionService.sessionId;
			this.resources =
				[
					'Entity.Type',
					'Entity.Version'
				];
			this.clientMessage =
				'Access is required to this entity type and version of '
					+ `'${entityInstanceComponent.entityTypeGroup}'.`;
			this.isOwnershipAllowed = false;
		}

		await this.setSecurityDefinitions(
			entityInstanceComponent.id,
			entityInstanceComponent.entityType.group,
			entityInstanceComponent.entityDefinition,
			entityInstanceComponent.entityInstanceApiService,
			entityInstanceComponent.entityTypeApiService);

		this.itemActions =
			[
				<ISecureMenuItem>
				{
					icon: 'fa-' + AppConstants.cssIcons.flag,
					id: AppConstants.displayMode.list,
					label: 'Rules',
					command:
						(event: any) =>
						{
							this.displayMode =
								this.displayModes.list;
							this.listDisplayMode = this.displayMode;
							event.stopPropagation();
						},
					securityRightCategory: SecurityRightCategory.TopLevelRight,
					securityRightType: SecurityRightType.read
				},
				<ISecureMenuItem>
				{
					icon: 'fa-' + AppConstants.cssIcons.history,
					id: this.displayModes.secondaryList,
					label: 'Rule Overrides',
					command:
						(event: any) =>
						{
							this.displayMode =
								AppConstants.displayMode.secondaryList;
							this.listDisplayMode = this.displayMode;
							event.stopPropagation();
						},
					securityRightCategory: SecurityRightCategory.TopLevelRight,
					securityRightType: SecurityRightType.read
				}
			];

		this.nestedItemActions =
			[
				<ISecureMenuItem>
				{
					icon: 'fa-' + AppConstants.cssIcons.infoCircle,
					id: AppConstants.displayMode.view,
					command:
						(event: any) =>
						{
							event.stopPropagation();
						},
					securityRightCategory: SecurityRightCategory.TopLevelRight,
					securityRightType: SecurityRightType.read
				}
			];

		this.context.data =
			{
				...this.context.data,
				drawerComponent: this
			};

		this.displayMode = this.displayModes.list;
		this.listDisplayMode = this.displayMode;
	}

	/**
	 * Gets the action label for nested icon displays.
	 *
	 * @returns {string}
	 * The action label.
	 * @memberof RulesComponent
	 */
	public getActionLabel(): string
	{
		return this.listDisplayMode === this.displayModes.list
			? 'Overrides'
			: 'Override Details';
	}

	/**
	 * Handles the back action.
	 * This will set the display mode to the last used list display mode.
	 *
	 * @memberof RulesComponent
	 */
	public handleBackAction(): void
	{
		this.displayMode = this.listDisplayMode;
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate drawer ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this drawer.
	 * @memberof RulesComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		await this.entityService.setStoredVariables();

		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;
		const entityType: IEntityType =
			this.entityService
				.entityTypes
				.find(
					(type: IEntityType) =>
						type.group ===
							entityInstanceComponent.entityTypeGroup);

		if (AnyHelper.isNull(entityType))
		{
			return false;
		}

		return this.entityService.verifyEntityTypeAccess(
			entityType);
	}
}