/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	state,
	style,
	trigger
} from '@angular/animations';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	SelectCustomControlDirective
} from '@entity/directives/select-custom-control.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IGroupedDropdownOption
} from '@shared/interfaces/application-objects/grouped-dropdown-option.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	isArray
} from 'lodash-es';
import {
	Observable,
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'custom-select',
	templateUrl: './custom-select.component.html',
	styleUrls: [
		'./custom-select.component.scss'
	],
	animations: [
		trigger('displayAnimation', [
			state('hidden', style({
				visibility: 'hidden'
			})),
			state('visible', style({
				visibility: 'visible'
			}))
		])
	]
})

/**
 * A component representing an instance of a Custom Select.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomSelectComponent
 * @extends {SelectCustomControlDirective}
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
export class CustomSelectComponent
	extends SelectCustomControlDirective
	implements OnInit, OnDestroy, AfterViewInit
{
	/** Initializes a new instance of the CustomSelectComponent.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomSelectComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetector: ChangeDetectorRef)
	{
		super(siteLayoutService,
			changeDetector);
	}

	/**
	 * Gets or sets the filter event that will be emitted when the filter
	 * input is altered.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof CustomSelectComponent
	 */
	@Output() public onfilterEvent: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets or sets the initial property show clear value prior to
	 * in code alters. This value is reset on destroy.
	 *
	 * @type {boolean}
	 * @memberof CustomSelectComponent
	 */
	public archivedShowClear: boolean;

	/**
	 * On component initialization event.
	 * This method is used to set default values if these are defined
	 * and a set value does not exist.
	 *
	 * @memberof CustomSelectComponent
	 */
	public ngOnInit(): void
	{
		this.archivedDisable = this.field.props.disabled;
		this.archivedPlaceHolder = this.field.props.placeholder;
		this.archivedShowClear = this.field.props.showClear;
		this.field.props.updateDataOptions =
			this.updateDataOptions.bind(this);

		if (AnyHelper.isNull(this.dataOptions))
		{
			this.dataOptions = this.field.props.options;
		}

		const isAnArray: boolean =
			isArray(this.dataOptions) === true;
		const isGrouped: boolean =
			isAnArray === true
				&& (<any[]>this.dataOptions).length > 0
				&& !AnyHelper.isNull((<any[]>this.dataOptions)[0].items);

		if (AnyHelper.isNullOrWhitespace(this.field.formControl.value))
		{
			setTimeout(() =>
			{
				let availableOptions: any[] | Observable<any[]> =
					isGrouped
						? <IDropdownOption[]>[]
						: this.dataOptions;

				if (isGrouped === true)
				{
					(<IGroupedDropdownOption[]>this.dataOptions)
						.forEach((groupedDataOption: IGroupedDropdownOption) =>
						{
							availableOptions =
								<IDropdownOption[]>
								[
									...<IDropdownOption[]>availableOptions,
									...groupedDataOption.items
								];
						});
				}

				if (!AnyHelper.isNullOrEmpty(
					this.field.props?.default))
				{
					this.field.formControl.setValue(
						null);

					availableOptions.forEach(
						(option: any) =>
						{
							if (option.value ===
								this.field.props.default)
							{
								this.field.formControl.setValue(
									this.field.props.default);
							}
						});
				}
				else
				{
					if (isAnArray === true
						&& (<any[]>availableOptions).length === 1
						&& this.field.props?.disableAutoSetValue !== true)
					{
						this.field.formControl.setValue(
							(<IDropdownOption[]>availableOptions)[0].value);

						this.field.props.disabled = true;
					}
					else
					{
						this.field.formControl.setValue(
							null);
					}
				}
			});
		}

		this.disableSelectionLogic();

		if (AnyHelper.isNullOrWhitespace(this.field.formControl?.value))
		{
			this.field.props.showClear = false;
		}

		super.ngOnInit();
		this.loading = false;
	}

	/**
	 * On component destroy event.
	 * This method is used to reset the disabled value to the original template
	 * option value as this is programatically altered in this component.
	 *
	 * @memberof CustomSelectComponent
	 */
	public ngOnDestroy(): void
	{
		if (!AnyHelper.isNull(this.field?.props))
		{
			this.field.props.disabled =
				this.archivedDisable;

			this.field.props.showClear =
				this.archivedShowClear;
		}
	}

	/**
	 * This event handler method is used to set the value of the form control
	 * as an empty string if null or undefined. This will then call the existing
	 * change function.
	 *
	 * @param {any} event
	 * The change event that fired this handler.
	 * @memberof CustomSelectComponent
	 */
	public change(
		event: any): void
	{
		if (AnyHelper.isNullOrWhitespace(this.field.formControl.value))
		{
			this.field.formControl
				.setValue(null);
			this.field.props.showClear = false;
		}
		else
		{
			this.field.props.showClear = this.archivedShowClear;
		}

		if (!AnyHelper.isNullOrWhitespace(this.field.props.change))
		{
			this.field.props.change(
				this.field,
				event);
		}

		this.validateControl();
	}

	/**
	 * This event handler method is used to capture and send a filter event
	 * from a filtered dropdown.
	 *
	 * @param {any} event
	 * The filter event that fired this handler.
	 * @memberof CustomSelectComponent
	 */
	public onFilter(
		event: any): void
	{
		this.onfilterEvent.emit(event.filter);
	}
}