/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';
import {
	PageContextDirective
} from '@shared/directives/page-context.directive';
import {
	ResolverService
} from '@shared/services/resolver.service';

@Component({
	selector: 'app-logos',
	templateUrl: './logos.component.html'
})

/**
 * A component representing an instance of the user interface logos
 * component.
 *
 * @export
 * @class LogosComponent
 * @extends {PageContextDirective}
 */
export class LogosComponent
	extends PageContextDirective
{
	/**
	 * Creates an instance of the logos component.
	 *
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof LogosComponent
	 */
	public constructor(
		public resolver: ResolverService)
	{
		super(resolver);
	}
}