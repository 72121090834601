/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 *  An enum for security right categories.
 *
 * @export
 */
export enum SecurityRightCategory
{
	/**
	 * Designates the top level rights.
	 *
	 * @type {string}
	 * @memberof SecurityRightCategory
	 */
	TopLevelRight = 'TopLevelRight',

	/**
	 * Designates the top action rights.
	 *
	 * @type {string}
	 * @memberof SecurityRightCategory
	 */
	Action = 'Action',

	/**
	 * Designates the top data rights.
	 *
	 * @type {string}
	 * @memberof SecurityRightCategory
	 */
	Data = 'Data',
}