<app-base-page pageTitle="Entity Search"
	[pageContext]="pageContext"
	utilityMenuOperationGroupName="">

	<div class="p-inputgroup ui-g-12 search-input">
		<input type="text"
			#SearchInput
			id="Search"
			pInputText
			placeholder="Filter"
			(keypress)="searchOnEnterKeyPress($event)">
		<button type="button"
			pButton
			icon="fa fa-search"
			styleClass="p-button-primary"
			(click)="searchEntity()">
		</button>
	</div>

	<div *ngIf="loadingTableDefinitions"
		class="text-center ui-g-12">
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>

	<div *ngIf="!loadingTableDefinitions"
		class="ui-g-12 table-container">
		<app-common-table
			[tableDefinitions]="tableDefinitions">
		</app-common-table>
	</div>
</app-base-page>