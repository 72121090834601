/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A singleton class representing module based data.
 *
 * @export
 * @class ModuleService
 */
@Injectable({
	providedIn: 'root'
})
export class ModuleService
{
	/**
	 * Gets or sets the current module name.
	 *
	 * @type {string}
	 * @memberof ModuleService
	 */
	public name: string;

	/**
	 * Gets a formatted name to use when looking up the values for
	 * a module based context menu.
	 *
	 * @returns {string}
	 * A formatted operation group name defining the current module's
	 * set of context menu operations.
	 * @memberof ModuleService
	 */
	public getContextMenuName(): string
	{
		return `${StringHelper.toProperCase(this.name)}`
			+ AppConstants.OperationIdentifiers.contextMenuModule;
	}
}