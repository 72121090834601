/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SecurityRightCategory
} from '@shared/constants/enums/security-right-category.enum';
import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';
import {
	PaymentBatchConstants
} from '@shared/constants/payment-batch-constants';
import {
	CommonChildListDirective
} from '@shared/directives/common-child-list-directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	DateTime
} from 'luxon';

@Component({
	selector: 'app-payment-batch-list',
	templateUrl: './payment-batch-list.component.html',
	styleUrls: [
		'./payment-batch-list.component.scss'
	]
})

/**
 * A component representing context level payment batch list view.
 *
 * @export
 * @class PaymentBatchListComponent
 * @extends {CommonChildListDirective}
 * @implements {IDynamicComponent<Component, any>}
 */
export class PaymentBatchListComponent
	extends CommonChildListDirective
	implements IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the work item list component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity navigation.
	 * @param {Router} router
	 * The router used for navigation in this component.
	 * @memberof PaymentBatchListComponent
	 */
	public constructor(
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public moduleService: ModuleService,
		public router: Router)
	{
		super(
			entityService,
			entityTypeApiService,
			entityInstanceApiService);
	}

	/**
	 * Gets or sets the list of users used to map created by.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof PaymentBatchListComponent
	 */
	public users: IEntityInstance[] = [];

	/**
	 * Gets or sets the list of sorts available in this list component.
	 *
	 * @type {ICommonListSort[]}
	 * @memberof PaymentBatchListComponent
	 */
	public sorters: ICommonListSort[] =
		<ICommonListSort[]>
		[
			<ICommonListSort>
			{
				name: 'Batch Date',
				value: AppConstants.commonProperties.id,
				direction: AppConstants.sortDirections.descending,
				iconAsc: AppConstants.cssClasses.sortAscending,
				iconDesc: AppConstants.cssClasses.sortDescending
			},
			<ICommonListSort>
			{
				name: 'Decision Date',
				value: 'keyDates.decisionDate',
				direction: AppConstants.sortDirections.ascending,
				iconAsc: AppConstants.cssClasses.sortAscending,
				iconDesc: AppConstants.cssClasses.sortDescending
			},
			<ICommonListSort>
			{
				name: 'Status',
				value: 'status',
				direction: AppConstants.sortDirections.ascending,
				iconAsc: AppConstants.cssClasses.sortAscending,
				iconDesc: AppConstants.cssClasses.sortDescending
			}
		];

	/**
	 * Loads all of the associated items to be displayed in this child common
	 * list based on the sent filters and sorters.
	 *
	 * @async
	 * @param {ICommonListFilter[]} filters
	 * The additional filters array above and beyond the primary filter used
	 * to further limit the items result set.
	 * @param {ICommonListSort} sorter
	 * The sort order to load these items in.
	 * @returns {Promise<IEntityInstance[]>}
	 * An awaitable promise that will contain all of the entity instances
	 * matching the wildcard filter with additional filtering and sorts
	 * based on user interface selections.
	 * @memberof PaymentBatchListComponent
	 */
	public async loadItems(
		filters?: ICommonListFilter[],
		sorter?: ICommonListSort): Promise<IEntityInstance[]>
	{
		await this.setCreatableEntityTypes(this.wildcardChildFilter);

		const loadedItems: IEntityInstance[] =
			await super.loadItems(
				filters,
				sorter);

		if (loadedItems.length === 0)
		{
			return [];
		}

		const creatorIds: string[] =
			[...new Set(
				loadedItems.map(
					(paymentBatch: IEntityInstance) =>
						paymentBatch.createdById.toString()))];

		const createdByFilter: string =
			AppConstants.commonProperties.id
				+ ' in ('
				+ ApiFilterHelper.commaSeparatedStringValues(
					creatorIds,
					AppConstants.empty)
				+ ')';

		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
		this.users =
			await ApiHelper.getFullDataSet(
				this.entityInstanceApiService,
				createdByFilter,
				AppConstants.empty);

		return loadedItems;
	}

	/**
	 * Generates a common list context that can be consumed by a common list
	 * displayed in this component.
	 *
	 * @param {ICommonListItem<IEntityInstance>[]} data
	 * The collection of data that will be displayed in this list.
	 * @returns {ICommonListContext<IEntityInstance>}
	 * The common list context to display.
	 * @memberof PaymentBatchListComponent
	 */
	public generateCommonListContext(
		data: ICommonListItem<IEntityInstance>[]):
		ICommonListContext<IEntityInstance>
	{
		const statusCheck: string = 'status eq \'';
		const statusCheckEndQuote: string = '\'';

		const listFilters: ICommonListFilter[] =
			<ICommonListFilter[]>
			[
				{
					name: 'Approved',
					value: statusCheck
						+ PaymentBatchConstants.paymentBatchStatus.approved
						+ statusCheckEndQuote
				},
				{
					name: 'Pending',
					value: statusCheck
						+ PaymentBatchConstants.paymentBatchStatus.pending
						+ statusCheckEndQuote
				},
				{
					name: 'Rejected',
					value: statusCheck
						+ PaymentBatchConstants.paymentBatchStatus.rejected
						+ statusCheckEndQuote
				}
			];

		const scrubbedActions: ISecureMenuItem[] =
			this.creatableEntityTypes?.length > 0
				? [
					<ISecureMenuItem>
					{
						icon: 'fa fa-plus-circle',
						command:
							(_) => {
								this.navigateToCreate();
							},
						securityRightCategory: SecurityRightCategory.Action,
						securityRightPath: AppConstants.apiMethods.create
					}
				]
				: [];

		const generatedCommonListContext:
			ICommonListContext<IEntityInstance> =
				<ICommonListContext<IEntityInstance>>
				{
					data: data,
					supportMarkdown: true,
					searchable: true,
					sortable: true,
					sorters: this.sorters,
					actions: scrubbedActions,
					filters: listFilters,
					onFilterChange: (source, filters) =>
						this.handleFilterChange(source, filters),
					onSortChange: (source, sorter) =>
						this.handleSortingChange(source, sorter)
				};

		return generatedCommonListContext;
	}

	/**
	 * Maps and returns a list item that represents a payment batch entity
	 * instance.
	 *
	 * @param {IEntityInstance} entityInstance
	 * The entity instance to be mapped into a list item.
	 * @returns {ICommonListItem<IEntityInstance>}
	 * The common list item to display that represents this entity instance.
	 * @memberof PaymentBatchListComponent
	 */
	public mapToListItem(
		entityInstance: IEntityInstance):
		ICommonListItem<IEntityInstance>
	{
		const batchDate: string =
			DateHelper.formatDate(
				DateTime.fromISO(
					entityInstance.data.keyDates.batchDate),
				DateHelper.presetFormats.longDateFormat);
		const decisionDate: string =
			DateHelper.formatDate(
				DateTime.fromISO(
					entityInstance.data.keyDates.decisionDate),
				DateHelper.presetFormats.longDateFormat);

		let statusColor: string = AppConstants.empty;
		let decisionUser: IEntityInstance;
		switch (entityInstance.data.status)
		{
			case PaymentBatchConstants.paymentBatchStatus.approved:
				statusColor = AppConstants.cssClasses.textSuccessColor;
				decisionUser =
					this.users.find(
						(user: IEntityInstance) =>
							user.resourceIdentifier
								=== entityInstance.data.decisionUserId);
				break;
			case PaymentBatchConstants.paymentBatchStatus.pending:
				break;
			case PaymentBatchConstants.paymentBatchStatus.rejected:
				statusColor = AppConstants.cssClasses.textSecondaryColor;
				decisionUser =
					this.users.find(
						(user: IEntityInstance) =>
							user.resourceIdentifier
								=== entityInstance.data.decisionUserId);
				break;
		}

		const createdByUser: IEntityInstance =
			this.users.find(
				(user: IEntityInstance) =>
					user.id
						=== entityInstance.createdById);
		const informationLineFormat: string =
			AnyHelper.isNull(decisionUser)
				? 'Created By: '
					+ StringHelper.toNameString(
						createdByUser?.data.firstName,
						createdByUser?.data.lastName)
				: 'Decision By: '
					+ StringHelper.toNameString(
						decisionUser?.data.firstName,
						decisionUser?.data.lastName);

		let displayType: string = entityInstance.entityType;
		displayType =
			StringHelper
				.beforeCapitalSpaces(
					displayType
						.replace(
							PaymentBatchConstants.paymentBatchIdentifiers
								.entityTypePrefix,
							AppConstants.empty)
						.replaceAll(
							AppConstants.characters.period,
							AppConstants.empty))
				+ ' Batch';

		const scrubbedInfoActions: ISecureMenuItem[] =
			SecurityHelper.scrubMenuItems(
				[
					<ISecureMenuItem>
					{
						icon: 'fa fa-info-circle',
						command:
							(_event: any,
								selectedItem:
									ICommonListItem<IEntityInstance>) =>
							{
								this.navigateToPaymentBatch(
									selectedItem.item);
							},
						securityRightCategory:
							SecurityRightCategory.TopLevelRight,
						securityRightType:
							SecurityRightType.read
					}
				],
				entityInstance.entityType,
				this.securityDefinitions);

		const scubbedEditActions: ISecureMenuItem[] =
			SecurityHelper.scrubMenuItems(
				[
					<ISecureMenuItem>
					{
						icon: 'fa fa-pencil',
						command:
							(_event: any,
								selectedItem:
									ICommonListItem<IEntityInstance>) =>
							{
								this.navigateToPaymentBatch(
									selectedItem.item,
									false);
							},
						securityRightCategory:
							SecurityRightCategory.Action,
						securityRightPath:
							AppConstants.apiMethods.update
					}
				],
				entityInstance.entityType,
				this.securityDefinitions);

		return <ICommonListItem<IEntityInstance>>
			{
				item: entityInstance,
				descriptionLineFormat:
					`<i class="fa fa-fw fa-list-alt ${statusColor} `
						+ 'item-description-icon"></i>'
						+ `**${displayType}** <br>`
						+ `${entityInstance.data.status} - `
						+ (entityInstance.data.status ===
							PaymentBatchConstants.paymentBatchStatus.pending
							? `Batch Date: ${batchDate}`
							: `Decision Date: ${decisionDate}`)
						+ '<span class="visually-hidden">'
						+ `|${PaymentBatchConstants
							.paymentBatchIdentifiers.paymentBatchIdentifier}:`
							+ `${entityInstance.id}|`
						+ '</span>',
				informationLineFormat: informationLineFormat,
				informationIcons: [],
				actions:
					entityInstance.data.status ===
						PaymentBatchConstants.paymentBatchStatus.pending
						? scubbedEditActions
						: scrubbedInfoActions
			};
	}

	/**
	 * Navigates to the payment batch create wizard.
	 *
	 * @memberof PaymentBatchListComponent
	 */
	private navigateToCreate(): void
	{
		const navigationModule: string =
			(<EntityInstanceComponent>this.context.source)
				.entityDefinition
				.contextMenuModule;

		this.router.navigate(
			[
				`${navigationModule}/entities/display`,
				'BasePage.Wizard.PaymentBatch.Create',
				AppConstants.viewTypes.create
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								data: {
									parentEntityType:
										(<EntityInstanceComponent>this
											.context.source)
											.entityType,
									parentId:
										(<EntityInstanceComponent>this
											.context.source)
											.entityInstance
											.id
								}
							})
				}
			});
	}

	/**
	 * Navigates to the payment batch create wizard.
	 *
	 * @param {IEntityInstance} paymentBatch
	 * The payment batch entity instance to navigate to.
	 * @param {boolean} viewOnly
	 * A value that signifies whether or not this should be opened
	 * in view only mode. This value defaults to true.
	 * @memberof PaymentBatchListComponent
	 */
	private async navigateToPaymentBatch(
		paymentBatch: IEntityInstance,
		viewOnly: boolean = true): Promise<void>
	{
		const entityType: IEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq `
							+ `'${paymentBatch.entityType}'`,
					AppConstants.empty);
		this.moduleService.name =
			await this.entityService.getContextMenuModule(
				entityType.name);

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				entityType.group,
				viewOnly
					? AppConstants.viewTypes.view
					: AppConstants.viewTypes.edit,
				paymentBatch.id
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}