<div *ngIf="loading"
	class="text-center ui-g-12 vertical-centered">
	<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
	</span>
</div>

<div *ngIf="!loading"
	class="ui-g-12 no-padding">
	<p-carousel [value]="feedItemGroups"
		orientation="vertical"
		[numVisible]="1"
		[numScroll]="1"
		[circular]="true"
		[autoplayInterval]="7000"
		[responsiveOptions]="responsiveOptions"
		[contentClass]="siteLayoutService.contentCssClass"
		verticalViewPortHeight="380px">
		<ng-template pTemplate="header">
			<div class="ui-g-12 title-section">
				<ng-template [ngIf]="feedContent.image.url != null"
					[ngIfElse]="feedFallBackIcon">
					<img alt="FeedImage"
						[src]="feedContent.image.url">
				</ng-template>
				<ng-template #feedFallBackIcon>
					<span class="fa fa-rss"></span>
				</ng-template>
				<span class="panel-title ellipsis-text"
					[innerHTML]="feedContent.title">
				</span>
			</div>
		</ng-template>
		<ng-template let-feedItemGroup pTemplate="item">
			<ng-template ngFor
				let-feedItem
				[ngForOf]="feedItemGroup">
				<div class="ui-g-6 no-padding card-container cursor-pointer {{siteLayoutService.contentCssClass}}"
					[ngClass]="{'context-menu-displayed' : contextMenuDisplayed }"
					(click)="cardClicked(feedItem)">
					<p-card>
						<ng-template pTemplate="header">
							<div class="ellipsis-text">
								<span class="header-section panel-sub-title"
									[innerHTML]="feedItem.title">
								</span>
							</div>
							<div *ngIf="feedItem.publicationDate != null"
								class="font-smaller theme-color-gray">
								{{feedItem.publicationDate | dateTimeFromRfc2822 | dateTimeToTrueLocal | dateTimeToFormat:'d MMM. yyyy, h:mm a' }}
							</div>
						</ng-template>
						<div>
							<p-scrollPanel>
								<span [innerHTML]="feedItem.description">
								</span>
							</p-scrollPanel>
						</div>
					</p-card>
				</div>
			</ng-template>
		</ng-template>
	</p-carousel>
</div>

