<div class="ui-g-12 no-padding">
	<div class="ui-g-12 no-horizontal-padding wizard-content-title">
	</div>
	<ng-template
		[ngIf]="context.source.wizardStepLoading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12 no-horizontal-padding text-center"
			*ngIf="allowedNonRenew === false">
			<span class="panel-sub-title">
				{{clientMessage}}
			</span>
		</div>
		<div class="ui-g-12 content-section"
			*ngIf="allowedNonRenew === true">
				<insurance-status-reasons
					[context]="context"
					[entityInstanceId]="entityInstanceId"
					[entityInstanceTypeGroup]="entityInstanceTypeGroup"
					(validityChange)="validityChanged($event)">
				</insurance-status-reasons>
		</div>
	</ng-template>
</div>