/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';

@Component({
	selector: 'app-work-items',
	templateUrl: './work-items.component.html'
})

/**
 * A component representing an instance of the main work items page.
 *
 * @export
 * @class WorkItemsComponent
 */
export class WorkItemsComponent
{
}