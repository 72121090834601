/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';

/**
 * A class representing an application Activity that will interact
 * with the Activity List component.
 *
 * @export
 * @class Activity
 */
export class Activity<TDataType>
{
	/**
	 * Creates an instance of an Activity.
	 *
	 * @param {Promise<TDataType>} action
	 * The activity action as a Promise, when resolved as expected the success
	 * message will be returned as the status message and marked as successful,
	 * else the failure message will be set and the status set as errored.
	 * If null, a guaranteed promise resolve becomes the action and this will
	 * act as others with only a success message.
	 * @param {string} pendingMessage
	 * The primary or title message for this activity. This is displayed while
	 * the activity is executing. Ie. 'Saving Agency'.
	 * @param {string} completedMessage
	 * The primary or title message for this activity. This is displayed when
	 * the activity has completed successfully. Ie 'Saved Agency'.
	 * @param {string} successDetails
	 * The status message to set for this Activity when the action completes
	 * successfully. Ie. 'Saved ABC Agency.'.
	 * @param {string} failureDetails
	 * The status message to set for this Activity when the action fires an
	 * http or application error. Ie. 'Unable to save ABC Agency.'.
	 * @memberof Activity
	 */
	public constructor(
		action: Promise<TDataType>,
		pendingMessage: string,
		completedMessage: string,
		successDetails: string,
		failureDetails: string)
	{
		let activityAction: Promise<TDataType> = action;

		if (activityAction == null)
		{
			activityAction =
				Promise.resolve(null);
		}

		this.message = pendingMessage;

		this.command =
			new Promise(
				(resolve, reject) =>
				{
					activityAction.then(
						(data) =>
						{
							this.message = completedMessage;
							this.data = data;
							resolve(successDetails);
						},
						(error) =>
						{
							this.message =
								AppConstants.messages.genericErrorMessage;
							this.data = error;
							reject(failureDetails);
						});
				});
	}

	/**
	 * Gets or sets the command for this activity.
	 * This will be of type Promise<string> and when completed
	 * successfully will display the resolve string or when failed
	 * this will display the reject string of the promise.
	 *
	 * @type {Promise<string>}
	 * @memberof Activity
	 */
	public command: Promise<string>;

	/**
	 * Gets or sets the data for the action called by this activity.
	 *
	 * @type {TDataType}
	 * @memberof Activity
	 */
	public data: TDataType;

	/**
	 * Gets or sets the time for the last activity event.
	 * This is set as the initial start of the activity execution and is set
	 * again when the activity is completed.
	 *
	 * @type {number}
	 * @memberof Activity
	 */
	public lastActivityTime: number;

	/**
	 * Gets or sets the icon for this activity.
	 *
	 * @type {string}
	 * @memberof Activity
	 */
	public icon: string;

	/**
	 * Gets or sets the unique identifier of this activity. This is
	 * generated at run time to allow for distinct gets.
	 *
	 * @type {string}
	 * @memberof Activity
	 */
	public id: string;

	/**
	 * Gets or sets the primary message or type of activity.
	 *
	 * @type {string}
	 * @memberof Activity
	 */
	public message: string;

	/**
	 * Gets or sets the status of this activity as defined in
	 * AppConstants.activityStatus.
	 *
	 * @type {string}
	 * @memberof Activity
	 */
	public status: string;

	/**
	 * Gets or sets the message for the activity when complete.
	 *
	 * @type {string}
	 * @memberof Activity
	 */
	public statusMessage: string;
}