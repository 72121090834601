/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	HttpClient,
	HttpRequest,
	HttpResponse,
	HttpXhrBackend
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	StorageMap
} from '@ngx-pwa/local-storage';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IServiceWorkerDataGroup
} from '@shared/interfaces/application-objects/service-worker-data-group';
import {
	Subscription,
	lastValueFrom
} from 'rxjs';

/**
 * A singleton class representing a service worker based application cache.
 *
 * @export
 * @class CacheService
 */
@Injectable({
	providedIn: 'root'
})
export class CacheService
{
	/**
	 * Creates an instance of a cache service.
	 *
	 * @param {StorageMap} storageMap
	 * The local storage map used for ETag handling.
	 * @memberof CacheService
	 */
	public constructor(
		private readonly storageMap: StorageMap)
	{
	}

	/**
	 * Gets or sets the currently running revalidation subscription set.
	 *
	 * @type {Subscription[]}
	 * @memberof CacheService
	 */
	public currentSubscriptions: Subscription[] = [];

	/**
	 * Gets or sets the service worker configuration from the base directory.
	 *
	 * @type {any}
	 * @memberof CacheService
	 */
	public serviceWorkerConfiguration: any;

	/**
	 * Gets the identifier for ngsw.config.json freshness property.
	 *
	 * @type {string}
	 * @memberof CacheService
	 */
	public readonly freshnessStrategyIdentifier: string = 'freshness';

	/**
	 * Gets the identifier for ngsw.config.json freshness property.
	 *
	 * @type {string}
	 * @memberof CacheService
	 */
	public readonly performanceStrategyIdentifier: string = 'performance';

	/**
	 * Gets the identifier for ngsw.config.json file name.
	 *
	 * @type {string}
	 * @memberof CacheService
	 */
	private readonly serviceWorkerConfigFileIdentifier: string =
		'ngsw-config.json';

	/**
	 * Gets the service worker configured data groups at runtime.
	 *
	 * @async
	 * @returns {IServiceWorkerDataGroup[]}
	 * The data groups that are set for service worker handling in this project.
	 * @memberof CacheService
	 */
	public async getServiceWorkerConfigurationDataGroups():
		Promise<IServiceWorkerDataGroup[]>
	{
		if (!AnyHelper.isNull(this.serviceWorkerConfiguration))
		{
			return this.serviceWorkerConfiguration.dataGroups;
		}

		const http: HttpClient = new HttpClient(
			new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

		this.serviceWorkerConfiguration =
			await lastValueFrom(
				http.get<any>(this.serviceWorkerConfigFileIdentifier));

		return this.serviceWorkerConfiguration.dataGroups;
	}

	/**
	 * Clears all existing cached data.
	 *
	 * @async
	 * @memberof CacheService
	 */
	public async clearAllCaches(): Promise<void>
	{
		// If caches are not available or insecure, nothing to clear.
		if (this.cachesAreNullOrInsecure() === true)
		{
			return;
		}

		const currentApiCaches: string[] =
			await caches.keys();

		for (let index = 0; index <= currentApiCaches.length; index++)
		{
			const cacheToClear: Cache =
				await caches.open(currentApiCaches[index]);

			const urlCacheKeys: readonly Request[] =
				await cacheToClear.keys();

			urlCacheKeys.forEach(async(eachRequest: Request) =>
			{
				await cacheToClear.delete(eachRequest);
			});
		}
	}

	/**
	 * Clears all current stale while revalidate subscriptions.
	 *
	 * @memberof CacheService
	 */
	public clearAllSubscriptions(): void
	{
		this.currentSubscriptions
			.forEach((subscription: Subscription) =>
			{
				subscription.unsubscribe();
			});
	}

	/**
	 * Searches cached data for the matching cached response.
	 *
	 * @async
	 * @param {HttpRequest<any>} request
	 * The request to lookup the associated response for.
	 * @returns {Promise<HttpResponse<any> | Void>}
	 * An awaitable promise holding the found cache service value
	 * as an HttpResponse consumable in the application or null
	 * if not found.
	 * @memberof CacheService
	 */
	public async getCachedResponse(
		request: HttpRequest<any>): Promise<HttpResponse<any>>
	{
		const matchingResponse: Response =
			await this.lookupMatchingResponse(request);
		const responseArrayBuffer: ArrayBuffer =
			await matchingResponse?.clone()
				.arrayBuffer();

		if (!AnyHelper.isNull(matchingResponse))
		{
			const response: HttpResponse<any> =
				new HttpResponse({
					status: 200,
					body: JSON.parse(
						new TextDecoder()
							.decode(responseArrayBuffer))
				});

			return response;
		}

		return null;
	}

	/**
	 * Creates an observer on the cached response row for changes that
	 * occur to the ETag value.
	 *
	 * @async
	 * @param {HttpRequest<any>} request
	 * The request to lookup the associated ETag observer for.
	 * @returns {Promise<Observable<string | Void>>}
	 * An awaitable promise holding an observer on the ETag value of the
	 * cache data row.
	 * @memberof CacheService
	 */
	public async getCachedETag(
		request: HttpRequest<any>): Promise<string>
	{
		const matchingResponse: Response =
			await this.lookupMatchingResponse(request);

		if (!AnyHelper.isNull(matchingResponse))
		{
			return matchingResponse.headers.get(
				AppConstants.webApi.eTag);
		}

		return null;
	}

	/**
	 * Verifies that the request is part of the configured cache set of
	 * urls.
	 *
	 * @async
	 * @param {HttpRequest<any>} request
	 * The request to lookup the associated value for.
	 * @returns {boolean}
	 * A truthy defining if this url is part of the PWA service worker cache.
	 * @memberof CacheService
	 */
	public async isCachedRequest(
		request: HttpRequest<any>): Promise<boolean>
	{
		const serviceWorkerDefinitions: IServiceWorkerDataGroup[] =
			await this.getServiceWorkerConfigurationDataGroups();

		const matchingServiceWorkerDefinitions: IServiceWorkerDataGroup[] =
			serviceWorkerDefinitions.filter((
				dataGroup: IServiceWorkerDataGroup) =>
				dataGroup.urls.filter((urlIdentifier: string) =>
					request.url.startsWith(urlIdentifier)).length > 0);

		return matchingServiceWorkerDefinitions.length > 0;
	}

	/**
	 * Verifies that the request is part of a stale while revalidate pattern
	 * specified by the freshness setting in ngsw-config.json.
	 *
	 * @async
	 * @param {HttpRequest<any>} request
	 * The request to lookup the associated freshness value for.
	 * @returns {boolean}
	 * A truthy defining if this url is part of the PWA service worker cache
	 * and part of the freshness set.
	 * @memberof CacheService
	 */
	public async isFreshnessRequest(
		request: HttpRequest<any>): Promise<boolean>
	{
		const serviceWorkerDefinitions: IServiceWorkerDataGroup[] =
			await this.getServiceWorkerConfigurationDataGroups();

		const matchingServiceWorkerDefinitions: IServiceWorkerDataGroup[] =
			serviceWorkerDefinitions.filter((
				dataGroup: IServiceWorkerDataGroup) =>
				dataGroup.urls.filter((urlIdentifier: string) =>
					request.url.startsWith(urlIdentifier)).length > 0);

		return matchingServiceWorkerDefinitions.length > 0
			&& matchingServiceWorkerDefinitions[0].cacheConfig.strategy ===
				this.freshnessStrategyIdentifier;
	}

	/**
	 * Verifies that the request is part of always display from the cache set
	 * specified by the performance setting in ngsw-config.json.
	 *
	 * @async
	 * @param {HttpRequest<any>} request
	 * The request to lookup the associated performance value for.
	 * @returns {boolean}
	 * A truthy defining if this url is part of the PWA service worker cache
	 * and part of the performance set.
	 * @memberof CacheService
	 */
	public async isPerformanceRequest(
		request: HttpRequest<any>): Promise<boolean>
	{
		const serviceWorkerDefinitions: IServiceWorkerDataGroup[] =
			await this.getServiceWorkerConfigurationDataGroups();

		const matchingServiceWorkerDefinitions: IServiceWorkerDataGroup[] =
			serviceWorkerDefinitions.filter((
				dataGroup: IServiceWorkerDataGroup) =>
				dataGroup.urls.filter((urlIdentifier: string) =>
					request.url.startsWith(urlIdentifier)).length > 0);

		return matchingServiceWorkerDefinitions.length > 0
			&& matchingServiceWorkerDefinitions[0].cacheConfig.strategy ===
				this.performanceStrategyIdentifier;
	}

	/**
	 * Gathers and returns the associated url identifier for this request
	 * found in the service worker configuration.
	 *
	 * @async
	 * @param {HttpRequest<any>} request
	 * The request to lookup the associated configuration identifier for.
	 * @param {string} configurationStrategy
	 * If sent this will search matching configuration strategy types. The
	 * default will search in the performance url set.
	 * @returns {string}
	 * The defined service worker configuration URL for the sent request
	 * and strategy type.
	 * @memberof CacheService
	 */
	public async getRequestConfigurationIdentifier(
		request: HttpRequest<any>,
		configurationStrategy: string = this.performanceStrategyIdentifier):
		Promise<string>
	{
		const serviceWorkerDefinitions: IServiceWorkerDataGroup[] =
			await this.getServiceWorkerConfigurationDataGroups();

		const matchingServiceWorkerDefinitions: IServiceWorkerDataGroup[] =
			serviceWorkerDefinitions.filter((
				dataGroup: IServiceWorkerDataGroup) =>
				dataGroup.cacheConfig.strategy
						=== configurationStrategy);

		const matchingUrls: string[] =
			matchingServiceWorkerDefinitions[0].urls.filter(
				(urlIdentifier: string) =>
					request.url.startsWith(urlIdentifier));

		return matchingUrls.length > 0
			? matchingUrls[0]
			: null;
	}

	/**
	 * Clears an existing cached response or partial url responses matching
	 * the sent request value.
	 *
	 * @async
	 * @param {HttpRequest<any>} request
	 * The request to delete the associated response for.
	 * @param {HttpRequest<any>} clearPartials
	 * If sent and true, this will delete any cached responses that match
	 * the url without query parameters. This defaults to false and will
	 * delete only the single cache value if false.
	 * @memberof CacheService
	 */
	public async clearExistingResponse(
		request: HttpRequest<any>,
		clearPartials: boolean = false): Promise<void>
	{
		// If caches are not available or insecure, nothing to clear.
		if (this.cachesAreNullOrInsecure() === true)
		{
			return;
		}

		const currentApiCaches: string[] =
			await caches.keys();

		for (const currentApiCache of currentApiCaches)
		{
			const cacheToSearch: Cache =
				await caches.open(currentApiCache);

			const urlCacheResponses: readonly Response[] =
				await cacheToSearch.matchAll(
					encodeURI(request.url),
					{
						// Ignores query parameters.
						ignoreSearch: clearPartials
					});

			for (const urlCacheResponse of urlCacheResponses)
			{
				this.storageMap.set(
					urlCacheResponse.url,
					AppConstants.empty)
					.subscribe(
						() =>
						{
							// No implementation.
						});

				await cacheToSearch.delete(
					urlCacheResponse.url);
			}
		}
	}

	/**
	 * Clears all existing cached responses starting with the sent
	 * request value.
	 *
	 * @async
	 * @param {string} startsWithUrl
	 * The request url to delete the associated responses for.
	 * @memberof CacheService
	 */
	public async clearExistingStartsWithResponses(
		startsWithUrl: string): Promise<void>
	{
		this.clearExistingResponse(
			new HttpRequest(
				<any>AppConstants.httpRequestTypes.get,
				startsWithUrl),
			true);
	}

	/**
	 * Tests for null caches and for a possible operation is insecure error
	 * found when using firefox with their setting of
	 * Options -> Privacy and Security ->
	 * 'Delete cookies and site data when Firefox is closed'
	 * without allowing an exclusion to this site
	 * or when opened in a private Firefox window.
	 *
	 * @returns {Promise<boolean>}
	 * A value that signifies that the caches are insecure or null and
	 * cannot be used for this session.
	 * @memberof CacheService
	 */
	public cachesAreNullOrInsecure(): boolean
	{
		if (AnyHelper.isNull(navigator.serviceWorker)
			|| AnyHelper.isNull(window.caches))
		{
			return true;
		}

		return false;
	}

	/**
	 * Finds and returns the matching cached response if it exists.
	 * This will be null if not found.
	 *
	 * @async
	 * @param {HttpRequest<any>} request
	 * The request to lookup the associated response for.
	 * @returns {Promise<Response>}
	 * An awaitable promise holding the cached response of the
	 * cache data row
	 * @memberof CacheService
	 */
	private async lookupMatchingResponse(
		request: HttpRequest<any>): Promise<Response>
	{
		return caches.match(encodeURI(request.url));
	}
}