/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'manage-exposure-parties-claim',
	templateUrl: './manage-exposure-parties-claim.component.html'
})

/**
 * A component representing a wizard step for managing parties on an exposure.
 *
 * @export
 * @class ManageExposurePartiesClaimComponent
 * @extends {StatusReasonsDirective}
 */
export class ManageExposurePartiesClaimComponent
	extends StatusReasonsDirective
{
	/**
	 * Creates an instance of an manage exposures parties
	 * claim component.
	 *
	 * @param {ClaimsService} claimsService
	 * The claims service for this component.
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used to lookup insurance modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	public constructor(
		public claimsService: ClaimsService,
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public insuranceService: InsuranceService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService
	)
	{
		super(
			router,
			activityService,
			moduleService,
			entityService,
			insuranceService,
			entityTypeApiService,
			entityInstanceApiService,
			sessionService);
	}

	/**
	 * Gets or sets the exposure index.
	 *
	 * @type {number}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private exposureIndex: number;

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private claimId: number;

	/**
	 * Gets or sets the claim instance data.
	 *
	 * @type {IEntityInstance}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private claim: IEntityInstance;

	/**
	 * Gets or sets the available parties.
	 *
	 * @type {any[]}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private availableParties: any[];

	/**
	 * Gets or sets the selected parties.
	 *
	 * @type {any[]}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private selectedParties: any[];

	/**
	 * Gets or sets the selected parties resource identifiers.
	 *
	 * @type {string[]}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private selectedPartiesResourceIdentifiers: string[];

	/**
	 * Gets or sets the list of adjusting companies.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private adjustingCompanies: IEntityInstance[] = [];

	/**
	 * Sets the adjuster readonly string.
	 *
	 * @type {string}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private readonly adjusterType: string = 'Adjuster';

	/**
	 * Sets the vendor readonly string.
	 *
	 * @type {string}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private readonly vendorType: string = 'Vendor';

	/**
	 * Sets the profile tab layout index readonly number.
	 *
	 * @type {number}
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private readonly profileTabIndex: number = 1;

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		this.exposureIndex =
			this.context.source.activeMenuItem
				.currentData.data.exposureIndex;

		this.claimId =
			this.context.source.activeMenuItem
				.currentData.data.id;

		this.claim =
			await this.entityInstanceApiService.get(this.claimId);

		this.adjustingCompanies =
			await this.claimsService.getClaimAdjusterOrganizations(
				this.claimId);

		this.selectedParties =
			await this.getSelectedParties(
				this.exposureIndex,
				this.claim);

		this.availableParties =
			await this.getAvailableParties(this.claim);

		this.context.source.addOrUpdateStepData(
			{
				availableReasons: this.availableParties,
				selectedReasons: this.selectedParties,
				comments: AppConstants.empty,
				displayCommentBox: false
			});

		this.context.source.addToNext(this.confirmParty.bind(this));
	}

	/**
	 * This will send the confirm party event and navigate to
	 * the claim.
	 *
	 * @async
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	public async confirmParty(): Promise<void>
	{
		await this.saveExposureParties();
		await this.navigateToClaimSummary(
			this.claimId,
			ClaimConstants.claimEntityTypeGroups.claims
		);
	}

	/**
	 * Executes the manage parties process by executing the
	 * ManageExposureParties workflow action to update the claim exposure
	 * with the collected data from the Manage Exposure Parties Wizard.
	 *
	 * @async
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	public async saveExposureParties(): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const queryString: string =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					exposureIndex: this.exposureIndex,
					assignedPartiesResourceIdentifiers:
						ObjectArrayHelper.commaSeparatedPropertyValues(
							currentData.selectedReasons,
							AppConstants.commonProperties.resourceIdentifier)
				});

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		return this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimId,
					ClaimConstants.workflowActions.manageExposureParties,
					null,
					queryString),
				'<strong>Modifying Exposure Parties</strong>',
				'<strong>Exposure Parties Modified</strong>',
				'Exposure party changes have been saved.',
				'Exposure party changes have not been saved.'));
	}

	/**
	 * This will navigate to the claim summary dashboard the claim id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private async navigateToClaimSummary(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full,
								activeTabIndex: this.profileTabIndex
							})
				}
			});
	}

	/**
	 * Gets the available involved parties.
	 *
	 * @async
	 * @param {IEntityInstance} claim
	 * The claim instance.
	 * @returns {Promise<any>}
	 * The list of available involved parties for a claim.
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private async getAvailableParties(
		claim: IEntityInstance): Promise<any[]>
	{
		let availableParties = claim.data.involvedParties
			.filter(
				(party: any) =>
					(party.type === this.vendorType
						|| party.type === this.adjusterType)
						&& !this.selectedPartiesResourceIdentifiers.includes(
							party.resourceIdentifier));

		availableParties =
			await this.formatParties(availableParties);

		return availableParties;
	}

	/**
	 * Gets the selected assigned parties.
	 *
	 * @async
	 * @param {number} exposureIndex
	 * The claim exposure index.
	  * @param {IEntityInstance} claim
	 * The claim instance.
	 * @returns {Promise<any>}
	 * The list of all selected assigned parties for an exposure.
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private async getSelectedParties(
		exposureIndex: number,
		claim: IEntityInstance): Promise<any[]>
	{
		let selectedParties: any[] = [];
		const selectedPartiesResourceIdentifiers: any[] = [];

		const exposureParties =
			claim.data.exposures[exposureIndex].assignedParties;

		for await (const partyIdentifier of exposureParties)
		{
			const partyData = claim.data.involvedParties.find(
				(party: any) =>
					party.resourceIdentifier === partyIdentifier);

			selectedParties.push(partyData);
			selectedPartiesResourceIdentifiers.push(partyIdentifier);
		}

		this.selectedPartiesResourceIdentifiers =
			selectedPartiesResourceIdentifiers;

		selectedParties =
			await this.formatParties(selectedParties);

		return selectedParties;
	}

	/**
	 * Formats parties data.
	 *
	 * @async
	 * @param {any[]} parties
	 * The array of parties.
	 * @returns {Promise<any[]>}
	 * The list of formatted parties.
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private async formatParties(
		parties: any[]): Promise<any[]>
	{
		const formattedParties: any[] = [];

		for await (const party of parties)
		{
			switch (party.type)
			{
				case this.vendorType:
				{
					formattedParties.push(
						await this.formatVendor(party));
					break;
				}
				case this.adjusterType:
				{
					formattedParties.push(
						await this.formatAdjuster(party));
					break;
				}
			}
		}

		return formattedParties;
	}

	/**
	 * Formats vendor data.
	 *
	 * @async
	 * @param {any} vendor
	 * The vendor data.
	 * @returns {Promise<any>}
	 * The formatted vendor data.
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private async formatVendor(
		vendor: any): Promise<any>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.serviceProviderEntityTypeGroups.vendorCompany;

		const vendorInstance: IEntityInstanceDto =
			await this.entityInstanceApiService.get(
				+vendor.vendorOrganizationId);

		const formattedDescription: string =
			this.claimsService.formatClaimOrganizationData(
				vendorInstance.data?.vendorType,
				vendorInstance.data?.name?.legalName,
				AppConstants.empty,
				AppConstants.empty);

		return {
			name: this.vendorType,
			description: formattedDescription,
			resourceIdentifier: vendor.resourceIdentifier
		};
	}

	/**
	 * Formats adjuster data.
	 *
	 * @async
	 * @param {any} adjuster
	 * The adjuster data.
	 * @returns {Promise<any>}
	 * The formatted adjuster data.
	 * @memberof ManageExposurePartiesClaimComponent
	 */
	private async formatAdjuster(
		adjuster: any): Promise<any>
	{
		const adjusterInstance: IEntityInstanceDto =
			this.adjustingCompanies.find(
				(organization: any) =>
					organization.id === +adjuster.adjustingOrganizationId);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.users;

		const userInstance =
			await this.entityInstanceApiService.get(
				+adjuster.adjustingOrganizationUserId);

		const formattedDescription =
			this.claimsService.formatClaimOrganizationData(
				adjusterInstance.data?.adjustingType
					?? adjusterInstance.data?.tpaType,
				adjusterInstance.data?.name?.legalName,
				userInstance.data?.firstName,
				userInstance.data?.lastName
			);

		return {
			name: this.adjusterType,
			description: formattedDescription,
			resourceIdentifier: adjuster.resourceIdentifier
		};
	}
}