<span *ngIf="!loadingOperationDefinition">
	<ng-template [ngIf]="context.source.displayMode === 'View'"
		[ngIfElse]="displayUpdate">
		<div class="ui-g-6 no-padding edit-form-container"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div class="section-margin">
				<app-dynamic-formly
					[dataSet]="context.source.selectedItem"
					[layoutSchema]="context.source.formlyDefinitions.layout"
					(validityChanged)="context.source.validExpandComponentChanged($event)">
				</app-dynamic-formly>
			</div>
		</div>
		<div class="ui-g-6"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div class="parameter-table">
				<app-common-table
					[tableDefinitions]="parametersTableDefinitionsView">
				</app-common-table>
			</div>
			<div class="operation-group-table">
				<app-common-table
					[tableDefinitions]="operationGroupsTableDefinitionsView">
				</app-common-table>
			</div>
		</div>
	</ng-template>

	<ng-template #displayUpdate>
		<div class="ui-g-6 no-padding edit-form-container"
			[ngClass]="{
				'ui-g-12':
					siteLayoutService.displayTabletView
						|| context.source.displayMode === 'Create'
						|| context.source.displayMode === 'Delete'
			}">
			<div class="section-margin">
				<app-dynamic-formly
					[dataSet]="context.source.selectedItem"
					[layoutSchema]="context.source.formlyDefinitions.layout"
					(validityChanged)="context.source.validExpandComponentChanged($event)">
				</app-dynamic-formly>
			</div>
		</div>
		<div class="ui-g-6"
			[ngClass]="{
				'ui-g-12': siteLayoutService.displayTabletView
			}"
			*ngIf="context.source.displayMode === 'Update'">

			<div class="align-center"
				*ngIf="loadingTableDefinitions === true">
				<span class="fa fa-fw fa-spin fa-spinner theme-color panel-title">
				</span>
			</div>
			<span *ngIf="loadingTableDefinitions === false">
				<div class="parameter-table">
					<app-common-table
						[tableDefinitions]="parametersTableDefinitionsUpdate">
					</app-common-table>
				</div>
				<div class="operation-group-table">
					<app-common-table
						[tableDefinitions]="operationGroupsTableDefinitionsUpdate">
					</app-common-table>
				</div>
			</span>
		</div>
	</ng-template>
</span>

<div class="ui-g-12"
	*ngIf="loadingOperationDefinition">
	<span class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</span>
</div>
