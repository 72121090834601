<app-base-page pageTitle="Edit {{entityType?.name}} {{ruleDefinition?.displayName}} Rule Server Validation"
	[pageContext]="pageContext"
	[loading]="loadingDefinitions"
	pageContextOperationGroupName="EntityRules.PageContext"
	operationGroupName="CommonSaveAction.Operations"
	utilityMenuOperationGroupName="">

	<div *ngIf="loadingDefinitions"
		class="text-center ui-g-12">
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>

	<div *ngIf="!loadingDefinitions"
		class="ui-g-12 no-padding">
		<app-dynamic-formly
			[dataSet]="contextData"
			[initialData]="databaseData"
			[layoutSchema]="layoutFields"
			(valuesChanged)="formValuesChange($event)"
			(validityChanged)="formValidityChange($event)">
		</app-dynamic-formly>
	</div>

</app-base-page>