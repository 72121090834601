/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	CommonModule
} from '@angular/common';
import {
	NgModule
} from '@angular/core';
import {
	RouterModule
} from '@angular/router';
import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	ClaimsComponent
} from '@claims/claims.component';
import {
	ClaimsTokenLookup
} from '@claims/claims.token-lookup';
import {
	AddClaimPaymentComponent
} from '@claims/components/wizard-steps/claim/add-claim-payment/add-claim-payment.component';
import {
	AddExposureClaimComponent
} from '@claims/components/wizard-steps/claim/add-exposure-claim/add-exposure-claim.component';
import {
	AdjustReservesClaimComponent
} from '@claims/components/wizard-steps/claim/adjust-reserves-claim/adjust-reserves-claim.component';
import {
	ApproveClaimPaymentClaimComponent
} from '@claims/components/wizard-steps/claim/approve-claim-payment/approve-claim-payment-claim.component';
import {
	CreateClaimComponent
} from '@claims/components/wizard-steps/claim/create-claim/create-claim.component';
import {
	ManageAdditionalPayeesClaimComponent
} from '@claims/components/wizard-steps/claim/manage-additional-payees-claim/manage-additional-payees-claim.component';
import {
	ManageClaimOwnerClaimComponent
} from '@claims/components/wizard-steps/claim/manage-claim-owner/manage-claim-owner-claim.component';
import {
	ManageExposurePartiesClaimComponent
} from '@claims/components/wizard-steps/claim/manage-exposure-parties-claim/manage-exposure-parties-claim.component';
import {
	ManagePartiesConfirmationClaimComponent
} from '@claims/components/wizard-steps/claim/manage-parties-confirmation-claim/manage-parties-confirmation-claim.component';
import {
	RejectClaimPaymentClaimComponent
} from '@claims/components/wizard-steps/claim/reject-claim-payment/reject-claim-payment-claim.component';
import {
	UpdateClaimExposureStatusClaimComponent
} from '@claims/components/wizard-steps/claim/update-claim-exposure-status/update-claim-exposure-status-claim.component';
import {
	UpdateClaimStatusClaimComponent
} from '@claims/components/wizard-steps/claim/update-claim-status/update-claim-status-claim.component';
import {
	VerifyCoverageClaimComponent
} from '@claims/components/wizard-steps/claim/verify-coverage-claim/verify-coverage-claim.component';
import {
	ClaimPaymentAdditionalPayeesComponent
} from '@claims/dynamic-components/claim-payment-additional-payees/claim-payment-additional-payees.component';
import {
	ClaimPaymentPayeeComponent
} from '@claims/dynamic-components/claim-payment-payee/claim-payment-payee.component';
import {
	ExposureOutstandingReservesComponent
} from '@claims/dynamic-components/exposure-outstanding-reserves/exposure-outstanding-reserves.component';
import {
	ExposurePaymentsComponent
} from '@claims/dynamic-components/exposure-payments/exposure-payments.component';
import {
	ExposureRecoveriesComponent
} from '@claims/dynamic-components/exposure-recoveries/exposure-recoveries.component';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntitySearchComponent
} from '@entity/components/entity-search/entity-search.component';
import {
	EntityComponent
} from '@entity/entity.component';
import {
	InsuranceModule
} from '@insurance/insurance.module';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	SharedModule
} from '@shared/shared.module';

/* eslint-disable max-len */

@NgModule({
	declarations: [
		AddClaimPaymentComponent,
		AddExposureClaimComponent,
		AdjustReservesClaimComponent,
		ApproveClaimPaymentClaimComponent,
		ClaimsComponent,
		ClaimPaymentAdditionalPayeesComponent,
		ClaimPaymentPayeeComponent,
		CreateClaimComponent,
		ExposureOutstandingReservesComponent,
		ExposurePaymentsComponent,
		ExposureRecoveriesComponent,
		ManageAdditionalPayeesClaimComponent,
		ManageClaimOwnerClaimComponent,
		ManageExposurePartiesClaimComponent,
		ManagePartiesConfirmationClaimComponent,
		RejectClaimPaymentClaimComponent,
		UpdateClaimExposureStatusClaimComponent,
		UpdateClaimStatusClaimComponent,
		VerifyCoverageClaimComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		InsuranceModule,
		RouterModule.forChild([
			{
				path: 'claim',
				component: ClaimsComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				data:
				{
					roles: [
						AppConstants.securityGroups.claimModuleAccess
					]
				},
				children: [
					{
						path: 'accessDenied',
						component: AccessDeniedComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'dashboard',
						component: GenericDashboardComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'dashboard/:dashboardType',
						component: GenericDashboardComponent,
						data: {
							refreshComponent: true
						}
					},
					{
						path: 'display',
						children: [
							{
								path: ':displayComponent/view',
								component: GenericBasePageComponent,
								data: {
									refreshComponent: true
								}
							},
							{
								path: ':displayComponent/edit',
								component: GenericBasePageComponent,
								canActivate: [
									AppAuthGuard
								],
								data:
								{
									roles: [
										AppConstants.securityGroups.claimModuleAccess
									],
									refreshComponent: true
								}
							}
						]
					},
					{
						path: 'entities',
						component: EntityComponent,
						children: [
							{
								path: ':entityType/view/:id',
								component: EntityInstanceComponent,
								data: {
									refreshComponent: true
								}
							},
							{
								path: ':entityType/edit/:id',
								component: EntityInstanceComponent,
								data: {
									refreshComponent: true
								}
							},
							{
								path: 'display',
								children: [
									{
										path: ':displayComponent/create',
										component: GenericBasePageComponent,
										data: {
											refreshComponent: true
										}
									}
								]
							},
							{
								path: '',
								component: EntitySearchComponent,
								data: {
									refreshComponent: true
								}
							}
						]
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					{
						path: '**',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					}
				]
			}])
	],
	exports: [
		AddClaimPaymentComponent,
		AddExposureClaimComponent,
		AdjustReservesClaimComponent,
		ApproveClaimPaymentClaimComponent,
		ClaimPaymentAdditionalPayeesComponent,
		ClaimPaymentPayeeComponent,
		CreateClaimComponent,
		ExposureOutstandingReservesComponent,
		ExposurePaymentsComponent,
		ExposureRecoveriesComponent,
		ManageAdditionalPayeesClaimComponent,
		ManageClaimOwnerClaimComponent,
		ManageExposurePartiesClaimComponent,
		ManagePartiesConfirmationClaimComponent,
		RejectClaimPaymentClaimComponent,
		UpdateClaimExposureStatusClaimComponent,
		UpdateClaimStatusClaimComponent,
		VerifyCoverageClaimComponent
	],
	providers: [
		AddClaimPaymentComponent,
		AddExposureClaimComponent,
		AdjustReservesClaimComponent,
		ApproveClaimPaymentClaimComponent,
		ClaimPaymentAdditionalPayeesComponent,
		ClaimPaymentPayeeComponent,
		ClaimsService,
		{
			provide: ClaimsTokenLookup.tokens.ClaimsService,
			useClass: ClaimsService
		},
		CreateClaimComponent,
		ExposureOutstandingReservesComponent,
		ExposurePaymentsComponent,
		ExposureRecoveriesComponent,
		ManageAdditionalPayeesClaimComponent,
		ManageClaimOwnerClaimComponent,
		ManageExposurePartiesClaimComponent,
		ManagePartiesConfirmationClaimComponent,
		RejectClaimPaymentClaimComponent,
		UpdateClaimExposureStatusClaimComponent,
		UpdateClaimStatusClaimComponent,
		VerifyCoverageClaimComponent
	]
})

/**
 * A module used to hold claims route specific logic and display resources.
 *
 * @export
 * @class ClaimsModule
 */
export class ClaimsModule
{
}