/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'add-exposure-claim',
	templateUrl: './add-exposure-claim.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard step for add exposure.
 *
 * @export
 * @class AddExposureClaimComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class AddExposureClaimComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the add exposure component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation..
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof AddExposureClaimComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof AddExposureClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof AddExposureClaimComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the exposure details markdown.
	 *
	 * @type {Promise<string>}
	 * @memberof AddExposureClaimComponent
	 */
	private exposureDetails: Promise<string>;

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof AddExposureClaimComponent
	 */
	private claimId: number;

	/**
	 * Gets or sets the claim instance data.
	 *
	 * @type {IEntityInstance}
	 * @memberof AddExposureClaimComponent
	 */
	private claim: IEntityInstance;

	/**
	 * Gets or sets the claim policy instance data.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof AddExposureClaimComponent
	 */
	private claimPolicy: IEntityInstance[];

	/**
	 * Gets or sets the adjuster instance data.
	 *
	 * @type {IEntityInstance}
	 * @memberof AddExposureClaimComponent
	 */
	private adjuster: IEntityInstance;

	/**
	 * Gets or sets the claimant object data.
	 *
	 * @type {any}
	 * @memberof AddExposureClaimComponent
	 */
	private claimant: any;

	/**
	 * Gets or sets the asset object data.
	 *
	 * @type {any}
	 * @memberof AddExposureClaimComponent
	 */
	private asset: any;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof AddExposureClaimComponent
	 */
	private currentData: any;

	/**
	 * Implements the on initialization interface.
	 *
	 * @memberof AddExposureClaimComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.claimId = this.currentData.data.id;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		this.claim =
			await this.entityInstanceApiService.get(this.claimId);

		this.exposureDetails = this.getExposureDetails();

		this.context.source.addToNext(this.create.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof AddExposureClaimComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the claim create event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof AddExposureClaimComponent
	 */
	public async create(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const parameterString =
			this.getActionParameters(
				this.currentData);

		await this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimId,
					ClaimConstants.workflowActions.addExposure,
					null,
					parameterString),
				'<strong>Adding Exposure</strong>',
				'<strong>Exposure Added</strong>',
				'Exposure information has been added.',
				'Exposure information has not been added.'));

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const url: string = this.entityInstanceApiService.getBaseUrl()
			+ `/${this.claimId}`;

		await this.entityInstanceApiService.resetAssociatedCache(url);

		await this.navigateToClaimSummary(
			this.claimId,
			ClaimConstants.claimEntityTypeGroups.claims);
	}

	/**
	 * This get the exposure summary information to display.
	 *
	 * @async
	 * @memberof AddExposureClaimComponent
	 */
	public async getExposureDetails(): Promise<string>
	{
		let details: string;

		await this.getAdjusterData();
		await this.getClaimantData();

		if (!AnyHelper.isNullOrEmpty(this.currentData.data.exposure.assetId))
		{
			await this.getAssetData();

			details =
				'<strong>Exposure Details</strong><br/>'
					+ `Claimant: ${this.claimant.name.firstName}`
						+ ` ${this.claimant.name.lastName}<br/>`
					+ `Adjuster: ${this.adjuster.data.firstName}`
						+ ` ${this.adjuster.data.lastName}<br/>`
					+ 'Asset: '
						+ `${this.asset.addresses[0].address}`
						+ ` ${this.asset.addresses[0].city}`
						+ `, ${this.asset.addresses[0].state}`
						+ ` ${this.asset.addresses[0].postalCode}<br/>`
					+ 'Coverage: '
						+`${this.currentData.data.exposure.coverage.name}`;
		}
		else
		{
			details =
				'<strong>Exposure Details</strong><br/>'
					+ `Claimant: ${this.claimant.name.firstName}`
						+ ` ${this.claimant.name.lastName}<br/>`
					+ `Adjuster: ${this.adjuster.data.firstName}`
						+ ` ${this.adjuster.data.lastName}<br/>`
					+ 'Coverage: '
						+ `${this.currentData.data.exposure.coverage.name}`;
		}

		return details;
	}

	/**
	 * This get the asset data to display in the
	 * exposure summary detail section.
	 *
	 * @async
	 * @memberof AddExposureClaimComponent
	 */
	private async getAssetData()
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		this.claimPolicy =
			await this.entityInstanceApiService.getChildren(
				this.claimId,
				AppConstants.empty,
				AppConstants.empty,
				null,
				1,
				ClaimConstants.claimEntityTypeGroups.claimPolicies);

		this.asset =
			this.claimPolicy[0].data.assets.find(
				(asset: any) =>
					asset.resourceIdentifier ===
						this.currentData.data.exposure.assetId);
	}

	/**
	 * This will set the parameters needed for the add exposure
	 * workflow action.
	 *
	 * @param {any} currentData
	 * The current wizard data.
	 * @returns {string}
	 * The request parameters.
	 * @memberof AddExposureClaimComponent
	 */
	private getActionParameters(
		currentData: any): string
	{
		const exposure: any = currentData.data.exposure;

		const parameterString: string =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					level: exposure.level,
					assetId: exposure?.assetId,
					coverage: exposure.coverage.name,
					description: exposure?.description,
					claimantIdentifier: exposure.claimantIdentifier,
					assignedPartiesIdentifiers: exposure
						.assignedParties.toString(),
					adjustingAndOther:
						currentData.data.adjustingAndOther,
					defenseAndCostContainment:
						currentData.data.defenseAndCostContainment,
					loss: currentData.data.loss
				});

		return parameterString;
	}

	/**
	 * This get the adjuster data to display in the
	 * exposure summary detail section.
	 *
	 * @async
	 * @memberof AddExposureClaimComponent
	 */
	private async getAdjusterData()
	{
		const adjusterData =
			this.claim.data.involvedParties.find(
				(party: any) =>
					party.resourceIdentifier ===
						this.currentData.data.exposure.assignedParties[0]);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.users;

		this.adjuster =
			await this.entityInstanceApiService.get(
				adjusterData.adjustingOrganizationUserId);
	}

	/**
	 * This get the claimant data to display in the
	 * exposure summary detail section.
	 *
	 * @async
	 * @memberof AddExposureClaimComponent
	 */
	private async getClaimantData()
	{
		this.claimant =
			this.claim.data.involvedParties.find(
				(party: any) =>
					party.resourceIdentifier ===
						this.currentData.data.exposure.claimantIdentifier);
	}

	/**
	 * This will navigate to the claim summary dashboard the claim id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof AddExposureClaimComponent
	 */
	private async navigateToClaimSummary(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof AddExposureClaimComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					type: FormlyConstants.customControls.customDashboard,
					props: {
						dashboardDisplayComponentInstanceName:
							'ClaimAddExposureDashboard'
					}
				},
				<FormlyFieldConfig>
				{
					type: FormlyConstants.customControls.customTextDisplay,
					props: {
						centerText: true,
						useMarkDown: true,
						usePanelDisplay: false,
						content: await this.exposureDetails
					}
				}
			];
	}
}