/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';

/* eslint-enable max-len */

@Component({
	selector: 'address-list',
	templateUrl: './address-list.component.html',
	styleUrls: [
		'./address-list.component.scss'
	]
})

/**
 * A component representing a list view of addresses.
 *
 * @export
 * @class AddressListComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class AddressListComponent
implements IDynamicComponent<Component, any>
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof AddressListComponent
	 */
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets the address line one of the sent address object.
	 * At this time this is hardcoded as the sample used does not gather
	 * this data. AgencyV2.
	 *
	 * @param {any} _location
	 * The location parsed to gather address data from.
	 * @returns {string}
	 * The line one address formatted for mailing.
	 * @memberof AddressListComponent
	 */
	public getAddressLineOne(
		_location: any): string
	{
		return '555 Test Address';
	}

	/**
	 * Gets the address line two of the sent address object.
	 *
	 * @param {any} _location
	 * The location parsed to gather address data from.
	 * @returns {string}
	 * The line two address formatted for mailing.
	 * @memberof AddressListComponent
	 */
	public getAddressLineTwo(
		location: any): string
	{
		return `${location.city}, ${location.state} ${location.zip}`;
	}

	/**
	 * Handles the copy address click.
	 * Creates a pastable copy on the clipboard of this address ready
	 * for use as a mailing label.
	 *
	 * @async
	 * @param {any} location
	 * The location parsed to gather address data from an send to the
	 * clipboard.
	 * @memberof AddressListComponent
	 */
	public async copyAddressToClipboard(
		location: any): Promise<void>
	{
		await StringHelper.copyToClipboard(
			`${this.getAddressLineOne(location)}\r\n`
				+ `${this.getAddressLineTwo(location)}`);
	}
}