/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	SafeHtml
} from '@angular/platform-browser';
import {
	IActionResponseDto
} from '@api/interfaces/workflow/action-response.dto.interface';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ErrorHelper
} from '@shared/helpers/error.helper';
import {
	ClientMessageDetail
} from '@shared/implementations/application-data/client-message-detail';
import {
	IApplicationMessage
} from '@shared/interfaces/application-messages/application-message.interface';
import {
	ILogEntry
} from '@shared/interfaces/logs/log-entry.interface';
import {
	MenuItem
} from 'primeng/api';
import {
	Subject
} from 'rxjs';

/**
 * A class representing a client side message for use in message
 * based displays.
 *
 * @export
 * @class ClientMessage
 */
export class ClientMessage
{
	/**
	 * Gets or sets the id for this client message. This is
	 * generated at run time to allow for distinct gets.
	 *
	 * @type {number}
	 * @memberof ClientMessage
	 */
	public id: number;

	/**
	 * Gets or sets the content for this client message. This can
	 * be an html string.
	 *
	 * @type {string}
	 * @memberof ClientMessage
	 */
	public content: string;

	/**
	 * Gets or sets the content for this client message. This value
	 * is used when displaying the content value using innerHtml.
	 *
	 * @type {SafeHtml}
	 * @memberof ClientMessage
	 */
	public safeContent: SafeHtml;

	/**
	 * Gets or sets additional data for this client message.
	 *
	 * @type {any}
	 * @memberof ClientMessage
	 */
	public data: any;

	/**
	 * Gets or sets whether or not this client message should remain
	 * displayed.
	 *
	 * @type {boolean}
	 * @memberof ClientMessage
	 */
	public display: boolean;

	/**
	 * Gets or sets the status for this client message as defined in
	 * AppConstants.activityStatus.
	 *
	 * @type {string}
	 * @memberof ClientMessage
	 */
	public status: string;

	/**
	 * Gets or sets the title for this client message. This can
	 * be an html string.
	 *
	 * @type {string}
	 * @memberof ClientMessage
	 */
	public title: string;

	/**
	 * Gets or sets the title for this client message. This value
	 * is used when displaying the content value using innerHtml.
	 *
	 * @type {SafeHtml}
	 * @memberof ClientMessage
	 */
	public safeTitle: SafeHtml;

	/**
	 * Gets or sets the display changed subject which is used to watch
	 * for and debounce fade events. This can be subscribed to and
	 * sent change events in components displaying this message.
	 *
	 * @type {Subject<boolean>}
	 * @memberof ClientMessage
	 */
	public displayChanged: Subject<boolean> = new Subject<boolean>();

	/**
	 * Gets or sets the truthy used to define if a banner message is expandable
	 * due to additional extended details.
	 *
	 * @type {boolean}
	 * @memberof ClientMessage
	 */
	public extendedDetailsExist: boolean = false;

	/**
	 * Gets or sets the set of extended details to display for this client
	 * message.
	 *
	 * @type {ClientMessageDetail[]}
	 * @memberof ClientMessage
	 */
	public extendedDetails: ClientMessageDetail[] = [];

	/**
	 * Gets or sets the set of actions to associated with this client message.
	 *
	 * @type {MenuItem[]}
	 * @memberof ClientMessage
	 */
	public actions: MenuItem[] = [];

	/**
	 * Sets the extended details for this client message.
	 * Parses any data and outputs this into a list of ClientMessageDetails
	 * allowing for a quick breakdown of additional data.
	 * At this time, this only handles any error type and an IActionResponse.
	 *
	 * @async
	 * @memberof ClientMessage
	 */
	public async setExtendedDetails(): Promise<void>
	{
		const extendedDetails: ClientMessageDetail[] = [];

		if (!AnyHelper.isNullOrEmpty(this.data))
		{
			// IActionResponse from execute workflow
			if (!AnyHelper.isNullOrEmpty(this.data.workflowActionName))
			{
				const actionResponse: IActionResponseDto =
					<IActionResponseDto>this.data;

				const orderedResponses: IApplicationMessage[] =
					[
						...actionResponse.preRequisiteResponses,
						...actionResponse.messages,
						...actionResponse.postRequisiteResponses
					];

				orderedResponses.forEach(
					(response: IApplicationMessage) =>
					{
						extendedDetails.push(
							new ClientMessageDetail(
								response.workflowActionName
									|| response.description,
								response.messages));
					});
			}
			else if (!AnyHelper.isNullOrEmpty(this.data)
				&& !AnyHelper.isNullOrEmpty(this.data.message))
			{
				// Error
				const errorLogEntry: ILogEntry =
					await ErrorHelper.getGenericErrorLogEntry(this.data);

				const errorMessage = `${errorLogEntry.message}`;
				extendedDetails.push(
					new ClientMessageDetail(
						AnyHelper.isNullOrEmpty(errorLogEntry.details)
							? errorMessage
							: `${errorLogEntry.message}<br/>`
								+ `&nbsp;&nbsp;&nbsp;${errorLogEntry.details}`,
						[]));
			}
		}

		this.extendedDetailsExist = extendedDetails.length > 0;
		this.extendedDetails = extendedDetails;
	}
}