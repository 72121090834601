<div class="ui-g-12 content-section">
	<ng-template
		[ngIf]="loading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="payment-details-box"
			[ngClass]="{'payment-details-box-tablet-view': siteLayoutService.displayTabletView}">

			<div class="payment-details-header ui-g-12 no-padding">
				<div class="ui-g-4 payment-details-logo">
					<img [src]="logoUrl"
						[alt]="processor?.name" />
				</div>
				<div class="ui-g-8 text-right">
					<span class="payment-details-tagline">
						{{processor?.tagline}}
					</span>
				</div>
			</div>

			<div class="payment-details-title ui-g-12 text-center">
				{{title}}
			</div>

			<ng-template
				[ngIf]="currentData.paymentResult == null"
				[ngIfElse]="Transaction">
				<div class="ui-g-12 payment-details-section payment-details-disclaimer">
					<p>
						{{disclaimer}}
					</p>
				</div>
			</ng-template>

			<ng-template #Transaction>
				<div class="ui-g-12 payment-details-section">
					<div>
						<div class="ui-g-8">
							Payment Date
						</div>
						<div class="ui-g-4 text-right">
							{{currentData.paymentResult.transactionDate | dateTimeFromIso | dateTimeToLocal | dateTimeToFormat:'D h:mm a ZZZZ'}}
						</div>
					</div>
					<div>
						<div class="ui-g-8">
							Transaction Number
						</div>
						<div class="ui-g-4 text-right">
							{{currentData.paymentResult.transactionId}}
						</div>
					</div>
				</div>
			</ng-template>

			<div class="ui-g-12 payment-details-section">
				<ng-container
					*ngIf="paymentItems.length > 1">
					<div class="ui-g-12" *ngFor="let item of paymentItems; let itemIndex=index">
						<div class="ui-g-8">
							<strong>
								{{item.description}}<sup *ngIf="itemIndex > 0 && paymentResult == null">{{itemIndex}}</sup>
							</strong>
						</div>
						<div class="ui-g-4 text-right">
							<strong>
								{{item.amount | currency}}
							</strong>
						</div>
					</div>
					<div class="ui-g-12">
						<hr>
					</div>
				</ng-container>
				<div>
					<div class="ui-g-8">
						<strong>
							Total Payment Amount
						</strong>
					</div>
					<div class="ui-g-4 text-right">
						<strong>
							{{totalPaymentAmount | currency}}
						</strong>
					</div>
				</div>
			</div>

			<div class="ui-g-12 payment-details-section">
				<div class="input-label">
					<strong>Payment Method</strong>
				</div>
				<div>
					<div class="payment-details-method">
						<i [ngClass]="getPaymentMethodClass()">
						</i>
						{{paymentMethodDescription}} ending in {{currentData.paymentMethod.accountNumber}}
					</div>
				</div>
			</div>

			<ng-template
				[ngIf]="currentData.paymentResult == null"
				[ngIfElse]="Notice">
				<div class="ui-g-12 payment-details-section payment-details-disclaimer-small">
					<p *ngFor="let feeDisclaimer of feeDisclaimers; let itemIndex=index">
						<sup>{{itemIndex + 1}}</sup>{{feeDisclaimer}}
					</p>
				</div>
			</ng-template>

			<ng-template #Notice>
				<div class="ui-g-12 payment-details-section payment-details-disclaimer-small"
					*ngIf="currentData.insured != null">
					<p>
						A copy of the receipt is available in your files drawer for download and has been sent to the following email: {{currentData.insured.emails[0].address}}.
					</p>
				</div>
			</ng-template>
		</div>
	</ng-template>
</div>