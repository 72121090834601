/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewInit,
	Component,
	ComponentRef,
	Input,
	OnChanges,
	SimpleChanges,
	ViewContainerRef
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DynamicComponentLookup
} from '@shared/dynamic-components/dynamic-component.lookup';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';

/* eslint-enable max-len */

@Component({
	selector: 'app-dynamic-component',
	templateUrl: './dynamic.component.html'
})

/**
 * A component representing an instance of the dynamic component.
 *
 * @export
 * @implements {OnChanges}
 * @implements {AfterViewInit}
 * @class DynamicComponent
 */
export class DynamicComponent
implements OnChanges, AfterViewInit
{
	/**
	 * Initializes a new instance of the DynamicComponent class.
	 *
	 * @param {ViewContainerRef} viewContainerReference
	 * The injected view container reference.
	 * @memberof DynamicComponent
	 */
	public constructor(
		public readonly viewContainerReference: ViewContainerRef)
	{
	}

	/**
	 * Gets or sets the dynamic component context of the current dynamically
	 * displayed component.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof DynamicComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the dynamic component to be displayed at runtime.
	 *
	 * @type {any}
	 * @memberof DynamicComponent
	 */
	@Input() public displayComponent: any;

	/**
	 * Gets or sets the loading value for external inputs.
	 *
	 * @type {any}
	 * @memberof DynamicComponent
	 */
	@Input() public loading: boolean = true;

	/**
	 * Gets or sets the overload that will display a loader in this component.
	 *
	 * @type {any}
	 * @memberof DynamicComponent
	 */
	@Input() public showLoader: boolean = false;

	/**
	 * Gets or sets the reference to the component that will be displayed
	 * in this dynamic component.
	 *
	 * @type {ComponentRef<any>}
	 * @memberof DynamicComponent
	 */
	public componentReference: ComponentRef<any>;

	/**
	 * Implements the after view initialization interface.
	 * This method is used to load and set context data for the selected
	 * display component into the selected dynamic element.
	 *
	 * @memberof DynamicComponent
	 */
	public async ngAfterViewInit(): Promise<void>
	{
		if (AnyHelper.isNull(this.displayComponent))
		{
			this.viewContainerReference?.clear();
			this.componentReference = null;

			return;
		}

		setTimeout(
			async () =>
			{
				if (typeof(this.displayComponent) ===
					AppConstants.variableTypes.string)
				{
					this.componentReference =
						this.viewContainerReference.createComponent(
							await DynamicComponentLookup
								.components[this.displayComponent]());
				}
				else
				{
					this.componentReference =
						this.viewContainerReference.createComponent(
							this.displayComponent);
				}

				(<IDynamicComponent<Component, any>>
					this.componentReference.instance).context = this.context;

				this.componentReference.changeDetectorRef.detectChanges();
			});
	}

	/* *
	 * Implements the on changes interface.
	 * This method is used to clear any currently displayed component and
	 * refresh this view when the component display changes at runtime.
	 *
	 * @memberof DynamicComponent
	 */
	public ngOnChanges(
		changes: SimpleChanges): void
	{
		if (!AnyHelper.isNull(changes.displayComponent?.previousValue)
			&& changes.displayComponent?.previousValue
				!== changes.displayComponent?.currentValue)
		{
			this.viewContainerReference?.clear();
			this.ngAfterViewInit();
		}
	}
}