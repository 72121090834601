<div class="ui-g-12 no-padding text-center work-item-container"
	[ngClass]="{
		'no-margin': (displayMode === displayModes.list)
	}">

	<ng-template #BackNavigation
		[ngIf]="isDisplayModeAvailable() === false
			|| displayMode !== displayModes.list">
		<div class="ui-g-12 no-padding text-left nested-display-header">
			<span
				class="text-link theme-color nested-display-back-container"
				(click)="displayMode = displayModes.list">
				<i class="fa fa-arrow-left nested-display-back-icon">
				</i>
				<span class="nested-display-back">
					Back
				</span>
			</span>
		</div>
	</ng-template>

	<ng-template #Invalid
		[ngIf]="isValid() === false
			|| isDisplayModeAvailable() === false"
		[ngIfElse]="Content">
		<div class="ui-g-12 text-center">
			This view is not currently available.
		</div>
	</ng-template>

	<ng-template #Content>
		<div *ngIf="loading">
			<div class="ui-g-12 text-center">
				<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
				</span>
			</div>
		</div>
		<div *ngIf="!loading">
			<ng-template #List [ngIf]="displayMode === displayModes.list">
				<app-product-setting-list [context]="context" [securityDefinitions]="securityDefinitions"
					(changeDisplayMode)="changeDisplayMode($event)" (changeSelectedItem)="changeSelectedItem($event)">
				</app-product-setting-list>
			</ng-template>

			<ng-template #Action [ngIf]="displayMode !== displayModes.list">
				<app-product-setting-action [context]="context" (changeDisplayMode)="changeDisplayMode($event)">
				</app-product-setting-action>
			</ng-template>
		</div>
	</ng-template>
</div>