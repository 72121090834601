/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	MenuItem
} from 'primeng/api';

@Component({
	selector: 'app-base-page-tab-menu',
	templateUrl: './base-page-tab-menu.component.html'
})

/**
 * A component representing an instance of a base page tab menu.
 *
 * @export
 * @class BasePageTabMenuComponent
 */
export class BasePageTabMenuComponent
{
	/**
	 * Gets or sets the tab items specifying different
	 * data display tabs.
	 *
	 * @type {MenuItem[]}
	 * @memberof BasePageTabMenuComponent
	 */
	@Input() public tabItems: MenuItem[] = [];

	/**
	 * Gets or sets the section items specifying different
	 * data sections.
	 *
	 * @type {MenuItem[]}
	 * @memberof BasePageTabMenuComponent
	 */
	@Input() public sectionItems: MenuItem[] = [];

	/**
	 * Gets or sets the current selected tab item.
	 *
	 * @type {MenuItem}
	 * @memberof BasePageTabMenuComponent
	 */
	@Input() public activeTabItem: MenuItem;

	/**
	 * Gets or sets the current selected section item.
	 *
	 * @type {MenuItem}
	 * @memberof BasePageTabMenuComponent
	 */
	@Input() public activeSectionItem: MenuItem;

	/**
	 * Gets or sets the event emitter that will emit that a tab has been
	 * selected.
	 *
	 * @type {EventEmitter<any>}
	 * @memberof BasePageTabMenuComponent
	 */
	@Output() public tabSelectedEvent: EventEmitter<any> =
		new EventEmitter<any>();

	/**
	 * Handles the tab select observed event.
	 * This event handler will emit the tab selected event to any listening
	 * components.
	 *
	 * @param {any} event
	 * The tab selected event sent from the observed action.
	 * @memberof BasePageTabMenuComponent
	 */
	public tabSelected(
		event: any): void
	{
		this.tabSelectedEvent.emit(event);
	}

	/**
	 * Handles a section menu item click event.
	 * This will dispatch a site layout change to insure we check the active
	 * tab item following a click regardless of a scroll event.
	 *
	 * @memberof BasePageTabMenuComponent
	 */
	public sectionMenuItemClicked(): void
	{
		EventHelper.dispatchSiteLayoutChangedEvent();
	}
}