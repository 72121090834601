/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	EntityInstanceRuleViolationOverrideApiService
} from '@api/services/entities/entity-instance-rule-violation-override.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	RuleWorkflowDisplayDirective
} from '@shared/directives/rule-workflow-display.directive';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstanceRuleViolationOverride
} from '@shared/interfaces/entities/entity-instance-rule-violation-override.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	RuleService
} from '@shared/services/rule.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-rule-override-details',
	templateUrl: './rule-override-details.component.html',
	styleUrls: [
		'./rule-override-details.component.scss'
	]
})

/**
 * A component representing a context level rule override display component.
 *
 * @export
 * @class RuleOverrideDetailsComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @implements {IDynamicComponent<Component, any>}
 */
export class RuleOverrideDetailsComponent
	extends RuleWorkflowDisplayDirective
	implements OnInit, AfterViewInit, OnDestroy,
		IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the rule override component.
	 *
	 * @param {RuleService} ruleService
	 * The rule service used in this component.
	 * @param {ActivityService} activityService
	 * The activity service used in this component.
	 * @param {EntityInstanceRuleViolationOverrideApiService}
	 * entityInstanceRuleViolationOverrideApiService
	 * The entity instance rule violation override service used in this
	 * component.
	 * @memberof RuleOverrideDetailsComponent
	 */
	public constructor(
		public ruleService: RuleService,
		public activityService: ActivityService,
		public entityInstanceRuleViolationOverrideApiService:
			EntityInstanceRuleViolationOverrideApiService)
	{
		super();
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof RuleOverrideDetailsComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the selected item to be displayed in this override
	 * details component.
	 *
	 * @type {IEntityInstanceRuleViolationOverride}
	 * @memberof RuleOverrideDetailsComponent
	 */
	@Input() public selectedItem: IEntityInstanceRuleViolationOverride;

	/**
	 * Gets or sets the event emitted used to notify listening components of
	 * a request to change the display mode.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof RuleOverrideDetailsComponent
	 */
	@Output() public changeDisplayMode: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets or sets the formly layout defining the displayed override form.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof RuleOverrideDetailsComponent
	 */
	public overrideFormlyLayout: FormlyFieldConfig[] = [];

	/**
	 * Handles the on initialization event.
	 * This will load the data needed to display an overridable rule item and
	 * set the value for our archived override workflow action state.
	 *
	 * @memberof RuleOverrideDetailsComponent
	 */
	public ngOnInit(): void
	{
		this.overrideFormlyLayout =
			this.getRuleOverrideFormlyLayout();
	}

	/**
	 * Handles the cancel action by restting to the last saved value set
	 * and displaying the rules list.
	 *
	 * @memberof RuleOverrideDetailsComponent
	 */
	public cancel(): void
	{
		this.changeDisplayMode.emit(AppConstants.displayMode.secondaryList);
	}

	/**
	 * Initializes the layout for the override details formly layout.
	 * This will initialize each of the custom text areas content values.
	 *
	 * @returns {FormlyFieldConfig[]}
	 * The formly layout for the override details.
	 * @memberof RuleOverrideDetailsComponent
	 */
	private getRuleOverrideFormlyLayout(): FormlyFieldConfig[]
	{
		const overrideFormlyLayout: FormlyFieldConfig[] =
			<FormlyFieldConfig[]>
			[
				{
					key: 'ruleDefinitionName',
					type: FormlyConstants.customControls.customTextDisplay,
					props: {
						useMarkdown: true,
						usePanelDisplay: false,
						centerText: false,
						content: AppConstants.empty
					},
					expressions: {
						'props.content':
							(field: FormlyFieldConfig) =>
								this.getContentValue(
									field.model.ruleDefinitionName,
									'Rule Name',
									true,
									false,
									true)
					}
				},
				{
					key: 'workflowActionDefinitionName',
					type: FormlyConstants.customControls.customTextDisplay,
					props: {
						useMarkdown: true,
						usePanelDisplay: false,
						centerText: false,
						content: AppConstants.empty
					},
					expressions: {
						'props.content':
							(field: FormlyFieldConfig) =>
								this.getContentValue(
									field.model.workflowActionDefinitionName,
									'Action Name')
					}
				},
				{
					key: 'overrideDateIsoString',
					type: FormlyConstants.customControls.customTextDisplay,
					props: {
						useMarkdown: true,
						usePanelDisplay: false,
						centerText: false,
						content: AppConstants.empty
					},
					expressions: {
						'props.content':
							(field: FormlyFieldConfig) =>
								this.getContentValue(
									StringHelper.format(
										field.model.overrideDateIsoString,
										AppConstants.formatTypes.longDate),
									'Overridden Date')
					}
				},
				{
					key: 'overrideUserDisplayName',
					type: FormlyConstants.customControls.customTextDisplay,
					props: {
						useMarkdown: true,
						usePanelDisplay: false,
						centerText: false,
						content: AppConstants.empty
					},
					expressions: {
						'props.content':
							(field: FormlyFieldConfig) =>

								this.getContentValue(
									field.model.overrideUserDisplayName,
									'Overridden By')
					}
				},
				{
					key: 'reason',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Reason',
						rows: 5,
						gridColumns: 12,
						disabled: true
					}
				}
			];

		return overrideFormlyLayout;
	}

	/**
	 * Gets the content display for a custom text area based on the sent
	 * parameters.
	 *
	 * @param {string} value
	 * The value to display.
	 * @param {string} title
	 * The title to display.
	 * @param {boolean} [boldEntireSection]
	 * A value indicating whether the entire section should be bold or only the
	 * label.
	 * @param {boolean} [subtitleValueDisplay]
	 * A value indicating whether the subtitle value display should be shown.
	 * This class will be used to style the value as secondary bold text.
	 * @param {boolean} [noVerticalPadding]
	 * A value indicating whether the vertical padding should be removed.
	 * @returns {string}
	 * The content value for the custom text area.
	 * @memberof RuleOverrideDetailsComponent
	 */
	private getContentValue(
		value: string,
		title: string,
		boldEntireSection: boolean = false,
		subtitleValueDisplay: boolean = false,
		noVerticalPadding: boolean = false): string
	{
		return '<div class="text-left ui-g-12 '
			+ (noVerticalPadding === true
				? 'no-padding'
				: 'no-horizontal-padding')
			+ '">'
			+ `<b>${title}: `
			+ (boldEntireSection === true
				? AppConstants.empty
				: '</b>')
			+ '<span'
			+ (subtitleValueDisplay === true
				? ' class="subtitle-value-display">'
				: '>')
			+ value
			+ '</span>'
			+ (boldEntireSection === true
				? '</b>'
				: AppConstants.empty)
			+ '</div>';
	}
}