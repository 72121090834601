/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	PaymentBatchConstants
} from '@shared/constants/payment-batch-constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'payment-batch-create',
	templateUrl: './payment-batch-approve.component.html'
})

/**
 * A component representing a wizard step for approve a payment batch.
 *
 * @export
 * @class PaymentBatchApproveComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class PaymentBatchApproveComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the approve payment batch component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @memberof PaymentBatchApproveComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof PaymentBatchApproveComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof PaymentBatchApproveComponent
	 */
	public staticFormlyLayout: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the entity type to be created.
	 *
	 * @type {IEntityType}
	 * @memberof PaymentBatchApproveComponent
	 */
	public entityApprovalType: IEntityType;

	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof PaymentBatchApproveComponent
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof PaymentBatchApproveComponent
	 */
	private currentData: any;

	/**
	 * Gets or sets the payment batch id.
	 *
	 * @type {number}
	 * @memberof PaymentBatchApproveComponent
	 */
	private paymentBatchId: number;

	/**
	 * Gets or sets the workflow action being executed against the current
	 * wizard steps entity instance.
	 *
	 * @type {string}
	 * @memberof PaymentBatchApproveComponent
	 */
	private readonly workflowActionName: string = 'PaymentBatchApprove';

	/**
	 * Gets or sets the message that will be set if the valid wizard step check
	 * returns false.
	 *
	 * @type {string}
	 * @memberof PaymentBatchApproveComponent
	 */
	private readonly invalidWizardStepMessage: string =
		'This payment batch has already been approved or rejected.';

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof PaymentBatchApproveComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.paymentBatchId = this.currentData.data.entityId;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.currentData.data.entityTypeGroup;
		const paymentBatch: IEntityInstance =
			await this.entityInstanceApiService.get(
				this.paymentBatchId);

		this.context.source.addOrUpdateStepData(
			{
				entityInstance: paymentBatch
			});

		this.context.source
			.addToNext(
				this.approve.bind(this));

		await this.performPostInitActions();

		this.isWizardStepValidForDisplay(
			paymentBatch);

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof PaymentBatchApproveComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the approve event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof PaymentBatchApproveComponent
	 */
	public async approve(): Promise<void>
	{
		this.entityApprovalType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Name eq '${this.currentData.data.entityTypeGroup}'`,
					AppConstants.empty);

		await this.approveEntity();

		await this.navigateToPaymentBatch();
	}

	/**
	 * This will send the entity approval event.
	 *
	 * @async
	 * @memberof PaymentBatchApproveComponent
	 */
	private async approveEntity(): Promise<void>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		const displayName: string =
			StringHelper.beforeCapitalSpaces(
				this.entityApprovalType.name
					.replace(
						AppConstants.characters.period,
						AppConstants.empty));

		return this.activityService.handleActivity<void>(
			new Activity<void>(
				this.approveEntityInstance(),
				'<strong>Approving Payment Batch</strong>',
				'<strong>Payment Batch approved</strong>',
				`${displayName} has been approved.`,
				`${displayName} has not been approved.`));
	}

	/**
	 * Approves a payment batch entity instance.
	 *
	 * @async
	 * @memberof PaymentBatchApproveComponent
	 */
	private async approveEntityInstance(): Promise<void>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				this.entityApprovalType.group;

		return this.entityInstanceApiService
			.executeAction(
				this.paymentBatchId,
				this.workflowActionName);
	}

	/**
	 * This will navigate to the payment batch using the entity id provided.
	 *
	 * @async
	 * @memberof PaymentBatchApproveComponent
	 */
	private async navigateToPaymentBatch()
	{
		this.moduleService.name =
			await this.entityService.getContextMenuModule(
				this.entityApprovalType.name);

		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.context.source.storeData(
			this.currentData);

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				this.entityApprovalType.group,
				AppConstants.viewTypes.edit,
				this.paymentBatchId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the static formly layout.
	 *
	 * @async
	 * @memberof PaymentBatchApproveComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.staticFormlyLayout =
			<FormlyFieldConfig[]>
			[
				{
					type: FormlyConstants.customControls.customDashboard,
					props: {
						dashboardDisplayComponentInstanceName:
							'PaymentBatchGenericDashboard'
					}
				}
			];
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @param {IEntityInstance} paymentBatch
	 * The payment batch to validate.
	 * @returns {boolean}
	 * A value signifying whether or not the wizard step is valid for display.
	 * @memberof PaymentBatchApproveComponent
	 */
	private isWizardStepValidForDisplay(
		paymentBatch: IEntityInstance): boolean
	{
		const result: boolean =
			paymentBatch.data.status ===
				PaymentBatchConstants.paymentBatchStatus.pending;

		this.clientMessage =
			result === true
				? AppConstants.empty
				: this.invalidWizardStepMessage;

		return result;
	}
}