/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-accounting-details',
	templateUrl: './transaction-accounting-details.component.html',
	styleUrls: [
		'./transaction-accounting-details.component.scss'
	]
})

/**
 * A component representing transaction accounting details used
 * in the associated entity list.
 *
 * @export
 * @class TransactionAccountingDetailsComponent
 * @implements {IDynamicComponent<Component, {
		accounting: any
		entityListContext: IAssociatedEntityListContext
	}>}
 */
export class TransactionAccountingDetailsComponent
implements IDynamicComponent<Component, {
		accounting: any;
		entityListContext: IAssociatedEntityListContext;
	}>, OnInit
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component>}
	 * @memberof TransactionAccountingDetailsComponent
	 */
	public context: IDynamicComponentContext<Component, {
			accounting: any;
			entityListContext: IAssociatedEntityListContext;
		}>;

	/**
	 * Initiates the component to get the data object.
	 *
	 * @memberof TransactionAccountingDetailsComponent
	 */
	public ngOnInit(): void
	{
		if (AnyHelper.isNull(
			(<any>this.context.source).entityInstance))
		{
			return;
		}

		this.context.data.accounting =
			(<any>this.context.source).entityInstance.data.accounting;
	}
}
