<app-base-page pageTitle="Workbench"
	[pageContext]="pageContext"
	utilityMenuOperationGroupName="">

	<div class="ui-g-12 {{siteLayoutService.contentCssClass}}">

		<div *ngIf="loadingDashboard === true || loadingPageGuard === true"
			[@contentAnimation]="state">
			<div class="ui-g-12 no-horizontal-padding text-center">
				<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
				</i>
			</div>
		</div>

		<div *ngIf="loadingDashboard === false && loadingPageGuard === false"
			[@contentAnimation]="state">
			<app-common-dashboard
				[dashboardDisplayComponentInstanceName]="'WorkbenchGenericDashboard'">
			</app-common-dashboard>
		</div>

		<div class="ui-g-12 no-padding workbench-section"
			[ngClass]="{
				'active-filter-container': filterActive === true,
				'loading-filter-container': loadingTableFilters === true
			}"
			*ngIf="loadingPageGuard === false"
			[@contentAnimation]="state">
			<custom-section-title
				[field]="{
					props: {
						label: 'Workbench'
					}
				}">
			</custom-section-title>

			<div class="filter-loading-spinner"
				*ngIf="loadingTableFilters === true || loadingPageGuard === true"
				[@contentAnimation]="state">
				<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
				</i>
			</div>

			<span class="table-filter cursor-pointer"
				*ngIf="loadingTableFilters === false && loadingPageGuard === false"
				[@contentAnimation]="state"
				clickOutside
				(clickOutside)="toggleFilterDisplay(false)"
				[ignoreAppendedBodyPanelClicks]="true"
				[contentDisplayed]="filterActive === true">

				<div class="filter-summary no-scrollbar">
					<p-chips [(ngModel)]="filterLayoutSchema"
						[disabled]="true">
						<ng-template let-item pTemplate="item">
							<div class="setting-chip ellipsis-text">
								<span class='setting-chip-label'>
									{{item.props.label}}:
								</span>
								{{getChipValue(item)}}
							</div>
						</ng-template>
					</p-chips>
				</div>

				<i class="fa fa-fw fa-filter theme-color"
					(click)="toggleFilterDisplay()">
				</i>

				<ng-template [ngIf]="filterActive === true">
					<div class="ui-g-12 filter-container"
						[ngClass]="{
							'theme-box-shadow': useOverlay === true
						}">
						<div class="ui-g-12 no-vertical-padding filter-container-title">
							Workbench Table Filters
						</div>

						<div #ScrollPanel class="scroll-panel fixed-height-scroll-panel">
							<p-scrollPanel styleClass="filter-form-container">
								<app-dynamic-formly
									[dataSet]="filterLayoutData"
									[initialData]="archivedFilterLayoutData"
									[layoutSchema]="filterLayoutSchema"
									[context]="pageContext"
									(validityChanged)="filterValidityChange($event)"
									(valuesChanged)="filterValuesChange($event)">
								</app-dynamic-formly>
							</p-scrollPanel>
						</div>

						<div class="ui-g-12 text-right button-set">
							<a id="cancelFilterChanges"
								*ngIf="filterValuesChanged === true"
								class="text-link theme-color secondary-action-button"
								(click)="cancelFilterChanges($event)">
								Cancel
							</a>
							<button
								pButton
								type="button"
								label="Clear Filters"
								class="p-button-outlined"
								(click)="clearFilters($event)">
							</button>
							<button
								pButton
								type="button"
								label="Apply"
								class="no-margin-right"
								(click)="applyFilters($event)"
								[disabled]="filterValidity === false || filterValuesChanged === false">
							</button>
						</div>
					</div>
				</ng-template>
			</span>
		</div>

		<div *ngIf="loadingTableDefinition === true || loadingPageGuard === true"
			[@contentAnimation]="state">
			<div class="ui-g-12 no-horizontal-padding text-center">
				<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
				</i>
			</div>
		</div>

		<div class="ui-g-12 no-padding"
			*ngIf="loadingTableDefinition === false && loadingPageGuard === false"
			[@contentAnimation]="state">
			<app-common-table
				[tableDefinitions]="workbenchTableDefinition">
			</app-common-table>
		</div>
	</div>

</app-base-page>