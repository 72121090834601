/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-endorse-reasons',
	templateUrl: './transaction-endorse-reasons.component.html'
})

/**
 * A component representing a wizard step for endorsing a policy transaction.
 *
 * @export
 * @class TransactionEndorseReasonsComponent
 * @extends {StatusReasonsDirective}
 */
export class TransactionEndorseReasonsComponent
	extends StatusReasonsDirective
{
	/**
	 * Gets or sets the newly created pending endorsement transaction id.
	 *
	 * @type {number}
	 * @memberof TransactionEndorseReasonsComponent
	 */
	public createdPendingEndorsementTransactionId: number;

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @memberof TransactionEndorseReasonsComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.workflowActionName =
			InsuranceConstants.workflowActions.transactionEndorse;

		this.context.source.addOrUpdateStepData(
			{
				availableReasons:
					this.getReasons(
						this.productEntityInstance.data.reasons,
						InsuranceConstants.statusReasonTypes.endorsement),
				selectedReasons: [],
				comments: AppConstants.empty
			});

		this.context.source.addToNext(this.endorse.bind(this));
	}

	/**
	 * This will send the endorse event and navigate to the pending endorse
	 * transaction.
	 *
	 * @async
	 * @memberof TransactionEndorseReasonsComponent
	 */
	public async endorse(): Promise<void>
	{
		await this.transactionEndorse();
		await this.navigateToPendingEndorseTransaction();
	}

	/**
	 * Executes the transaction endorse process by executing the PolicyEndorse
	 * workflow action to update the associated Policy term transaction
	 * entity data with the collected data from the Endorse Wizard.
	 *
	 * @async
	 * @memberof TransactionEndorseReasonsComponent
	 */
	public async transactionEndorse(): Promise<void>
	{
		const displayName: string =
			new EntityType(this.entityType)
				.displayName;
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const queryString: string =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					statusEffectiveDate: currentData.statusEffectiveDate,
					parentId: currentData.statusEntityId,
					comments: currentData.comments,
					reasons: ObjectArrayHelper.commaSeparatedPropertyValues(
						currentData.selectedReasons,
						AppConstants.commonProperties.id)
				});

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		return this.activityService.handleActivity(
			new Activity(
				new Promise(
					async(resolve: any) =>
					{
						const response: IActionResponse =
							await this.executeWorkflowAction(
								this.entityType,
								this.entityInstance,
								queryString);

						this.createdPendingEndorsementTransactionId =
							(<any>response).body.value;

						resolve();
					}),
				'<strong>Creating endorsement</strong>',
				'<strong>Endorsement created</strong>',
				`${displayName} endorsement has been created.`,
				`${displayName} endorsement has not been created.`));
	}

	/**
	 * This will navigate to the new endorse transaction using the transaction
	 * id provided.
	 *
	 * @async
	 * @memberof TransactionEndorseReasonsComponent
	 */
	private async navigateToPendingEndorseTransaction()
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				this.entityInstanceTypeGroup,
				AppConstants.viewTypes.edit,
				this.createdPendingEndorsementTransactionId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}