<span class="p-input-icon-right">
	<i [class]="htmlIconClassName">
	</i>
	<input type="text"
		pInputText
		[formControl]="formControl"
		[formlyAttributes]="field"
		(input)="inputChange()"/>
</span>

<field-validation-message
	[field]="field">
</field-validation-message>