/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewInit,
	Component,
	HostListener,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild
} from '@angular/core';
import {
	Title
} from '@angular/platform-browser';
import {
	ActivatedRoute,
	NavigationStart,
	Router
} from '@angular/router';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionRefreshService
} from '@shared/services/session-refresh.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	MenuOrientation,
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StonlyService
} from '@shared/services/stonly.service';
import {
	UpdateService
} from '@shared/services/update.service';
import {
	Settings
} from 'luxon';
import {
	MenuItem,
	MessageService
} from 'primeng/api';
import {
	ScrollPanel
} from 'primeng/scrollpanel';
import {
	Subject,
	debounceTime
} from 'rxjs';
import {
	AppConfig
} from 'src/app/app.config';

/* eslint-enable max-len */

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [
		'./app.component.scss'
	],
	providers: [
		MessageService
	],
	animations: [
		ContentAnimation
	]
})

/**
 * The primary application component class.
 *
 * @export
 * @class AppComponent
 * @implements {AfterViewInit}
 * @implements {OnInit}
 */
export class AppComponent implements AfterViewInit, OnInit, OnDestroy
{
	/**
	 * Creates an instance of the application component.
	 *
	 * @param {Renderer2} renderer
	 * The custom renderer for this application component.
	 * @param {LoggerService} logger
	 * The logger service for this application component.
	 * @param {SessionService} sessionService
	 * The session service for this application component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service for this application component.
	 * @param {Title} titleService
	 * The title service for this application component.
	 * @param {UpdateService} updateService
	 * The service that monitors for application updates.
	 * @param {SessionRefreshService} sessionRefreshService
	 * The service that monitors for application updates.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router service.
	 * @param {ModuleService} moduleService
	 * The module service.
	 * @memberof AppComponent
	 */
	public constructor(
		public renderer: Renderer2,
		public logger: LoggerService,
		public sessionService: SessionService,
		public siteLayoutService: SiteLayoutService,
		public titleService: Title,
		public updateService: UpdateService,
		public sessionRefreshService: SessionRefreshService,
		public router: Router,
		public route: ActivatedRoute,
		public moduleService: ModuleService,
		public stonlyService: StonlyService)
	{
		this.router.events.subscribe(
			this.handleRouteChange.bind(this));
		this.route.params.subscribe(
			this.handleRouteChange.bind(this));
	}

	/**
	 * Gets or sets the layout menu scroller element reference
	 * from the dom.
	 *
	 * @type {ScrollPanel}
	 * @memberof AppComponent
	 */
	@ViewChild('layoutMenuScroller')
	public layoutMenuScrollerViewChild: ScrollPanel;

	/**
	 * Gets or sets the base page's content context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof AppComponent
	 */
	public contentContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the rotate menu button value.
	 * This is used to define if the menu display should
	 * have a rotate animation (Used when expanded).
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public rotateMenuButton: boolean;

	/**
	 * Gets or sets the overlay menu button active value.
	 * This is used to define if the site is currently displaying
	 * and overlay navigation menu.
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public overlayMenuActive: boolean;

	/**
	 * Gets or sets the modal display value of chat. When in mobile views
	 * this chat will add a minimize button to the chat modal we need to
	 * override.
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public chatModalDisplayed: boolean = false;

	/**
	 * Gets or sets the inactive desktop menu display value.
	 * This is used to define if the primary navigation menu
	 * should be hidden.
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public staticMenuDesktopInactive: boolean;

	/**
	 * Gets or sets the inactive mobile menu display value.
	 * This is used to define if the primary navigation menu
	 * should be displayed.
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public staticMenuMobileActive: boolean;

	/**
	 * Gets or sets the boolean value signifying that the context
	 * menu is open.
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public contextMenuDisplayed: boolean;

	/**
	 * Gets or sets the layout menu scroll element reference.
	 *
	 * @type {HTMLDivElement}
	 * @memberof AppComponent
	 */
	public layoutMenuScroller: HTMLDivElement;

	/**
	 * Gets or sets the layout changed subject which is called
	 * when the site layout sizes are changed in this component.
	 * This updates the site layout service and notifies all observers
	 * that the site layout has changed post data updates.
	 *
	 * @type {Subject<void>}
	 * @memberof AppComponent
	 */
	public layoutChanged: Subject<void> = new Subject<void>();

	/**
	 * Gets or sets the value used to define if the profile display
	 * is expanded.
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public activeProfileMenu: boolean = false;

	/**
	 * Gets the base model used to display the profile operation
	 * group. This item will display prior to the data
	 * load of the operation group.
	 *
	 * @type {MenuItem[]}
	 * @memberof AppComponent
	 */
	public initialProfileModel: MenuItem[] = [];

	/**
	 * Gets or sets the profile group identifier that will
	 * be displayed via this operation group display component.
	 *
	 * @type {string}
	 * @memberof AppComponent
	 */
	public profileGroupName: string =
		AppConstants.primaryOperationGroups.profileActions;

	/**
	 * Gets or sets the value used to reset the profile operation menu.
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public resetProfileMenu: boolean = false;

	/**
	 * Gets the current count of pinned drawers that are
	 * consuming content width.
	 *
	 * @type {number}
	 * @memberof AppComponent
	 */
	public get pinnedDrawerCount(): number
	{
		return this.siteLayoutService.pinnedDrawerCount;
	}

	/**
	 * Sets the current count of pinned drawers that are
	 * consuming content width.
	 *
	 * @param {number} pinnedDrawerCount
	 * The count of pinned drawers currently displayed.
	 * @memberof AppComponent
	 */
	public set pinnedDrawerCount(
		drawerCount: number)
	{
		const countChanged: boolean =
			this.siteLayoutService.pinnedDrawerCount !== drawerCount;

		if (countChanged === true)
		{
			this.siteLayoutService.pinnedDrawerCount = drawerCount;
			this.layoutChanged.next();
		}
	}

	/**
	 * Handles the set content context event. This will set the
	 * application level content context.
	 *
	 * @param {IDynamicComponentContext<Component, any>} contentContext
	 * The main content context of this application.
	 * @memberof AppComponent
	 */
	@HostListener(
		AppEventConstants.setContentContextEvent,
		[AppEventParameterConstants.contentContext])
	public displayContextMenuOverlay(
		contentContext: IDynamicComponentContext<Component, any>): void
	{
		setTimeout(() =>
		{
			this.contentContext = contentContext;
		});
	}

	/**
	 * Handles the context menu active event. This is true when the
	 * context menu is open.
	 *
	 * @param {boolean} active
	 * If true, the context menu is open.
	 * @memberof AppComponent
	 */
	@HostListener(
		AppEventConstants.contextMenuActiveEvent,
		[AppEventParameterConstants.active])
	public contextMenuActive(
		active: boolean): void
	{
		if (this.contextMenuDisplayed !== active)
		{
			this.contextMenuDisplayed = active;
			this.layoutChanged.next();
		}
	}

	/**
	 * Handles the chat modal displayed event.
	 * Sets the chat modal window display value. If this is true, the minimize
	 * added to the chat window will be covered so minimize will close the
	 * drawer. This is only visible in 600px or smaller views while
	 * the chat modal is displayed in an overlay drawer.
	 *
	 * @memberof AppComponent
	 */
	@HostListener(
		AppEventConstants.chatModalDisplayedEvent,
		[AppEventParameterConstants.displayed])
	public onChatModalDisplayed(
		displayed: boolean): void
	{
		setTimeout(
			() =>
			{
				this.chatModalDisplayed = displayed;
			});
	}

	/**
	 * Handles the on window resize event.
	 * Dispatches any layout changed updates to listening subjects.
	 *
	 * @memberof AppComponent
	 */
	@HostListener(
		WindowEventConstants.resizeEvent)
	public onResize(): void
	{
		this.hideOverlayMenu();
		this.layoutChanged.next();
	}

	/**
	 * Handles the navigate to access denied event by navigating to the
	 * generic access denied page. This will replace the current url
	 * so that back will not try to access the page that denied access
	 * in the history.
	 *
	 * @param {string} route
	 * The route to be displayed.
	 * @param {string} resources
	 * The set of resources associated with this access denied display.
	 * @param {string} clientMessage
	 * If sent this will add a client message to the access denied display. This
	 * value defaults to an empty string.
	 * @memberof AppComponent
	 */
	@HostListener(
		AppEventConstants.navigateToAccessDeniedEvent,
		[
			AppEventParameterConstants.route,
			AppEventParameterConstants.resources,
			AppEventParameterConstants.clientMessage,
		])
	public navigateToAccessDenied(
		route: string,
		resources: string[],
		clientMessage: string = AppConstants.empty): void
	{
		const moduleName: string =
			this.moduleService.name.toLowerCase() ===
				AppConstants.moduleNames.dashboard
				? AppConstants.empty
				: this.moduleService.name.charAt(0).toLowerCase()
					+ this.moduleService.name.slice(1);

		this.router.navigate(
			[
				`${moduleName}/accessDenied`
			],
			{
				skipLocationChange: true,
				queryParams:
					{
						routeData:
							ObjectHelper.mapRouteData(
								{
									route: route,
									resources: resources,
									clientMessage: clientMessage
								})
					}
			});
	}

	/**
	 * On initialization event.
	 * Initializes user layout settings.
	 *
	 * @memberof AppComponent
	 */
	public ngOnInit(): void
	{
		this.logger.logInformation('Application component initialized ...');

		// Set page title based on branding
		this.titleService.setTitle(
			AppConfig.settings.branding.title
				?? 'WaterStreet - Nautix');

		// If user is logged in and we have an active session then we should
		// Set the page layout to that users settings.
		if (this.sessionService.isLoggedIn)
		{
			this.siteLayoutService.setFromUserSettings(
				this.sessionService.user);
		}

		this.layoutChanged.pipe(
			debounceTime(this.siteLayoutService.debounceDelay))
			.subscribe(() =>
			{
				this.setSiteLayoutVariables();
			});

		this.layoutChanged.next();

		// Start interval based services.
		this.updateService
			.start(AppConfig.settings
				.checkForUpdatesInterval);

		this.sessionService.addLocalStorageEventListener();
	}

	/**
	 * On after view initialization event.
	 * Handles scrollbar setups based on menu interactions.
	 *
	 * @memberof AppComponent
	 */
	public ngAfterViewInit(): void
	{
		if (this.sessionService.isValidLoggedInAndReady)
		{
			setTimeout(
				() =>
				{
					this.layoutMenuScrollerViewChild?.moveBar();
				},
				this.siteLayoutService.debounceDelay);

			// Start interval based session services.
			this.sessionRefreshService
				.start(AppConfig.settings
					.clientExpirationInterval);

			Settings.defaultZone = this.sessionService.systemTimeZone;
		}
		else
		{
			// Stop interval based session services.
			this.sessionRefreshService
				.stop();

			Settings.defaultZone = DateHelper.getLocalTimeZone();
		}
	}

	/**
	 * Handles the on destroy interface.
	 * This completes any watched subjects to free memory.
	 *
	 * @memberof AppComponent
	 */
	public ngOnDestroy(): void
	{
		this.layoutChanged.complete();
		this.updateService.stop();
		this.sessionRefreshService.stop();
	}

	/**
	 * Handles the active profile changed event from the app profile.
	 * Sets the display value of the profile menu.
	 *
	 * @memberof AppComponent
	 */
	public activeProfileChanged(
		active: boolean): void
	{
		this.resetProfileMenu = active;
		this.activeProfileMenu = active;
	}

	/**
	 * Sets the singleton variables for the site layout service
	 * on a site initial load or on a layout change event.
	 *
	 * @memberof AppComponent
	 */
	public setSiteLayoutVariables(): void
	{
		const pinnedDrawerWidth: number =
			this.siteLayoutService.pinnedDrawerCount
			* AppConstants.staticLayoutSizes.drawerWidth;

		const contextMenuCapturedWidth: number =
			this.siteLayoutService.displayTabletView === true
				|| (this.siteLayoutService.displayTabletView === false
					&& this.contextMenuDisplayed !== true)
				? 0
				: AppConstants.staticLayoutSizes.expandedContextMenuWidth
					- AppConstants.staticLayoutSizes.collapsedContextMenuWidth;

		this.siteLayoutService.windowWidth =
			window.innerWidth;
		this.siteLayoutService.windowHeight =
			window.innerHeight;
		this.siteLayoutService.contextMenuCapturedWidth =
			contextMenuCapturedWidth;
		this.siteLayoutService.contentWidth =
			this.siteLayoutService.windowWidth
				- (pinnedDrawerWidth)
				- (contextMenuCapturedWidth);
		this.siteLayoutService.breakpointWidth =
			this.siteLayoutService.contentWidth
				+ contextMenuCapturedWidth;
		this.siteLayoutService.contentCssClass =
			this.siteLayoutService.getContentCssClass();

		EventHelper.dispatchSiteLayoutChangedEvent();
	}

	/**
	 * Handles the click event for menu layout changes requested
	 * by the user. Sets variables for defined behaviors based
	 * on the menu layout selection.
	 *
	 * @param {any} event
	 * The event passed from the menu button click event.
	 * @memberof AppComponent
	 */
	public overlayMenuButtonClicked(
		event: Event): void
	{
		this.rotateMenuButton = !this.rotateMenuButton;

		if (this.siteLayoutService.menuMode === MenuOrientation.OVERLAY)
		{
			this.overlayMenuActive = !this.overlayMenuActive;
		}
		else
		{
			if (!this.siteLayoutService.displayTabletView)
			{
				this.staticMenuDesktopInactive =
					!this.staticMenuDesktopInactive;
			}
			else
			{
				this.staticMenuMobileActive = !this.staticMenuMobileActive;
			}
		}

		event.preventDefault();
		event.stopPropagation();
	}

	/**
	 * Hides the overlay menu.
	 *
	 * @memberof AppComponent
	 */
	public hideOverlayMenu(): void
	{
		this.rotateMenuButton = false;
		this.overlayMenuActive = false;
		this.staticMenuMobileActive = false;
	}

	/**
	 * Handles the pinning of drawers by notifying the application
	 * of a change in pinned counts. This is used to define available
	 * layout space for main content.
	 *
	 * @param {number} pinnedDrawerCount
	 * The current pinned drawer count.
	 * @memberof AppComponent
	 */
	public pinnedDrawerCountChanged(
		pinnedDrawerCount: number): void
	{
		this.pinnedDrawerCount = pinnedDrawerCount;
		this.layoutChanged.next();
	}

	/**
	 * Gets the is overlay value. This defines if
	 * the application is using an overlay menu display mode.
	 *
	 * @returns {boolean}
	 * If the application is using a overlay menu display mode.
	 * @memberof AppComponent
	 */
	public isOverlay(): boolean
	{
		return this.siteLayoutService.menuMode === MenuOrientation.OVERLAY;
	}

	/**
	 * Gets the is slim value. This defines if
	 * the application is using a slim menu display mode.
	 *
	 * @returns {boolean}
	 * If the application is using a slim menu display mode.
	 * @memberof AppComponent
	 */
	public isSlim(): boolean
	{
		return this.siteLayoutService.menuMode === MenuOrientation.SLIM;
	}

	/**
	 * Sets the menu display mode as overlay.
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public changeToOverlayMenu(): void
	{
		this.siteLayoutService.menuMode = MenuOrientation.OVERLAY;
	}

	/**
	 * Sets the menu display mode as slim.
	 *
	 * @type {boolean}
	 * @memberof AppComponent
	 */
	public changeToSlimMenu(): void
	{
		this.siteLayoutService.menuMode = MenuOrientation.SLIM;
	}

	/**
	 * Handles the route change events.
	 *
	 * @param {RouterEvent} routerChangeEvent
	 * The router change event.
	 * @memberof AppComponent
	 */
	public handleRouteChange(
		routerChangeEvent: any): void
	{
		if (routerChangeEvent instanceof NavigationStart)
		{
			this.stonlyService.closeWidget();
		}
	}
}