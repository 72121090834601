<div class="ui-g-12 no-horizontal-padding">
	<ng-template
		[ngIf]="context.source.wizardStepLoading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<ng-template
			[ngIf]="clientMessage !== ''"
			[ngIfElse]="Differences">
			<div class="ui-g-12 no-horizontal-padding text-center">
				<span class="panel-sub-title">
					{{clientMessage}}
				</span>
			</div>
		</ng-template>

		<ng-template #Differences>
			<app-differences-display
				[objectName]="comparisonObjectName"
				[initialObject]="existingTransactionInstance"
				[comparisonObject]="endorseTransactionInstance"
				[entityDefinition]="entityDefinition"
				[differences]="differences"
				(detectChanges)="detectChanges()">
			</app-differences-display>
		</ng-template>
	</ng-template>
</div>