/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';

@Component({
	selector: 'app-color-swatch',
	templateUrl: './color-swatch.component.html',
	styleUrls: [
		'./color-swatch.component.scss'
	]
})

/**
 * A component representing an instance of a color swatch.
 * This is generated and hidden so that computed color styles
 * matching the theme can be used in code.
 *
 * @export
 * @class ColorSwatchComponent
 */
export class ColorSwatchComponent
{
}