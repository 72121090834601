/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	HttpProgressEvent,
	HttpResponse
} from '@angular/common/http';
import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	Validators
} from '@angular/forms';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	FileCategory
} from '@dynamicComponents/files/file-category/file-category';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	EmbeddedFileEncoding
} from '@shared/constants/enums/embedded-file-encoding.enum';
import {
	FileProgressType
} from '@shared/constants/enums/file-progress-type.enum';
import {
	StorageType
} from '@shared/constants/enums/storage-type.enum';
import {
	FileDirective
} from '@shared/directives/file.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FileHelper
} from '@shared/helpers/file.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IFileProgress
} from '@shared/interfaces/files/file-progress.interface';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	FileService
} from '@shared/services/files/file.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	FileUpload
} from 'primeng/fileupload';

/* eslint-enable max-len */

@Component({
	selector: 'app-add-file',
	templateUrl: './add-file.component.html',
	styleUrls: ['./add-file.component.scss']
})

/**
 * A component for adding files.
 *
 * @export
 * @class AddFileComponent
 */
export class AddFileComponent
	extends FileDirective
	implements OnInit
{
	/**
	 * Initializes a new instance of add document component.
	 *
	 * @param {ActivityService} activityService
	 * Handles activities and banners.
	 * @param {FileService} fileService
	 * A file service for working with files.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof AddFileComponent
	 */
	public constructor(
		private readonly activityService: ActivityService,
		private readonly fileService: FileService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public resolver: ResolverService)
	{
		super(
			entityTypeApiService,
			entityInstanceApiService,
			resolver);
	}

	/**
	 * Gets or sets the common list context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof AddFileComponent
	 */
	@Input()
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the file added event
	 *
	 * @type {EventEmitter<string>}
	 * @memberof AddFileComponent
	 */
	@Output()
	public added: EventEmitter<string> = new EventEmitter<string>();

	/**
	 * Gets or sets the file uploader.
	 *
	 * @type {FileUpload}
	 * @memberof AddFileComponent
	 */
	@ViewChild('fileUploader')
	public fileUploader: FileUpload;

	/**
	 * Gets or sets the percentage amount of progress made
	 * towards completing the add file task.
	 *
	 * @type {number}
	 * @memberof AddFileComponent
	 */
	public progressPercent: number = 0;

	/**
	 * Gets or sets the display mode for the progress component.
	 *
	 * @type {string}
	 * @memberof AddFileComponent
	 */
	public progressMode: string = 'progressBar';

	/**
	 * Gets or sets the display message for the progress component.
	 *
	 * @type {string}
	 * @memberof AddFileComponent
	 */
	public progressMessage: string = 'Starting upload...';

	/**
	 * Gets or sets a value indicating whether the
	 * add document form should display.
	 *
	 * @type {boolean}
	 * @memberof AddFileComponent
	 */
	public addDocumentVisible: boolean = true;

	/**
	 * Gets or sets the list of storage types.
	 *
	 * @type {object}
	 * @memberof AddFileComponent
	 */
	public storageTypes: object =
		[
			{
				label: 'Save to cloud/disk',
				value: StorageType.Persisted,
				icon: 'fa fa-cloud',
				tooltip: 'Persisted'
			},
			{
				label: 'Referenced',
				value: StorageType.Referenced,
				icon: 'fa fa-link',
				tooltip: 'Referenced'
			},
			{
				label: 'Embedded',
				value: StorageType.Embedded,
				icon: 'fa fa-suitcase',
				tooltip: 'Embedded'
			}
		];

	/**
	 * Gets or sets the form control for the name field.
	 *
	 * @type {FormControl}
	 * @memberof AddFileComponent
	 */
	public nameControl: UntypedFormControl =
		new UntypedFormControl(
			AppConstants.empty,
			[
				Validators.required,
				Validators.maxLength(128)
			]);

	/**
	 * Gets or sets the form control for the url field
	 * for a referenced file.
	 *
	 * @type {FormControl}
	 * @memberof AddFileComponent
	 */
	public urlControl: UntypedFormControl =
		new UntypedFormControl(
			AppConstants.empty,
			Validators.pattern('^(http|https)://.+/.+$'));

	/**
	 * Gets or sets the form control for the category field.
	 *
	 * @type {FormControl}
	 * @memberof AddFileComponent
	 */
	public categoryControl: UntypedFormControl =
		new UntypedFormControl(
			AppConstants.empty,
			Validators.required);

	/**
	 * Gets or sets the form control for the category field.
	 *
	 * @type {FormControl}
	 * @memberof AddFileComponent
	 */
	public subTypeControl: UntypedFormControl =
		new UntypedFormControl(AppConstants.empty);

	/**
	 * Gets or sets the value for the document description.
	 *
	 * @type {string}
	 * @memberof AddFileComponent
	 */
	public description: string;

	/**
	 * The file extensions
	 *
	 * @type {string}
	 * @memberof AddFileComponent
	 */
	public extension: string = this.defaultFileExtension;

	/**
	 * The subtype options
	 *
	 * @type {IDropdownOption[]}
	 * @memberof AddFileComponent
	 */
	public subTypeOptions: IDropdownOption[] = [];

	/**
	 * Gets or sets the Form group for the component
	 * that need validation.
	 *
	 * @type {FormGroup}
	 * @memberof AddFileComponent
	 */
	public addForm: UntypedFormGroup =
		new UntypedFormGroup(
			{
				'nameControl': this.nameControl,
				'urlControl': this.urlControl,
				'categoryControl': this.categoryControl,
				'subTypeControl': this.subTypeControl
			},
			{
				validators:
					(formGroup: UntypedFormGroup): ValidationErrors | null =>
					{
						const url = formGroup
							.get('urlControl');

						switch (this.storageType)
						{
							case StorageType.Persisted:
							case StorageType.Embedded:

								url.setErrors(null, {emitEvent: true});

								if (this.fileUploader?.files?.length !== 1)
								{
									return <ValidationErrors>
									{
										fileRequired: true
									};
								}
								break;

							case StorageType.Referenced:
								if (AnyHelper.isNullOrWhitespace(url.value))
								{
									url.setErrors(
										{
											required: true
										},
										{
											emitEvent: true
										});
								}
								break;
						}

						return null;
					}
			});

	/**
	 * Gets the entity group type of the selected document.
	 *
	 * @type {string}
	 * @memberof AddFileComponent
	 */
	public get documentGroup(): string
	{
		return this.categories
			.find((element: FileCategory) =>
				element.value === this.categoryControl.value)
			.entityType
			.group;
	}

	/**
	 * Gets the storage type of the selected document.
	 *
	 * @type {StorageType}
	 * @memberof AddFileComponent
	 */
	public get storageType(): StorageType
	{
		return this._storageType;
	}

	/**
	 * Sets the storage type of the selected document.
	 *
	 * @type {StorageType}
	 * @memberof AddFileComponent
	 */
	public set storageType(value: StorageType)
	{
		this._storageType = value;
	}

	/**
	 * Gets or sets the timer used to simulate progress
	 * when extra large files are taking too long.
	 *
	 * @private
	 * @type {any}
	 * @memberof AddFileComponent
	 */
	private fakeProgressTimer: any;

	/**
	 * Gets or sets the local variable for the storage type.
	 *
	 * @type {StorageType}
	 * @memberof AddFileComponent
	 */
	private _storageType: StorageType =
		StorageType.Persisted;

	/**
	 * Handles the on initialization event.
	 * This will validate the form on initial load.
	 *
	 * @memberof AddFileComponent
	 */
	public ngOnInit(): void
	{
		this.addForm.updateValueAndValidity();
	}

	/**
	 * Handles the file selected event.
	 *
	 * @memberof AddFileComponent
	 */
	public fileSelected(): void
	{
		if (this.nameControl.invalid)
		{
			this.setName(
				this.fileUploader
					.files[0]
					.name);
		}

		const extension = FileHelper
			.findKnownExtension(
				this.fileUploader
					.files[0].name);

		this.extension = AnyHelper.isNull(extension)
			? this.defaultFileExtension
			: extension;
	}

	/**
	 * Handles the referenced file url changed event.
	 *
	 * @memberof AddFileComponent
	 */
	public fileUrlChanged(): void
	{
		if (AnyHelper.isNullOrEmpty(this.nameControl.value))
		{
			const name: string =
				this.tryGetFileName(
					this.urlControl.value);

			this.setName(name);
		}

		const extension = FileHelper
			.findKnownExtension(this.urlControl.value);

		this.extension = AnyHelper.isNull(extension)
			? this.defaultFileExtension
			: extension;
	}

	/**
	 * Handles the file category changed event.
	 * @param {string} event
	 * The event
	 * @memberof AddFileComponent
	 */
	public categoryChanged(event: any): void
	{
		this.subTypes = this.categories
			.find(item => item.entityType.name === event.value)
			?.subTypes ?? [];

		this.subTypeOptions = [];

		this.subTypes.forEach(
			(subType: string) =>
			{
				if(!AnyHelper.isNullOrEmpty(subType))
				{
					this.subTypeOptions.push(
						<IDropdownOption>
						{
							label: subType,
							value: subType
						});
				}
			});

		if (this.subTypes.length === 0)
		{
			this.subTypeControl.reset();
			this.subTypeControl.clearValidators();
		}
		else
		{
			this.subTypeControl.addValidators(Validators.required);
		}

		this.subTypeControl.updateValueAndValidity();
	}

	/**
	 * Determines if there are file categories for the entity in context.
	 *
	 * @memberof AddFileComponent
	 * @returns {boolean}
	 * The result of the check.
	 */
	public hasCategories(): boolean
	{
		return !AnyHelper.isNull(this.categories)
			&& this.categories.length > 0;
	}

	/**
	 * Determines if a form field is valid.
	 *
	 * @param {string} controlName
	 * The name of the filed to check.
	 * @memberof AddFileComponent
	 * @returns {boolean}
	 * The result of the check. True if the field is invalid.
	 */
	public invalid(controlName: string): boolean
	{
		const control: any = this.addForm.get(controlName);

		return control.invalid;
	}

	/**
	 * Handles the add file button click event and
	 * attempts to add a new file.
	 *
	 * @memberof AddFileComponent
	 */
	public onAddClicked(): void
	{
		if (this.addForm.invalid)
		{
			return;
		}

		let parentId: number;
		let parentTypeGroup: string;
		if (this.context.source instanceof EntityInstanceComponent)
		{
			parentId = this.context.source.id;
			parentTypeGroup = this.context.source.entityTypeGroup;
		}
		else
		{
			return;
		}

		this.addDocumentVisible = false;
		this.progressVisible = true;

		const file: File =
			this.storageType !== StorageType.Referenced
				? this.fileUploader.files[0]
				: null;

		const encoding: EmbeddedFileEncoding =
			this.storageType === StorageType.Embedded
				? EmbeddedFileEncoding.Base64
				: null;

		const promise: Promise<void> =
				new Promise<void>((resolve, reject) =>
				{
					this.fileService
						.addNewFile(
							this.storageType,
							parentId,
							parentTypeGroup,
							this.urlControl.value,
							encoding,
							file,
							this.nameControl.value,
							this.description,
							this.categoryControl.value,
							this.documentGroup,
							this.subTypeControl.value)
						.subscribe({
							next:
								(progress: IFileProgress) =>
								{
									this.handleProgress(progress);

									if (progress.type ===
										FileProgressType.Error)
									{
										reject(progress.message);
									}

									if (progress.type ===
										FileProgressType.Complete)
									{
										EventHelper
											.dispatchRefreshComponentEvent(
												DynamicComponentLookup
													.supportedTypes
													.workItemsComponent,
												DynamicComponentLookup
													.targetComponents
													.utilityMenuComponent);
									}
								},
							error:
								(error: any) =>
								{
									this.displayError(error);
									reject(error);
								},
							complete:
								() =>
								{
									resolve();
								}
						});
				});

		this.activityService.handleActivity<void>(
			new Activity<void>(
				promise,
				`<strong>Adding file</strong> ${this.nameControl.value}`,
				`<strong>Added file</strong> ${this.nameControl.value}`,
				`${this.nameControl.value} was successfully added.`,
				`Error adding ${this.nameControl.value}.`));
	}

	/**
	 * Sets the name and extenion.
	 *
	 * @private
	 * @param {string} fileName
	 * The name to set.
	 * @memberof AddFileComponent
	 */
	private setName(fileName: string): void
	{
		this.nameControl.setValue(
			fileName.split('.')[0]);
	}

	/**
	 * Handles Progress responses.
	 *
	 * @private
	 * @param {IFileProgress} progress
	 * The file progress to handle
	 * @memberof AddFileComponent
	 */
	private handleProgress(
		progress: IFileProgress): void
	{
		const startingUpload: string = 'Starting upload...';
		const uploading: string = 'Uploading...';

		switch (progress.type)
		{
			case FileProgressType.CreatingEntity:
				this.progressAmount = 1;
				this.calculateProgressPercent();
				this.progressMessage = startingUpload;
				this.fakeProgress(
					'start',
					false);
				break;

			case FileProgressType.EntityCreated:
				this.fakeProgress(
					'stop',
					false);
				this.progressAmount += 67;
				this.progressMessage = startingUpload;
				this.calculateProgressPercent();
				break;

			case FileProgressType.UploadStarted:
				this.progressMode = 'progressBar';
				this.progressAmount += 33;
				this.calculateProgressPercent();
				this.progressMessage = uploading;
				break;

			case FileProgressType.UploadProgress:
				this.progressMode = 'progressBar';
				this.progressMessage = uploading;

				const progressEvent: HttpProgressEvent
					= progress.value;

				const amountToAdd: number = Math
					.round((progressEvent.loaded / progressEvent.total) * 100);

				this.progressAmount = 100 + amountToAdd;

				this.calculateProgressPercent();

				if (progressEvent.loaded === progressEvent.total)
				{
					this.fakeProgress('start');

					this.progressMode = 'progressBar';
					this.progressMessage = 'Finishing up...';
				}
				break;

			case FileProgressType.ResponseStatus:
				this.fakeProgress('stop');
				this.progressAmount += 34;
				this.calculateProgressPercent();
				break;

			case FileProgressType.Response:
				const response: HttpResponse<IActionResponse>
					= progress.value;
				this.progressAmount += 33;
				this.calculateProgressPercent();
				if (response.ok)
				{
					this.fakeProgress('stop');
					this.added.emit(response.body.value);
					setTimeout(() =>
					{
						this.progressMode = 'done';
						this.progressMessage = 'Done!';
					},
					500);
				}

				break;

			case FileProgressType.Error:
				this.fakeProgress('stop');
				this.displayError(progress.message);
				break;

			case FileProgressType.Complete:
				this.fakeProgress('stop');
				this.progressAmount = 300;
				this.calculateProgressPercent();
				setTimeout(() =>
				{
					this.changeDisplayMode.emit(
						this.viewModes.list);
				},
				3000);
		}
	}

	/**
	 * Handles the progress component display of any errors
	 *
	 * @private
	 * @param {string} message
	 * The error message to didplay.
	 * @memberof AddFileComponent
	 */
	private displayError(
		message: string): void
	{
		this.progressMessage = null;
		this.errorMessage = message;
		this.progressMode = 'error';
		this.fakeProgress('stop');
	}

	/**
	 * Attempts to get the filename from a url.
	 *
	 * @private
	 * @param {string} url
	 * The url of the file.
	 * @memberof AddFileComponent
	 * @return {string}
	 * The file name found or null.
	 */
	private tryGetFileName(url: string): string
	{
		try
		{
			return url
				.split('?')[0]
				.split('/')
				.reverse()[0];
		}
		catch
		{
			return null;
		}
	}

	/**
	 * Calculates an overall progress of the tasks involved
	 * in adding a file/document.
	 *
	 * @private
	 * @memberof AddFileComponent
	 */
	private calculateProgressPercent(): void
	{
		this.progressPercent = Math.round((this.progressAmount / 300) * 100 );
	}

	/**
	 * Starts or stops simulated progress on a set interval until
	 * progress reaches 100 percent.
	 *
	 * @private
	 * @param {string} action
	 * The action of start or stop
	 * @param {boolean} showRandomMessages
	 * Option value to show random ening messages. Default is true.
	 * @memberof AddFileComponent
	 */
	private fakeProgress(
		action: string,
		showRandomMessages: boolean = true)
	{
		if (action === 'start')
		{
			let count = 0;
			this.fakeProgressTimer =
				setInterval(() =>
				{
					this.progressAmount += 5;
					this.calculateProgressPercent();

					count++;
					if (showRandomMessages)
					{
						switch (count)
						{
							case 3:
								this.progressMessage =
									'Thanks for your patience...';
								break;
							case 6:
								this.progressMessage =
									'Getting closer...';
								break;
							case 9:
								this.progressMessage = 'Sheesh! Big file...';
								break;
							case 12:
								this.progressMessage = 'Almost done...';
								break;
						}
					}
					if (this.progressPercent >= 100)
					{
						this.progressMode = 'spinner';
						this.progressMessage =
							'You can exit any time. '
								+ 'We will notify you when the process '
								+ 'is complete.';
						clearInterval(this.fakeProgressTimer);
					}
				},
				AppConstants.time.twoSeconds);
		}
		else if (action === 'stop')
		{
			clearInterval(this.fakeProgressTimer);
		}
	}
}