/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'manage-parties-confirmation-claim',
	templateUrl: './manage-parties-confirmation-claim.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard step for confirming claim party changes.
 *
 * @export
 * @class ManagePartiesConfirmationClaimComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class ManagePartiesConfirmationClaimComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the manage parties component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation..
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the party details markdown.
	 *
	 * @type {Promise<string>}
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private partyDetails: Promise<string>;

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private claimId: number;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private currentData: any;

	/**
	 * Gets or sets the party type.
	 *
	 * @type {string}
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private partyType: string;

	/**
	 * Gets or sets the party action.
	 *
	 * @type {string}
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private partyAction: string;

	/**
	 * Gets or sets the workflow action name to be executed.
	 *
	 * @type {string}
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private workflowActionName: string;

	/**
	 * Gets or sets the parameter string.
	 *
	 * @type {string}
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private parameterString: string;

	/**
	 * Implements the on initialization interface.
	 *
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.claimId =
			this.currentData.data.id;

		this.partyType =
			this.currentData.data.partyType;

		this.partyAction =
			this.currentData.data.partyAction;

		this.partyDetails = this.getPartyDetails();

		this.context.source.addToNext(this.confirmParty.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the claim manage parties event and navigate to the claim
	 * entity.
	 *
	 * @async
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	public async confirmParty(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		await this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimId,
					this.workflowActionName,
					null,
					this.parameterString),
				`<strong>Updating ${this.partyType}</strong>`,
				`<strong>${this.partyType} Updated</strong>`,
				`${this.partyType} has been updated.`,
				`${this.partyType} has not been updated.`));

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const url: string = this.entityInstanceApiService.getBaseUrl()
			+ `/${this.claimId}`;

		await this.entityInstanceApiService.resetAssociatedCache(url);

		await this.navigateToClaimSummary(
			this.claimId,
			ClaimConstants.claimEntityTypeGroups.claims);
	}

	/**
	 * This gets the party summary information to display.
	 *
	 * @async
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	public async getPartyDetails(): Promise<string>
	{
		let details: string;

		switch(this.partyType){
			case 'Claimant':
				const claimant: any = this.currentData.data.claimant;

				const claimantName: string =
					StringHelper.toNameString(
						claimant.name.firstName,
						claimant.name.lastName);

				const claimantAddress: string =
					StringHelper.toAddressString(
						claimant.address.address,
						claimant.address.city,
						claimant.address.state,
						claimant.address.postalCode);

				const claimantPhone: string =
					!AnyHelper.isNullOrEmpty(claimant?.phone?.number)
						? claimant.phone.number
						: 'Not Provided';

				const claimantEmail: string =
					!AnyHelper.isNullOrEmpty(claimant?.email?.address)
						? claimant.email.address
						: 'Not Provided';
				details =
					'<strong>Third Party Claimant Details</strong><br/>'
						+ `Claimant: ${claimantName}<br/>`
						+ `Mailing Address: ${claimantAddress}<br/>`
						+ `Phone: ${claimantPhone}<br/>`
						+ `E-mail: ${claimantEmail}<br/>`;

				this.setClaimantActionParameters(claimant);

				break;
			case 'Adjuster':
				const adjuster: any = this.currentData.data.adjuster;

				const adjusterName: string =
					adjuster.instance.data.name.legalName;

				const adjusterAddress: any =
					adjuster.instance.data?.addresses?.find(
						(address: any) =>
							address.type === 'Mailing');

				const formattedAdjusterAddress: string =
					adjusterAddress
						? StringHelper.toAddressString(
							adjusterAddress.address,
							adjusterAddress.city,
							adjusterAddress.state,
							adjusterAddress.postalCode)
						: 'No address on file';

				const userName: string =
					StringHelper.toNameString(
						adjuster.userInstance.data.firstName,
						adjuster.userInstance.data.lastName);

				details =
					'<strong>Adjuster Details</strong><br/>'
						+ `Company: ${adjusterName}<br/>`
						+ `Mailing Address: ${formattedAdjusterAddress}<br/>`
						+ `User: ${userName}<br/>`;

				this.setAdjusterActionParameters(
					adjuster.instance.id,
					adjuster.userInstance.id);

				break;
			case 'Vendor':
				const vendor: any = this.currentData.data.vendor;
				const vendorName: string = vendor.instance.data.name.legalName;
				const vendorType: string =
					StringHelper.beforeCapitalSpaces(vendor.type);

				const vendorAddress: any =
					vendor.instance.data?.addresses?.find(
						(address: any) =>
							address.type === 'Mailing');

				const formattedVendorAddress: string =
					vendorAddress
						? StringHelper.toAddressString(
							vendorAddress.address,
							vendorAddress.city,
							vendorAddress.state,
							vendorAddress.postalCode)
						: 'No address on file';

				details =
					'<strong>Vendor Details</strong><br/>'
						+ `Company: ${vendorName}<br/>`
						+ `Type: ${vendorType}<br/>`
						+ `Mailing Address: ${formattedVendorAddress}<br/>`;

				this.setVendorActionParameters(
					vendor.instance.id,
					vendor.type);

				break;
		}

		return details;
	}

	/**
	 * This will set the parameters needed for the manage claimant
	 * workflow action.
	 *
	 * @param {any} claimant
	 * The claimant object.
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private setClaimantActionParameters(
		claimant: any): void
	{
		this.workflowActionName =
			ClaimConstants.workflowActions.manageClaimant;

		this.parameterString =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					partyAction: this.partyAction,
					resourceIdentifier: claimant?.instance?.resourceIdentifier,
					firstName: claimant.name.firstName,
					lastName: claimant.name.lastName,
					address: claimant.address.address,
					city: claimant.address.city,
					state: claimant.address.state,
					postalCode: claimant.address.postalCode,
					email: claimant?.email?.address,
					phone: claimant?.phone?.number
				});
	}

	/**
	 * This will set the parameters needed for the manage adjuster
	 * workflow action.
	 *
	 * @param {number} adjustingCompanyId
	 * The adjusting company id.
	 * @param {number} adjustingCompanyUserId
	 * The adjusting company user id.
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private setAdjusterActionParameters(
		adjustingCompanyId: number,
		adjustingCompanyUserId: number): void
	{
		this.workflowActionName =
			ClaimConstants.workflowActions.manageAdjuster;

		this.parameterString =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					partyAction: this.partyAction,
					adjustingCompanyId: adjustingCompanyId,
					adjustingCompanyUserId: adjustingCompanyUserId
				});
	}

	/**
	 * This will set the parameters needed for the manage vendor
	 * workflow action.
	 *
	 * @param {number} vendorCompanyId
	 * The vendor company id.
	 * @param {string} vendorType
	 * The vendor type.
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private setVendorActionParameters(
		vendorCompanyId: number,
		vendorType: string): void
	{
		this.workflowActionName =
			ClaimConstants.workflowActions.manageVendor;

		this.parameterString =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					partyAction: this.partyAction,
					vendorCompanyId: vendorCompanyId,
					vendorType: vendorType
				});
	}

	/**
	 * This will navigate to the claim summary dashboard the claim id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private async navigateToClaimSummary(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof ManagePartiesConfirmationClaimComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					type: FormlyConstants.customControls.customTextDisplay,
					props: {
						centerText: true,
						useMarkDown: true,
						usePanelDisplay: false,
						content: await this.partyDetails
					}
				}
			];
	}
}