/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Directive,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	MouseEventConstants
} from '@shared/constants/mouse-event.constants';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTablePageDirective
} from '@shared/interfaces/application-objects/common-table-page-directive.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	MenuItem
} from 'primeng/api';
import {
	Subscription
} from 'rxjs';

/* eslint-enable max-len */

@Directive({
	selector: '[CommonTablePage]'
})

/**
 * A directive representing shared logic for a component displaying
 * an admin page component.
 *
 * @export
 * @class CommonTablePageDirective
 * @implements {ICommonTablePageDirective}
 * @implements {OnInit}
 */
export class CommonTablePageDirective
implements ICommonTablePageDirective, OnInit, OnDestroy
{
	/**
	 * Creates an instance of an admin page directive.
	 *
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof CommonTablePageDirective
	 */
	public constructor(
		public resolver: ResolverService)
	{
	}

	/**
	 * Gets or sets the api service used to capture data. This service is saved
	 * during the initial load and used on subsequent loads. If the
	 * loadListData method is overrode in the implementing component, this
	 * interface is not required.
	 *
	 * @type {any}
	 * @memberof CommonTablePageDirective
	 */
	public apiService: any;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof CommonTablePageDirective
	 */
	public availableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof CommonTablePageDirective
	 */
	public selectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the menu items for page actions that will be
	 * displayed in the page containing this admin page.
	 *
	 * @type {MenuItem[]}
	 * @memberof CommonTablePageDirective
	 */
	public pageActions: MenuItem[] = [];

	/**
	 * Gets or sets the common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof CommonTablePageDirective
	 */
	public commonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the table row count.
	 *
	 * @type {number}
	 * @memberof CommonTablePageDirective
	 */
	public tableRowCount: number = 15;

	/**
	 * Gets or sets the table filter query.
	 *
	 * @type {string}
	 * @memberof CommonTablePageDirective
	 */
	public tableFilterQuery: string = AppConstants.empty;

	/**
	 * Gets or sets the loading table definitions state.
	 *
	 * @type {boolean}
	 * @memberof CommonTablePageDirective
	 */
	public loadingTableDefinitions: boolean = true;

	/**
	 * Gets or sets the subscriptions used in this component.
	 *
	 * @type {Subscription}
	 * @memberof CommonTablePageDirective
	 */
	public subscriptions: Subscription = new Subscription();

	/**
	 * Gets the page context sent to associated context utilities
	 * and menus.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof CommonTablePageDirective
	 */
	public get pageContext(): IDynamicComponentContext<Component, any>
	{
		return <IDynamicComponentContext<Component, any>> {
			source: this
		};
	}

	/**
	 * Implements the on initialization interface.
	 * This method is used to load list data and to set the admin page row
	 * expand component to be displayed.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public ngOnInit(): void
	{
		this.setupPage();
	}

	/**
	 * Implements the on destroy interface.
	 * This method is used to clean up any page based subscriptions.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	/**
	 * Sets up the full page set of display items.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public setupPage(): void
	{
		this.setupPageVariables();
		this.setupTableDefinitions();
		this.setupInformationMenuItems();
		this.setupPageCharts();
		this.setupPageActions();
	}

	/**
	 * Resets pulled data filters to latest and refreshes
	 * related diplays.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public refreshDataQueries(): void
	{
		this.setupPageVariables();
		this.setupTableDefinitions();
		this.setupInformationMenuItems();
		this.setupPageCharts();
	}

	/**
	 * Sets up the array of information menu items for the current
	 * admin page.
	 * This must be overrode in the implementing class.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public setupInformationMenuItems(): void
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets up the array of charts to be displayed in the current
	 * admin page.
	 * This must be overrode in the implementing class.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public setupPageCharts(): void
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets up the table definitions for the current admin page.
	 * This must be overrode in the implementing class.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public setupTableDefinitions(): void
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets up the page actions to display in an operation button bar to
	 * interact with data in this admin page.
	 *
	 * This must be overrode in the implementing class.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public setupPageActions(): void
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets up variables used in this admin page based filter.
	 * This must be overrode in the implementing class.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public setupPageVariables(): void
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Handles a mobile specific pan event to ensure that tooltips
	 * are displayed one at a time for all touch actions.
	 *
	 * @memberof CommonTablePageDirective
	 */
	public closeChartTooltips(): void
	{
		const mouseOutEvent =
			new MouseEvent(
				MouseEventConstants.mouseOut);

		document.querySelectorAll(
			ChartConstants.chartClasses.canvasElement)
			.forEach(
				(canvasElement: Element) =>
				{
					canvasElement.dispatchEvent(mouseOutEvent);
				});
	}
}