/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommissionDisbursementStatus
} from '@shared/constants/enums/commission-disbursment-status.enum';
import {
	CommonChildListDirective
} from '@shared/directives/common-child-list-directive';
import {
	Component
} from '@angular/core';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	SecurityRightCategory
} from '@shared/constants/enums/security-right-category.enum';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';

@Component({
	selector: 'app-commissions-list',
	templateUrl: './commissions-list.component.html',
	styleUrls: [
		'./commissions-list.component.scss'
	]
})

/**
 * A component representing commission disbursement item list view.
 *
 * @export
 * @class CommisionsListComponent
 * @extends {CommonChildListDirective}
 * @implements {IDynamicComponent<Component, any>}
 */
export class CommissionsListComponent
	extends CommonChildListDirective
	implements IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the commission list component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Router} router
	 * The router for navigation.

	 * @memberof CommisionsListComponent
	 */
	public constructor(
		public entityservice: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public router: Router)
	{
		super(
			entityservice,
			entityTypeApiService,
			entityInstanceApiService);
	}

	/**
	 * Gets or sets the list of sorts available in this list component.
	 *
	 * @type {ICommonListSort[]}
	 * @memberof CommisionsListComponent
	 */
	public sorters: ICommonListSort[] =
		<ICommonListSort[]>
		[
			<ICommonListSort>
			{
				name: 'Create Date',
				value: AppConstants.commonProperties.id,
				direction: AppConstants.sortDirections.descending,
				iconAsc: AppConstants.cssClasses.sortAscending,
				iconDesc: AppConstants.cssClasses.sortDescending
			}
		];

	/**
	 * Gets the listy of users who created teh commission disbursements.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof CommisionsListComponent
	 */
	public createdByList: IEntityInstance[] = [];

	/**
	 * Gets the search  flag that indicates that the filter from
	 * the search input.
	 *
	 * @type {string}
	 * @memberof CommisionsListComponent
	 */
	private readonly searchFlag: string = 'Search';

	/**
	 * Performs data operation that need to happen before mapping.
	 *
	 * @type {Promise<void>}
	 * @returns
	 * An empty promise.
	 * @member CommisionsListComponent
	 */
	public async performPreMappingRequirements(): Promise<void>
	{
		await this.setCreatableEntityTypes(this.wildcardChildFilter);

		if (AnyHelper.isNullOrEmptyArray(this.listData))
		{
			return;
		}

		const createdByIds: string[] =
			this.listData.map((item: any) =>
				`${item.createdById}`);

		const createdByFilter: string =
			AppConstants.commonProperties.id
				+ ' in ('
				+ ApiFilterHelper.commaSeparatedStringValues(
					createdByIds,
					AppConstants.empty)
				+ ')';

		this.createdByList =
			await this.getFullCreatorDataSet(createdByFilter);
	}

	/**
	 * Generates a common list context that can be consumed by a common list
	 * displayed in this component.
	 *
	 * @param {ICommonListItem<IEntityInstance>[]} data
	 * The collection of data that will be displayed in this list.
	 * @returns {ICommonListContext<IEntityInstance>}
	 * The common list context to display.
	 * @memberof CommisionsListComponent
	 */
	public generateCommonListContext(
		data: ICommonListItem<IEntityInstance>[]):
		ICommonListContext<IEntityInstance>
	{
		const listFilters: ICommonListFilter[] =
			<ICommonListFilter[]>
			[
				{
					name: CommissionDisbursementStatus.Pending,
					value:
						`status eq "${CommissionDisbursementStatus.Pending}"`
				},
				{
					name: CommissionDisbursementStatus.Approved,
					value:
						`status eq "${CommissionDisbursementStatus.Approved}"`
				},
				{
					name: CommissionDisbursementStatus.Rejected,
					value:
						`status eq "${CommissionDisbursementStatus.Rejected}"`
				}
			];

		const generatedCommonListContext:
			ICommonListContext<IEntityInstance> =
				<ICommonListContext<IEntityInstance>>
				{
					data: data,
					supportMarkdown: true,
					searchable: true,
					sortable: true,
					sorters: this.sorters,
					searchFilterFormat: this.searchFlag,
					actions: this.creatableEntityTypes?.length > 0
						? [
							<ISecureMenuItem>
							{
								icon: 'fa fa-plus-circle',
								command: async (event: any) => {
									await this.navigateToWizard();
									event.stopPropagation();
								},
								securityRightCategory:
									SecurityRightCategory.Action,
								securityRightPath:
									AppConstants.apiMethods.create,
								securityRightType:
									SecurityRightType.execute
							}
						]
						: [],
					filters: listFilters,
					onFilterChange: (source, filters) =>
						this.filterChanged(source, filters),
					onSortChange: (source, sorter) =>
						this.handleSortingChange(source, sorter)
				};

		return generatedCommonListContext;
	}

	/**
	 * Navigates to the create wizard.
	 *
	 * @type {Promise<void>}
	 * @returns
	 * An empty promise.
	 * @member CommisionsListComponent
	 */
	public async navigateToWizard(): Promise<void>
	{
		const organization: IEntityInstance =
			(<EntityInstanceComponent>this.context.source).entityInstance;

		const entityType: IEntityType =
			(<EntityInstanceComponent>this.context.source).entityType;

		const activeExists: boolean = await this.activeDisbursementExists();

		this.router.navigate(
			[
				`${AppConstants.moduleNames.organizations}/entities`
					+ '/display/'
					+ 'BasePage.Wizard.InsuranceCompany'
					+ '.CreateCommissionDisbursement/'
					+ AppConstants.viewTypes.create
			],
			{
				skipLocationChange: true,
				queryParams:
				{
					routeData:
						ObjectHelper.mapRouteData(
							{
								data: {
									organizationName:
									organization.data.name.legalName,
									organizationId: organization.id,
									organizationType: entityType.name,
									organizationGroup: entityType.group,
									activeSnapshotExists: activeExists,
									cancelRoute: this.router.url
								}
							})
				}
			});
	}

	/**
	 * Determines if there is an existing disbursement pending.
	 *
	 * @type {Promise<boolean>}
	 * @returns
	 * The result true if there are pending disbursements.
	 * @member CommisionsListComponent
	 */
	public async activeDisbursementExists(): Promise<boolean>
	{
		const organization: IEntityInstance =
			(<EntityInstanceComponent>this.context.source).entityInstance;

		const entityType: IEntityType =
			(<EntityInstanceComponent>this.context.source).entityType;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			entityType.group;

		const activeDisbursements = await this.entityInstanceApiService
			.getChildren(
				organization.id,
				`status eq "${CommissionDisbursementStatus.Pending}"`,
				'Id Desc',
				0,
				1,
				InsuranceConstants
					.insuranceEntityTypeGroups
					.commissionDisbursements);

		return activeDisbursements.length > 0;
	}

	/**
	 * Gets a full data set of creators.
	 *
	 * @type {Promise<IEntityInstance[]>}
	 * @returns
	 * all creators of the disbursments.
	 * @member CommisionsListComponent
	 */
	public getFullCreatorDataSet(
		createdByFilter: string): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;

		return ApiHelper.getFullDataSet(
			this.entityInstanceApiService,
			createdByFilter,
			AppConstants.empty);
	}

	/**
	 * Maps and returns a list item that represents
	 * a commission disbursement instance.
	 *
	 * @param {IEntityInstance} entityInstance
	 * The entity instance to be mapped into a list item.
	 * @returns {ICommonListItem<IEntityInstance>}
	 * The common list item to display that represents this entity instance.
	 * @memberof CommisionsListComponent
	 */
	public mapToListItem(
		entityInstance: IEntityInstance):
		ICommonListItem<IEntityInstance>
	{
		const createDate: string =
			DateHelper.formatDate(
				DateTime.fromISO(
					entityInstance.createDate),
				DateHelper.presetFormats.longDateFormat);

		const decisionDate: string =
			DateHelper.formatDate(
				DateTime.fromISO(
					entityInstance.data.decisionDate),
				DateHelper.presetFormats.longDateFormat);

		const createdBy: IEntityInstance =
			this.createdByList.find(
				item => item.id === entityInstance.createdById);

		const decidedBy: IEntityInstance =
			AnyHelper.isNullOrEmpty(entityInstance.data.decidedBy)
				? null
				: this.createdByList.find(
					(user: IEntityInstance) =>
						user.resourceIdentifier
							=== entityInstance.data.decidedBy);

		const createdByName =
			StringHelper.toNameString(
				createdBy.data.firstName,
				createdBy.data.lastName);

		const decidedByName = AnyHelper.isNull(decidedBy)
			? null
			: StringHelper.toNameString(
				decidedBy.data.firstName,
				decidedBy.data.lastName);

		let statusColor: string = AppConstants.empty;
		switch (entityInstance.data.status) {

			case CommissionDisbursementStatus.Pending:
				break;

			case CommissionDisbursementStatus.Approved:
				statusColor = AppConstants.cssClasses.textSuccessColor;
				break;

			case CommissionDisbursementStatus.Rejected:
				statusColor = AppConstants.cssClasses.textErrorColor;
				break;
		}

		return <ICommonListItem<IEntityInstance>>
			{
				item: entityInstance,
				descriptionLineFormat:
					`<i class="fa fa-fw fa-trophy ${statusColor} `
					+ 'item-description-icon"></i>'
					+ `**${entityInstance.data.status} Disbursement**<br>`
					+ (entityInstance.data.status ===
							CommissionDisbursementStatus.Pending
						? `Create Date: ${createDate}`
						: `Decision Date: ${decisionDate}`),
				informationLineFormat:
					AnyHelper.isNull(decidedBy)
						? `Created By: ${createdByName}`
						: `Decision By: ${decidedByName}`,
				informationIcons: [],
				actions: SecurityHelper.scrubMenuItems(
					[
						<ISecureMenuItem>
						{
							icon: 'fa fa-pencil',
							command: (event: any) => {
								this.navigateToComissionDisbursementPage(
									entityInstance.id);
								event.stopPropagation();
							},
							securityRightCategory: SecurityRightCategory.Action,
							securityRightPath: AppConstants.apiMethods.update
						}
					],
					entityInstance.entityType,
					this.securityDefinitions)
			};
	}

	/**
	 * Navigates to the the commission disbursement page.
	 *
	 * @param {number} id
	 * The id of the commission disbursement.
	 * @memberof CommisionsListComponent
	 */
	private navigateToComissionDisbursementPage(
		id: number)
	{
		const typeGroup: string =
			InsuranceConstants
				.insuranceEntityTypeGroups
				.commissionDisbursements;

		this.router.navigate(
			[
				`${AppConstants.moduleNames.organizations}/entities`
					+ `/${typeGroup}/`
					+ AppConstants.viewTypes.edit
					+ '/' + id
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the filter changed event.
	 *
	 * @param {any} source
	 * The context sourse.
	 * @param { ICommonListFilter[]} filters
	 * the filters.
	 *
	 * @memberof CommisionsListComponent
	 */
	private filterChanged(
		source: any,
		filters: ICommonListFilter[])
	{
		const searchfilters = filters
			.filter((filter: ICommonListFilter) =>
				filter.value === this.searchFlag);

		for (const filter of searchfilters) {
			const searchTerm: string =
				filter.name.toUpperCase();

			const createdByMatch =
				this.createdByList
					.find(creator => {
						const textToSearch: string =
							`${creator.data.userName} `
							+ `${creator.data.firstName} `
							+ `${creator.data.lastName} `;

						const matchIndex: number =
							textToSearch
								.toUpperCase()
								.search(searchTerm);

						return matchIndex > -1;
					});

			filter.value =
				AnyHelper.isNullOrEmpty(createdByMatch)
					? 'CreatedById eq 0'
					: `CreatedById eq ${createdByMatch.id}`;
		}

		this.handleFilterChange(
			source,
			filters);
	}
}