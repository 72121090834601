/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Injectable
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WorkItemConstants
} from '@shared/constants/work-item-constants';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

/**
 * A singleton service representing shared interactions with the work item
 * schema api.
 *
 * @export
 * @class WorkItemService
 */
@Injectable({
	providedIn: 'root'
})
export class WorkItemService
{
	/**
	 * Creates an instance of a work item service.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance service used for lookups in this service.
	 * @memberof WorkItemService
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Given an entity instance id, this will look for a matching work
	 * item as a child of this instance.
	 *
	 * @async
	 * @param {number} instanceId
	 * The instance id to be checked for an active child work item.
	 * @param {string} instanceEntityType
	 * The instance entity type to be checked for an active child work item.
	 * @param {string} workItemInstanceName
	 * The work item instance name to check for a child instance match.
	 * @param {string[]} allowedStatusValues
	 * The status values that should be allowed as a specific match.
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not the sent work item exists as a child
	 * of this instance and is active.
	 * @memberof WorkItemService
	 */
	public async workItemChildExists(
		instanceId: number,
		instanceEntityType: string,
		workItemInstanceEntityType: string,
		allowedStatusValues: string[] = [
			WorkItemConstants.workItemStatus.active
		]): Promise<boolean>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			instanceEntityType;
		const workItemInstances: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				instanceId,
				null,
				null,
				null,
				AppConstants.dataLimits.large,
				workItemInstanceEntityType);

		return workItemInstances.length > 0
			&& workItemInstances.filter(
				(workItemInstance: IEntityInstance) =>
					allowedStatusValues
						.indexOf(workItemInstance.data.status) !== -1)
				.length > 0;
	}
}