/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	InjectionToken
} from '@angular/core';
import {
	ClaimsService
} from '@claims/services/claims.service';

/*
 * Export all tokens to be used in the dependency injector for
 * string based resolution
*/
export const claimsServiceToken =
	new InjectionToken<ClaimsService>(
		'ClaimsService');

/**
 * A lookup to be shared accross the application used
 * for classes available in the shared module.
 *
 * @export
 * @class ClaimsTokenLookup
 */
export class ClaimsTokenLookup
{
	/**
	 * Dictionary to be used in dependency injector for
	 * string based resolution.
	 *
	 * @static
	 * @memberof ClaimsTokenLookup
	 */
	public static tokens =
		{
			'ClaimsService':
			claimsServiceToken
		};
}