<p-password inputStyleClass="password-input"
	autocomplete="new-password"
	[formControl]="formControl"
	[formlyAttributes]="field"
	[toggleMask]="enableMaskToogle">
	<ng-template pTemplate="header">
		<div class="panel-sub-title">Pick a password</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<p class="suggestions-title">Suggestions</p>
		<ul class="suggestions-section">
			<li>At least {{getSecurityRestrictions()?.lowercase}} lowercase</li>
			<li>At least {{getSecurityRestrictions()?.uppercase}} uppercase</li>
			<li>At least {{getSecurityRestrictions()?.number}} numeric</li>
			<li>At least{{getSecurityRestrictions()?.special}} special <strong>{{getPasswordSpecialCharacters()}}</strong></li>
			<li>Minimum {{getSecurityRestrictions()?.minimumLength}} characters</li>
			<li>Maximum {{getSecurityRestrictions()?.maximumLength}} characters</li>
			<li>Allowed Password Reuse: {{getSecurityRestrictions()?.allowPasswordReuse}}</li>
		</ul>
	</ng-template>
</p-password>

<field-validation-message
	[field]="field">
</field-validation-message>