/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Injectable,
	OnDestroy
} from '@angular/core';
import {
	AppUtilityMenuComponent
} from '@appComponents/app-utility-menu/app-utility-menu.component';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IChatService
} from '@shared/interfaces/services/chat-service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	interval,
	Subject,
	Subscription,
	takeUntil
} from 'rxjs';

/* eslint-enable max-len */

/**
 * A singleton service representing the ability to interact with the Genesys
 * chat system.
 *
 * @export
 * @class GenesysChatService
 * @implements {IChatService}
 * @implements {OnDestroy}
 */
@Injectable({
	providedIn: 'root'
})
export class GenesysChatService
implements IChatService, OnDestroy
{
	/**
	 * Creates an instance of Genesys chat service.
	 *
	 * @param {SessionService} sessionService
	 * The session service used to interact with the session.
	 * @memberof GenesysChatService
	 */
	public constructor(
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets a boolean signifying whether the chat is opened.
	 *
	 * @type {boolean}
	 * @memberof GenesysChatService
	 */
	public chatOpened: boolean = false;

	/**
	 * Gets or sets the Genesys deployment id.
	 *
	 * @type {string}
	 * @memberof GenesysChatService
	 */
	public deploymentId: string;

	/**
	 * Gets or sets the Genesys environment.
	 *
	 * @type {string}
	 * @memberof GenesysChatService
	 */
	public environment: string;

	/**
	 * Gets or sets the subject observable for installations.
	 *
	 * @type {Subject<void>}
	 * @memberof GenesysChatService
	 */
	public installSubject: Subject<void> =
		new Subject<void>();

	/**
	 * Gets or sets the subscription for installations.
	 *
	 * @type {Subscription}
	 * @memberof GenesysChatService
	 */
	public installSubscription: Subscription =
		new Subscription();

	/**
	 * Gets or sets the context of this dynamic service that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof GenesysChatService
	 */
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets the id for the Genesys messenger element.
	 *
	 * @type {string}
	 * @memberof GenesysChatService
	 */
	private readonly messengerElementId: string =
		'#genesys-messenger';

	/**
	 * Gets the name for the Genesys messenger custom attributes data location.
	 *
	 * @type {string}
	 * @memberof GenesysChatService
	 */
	private readonly customAttributesDataName: string =
		'messaging.customAttributes';

	/**
	 * Gets the Genesys commands that can be executed.
	 *
	 * @type {{
	 *	command: string;
	 *	subscribe: string;
	 * }}
	 * @memberof GenesysChatService
	 */
	private readonly genesysCommands: {
		command: string;
		subscribe: string;
	} = {
			command: 'command',
			subscribe: 'subscribe'
		};

	/**
	 * Gets the Genesys events that can be listened or ran to.
	 *
	 * @type {{
	 *	cobrowseReady: string;
	 *	cobrowseSessionStarted: string;
	 *	cobrowseSessionEnded: string;
	 *	conversationClosed: string;
	 *	conversationOpened: string;
	 *	conversationStarted: string;
	 *	getData: string;
	 *	hideLauncher: string;
	 *	launcherReady: string;
	 *	messengerClear: string;
	 *	messengerCleared: string;
	 *	messengerClose: string;
	 *	messengerClosed: string;
	 *	messengerOpen: string;
	 *	messengerOpened: string;
	 *	messengerReady: string;
	 *	removeData: string;
	 *	setData: string;
	 *	updateData: string;
	 * }}
	 * @memberof GenesysChatService
	 */
	private readonly genesysEvents: {
		cobrowseReady: string;
		cobrowseSessionStarted: string;
		cobrowseSessionEnded: string;
		conversationClosed: string;
		conversationOpened: string;
		conversationStarted: string;
		getData: string;
		hideLauncher: string;
		launcherReady: string;
		messengerClear: string;
		messengerCleared: string;
		messengerClose: string;
		messengerClosed: string;
		messengerOpen: string;
		messengerOpened: string;
		messengerReady: string;
		removeData: string;
		setData: string;
		updateData: string;
	} = {
			cobrowseReady: 'CobrowseService.ready',
			cobrowseSessionStarted: 'CobrowseService.sessionStarted',
			cobrowseSessionEnded: 'CobrowseService.sessionEnded',
			conversationClosed: 'Conversations.closed',
			conversationStarted: 'Conversations.started',
			conversationOpened: 'Conversations.opened',
			getData: 'Database.get',
			hideLauncher: 'Launcher.hide',
			launcherReady: 'Launcher.ready',
			messengerClear: 'Messenger.clear',
			messengerCleared: 'Messenger.cleared',
			messengerClose: 'Messenger.close',
			messengerClosed: 'Messenger.closed',
			messengerOpen: 'Messenger.open',
			messengerOpened: 'Messenger.opened',
			messengerReady: 'Messenger.ready',
			removeData: 'Database.remove',
			setData: 'Database.set',
			updateData: 'Database.update'
		};

	/**
	 * Gets the script for genesys.
	 * See: https://developer.genesys.cloud/.
	 *
	 * @type {string}
	 * @memberof GenesysChatService
	 */
	private readonly genesysScript: string =
		`(function (g, e, n, es, ys)
		{
			try
			{
				g['_genesysJs'] = e;
				g[e] = g[e] || function () {
					(g[e].q = g[e].q || []).push(arguments)
				};
				g[e].t = 1 * new Date();
				g[e].c = es;
				ys = document.createElement('script');
				ys.async = 1;
				ys.src = n;
				ys.charset = 'utf-8';
				document.head.appendChild(ys);
			}
			catch (exception)
			{
				// Do nothing, error expected when logging out quickly.
			}
		})(window,
			'Genesys',
			'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js',
			{
				deploymentId: '{{deploymentId}}',
				environment: '{{environment}}'
			});`;

	/**
	 * Destroys the instance of the Genesys chat service.
	 * Unsubscribes from all subscriptions.
	 * Completes all subjects.
	 *
	 * @memberof GenesysChatService
	 */
	public ngOnDestroy(): void
	{
		this.installSubscription.unsubscribe();

		this.installSubject.next();
		this.installSubject.complete();
	}

	/**
	 * Initializes the chat.
	 *
	 * @async
	 * @memberof GenesysChatService
	 */
	public async initializeChat(): Promise<void>
	{
		await this.installChat();

		await new Promise(
			(resolve: (value?: boolean) => void) =>
			{
				window.Genesys(
					this.genesysCommands.subscribe,
					this.genesysEvents.messengerReady,
					() =>
					{
						this.handleMessengerEvents();
						this.handleConversationEvents();

						resolve();
					});
			});
	}

	/**
	 * Opens the modal chat.
	 *
	 * @param {boolean} messengerCloseEvent
	 * A boolean signifying whether this event came from a messenger
	 * subscription. This value defaults to false.
	 * @memberof GenesysChatService
	 */
	public openModalChat(
		messengerCloseEvent: boolean = false): void
	{
		if (AnyHelper.isNull(window.Genesys))
		{
			// Perform no actions, if not initialized.
			return;
		}

		if (messengerCloseEvent === false)
		{
			this.displayChat(
				true);
		}

		if (this.chatOpened === true
			&& messengerCloseEvent === false)
		{
			return;
		}

		window.Genesys(
			this.genesysCommands.command,
			this.genesysEvents.messengerOpen);
	}

	/**
	 * closes the modal chat.
	 *
	 * @memberof GenesysChatService
	 */
	public closeModalChat(): void
	{
		if (AnyHelper.isNull(window.Genesys))
		{
			// Perform no actions, if not initialized.
			return;
		}

		this.displayChat(
			false);
	}

	/**
	 * Closes any current conversations and resets chat.
	 *
	 * @async
	 * @memberof GenesysChatService
	 */
	public async resetChat(): Promise<void>
	{
		if (AnyHelper.isNull(window.Genesys))
		{
			// Perform no actions, if never installed.
			return;
		}

		await new Promise(
			(resolve: (value?: boolean) => void) =>
			{
				window.Genesys(
					this.genesysCommands.subscribe,
					this.genesysEvents.messengerCleared,
					function()
					{
						this.displayChat(
							false);

						resolve();
					}.bind(this));

				window.Genesys(
					this.genesysCommands.command,
					this.genesysEvents.messengerClear);
			});
	}

	/**
	 * Positions the modal content.
	 *
	 * @param {number} drawerIndex
	 * The drawer index to position the modal content in.
	 * @memberof GenesysChatService
	 */
	public positionModalContent(
		drawerIndex: number): void
	{
		const drawerPrefix: string =
			'drawer-';
		const drawerSuffix: string =
			'-display';
		const chatElement: HTMLElement =
			document.querySelector(
				this.messengerElementId);

		if (AnyHelper.isNull(chatElement))
		{
			return;
		}

		for(let index = 0;
			index < AppUtilityMenuComponent.maximumPinnableDrawerCount;
			index++)
		{
			chatElement.classList.remove(
				drawerPrefix
					+ StringHelper.numberToWords(
						index.toString())
					+ drawerSuffix);
		}

		chatElement.classList.add(
			drawerPrefix
				+ StringHelper.numberToWords(
					drawerIndex.toString())
				+ drawerSuffix);
	}

	/**
	 * Installs the chat.
	 *
	 * @async
	 * @memberof GenesysChatService
	 */
	private async installChat(): Promise<void>
	{
		const genesys: Function =
			window.Genesys;

		if (!AnyHelper.isNull(genesys))
		{
			return;
		}

		const script: HTMLScriptElement =
			<HTMLScriptElement>document.createElement(
				AppConstants.documentElementTypes.script);
		script.type = AppConstants.scriptTypes.javascript;
		script.text =
			this.genesysScript
				.replace(
					'{{deploymentId}}',
					this.sessionService.systemSettings
						.chat
						.deploymentId)
				.replace(
					'{{environment}}',
					this.sessionService.systemSettings
						.chat
						.environment);
		document.head.appendChild(script);

		await new Promise(
			(resolve: (value?: unknown) => void) =>
			{
				this.installSubscription =
					interval(AppConstants.time.fiftyMilliseconds)
						.pipe(
							takeUntil(this.installSubject))
						.subscribe(
							{
								next:
									() =>
									{
										if (AnyHelper.isNull(window.Genesys))
										{
											return;
										}

										this.installSubject.next();
										this.installSubject.complete();
									},
								complete:
									() =>
									{
										resolve();
									}
							});
			});
	}

	/**
	 * Displays or hides the chat.
	 *
	 * @param {boolean} display
	 * A boolean signifying whether chat should be displayed. This value
	 * defaults to true.
	 * @memberof GenesysChatService
	 */
	private displayChat(
		display: boolean = true): void
	{
		const elementsToAlter: HTMLElement[] =
			[
				document.querySelector(
					this.messengerElementId)
			];

		elementsToAlter.forEach(
			(element: HTMLElement) =>
			{
				if (AnyHelper.isNull(element))
				{
					return;
				}

				element.classList.remove(
					display === true
						? AppConstants.cssClasses.hidden
						: AppConstants.cssClasses.visible);
				element.classList.add(
					display === true
						? AppConstants.cssClasses.visible
						: AppConstants.cssClasses.hidden);
			});

		EventHelper.dispatchChatModalDisplayedEvent(
			display);
	}

	/**
	 * Handles the messenger events.
	 *
	 * @memberof GenesysChatService
	 */
	private handleMessengerEvents(): void
	{
		window.Genesys(
			this.genesysCommands.subscribe,
			this.genesysEvents.messengerOpened,
			() =>
			{
				this.chatOpened = true;
			});

		window.Genesys(
			this.genesysCommands.subscribe,
			this.genesysEvents.messengerClosed,
			() =>
			{
				this.chatOpened = false;
			});
	}

	/**
	 * Handles the conversation events.
	 *
	 * @memberof GenesysChatService
	 */
	private handleConversationEvents(): void
	{
		window.Genesys(
			this.genesysCommands.subscribe,
			this.genesysEvents.conversationOpened,
			async() =>
			{
				// Always set non context based chat data.
				await this.setData(
					this.getInitialChatData());
			});

		window.Genesys(
			this.genesysCommands.subscribe,
			this.genesysEvents.conversationStarted,
			async() =>
			{
				// Clear existing conversation data.
				await this.removeData();

				// Update decorated chat data on conversation start.
				await this.setData(
					await this.getDecoratedChatData());
			});

		window.Genesys(
			this.genesysCommands.subscribe,
			this.genesysEvents.conversationClosed,
			() =>
			{
				// Handle closed conversations from clear chat in the Messenger
				// by re-opening the messenger.
				this.openModalChat(
					true);
			});
	}

	/**
	 * Sets the data for the chat.
	 *
	 * @async
	 * @param {object} data
	 * The data to set.
	 * @memberof GenesysChatService
	 */
	private async setData(
		data: object): Promise<void>
	{
		await new Promise(
			(resolve: (value?: boolean) => void) =>
			{
				window.Genesys(
					this.genesysCommands.command,
					this.genesysEvents.updateData,
					{
						messaging: {
							customAttributes: data
						}
					},
					() =>
					{
						resolve();
					});
			});
	}

	/**
	 * Removes data from the chat.
	 *
	 * @async
	 * @memberof GenesysChatService
	 */
	private async removeData(): Promise<void>
	{
		await new Promise(
			(resolve: (value?: any) => void) =>
			{
				window.Genesys(
					this.genesysCommands.command,
					this.genesysEvents.removeData,
					{
						name: this.customAttributesDataName
					},
					() =>
					{
						resolve();
					});
			});
	}

	/**
	 * Gets the chat data to map into the message based on initialization of
	 * chat.
	 *
	 * @async
	 * @returns {Promise<object>}
	 * The chat data.
	 * @memberof GenesysChatService
	 */
	private async getDecoratedChatData(): Promise<object>
	{
		let chatData: any =
			this.getInitialChatData();

		chatData.route = window.location.href;

		if (AnyHelper.isNull(this.context?.source)
			|| !(this.context.source instanceof EntityInstanceComponent))
		{
			return chatData;
		}

		chatData.entityId = this.context.source.entityInstance.id;
		chatData.entityType = this.context.source.entityType.name;

		if (AnyHelper.isNull(
			this.context.source.entityDefinition.chatDataPromise))
		{
			return chatData;
		}

		chatData =
			{
				...chatData,
				...await StringHelper.transformToDataPromise(
					this.context.source.entityDefinition.chatDataPromise,
					this.context)
			};

		return chatData;
	}

	/**
	 * Gets the initial chat data.
	 *
	 * @returns {any}
	 * The initial chat data.
	 * @memberof GenesysChatService
	 */
	private getInitialChatData(): any
	{
		const defaultChatData: any =
			{
				name:
					StringHelper.toNameString(
						this.sessionService.user.data.firstName,
						this.sessionService.user.data.lastName)
			};

		return defaultChatData;
	}
}