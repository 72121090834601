<span>
	<div class="ui-g-12 no-padding">
		<span class="center-spinner loading-spinner theme-color fa fa-fw fa-spin fa-spinner"
			*ngIf="loading">
		</span>
		<div class="section-margin"
			*ngIf="!loading">
			<app-dynamic-formly
				[dataSet]="context.source.selectedItem"
				[layoutSchema]="formlyLayoutDefinition"
				[context]="context"
				(validityChanged)="context.source.validExpandComponentChanged($event)">
			</app-dynamic-formly>
		</div>
	</div>
</span>
