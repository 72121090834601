/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	Directive,
	Input
} from '@angular/core';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	MenuItem
} from 'primeng/api';

/**
 * A base class used to ensure the shared operation based
 * actions and child action contracts are met.
 *
 * @export
 * @class BaseOperationMenuDisplayDirective
 */
@Directive()
export class BaseOperationMenuDisplayDirective
{
	/**
	 * Inherited constructor for initializing an implemented
	 * base operation menu display component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used for responsive layouts.
	 * @memberof BaseOperationMenuDisplayDirective
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the value used to reset the
	 * operation menu items.
	 *
	 * @type {boolean}
	 * @memberof BaseOperationMenuDisplayDirective
	 */
	@Input() public reset: boolean;

	/**
	 * Gets the base model used to display the operation
	 * group. This item will display prior to the data
	 * load of the operation group.
	 *
	 * @type {MenuItem[]}
	 * @memberof BaseOperationMenuDisplayDirective
	 */
	public initialModel: MenuItem[] = [];
}