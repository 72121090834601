/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A constant library used to defined available entity
 * emitted events that can be consumed in listening components
 * via the host listener.
 *
 * @export
 * @const EntityEventConstants
 */
export const EntityEventConstants:
{
	enableEntitySave: string;
	enableEntitySaveEvent: string;
	calculateEntityGrid: string;
	calculateEntityGridEvent: string;
	setSearchFilterFocus: string;
	setSearchFilterFocusEvent: string;
} = {
	enableEntitySave:
		'enable-entity-save-event',
	enableEntitySaveEvent:
		'window:enable-entity-save-event',
	calculateEntityGrid:
		'calculate-entity-grid-event',
	calculateEntityGridEvent:
		'window:calculate-entity-grid-event',
	setSearchFilterFocus:
		'set-search-filter-focus-event',
	setSearchFilterFocusEvent:
		'window:set-search-filter-focus-event'
};