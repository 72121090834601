<div *ngIf="field.props.loadingTableDefinitions"
	class="text-center ui-g-12">
	<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
	</span>
</div>

<div *ngIf="!field.props.loadingTableDefinitions"
	class="ui-g-12 no-padding">
	<app-common-table
		[tableDefinitions]="field.props.tableDefinitions"
		[fieldContext]="field.props.context"
		[field]="field"
		(fireFormlyChangeDetection)="fireChanges()"
		(itemSelected)="itemSelected($event)">
	</app-common-table>
</div>