/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component,
	HostListener,
	Injectable,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	StorageMap
} from '@ngx-pwa/local-storage';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	ClientMessage
} from '@shared/implementations/application-data/client-message';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	ReallySimpleSyndicationFeedReaderService
} from '@shared/services/really-simple-syndication-feed-reader.service';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	SessionRefreshService
} from '@shared/services/session-refresh.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	WeatherForecastService
} from '@shared/services/weather-forecast.service';
import {
	DateTime,
	Settings
} from 'luxon';
import {
	Message
} from 'primeng/api';
import {
	AppConfig
} from 'src/app/app.config';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: [
		'./login.component.scss'],
	animations: [
		ContentAnimation
	]
})

/**
 * A class instance of the login component.
 *
 * @export
 * @class LoginComponent
 * @implements {OnInit}
 */
@Injectable()
export class LoginComponent
implements OnInit
{
	/**
	 * Creates an instance of LoginComponent.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used in this login component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {StorageMap} storageMap
	 * The storage map that is cleared on logout and login.
	 * @param {CacheService} cacheService
	 * The cache service that is cleared on logout and login.
	 * @param {DisplayComponentService} displayComponentService
	 * The display component service that is cleared on logout and login.
	 * @param {OperationService} operationService
	 * The operation service that is cleared on logout and login.
	 * @param {RuleService} ruleService
	 * The rule service that is cleared on logout and login.
	 * @param {EntityService} entityService
	 * The entity service that is cleared on logout and login.
	 * @param {PowerBiApiService} powerBiApiService
	 * The power bi api service that is cleared on logout and login.
	 * @param {SessionService} sessionService
	 * The sessions service used in this login component.
	 * @param {SessionRefreshService} sessionRefreshService
	 * The service that monitors for application updates.
	 * @param {Router} router
	 * The router used in this login component.
	 * @memberof LoginComponent
	 */
	public constructor (
		public activityService: ActivityService,
		public entityInstanceApiService: EntityInstanceApiService,
		protected storageMap: StorageMap,
		protected cacheService: CacheService,
		protected displayComponentService: DisplayComponentService,
		protected operationService: OperationService,
		protected reallySimpleSindicationFeedReaderService:
			ReallySimpleSyndicationFeedReaderService,
		protected ruleService: RuleService,
		protected entityService: EntityService,
		protected powerBiApiService: PowerBiApiService,
		protected weatherForecastService: WeatherForecastService,
		private readonly sessionService: SessionService,
		private readonly sessionRefreshService: SessionRefreshService,
		private readonly router: Router)
	{
		this.visible = true;

		if (this.sessionService.isValidLoggedInAndReady)
		{
			this.visible = false;

			this.router.navigate(
				[AppConstants.route.dashboardPage]);

			// Start interval based services.
			this.sessionRefreshService
				.start(AppConfig.settings
					.clientExpirationInterval);

			Settings.defaultZone = this.sessionService.systemTimeZone;
		}
		else if (this.sessionService.shouldChangePassword)
		{
			this.displayChangePassword();

			// Start interval based services.
			this.sessionRefreshService
				.start(AppConfig.settings
					.clientExpirationInterval);

			Settings.defaultZone = this.sessionService.systemTimeZone;
		}
		else
		{
			this.storageMap
				.clear()
				.subscribe(() => {
					// No implementation.
				});
			this.cacheService
				.clearAllCaches();
			this.powerBiApiService.accessTokenExpiry = DateTime.local();
			this.powerBiApiService.accessTokenPromise = null;

			this.displayComponentService
				.clearStoredVariables();
			this.operationService
				.clearStoredVariables();
			this.reallySimpleSindicationFeedReaderService
				.clearStoredVariables();
			this.ruleService
				.clearStoredVariables();
			this.entityService
				.clearStoredVariables();
			this.weatherForecastService
				.clearStoredVariables();
			this.activityService
				.dismissAllActivities();

			// Stop interval based services.
			this.sessionRefreshService.stop();

			Settings.defaultZone = DateHelper.getLocalTimeZone();
		}
	}

	/**
	 * Gets or sets the current username context.
	 *
	 * @type {string}
	 * @memberof LoginComponent
	 */
	public userName: string;

	/**
	 * Gets or sets the current visible value of this component.
	 *
	 * @type {boolean}
	 * @memberof LoginComponent
	 */
	public visible: boolean;

	/**
	 * Gets or sets a value indicating whether to show the login dialog.
	 *
	 * @public
	 * @type {boolean}
	 * @memberof LoginComponent
	 */
	public showLogin: boolean = true;

	/**
	 * Gets or sets a value indicating whether to show the verification page.
	 *
	 * @public
	 * @type {boolean}
	 * @memberof LoginComponent
	 */
	public showVerify: boolean = false;

	/**
	 * Gets or sets a value indicating whether to show the reset user page.
	 *
	 * @public
	 * @type {boolean}
	 * @memberof LoginComponent
	 */
	public showResetUser: boolean = false;

	/**
	 * Gets or sets a value indicating whether to show the change password page.
	 *
	 * @public
	 * @type {boolean}
	 * @memberof LoginComponent
	 */
	public showChangePassword: boolean = false;

	/**
	 * Gets or sets a collection of {Message} for login messaging.
	 *
	 * @public
	 * @type {Message[]}
	 * @memberof LoginComponent
	 */
	public loginMessages: Message[] = [];

	/**
	 * Gets the message returned when clearing cookies and local storage
	 * via the browser. This will log out the user and require a refresh and
	 * login.
	 *
	 * @private
	 * @type {string}
	 * @memberof LoginComponent
	 */
	private readonly clearedSiteDataMessage: string =
		'Failed to execute \'transaction\' on \'IDBDatabase\': '
			+ 'The database connection is closing.';

	/**
	 * Adds a {Message} to the login messaging component from an
	 * external source.
	 *
	 * @param {ClientMessage} clientMessage
	 * The client message to display.
	 * @memberof LoginComponent
	 */
	@HostListener(
		AppEventConstants.addLoginMessageEvent,
		[AppEventParameterConstants.clientMessage])
	public addLoginMessageEvent(
		clientMessage: ClientMessage): void
	{
		this.addMessage(
			clientMessage.status,
			clientMessage.title,
			clientMessage.content);
	}

	/**
	 * On initialization event.
	 * Verifies the user if not in a valid session state.
	 *
	 * @memberof LoginComponent
	 */
	public ngOnInit(): void
	{
		// Waiting for session to be validated. Show verify page.
		if (this.sessionService.isLoggedIn
			&& !this.sessionService.isValid)
		{
			this.displayVerify();

			return;
		}

		// User needs to change password. Show change password page.
		if (this.sessionService.shouldChangePassword)
		{
			this.displayChangePassword();
		}
	}

	/**
	 * Adds a {Message} to the login messaging component.
	 *
	 * @param {string} level
	 * The message level to display.
	 * @param {string} title
	 * The title to display.
	 * @param {string} description
	 * The description of the login message.
	 * @memberof LoginComponent
	 */
	public addMessage(
		level: string,
		title: string,
		description: string): void
	{
		this.clearMessages();

		const message: Message =
			<Message>
			{
				severity: level,
				summary: title,
				detail: description
			};

		// Handle cookie/local storage clears requiring a refresh.
		if (message.detail === this.clearedSiteDataMessage)
		{
			message.summary = 'Refresh Required';
			message.detail = 'Please refresh and login again.';
		}

		this.loginMessages.push(message);
	}

	/**
	 * Clears all {Message} from the login messaging component.
	 *
	 * @memberof LoginComponent
	 */
	public clearMessages(): void
	{
		this.loginMessages = [];
	}

	/**
	 * Display's the login dialog page.
	 *
	 * @param {string} [userName]
	 * @param {string} [message]
	 * @memberof LoginComponent
	 */
	public displayLogin(
		userName?: string,
		message: string = null): void
	{
		this.userName = userName;

		this.clearMessages();

		if (message)
		{
			this.addMessage(
				AppConstants.messageLevel.success,
				AppConstants.empty,
				message);
		}

		this.showLogin = true;
		this.showVerify = false;
		this.showResetUser = false;
		this.showChangePassword = false;
	}

	/**
	 * Display's the verification page.
	 *
	 * @memberof LoginComponent
	 */
	public displayVerify(): void
	{
		this.clearMessages();

		this.showLogin = false;
		this.showVerify = true;
		this.showResetUser = false;
		this.showChangePassword = false;
	}

	/**
	 * Display's the reset user page.
	 *
	 * @param {string} [userName]
	 * @memberof LoginComponent
	 */
	public displayResetUser(userName?: string): void
	{
		if (userName)
		{
			this.userName = userName;
		}

		this.clearMessages();

		this.showLogin = false;
		this.showVerify = false;
		this.showResetUser = true;
		this.showChangePassword = false;
	}

	/**
	 * Display's the change password page.
	 *
	 * @memberof LoginComponent
	 */
	public displayChangePassword(): void
	{
		this.clearMessages();

		this.showLogin = false;
		this.showVerify = false;
		this.showResetUser = false;
		this.showChangePassword = true;
	}
}