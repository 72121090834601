<app-base-page pageTitle="Action Definition"
	[pageContext]="pageContext"
	[pageContextOperationGroupName]="pageContextOperationGroupName"
	utilityMenuOperationGroupName="">

	<div *ngIf="loading"
		class="text-center"
		[@contentAnimation]="state">
		<br/>
		<span class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</span>
		<br/>
		<br/>
	</div>

	<div class="ui-g-12"
		*ngIf="!loading"
		[@contentAnimation]="state">
		<p-card>
			<div>
				<span class="panel-sub-title">
					Id:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.id}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					Entity Type:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.entityTypeId}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					Entity Version:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.entityVersionId}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					Name:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.name}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					Description:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.description}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					Class Reference:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.classReference}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					Class Definition:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.classDefinition}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					Failure Action:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.failureActionId}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					Create Date:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.createDate}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					Start Date:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.startDate}}
				</span>
			</div>
			<div>
				<span class="panel-sub-title">
					End Date:
				</span>
				<span class="ellipsis-text">
					{{actionDefinition.endDate}}
				</span>
			</div>
		</p-card>
	</div>

</app-base-page>