<div class="file-progress">
	<div class="progress-body" >
		<div *ngIf="mode==='spinner'"
			class="progress-spinner">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner"></i>
			<div class="progress-message">
				<span>
					{{ message }}
				</span>
			</div>
		</div>
		<div *ngIf="mode==='progressBar'"
			class="custom-progress-bar">
			<div class="custom-progress">
				<div
					class="custom-progress-value"
					[style.width]="progress +'%'">
				</div>
			</div>
			<div class="progress-message">
				<span>
					{{ message }}
				</span>
			</div>
		</div>
		<div *ngIf="mode==='done'">
			<i class="fa fa-check double-font-size theme-color-green"
				aria-hidden="true">
			</i>
			<div class="progress-message">
				<span>
					{{ message }}
				</span>
			</div>
		</div>
		<div *ngIf="mode==='error'">
			<i class="fa fa-exclamation double-font-size theme-color-red"
				aria-hidden="true">
			</i>
			<div class="error-message">
				<span>
					{{ errorMessage }}
				</span>
			</div>
		</div>
	</div>
</div>