/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';

@Component({
	selector: 'app-action-definition-router',
	templateUrl: './action-definition-router.component.html'
})

/**
 * A component representing an instance of action definition
 * router component.
 *
 * @export
 * @class ActionDefinitionRouterComponent
 */
export class ActionDefinitionRouterComponent
{
}