/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	filter,
	mergeMap,
	Observable
} from 'rxjs';
import {
	AppConfig
} from 'src/app/app.config';

/**
 * A class representing a data transform interceptor that will allow business
 * logic based data updates following get calls.
 *
 * @export
 * @class AppDataTransformHttpInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AppDataTransformHttpInterceptor implements HttpInterceptor
{
	/**
	 * Intercepts and handles all XHR requests.
	 * This logic handles data transforms of API objects to business logic
	 * based values.
	 *
	 * @param {HttpRequest<any>} request
	 * The request to be sent.
	 * @param {HttpHandler} next
	 * The http handler for synchronizing the requests.
	 * @returns {Observable<HttpEvent<any>>}
	 * The return of event of this intercepted http action.
	 * @memberof AppDataTransformHttpInterceptor
	 */
	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler): Observable<HttpEvent<any>>
	{
		if (request.method !== AppConstants.httpRequestTypes.get
			|| request.url.indexOf(AppConfig.settings.webApi.rootUrl) !== 0)
		{
			return next.handle(request);
		}

		return this.interceptGet(
			request,
			next);
	}

	/**
	 * Intercepts and handles all get XHR requests.
	 * This logic handles the business logic data transform before
	 * returning the response.
	 *
	 * @param {HttpRequest<any>} request
	 * The request to be sent.
	 * @param {HttpHandler} next
	 * The http handler for synchronizing the requests.
	 * @returns {Observable<HttpEvent<any>>}
	 * The return of event of this intercepted http action.
	 * @memberof AppDataTransformHttpInterceptor
	 */
	public interceptGet(
		request: HttpRequest<any>,
		next: HttpHandler): Observable<HttpEvent<any>>
	{
		return next.handle(request)
			.pipe(
				filter(
					(event: HttpEvent<any>) =>
						event instanceof HttpResponse
							&& !AnyHelper.isNull(event.body)),
				mergeMap(
					async(response: HttpResponse<any>) =>
						response.clone(
							{
								body:
									ApiHelper.transformBusinessLogicData(
										(<any>response).body)
							})));
	}
}