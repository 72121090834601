/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	maximumNumberValidationMessage,
	maximumNumberValidator
} from '@entity/entity-formly-definitions';
import {
	IValidator
} from '@shared/interfaces/application-objects/validator.interface';
import {
	BaseRuleValidator
} from '@shared/validators/base-rule-validator.validator';

/**
 * A class representing a maximum number validator.
 *
 * @export
 * @class MaximumNumberValidator
 * @extends {BaseRuleValidator}
 */
export class MaximumNumberValidator
	extends BaseRuleValidator
{
	/**
	 * Creates and returns a unique instance of a maximum number validator.
	 *
	 * @returns {IValidator}
	 * A validator ready for use in a Formly layout.
	 * @memberof MaximumNumberValidator
	 */
	public create(): IValidator
	{
		return <IValidator>
			{
				expression: maximumNumberValidator,
				message: maximumNumberValidationMessage
			};
	}
}