/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	WorkflowActionInstancesApiService
} from '@api/services/workflow/workflow-action-instances.api.service';
import {
	WorkflowRequisiteTypesApiService
} from '@api/services/workflow/workflow-requisite-types.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	ChartFactory
} from '@shared/factories/chart-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IChartDefinition
} from '@shared/interfaces/application-objects/chart-definition.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	IWorkflowActionInstances
} from '@shared/interfaces/workflow/workflow-action-instances.interface';
import {
	IWorkflowActionRequisites
} from '@shared/interfaces/workflow/workflow-action-requisites.interface';
import {
	IWorkflowRequisiteTypes
} from '@shared/interfaces/workflow/workflow-requisite-types.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	DateTime
} from 'luxon';

/* eslint-enable max-len */

@Component({
	selector: 'app-action-definition-expand',
	templateUrl: './action-definition-expand.component.html',
	styleUrls: [
		'./action-definition-expand.component.scss'
	]
})

/**
 * A component representing an instance of an action definition expand.
 *
 * @export
 * @class ActionDefinitionExpandComponent
 * @implements {ActionDefinitionExpandComponent}
 * @implements {IDynamicComponent<any, any>}
 */
export class ActionDefinitionExpandComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<any, any>
{
	/**
	 * Initializes a new instance of the ActionDefinitionExpandComponent class.
	 *
	 * @param {WorkflowRequisiteTypesApiService} workflowRequisiteTypeApiService
	 * The api service used to gather workflow requisite type data.
	 * @param {WorkflowActionInstancesApiService}
	 * workflowActionInstanceApiService
	 * The api service used to gather workflow action instance data.
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {ResolverService} resolver
	 * The resolver service used for display component providers.
	 * @param {ChartFactory} chartFactory
	 * The chart factory service.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ActionDefinitionExpandComponent
	 */
	public constructor(
		public workflowRequisiteTypeApiService:
			WorkflowRequisiteTypesApiService,
		public workflowActionInstanceApiService:
			WorkflowActionInstancesApiService,
		public activityService: ActivityService,
		public siteLayoutService: SiteLayoutService,
		public chartFactory: ChartFactory,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<any, any>}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public context: IDynamicComponentContext<any, any>;

	/**
	 * Gets or sets the postrequisite common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public postRequisiteCommonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the prerequisite common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public preRequisiteCommonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the formly layout definition.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public formlyLayoutDefinition: FormlyFieldConfig[];

	/**
	 * Gets or sets the loading formly definitions.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public loadingFormlyDefinitions: boolean = true;

	/**
	 * Gets or sets the PreRequisite table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public preRequisiteTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public preRequisiteAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public preRequisiteSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the PostRequisite table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public postRequisiteTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public postRequisiteAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public postRequisiteSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the loading instance table definitions.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public loadingInstanceTableDefinitions: boolean = true;

	/**
	 * Gets or sets the PostRequisite table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public actionInstanceTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public actionInstanceAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public actionInstanceSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the workflow requisite types.
	 *
	 * @type {IWorkflowRequisiteTypes[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public requisiteTypes: IWorkflowRequisiteTypes[];

	/**
	 * Gets or sets the action definition expand context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public actionExpandDefinitionsContext:
		IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the context menu items.
	 *
	 * @type {object}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public summaryCardItems: object;

	/**
	 * Gets or sets the context menu items.
	 *
	 * @type {object}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public chartItem: object;

	/**
	 * Gets or sets the local storage filter used
	 * to hide or show context menu content.
	 *
	 * @type {string}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public filter: string;

	/**
	 * Gets or sets if display the requisites tables.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public displayRequisiteTables: boolean = true;

	/**
	 * Sets the event by method chart definition.
	 *
	 * @type {{
		chartColors: string[],
		chartLabels: string[],
		chartPivotProperty: string}}
	 * @memberof ActionDefinitionExpandComponent
	 */
	private readonly eventByMethodChartDefinition: {
		chartColors: string[];
		chartLabels: string[];
		chartPivotProperty: string;
	} = {
			chartColors: [
				ChartConstants.themeColors.green,
				ChartConstants.themeColors.red,
				ChartConstants.themeColors.yellow
			],
			chartLabels: [
				'Complete',
				'Failed',
				'Pending'
			],
			chartPivotProperty: 'Name'
		};

	/**
	 * Gets the instance by state string literal.
	 *
	 * @type {string}
	 * @memberof ActionDefinitionExpandComponent
	 */
	private readonly instancesByState: string = 'Instances by State';

	/**
	 * Sets up the page variables.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		await this.setFormlyLayoutDefinition();

		this.actionExpandDefinitionsContext =
			<IDynamicComponentContext<Component, any>>
			{
				source: this
			};

		this.requisiteTypes =
			await this.workflowRequisiteTypeApiService
				.query(
					AppConstants.empty,
					AppConstants.empty);
		this.filter =
			`actionDefinitionId eq ${this.context.source
				.selectedItem.id}`;

		this.setupContextMenuItems();

		let displayOrder: number = 1;
		this.actionInstanceAvailableColumns =
			[
				{
					dataKey: 'entityInstanceId',
					columnHeader: 'Entity Instance Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'userId',
					columnHeader: 'User Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'responseTime',
					columnHeader: 'Response Time',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'state',
					columnHeader: 'State',
					displayOrder: displayOrder
				}
			];
		this.actionInstanceSelectedColumns =
			this.actionInstanceAvailableColumns;

		displayOrder = 1;
		this.preRequisiteAvailableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'order',
					columnHeader: 'Order',
					displayOrder: displayOrder
				}
			];
		this.preRequisiteSelectedColumns =
			this.preRequisiteAvailableColumns;

		this.postRequisiteAvailableColumns =
			[
				...this.preRequisiteAvailableColumns
			];
		this.postRequisiteSelectedColumns =
			this.postRequisiteAvailableColumns;

		this.setActionInstanceTableDefinitions();

		if (!AnyHelper.isNullOrEmpty(this.context.source
			.selectedItem.entityTypeId))
		{
			this.loadingTableDefinitions = true;
			await this.setPreRequisiteTableDefinitions();
			await this.setPostRequisiteTableDefinitions();
			this.loadingTableDefinitions = false;
		}
	}

	/**
	 * Sets up chart context menu items on this component.
	 * This will setup the data and summary cards.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setupContextMenuItems(): Promise<void>
	{
		this.summaryCardItems =
			[
				<IInformationMenuItem<any>>
				{
					dataPromise: new Promise(async (resolve) =>
					{
						const actionInstances =
							await this.workflowActionInstanceApiService
								.query(
									'ActionDefinitionId eq '
										+ this.context.source.selectedItem.id,
									AppConstants.empty);

						let averageResponse: number = 0;

						actionInstances.forEach((actionInstance) =>
						{
							averageResponse +=
								DateTime.fromISO(
									actionInstance.changeDate)
									.diff(
										DateTime.fromISO(
											actionInstance.createDate))
									.milliseconds;
						});

						const totalAverageTime: number =
							averageResponse / actionInstances.length;

						const responseTime =
							this.getAverageResponseTime(totalAverageTime);

						resolve(
							{
								responseTime: `${responseTime}`
							}
						);
					}),
					summaryTemplate: '${data.responseTime}',
					titleTemplate: 'Avg Response Time',
					width: AppConstants.sizeIdentifiers.large,
					summaryCardDisplay: true,
					squareCardDisplay: false
				},
				<IInformationMenuItem<any>>
				{
					dataPromise: new Promise(async (resolve) =>
					{
						const actionInstances =
							await this.workflowActionInstanceApiService
								.aggregate(
									'Count',
									null,
									'ActionDefinitionId eq '
										+ this.context.source.selectedItem.id);

						resolve(
							{
								actionInstances: `${actionInstances[0].value}`
							}
						);
					}),
					summaryTemplate: '${data.actionInstances}',
					titleTemplate: 'Instances Count',
					width: AppConstants.sizeIdentifiers.large,
					summaryCardDisplay: true,
					squareCardDisplay: false
				}
			];

		const dataId: string = this.context.source.selectedItem.id;
		this.chartItem =
			[
				<IInformationMenuItem<IAggregate[]>>
				{
					chartDefinition: <IChartDefinition<IAggregate[]>>{
						dataPromise: new Promise(async (resolve) => {
							const completeStateCount =
								await this.workflowActionInstanceApiService
									.aggregate(
										'Count',
										AppConstants.empty,
										`ActionDefinitionId eq ${dataId}`
											+ ` And StateId eq ${1}`);

							const failedStateCount =
								await this.workflowActionInstanceApiService
									.aggregate(
										'Count',
										AppConstants.empty,
										`ActionDefinitionId eq ${dataId}`
										+ ` And StateId eq ${2}`);

							const pendingStateCount =
								await this.workflowActionInstanceApiService
									.aggregate(
										'Count',
										AppConstants.empty,
										`ActionDefinitionId eq ${dataId}`
										+ ` And StateId eq ${3}`);

							resolve(<IAggregate[]>
							[
								{
									key:
									{
										name: 'complete'
									},
									value: completeStateCount[0].value
								},
								{
									key:
									{
										name: 'failed'
									},
									value: failedStateCount[0].value
								},
								{
									key:
									{
										name: 'pending'
									},
									value: pendingStateCount[0].value
								}
							]);
						}),
						chartColors:
							this.eventByMethodChartDefinition.chartColors,
						chartConfiguration: this.chartFactory.doughnutChart(
							this.instancesByState,
							this.eventByMethodChartDefinition.chartLabels,
							[],
							this.instancesByState),
						chartPivotProperty:
							this.eventByMethodChartDefinition.chartPivotProperty
					},
					titleTemplate: this.instancesByState,
					width: AppConstants.sizeIdentifiers.large,
					summaryCardDisplay: false,
					squareCardDisplay: true
				}
			];
	}

	/**
	 * Sets up the formly layout definitions.
	 *
	 * @param {number} averageTime
	 * The average time in miliseconds.
	 * @returns {string}
	 * The formated average time calculation to be displayed
	 * within a summary card.
	 * @memberof ActionDefinitionExpandComponent
	 */
	public getAverageResponseTime(
		averageTime: number): string
	{
		const dateTimeFromEpoch: DateTime =
			DateTime.fromMillis(averageTime);

		return !dateTimeFromEpoch.isValid
			? AppConstants.timeSpan.zeroMinutes
			: `${dateTimeFromEpoch.minute}:`
				+ `${dateTimeFromEpoch.second}.`
				+ `${dateTimeFromEpoch.millisecond}`;
	}

	/**
	 * Sets up the formly layout definitions.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setFormlyLayoutDefinition(): Promise<void>
	{
		const hideRequiredMarker: boolean =
			this.context.source.displayMode === AppConstants.displayMode.view;

		const entityTypeDropdownOptions: IDropdownOption[] =
				this.getDropdownOptions(
					await ApiHelper.getFullDataSet(
						this.context?.source?.customContext?.source
							?.entityTypeApiService,
						AppConstants.empty,
						AppConstants.commonProperties.name +
							` ${AppConstants.sortDirections.ascending}`),
					AppConstants.commonProperties.name,
					AppConstants.commonProperties.id);
		const failureActionDropdownOptions: IDropdownOption[] =
				this.getDropdownOptions(
					await this.context?.source?.customContext?.source
						?.workflowFailureActionsApiService
						.query(
							AppConstants.empty,
							AppConstants.empty,
							null,
							100),
					AppConstants.commonProperties.name,
					AppConstants.commonProperties.id);

		this.formlyLayoutDefinition =
			[
				{
					key: 'entityTypeId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Entity Type',
						showClear: true,
						required: true,
						hideRequiredMarker: hideRequiredMarker,
						placeholder: AppConstants.placeholders.selectAnOption,
						options: entityTypeDropdownOptions,
						change:
							async (field: FormlyFieldConfig) =>
							{
								if (this.context.source
									.selectedItem.entityTypeId
										=== field.formControl.value)
								{
									return;
								}

								this.context.source.selectedItem.entityTypeId =
									AnyHelper.isNullOrEmpty(
										field.formControl.value)
										? undefined
										: field.formControl.value;

								let entityVersions: IEntityVersion[] = [];

								if (!AnyHelper.isNullOrEmpty(
									field.formControl.value))
								{
									entityVersions =
										await this.context.source
											.customContext.source
											.entityVersionApiService.query(
												`TypeId eq ${field
													.formControl.value}`);

									await this
										.setPreRequisiteTableDefinitions();
									await this
										.setPostRequisiteTableDefinitions();
								}

								this.context.source.selectedItem
									.entityVersionId =
										entityVersions.length === 1
											? entityVersions[0].number
											: undefined;

								setTimeout(
									async() =>
									{
										await this.setFormlyLayoutDefinition();
										this.displayRequisiteTables =
											!AnyHelper.isNullOrEmpty(
												this.context.source
													.selectedItem
													.entityTypeId);
									});
							}
					}
				},
				{
					key: 'entityVersionId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						showClear: true,
						required: true,
						hideRequiredMarker: hideRequiredMarker,
						placeholder: AppConstants.placeholders.selectAnOption,
						label: 'Entity Version',
						options:
							AnyHelper.isNull(
								this.context
									?.source
									?.selectedItem
									?.entityTypeId)
								? []
								: ((await this.context
									?.source
									.customContext
									?.source
									?.entityVersionApiService
									.query(
										'typeId eq '
											+ `${this.context.source
												.selectedItem.entityTypeId}`,
										AppConstants.empty))
									?.map((item: any) =>
										<IDropdownOption>
										{
											label: item.number,
											value: item.id
										}))
					}
				},
				{
					key: 'name',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Name',
						required: true,
						hideRequiredMarker: hideRequiredMarker
					},
					asyncValidators: {
						uniqueCombination: {
							expression: (control: UntypedFormControl) =>
								this.uniqueCombination(control),
							message:
								'Existing Type, Version and Name combination.'
						}
					}
				},
				{
					key: 'description',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Description'
					}
				},
				{
					key: 'failureActionId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						showClear: true,
						placeholder: AppConstants.placeholders.selectAnOption,
						label: 'Failure Action',
						required: true,
						hideRequiredMarker: hideRequiredMarker,
						options: failureActionDropdownOptions
					}
				},
				{
					key: 'classReference',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Class Reference',
						required: true,
						hideRequiredMarker: hideRequiredMarker
					},
					validators: {
						oneOf: {
							expression: (
								control: UntypedFormControl,
								field: FormlyFieldConfig) =>
								AnyHelper.isNullOrWhitespace(
									control.value)
									|| AnyHelper.isNullOrWhitespace(
										field.model.classDefinition),
							message:
								'Only one of class definition or class '
									+ 'reference can be set.'
						}
					},
					expressions: {
						'props.required':
							'model.classDefinition == null '
								+ '|| model.classDefinition.trim() === ""'
					}
				},
				{
					key: 'classDefinition',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Class Definition',
						rows: FormlyConstants.textAreaRowSizes.standard,
						required: true
					},
					validators: {
						validCSharpFormat: {
							expression: (
								control: UntypedFormControl) =>
								AnyHelper.isNullOrWhitespace(control.value)
									|| this.isValidCSharpFormat(
										control.value),
							message: 'Not a valid C# format.'
						},
						oneOf: {
							expression: (
								control: UntypedFormControl,
								field: FormlyFieldConfig) =>
								AnyHelper.isNullOrWhitespace(
									control.value)
									|| AnyHelper.isNullOrWhitespace(
										field.model.classReference),
							message:
								'Only one of class definition or class '
									+ 'reference can be set.'
						}
					},
					expressions: {
						'props.required':
							'model.classReference == null '
								+ '|| model.classReference.trim() === ""'
					}
				}
			];

		if (this.context.source.displayMode === AppConstants.displayMode.view)
		{
			this.formlyLayoutDefinition.forEach(
				(definition) =>
				{
					definition.props.disabled = true;
				});

			this.formlyLayoutDefinition
				.splice(
					6,
					1,
					{
						type: FormlyConstants.customControls.customTextDisplay,
						wrappers: [
							FormlyConstants.customControls.customFieldWrapper
						],
						props: {
							label: 'Class Definition',
							useCodeBlock: true,
							usePanelDisplay: false,
							codeBlockType:
								AppConstants.markdownLanguages.csharp,
							content: AppConstants.empty
						},
						expressions: {
							'props.content':
								'`${model.classDefinition == null '
									+ '? "No Data" : '
									+ 'model.classDefinition}`'
						}
					});
		}

		if (this.context.source.displayMode === AppConstants.displayMode.create)
		{
			this.context.source.selectedItem.classDefinition =
				'namespace WaterStreet.Nautix.Data.Workflow.Actions\n'
					+ '{\n'
					+ '\tusing WaterStreet.Nautix.Data.Workflow;\n'
					+ '\tusing WaterStreet.Nautix.Public;\n'
					+ '\tusing WaterStreet.Nautix.Public.Workflow;\n\r'
					+ '\tpublic class YourWorkflowAction\n'
					+ '\t\t: BaseAction\n'
					+ '\t{\n'
					+ '\t\tpublic override ActionResponse Execute()\n'
					+ '\t\t{\n'
					+ '\t\t\treturn Ok(new object());\n'
					+ '\t\t}\n'
					+ '\t}\n'
					+ '}';
		}

		this.loadingFormlyDefinitions = false;
	}

	/**
	 * Sets up the PreRequisite Table Definitions.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public setActionInstanceTableDefinitions(): void
	{
		this.actionInstanceTableDefinitions =
			{
				tableTitle: 'Instances',
				nestedTable: true,
				hideSettings: true,
				objectSearch: {
					filter: this.filter,
					orderBy: 'Id desc',
					offset: 0,
					limit: 50,
					virtualIndex: 0,
					virtualPageSize: 5
				},
				tableHeight: {
					virtualPageSizeBased: false,
					explicitHeight: '138'
				},
				apiPromise:
					async(objectSearch: IObjectSearch) =>
					{
						const actionInstances: IWorkflowActionInstances[] =
							await this.workflowActionInstanceApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						const dataPromise = [];

						actionInstances.forEach(
							(instance: IWorkflowActionInstances) =>
							{
								dataPromise.push(
									{
										id: instance.id,
										entityInstanceId:
											instance.entityInstanceId,
										userId: instance.userId,
										responseTime:
											`${DateTime.fromISO(
												instance.changeDate)
												.diff(
													DateTime.fromISO(
														instance.createDate))
												.milliseconds}ms`,
										state:
											`${this.getState(instance.stateId)}`
									});
							});

						return dataPromise;
					},
				availableColumns: this.actionInstanceAvailableColumns,
				selectedColumns: this.actionInstanceSelectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					filter: {
						quickFilters:
							[
								{
									label: 'All',
									value: AppConstants.empty
								},
								{
									label: 'Complete',
									value: 'StateId eq 1'
								},
								{
									label: 'Failed',
									value: 'StateId eq 2'
								},
								{
									label: 'Pending',
									value: 'StateId eq 3'
								}
							],
						selectedFilterValue: this.filter
					}
				}
			};

		this.loadingInstanceTableDefinitions = false;
	}

	/**
	 * Gets the State string.
	 *
	 * @param {number} stateId
	 * @memberof ActionDefinitionExpandComponent
	 */
	public getState(stateId: number): string
	{
		if (stateId === 1)
		{
			return 'Complete';
		}
		else if (stateId === 2)
		{
			return 'Failed';
		}

		return 'Pending';
	}

	/**
	 * Sets up the PreRequisite Table Definitions.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setPreRequisiteTableDefinitions(): Promise<void>
	{
		this.preRequisiteTableDefinitions =
			{
				tableTitle: 'PreRequisites',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.preRequisiteCommonTableContext,
						AppConstants.workflowRequisiteTypes.preRequisite),
				nestedTable: true,
				hideSettings: true,
				objectSearch: {
					filter: 'actionDefinitionId eq '
						+ this.context.source.selectedItem.id
						+ ' and requisiteTypeId eq '
						+ (this.requisiteTypes.filter(requisiteType =>
							requisiteType.name ===
								AppConstants.workflowRequisiteTypes
									.preRequisite))[0].id,
					orderBy: `Order ${AppConstants.sortDirections.ascending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: 5
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const actionRequisites: IWorkflowActionRequisites[] =
								await this.context.source.customContext.source
									.workflowActionRequisitesApiService
									.query(
										objectSearch.filter,
										objectSearch.orderBy,
										objectSearch.offset,
										objectSearch.limit);

						const preRequisites: IWorkflowActionRequisites[] = [];

						for (const actionRequisite of actionRequisites)
						{
							preRequisites.push(
								await this.decorateActionRequisiteViewModel(
									actionRequisite));
						}

						return preRequisites;
					},
				decorateViewModel:
					async(model: any) =>
						this.decorateActionRequisiteViewModel(model),
				availableColumns: this.preRequisiteAvailableColumns,
				selectedColumns: this.preRequisiteSelectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.preRequisiteCommonTableContext = commonTableContext;
				}
			};

		if (this.context.source.displayMode !== AppConstants.displayMode.update)
		{
			return;
		}

		const preRequisiteDropdownOptions: IDropdownOption[] =
			this.getDropdownOptions(
				await this.context.source
					.customContext.source
					.workflowActionDefinitionsApiService
					.query(
						'EntityTypeId eq '
							+ `${this.context.source
								.selectedItem.entityTypeId} `
							+ `and Id ne ${this.context.source
								.selectedItem.id}`,
						AppConstants.empty,
						null,
						100),
				AppConstants.commonProperties.name,
				AppConstants.commonProperties.id);

		this.preRequisiteTableDefinitions.actions =
			{
				create: {
					customContext: this.actionExpandDefinitionsContext,
					layout: [
						{
							key: 'requisiteActionDefinitionId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Action Definition',
								required: true,
								showClear: true,
								placeholder:
									AppConstants.placeholders.selectAnOption,
								options: preRequisiteDropdownOptions,
								appendTo: FormlyConstants.appendToTargets.body
							}
						},
						{
							key: 'order',
							type: FormlyConstants.customControls
								.customInputNumber,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Order',
								required: true,
								type: 'number'
							},
							asyncValidators: {
								uniqueOrder: {
									expression: (control: UntypedFormControl) =>
										this.uniqueOrder(
											control,
											AppConstants.workflowRequisiteTypes
												.preRequisite),
									message: 'Existing Order.'
								}
							}
						}
					],
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createRequisite(
								AppConstants.workflowRequisiteTypes
									.preRequisite)
						}
					]
				},
				updateIndex: [
					{
						id: 'updateIndexUp',
						command: async(
							selectedItem: any) =>
							this.updateOrderIndex(
								selectedItem,
								AppConstants.workflowRequisiteTypes
									.preRequisite,
								-1)
					},
					{
						id: 'updateIndexDown',
						command: async(
							selectedItem: any) =>
							this.updateOrderIndex(
								selectedItem,
								AppConstants.workflowRequisiteTypes
									.preRequisite,
								1)
					}
				],
				delete: {
					deleteStatement: () =>
						'Confirm to remove PreRequisite '
							+ this.preRequisiteCommonTableContext.source
								.selectedItem.name
							+ ` from action definition
								${this.context.source.selectedItem.name}.`,
					items: [
						{
							label: 'Remove',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async() =>
								this.deleteRequisite(
									AppConstants.workflowRequisiteTypes
										.preRequisite)
						}
					]
				}
			};
	}

	/**
	 * Sets up the PostRequisite Table Definitions.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setPostRequisiteTableDefinitions(): Promise<void>
	{
		this.postRequisiteTableDefinitions =
			{
				tableTitle: 'PostRequisites',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.postRequisiteCommonTableContext,
						AppConstants.workflowRequisiteTypes.postRequisite),
				nestedTable: true,
				hideSettings: true,
				objectSearch: {
					filter: 'actionDefinitionId eq '
						+ this.context.source.selectedItem.id
						+ ' and requisiteTypeId eq '
						+ (this.requisiteTypes.filter(requisiteType =>
							requisiteType.name ===
								AppConstants.workflowRequisiteTypes
									.postRequisite))[0].id,
					orderBy: `Order ${AppConstants.sortDirections.ascending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: 5
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const actionRequisites: IWorkflowActionRequisites[] =
							await this.context.source.customContext.source
								.workflowActionRequisitesApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						const postRequisites: IWorkflowActionRequisites[] = [];

						for (const actionRequisite of actionRequisites)
						{
							postRequisites.push(
								await this.decorateActionRequisiteViewModel(
									actionRequisite));
						}

						return postRequisites;
					},
				decorateViewModel:
					async(model: any) =>
						this.decorateActionRequisiteViewModel(model),
				availableColumns: this.postRequisiteAvailableColumns,
				selectedColumns: this.postRequisiteSelectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.postRequisiteCommonTableContext = commonTableContext;
				}
			};

		if (this.context.source.displayMode === AppConstants.displayMode.update)
		{
			const postRequisiteDropdownOptions: IDropdownOption[] =
				this.getDropdownOptions(
					await this.context.source
						.customContext.source
						.workflowActionDefinitionsApiService
						.query(
							'EntityTypeId eq '
								+ `${this.context.source
									.selectedItem.entityTypeId}`
								+ ` and Id ne ${this.context
									.source.selectedItem.id}`,
							AppConstants.empty,
							null,
							100),
					AppConstants.commonProperties.name,
					AppConstants.commonProperties.id);

			this.postRequisiteTableDefinitions.actions =
				{
					create: {
						customContext: this.actionExpandDefinitionsContext,
						layout: [
							{
								key: 'requisiteActionDefinitionId',
								type: FormlyConstants.customControls
									.customSelect,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Action Definition',
									required: true,
									showClear: true,
									placeholder:
										AppConstants.placeholders
											.selectAnOption,
									options: postRequisiteDropdownOptions,
									appendTo:
										FormlyConstants.appendToTargets.body
								}
							},
							{
								key: 'order',
								type: FormlyConstants.customControls
									.customInputNumber,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Order',
									required: true,
									type: 'number'
								},
								asyncValidators: {
									uniqueOrder: {
										expression:
											(control: UntypedFormControl) =>
												this.uniqueOrder(
													control,
													AppConstants
														.workflowRequisiteTypes
														.postRequisite),
										message: 'Existing Order.'
									}
								}
							}
						],
						items: [
							{
								label: 'Save',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command:
									() => this.createRequisite(
										AppConstants.workflowRequisiteTypes
											.postRequisite)
							}
						]
					},
					updateIndex: [
						{
							id: 'updateIndexUp',
							command: async(
								selectedItem: any) =>
								this.updateOrderIndex(
									selectedItem,
									AppConstants.workflowRequisiteTypes
										.postRequisite,
									-1)
						},
						{
							id: 'updateIndexDown',
							command: async(
								selectedItem: any) =>
								this.updateOrderIndex(
									selectedItem,
									AppConstants.workflowRequisiteTypes
										.postRequisite,
									1)
						}
					],
					delete: {
						deleteStatement:
							() =>
							{
								const selectedItem: any =
									this.postRequisiteCommonTableContext.source
										.selectedItem;

								return `Confirm to remove PostRequisite
									${selectedItem.name}
									from action definition
									${this.context.source.selectedItem.name}.`;
							},
						items: [
							{
								label: 'Remove',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: async() =>
									this.deleteRequisite(
										AppConstants.workflowRequisiteTypes
											.postRequisite)
							}
						]
					}
				};
		}
	}

	/**
	 * Decorates the view model for the object.
	 *
	 * @async
	 * @param {IWorkflowActionRequisites} actionRequisite
	 * The object to be decorated as a view model.
	 * @returns {Promise<any>}
	 * The mapped view model for display in this component.
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async decorateActionRequisiteViewModel(
		actionRequisite: IWorkflowActionRequisites): Promise<any>
	{
		return <any>{
			id: actionRequisite.id,
			name:
				(await this.context.source.customContext
					?.source
					.workflowActionDefinitionsApiService
					.get(actionRequisite
						.requisiteActionDefinitionId))
					?.name,
			requisiteTypeId:
				actionRequisite.requisiteTypeId,
			actionDefinitionId:
				actionRequisite.actionDefinitionId,
			requisiteActionDefinitionId:
				actionRequisite
					.requisiteActionDefinitionId,
			order: actionRequisite.order
		};
	}

	/**
	 * Applies the filter criteria event to the table display. This will set
	 * the object search filter to the provided filter value.
	 *
	 * @async
	 * @param {string} filterCriteria
	 * The filter event sent from a table quick filter or formatted in a
	 * component.
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async filterCriteriaChanged(
		filterCriteria: string): Promise<void>
	{
		this.filter =
			filterCriteria === AppConstants.empty
				? `actionDefinitionId eq ${this.context.source.selectedItem.id}`
				: `actionDefinitionId eq ${this.context.source.selectedItem.id}
					And ${filterCriteria}`;

		await this.context.source.filterCriteriaChanged(this.filter);
	}

	/**
	 * Checks if content value is a valid c sharp format.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	private isValidCSharpFormat(value: string): RegExpMatchArray
	{
		return value?.match(new RegExp(
			'^(?=.*namespace (?=WaterStreet))'
				+ '(?=.*using (?=WaterStreet))'
				+ '(?=.*public (?=class))'
				+ '(?=.*public (?=override))'
				+ '(?=.*return).*$',
			'gs'))
			&& value.match(/\{(?:[^{}]+|\{(?:[^{}]+|\{[^{}]\})\}+)\}+/g);
	}

	/**
	 * Validates if the requisite order is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @param {string} requisiteType
	 * The requisite type.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async uniqueOrder(
		control: UntypedFormControl,
		requisiteType: string): Promise<boolean>
	{
		const workflowRequisiteTypes: IWorkflowRequisiteTypes[] =
			await this.workflowRequisiteTypeApiService
				.query(
					`Name eq '${requisiteType}'`,
					AppConstants.empty);

		const workflowRequisites: IWorkflowActionRequisites[] =
			await this.context.source.customContext.source
				.workflowActionRequisitesApiService
				.query(
					`Order eq ${control.value} `
						+ 'and ActionDefinitionId eq '
						+ `${this.context.source.selectedItem.id} `
						+ 'and RequisiteTypeId eq '
						+ workflowRequisiteTypes[0].id,
					AppConstants.empty);

		return Promise.resolve(workflowRequisites.length <= 0);
	}

	/**
	 * Creates a new Requisite.
	 *
	 * @async
	 * @param {string} requisiteType
	 * The requisite type to create.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async createRequisite(
		requisiteType: string): Promise<void>
	{
		const commonTableContext:
			IDynamicComponentContext<CommonTableComponent, any> =
			this.getRequisiteTableContext(
				requisiteType);

		const createRequisite: Function =
			async () =>
			{
				const requisiteTypeId: number =
					(await this
						.workflowRequisiteTypeApiService
						.query(
							`Name eq '${requisiteType}'`,
							AppConstants.empty))[0].id;

				const createdId: number =
					await this.context.source.customContext.source
						.workflowActionRequisitesApiService
						.create(
							<IWorkflowActionRequisites>
							{
								id: 0,
								requisiteTypeId: requisiteTypeId,
								actionDefinitionId:
									this.context.source.selectedItem.id,
								requisiteActionDefinitionId:
									commonTableContext.source.selectedItem
										.requisiteActionDefinitionId,
								order:
									commonTableContext.source.selectedItem
										.order
							});

				commonTableContext.source.selectedItem.id =
					createdId;
				commonTableContext.source.selectedItem.requisiteTypeId =
					requisiteTypeId;
				commonTableContext.source.selectedItem.actionDefinitionId =
					this.context.source.selectedItem.id;

				await commonTableContext.source.addSelectedItem();
				this.sortCommonTableByOrder(commonTableContext);
			};

		await this.activityService.handleActivity(
			new Activity(
				createRequisite(),
				'<strong>Creating Requisite</strong>',
				'<strong>Created Requisite</strong>',
				`Requisite
					${commonTableContext.source.selectedItem.name}
					was successfully created.`,
				`Requisite
					${commonTableContext.source.selectedItem.name}
					was not created.`));
	}

	/**
	 * Deletes an existing Requisite.
	 *
	 * @async
	 * @param {string} requisiteType
	 * The requisite type to delete.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async deleteRequisite(
		requisiteType: string): Promise<void>
	{
		const commonTableContext:
			IDynamicComponentContext<CommonTableComponent, any> =
			this.getRequisiteTableContext(
				requisiteType);

		const selectedItem: any =
			commonTableContext.source.selectedItem;

		const deleteRequisite: Function =
			async() =>
			{
				await this.context.source.customContext.source
					.workflowActionRequisitesApiService
					.delete(selectedItem.id);

				commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteRequisite(),
				'<strong>Deleting Requisite</strong>',
				'<strong>Deleted Requisite</strong>',
				`Requisite ${selectedItem.name}
					was successfully deleted.`,
				`Requisite ${selectedItem.name}
					was not deleted.`));
	}

	/**
	 * Updates the order index of the selected row item
	 * up or down based on the indexOperator.
	 *
	 * @async
	 * @param {any} selectedItem
	 * The selected item.
	 * @param {string} requisiteType
	 * The requisite type to alter orders for.
	 * @param {number} indexReference
	 * The index reference to add or substract to the current
	 * selected order index.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async updateOrderIndex(
		selectedItem: any,
		requisiteType: string,
		indexReference: number): Promise<void>
	{
		const commonTableContext:
			IDynamicComponentContext<CommonTableComponent, any> =
			this.getRequisiteTableContext(
				requisiteType);
		commonTableContext.source.loadingNextDataset = true;
		commonTableContext.source.selectedItem = selectedItem;

		const updateOrderIndex: Function =
			async() =>
			{
				const orderedRequisites: any[] =
					commonTableContext.source.virtualData
						.filter((data) => data !== undefined)
						.sort((itemOne: any, itemTwo: any) =>
							itemOne.order - itemTwo.order);

				const neighborOrderIndex: number =
					this.findSelectedChildIndex(
						orderedRequisites,
						selectedItem)
						+ indexReference;

				await this.context.source.customContext.source
					.workflowActionRequisitesApiService
					.update(
						orderedRequisites[neighborOrderIndex].id,
						<IWorkflowActionRequisites>
						{
							id: orderedRequisites[
								neighborOrderIndex].id,
							requisiteTypeId: orderedRequisites[
								neighborOrderIndex]
								.requisiteTypeId,
							actionDefinitionId:
								orderedRequisites[neighborOrderIndex]
									.actionDefinitionId,
							requisiteActionDefinitionId:
								orderedRequisites[neighborOrderIndex]
									.requisiteActionDefinitionId,
							order: 1000
						});

				await this.context.source.customContext.source
					.workflowActionRequisitesApiService
					.update(
						selectedItem.id,
						<IWorkflowActionRequisites>
						{
							id: selectedItem.id,
							requisiteTypeId:
								selectedItem
									.requisiteTypeId,
							actionDefinitionId:
								selectedItem
									.actionDefinitionId,
							requisiteActionDefinitionId:
								selectedItem
									.requisiteActionDefinitionId,
							order: orderedRequisites[
								neighborOrderIndex].order
						});

				await this.context.source.customContext.source
					.workflowActionRequisitesApiService
					.update(
						orderedRequisites[neighborOrderIndex].id,
						<IWorkflowActionRequisites>
						{
							id: orderedRequisites[
								neighborOrderIndex].id,
							requisiteTypeId:
								orderedRequisites[neighborOrderIndex]
									.requisiteTypeId,
							actionDefinitionId:
								orderedRequisites[neighborOrderIndex]
									.actionDefinitionId,
							requisiteActionDefinitionId:
								orderedRequisites[neighborOrderIndex]
									.requisiteActionDefinitionId,
							order:
								selectedItem.order
						});

				commonTableContext.source.virtualData[neighborOrderIndex] =
					{
						...orderedRequisites[neighborOrderIndex],
						order: selectedItem.order
					};
				commonTableContext.source.virtualData[
					neighborOrderIndex - indexReference] =
					{
						...commonTableContext.source.selectedItem,
						order: orderedRequisites[neighborOrderIndex].order
					};

				this.sortCommonTableByOrder(commonTableContext);
				commonTableContext.source.loadingNextDataset = false;
			};

		await this.activityService.handleActivity(
			new Activity(
				updateOrderIndex(),
				'<strong>Updating Requisite Order</strong>',
				'<strong>Updated Requisite Order</strong>',
				'Requisite Order was successfully updated.',
				'Requisite Order was not updated.'),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Gets the requisite table context based on the sent requisite type.
	 *
	 * @param {string} requisiteType
	 * The requisite type to get a table context for.
	 * @returns {IDynamicComponentContext<CommonTableComponent, any>}
	 * The requisite table context.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private getRequisiteTableContext(
		requisiteType: string):
		IDynamicComponentContext<CommonTableComponent, any>
	{
		return requisiteType ===
			AppConstants.workflowRequisiteTypes.preRequisite
			? this.preRequisiteCommonTableContext
			: this.postRequisiteCommonTableContext;
	}

	/**
	 * Finds the selected child index.
	 *
	 * @param {any[]} children
	 * The children object array.
	 * @param {any} selectedChild
	 * The selected child.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private findSelectedChildIndex(
		children: any[],
		selectedChild: any): number
	{
		for (let index = 0; index < children.length; index++)
		{
			if (children[index].id === selectedChild.id
				&& children[index].requisiteActionDefinitionId
					=== selectedChild.requisiteActionDefinitionId
				&& children[index].order === selectedChild.order)
			{
				return index;
			}
		}

		return -1;
	}

	/**
	 * Sorts the common table context data by order.
	 *
	 * @param {IDynamicComponentContext<CommonTableComponent, any>}
	 * commonTableContext
	 * The common table context to sort by order.
	 * @memberof EntityActionDefinitionExpandComponent
	 */
	private sortCommonTableByOrder(
		commonTableContext:
			IDynamicComponentContext<CommonTableComponent, any>): void
	{
		commonTableContext.source.virtualData
			.sort((itemOne: any, itemTwo: any) =>
				itemOne.order - itemTwo.order);
		commonTableContext.source.virtualData =
			[
				...commonTableContext.source.virtualData
			];
	}

	/**
	 * Checks if entity type, entity verion
	 * and action name combination is existing or unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async uniqueCombination(
		control: UntypedFormControl):
		Promise<boolean>
	{
		let actionDefinitions: IWorkflowActionDefinitions[] = [];

		if (!AnyHelper.isNullOrEmpty(
			this.context.source.selectedItem.entityTypeId))
		{
			const entityVersionId = this.context.source.displayMode ===
				AppConstants.displayMode.create
				? (await this.context.source.customContext
					.source.entityVersionApiService
					.query(
						`TypeId eq ${this.context.source
							.selectedItem.entityTypeId}`,
						AppConstants.empty))[0].id
				: this.context.source.selectedItem.entityVersionId;

			actionDefinitions =
				await this.context.source.customContext.source
					.workflowActionDefinitionsApiService
					.query(
						'EntityTypeId eq '
							+ `${this.context.source
								.selectedItem.entityTypeId} `
							+ `and EntityVersionId eq ${entityVersionId} `
							+ `and Name eq '${control.value}'`,
						AppConstants.empty);
		}

		return Promise.resolve(
			actionDefinitions.length === 0
				|| this.context.source.selectedItem.id ===
					actionDefinitions[0]?.id);
	}

	/**
	 * Gets the dropdown options.
	 *
	 * @param {any} apiData
	 * The api resolved with data for the dropdown options.
	 * @param {string} label
	 * The dropdown label to map with the data promise results.
	 * @param {string} value
	 * The dropdown value to map with the data promise results.
	 * @returns {IDropdownOption[]}
	 * The dropdown options object
	 * @memberof EntityManagerDirective
	 */
	private getDropdownOptions(
		apiData: any,
		label: string,
		value: string): IDropdownOption[]
	{
		return (apiData)
			.map((item: any) =>
			<IDropdownOption>
					{
						label: item[label],
						value: item[value]
					});
	}
}