/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Directive,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	CommonListDirective
} from '@shared/directives/common-list-directive';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	SecurityRightCategory
} from '@shared/constants/enums/security-right-category.enum';
import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';

/* eslint-enable max-len */

@Directive({
	selector: '[ProductSetting]'
})

/**
 * A directive representing shared logic for a component interacting
 * with product setting.
 *
 * @export
 * @class ProductSettingDirective
 */
export class ProductSettingDirective
	extends CommonListDirective<IEntityInstance, IEntityInstanceDto>
{
	/**
	 * Initializes a new instance of product setting directive.
	 * @memberof ProductSettingDirective
	 */
	public constructor()
	{
		super();
	}

	/**
	 * Gets or sets the security definitions.
	 *
	 * @type {ISecurityEntityTypeDefinition[]}
	 * @memberof ProductSettingDirective
	 */
	@Input() public securityDefinitions: ISecurityEntityTypeDefinition[];

	/**
	 * Gets or sets the change display mode event
	 *
	 * @type {EventEmitter<string>}
	 * @memberof ProductSettingDirective
	 */
	@Output() public changeDisplayMode: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets the product Authority Types
	 *
	 * @type {object}
	 * @memberof ProductSettingDirective
	 */
	public readonly productAuthorityTypes:
	{
		active: string;
		inactive: string;
		serviceExisting: string;
	} = {
			active: 'Active',
			inactive: 'Inactive',
			serviceExisting: 'Service Existing'
		};

	/**
	 * Gets the set of available actions for nested displays.
	 *
	 * @type
	 * @memberof ProductSettingDirective
	 */
	public itemActions: ISecureMenuItem[] =
		[
			<ISecureMenuItem> {
				icon: 'fa fa-info-circle',
				id: AppConstants.displayMode.view,
				securityRightCategory: SecurityRightCategory.TopLevelRight,
				securityRightType: SecurityRightType.read,
				command: ((
					event: any,
					item: any) => {
					this.changeSelectedItem.emit(item);
					this.changeDisplayMode.emit(
						AppConstants.displayMode.view);
					event.stopPropagation();
				})
			},
			<ISecureMenuItem> {
				icon: 'fa fa-pencil',
				id: AppConstants.displayMode.update,
				securityRightCategory: SecurityRightCategory.Action,
				securityRightPath: AppConstants.apiMethods.update,
				command: ((
					event: any,
					item: any) => {
					this.changeSelectedItem.emit(item);
					this.changeDisplayMode.emit(
						AppConstants.displayMode.update);
					event.stopPropagation();
				})
			}
		];
}