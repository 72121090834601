/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */
@Component({
	selector: 'app-manage-user-expand',
	templateUrl: './manage-user-expand.component.html',
	styleUrls: [
		'./manage-user-expand.component.scss'
	]
})

/**
 * A component representing an instance of the security applications
 * component.
 *
 * @export
 * @class ManageUserComponent
 * @extends {CommonTablePageDirective}
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 */
export class ManageUserComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<CommonTableComponent, any>
{
	/**
	 * Initializes a new instance of the ManageUserComponent class.
	 *
	 * @param {SecurityApplicationApiService} securityApplicationApiService
	 * The api service used to load security application data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component to get its data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ManageUserComponent
	 */
	public constructor(
		public securityGroupApiService: SecurityGroupApiService,
		public activityService: ActivityService,
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof BaseExpandComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ManageUserComponent
	 */
	public manageUserTableDefinitionsView: ICommonTable;

	/**
	 * Gets or sets the table definitions for the common table update view.
	 *
	 * @type {ICommonTable}
	 * @memberof ManageUserComponent
	 */
	public manageUserTableDefinitionsUpdate: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public viewAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public viewSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public updateAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public updateSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the error message displayed on the formly async validator.
	 *
	 * @type {string}
	 * @memberof ManageUserComponent
	 */
	public asyncValidatorErrorMessage: string;

	/**
	 * Gets or sets the security groups.
	 *
	 * @type {string}
	 * @memberof ManageUserComponent
	 */
	public securityGroups: ISecurityGroup[];

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ManageUserComponent
	 */
	public setupPageVariables(): void
	{
		this.viewAvailableColumns =
			[
				{
					dataKey: 'securityGroup',
					columnHeader: 'Group Name',
					displayOrder: 1
				}
			];
		this.viewSelectedColumns =
			this.viewAvailableColumns;

		this.updateAvailableColumns =
			[
				...this.viewAvailableColumns
			];
		this.updateSelectedColumns =
			this.updateAvailableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @async
	 * @memberof ManageUserComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		await this.setSecurityGroups();

		const baseTableDefinitions: any =
			{
				tableTitle: 'Group Membership',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Group Membership'),
				hideSettings: true,
				nestedTable: true,
				objectSearch: {
					filter: AppConstants.empty,
					orderBy: `${AppConstants.commonProperties.name} `
						+ `${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: 4
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const selectedItem: any =
							this.context.source.selectedItem;
						const securityGroups: ISecurityGroup[] =
							await ApiHelper.getFullDataSet(
								this.securityGroupApiService,
								'SecurityGroupEntityInstances.Any('
									+ 'EntityInstanceId eq '
									+ `'${selectedItem.id}')`,
								objectSearch.orderBy);

						return securityGroups
							.map(
								(securityGroup: ISecurityGroup) =>
									<any>
									{
										id: securityGroup.id,
										securityGroup: securityGroup.name
									});
					},
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				}
			};

		this.manageUserTableDefinitionsView =
			{
				...baseTableDefinitions,
				availableColumns: this.viewAvailableColumns,
				selectedColumns: this.viewSelectedColumns,
				actions: {}
			};

		this.manageUserTableDefinitionsUpdate =
			{
				...baseTableDefinitions,
				availableColumns: this.updateAvailableColumns,
				selectedColumns: this.updateSelectedColumns,
				actions: {
					create: {
						layout: [
							{
								key: 'securityGroup',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: AppConstants.empty,
									disabled: false,
									required: true
								},
								asyncValidators: {
									validSecurityGroup: {
										expression: async(
											control: UntypedFormControl,
											field: FormlyFieldConfig) =>
											this.isValidSecurityGroup(
												control,
												field),
										message: AppConstants.empty
									}
								}
							}
						],
						items: [
							{
								label: 'Add',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.addUserSecurityGroup()
							}]
					},
					delete: {
						deleteStatement: async () =>
							'Confirm to remove user '
								+ this.context.source.selectedItem.userName
								+ ' from security group '
								+ `${this.context.source
									.selectedItem.securityGroup}.`,
						items: [
							{
								label: 'Remove',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: () => this.removeUserSecurityGroup()
							}]
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Set Security Groups.
	 *
	 * @private
	 * @async
	 * @memberof ManageUserComponent
	 */
	private async setSecurityGroups(): Promise<void>
	{
		const securityGroups: ISecurityGroup[] =
			await ApiHelper.getFullDataSet(
				this.securityGroupApiService,
				AppConstants.empty,
				AppConstants.empty);

		this.securityGroups = securityGroups;
	}

	/**
	 * Removes the user from security group.
	 *
	 * @private
	 * @async
	 * @memberof ManageUserComponent
	 */
	private async removeUserSecurityGroup(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const removeUserSecurityGroup: Function =
			async () =>
			{
				const securityGroup =
					await this.securityGroupApiService
						.query(
							`Name eq '${selectedItem.securityGroup}'`,
							AppConstants.empty);

				await this.securityGroupApiService
					.deleteSecurityGroupUser(
						securityGroup[0].id,
						this.context.source.selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				removeUserSecurityGroup(),
				`<strong>Removing User ${selectedItem.userName} `
					+ 'from Security Group '
					+ `${selectedItem.securityGroup}</strong>`,
				`<strong>Removed User ${selectedItem.userName} `
					+ 'from Security Group '
					+ `${selectedItem.securityGroup}</strong>`,
				`User ${this.context.source.selectedItem.userName} `
					+ 'was removed from Security Group '
					+ `${selectedItem.securityGroup}.`,
				`User ${this.context.source.selectedItem.userName} `
					+ 'was not removed from Security Group '
					+ `${selectedItem.securityGroup}.`));
	}

	/**
	 * Add the user to security group.
	 *
	 * @private
	 * @async
	 * @memberof ManageUserComponent
	 */
	private async addUserSecurityGroup(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const addUserSecurityGroup: Function =
			async () =>
			{
				const securityGroup =
					await this.securityGroupApiService
						.query(
							`Name eq '${selectedItem.securityGroup}'`,
							AppConstants.empty);

				await this.securityGroupApiService
					.createSecurityGroupUser(
						securityGroup[0].id,
						this.context.source.selectedItem.id);

				this.commonTableContext.source.selectedItem.id
					= securityGroup[0].id;
				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				addUserSecurityGroup(),
				`<strong>Adding User ${selectedItem.userName} `
					+ 'to Security Group '
					+ `${selectedItem.securityGroup}</strong>`,
				`<strong>Added User ${selectedItem.userName} `
					+ 'to Security Group '
					+ `${selectedItem.securityGroup}</strong>`,
				`User ${this.context.source.selectedItem.userName} `
					+ 'was added to Security Group '
					+ `${selectedItem.securityGroup}.`,
				`User ${this.context.source.selectedItem.userName} `
					+ 'was not added to Security Group '
					+ `${selectedItem.securityGroup}.`));
	}

	/**
	 * Defines and validates if the value is a valid Security Group.
	 *
	 * @returns {Promise<boolean>}
	 * @private
	 * @async
	 * @memberof ManageUserComponent
	 */
	private async isValidSecurityGroup(
		control: UntypedFormControl,
		field: FormlyFieldConfig): Promise<boolean>
	{
		const initialPromiseArray: Promise<any>[] =
			[
				this.securityGroupApiService.query(
					`Name eq '${control.value}'`,
					AppConstants.empty)
			];

		return Promise.all(
			initialPromiseArray)
			.then(async (
				[
					securityGroup
				]) =>
			{
				field.asyncValidators.validSecurityGroup.message =
					'Invalid security group.';
				let subscribedUser: boolean = false;
				if (!AnyHelper.isNullOrEmpty(securityGroup[0]))
				{
					const securityGroupUsers: number[] =
						await this.securityGroupApiService
							.getSecurityGroupUsers(securityGroup[0]?.id);
					securityGroupUsers.forEach(
						(user: number) =>
						{
							if (user === this.context.source.selectedItem.id)
							{
								subscribedUser = true;
								field.asyncValidators
									.validSecurityGroup.message =
										'Group already taken.';
							}
						});
				}

				return Promise.resolve(
					!AnyHelper.isNullOrEmpty(securityGroup[0])
						&& subscribedUser === false);
			});
	}
}