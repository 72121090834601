/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	AbstractControl,
	UntypedFormControl
} from '@angular/forms';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';

@Component({
	selector: 'adjustment-details',
	templateUrl: './adjustment-details.component.html'
})

/**
 * A component representing a wizard step to collect the commission adjustment
 * details information.
 *
 * @export
 * @class AdjustmentDetailsComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class AdjustmentDetailsComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the adjustment write off component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used to lookup insurance modules.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @memberof AdjustmentDetailsComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public insuranceService: InsuranceService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof AdjustmentDetailsComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof AdjustmentDetailsComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof AdjustmentSummaryComponent
	 */
	public currentData: any;

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof AdjustmentDetailsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData.data;

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof AdjustmentDetailsComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * Validates the provided transaction number.
	 *
	 * @async
	 * @param {AbstractControl} control
	 * The form control to get the input value.
	 * @param {FormlyFieldConfig} _field
	 * The formly field configuration.
	 * @return {boolean}
	 * The transaction number validity
	 * @memberof AdjustmentDetailsComponent
	 */
	public async transactionNumberValidator(
		control: AbstractControl,
		_field: FormlyFieldConfig): Promise<boolean>
	{
		if (AnyHelper.isNullOrWhitespace(control.value))
		{
			return false;
		}

		const group: string = 'Group';

		const availableEntityTypes: IEntityType[] =
			await this.entityTypeApiService
				.query(
					ApiFilterHelper.getWildcardFilter(
						group,
						InsuranceConstants
							.insuranceEntityTypeGroups
							.policyTermTransactionWildcard),
					group,
					null,
					AppConstants.dataLimits.large);

		if (availableEntityTypes.length >= 1)
		{
			for (const entityType of availableEntityTypes)
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
					entityType.group;

				const policyTermTransactionEntityInstance: IEntityInstance =
					await this.entityInstanceApiService
						.getSingleQueryResult(
							`${InsuranceConstants.commonProperties.transactionNumber} `
								+ `eq '${control.value}'`,
							`${AppConstants.commonProperties.id} `
								+ AppConstants.sortDirections.descending,
							true);

				if (!AnyHelper.isNull(policyTermTransactionEntityInstance))
				{
					this.context.source.addOrUpdateStepData(
						{
							policyNumber:
								policyTermTransactionEntityInstance
									.data
									.policyNumber,
						});

					return true;
				}
			}
		}

		this.context.source.addOrUpdateStepData(
			{
				policyNumber: AppConstants.empty,
			});

		return false;
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof AdjustmentDetailsComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'reason',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						placeholder: 'Select an Option',
						label: 'Reason',
						required: true,
						default: 'Correction',
						options:
							<IDropdownOption[]>
							[
								{
									label:
										InsuranceConstants
											.commissionAdjustentReasonTypes
											.correction,
									value:
										InsuranceConstants
											.commissionAdjustentReasonTypes
											.correction
								},
								{
									label:
										InsuranceConstants
											.commissionAdjustentReasonTypes
											.other,
									value:
										InsuranceConstants
											.commissionAdjustentReasonTypes
											.other
								}
							],
						change:
							(field: FormlyFieldConfig,
								_event: any) =>
							{
								if (field.formControl.value ===
									InsuranceConstants
										.commissionAdjustentReasonTypes
										.correction)
								{
									this.dynamicFormlyLayout[1]
										.formControl.enable();
								}
								else
								{
									this.dynamicFormlyLayout[1]
										.formControl.setValue(AppConstants.empty);
									this.dynamicFormlyLayout[1]
										.formControl.disable();
								}
							}
					}
				},
				{
					key: 'transactionNumber',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						type: 'string',
						label: 'Transaction Id',
						required: true,
						tooltip:
							'Transaction Id can be found in the Transaction '
								+ 'Table displayed in the Policy Term Summary '
								+ 'Page'
					},
					asyncValidators: {
						transactionNumberValidator: {
							expression: (
								control: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.transactionNumberValidator(
									control,
									field),
							message:
								'This transaction number does not exist.'
						}
					}
				},
				{
					key: 'amount',
					type: FormlyConstants.customControls.customInputNumber,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Amount',
						useCurrency: true,
						required: true
					},
					validators: {
						validAmout: {
							expression: (control: UntypedFormControl) =>
								control.value !== 0,
							message: 'Invalid amount value.'
						}
					},
				},
				{
					key: 'note',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						type: 'string',
						label: 'Note',
						description: 'Commission adjustment note.'
					}
				}
			];
	}
}