/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	AssociatedTransactionDirective
} from '@insurance/directives/associated-transaction.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-interests',
	templateUrl: './transaction-interests.component.html',
	styleUrls: [
		'./transaction-interests.component.scss'
	]
})

/**
 * A component representing an associated company used
 * in the associated entity list.
 *
 * @export
 * @class TransactionInterestsComponent
 * @extends AssociatedTransactionDirective
 * @implements OnInit
 */
export class TransactionInterestsComponent
	extends AssociatedTransactionDirective
	implements OnInit
{
	/**
	 * Sets the individual icon class.
	 *
	 * @type {string}
	 * @memberof TransactionInterestsComponent
	 */
	private readonly individualIconClass: string = 'fa-user';

	/**
	 * Sets the organization icon class.
	 *
	 * @type {string}
	 * @memberof TransactionInterestsComponent
	 */
	private readonly organizationIconClass: string = 'fa-users';

	/**
	 * Sets the individual type.
	 *
	 * @type {string}
	 * @memberof TransactionInterestsComponent
	 */
	private readonly individualType: string = 'Individual';

	/**
	 * Sets the organization type.
	 *
	 * @type {string}
	 * @memberof TransactionInterestsComponent
	 */
	private readonly organizationType: string = 'Organization';

	/**
	 * Sets the interests property name.
	 *
	 * @type {string}
	 * @memberof TransactionInterestsComponent
	 */
	private readonly interestsPropertyName: string = 'interests';

	/**
	 * Initiates the component to get the data object.
	 *
	 * @memberof TransactionInterestsComponent
	 */
	public ngOnInit(): void
	{
		if (AnyHelper.isNullOrEmpty(
			this.context.data.entityInstance))
		{
			return;
		}

		this.setData(this.interestsPropertyName);
	}

	/**
	 * Sets the icon class.
	 *
	 * @param {any} property
	 * The mapping property.
	 *
	 * @memberof TransactionInterestsComponent
	 */
	public setIconClass(property: any): void
	{
		const iconClass =
			property.characteristics?.name?.type === this.individualType
				? this.individualIconClass
				: null;

		property.iconClass = AnyHelper.isNull(iconClass)
				&& property.characteristics?.name?.type
					=== this.organizationType
			? this.organizationIconClass
			: iconClass;
	}
}