/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewInit,
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

@Component({
	selector: 'custom-input-password',
	templateUrl: './custom-input-password.component.html',
	styleUrls: [
		'./custom-input-password.component.scss'
	],
})

/**
 * A component representing an instance of a Custom Input Password.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomInputPasswordComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {AfterViewInit}
 */
export class CustomInputPasswordComponent
	extends ExtendedCustomControlDirective
	implements AfterViewInit
{
	/** Initializes a new instance of the CustomInputPasswordComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @memberof CustomInputPasswordComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef,
		public entityInstanceApiService: EntityInstanceApiService)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets the enable or disable the mask toogle display.
	 *
	 * @type {boolean}
	 * @memberof CustomInputPasswordComponent
	 */
	public enableMaskToogle: boolean = false;

	/**
	 * Gets or sets the system security object.
	 *
	 * @type {any}
	 * @memberof CustomInputPasswordComponent
	 */
	public systemSecurity: any;

	/**
	 * Gets the system security restrictions to define a password.
	 *
	 * @type {any}
	 * @memberof LoginChangePasswordComponent
	 */
	public getSecurityRestrictions(): any
	{
		return SecurityHelper.getSecurityRestrictions(
			this.systemSecurity);
	}

	/**
	 * Gets the allowed password special characters.
	 *
	 * @type {any}
	 * @memberof LoginChangePasswordComponent
	 */
	public getPasswordSpecialCharacters(): any
	{
		return AppConstants.passwordSpecialCharacters;
	}

	/**
	 * On component initialization event.
	 *
	 * @memberof CustomInputPasswordComponent
	 */
	public ngOnInit(): void
	{
		this.setSystemSecurity();
	}

	/**
	 * Sets an event into the pasword input field to handle the
	 * enable or disable the toogle mask display.
	 *
	 * @memberof CustomInputPasswordComponent
	 */
	public ngAfterViewInit(): void
	{
		const element: HTMLInputElement =
			document.querySelector('.password-input');
		const handleKeyUpEvent: EventListenerOrEventListenerObject =
			() =>
			{
				const passwordInput = element.value;
				this.enableMaskToogle = !AnyHelper.isNullOrEmpty(passwordInput);
				this.validateControl();
			};

		element.addEventListener(
			WindowEventConstants.keyup,
			handleKeyUpEvent);
	}

	/**
	 * Sets the system security information.
	 *
	 * @returns {Promise<any>}
	 * @memberof CustomInputPasswordComponent
	 */
	public async setSystemSecurity(): Promise<void>
	{
		if (AnyHelper.isNull(this.systemSecurity))
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				AppConstants.typeGroups.systems;

			const systemEntityInstance: IEntityInstance =
				await this.entityInstanceApiService
					.get(
						parseInt(
							AppConstants.systemId,
							AppConstants.parseRadix));

			this.systemSecurity =
				systemEntityInstance.data.security;
		}
	}
}