<div class="ui-g-12 text-center"
	*ngIf="loading === true">
	<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</div>

<div class="ui-g-12 no-horizontal-padding text-left"
	*ngIf="loading === false"
	[ngClass]="{'no-content-animation': !showContentAnimation}"
	[@contentAnimation]="state">
	<app-dynamic-formly
		[dataSet]="entityInstance"
		[initialData]="databaseEntityInstance"
		[layoutSchema]="formlyEntityLayout"
		[context]="pageContext">
	</app-dynamic-formly>
</div>