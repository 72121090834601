/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	Component
} from '@angular/core';
import {
	CustomFieldWrapperComponent
} from '@entity/components/custom-components/wrappers/custom-field-wrapper/custom-field-wrapper.component';

/* eslint-enable max-len */

@Component({
	selector: 'custom-empty-wrapper',
	templateUrl: './custom-empty-wrapper.component.html',
	styleUrls: [
		'./custom-empty-wrapper.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Empty Wrapper.
 *
 * @export
 * @class CustomEmptyWrapperComponent
 * @extends {FieldWrapper}
 */
export class CustomEmptyWrapperComponent
	extends CustomFieldWrapperComponent
{
}