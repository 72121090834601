/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	JsonSchemaHelper
} from '@shared/helpers/json-schema.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDescriptionDisplayDefinition
} from '@shared/interfaces/application-objects/description-display-definition.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IKeyValuePair
} from '@shared/interfaces/application-objects/key-value-pair.interface';
import {
	IMappedDifferenceDefinition
} from '@shared/interfaces/application-objects/mapped-difference-display-definition.interface';
import {
	IModelDisplayDefinition
} from '@shared/interfaces/application-objects/model-display-definition.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	isArray,
	isObject
} from 'lodash-es';

/* eslint-enable max-len */

@Component({
	selector: 'app-difference-display',
	templateUrl: './difference-display.component.html',
	styleUrls: [
		'./difference-display.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of the difference display component.
 *
 * @export
 * @class DifferenceDisplayComponent
 */
export class DifferenceDisplayComponent implements OnInit
{
	/**
	 * Initializes a new instance of the difference display component.
	 *
	 * @param {ModuleService} moduleService
	 * The module service which is used to define module specific business
	 * logic for display.
	 * @memberof DifferenceDisplayComponent
	 */
	public constructor(
		public moduleService: ModuleService)
	{
	}

	/**
	 * Gets or sets the mapped difference to be displayed in this component.
	 *
	 * @type {IMappedDifferenceDefinition}
	 * @memberof DifferenceDisplayComponent
	 */
	@Input() public mappedDifference: IMappedDifferenceDefinition;

	/**
	 * Gets or sets the previous sibling to this mapped difference definition.
	 *
	 * @type {IMappedDifferenceDefinition}
	 * @memberof DifferenceDisplayComponent
	 */
	@Input() public previousSibling: IMappedDifferenceDefinition;

	/**
	 * Gets or sets the current data level being displayed in this component.
	 * This value is used for business logic and display definitions based
	 * on data levels.
	 *
	 * @type {number}
	 * @memberof DifferenceDisplayComponent
	 */
	@Input() public dataLevel: number = 0;

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof DifferenceDisplayComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets an event emitter that will be used to detect changes
	 * in the component and trigger change detection.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DifferenceDisplayComponent
	 */
	@Output() public readonly detectChanges: EventEmitter<void> =
		new EventEmitter<void>();

	/**
	 * Gets or sets a value that signifies whether or not this component is
	 * currently loading data. This value is used to display a loading
	 * spinner while data is being loaded.
	 *
	 * @type {boolean}
	 * @memberof DifferenceDisplayComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a value that signifies whether or not this difference is
	 * based on an array index item. This value is used when nested array
	 * level items are altered and signifies an updated nested value in this
	 * array item.
	 *
	 * @type {boolean}
	 * @memberof DifferenceDisplayComponent
	 */
	public isNumericKey: boolean;

	/**
	 * Gets or sets a mapped parent value for this mapped difference.
	 *
	 * @type {any}
	 * @memberof DifferenceDisplayComponent
	 */
	public parentValue: any;

	/**
	 * Gets or sets the model display specific to this difference display
	 * based on a key value.
	 *
	 * @type {IModelDisplayDefinition}
	 * @memberof DifferenceDisplayComponent
	 */
	public modelDisplayDefinition: IModelDisplayDefinition;

	/**
	 * Gets or sets the object description for this difference. This is used
	 * to display a friendly description of the object based on the schema
	 * definition of the object.
	 *
	 * @type {string}
	 * @memberof DifferenceDisplayComponent
	 */
	public objectDifferenceDescription: string;

	/**
	 * Gets or sets a value signifying whether or not the object level
	 * name should be displayed. This is hidden when there are no differences
	 * at the base level.
	 *
	 * @type {boolean}
	 * @memberof DifferenceDisplayComponent
	 */
	public displayObjectName: boolean = true;

	/**
	 * Gets or sets the original value of the difference to be displayed.
	 *
	 * @type {string}
	 * @memberof DifferenceDisplayComponent
	 */
	public originalValue: string = AppConstants.empty;

	/**
	 * Gets or sets the updated value of the difference to be displayed.
	 *
	 * @type {string}
	 * @memberof DifferenceDisplayComponent
	 */
	public updatedValue: string = AppConstants.empty;

	/**
	 * Gets the set of difference types that should display a nested label
	 * display.
	 *
	 * @type {string[]}
	 * @memberof DifferenceDisplayComponent
	 */
	private readonly labelledDifferenceTypes: string[] =
		[
			AppConstants.differenceTypes.date,
			AppConstants.differenceTypes.property
		];

	/**
	 * Implements the on initialization interface.
	 * This method will set values explicit to this implementation of the
	 * difference display component at this level, for this difference.
	 *
	 * @async
	 * @memberof DifferenceDisplayComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.parentValue =
			this.mappedDifference.updatedParentValue;
		this.modelDisplayDefinition =
			this.mapModelDisplay();
		this.isNumericKey =
			!isNaN(parseInt(
				this.mappedDifference.key,
				AppConstants.parseRadix));

		if (this.isNumericKey === true
			&& this.mappedDifference.schemaDefinition.type
				!== AppConstants.propertyTypes.object)
		{
			this.displayObjectName = false;
			this.isNumericKey = false;
			this.dataLevel++;
			this.loading = false;
			this.detectChanges.emit();

			return;
		}

		this.originalValue =
			await this.getFormattedValue(
				this.mappedDifference.difference?.originalValue);
		this.updatedValue =
			await this.getFormattedValue(
				this.mappedDifference.difference?.updatedValue);

		this.displayObjectName =
			this.dataLevel !== 0
				|| (!AnyHelper.isNullOrWhitespace(this.originalValue)
					|| !AnyHelper.isNullOrWhitespace(this.updatedValue))
				|| this.mappedDifference.nestedDifferences.some(
					(mappedDifference: IMappedDifferenceDefinition) =>
						!AnyHelper.isNull(mappedDifference.difference)
							&& (this.labelledDifferenceTypes.includes(
								mappedDifference.difference.differenceType)
								|| this.isPrimitiveArrayDifference(
									mappedDifference)));

		await this.handleArrayObjectDescriptions();

		this.dataLevel++;
		this.loading = false;
		this.detectChanges.emit();
	}

	/**
	 * Gets a page context for this difference display component. This
	 * context is used to pass data to dynamic components that are created
	 * in this component.
	 *
	 * @param {any} itemValue
	 * The item value that should be passed to the dynamic component.
	 * @returns {IDynamicComponentContext<any, Component>}
	 * The page context that contains the source and data values for the
	 * dynamic component.
	 * @memberof DifferenceDisplayComponent
	 */
	public getPageContext(
		itemValue: any):
		IDynamicComponentContext<any, Component>
	{
		const pageContext: IDynamicComponentContext<Component, any> =
			{
				source: this.context.source,
				data: {
					...this.context.data,
					item: itemValue
				}
			};

		return pageContext;
	}

	/**
	 * Handles the array object descriptions for this difference display.
	 * This method will set the object description for each item in the array
	 * if the difference type is an array.
	 *
	 * @async
	 * @memberof DifferenceDisplayComponent
	 */
	public async handleArrayObjectDescriptions(): Promise<void>
	{
		if (this.isNumericKey === true)
		{
			this.objectDifferenceDescription =
				await this.getObjectDescription();

			return;
		}

		if (this.mappedDifference.difference
			?.differenceType !== AppConstants.differenceTypes.array)
		{
			return;
		}

		// Handle arrays of objects.
		if (isObject(this.mappedDifference.difference.originalValue[0])
			|| isObject(this.mappedDifference.difference.updatedValue[0]))
		{
			for (const arrayItem of
				this.mappedDifference.difference.originalValue)
			{
				arrayItem.objectDifferenceDescription =
					await this.getObjectDescription(arrayItem);
			}

			for (const arrayItem of
				this.mappedDifference.difference.updatedValue)
			{
				arrayItem.objectDifferenceDescription =
					await this.getObjectDescription(arrayItem);
			}

			return;
		}

		// Handle arrays of primitive values.
		this.mappedDifference.difference.differenceType =
			AppConstants.differenceTypes.property;

		this.originalValue =
			await this.getPrimitiveArrayValue(
				this.mappedDifference.difference.originalValue);
		this.updatedValue =
			await this.getPrimitiveArrayValue(
				this.mappedDifference.difference.updatedValue);
	}

	/**
	 * Gets a display valu from the description property of the schema
	 * definition. If no value is found this will use the property name
	 * and add spaces before all capital letters and convert the value to
	 * proper case.
	 *
	 * @param {string} value
	 * The camel case value that should be displayed as a friendly named
	 * value if no label value is defined in the model display definition.
	 * @returns {string}
	 * The display value signifying the friendly name of the sent camelcase
	 * value or label value if set.
	 * @memberof DifferenceDisplayComponent
	 */
	public getLabelValue(
		value: string): string
	{
		if (!AnyHelper.isNull(this.modelDisplayDefinition?.label))
		{
			return this.modelDisplayDefinition.label;
		}

		return StringHelper.beforeCapitalSpaces(
			StringHelper.toProperCase(value));
	}

	/**
	 * Gets a display value for a property value to display to the user.
	 * This method will format based on the property schema definition of
	 * output format.
	 *
	 * @async
	 * @param {any} itemValue
	 * The item value that should be cast into a formatted string.
	 * @returns {Promise<string>}
	 * The formatted display value of the sent property value if set, or the
	 * value unaltered if undefined.
	 * @memberof DifferenceDisplayComponent
	 */
	public async getFormattedValue(
		itemValue: any): Promise<string>
	{
		if (AnyHelper.isNullOrWhitespace(itemValue))
		{
			return AppConstants.empty;
		}

		if (!AnyHelper.isNull(this.modelDisplayDefinition?.format))
		{
			return this.mappedDifference.difference
				?.differenceType === AppConstants.differenceTypes.array
				? this.getPrimitiveArrayValue(
					itemValue)
				: StringHelper.format(
					itemValue,
					this.modelDisplayDefinition.format);
		}

		if (!AnyHelper.isNull(this.modelDisplayDefinition?.formatPromise))
		{
			return StringHelper.transformToDataPromise(
				this.modelDisplayDefinition?.formatPromise,
				this.getPageContext(itemValue));
		}

		return itemValue;
	}

	/**
	 * Gets a display value for an object based on it's type and subType if
	 * these values exist.
	 *
	 * @param {any} itemValue
	 * The item that will be checked for a type and subType based definition.
	 * @returns {string}
	 * If the item holds a type or subtype, this will return the string
	 * value containing those two combined values. If no type value exists
	 * this will return as an empty string.
	 * @memberof DifferenceDisplayComponent
	 */
	 public getObjectDisplayType(
		itemValue: any): string
	{
		const item: any =
			itemValue || this.parentValue;

		if (AnyHelper.isNullOrWhitespace(item?.type))
		{
			return AppConstants.empty;
		}

		return this.getLabelValue(
			item.type
				+ (AnyHelper.isNullOrWhitespace(item.subType)
					? AppConstants.empty
					: ` - ${item.subType}`));
	}

	/**
	 * Gets an icon display for an item type from the model display
	 * definition or schema definition.
	 *
	 * @param {any} itemValue
	 * The item that will be checked for a type based icon value.
	 * @returns {string}
	 * If the item holds a type and is defined in the difference display
	 * definition, this will return the mapped icon value.
	 * @memberof DifferenceDisplayComponent
	 */
	 public getObjectDisplayIcon(
		itemValue: any): string
	{
		const iconPrefix: string = 'fa fa-fw fa-';

		if (!AnyHelper.isNullOrWhitespace(
			this.modelDisplayDefinition?.icon))
		{
			return `${iconPrefix}${this.modelDisplayDefinition?.icon}`;
		}

		const item: any =
			itemValue || this.parentValue;

		if (!AnyHelper.isNullOrWhitespace(
			this.modelDisplayDefinition?.iconFunction))
		{
			return iconPrefix
				+ StringHelper.transformToFunction(
					this.modelDisplayDefinition.iconFunction,
					this.getPageContext(item))(item);
		}

		if (AnyHelper.isNullOrWhitespace(item?.type))
		{
			return AppConstants.empty;
		}

		const itemDefinition: any =
			JsonSchemaHelper.getArrayItemDefinition(
				this.modelDisplayDefinition.schemaDefinition,
				item?.type);

		return AnyHelper.isNull(itemDefinition?.icon)
			? AppConstants.empty
			: `${iconPrefix}${itemDefinition.icon}`;
	}

	/**
	 * Gets a display value for an array of primitive values based on the
	 * schema definition of the array item.
	 *
	 * @async
	 * @param {any[]} arrayValue
	 * The array value that should be cast into a formatted string.
	 * @returns {Promise<string>}
	 * The formatted display value of the sent array value if set, or the
	 * value unaltered if there is no description promise.
	 * @memberof DifferenceDisplayComponent
	 */
	public async getPrimitiveArrayValue(
		arrayValue: any[]): Promise<string>
	{
		const descriptionPromise: string =
			this.mappedDifference
				.schemaDefinition
				?.descriptionPromise;

		const values: string[] = [];
		for (const arrayItem of arrayValue)
		{
			const primitiveValue: string =
				AnyHelper.isNullOrWhitespace(descriptionPromise)
					? StringHelper.beforeCapitalSpaces(
						StringHelper.toProperCase(
							arrayItem.toString()))
					: await StringHelper.transformToDataPromise(
						descriptionPromise,
						this.getPageContext(
							arrayItem));

			values.push(primitiveValue);
		}

		return values.join(
			AppConstants.characters.comma
				+ AppConstants.characters.space);
	}

	/**
	 * Gets a display value for an object based on the entity definition
	 * schema properties for this object type. If no description property keys
	 * are found or any value in the key is not set this will not display
	 * the item level description. The description property type will always
	 * display if set.
	 *
	 * @async
	 * @param {any} itemValue
	 * The item that should be cast into a friendly description in this view.
	 * @returns {Promise<string>}
	 * The display value of the sent item as mapped in the module
	 * level configurations. If any value of a description is null or empty,
	 * this will return as an empty string.
	 * @memberof DifferenceDisplayComponent
	 */
	public async getObjectDescription(
		itemValue: any = null): Promise<string>
	{
		const item: any =
			itemValue || this.parentValue;

		let descriptionType: string;
		let descriptionPromise: string;
		let propertyKeys:
			IDescriptionDisplayDefinition[];
		let schemaDefinition: any =
			this.modelDisplayDefinition.schemaDefinition;

		if (!AnyHelper.isNullOrWhitespace(
			this.modelDisplayDefinition?.descriptionPropertyType)
			|| AnyHelper.isNull(schemaDefinition.items))
		{
			descriptionType =
				this.modelDisplayDefinition.descriptionPropertyType;
			propertyKeys =
				this.modelDisplayDefinition.descriptionPropertyKeys;
			descriptionPromise =
				this.modelDisplayDefinition.descriptionPromise;
		}
		else
		{
			schemaDefinition =
				JsonSchemaHelper.getArrayItemDefinition(
					schemaDefinition,
					item?.type);

			descriptionType =
				schemaDefinition.descriptionPropertyType;
			propertyKeys =
				schemaDefinition.descriptionPropertyKeys;
			descriptionPromise =
				schemaDefinition.descriptionPromise;
		}

		propertyKeys = propertyKeys || [];
		const schemaProperties: IKeyValuePair[] =
			JsonSchemaHelper.getSchemaProperties(
				schemaDefinition.properties);

		for (const propertyKey of
			propertyKeys.filter(
				(propertyData: IDescriptionDisplayDefinition) =>
					AnyHelper.isNull(propertyData.outputFormat)))
		{
			const schemaProperty: IKeyValuePair =
				schemaProperties.find(
					(schema: any) =>
						schema.key.replace(
							'[]',
							AppConstants.empty) === propertyKey.key);

			propertyKey.outputFormat =
				schemaProperty?.value.outputFormat
					|| AppConstants.formatTypes.none;
		}

		const description: string =
			AnyHelper.isNullOrWhitespace(descriptionPromise)
				? ObjectHelper.getObjectDescription(
					item,
					propertyKeys)
				: await StringHelper.transformToDataPromise(
					descriptionPromise,
					this.getPageContext(item));

		if (AnyHelper.isNullOrWhitespace(descriptionType)
			&& AnyHelper.isNullOrWhitespace(description))
		{
			return AppConstants.empty;
		}

		return (AnyHelper.isNullOrWhitespace(descriptionType)
			? AppConstants.empty
			: `${descriptionType}:`)
			+ (AnyHelper.isNullOrWhitespace(description)
				? AppConstants.empty
				: ` ${description}`);
	}

	/**
	 * Given a difference definition, this will determine if this is a
	 * primitive array difference.
	 *
	 * @param {IMappedDifferenceDefinition} mappedDifference
	 * The difference definition to check if this is a primitive array
	 * difference.
	 * @returns {boolean}
	 * A value indicating if this is a primitive array difference.
	 * @memberof DifferenceDisplayComponent
	 */
	private isPrimitiveArrayDifference(
		mappedDifference: IMappedDifferenceDefinition): boolean
	{
		if (mappedDifference.difference?.differenceType !==
			AppConstants.differenceTypes.array
			|| (!isArray(mappedDifference.difference?.originalValue)
				&& !isArray(mappedDifference.difference?.updatedValue)))
		{
			return false;
		}

		return !isObject(mappedDifference.difference?.originalValue[0])
			&& !isObject(mappedDifference.difference?.updatedValue[0]);
	}

	/**
	 * Gets a mapped model display definition from the existing mapped
	 * difference level schema definition. These values are used
	 * to fine tune outputs displayed in this component.
	 *
	 * @returns {IModelDisplayDefinition}
	 * The model display definition that represents this current mapped
	 * difference schema definition.
	 * @memberof DifferenceDisplayComponent
	 */
	private mapModelDisplay(): IModelDisplayDefinition
	{
		const schemaDefinition: any =
			this.mappedDifference.schemaDefinition;
		const label: string =
			schemaDefinition.description
				?? schemaDefinition.items?.description;

		const modelDisplayDefinition: IModelDisplayDefinition =
			 <IModelDisplayDefinition>
			{
				schemaDefinition:
					schemaDefinition,
				label:
					typeof label === AppConstants.propertyTypes.string
						? label
						: null,
				format:
					schemaDefinition.outputFormat
						?? schemaDefinition.items?.outputFormat,
				formatPromise:
					schemaDefinition.outputPromise
						?? schemaDefinition.items?.outputPromise,
				icon:
					schemaDefinition.icon
						?? schemaDefinition.items?.icon,
				iconFunction:
					schemaDefinition.iconFunction
						?? schemaDefinition.items?.iconFunction,
				descriptionPropertyType:
					schemaDefinition.descriptionPropertyType
						?? schemaDefinition.items?.descriptionPropertyType,
				descriptionPromise:
					schemaDefinition.descriptionPromise
						?? schemaDefinition.items?.descriptionPromise,
				descriptionPropertyKeys:
					schemaDefinition.descriptionPropertyKeys
						?? schemaDefinition.items?.descriptionPropertyKeys
			};

		return modelDisplayDefinition;
	}
}