/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	WorkflowActionRequisitesApiService
} from '@api/services/workflow/workflow-action-requisites.api.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

@Component({
	selector: 'app-entity-actions',
	templateUrl: './entity-actions.component.html',
	styleUrls: [
		'./entity-actions.component.scss'
	]
})

/**
 * A component representing an instance of the system entity actions
 * component.
 *
 * @export
 * @class EntityActionsComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class EntityActionsComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Creates an instance of an EntityActionsComponent.
	 *
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The api service used to get the entity definition data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The api service used to get entity version data.
	 * @param {WorkflowActionDefinitionsApiService}
	 * workflowActionDefinitionApiService
	 * The api service used to get the workflow action definitions data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router service.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityActionsComponent
	 */
	public constructor(
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public workflowActionDefinitionApiService:
			WorkflowActionDefinitionsApiService,
		public workflowActionRequisitesApiService: WorkflowActionRequisitesApiService,
		public route: ActivatedRoute,
		public router: Router,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntityActionsComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * Gets or sets the entity layout types.
	 *
	 * @type {IEntityLayoutType[]}
	 * @memberof EntityActionsComponent
	 */
	public entityLayoutTypes: IEntityLayoutType[];

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {IEntityType}
	 * @memberof EntityActionsComponent
	 */
	public entityType: IEntityType;

	/**
	 * Gets or sets the entity version.
	 *
	 * @type {IEntityVersion}
	 * @memberof EntityActionsComponent
	 */
	public entityVersion: IEntityVersion;

	/**
	 * Gets or sets the table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityActionsComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the entity definition id.
	 *
	 * @type {number}
	 * @memberof EntityActionsComponent
	 */
	public entityDefinitionId: number;

	/**
	 * Initializes the component to set the page variables
	 * and setup the table definitions.
	 *
	 * @async
	 * @memberof EntityActionsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		this.setupTableDefinitions();
	}

	/**
	 * Sets the page variables needed for this component.
	 *
	 * @async
	 * @memberof EntityActionsComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.entityVersion =
			await this.entityVersionApiService
				.get(this.entityDefinition.versionId);

		this.tableFilterQuery = 'EntityTypeId'
			+ ` eq ${this.entityDefinition.typeId}`
			+ ' and EntityVersionId'
			+ ` eq ${this.entityDefinition.versionId}`;

		this.availableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: 1
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: 2
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets the common table definitions needed for this component.
	 *
	 * @async
	 * @memberof EntityActionsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.tableDefinitions =
		{
			tableTitle: 'Workflow Actions',
			hideTableTitle: true,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Workflow Action'),
			objectSearch: {
				filter: this.tableFilterQuery,
				orderBy: `Name ${AppConstants.sortDirections.ascending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise:
				async (objectSearch: IObjectSearch) =>
					this.workflowActionDefinitionApiService
						.query(
							objectSearch.filter,
							objectSearch.orderBy,
							objectSearch.offset,
							objectSearch.limit),
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			actions: {
				create: {
					layout: [
						{
							key: 'name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							props: {
								label: 'Action Name',
								disabled: false,
								required: true
							},
							asyncValidators: {
								uniqueName: {
									expression: (
										control: UntypedFormControl) =>
										this.uniqueName(control),
									message: 'Existing Action Name.'
								}
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: async() => this.createAction()

						}]
				},
				update: {
					disabledExpandItem: true,
					items: [
						{
							command: () =>
							{
								this.router.navigate(
									[
										'admin/entity/actionDefinition/edit',
										this.entityDefinitionId
									],
									{
										queryParams: {
											routeData: ObjectHelper
												.mapRouteData(
													{
														actionId:
															this.commonTableContext
																.source
																.selectedItem
																.id
													})
										}
									});
							}
						}
					]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async() => this.deleteAction(),
						}],
					deleteStatement: () => this.getDeleteStatement(),
				}
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * creates a new entity layout.
	 *
	 * @async
	 * @memberof EntityActionsComponent
	 */
	private async createAction(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const createEntityAction: Function =
			async() =>
			{
				const newAction: IWorkflowActionDefinitions =
					<IWorkflowActionDefinitions>
					{
						entityTypeId: this.entityType.id,
						entityVersionId: this.entityVersion.id,
						name: selectedItem.name,
						description: AppConstants.empty,
						failureActionId: 1,
						classReference: 'WaterStreet.Nautix.Data.Workflow.Actions',
						classDefinition: 'namespace WaterStreet.Nautix.Data.Workflow.Actions\n'
							+ '{\n'
							+ '\tusing WaterStreet.Nautix.Data.Workflow;\n'
							+ '\tusing WaterStreet.Nautix.Public;\n'
							+ '\tusing WaterStreet.Nautix.Public.Workflow;\n\r'
							+ '\tpublic class YourWorkflowAction\n'
							+ '\t\t: BaseAction\n'
							+ '\t{\n'
							+ '\t\tpublic override ActionResponse Execute()\n'
							+ '\t\t{\n'
							+ '\t\t\treturn Ok(new object());\n'
							+ '\t\t}\n'
							+ '\t}\n'
							+ '}'
					};

				const newActionDefinitionId: number =
						await this.workflowActionDefinitionApiService
							.create(newAction);

				this.router.navigate(
					[
						'admin/entity/actionDefinition/edit',
						this.entityDefinitionId
					],
					{
						queryParams:
							{
								routeData:
									ObjectHelper.mapRouteData(
										{
											actionId:
												newActionDefinitionId
										})
							}
					});
			};

		await this.activityService.handleActivity(
			new Activity(
				createEntityAction(),
				'<strong>Adding Action</strong>',
				'<strong>Added Action</strong>',
				`Action ${selectedItem.name}`
					+ ` was added to Entity ${this.entityType.name}.`,
				`Action ${selectedItem.name}`
					+ ` was not added to Entity ${this.entityType.name}.`));
	}

	/**
	 * Gets the delete statement.
	 *
	 * @async
	 * @return {Promise<string>}
	 * The delete statement string.
	 * @memberof EntityActionsComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const existingRequisites =
			await this.workflowActionRequisitesApiService
				.query(
					'ActionDefinitionId eq '
						+ `${selectedItem.id} `
						+ 'Or RequisiteActionDefinitionId eq '
						+ `${selectedItem.id}`,
					AppConstants.empty);

		if (existingRequisites.length > 0)
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[0].disabled = true;

			return 'Unable to delete Worflow Action Definition'
				+ ` ${selectedItem.id}`
				+ ` ${selectedItem.name}`
				+ ' due to existing requisite relationship'
				+ ' action definition id'
				+ ` ${existingRequisites[0].actionDefinitionId}`
				+ ' and requisite action definition id'
				+ ` ${existingRequisites[0].requisiteActionDefinitionId}.`;
		}
		else
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[0].disabled = false;

			return 'Confirm you are about to delete Workflow Action Definition'
				+ ` ${selectedItem.id}`
				+ ` ${selectedItem.name}.`;
		}
	}

	/**
	 * Deletes an existing entity action.
	 *
	 * @async
	 * @memberof EntityActionsComponent
	 */
	private async deleteAction(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const deleteAction: Function =
			async() =>
			{
				await this.workflowActionDefinitionApiService
					.delete(selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteAction(),
				'<strong>Deleting Action</strong>',
				'<strong>Deleted Action</strong>',
				`Action ${selectedItem.name}`
					+ ` was deleted from Entity ${this.entityType.name}.`,
				`Action ${selectedItem.layoutTypeName}`
					+ ` was not deleted from Entity ${this.entityType.name}.`));
	}

	/**
	 * Validates the name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Form Control used to get the input value
	 * @returns {Promise<boolean>}
	 * The Validator promise result.
	 * @memberof EntityActionsComponent
	 */
	private async uniqueName(control: UntypedFormControl): Promise<boolean>
	{
		const existingActions: IWorkflowActionDefinitions[] =
			await this.workflowActionDefinitionApiService
				.query(
					'entityTypeId'
						+ ` eq ${this.entityDefinition.typeId}`
						+ ' and entityVersionId'
						+ ` eq ${this.entityDefinition.versionId}`
						+ ' and name'
						+ ` eq '${control.value}'`,
					AppConstants.empty);

		return Promise.resolve(existingActions.length === 0);
	}
}