/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	PowerBiWizardStepDirective
} from '@bi/directives/power-bi-wizard-step.directive';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ReportConstants
} from '@shared/constants/report.constants';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IGroupedDropdownOption
} from '@shared/interfaces/application-objects/grouped-dropdown-option.interface';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';
import {
	IPowerBiDataset
} from '@shared/interfaces/reports/power-bi/power-bi-dataset.interface';
import {
	IPowerBiReportDefinition
} from '@shared/interfaces/reports/power-bi/power-bi-report-definition.interface';
import {
	IPowerBiReport
} from '@shared/interfaces/reports/power-bi/power-bi-report.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	PowerBiService
} from '@shared/services/power-bi.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-clone-power-bi-report',
	templateUrl: './clone-power-bi-report.component.html'
})

/**
 * A component representing a clone power bi report step.
 *
 * @export
 * @class ClonePowerBiReportComponent
 * @extends {PowerBiWizardStepDirective<IPowerBiDataset>}
 * @implements {IDynamicComponent<DynamicWizardComponent, any>}
 */
export class ClonePowerBiReportComponent
	extends PowerBiWizardStepDirective<IPowerBiDataset>
	implements IDynamicComponent<DynamicWizardComponent, any>
{
	/**
	 * Initializes a new instance of the clone power bi report component.
	 *
	 * @param {PowerBiService} powerBiService
	 * The power bi display service used for external power bi reports.
	 * @param {PowerBiApiService} powerBiApiService
	 * The power bi api service used for external power bi reports.
	 * @param {DisplayComponentService} displayComponentService
	 * The display component service used in this component.
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The display component definition api service used in this component.
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The display component instance api service used in this component.
	 * @param {DisplayComponentTypeApiService}
	 * displayComponentTypeApiService
	 * The display component type api service used in this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @param {SessionService} sessionService
	 * The security group api service used in this component.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions.
	 * @param {ActivatedRoute} route
	 * The site layout service to use for responsive layouts.
	 * @param {Router} router
	 * The router used in this component for site navigation.
	 * @memberof ClonePowerBiReportComponent
	 */
	public constructor(
		public powerBiService: PowerBiService,
		public powerBiApiService: PowerBiApiService,
		public displayComponentService: DisplayComponentService,
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public displayComponentTypeApiService:
			DisplayComponentTypeApiService,
		public siteLayoutService: SiteLayoutService,
		public optionsFactory: OptionsFactory,
		public sessionService: SessionService,
		public activityService: ActivityService,
		public router: Router,
		public route: ActivatedRoute)
	{
		super(
			powerBiService,
			powerBiApiService,
			displayComponentDefinitionApiService,
			displayComponentTypeApiService,
			siteLayoutService);
	}

	/**
	 * Gets or sets the lookup type used when gathering available values of
	 * the api data type.
	 *
	 * @type {string}
	 * @memberof ClonePowerBiReportComponent
	 */
	public lookupType: string = ReportConstants.powerBiObjectTypes.dataset;

	/**
	 * Gets or sets the truthy that defines if the initial validation has
	 * completed. This is used to handle async valid returns on form display
	 * which will not fire status changes.
	 *
	 * @type {boolean}
	 * @memberof ClonePowerBiReportComponent
	 */
	public initialValidationComplete: boolean = false;

	/**
	 * Gets the constant value used to set and store a report name in layout
	 * data.
	 *
	 * @type {string}
	 * @memberof ClonePowerBiReportComponent
	 */
	private readonly reportNameKey: string =
		'data.reportDefinition.newReportName';

	/**
	 * Gets the constant value used to set and store a
	 * BI Utility Menu in layout
	 *
	 * @type {string}
	 * @memberof ClonePowerBiReportComponent
	 */
	private readonly businessIntelligenceUtilityMenu: string =
		'BiModule.UtilityMenu';

	/**
	 * Gets the constant value for the empty report name.
	 *
	 * @type {string}
	 * @memberof ClonePowerBiReportComponent
	 */
	private readonly emptyReportTemplateName: string =
		'Blank';

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof ClonePowerBiReportComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.context.source
			.addToNext(
				this.cloneAndNavigate.bind(this));

		if (this.useExisting === false)
		{
			await this.loadEmptyTemplate();
		}

		const cloneSuffix: string = 'Clone';
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const existingReportDefinition: IPowerBiReportDefinition =
			this.context.source.activeMenuItem
				.currentData.data.reportDefinition;
		const reportCloneName: string =
			this.useExisting === true
				? existingReportDefinition.reportName
					.replace(
						` ${cloneSuffix}`,
						AppConstants.empty)
				: 'New Report';
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions();

		const existingReportName: string =
			this.useExisting === true
				? `${reportCloneName} ${cloneSuffix}`
				: reportCloneName;

		this.context.source.addOrUpdateStepData(
			{
				reportDefinition:
					<IPowerBiReportDefinition>
					{
						...existingReportDefinition,
						newDatasetId:
							!AnyHelper.isNullOrWhitespace(
								currentData.reportDefinition.newDatasetId)
								? currentData.reportDefinition.newDatasetId
								: existingReportDefinition.datasetId,
						newGroupId: currentData.customGroupId,
						newReportName:
							!AnyHelper.isNullOrWhitespace(
								currentData.reportDefinition.newReportName)
								? currentData.reportDefinition.newReportName
								: existingReportName
					},
				selectedSecurityGroups:
					!AnyHelper.isNull(
						currentData.selectedSecurityGroups)
						? currentData.selectedSecurityGroups
						: []
			});

		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: this.reportNameKey,
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props:
					{
						label: 'Report Name',
						tooltip: 'The name of the new report.',
						maxLength: 100,
						required: true
					},
					validators:
					{
						noUnderscoreStart:
						{
							expression:
								this.validateNoUnderscoreStart.bind(this),
							message:
								'Report names cannot begin with an underscore.'
						}
					},
					asyncValidators:
					{
						uniqueReportName:
						{
							expression:
								this.validateUniqueReportName.bind(this),
							message: 'Report name already exists.'
						}
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.reportDefinition.newDatasetId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props:
					{
						label: 'Dataset',
						tooltip: 'The dataset of the new report.',
						options:
							this.groupedSelectionOptions.map(
								(groupedOption: IGroupedDropdownOption) =>
								{
									const groupedIdOptions:
										IDropdownOption[] = [];
									groupedOption.items.forEach(
										(option: IDropdownOption) =>
										{
											groupedIdOptions.push(
												<IDropdownOption>
												{
													label: option.label,
													value: option.value.id
												});
										});
									groupedOption.items = groupedIdOptions;

									return groupedOption;
								}),
						group: true,
						required: true
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.selectedSecurityGroups',
					type: FormlyConstants.customControls.customMultiSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props:
					{
						label: 'Report Viewer(s)',
						tooltip: 'Set the Security Groups that will be'
							+ ' allowed to view the report.',
						options: securityGroupOptions
					}
				},
				{
					key: 'data.public',
					type: FormlyConstants.customControls.customInputSwitch,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props:
					{
						label: 'Publish',
						tooltip: 'If Public is enabled, then that means'
							+ ' the report is published and accessible to'
							+ ' the specified Report Viewers. If Public is'
							+ ' disabled, then only the Report Owner\'s'
							+ '  will be able to see the new report.',
						default: true,
						change: (field: FormlyFieldConfig) =>
						{
							setTimeout( () =>
							{
								this.publicRulesValidator(
									field.formControl.value);
							});
						}
					}
				},
				{
					key: 'data.ownershipSecurityGroupId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props:
					{
						label: 'Report Owner(s)',
						tooltip: 'Set Additional Report Owner\'s'
							+ ' that will be allowed to edit'
							+ ' the report.',
						placeholder: 'Select an ownership group',
						options: securityGroupOptions,
						showClear: true
					}
				}
			];

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Validates that new report names do not begin with an underscore
	 * which would set them as not searchable due to our underscore
	 * name filters on reports and datasets.
	 *
	 *
	 * @param {FormControl} control
	 * The control to be validated.
	 * @returns {boolean}
	 * A value representing that the value of this control starts with
	 * and underscore.
	 * @memberof ClonePowerBiReportComponent
	 */
	public validateNoUnderscoreStart(
		control: UntypedFormControl): boolean
	{
		return control.value.indexOf(
			AppConstants.characters.underscore) !== 0;
	}

	/**
	 * Validates business rules for Public
	 * or private reports
	 *
	 * @param {boolean} isPublicEnabled
	 * isPublicEnabled flag.
	 * @memberof ClonePowerBiReportComponent
	 */
	public publicRulesValidator(
		isPublicEnabled: boolean): void
	{
		this.dynamicFormlyLayout.forEach(
			(definition) =>
			{
				let isDisabled: boolean = false;

				if (definition.key === 'data.reportDefinition.newDatasetId'
					|| (isPublicEnabled === false
						&& (definition.key === 'data.selectedSecurityGroups'
							|| definition.key ===
								'data.ownershipSecurityGroupId')))
				{
					isDisabled = true;
				}

				definition.props.disabled =
					isDisabled;
			});
	}

	/**
	 * Validates that the report name being selected is unique in power bi
	 * as well as when we create the display component instance.
	 *
	 * @param {FormControl} control
	 * The control to be validated for a unique name value.
	 * @returns {Promise<boolean>}
	 * A value representing the is valid value of this control.
	 * @async
	 * @memberof ClonePowerBiReportComponent
	 */
	public async validateUniqueReportName(
		control: UntypedFormControl): Promise<boolean>
	{
		const newReportDisplayComponentName: string =
			this.getReportDisplayComponentName(
				control.value);
		const newBasePageDisplayComponentName: string =
			`${AppConstants.displayComponentTypes.basePage}.`
				+ `${newReportDisplayComponentName}`;
		const selectedGroupId: string =
			this.context.source.activeMenuItem
				.currentData.data.customGroupId;
		const matchingReportNames: IPowerBiReport[] =
			(await this.powerBiApiService
				.groupLevelData<IPowerBiReport>(
					selectedGroupId))
				.filter((report: IPowerBiReport) =>
					report.name ===
						StringHelper.trim(control.value));
		const matchingDisplayNames: IDisplayComponentInstance[] =
			<IDisplayComponentInstance[]>
			await this.displayComponentInstanceApiService
				.query(
					`${AppConstants.commonProperties.name} eq `
						+ `'${newReportDisplayComponentName}'`,
					`${AppConstants.commonProperties.id}`);
		const matchingBasePageNames: IDisplayComponentInstance[] =
			<IDisplayComponentInstance[]>
			await this.displayComponentInstanceApiService
				.query(
					`${AppConstants.commonProperties.name} eq `
						+ `'${newBasePageDisplayComponentName}'`,
					`${AppConstants.commonProperties.id}`);

		const isValid: boolean =
			(matchingReportNames.length === 0
				&& matchingDisplayNames.length === 0
				&& matchingBasePageNames.length === 0);

		// Fire a validity check for a starting valid value.
		if (this.initialValidationComplete === false
			&& control.valid === false
			&& isValid === true)
		{
			control.markAsTouched();
			control.updateValueAndValidity();
			this.initialValidationComplete = true;
		}

		return matchingReportNames.length === 0
			&& matchingDisplayNames.length === 0
			&& matchingBasePageNames.length === 0;
	}

	/**
	 * Loads the existing empty report template from the selected standard
	 * workspace. This will populate the report definition based on that
	 * matching report.
	 *
	 * @async
	 * @memberof ClonePowerBiReportComponent
	 */
	public async loadEmptyTemplate(): Promise<void>
	{
		const emptyReportTemplateWorkspace: string =
			this.context.source.activeMenuItem
				.currentData.data.standardGroupId;
		const matchingReports: IPowerBiReport[] =
			(await this.powerBiApiService
				.groupLevelData<IPowerBiReport>(
					emptyReportTemplateWorkspace))
				.filter((report: IPowerBiReport) =>
					report.name ===
						this.emptyReportTemplateName);

		this.context.source.addOrUpdateStepData(
			{
				report: matchingReports[0]
			});

		await this.createReportDefinition(
			matchingReports[0],
			false,
			emptyReportTemplateWorkspace);
	}

	/**
	 * Given a power bi report definition, this method will clone that report
	 * and realign the sent report definition to now point to this new cloned
	 * report.
	 *
	 * @async
	 * @memberof ClonePowerBiReportComponent
	 */
	public async cloneSelectedReport(): Promise<void>
	{
		const reportDefinition: IPowerBiReportDefinition =
			this.context.source.activeMenuItem
				.currentData.data.reportDefinition;
		const clonedReport: IPowerBiReport =
			await this.powerBiApiService
				.cloneExistingReport(
					this.context.source.activeMenuItem
						.currentData.data.report,
					reportDefinition.groupId,
					reportDefinition.newReportName,
					reportDefinition.newDatasetId,
					reportDefinition.newGroupId);

		reportDefinition.reportId = clonedReport.id;
		reportDefinition.reportName = clonedReport.name;
		reportDefinition.datasetId = clonedReport.datasetId;
		reportDefinition.datasetGroupId =
			this.getDatasetGroupId(clonedReport.datasetId);
		reportDefinition.groupId = reportDefinition.newGroupId;

		this.context.source
			.addOrUpdateStepData(
				<object>
				{
					reportDefinition: reportDefinition
				});
	}

	/**
	 * Given a power bi report definition, this method will translate that
	 * value into a display component instance for both the external report
	 * component and the generic base page component.
	 *
	 * @async
	 * @memberof ClonePowerBiReportComponent
	 */
	public async createDisplayComponents(): Promise<void>
	{
		const buildReportSecurityGroup: number =
			(await this.optionsFactory
				.getSecurityGroupOptions())
				.find((item: IDropdownOption) =>
					item.label === 'Bi_Module_ReportBuilders').value;

		const activeMenuItemData: any =
			this.context.source.activeMenuItem
				.currentData.data;
		const displayComponentDefinition: IDisplayComponentDefinition =
			await this.displayComponentService
				.populateDisplayComponentDefinition(
					AppConstants.displayComponentTypes
						.reportPowerBiCustom,
					DynamicComponentLookup.supportedTypes
						.externalReportComponent);
		const basePageDisplayComponentDefinition: IDisplayComponentDefinition =
			await this.displayComponentService
				.populateDisplayComponentDefinition(
					AppConstants.displayComponentTypes
						.basePage,
					DynamicComponentLookup.supportedTypes
						.genericBasePageComponent);

		const reportDefinition: IPowerBiReportDefinition =
			activeMenuItemData.reportDefinition;
		const reportDisplayComponentName: string =
			this.getReportDisplayComponentName(
				reportDefinition.reportName);
		const reportDisplayJson: object =
			<object>
			{
				interpolationData:
				{
					datasetGroupId: reportDefinition.datasetGroupId,
					externalReportType: reportDefinition.externalReportType,
					reportName: reportDefinition.reportName,
					reportType: reportDefinition.reportType
				}
			};
		const basePageDisplayJson: object =
			<object>
			{
				interpolationData:
				{
					displayComponent: reportDisplayComponentName,
					pageButtonBar: AppConstants.empty,
					loadingPageTitle: 'Report',
					pageTitleTemplate: '${data.reportDefinition.reportName}',
					pageContextMenu: AppConstants.empty,
					pageUtilityMenu: this.businessIntelligenceUtilityMenu,
					pageInformationMenu: AppConstants.empty
				}
			};

		const reportDisplayComponent: IDisplayComponentInstance =
			<IDisplayComponentInstance>
			{
				definitionId: displayComponentDefinition.id,
				description: 'Built Report',
				jsonData: JSON.stringify(reportDisplayJson),
				name: reportDisplayComponentName,
				typeId: displayComponentDefinition.typeId,
				public: activeMenuItemData.public,
				ownershipSecurityGroupId:
					AnyHelper
						.isNullOrWhitespace(activeMenuItemData
							.ownershipSecurityGroupId)
						? buildReportSecurityGroup
						: activeMenuItemData.ownershipSecurityGroupId
			};
		const basePageDisplayComponent: IDisplayComponentInstance =
			<IDisplayComponentInstance>
			{
				definitionId: basePageDisplayComponentDefinition.id,
				description: 'Built Base Page',
				jsonData: JSON.stringify(basePageDisplayJson),
				name: AppConstants.displayComponentTypes.basePage
					+ `.${reportDisplayComponentName}`,
				typeId: basePageDisplayComponentDefinition.typeId,
				public: activeMenuItemData.public,
				ownershipSecurityGroupId:
					AnyHelper
						.isNullOrWhitespace(
							activeMenuItemData.ownershipSecurityGroupId)
						? buildReportSecurityGroup
						: activeMenuItemData.ownershipSecurityGroupId
			};

		this.context.source
			.addOrUpdateStepData(
				<object>
				{
					newDisplayComponent: reportDisplayComponent,
					newBasePageDisplayComponent: basePageDisplayComponent
				});
	}

	/**
	 * This method extends the wizard steps existing next action.
	 * This will clone a selected report definition with the selected on screen
	 * values, create both the external and base page display components,
	 * and also add mapped security groups to each role selected in the
	 * permissions dropdown to both display components. Upon completion
	 * this will navigate to the newly created report base page.
	 *
	 * @async
	 * @memberof ClonePowerBiReportComponent
	 */
	public async cloneAndNavigate(): Promise<void>
	{
		await this.cloneSelectedReport();
		await this.createDisplayComponents();

		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const buildReportSecurityGroup: number =
			(await this.optionsFactory
				.getSecurityGroupOptions())
				.find((item: IDropdownOption) =>
					item.label === 'Bi_Module_ReportBuilders').value;

		 if (currentData.selectedSecurityGroups.length === 0)
		 {
			currentData.selectedSecurityGroups =
				[
					buildReportSecurityGroup
				];
		 }

		const newReportDisplayComponentId: number =
			await this.displayComponentInstanceApiService
				.create(currentData.newDisplayComponent);
		const newBasePageDisplayComponentId: number =
			await this.displayComponentInstanceApiService
				.create(currentData.newBasePageDisplayComponent);

		const initialPromiseArray: Promise<any>[] = [];
		currentData.selectedSecurityGroups.forEach(
			(securityGroupId: number) =>
			{
				initialPromiseArray.push(
					this.displayComponentInstanceApiService
						.createSecurityGroupMap(
							newReportDisplayComponentId,
							securityGroupId));
			});
		currentData.selectedSecurityGroups.forEach(
			(securityGroupId: number) =>
			{
				initialPromiseArray.push(
					this.displayComponentInstanceApiService
						.createSecurityGroupMap(
							newBasePageDisplayComponentId,
							securityGroupId));
			});

		const newReportName: string =
			this.context.source.activeMenuItem.currentData.data
				.reportDefinition.newReportName;

		await this.activityService.handleActivity(
			new Activity(
				new Promise<void>(
					async(resolve) =>
					{
						await Promise.all(
							initialPromiseArray);

						this.context.source.storeData(
							this.context.source.activeMenuItem.currentData);

						history.pushState(
							null,
							AppConstants.empty,
							this.router.url);

						this.dynamicFormlyLayout = [];

						this.router.navigate(
							[
								AppConstants.moduleNames.bi,
								AppConstants.route.display,
								currentData.newBasePageDisplayComponent.name,
								AppConstants.viewTypes.edit
							],
							{
								replaceUrl: true
							});

						resolve();
					}),
				'<strong>Creating Report</strong>',
				'<strong>Created A Report</strong>',
				`${newReportName} was created.`,
				`${newReportName} was not created.`));
	}

	/**
	 * Parses the selected dataset and finds the associated group id
	 * for this dataset.
	 *
	 * @param {string} datasetId
	 * The dataset id to find the matching group id for.
	 * @returns {string}
	 * The selected datasets group id.
	 * @memberof ClonePowerBiReportComponent
	 */
	public getDatasetGroupId(
		datasetId: string): string
	{
		let groupId: string;
		this.groupedSelectionOptions.forEach(
			(groupedOption: IGroupedDropdownOption) =>
			{
				const matchingGroupedItems: IDropdownOption[] =
					groupedOption.items.filter(
						(option: IDropdownOption) =>
							option.value === datasetId);

				if (matchingGroupedItems.length > 0)
				{
					groupId = groupedOption.value;
				}
			});

		return groupId;
	}

	/**
	 * Returns a formatted string name for the display component based on the
	 * alphanumeric report name.
	 *
	 * @param {string} reportName
	 * The dataset id to find the matching group id for.
	 * @returns {string}
	 * The display component name suffix that this will be created under.
	 * @memberof ClonePowerBiReportComponent
	 */
	private getReportDisplayComponentName(
		reportName: string): string
	{
		return 'Report.PowerBi.'
			+ StringHelper.getCleanedValue(
				reportName,
				[
					AppConstants.commonRegex.alphaNumericOnly
				]);
	}
}