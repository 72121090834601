/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Location
} from '@angular/common';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityArrayHelper
} from '@entity/helpers/entity-array-helper';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	ITimelineItem
} from '@shared/interfaces/application-objects/timeline-item.interface';
import {
	IEntityInstanceHistory
} from '@shared/interfaces/entities/entity-instance-history.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: [
		'./history.component.scss'
	]
})

/**
 * A component representing context level history data.
 *
 * @export
 * @class HistoryComponent
 * @extends {DrawerListDirective<IEntityInstance>}
 * @implements {IDynamicComponent<Component, any>}
 * @implements {IOwnershipGuardComponent}
 */
export class HistoryComponent
	extends DrawerListDirective<ITimelineItem>
	implements OnInit, IDynamicComponent<Component, any>,
		IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the history component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {ClaimsService} claimsService
	 * The claims service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	 * @memberof HistoryComponent
	 */
	public constructor(
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public claimsService: ClaimsService,
		public sessionService: SessionService,
		public location: Location)
	{
		super();
	}

	/**
	 * Gets or sets the loading value of this component.
	 *
	 * @type {boolean}
	 * @memberof HistoryComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the entity definition for this component.
	 *
	 * @type {EntityDefinition}
	 * @memberof HistoryComponent
	 */
	public entityDefinition: EntityDefinition;

	/**
	 * Gets or sets the comparison object friendly name.
	 *
	 * @type {string}
	 * @memberof HistoryComponent
	 */
	public comparisonObjectName: string = AppConstants.empty;

	/**
	 * Gets or sets the business logic history array.
	 *
	 * @type {IEntityInstanceHistory[]}
	 * @memberof HistoryComponent
	 */
	public businessLogicHistory: IEntityInstanceHistory[] = [];

	/**
	 * Gets or sets the excluded difference properties. If sent as a partial
	 * all instances that contain this will be removed otherwise exact
	 * property location matches will be excluded.
	 *
	 * @type {string[]}
	 * @memberof HistoryComponent
	 */
	public excludedDifferenceProperties: string[] = [];

	/**
	 * Gets or sets the set of keys and values associated to key dates
	 * in a timeline.
	 *
	 * @type {ITimelineItem[]}
	 * @memberof HistoryComponent
	 */
	public keyDateEvents: ITimelineItem[] = [];

	/**
	 * Gets or sets the set of keys and values associated to key activites
	 * in a timeline.
	 *
	 * @type {ITimelineItem[]}
	 * @memberof HistoryComponent
	 */
	public keyActivityEvents: ITimelineItem[] = [];

	/**
	 * Gets or sets the set of keys and values associated to data changes
	 * in a timeline.
	 *
	 * @type {ITimelineItem[]}
	 * @memberof HistoryComponent
	 */
	public changeEvents: ITimelineItem[] = [];

	/**
	 * Gets the identifier for the view model property of the update user.
	 *
	 * @type {string}
	 * @memberof HistoryComponent
	 */
	private readonly updatedByUser: string = 'updatedByUser';

	/**
	 * Gets the identifier for the view model object description.
	 *
	 * @type {string}
	 * @memberof HistoryComponent
	 */
	private readonly objectDifferenceDescription: string =
		'objectDifferenceDescription';

	/**
	 * Gets the identifier for the view model property of the comparison
	 * record for previous history.
	 *
	 * @type {string}
	 * @memberof HistoryComponent
	 */
	private readonly previousHistoryRecord: string =
		'previousHistoryRecord';

	/**
	 * Gets the identifier for the view model property of difference records.
	 *
	 * @type {string}
	 * @memberof HistoryComponent
	 */
	private readonly previousHistoryRecordDifferences: string =
		'previousHistoryRecordDifferences';

	/**
	 * Gets the identifier for the view model property of a difference record
	 * summary.
	 *
	 * @type {string}
	 * @memberof HistoryComponent
	 */
	private readonly previousHistoryRecordDifferenceSummary: string =
		'previousHistoryRecordDifferenceSummary';

	/**
	 * Gets the icon to display for a key activity event.
	 *
	 * @type {string}
	 * @memberof HistoryComponent
	 */
	private readonly keyActivityIcon: string =
		AppConstants.cssClasses.fontAwesomeKey;

	/**
	 * Gets the icon to display for a key date.
	 *
	 * @type {string}
	 * @memberof HistoryComponent
	 */
	private readonly keyDateIcon: string =
		AppConstants.cssClasses.fontAwesomeCalendar;

	/**
	 * Gets the icon to display for a change record.
	 *
	 * @type {string}
	 * @memberof HistoryComponent
	 */
	private readonly changeEventIcon: string =
		AppConstants.cssClasses.fontAwesomeHistory;

	/**
	 * Gets the offset to use to ensure the first key date event will
	 * be the create record.
	 *
	 * @type {number}
	 * @memberof HistoryComponent
	 */
	private readonly createDateOffset: number = 5;

	/**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @async
	 * @memberof HistoryComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!(this.context.source instanceof EntityInstanceComponent))
		{
			return;
		}

		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;

		this.entityDisplayName = 'History';
		this.comparisonObjectName =
			StringHelper.getEntityTypeTitle(
				entityInstanceComponent.entityType.displayName);

		this.entityDefinition =
			entityInstanceComponent.entityDefinition;

		await this.setSecurityDefinitions(
			entityInstanceComponent.id,
			entityInstanceComponent.entityType.group,
			entityInstanceComponent.entityDefinition,
			entityInstanceComponent.entityInstanceApiService,
			entityInstanceComponent.entityTypeApiService);

		await this.setBusinessLogicHistory(
			entityInstanceComponent);

		await this.createHistoryTimelineEvents(
			entityInstanceComponent);

		this.itemActions = [];
		this.context.data =
			{
				...this.context.data,
				drawerComponent: this
			};

		this.displayMode = this.displayModes.timeline;
		this.loading = false;
	}

	/**
	* Implements the ownership guard interface.
	* This will calculate drawer ownership permissions.
	*
	* @async
	* @returns {Promise<boolean>}
	* A value signifying whether or not access is allowed to this drawer.
	* @memberof HistoryComponent
	*/
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		// Entity instance (Source Component) access allows history access.
		return true;
	}

	/**
	* Handles the item selected event sent from the history timeline.
	* This will alter the view mode to display the selected item details.
	*
	* @param {ITimelineItem} item
	* The selected item to display.
	* @memberof HistoryComponent
	*/
	public itemSelected(
		item: ITimelineItem): void
	{
		this.selectedItem = item;
		this.displayMode = AppConstants.displayMode.view;
	}

	/**
	 * Sets the comparison object name for differences displays
	 *
	 * @param {string[]} definitionExcludedDifferenceProperties
	 * If defined in the entity definition, included these one off excluded
	 * properties.
	 * @memberof HistoryComponent
	 */
	private setExcludedDifferenceProperties(
		definitionExcludedDifferenceProperties: string[]): void
	{
		this.excludedDifferenceProperties =
			<string[]>
			[
				// First level data.
				`${this.comparisonObjectName}.`
					+ AppConstants.commonProperties
						.id,
				`${this.comparisonObjectName}.`
					+ AppConstants.commonProperties
						.versionNumber,
				`${this.comparisonObjectName}.`
					+ AppConstants.commonProperties
						.createDate,
				`${this.comparisonObjectName}.`
					+ AppConstants.commonProperties
						.changeDate,
				`${this.comparisonObjectName}.`
					+ AppConstants.commonProperties
						.changedById,
				`${this.comparisonObjectName}.`
					+ AppConstants.commonProperties
						.createdById,
				`${this.comparisonObjectName}.`
					+ AppConstants.commonProperties
						.entityType,

				// Remove at all levels.
				AppConstants.commonProperties
					.resourceIdentifier,
				this.objectDifferenceDescription,

				// Do not track view model data.
				`${this.comparisonObjectName}.`
					+ this.updatedByUser,
				`${this.comparisonObjectName}.`
					+ this.previousHistoryRecord,
				`${this.comparisonObjectName}.`
					+ this.previousHistoryRecordDifferences,
				`${this.comparisonObjectName}.`
					+ this.previousHistoryRecordDifferenceSummary
			];

		// Definition level exclusions.
		this.excludedDifferenceProperties =
			this.excludedDifferenceProperties.concat(
				definitionExcludedDifferenceProperties
					.map((property: string) =>
						property.replace(
							AppConstants.characters.dollarSign,
							this.comparisonObjectName)));
	}

	/**
	 * Sets the business logic history for this component.
	 *
	 * @async
	 * @param {EntityInstanceComponent} entityInstanceComponent
	 * The entity instance component to set the business logic history for.
	 * @memberof HistoryComponent
	 */
	private async setBusinessLogicHistory(
		entityInstanceComponent: EntityInstanceComponent)
	{
		const decorateUpdatedByUsers: boolean = true;
		this.setExcludedDifferenceProperties(
			this.entityDefinition.excludedDifferenceProperties
				?? []);

		const businessLogicHistory: IEntityInstance[] =
			await this.entityService.getHistory(
				entityInstanceComponent.id,
				entityInstanceComponent.entityType.group,
				AppConstants.empty,
				AppConstants.commonProperties.startDate
					+ ` ${AppConstants.sortDirections.ascending}`,
				decorateUpdatedByUsers,
				entityInstanceComponent.entityInstance);

		this.businessLogicHistory =
			EntityArrayHelper.decorateBusinessLogicDifferences(
				this.comparisonObjectName,
				businessLogicHistory,
				this.excludedDifferenceProperties);
	}

	/**
	 * Creates the history timeline events for this component.
	 *
	 * @async
	 * @param {EntityInstanceComponent} entityInstanceComponent
	 * The entity instance component to create the history timeline events for.
	 * @memberof HistoryComponent
	 */
	private async createHistoryTimelineEvents(
		entityInstanceComponent: EntityInstanceComponent): Promise<void>
	{
		this.keyDateEvents =
			[
				<ITimelineItem>
				{
					summary: 'Create Date',
					icon: this.keyDateIcon,
					dateTime:
						DateHelper.addTimeUnit(
							DateHelper.fromUtcIso(
								entityInstanceComponent
									.entityInstance.createDate),
							-this.createDateOffset,
							DateHelper.timeUnits.second)

				},
				...this.mapObjectToTimelineItems(
					entityInstanceComponent.entityInstance.data.keyDates,
					this.keyDateIcon)
			];

		this.context.data.includeActivityName = false;
		this.context.data.detailedDescription = true;
		this.keyActivityEvents =
			await this.mapObjectArrayToTimelineItems(
				entityInstanceComponent.entityInstance.data.keyActivities,
				this.keyActivityIcon);

		this.context.data.includeActivityName = true;
		this.context.data.detailedDescription = false;
		this.createChangeEvents();
	}

	/**
	 * Creates the history record change events for this component.
	 *
	 * @memberof HistoryComponent
	 */
	private createChangeEvents(): void
	{
		if (AnyHelper.isNull(this.businessLogicHistory)
			|| this.businessLogicHistory.length === 0)
		{
			this.changeEvents = [];
		}

		const changeEvents: ITimelineItem[] =
			this.businessLogicHistory
				.map(
					(historyItem: IEntityInstanceHistory) =>
					{
						if (AnyHelper.isNullOrWhitespace(
							historyItem
								.previousHistoryRecordDifferenceSummary))
						{
							return null;
						}

						const changeEvent: ITimelineItem =
							<ITimelineItem>
							{
								summary:
									historyItem
										.previousHistoryRecordDifferenceSummary
										+ ' Updated',
								icon: this.changeEventIcon,
								item: historyItem,
								dateTime:
									DateHelper.fromUtcIso(
										historyItem.changeDate)
							};

						return changeEvent;
					})
				.filter(
					(item: ITimelineItem) =>
						!AnyHelper.isNull(item));

		this.changeEvents = changeEvents;
	}

	/**
	 * Maps an object to a set of timeline items.
	 *
	 * @param {object} objectToMap
	 * The object to map to timeline items.
	 * @param {string} icon
	 * The icon to display for the timeline items.
	 * @returns {ITimelineItem[]}
	 * A set of timeline items mapped from the object.
	 * @memberof HistoryComponent
	 */
	private mapObjectToTimelineItems(
		objectToMap: object,
		icon: string): ITimelineItem[]
	{
		if (AnyHelper.isNull(objectToMap))
		{
			return [];
		}

		const objectEvents: ITimelineItem[] =
			Object.keys(objectToMap)
				.map(
					(key: string) =>
					{
						if (AnyHelper.isNullOrWhitespace(objectToMap[key]))
						{
							return null;
						}

						const timelineItem: ITimelineItem =
							<ITimelineItem>
							{
								summary:
									StringHelper.toProperCase(
										StringHelper.beforeCapitalSpaces(
											key)),
								icon: icon,
								dateTime:
									DateHelper.fromUtcIso(objectToMap[key])
							};

						return timelineItem;
					})
				.filter(
					(item: ITimelineItem) =>
						!AnyHelper.isNull(item));

		objectEvents.sort(
			(itemOne: ITimelineItem, itemTwo: ITimelineItem) =>
				itemOne.dateTime.valueOf()
					- itemTwo.dateTime.valueOf());

		return objectEvents;
	}

	/**
	 * Maps an object array to a set of timeline items.
	 *
	 * @param {any[]} objectArrayToMap
	 * The object array to map to timeline items.
	 * @param {string} icon
	 * The icon to display for the timeline items.
	 * @returns {ITimelineItem[]}
	 * A set of timeline items mapped from the object.
	 * @memberof HistoryComponent
	 */
	private async mapObjectArrayToTimelineItems(
		objectArrayToMap: any[],
		icon: string): Promise<ITimelineItem[]>
	{
		if (AnyHelper.isNull(objectArrayToMap)
			|| objectArrayToMap.length === 0
			|| AnyHelper.isNullOrWhitespace(
				this.entityDefinition.getKeyActivitiesDescriptionPromise()))
		{
			return [];
		}

		const objectEvents: ITimelineItem[] =
			objectArrayToMap.map(
				(item: any) =>
				{
					const timelineItem: ITimelineItem =
						<ITimelineItem>
						{
							summary: item.name,
							icon: icon,
							dateTime: DateHelper.fromUtcIso(item.date),
							item: item
						};

					return timelineItem;
				});

		const keyActivityPromise: string =
			this.entityDefinition.getKeyActivitiesDescriptionPromise();

		for (const objectEvent of objectEvents)
		{
			this.context.data.item = objectEvent.item;

			objectEvent.reference =
				await StringHelper.transformToDataPromise(
					keyActivityPromise,
					{...this.context});
		}

		return objectEvents;
	}
}