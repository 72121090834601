/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	Component,
	EventEmitter,
	HostListener,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	BaseSessionUserDirective
} from '@operation/directives/base-session-user.directive';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	Subject,
	debounceTime,
	distinctUntilChanged
} from 'rxjs';

@Component({
	selector: 'app-inline-profile',
	templateUrl: './app-profile.component.html',
	styleUrls: ['./app-profile.component.scss']
})

/**
 * A component used to display and handle menu based
 * profile actions and navigation.
 *
 * @export
 * @class AppProfileComponent
 * @extends {BaseSessionUserComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class AppProfileComponent
	extends BaseSessionUserDirective
	implements OnInit, OnDestroy
{
	/**
	 * Creates an instance of an AppProfileComponent.
	 *
	 * @param {SessionService} sessionService
	 * The session service to use for side menu profile displays.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to use for responsive layouts.
	 * @memberof AppProfileComponent
	 */
	public constructor(
		sessionService: SessionService,
		siteLayoutService: SiteLayoutService)
	{
		super(
			siteLayoutService,
			sessionService);
	}

	/**
	 * Gets or sets the close overlay event emitter which is used to send to
	 * listening components that an action was performed.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof AppProfileComponent
	 */
	@Output() public closeOverlays: EventEmitter<void> =
		new EventEmitter();

	/**
	 * Gets or sets the event that will be emitted to all listening components
	 * when the active value of this profile has changed.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof AppProfileComponent
	 */
	@Output() public activeProfileChanged: EventEmitter<boolean> =
		new EventEmitter();

	/**
	 * Gets or sets the value used to define if the profile display
	 * is expanded.
	 *
	 * @type {boolean}
	 * @memberof AppProfileComponent
	 */
	public active: boolean = false;

	/**
	 * Gets or sets the debounced subject which is used to debounce
	 * the active value.
	 *
	 * @type {boolean}
	 * @memberof AppProfileComponent
	 */
	public activeChanged: Subject<boolean> = new Subject<boolean>();

	/**
	 * Sets the debounce time used when settinng the active value.
	 *
	 * @type {number}
	 * @memberof AppProfileComponent
	 */
	public readonly debounceTime: number = 25;

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof AppProfileComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.closeListWhenActive();
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close this list when an associated menu is
	 * opened, such as a sibling navigation menu.
	 *
	 * @memberof AppProfileComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(
		_controlIdentifer: string): void
	{
		this.closeListWhenActive();
	}

	/**
	 * Handles the on initialization event.
	 * This is used to set the active changed debounce.
	 *
	 * @memberof AppProfileComponent
	 */
	public ngOnInit(): void
	{
		this.activeChanged.pipe(
			debounceTime(this.debounceTime),
			distinctUntilChanged())
			.subscribe((newValue: boolean) =>
			{
				this.active = newValue;
				this.activeProfileChanged.emit(this.active);
			});

		this.activeChanged.next(this.active);
	}

	/**
	 * Handles the on destroy event.
	 * This is used to complete the active changed debounce.
	 *
	 * @memberof AppProfileComponent
	 */
	public ngOnDestroy(): void
	{
		this.activeChanged.complete();
	}

	/**
	 * Handles the on profile display click event by setting the
	 * profile display as expanded or collapsed.
	 *
	 * @memberof AppProfileComponent
	 */
	public profileDisplayClick(): void
	{
		this.activeChanged.next(!this.active);
	}

	/**
	 * Handles the close list event sent from the operation menu.
	 * This is used to close this profile operation list and is sent when a
	 * click outside or operation menu action event occurs.
	 *
	 * @memberof AppProfileComponent
	 */
	public closeList(): void
	{
		this.activeChanged.next(false);
	}

	/**
	 * Closes List when active if true.
	 *
	 * @memberof AppProfileComponent
	 */
	private closeListWhenActive(): void
	{
		if (this.active !== true)
		{
			return;
		}

		this.closeList();
	}
}