/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewChecked,
	Component,
	Directive,
	ElementRef,
	HostListener,
	ViewChild
} from '@angular/core';
import {
	OperationButtonTypeConstants
} from '@operation/shared/operation-button-type.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	IDisplayComponentContainer
} from '@shared/interfaces/display-components/display-component-container.interface';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	MenuItem
} from 'primeng/api';
import {
	Subject,
	Subscription
} from 'rxjs';

/* eslint-enable max-len */

@Directive({
	selector: '[BasePage]'
})

/**
 * A directive representing shared logic for displaying
 * a base page component with common utilities.
 *
 * @export
 * @class BasePageDirective
 * @implements {AfterViewChecked}
 */
export class BasePageDirective
implements AfterViewChecked
{
	/**
	 * Creates an instance of a base page with common utilities.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this directive.
	 * @param {DisplayComponentService} displayComponentService
	 * The service used to load and gather display component data.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The factory used to generate display component interfaces.
	 * @memberof BasePageDirective
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public displayComponentService: DisplayComponentService,
		public displayComponentFactory: DisplayComponentFactory)
	{
	}

	/**
	 * Gets or sets the identifier for the div directly above the base
	 * page content.
	 *
	 * @type {string}
	 * @memberof BasePageDirective
	 */
	public static readonly contentTopIdentifier: string =
		'base-page-content-top';

	/**
	 * Gets or sets the additional header content.
	 * This is defined in calling components via the #BasePageTabMenu
	 * attribute. This is requred for each base page tab section.
	 *
	 * @type {ElementRef}
	 * @memberof BasePageDirective
	 */
	@ViewChild('BasePageTabMenu', { read: ElementRef })
	public tabMenu: ElementRef;

	/**
	 * Gets or sets the loading value of this directive.
	 *
	 * @type {boolean}
	 * @memberof BasePageDirective
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the page operation group to load.
	 *
	 * @type {string}
	 * @memberof BasePageDirective
	 */
	public operationGroupName: string;

	/**
	 * Gets or sets the tab items specifying different
	 * data display tabs.
	 *
	 * @type {MenuItem[]}
	 * @memberof BasePageDirective
	 */
	public tabItems: MenuItem[] = [];

	/**
	 * Gets or sets the section items specifying different
	 * data sections.
	 *
	 * @type {MenuItem[]}
	 * @memberof BasePageDirective
	 */
	public sectionItems: MenuItem[] = [];

	/**
	 * Gets or sets the document item heights for different
	 * data sections.
	 *
	 * @type {number[]}
	 * @memberof BasePageDirective
	 */
	public tabItemHeights: number[] = [];

	/**
	 * Gets or sets the current selected tab item.
	 *
	 * @type {MenuItem}
	 * @memberof BasePageDirective
	 */
	public activeTabItem: MenuItem;

	/**
	 * Gets or sets the current selected section item.
	 *
	 * @type {MenuItem}
	 * @memberof BasePageDirective
	 */
	public activeSectionItem: MenuItem;

	/**
	 * Gets or sets the information menu display component instance name.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public informationMenuDisplayComponentInstanceName: string;

	/**
	 * Gets or sets the list of information menu items
	 * to display in this component.
	 *
	 * @type {IInformationMenuItem<any>[]}
	 * @memberof BasePageDirective
	 */
	public informationMenuItems: IInformationMenuItem<any>[] = [];

	/**
	 * Gets or sets the display value for a completed data load for
	 * an async populated information menu.
	 *
	 * @type {boolean}
	 * @memberof BasePageDirective
	 */
	public informationMenuItemsLoaded: boolean = false;

	/**
	 * Gets or sets the observer of scroll height changes.
	 *
	 * @type {Subject<number>}
	 * @memberof BasePageDirective
	 */
	public scrollHeightChangedSubject:
		Subject<number> = new Subject<number>();

	/**
	 * Gets or sets the subscriptions used in this component.
	 *
	 * @type {Subscription}
	 * @memberof BasePageDirective
	 */
	public subscriptions: Subscription = new Subscription();

	/**
	 * Gets or sets the is layout changed value of this component.
	 * This is currently set when the page header height changes
	 * or when the formly display moves to a one column layout.
	 * This can also be set when hiding elements in the business
	 * rules to update the tab menu scroll commands.
	 *
	 * @type {boolean}
	 * @memberof BasePageDirective
	 */
	public layoutChanged: boolean = false;

	/**
	 * Gets or sets the is layout reloaded value of this component.
	 *
	 * @type {boolean}
	 * @memberof BasePageDirective
	 */
	public layoutReloaded: boolean = false;

	/**
	 * Gets or sets the initial setup value of this component.
	 * This is used to fire a one time change on load to ensure
	 * tab menu commands are accurate.
	 *
	 * @type {boolean}
	 * @memberof BasePageDirective
	 */
	public initialSetupInProgress: boolean = true;

	/**
	 * Gets or sets the class of the container for a use available
	 * height content container. This can be set in the implementing
	 * component if you desire the content to be limited to available
	 * content height while using dynamic sized page headers.
	 *
	 * @type {string}
	 * @memberof BasePageDirective
	 */
	public fixedHeightContentClass: string;

	/**
	 * Gets or sets the calculated width for displaying a full tab menu.
	 *
	 * @type {number}
	 * @memberof BasePageDirective
	 */
	public tabMenuWidth: number;

	/**
	 * Gets or sets the boolean value that defines if we should be showing
	 * a navigation dropdown or a set of tab navigation items.
	 *
	 * @type {boolean}
	 * @memberof BasePageDirective
	 */
	public displayNavigationDropdown: boolean = false;

	/**
	 * Gets or sets the boolean value that defines if we are displaying a
	 * parameter filter/chips set.
	 *
	 * @type {boolean}
	 * @memberof BasePageDirective
	 */
	public displayParameterFilter: boolean = false;

	/**
	 * Gets or sets the boolean value sent to the base page to define if it
	 * should reserve the bottom right of the header when no tab navigation
	 * exists.
	 *
	 * @type {boolean}
	 * @memberof BasePageDirective
	 */
	public reserveHeaderBottomRight: boolean;

	/**
	 * Gets the value used by the UI to define at which content
	 * width we should switch to a two column page header layout.
	 *
	 * @type {number}
	 * @memberof BasePageDirective
	 */
	public readonly twoColumnHeaderBreakpoint: number =
		AppConstants.layoutBreakpoints.desktop;

	/**
	 * Gets or sets the ellipsis menu item that will be displayed when we
	 * are collapsing button sets and no ellipsis is already defined.
	 *
	 * @type {MenuItem}
	 * @memberof BasePageDirective
	 */
	public buttonMenuNavigationGroup: MenuItem =
		<MenuItem>
		{
			id: OperationButtonTypeConstants.groupButton,
			items: [],
			label: 'Page Navigation',
			styleClass: 'theme-color selected-bottom-border p-menuitem-link'
		};

	/**
	 * Gets the vertical height used for the header for both the site and the
	 * page.
	 *
	 * @type {number}
	 * @memberof BasePageDirective
	 */
	public get verticalOffsetHeight(): number
	{
		return (this.siteLayoutService.displayTabletView === true
			? AppConstants.staticLayoutSizes.mobileHeaderHeight
			: 0)
			+ DocumentHelper.getBoundingRectangleById(
				document,
				AppConstants.basePageSections.headerIdentifier).height;
	}

	/**
	 * Gets the identifier added to a section title for ID based
	 * lookups.
	 *
	 * @type {Subscription}
	 * @memberof BasePageDirective
	 */
	protected readonly sectionIdentifier: string = 'Section';

	/**
	 * Gets or sets the delay for a debounced subject change for tab
	 * calculations.
	 *
	 * @type {number}
	 * @memberof BasePageDirective
	 */
	protected readonly tabCalculationDebounceDelay: number =
		AppConstants.time.oneHundredMilliseconds;

	/**
	 * Handles the scroll event sent from the window.
	 * This will calculate the current scroll position so that the tab
	 * menu can stay accurate with the current displayed section.
	 *
	 * @memberof BasePageDirective
	 */
	@HostListener(
		WindowEventConstants.scrollEvent)
	public scroll(): void
	{
		if (window.scrollY < 0)
		{
			return;
		}

		this.scrollHeightChangedSubject.next(window.scrollY);
	}

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof BasePageDirective
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		if (this.initialSetupInProgress === false)
		{
			setTimeout(
				() =>
				{
					// Reserve the bottom right for operation button bars
					// or titles if the page navigation row is not used.
					this.reserveHeaderBottomRight =
						this.sectionItems.length === 0
							&& this.displayParameterFilter === true;

					this.setAvailableContentHeight();
					this.setTabDisplay();
					this.setTabHeights(window.scrollY);
				});
		}
	}

	/**
	 * Sets the tab display style between tab navigation and a navigation
	 * dropdown.
	 *
	 * @memberof BasePageDirective
	 */
	public setTabDisplay(): void
	{
		if (this.loading === true
			|| (AnyHelper.isNull(this.tabMenu)
				&& this.displayNavigationDropdown === false))
		{
			return;
		}

		if (AnyHelper.isNull(this.tabMenuWidth))
		{
			this.tabMenuWidth = this.tabMenu.nativeElement.scrollWidth;
		}

		const utilizedFilterWidth: number =
			this.displayParameterFilter === true
				? AppConstants.staticLayoutSizes.headerBottomRightIconWidth
				: 0;
		const utilizedTabletWidth: number =
			AppConstants.staticLayoutSizes.utilityMenuWidth
				+ AppConstants.staticLayoutSizes.collapsedContextMenuWidth
				+ AppConstants.staticLayoutSizes.nestedContentPadding * 3
				+ utilizedFilterWidth;
		const utilizedDesktopWidth: number =
			utilizedTabletWidth
				+ AppConstants.staticLayoutSizes.slimMenuWidth
				+ AppConstants.staticLayoutSizes.nestedContentPadding * 1;
		const utilizedWidth: number = this.siteLayoutService.contentWidth
			- (this.siteLayoutService.displayTabletView === true
				? utilizedTabletWidth
				: utilizedDesktopWidth);

		// If we are showing parameters and chips, split this width.
		const availableTabDisplayWidth: number =
			this.displayParameterFilter === true
				&& this.siteLayoutService.contentWidth >
					this.twoColumnHeaderBreakpoint
				? (this.siteLayoutService.contentWidth
						- utilizedDesktopWidth) / 2
				: utilizedWidth;

		const tooSmall: boolean =
			this.tabMenuWidth > availableTabDisplayWidth;

		if (this.displayNavigationDropdown !== tooSmall)
		{
			this.displayNavigationDropdown = tooSmall;
		}
	}

	/**
	 * On after view checked event.
	 * After the screen is drawn and displayed, this will calculate
	 * the height of each data section that is shown in the tab menu.
	 * These values are used for dynamic scroll events.
	 * @note This method is called on all formly changes and you must
	 * be careful to make sure you limit logic ran in this method.
	 *
	 * @memberof BasePageDirective
	 */
	public ngAfterViewChecked(): void
	{
		if ((this.loading === false
			&& (this.initialSetupInProgress === true
				|| this.layoutChanged === true))
			|| this.layoutReloaded === true)
		{
			this.sectionItems.forEach(
				(menuItem: MenuItem) =>
				{
					this.setScrollToCommand(menuItem);
				});

			this.tabItems.forEach(
				(menuItem: MenuItem,
					index: number) =>
				{
					if (menuItem.items.length === 0)
					{
						menuItem.command =
							() =>
							{
								this.tabSelected({ index: index });
							};
					}

					menuItem.items?.forEach(
						(tabMenuItem: MenuItem) =>
						{
							this.setScrollToCommand(
								tabMenuItem,
								index);
						});
				});

			this.buttonMenuNavigationGroup.items =
				this.tabItems.length > 0
					? <MenuItem[]>
						[
							...this.tabItems
						]
					: <MenuItem[]>
						[
							...this.sectionItems
						];

			this.initialSetupInProgress = false;
			this.layoutReloaded = false;
		}
	}

	/**
	 * Handles the tab select observed event.
	 * Selects the active tab item.
	 *
	 * @param {any} event
	 * The tab selected event sent from the observed action.
	 * @memberof BasePageDirective
	 */
	public tabSelected(
		event: any): void
	{
		this.activeTabItem = this.tabItems[event.index];
	}

	/**
	 * Calculates the available height for content displays. If the value of
	 * fixedHeightContentClass exists and matches with a displayed container,
	 * this will set that container height at runtime.
	 *
	 * @memberof BasePageDirective
	 */
	public setAvailableContentHeight(): void
	{
		if (AnyHelper.isNullOrWhitespace(
			this.fixedHeightContentClass))
		{
			return;
		}

		const reservedHeight: number =
			Math.ceil(
				DocumentHelper.getElementVerticalPositionById(
					document,
					BasePageDirective.contentTopIdentifier))
				+ AppConstants.staticLayoutSizes.standardPadding;

		const dynamicHeightContainer: HTMLElement =
			document.querySelector(
				`.${this.fixedHeightContentClass}`);
		dynamicHeightContainer.style.height =
			`calc(100vh - ${reservedHeight}px)`;
	}

	/**
	 * Calculates the tab heights of each control and then
	 * defines which section is currently at the top of the page.
	 * This is used to keep the tab menu accurate with current settings.
	 *
	 * @param {number} scrollHeight
	 * The current scroll height of the browser window. This is available
	 * as the window.scrollY in this component.
	 * @memberof BasePageDirective
	 */
	public setTabHeights(
		scrollHeight: number): void
	{
		if (scrollHeight < 0)
		{
			return;
		}

		if (this.sectionItems.length > 0
			|| this.activeTabItem?.items.length > 0)
		{
			const tabItemHeights: number[] = [];
			const sectionItems =
				this.activeTabItem?.items || this.sectionItems;

			sectionItems.forEach(
				(menuItem: MenuItem) =>
				{
					tabItemHeights.push(
						Math.floor(
							DocumentHelper.getElementVerticalPositionById(
								document,
								menuItem.id + this.sectionIdentifier)
									+ scrollHeight));
				});

			const activeTabHeight: number =
				Math.max.apply(
					Math,
					tabItemHeights.filter(
						(height: number) =>
							height <= Math.ceil(
								scrollHeight + this.verticalOffsetHeight)));

			const activeTabIndex: number =
				tabItemHeights.indexOf(activeTabHeight);

			this.activeSectionItem =
				sectionItems[
					activeTabIndex > 0
						? activeTabIndex
						: 0];
		}
	}

	/**
	 * Creates an array of information menu items to display in this
	 * component.
	 *
	 * @async
	 * @memberof BasePageDirective
	 */
	public async setupInformationMenuItems(
		pageContext: IDynamicComponentContext<Component, any>): Promise<void>
	{
		this.informationMenuItems = [];

		if (AnyHelper.isNullOrWhitespace(
			this.informationMenuDisplayComponentInstanceName))
		{
			return;
		}

		const displayComponentContainer: IDisplayComponentContainer =
			await this.displayComponentService
				.populateDisplayComponentContainer(
					this.informationMenuDisplayComponentInstanceName,
					pageContext);

		if (AnyHelper.isNull(displayComponentContainer)
			|| displayComponentContainer.container.visible === false)
		{
			return;
		}

		const informationMenuPageContext:
			IDynamicComponentContext<Component, any> =
				<IDynamicComponentContext<Component, any>>
				{
					source: pageContext.source,
					data: await this.displayComponentFactory
						.getMergedInitialParameterData(
							displayComponentContainer.container
								.jsonInitialParameters,
							pageContext)
				};

		let displayComponentCounter: number = 0;
		this.subscriptions.add(
			displayComponentContainer.components.subscribe(
				async(displayComponentPromise:
					Promise<DisplayComponentInstance>) =>
				{
					displayComponentCounter++;
					const displayComponentInstance: DisplayComponentInstance =
						await displayComponentPromise;

					const summaryCard: IInformationMenuItem<any> =
						await this.displayComponentFactory.summaryCard(
							displayComponentInstance,
							informationMenuPageContext);

					if (!AnyHelper.isNull(summaryCard))
					{
						summaryCard.order = displayComponentInstance.order;

						this.informationMenuItems =
							[
								...this.informationMenuItems,
								summaryCard
							]
								.sort(
									(summaryCardOne:
										IInformationMenuItem<any>,
									summaryCardTwo:
										IInformationMenuItem<any>) =>
										summaryCardOne.order -
											summaryCardTwo.order);

						setTimeout(() =>
						{
							EventHelper
								.dispatchSiteLayoutChangedEvent();
						},
						this.siteLayoutService.debounceDelay);
					}

					if (displayComponentCounter ===
						displayComponentContainer.container
							.displayArray.length)
					{
						setTimeout(() =>
						{
							this.informationMenuItemsLoaded = true;
						},
						this.siteLayoutService.debounceDelay);
					}
				}));
	}

	/**
	 * Adds a scroll to section command to an existing menu item.
	 *
	 * @param {MenuItem} menuItem
	 * The menu item to be decorated.
	 * @param {number} tabIndex
	 * If sent this value will represent the tab index this section level
	 * menu item is a child of.
	 * @memberof BasePageDirective
	 */
	private setScrollToCommand(
		menuItem: MenuItem,
		tabIndex: number = null): void
	{
		menuItem.command =
			() =>
			{
				if (!AnyHelper.isNull(tabIndex)
					&& this.tabItems[tabIndex] !== this.activeTabItem)
				{
					this.tabSelected({ index: tabIndex });
				}

				const sectionId: string =
					menuItem.id + this.sectionIdentifier;
				DocumentHelper.scrollToElementById(
					document,
					sectionId,
					this.verticalOffsetHeight);
				DocumentHelper.displaySelectedElementBorderById(
					document,
					sectionId);
			};
	}
}