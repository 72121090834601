/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	Component
} from '@angular/core';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';

/* eslint-enable max-len */

@Component({
	selector: 'app-dynamic-common-table',
	templateUrl: './dynamic-common-table.component.html'
})

/**
 * A component representing a wrapper to pass context to a common table.
 *
 * @export
 * @class DynamicCommonTableComponent
 * @implements {IDynamicComponent<SessionComponent, ICommonTable>}
 */
export class DynamicCommonTableComponent
implements IDynamicComponent<Component, ICommonTable>
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, ICommonTable>}
	 * @memberof DynamicCommonTableComponent
	 */
	public context: IDynamicComponentContext<Component, ICommonTable>;
}
