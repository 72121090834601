<ng-template
	[ngIf]="loading"
	[ngIfElse]="Content">
	<i
		class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>

<ng-template
	#Content>
	<div
		class="ui-g-12 text-left no-padding">
		<div
			class="ui-g-12 nested-display-subtitle text-left">
			<div class="ellipsis-text">
				View: Note
			</div>
			<span class="nested-display-actions">
				<ng-template
					ngFor
					let-action
					[ngForOf]="itemActions">
					<span [ngClass]="{
							'selected-bottom-border': (action.id === 'View')
						}">
						<app-common-icon-tooltip
							content="{{action.label}}"
							iconClass="fa fa-fw {{action.icon}} theme-color cursor-pointer"
							position="left"
							styleClass="utility-menu-tooltip"
							(iconClick)="action.command($event)">
						</app-common-icon-tooltip>
					</span>
				</ng-template>
			</span>
		</div>

		<app-note
			[context]="context"
			[selectedItem]="selectedItem"
			(changeDisplayMode)="changeDisplayMode($event)">
		</app-note>
	</div>
</ng-template>