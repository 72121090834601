/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	EventEmitter,
	Injectable
} from '@angular/core';
import {
	StorageMap
} from '@ngx-pwa/local-storage';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	uniqueId
} from 'lodash-es';

/**
 * A singleton service representing all current application activities that
 * will be displayed in the Activity List Component.
 *
 * @export
 * @class ActivityService
 */
@Injectable({
	providedIn: 'root'
})
export class ActivityService
{
	/**
	 * Creates an instance of an activity service. This will check and
	 * load the indexed database storage map for our session based
	 * activity list.
	 *
	 * @param {StorageMap} storageMap
	 * The local storage map used for activity list caching.
	 * @memberof ActivityService
	 */
	public constructor(
		private readonly storageMap: StorageMap)
	{
		this.storageMap.get(this.localStorageKey)
			.subscribe(
				(activities: Activity<any>[]) =>
				{
					if (AnyHelper.isNull(activities) === false)
					{
						activities.forEach(
							(activity: Activity<any>) =>
							{
								activity.data =
									activity.status ===
										AppConstants.activityStatus.error
										&& !AnyHelper
											.isNullOrEmpty(activity.data)
										? JSON.parse(activity.data)
										: activity.data;
							});

						this.activities = activities;

						return;
					}

					this.activities = [];
				});
	}

	/**
	 * Gets or sets the activity changed event emitter.
	 *
	 * @type {EventEmitter<Activity<any>>}
	 * @memberof ActivityService
	 */
	public activityChanged: EventEmitter<Activity<any>>
		= new EventEmitter<Activity<any>>();

	/**
	 * Gets or sets the list of all activities currently being handled by the
	 * activity service and not yet dismissed.
	 *
	 * @type {Activity<any>[]}
	 * @memberof ActivityService
	 */
	public activities: Activity<any>[] = [];

	/**
	 * Gets the identifier used for the session based activity list storage.
	 *
	 * @type {string}
	 * @memberof ActivityService
	 */
	private readonly localStorageKey: string = 'NautixActivities';

	/**
	 * Handles an activity by setting this activity in a pending status then
	 * executing the command action. Upon completion of this action, the message
	 * returned from the resolve/reject of the command promise will become this
	 * activity's status message.
	 *
	 * @async
	 * @param {Activity<TDataType>} activity
	 * The activity to be handled by this service.
	 * @param {string} successStatus
	 * The status to display upon success.
	 * @param {boolean} throwException
	 * If requested to throw an exception on a reject/error case.
	 * Defaults to false.
	 * @memberof ActivityService
	 */
	public async handleActivity<TDataType>(
		activity: Activity<TDataType>,
		successStatus: string = AppConstants.activityStatus.complete,
		throwException: boolean = false):
		Promise<TDataType>
	{
		activity.status = AppConstants.activityStatus.pending;
		activity.id = uniqueId();
		activity.lastActivityTime = Date.now();
		this.activities.push(activity);
		this.activityChanged.emit(activity);
		await activity.command
			.then(
				async(message: string) =>
				{
					await this.generateActivityBanner(
						activity,
						successStatus,
						message);
				},
				async(message: string) =>
				{
					await this.generateActivityBanner(
						activity,
						AppConstants.activityStatus.error,
						message);

					if (throwException === true)
					{
						throw message;
					}
				})
			.finally(
				() =>
				{
					activity.lastActivityTime = Date.now();
				}
			);

		return activity.data;
	}

	/**
	 * Clears all current activities being handled in this service.
	 *
	 * @async
	 * @memberof ActivityService
	 */
	public async dismissAllActivities(): Promise<void>
	{
		await this.syncActivitiesLocally(
			null,
			null,
			true);

		this.activityChanged
			.emit(null);
	}

	/**
	 * Clears a specific activity being handled in this service.
	 *
	 * @async
	 * @param {Activity<any>} dismissActivity
	 * The activity to be dismissed.
	 * @memberof ActivityService
	 */
	public async dismissActivity(
		dismissActivity: Activity<any>): Promise<void>
	{
		await this.syncActivitiesLocally(
			null,
			dismissActivity);

		this.activityChanged
			.emit(dismissActivity);
	}

	/**
	 * Generates a banner message to interact with the banner component
	 * based on the activity command's success or failure and resulting
	 * status message.
	 *
	 * @async
	 * @param {Activity<any>} activity
	 * The activity to be displayed.
	 * @param {string} status
	 * The status to display.
	 * @param {string} message
	 * The meessage to display.
	 * @memberof ActivityService
	 */
	public async generateActivityBanner(
		activity: Activity<any>,
		status: string,
		message: string): Promise<void>
	{
		activity.status = status;
		activity.statusMessage = message;
		activity.lastActivityTime ??= Date.now();

		EventHelper.dispatchBannerEvent(
			activity.message,
			activity.statusMessage,
			activity.status,
			activity.data);

		await this.syncActivitiesLocally(
			activity);

		this.activityChanged
			.emit(activity);
	}

	/**
	 * Locally stores and synchronizes the existing activities list.
	 *
	 * @async
	 * @param {Activity<any>} newActivity
	 * If sent this represents a new activity to be added to the local
	 * storage list.
	 * @param {Activity<any>} dismissActivity
	 * If sent this represents an activity to be removed from the local
	 * storage list.
	 * @param {Activity<any>} clearActivities
	 * If sent this represents a command to clear the activity list.
	 * @memberof ActivityService
	 */
	private async syncActivitiesLocally(
		newActivity: Activity<any> = null,
		dismissActivity: Activity<any> = null,
		clearActivities: boolean = false): Promise<void>
	{
		return new Promise((resolve: any) =>
		{
			let updatedActivities: Activity<any>[] = [];

			if (clearActivities === true)
			{
				return this.storageMap.set(
					this.localStorageKey,
					updatedActivities)
					.subscribe(() =>
					{
						this.activities = updatedActivities;
						resolve();
					});
			}

			return this.storageMap.get(this.localStorageKey)
				.subscribe((activities: Activity<any>[]) =>
				{
					const safeActivities: Activity<any>[] =
						activities || [];

					if (AnyHelper.isNull(newActivity) === false
						&& newActivity.status ===
							AppConstants.activityStatus.error)
					{
						// Serialize promise errors for Firefox.
						newActivity.data =
							JSON.stringify(newActivity.data);
					}

					updatedActivities =
						AnyHelper.isNull(dismissActivity) === false
							? safeActivities.filter(
								(activity: Activity<any>) =>
									dismissActivity.id
										!== activity.id)
							: [
								...safeActivities,
								newActivity
							];

					const storageSafeActivities: Activity<any>[] = [];
					updatedActivities.forEach(
						(activity: Activity<any>) =>
						{
							const clonedActivity: Activity<any> =
								<Activity<any>>
								{
									...activity
								};

							// Clear headers for serialization.
							if (!AnyHelper.isNull(
								clonedActivity.data?.headers))
							{
								clonedActivity.data.headers = null;
							}

							storageSafeActivities.push(
								clonedActivity);
						});

					this.storageMap.set(
						this.localStorageKey,
						storageSafeActivities)
						.subscribe(() =>
						{
							this.activities = updatedActivities;
							resolve();
						});
				});
		});
	}
}