<div class="dashboard-section ui-g-12 no-padding">
	<span *ngIf="section.label !== null">
		<custom-section-title
			[field]="{
				props: {
					label: section.label
				}
			}">
		</custom-section-title>
	</span>

	<app-display-component-parameter-header
		[parameterLayoutData]="section.parameterLayoutData"
		[parentParameterLayoutData]="parentParameterLayoutData"
		[parameterLayoutSchema]="section.parameterLayoutSchema"
		[displayParameterOption]="section.parameterLayoutSchema !== null"
		[displaySecondaryOption]="false"
		[displayEditOption]="false"
		[displayingSettings]="settingsActive"
		[display]="dashboardDisplayCompleted"
		(displaySettingsClick)="settingsClicked()">
	</app-display-component-parameter-header>

	<div class="header-parameter-container"
		[ngClass]="{
			'display-none': settingsActive === false
		}">
		<app-display-component-parameter
			[displayComponentInstance]="section.displayComponentInstance"
			[parameterLayoutData]="section.parameterLayoutData"
			[parameterLayoutSchema]="section.parameterLayoutSchema"
			[pageContext]="pageContext"
			[settingsActive]="settingsActive"
			(appliedParameters)="applyParameters()"
			(cancelClicked)="cancelClicked()">
		</app-display-component-parameter>
	</div>

	<ng-template ngFor let-widget
		[ngForOf]="section.widgets">
		<app-dashboard-widget
			[widget]="widget"
			[pageContext]="pageContext"
			[parentParameterLayoutData]="section.parameterLayoutData"
			[parameterDisplayReady]="dashboardDisplayCompleted"
			(widgetChange)="widgetChanged($event)"
			(widgetDisplayed)="widgetComponentDisplayed()"
			(widgetParametersApplied)="bubbleAppliedWidgetParameters($event)">
		</app-dashboard-widget>
	</ng-template>
</div>