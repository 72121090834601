/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	differenceBy,
	get
} from 'lodash-es';

/**
 * A class containing static helper methods for the
 * object array type.
 *
 * @export
 * @class ObjectArrayHelper
 */
export class ObjectArrayHelper
{
	/**
	 * Returns an array of comma separated property values for
	 * object arrays.
	 *
	 * @static
	 * @param {object[]} array
	 * The array to be evaluated.
	 * @param {string} propertyName
	 * The property value to concatenate.
	 * @memberof ObjectArrayHelper
	 * @returns {string}
	 * A comma separated array of property values.
	 */
	public static commaSeparatedPropertyValues(
		array: object[],
		propertyName: string): string
	{
		return array
			.filter((item: object) =>
				!AnyHelper.isNullOrEmpty(item[propertyName]))
			.map((item: object) => item[propertyName])
			.join(AppConstants.characters.comma);
	}

	/**
	 * Returns a consolidated list of the differences
	 * between two arrays.
	 *
	 * @static
	 * @param {object[]} initialArray
	 * The array to be evaluated.
	 * @param {object[]} comparisonArray
	 * The list of data to check against.
	 * @param {string} dataKey
	 * The data key if any existing.
	 * @memberof ObjectArrayHelper
	 * @returns {object[]}
	 * The object array diferences.
	 */
	public static getObjectArrayDifferences(
		initialArray: object[],
		comparisonArray: object[],
		dataKey: string): object[]
	{
		return differenceBy(
			initialArray,
			comparisonArray,
			dataKey)
			.concat(
				differenceBy(
					comparisonArray,
					initialArray,
					dataKey));
	}

	/**
	 * Returns an object from an array based on a property value.
	 * @remarks Nested property lookups are supported.
	 *
	 * @static
	 * @param {object[]} array
	 * The array to be evaluated.
	 * @param {string} propertyName
	 * The property to be evaluated in each array item.
	 * @param {any} matchValue
	 * The value to compare to find a match for.
	 * @returns {object}
	 * The object matching the property value.
	 * @typeparam TReturnType
	 * The object type to return.
	 * @memberof ObjectArrayHelper
	 */
	public static findByProperty<TReturnType>(
		array: object[],
		propertyName: string,
		matchValue: any): TReturnType
	{
		if (AnyHelper.isNull(array))
		{
			return null;
		}

		return array.find(
			(item: object) =>
				get(item, propertyName) ===	matchValue) as TReturnType
				?? null;
	}
}