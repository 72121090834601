/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	animate,
	state,
	style,
	transition,
	trigger
} from '@angular/animations';
import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Input,
	ViewChild
} from '@angular/core';
import {
	BooleanFadeAnimation,
	DisplayAnimation
} from '@shared/app-animations';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-information-menu',
	templateUrl: './information-menu.component.html',
	styleUrls: [
		'./information-menu.component.scss'
	],
	animations: [
		BooleanFadeAnimation,
		DisplayAnimation,
		trigger('easeAnimation', [
			state('void', style({
				transform: 'translate(.5em, 0)',
				opacity: 0
			})),
			state('*', style({
				transform: 'translate(.5em, 0)',
				opacity: 1
			})),
			transition('* => void',
				animate('195ms ease-out')),
			transition('void => *',
				animate('195ms ease-in'))
		]),
	]
})

/**
 * A component representing an instance of an information menu.
 *
 * @export
 * @class InformationMenuComponent
 * @implements {AfterViewInit}
 */
export class InformationMenuComponent
implements AfterViewInit
{
	/**
	 * Initializes a new instance of the information menu component.
	 *
	 * @param {SiteLayoutService}
	 * The site layout service used to watch for layout changes.
	 * @memberof InformationMenuComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the menu items to display in this component.
	 *
	 * @type {IInformationMenuItem<any, any>[]}
	 * @memberof InformationMenuComponent
	 */
	@Input() public informationMenuItems:
		IInformationMenuItem<any>[];

	/**
	 * Gets or sets the display value for a completed data load for
	 * an async populated information menu.
	 *
	 * @type {boolean}
	 * @memberof InformationMenuComponent
	 */
	@Input() public initialLoadComplete: boolean = true;

	/**
	 * Gets or sets the element reference for the information menu
	 * item list.
	 *
	 * @type {ElementRef}
	 * @memberof InformationMenuComponent
	 */
	@ViewChild('InformationMenuItems', { read: ElementRef })
	public informationMenu: ElementRef;

	/**
	 * Gets or sets the active overlay value, used to define
	 * if an information menu item is currently displaying
	 * a popout.
	 *
	 * @type {boolean}
	 * @memberof InformationMenuComponent
	 */
	public overlayActive: boolean = false;

	/**
	 * Gets or sets the dynamic component currently
	 * displayed in the overlay.
	 *
	 * @type {string}
	 * @memberof InformationMenuComponent
	 */
	public overlayDynamicComponent: string;

	/**
	 * Gets or sets the dynamic component context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof InformationMenuComponent
	 */
	public overlayContext:
		IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the display value for the left mask.
	 *
	 * @type {boolean}
	 * @memberof InformationMenuComponent
	 */
	public displayLeftMask: boolean = false;

	/**
	 * Gets or sets the display value for the right mask.
	 *
	 * @type {boolean}
	 * @memberof InformationMenuComponent
	 */
	public displayRightMask: boolean = false;

	/**
	 * Gets or the draggable value on whether or nor additional
	 * content exists and is not displayed.
	 *
	 * @type {boolean}
	 * @memberof InformationMenuComponent
	 */
	public get draggable(): boolean
	{
		return this.displayLeftMask === true
			|| this.displayRightMask === true;
	}

	/**
	 * Gets the multiplier for the drag scroll event.
	 *
	 * @type {number}
	 * @memberof InformationMenuComponent
	 */
	private readonly scrollMultiplier: number = 2.5;

	/**
	 * Gets the debounce delay for allowing a loaded
	 * view and calculating the masks.
	 *
	 * @type {number}
	 * @memberof InformationMenuComponent
	 */
	private readonly afterViewInitDebounceDelay: number =
		AppConstants.time.quarterSecond;

	/**
	 * Gets the debounce delay for validating proper arrow displays
	 * following layout changes.
	 *
	 * @type {number}
	 * @memberof InformationMenuComponent
	 */
	private readonly layoutChangedDebounceDelay: number =
		AppConstants.time.twentyFiveMilliseconds;

	/**
	 * Handles the site layout change event.
	 * This will set the scroll masks on layout changes and hide the
	 * overlay.
	 *
	 * @memberof InformationMenuComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		if (this.initialLoadComplete === true)
		{
			this.closeOverlayPanel();
		}

		if (!AnyHelper.isNullOrEmpty(this.informationMenu)
			&& !AnyHelper.isNull(this.informationMenu.nativeElement))
		{
			this.scrollInformationMenuTo(
				this.informationMenu.nativeElement.scrollLeft);

			setTimeout(() =>
			{
				this.setScrollMasks(
					this.informationMenu.nativeElement.scrollLeft);
			},
			this.layoutChangedDebounceDelay);
		}
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close this list when a non bubbled click
	 * event occurs outside of this element.
	 *
	 * @memberof InformationMenuComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent)
	public hideAssociatedMenus(): void
	{
		this.closeOverlayPanel();
	}

	/**
	 * Handles the after view init event.
	 * This will set the scroll masks on initial load.
	 *
	 * @memberof InformationMenuComponent
	 */
	public ngAfterViewInit(): void
	{
		setTimeout(
			() =>
			{
				this.setScrollMasks(
					this.informationMenu.nativeElement.scrollLeft);
			},
			this.afterViewInitDebounceDelay);
	}

	/**
	 * Handles the scroll right button click event.
	 * This will translate the x position of the div 100%
	 * to the left.
	 *
	 * @memberof InformationMenuComponent
	 */
	public scrollRight(): void
	{
		const horizontalScroll: number =
			this.informationMenu.nativeElement.scrollLeft
				+ this.getHorizontalScrollWidth();

		this.scrollInformationMenuTo(horizontalScroll);
	}

	/**
	 * Handles the scroll left button click event.
	 * This will translate the x position of the div 100%
	 * to the right.
	 *
	 * @memberof InformationMenuComponent
	 */
	public scrollLeft(): void
	{
		const horizontalScroll: number =
			this.informationMenu.nativeElement.scrollLeft
				- this.getHorizontalScrollWidth();

		this.scrollInformationMenuTo(horizontalScroll);
	}

	/**
	 * Handles the press event sent from a hammerjs touch event.
	 * This will set the active class on the information menu list.
	 *
	 * @memberof InformationMenuComponent
	 */
	public press(): void
	{
		if (this.draggable === true)
		{
			this.informationMenu.nativeElement
				.classList.add('active');
		}
	}

	/**
	 * Handles the press up event sent from a hammerjs touch event.
	 * This will remove the active class on the information menu list.
	 *
	 * @memberof InformationMenuComponent
	 */
	public pressUp(): void
	{
		if (this.draggable === true)
		{
			this.informationMenu.nativeElement
				.classList.remove('active');
		}
	}

	/**
	 * Handles the swipe event sent from a hammerjs touch event.
	 * This will calculate the direction and velocity of this
	 * swipe action and scroll to that location.
	 *
	 * @param {any} event
	 * The sent swipe event.
	 * @memberof InformationMenuComponent
	 */
	public swipe(
		event: any): void
	{
		if (this.draggable === true)
		{
			this.scrollInformationMenuTo(
				this.informationMenu.nativeElement.scrollLeft
					- (Math.abs(event.deltaX)
						* event.overallVelocityX
						* this.scrollMultiplier));

			this.pressUp();
		}
	}

	/**
	 * Handles the click outside event by closing the active overlay
	 * panel.
	 *
	 * @memberof InformationMenuComponent
	 */
	public closeOverlayPanel(): void
	{
		if (this.overlayActive === true)
		{
			this.overlayActive = false;
			this.setScrollMasks(
				this.informationMenu.nativeElement.scrollLeft);
		}
	}

	/**
	 * Handles the summary card click event sent from a child summary card.
	 * This will handle array based displays, overlays, and scrolling when
	 * a click event occurs in the child.
	 *
	 * @memberof InformationMenuComponent
	 */
	public summaryCardClicked(
		data: {
			dynamicComponent: string;
			summaryCardElement: HTMLDivElement;
			dynamicComponentContext: any;
		}): void
	{
		const displayOverlay =
			!AnyHelper.isNullOrEmpty(data.dynamicComponent);

		if (this.overlayActive === true
			&& displayOverlay === true)
		{
			this.overlayDynamicComponent = data.dynamicComponent;
			this.overlayContext = data.dynamicComponentContext;
			this.overlayActive = false;

			setTimeout(
				() =>
				{
					this.scrollToSummaryCard(
						data.summaryCardElement,
						true);
				});

			return;
		}

		this.overlayDynamicComponent = data.dynamicComponent;
		this.overlayContext = data.dynamicComponentContext;
		this.overlayActive =
			displayOverlay && !this.overlayActive;

		setTimeout(
			() =>
			{
				this.scrollToSummaryCard(
					data.summaryCardElement,
					this.overlayActive);
			});
	}

	/**
	 * Returns the horizontal scroll width of the information
	 * menu component.
	 *
	 * @returns {number}
	 * The calculated scroll width of the information menu items.
	 * @memberof InformationMenuComponent
	 */
	public getHorizontalScrollWidth(): number
	{
		return this.informationMenu.nativeElement
			.getBoundingClientRect().width;
	}

	/**
	 * Sets the display values for the left and right mask based
	 * on available scroll translations in the information menu
	 * list.
	 *
	 * @param {number} horizontalScroll
	 * The x translation recently scrolled to.
	 * @memberof InformationMenuComponent
	 */
	public setScrollMasks(
		horizontalScroll: number): void
	{
		this.displayLeftMask =
			horizontalScroll > 0;
		this.displayRightMask =
			this.informationMenu.nativeElement.scrollWidth >
				horizontalScroll
					+ this.informationMenu.nativeElement
						.getBoundingClientRect().width + 1;
	}

	/**
	 * Performs a smooth scroll to animation on the information
	 * menu list.
	 *
	 * @param {number} horizontalScroll
	 * The x translation to perform on the current information
	 * menu list. This is a bounds safe action and will only
	 * fire if a scroll is available to this sent location.
	 * @memberof InformationMenuComponent
	 */
	public scrollInformationMenuTo(
		horizontalScroll: number): void
	{
		this.informationMenu.nativeElement
			.scrollTo(
				{
					left: horizontalScroll,
					behavior: 'smooth'
				});

		setTimeout(
			() => this.setScrollMasks(horizontalScroll),
			this.layoutChangedDebounceDelay);
	}

	/**
	 * Scrolls the information menu to a centered summary card
	 * and recalculates mask and scroll displays.
	 *
	 * @memberof InformationMenuComponent
	 */
	public scrollToSummaryCard(
		summaryCardComponent: HTMLDivElement,
		setAsActive: boolean): void
	{
		this.overlayActive = setAsActive;

		const elementLocation: number =
			this.informationMenu.nativeElement.scrollLeft
				+ summaryCardComponent
					.getBoundingClientRect().left
				- this.informationMenu.nativeElement
					.getBoundingClientRect().left;
		const elementMiddle: number =
			summaryCardComponent
				.getBoundingClientRect().width / 2;
		const informationMenuMiddle: number =
			this.getHorizontalScrollWidth() / 2;

		this.scrollInformationMenuTo(
			elementLocation
				- informationMenuMiddle
					+ elementMiddle);

		this.setScrollMasks(
			this.informationMenu.nativeElement.scrollLeft);
	}
}