/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	EntityManagerDirective
} from '@admin/directives/entity-manager.directive';
import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	ActivatedRoute,
	Params,
	Router
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	RuleDefinitionApiService
} from '@api/services/rules/rule-definition.api.service';
import {
	RulePresentationDefinitionApiService
} from '@api/services/rules/rule-presentation-definition.api.service';
import {
	RulePresentationLogicDefinitionApiService
} from '@api/services/rules/rule-presentation-logic-definition.api.service';
import {
	RuleValidationDefinitionApiService
} from '@api/services/rules/rule-validation-definition.api.service';
import {
	RuleValidatorDefinitionApiService
} from '@api/services/rules/rule-validator-definition.api.service';
import {
	RuleViolationWorkflowActionDefinitionApiService
} from '@api/services/rules/rule-violation-workflow-action-definition.api.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IRuleDefinition
} from '@shared/interfaces/rules/rule-definition.interface';
import {
	IRulePresentationDefinition
} from '@shared/interfaces/rules/rule-presentation-definition.interface';
import {
	IRulePresentationLogicDefinition
} from '@shared/interfaces/rules/rule-presentation-logic-definition.interface';
import {
	IRuleValidationDefinition
} from '@shared/interfaces/rules/rule-validation-definition.interface';
import {
	IRuleValidatorDefinition
} from '@shared/interfaces/rules/rule-validator-definition.interface';
import {
	IRuleViolationWorkflowActionDefinition
} from '@shared/interfaces/rules/rule-violation-workflow-action-definition.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	RulePresentationService
} from '@shared/services/rule-presentation.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	Observable
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'app-entity-rule-definition',
	templateUrl: './entity-rule-definition.component.html',
	styleUrls: [
		'./entity-rule-definition.component.scss'
	]
})

/**
 * A component representing an instance of the entity rule definition
 * component.
 *
 * @export
 * @class EntityRuleDefinitionComponent
 * @extends {EntityManagerDirective}
 */
export class EntityRuleDefinitionComponent
	extends EntityManagerDirective
{
	/**
	 * Creates an instance of an EntityRuleDefinitionComponent.
	 *
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The api service used to get the entity definition data.
	 * @param {RuleDefinitionApiService} ruleDefinitionApiService
	 * The api service used to get the rule definition data.
	 * @param {RuleViolationWorkflowActionDefinitionApiService}
	 * ruleViolationWorkflowActionDefinitionApiService
	 * The api service used to get the rule violation workflow
	 * action definition data.
	 * @param {RuleValidatorDefinitionApiService}
	 * 	ruleValidatorDefinitionApiService
	 * The api service used to get the rule validator definition data.
	 * @param {RuleValidationDefinitionApiService}
	 * ruleValidationDefinitionApiService
	 * The api service used to get the rule validation definition data.
	 * @param {RulePresentationLogicDefinitionApiService}
	 * rulePresentationLogicDefinitionApiService
	 * The api service used to get the rule presentation logic definition data.
	 * @param {RulePresentationDefinitionApiService}
	 * rulePresentationDefinitionApiService
	 * The api service used to get the rule presentation definition data.
	 * @param {RulePresentationService} rulePresentationService
	 * The service used to get the rule presentation data.
	 * @param {WorkflowActionDefinitionsApiService}
	 * workflowActionDefinitionApiService
	 * The service used to get the workflow action definition data.
	 * @param {SiteLayoutService} siteLayoutService
	 * The service used to get the site layout data.
	 * @param {Router} router
	 * The router service that opens new components.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and
	 * client messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityRuleDefinitionComponent
	 */
	public constructor(
		public entityTypeApiService: EntityTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public ruleDefinitionApiService: RuleDefinitionApiService,
		public ruleViolationWorkflowActionDefinitionApiService:
			RuleViolationWorkflowActionDefinitionApiService,
		public ruleValidatorDefinitionApiService:
			RuleValidatorDefinitionApiService,
		public ruleValidationDefinitionApiService:
			RuleValidationDefinitionApiService,
		public rulePresentationLogicDefinitionApiService:
			RulePresentationLogicDefinitionApiService,
		public rulePresentationDefinitionApiService:
			RulePresentationDefinitionApiService,
		public rulePresentationService: RulePresentationService,
		public workflowActionDefinitionApiService:
			WorkflowActionDefinitionsApiService,
		public siteLayoutService: SiteLayoutService,
		public router: Router,
		public route: ActivatedRoute,
		public activityService: ActivityService,
		public resolver: ResolverService,
		public appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			route,
			activityService,
			resolver);
	}

	/**
	 * Gets or sets the rule definition id.
	 *
	 * @type {number}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public ruleDefinitionId: number;

	/**
	 * Gets or sets the rule definition.
	 *
	 * @type {IRuleDefinition}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public ruleDefinition: IRuleDefinition;

	/**
	 * Gets or sets the workflow table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public workflowTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public workflowAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public workflowSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the server validations table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public serverValidationsTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public serverAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public serverSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the server validations common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public serverValidationsCommonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the presentation logic table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public presentationLogicTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public presentationLogicAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public presentationLogicSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the presentation logic common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public presentationLogicCommonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the rule validator definitions.
	 *
	 * @type {IRuleValidatorDefinition[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public ruleValidatorDefinitions: IRuleValidatorDefinition[];

	/**
	 * Gets or sets the workflow action definitions.
	 *
	 * @type {IWorkflowActionDefinitions[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public workflowActionDefinitions: IWorkflowActionDefinitions[];

	/**
	 * Gets or sets the rule presentation logic definition.
	 *
	 * @type {IRulePresentationLogicDefinition[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public rulePresentationLogicDefinition: IRulePresentationLogicDefinition[];

	/**
	 * Gets or sets the workflow action definition options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public workflowActionDefinitionOptions: IDropdownOption[];

	/**
	 * Gets or sets the rule validator definition options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public ruleValidatorDefinitionOptions: IDropdownOption[];

	/**
	 * Gets or sets the rule presentation logic definition options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof EntityRuleDefinitionComponent
	 */
	public presentationLogicDefinitionOptions: IDropdownOption[];

	/**
	 * Gets the rule definition id query parameter.
	 *
	 * @type {string}
	 * @memberof EntityRuleDefinitionComponent
	 */
	private readonly ruleDefinitionIdQueryParameter: string =
		AppConstants.commonProperties.ruleDefinitionId;

	/**
	 * Gets the presentation friendly string.
	 *
	 * @type {string}
	 * @memberof EntityRuleDefinitionComponent
	 */
	private readonly presentationLogic: string = 'Presentation Logic';

	/**
	 * Handles the before unload event sent from the current window based
	 * on any action that will change the page.
	 *
	 * @memberof EntityRuleDefinitionComponent
	 * @returns {Observable<boolean> | boolean}
	 * The value that will allow the router to know if the data in this form
	 * is altered or not saved to the database. This implements the
	 * AppCanDeactivateGuard interface.
	 */
	public canDeactivate(): Observable<boolean> | boolean
	{
		return !this.formValuesChanged;
	}

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	public async setContextData(): Promise<void>
	{
		this.subscriptions.add(
			this.route.queryParams.subscribe((parameters: Params) =>
			{
				const mappedRouteData: any =
						ObjectHelper.mapFromRouteData(
							parameters);

				this.ruleDefinitionId =
						mappedRouteData[this.ruleDefinitionIdQueryParameter];
			}));

		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.ruleDefinition =
			<IRuleDefinition>
			await this.ruleDefinitionApiService
				.get(this.ruleDefinitionId);

		this.ruleValidatorDefinitions =
			await this.ruleValidatorDefinitionApiService
				.query(
					AppConstants.empty,
					AppConstants.empty);

		this.rulePresentationLogicDefinition =
			await this.rulePresentationLogicDefinitionApiService
				.query(
					AppConstants.empty,
					AppConstants.empty);

		this.workflowActionDefinitions =
			await this.workflowActionDefinitionApiService
				.query(
					`entityTypeId eq ${this.entityDefinition.typeId}`
						+ ' and entityVersionId eq '
						+ `${this.entityDefinition.versionId}`,
					AppConstants.empty);

		this.workflowActionDefinitionOptions =
			this.getDropdownOptions(
				await this.workflowActionDefinitionApiService
					.query(
						`entityTypeId eq ${this.entityDefinition.typeId}`
							+ ' and entityVersionId eq '
							+ `${this.entityDefinition.versionId}`,
						AppConstants.empty),
				AppConstants.commonProperties.name,
				AppConstants.commonProperties.id);

		this.ruleValidatorDefinitionOptions =
			this.getDropdownOptions(
				await this.ruleValidatorDefinitionApiService
					.query(
						AppConstants.empty,
						AppConstants.empty),
				AppConstants.commonProperties.name,
				AppConstants.commonProperties.id);

		this.presentationLogicDefinitionOptions =
			this.getDropdownOptions(
				await this.rulePresentationLogicDefinitionApiService
					.query(
						AppConstants.empty,
						AppConstants.empty),
				AppConstants.commonProperties.name,
				AppConstants.commonProperties.id);

		this.contextData =
			{
				id: this.ruleDefinition.id,
				name: this.ruleDefinition.name,
				displayName: this.ruleDefinition.displayName,
				entityVersionId: this.ruleDefinition.entityVersionId,
				entityTypeId: this.ruleDefinition.entityVersionId,
				overridable: this.ruleDefinition.overridable,
				description: this.ruleDefinition.description,
				order: this.ruleDefinition.order
			};

		this.saveTitle = 'Rule Definition';
		this.saveContent = 'Rule Definition'
			+ ` ${this.ruleDefinition.name}`;

		this.workflowAvailableColumns =
			[
				{
					dataKey: 'actionType',
					columnHeader: 'Action Type',
					displayOrder: 1
				},
				{
					dataKey: 'actionDefinitionName',
					columnHeader: 'Action Definition Name',
					displayOrder: 2
				}
			];
		this.workflowSelectedColumns =
			this.workflowAvailableColumns;

		this.serverAvailableColumns =
			[
				{
					dataKey: 'validationName',
					columnHeader: 'Validation Name',
					displayOrder: 1
				},
				{
					dataKey: 'validationVersion',
					columnHeader: 'Validation Version',
					displayOrder: 2
				}
			];
		this.serverSelectedColumns =
			this.serverAvailableColumns;

		this.presentationLogicAvailableColumns =
			[
				{
					dataKey: 'presentationName',
					columnHeader: 'Name',
					displayOrder: 1
				},
				{
					dataKey: 'eventType',
					columnHeader: 'Event Type',
					displayOrder: 2
				},
				{
					dataKey: 'order',
					columnHeader: 'Order',
					displayOrder: 3
				}
			];
		this.presentationLogicSelectedColumns =
			this.presentationLogicAvailableColumns;

		await this.setTableDefinitions();
	}

	/**
	 * Sets the formly layout fields.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	public async setLayoutFields(): Promise<void>
	{
		this.layoutFields =
			<FormlyFieldConfig[]>
			[
				{
					key: 'name',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Name',
						required: true
					},
					asyncValidators: {
						uniqueName: {
							expression: (
								control: UntypedFormControl) =>
								this.uniqueRuleName(control),
							message: 'Existing Rule Name.'
						}
					}
				},
				{
					key: 'displayName',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Display Name',
						placeholder: 'PlaceHolder for Display Name',
						required: true
					}
				},
				{
					key: 'order',
					type: FormlyConstants.customControls.customInputNumber,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Order',
						required: true,
						multipleOf: 10
					},
					asyncValidators: {
						uniqueOrder: {
							expression: (control: UntypedFormControl) =>
								this.uniqueOrder(control),
							message: 'Existing Order.'
						}
					}
				},
				{
					key: 'description',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Description',
						required: false,
						rows: FormlyConstants.textAreaRowSizes.standard
					}
				},
				{
					type: FormlyConstants.customControls.customSectionTitle,
					props: {
						label: 'Workflow'
					}
				},
				{
					type: FormlyConstants.customControls.customTableDisplay,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						loadingTableDefinitions: false,
						tableDefinitions: this.workflowTableDefinitions
					}
				},
				{
					type: FormlyConstants.customControls.customSectionTitle,
					props: {
						label: 'Server Validations'
					}
				},
				{
					type: FormlyConstants.customControls.customTableDisplay,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						tableDefinitions: this.serverValidationsTableDefinitions
					}
				},
				{
					type: FormlyConstants.customControls.customSectionTitle,
					props: {
						label: this.presentationLogic
					}
				},
				{
					type: FormlyConstants.customControls.customTableDisplay,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						tableDefinitions: this.presentationLogicTableDefinitions
					}
				}
			];
	}

	/**
	 * Excecutes the save action.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	public async saveAction(): Promise<void>
	{
		const ruleDefinitionDataObject: IRuleDefinition =
			<IRuleDefinition>
			{
				id: this.ruleDefinition.id,
				name: this.contextData.name,
				displayName: this.contextData.displayName,
				entityVersionId: this.ruleDefinition.entityVersionId,
				entityTypeId: this.ruleDefinition.entityTypeId,
				overridable: this.ruleDefinition.overridable,
				description: this.contextData.description,
				order: this.contextData.order
			};

		await this.ruleDefinitionApiService
			.update(
				this.ruleDefinition.id,
				ruleDefinitionDataObject);
	}

	/**
	 * Sets the table definitions.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	public async setTableDefinitions(): Promise<void>
	{
		await this.setWorkflowTableDefinitions();
		await this.setServerValidationsTableDefinitions();
		await this.setPresentationLogicTableDefinitions();
	}

	/**
	 * Sets the workflow table definitions.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	public async setWorkflowTableDefinitions(): Promise<void>
	{
		this.workflowTableDefinitions =
			{
				tableTitle: 'Rule Workflows',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Rule Workflow'),
				hideTableTitle: true,
				hideSettings: true,
				nestedTable: true,
				objectSearch: {
					filter: `DefinitionId eq ${this.ruleDefinition.id}`,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: 5
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const ruleViolationWorkflowActions:
							IRuleViolationWorkflowActionDefinition[] =
							<IRuleViolationWorkflowActionDefinition[]>
							await this
								.ruleViolationWorkflowActionDefinitionApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						const ruleViolations: any[] = [];

						for (const violationWorkflowAction of
							ruleViolationWorkflowActions)
						{
							let actionDefinitionName: string =
								AppConstants.empty;
							let actionDefinitionId: number = null;

							for (const workflowActionDefinition
								of this.workflowActionDefinitions)
							{
								if (violationWorkflowAction
									.workflowActionDefinitionId
										=== workflowActionDefinition.id)
								{
									actionDefinitionName =
										workflowActionDefinition.name;

									actionDefinitionId =
										workflowActionDefinition.id;
								}
							}

							let actionType: string = AppConstants.empty;
							if (violationWorkflowAction.actionTypeId ===
								AppConstants.ruleActionTypes.blocked)
							{
								actionType =
									AppConstants.ruleActionTypeNames.blocked;
							}
							else if (violationWorkflowAction.actionTypeId ===
								AppConstants.ruleActionTypes.continue)
							{
								actionType =
									AppConstants.ruleActionTypeNames.continue;
							}

							ruleViolations.push(
								{
									id: violationWorkflowAction.id,
									actionType: actionType,
									actionDefinitionName: actionDefinitionName,
									actionDefinitionId: actionDefinitionId
								});
						}

						return ruleViolations;
					},
				availableColumns: this.workflowAvailableColumns,
				selectedColumns: this.workflowSelectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					create: {
						layout: [
							{
								key: 'actionDefinitionId',
								type:
									FormlyConstants.customControls.customSelect,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Name',
									required: true,
									placeholder:
										AppConstants.placeholders
											.selectAnOption,
									showClear: true,
									appendTo:
										FormlyConstants.appendToTargets.body,
									options:
										this.workflowActionDefinitionOptions
								},
								asyncValidators: {
									uniqueName: {
										expression: (
											control: UntypedFormControl) =>
											this.uniqueWorkflowName(control),
										message:
											'Existing Action Definition Name.'
									}
								}
							}
						],
						items: [
							{
								label: 'Create',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: async() =>
									this.createViolationWorkflow()
							}]
					},
					update: {
						disabledExpandItem: true,
						items: [
							{
								command: () =>
								{
									const canDeactivate:
										boolean | Observable<boolean> =
										this.appCanDeactivateGuard
											.canDeactivate(this);

									if (canDeactivate === false)
									{
										return;
									}

									const routeData: string =
										ObjectHelper.mapRouteData(
											{
												ruleWorkflowDefinitionId:
													this.commonTableContext
														.source.selectedItem.id
											});
									this.router.navigate(
										[
											'admin/entity/ruleWorkflow/edit',
											this.entityDefinitionId
										],
										{
											queryParams:
												{
													routeData: routeData
												}
										});
								}
							}
						]
					},
					delete: {
						deleteStatement: () =>
							'Confirm to Remove Rule Violation Workflow '
								+ this.commonTableContext.source
									.selectedItem.actionDefinitionName,
						items: [
							{
								label: 'Remove',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: async () =>
									this.deleteViolationWorkflow()
							}
						]
					}
				}
			};
	}

	/**
	 * Sets the server validations table definitions.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	public async setServerValidationsTableDefinitions(): Promise<void>
	{
		this.serverValidationsTableDefinitions =
		{
			tableTitle: 'Server Validations',
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.serverValidationsCommonTableContext,
					'Server Validation'),
			hideTableTitle: true,
			hideSettings: true,
			nestedTable: true,
			objectSearch: {
				filter: `DefinitionId eq ${this.ruleDefinition.id}`,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 5
			},
			apiPromise:
				async (objectSearch: IObjectSearch) =>
				{
					const ruleValidationDefinitions:
							IRuleValidationDefinition[] =
							await this.ruleValidationDefinitionApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

					const ruleValidations: any[] = [];

					for (const ruleValidation of ruleValidationDefinitions)
					{
						let validationName: string = AppConstants.empty;
						let validationVersion: number = null;

						for (const ruleValidator of
							this.ruleValidatorDefinitions)
						{
							if (ruleValidation.validatorDefinitionId ===
								ruleValidator.id)
							{
								validationName =
									ruleValidator.name;
								validationVersion =
									ruleValidator.versionNumber;
							}
						}

						ruleValidations.push(
							{
								id: ruleValidation.id,
								validatorDefinitionId:
									ruleValidation.validatorDefinitionId,
								validationName: validationName,
								validationVersion: validationVersion
							});
					}

					return ruleValidations;
				},
			availableColumns: this.serverAvailableColumns,
			selectedColumns: this.serverSelectedColumns,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.serverValidationsCommonTableContext = commonTableContext;
			},
			actions: {
				create: {
					layout: [
						{
							key: 'validatorDefinitionId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							props: {
								label: 'Name',
								required: true,
								placeholder:
									AppConstants.placeholders.selectAnOption,
								showClear: true,
								appendTo:
									FormlyConstants.appendToTargets.body,
								options: this.ruleValidatorDefinitionOptions
							},
							asyncValidators: {
								uniqueName: {
									expression: (
										control: UntypedFormControl) =>
										this.uniqueValidatorDefinitionName(
											control),
									message:
										'Can not add multiple validation '
										+ 'Definitions.'
								}
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: async() =>
								this.createServerValidations()
						}]
				},
				update: {
					disabledExpandItem: true,
					items: [
						{
							command:
								() =>
								{
									const canDeactivate:
										boolean | Observable<boolean> =
										this.appCanDeactivateGuard
											.canDeactivate(this);

									if (canDeactivate === false)
									{
										return;
									}

									const itemId: number =
										this.serverValidationsCommonTableContext
											.source.selectedItem.id;
									const updateRouteData: string =
										ObjectHelper.mapRouteData(
											{
												ruleValidationDefinitionId:
													itemId
											});

									this.router.navigate(
										[
											'admin/entity/ruleValidations/edit',
											this.entityDefinitionId
										],
										{
											queryParams:
												{
													routeData: updateRouteData
												}
										});
								}
						}
					]
				},
				delete: {
					deleteStatement: () =>
						'Confirm to Remove Rule Validation Definition'
							+ ` ${this.serverValidationsCommonTableContext
								.source.selectedItem.validationName}`,
					items: [
						{
							label: 'Remove',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async () =>
								this.deleteServerValidations()
						}
					]
				}
			}
		};
	}

	/**
	 * Sets the presentation logic table definitions.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	public async setPresentationLogicTableDefinitions(): Promise<void>
	{
		this.presentationLogicTableDefinitions =
			{
				tableTitle: this.presentationLogic,
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.presentationLogicCommonTableContext,
						this.presentationLogic),
				hideTableTitle: true,
				hideSettings: true,
				nestedTable: true,
				objectSearch: {
					filter: `DefinitionId eq ${this.ruleDefinition.id}`,
					orderBy: `Order ${AppConstants.sortDirections.ascending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: 5
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const rulePresentationDefinitions:
								IRulePresentationDefinition[] =
								<IRulePresentationDefinition[]>
								await this.rulePresentationDefinitionApiService
									.query(
										objectSearch.filter,
										objectSearch.orderBy,
										objectSearch.offset,
										objectSearch.limit);

						const rulePresentations: any[] = [];

						for (const rulePresentation of
							rulePresentationDefinitions)
						{
							let presentationName: string = AppConstants.empty;

							for (const presentationLogic of
								this.rulePresentationLogicDefinition)
							{
								if (rulePresentation
									.presentationLogicDefinitionId ===
										presentationLogic.id)
								{
									presentationName = presentationLogic.name;
								}
							}

							rulePresentations.push(
								{
									id: rulePresentation.id,
									presentationName: presentationName,
									presentationLogicDefinitionId:
										rulePresentation
											.presentationLogicDefinitionId,
									jsonData:
										rulePresentation.jsonData,
									dataKey:
										rulePresentation.dataKey,
									definitionId:
										rulePresentation
											.presentationLogicDefinitionId,
									eventType: rulePresentation.eventType,
									order: rulePresentation.order
								});
						}

						return rulePresentations;
					},
				availableColumns: this.presentationLogicAvailableColumns,
				selectedColumns: this.presentationLogicSelectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.presentationLogicCommonTableContext =
						commonTableContext;
				},
				actions: {
					create: {
						layout: [
							{
								key: 'presentationLogicDefinitionId',
								type: FormlyConstants.customControls
									.customSelect,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Name',
									required: true,
									placeholder:
										AppConstants.placeholders
											.selectAnOption,
									showClear: true,
									appendTo:
										FormlyConstants.appendToTargets.body,
									options:
										this.presentationLogicDefinitionOptions
								}
							}
						],
						items: [
							{
								label: 'Create',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: async() =>
									this.createPresentationDefinition()
							}]
					},
					update: {
						disabledExpandItem: true,
						items: [
							{
								command: () =>
								{
									const canDeactivate:
										boolean | Observable<boolean> =
										this.appCanDeactivateGuard
											.canDeactivate(this);

									if (canDeactivate === false)
									{
										return;
									}

									const itemId: number =
										this.presentationLogicCommonTableContext
											.source.selectedItem.id;
									const updateRouteData: string =
										ObjectHelper.mapRouteData(
											{
												rulePresentationDefinitionId:
													itemId
											});

									this.router.navigate(
										[
											'admin/entity/rulePresentation'
												+ '/edit',
											this.entityDefinitionId
										],
										{
											queryParams:
												{
													routeData: updateRouteData
												}
										});
								}
							}
						]
					},
					delete: {
						deleteStatement: () =>
							'Confirm to Remove Presentation Definition '
								+ this.presentationLogicCommonTableContext
									.source.selectedItem.presentationName,
						items: [
							{
								label: 'Remove',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: async () =>
									this.deletePresentationDefinition()
							}
						]
					},
					updateIndex:
					[
						{
							id: 'updateIndexUp',
							command: async(
								selectedItem: any) =>
								this.updateOrderIndex(
									selectedItem,
									-1)
						},
						{
							id: 'updateIndexDown',
							command: async(
								selectedItem: any) =>
								this.updateOrderIndex(
									selectedItem,
									1)
						}
					]
				}
			};
	}

	/**
	 * Validates the rule name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Form Control used to get the input value
	 * @returns {Promise<boolean>}
	 * The Validator promise result.
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async uniqueRuleName(control: UntypedFormControl): Promise<boolean>
	{
		const existingRules: IRuleDefinition[] =
			<IRuleDefinition[]>
			await this.ruleDefinitionApiService
				.query(
					`id neq ${this.ruleDefinition.id}`
						+ ` and name eq '${control.value}'`
						+ ' and entityTypeId'
						+ ` eq ${this.entityDefinition.typeId}`
						+ ' and entityVersionId'
						+ ` eq '${this.entityDefinition.versionId}'`,
					AppConstants.empty);

		return Promise.resolve(existingRules.length === 0);
	}

	/**
	 * Validates the workflow name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Form Control used to get the input value
	 * @returns {Promise<boolean>}
	 * The Validator promise result.
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async uniqueWorkflowName(control: UntypedFormControl):
		Promise<boolean>
	{
		const existingruleViolationWorkflowActionDefinitions:
			IRuleViolationWorkflowActionDefinition[] =
			<IRuleViolationWorkflowActionDefinition[]>
			await this.ruleViolationWorkflowActionDefinitionApiService
				.query(
					`WorkflowActionDefinitionId eq ${control.value}`
						+ ` and definitionId eq ${this.ruleDefinition.id}`,
					AppConstants.empty);

		return Promise.resolve(
			existingruleViolationWorkflowActionDefinitions
				.length === 0);
	}

	/**
	 * Validates the validator definition name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Form Control used to get the input value
	 * @returns {Promise<boolean>}
	 * The Validator promise result.
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async uniqueValidatorDefinitionName(
		_control: UntypedFormControl): Promise<boolean>
	{
		const existingRuleValidatorDefinition:
			IRuleValidationDefinition[] =
			await this.ruleValidationDefinitionApiService
				.query(
					`definitionId eq ${this.ruleDefinition.id}`,
					// To Do
					// This will allow add multiple validations
					// for the same rule definition once the server validation
					// allows to add it.
					// + ` and validatorDefinitionId eq ${control.value}`
					AppConstants.empty);

		return Promise.resolve(existingRuleValidatorDefinition.length === 0);
	}

	/**
	 * Validates if the rule order is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async uniqueOrder(
		control: UntypedFormControl): Promise<boolean>
	{
		const existingRules: IRuleDefinition[] =
			<IRuleDefinition[]>
			await this.ruleDefinitionApiService
				.query(
					`id neq ${this.ruleDefinition.id}`
						+ ` and order eq ${control.value}`
						+ ' and entityTypeId'
						+ ` eq ${this.entityDefinition.typeId}`
						+ ' and entityVersionId'
						+ ` eq '${this.entityDefinition.versionId}'`,
					AppConstants.empty);

		return Promise.resolve(existingRules.length === 0);
	}

	/**
	 * Creates a new rule validation.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async createServerValidations(): Promise<void>
	{
		const createServerValidationAction:
			Function = async() =>
			{
				const validatorDefinition: IRuleValidatorDefinition =
					await this.ruleValidatorDefinitionApiService
						.get(this.serverValidationsCommonTableContext
							.source.selectedItem.validatorDefinitionId);
				const validatorJsonData: string =
					validatorDefinition.jsonData
						.replace(
							/\\"/g,
							'"');

				const validatorJsonDataObject: any =
					JSON.parse(validatorJsonData);

				const validationDataObject: any =
					{
						data: {}
					};

				for (const schema of validatorJsonDataObject.schema)
				{
					validationDataObject.data[schema] =
						AppConstants.empty;
				}

				const newRuleValidationDefinitionActionDefinition:
					IRuleValidationDefinition =
					{
						definitionId: this.ruleDefinition.id,
						validatorDefinitionId:
							this.serverValidationsCommonTableContext
								.source.selectedItem.validatorDefinitionId,
						jsonData: `${JSON.stringify(
							validationDataObject,
							undefined,
							AppConstants.jsonTabIndent)}`
					};

				const newRuleValidationId: number =
					await this.ruleValidationDefinitionApiService
						.create(newRuleValidationDefinitionActionDefinition);

				this.router.navigate(
					[
						'admin/entity/ruleValidations/edit',
						this.entityDefinitionId
					],
					{
						queryParams:
						{
							routeData:
								ObjectHelper.mapRouteData(
									{
										ruleValidationDefinitionId:
										newRuleValidationId
									})
						}
					});
			};

		const canDeactivate:
			boolean | Observable<boolean> =
			this.appCanDeactivateGuard.canDeactivate(this);

		if (canDeactivate === false)
		{
			this.serverValidationsCommonTableContext
				.source
				.toggleCreateDisplay();

			return;
		}

		await this.activityService.handleActivity(
			new Activity(
				createServerValidationAction(),
				'<strong>Creating Server Validation</strong>',
				'<strong>Created Server Validation</strong>',
				'Server Validation was successfully created.',
				'Server Validation was not created.'));
	}

	/**
	 * Deletes an existing rule validation.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async deleteServerValidations(): Promise<void>
	{
		const selectedItem: any =
			this.serverValidationsCommonTableContext.source.selectedItem;

		const deleteServerValidations: Function =
			async () =>
			{
				await this.ruleValidationDefinitionApiService
					.delete(selectedItem.id);

				this.serverValidationsCommonTableContext
					.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteServerValidations(),
				'<strong>Deleting Server Validation</strong>',
				'<strong>Deleted Server Validation</strong>',
				'Server Validation was successfully deleted.',
				'Server Validation was not deleted.'));
	}

	/**
	 * Creates a new rule violation workflow.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async createViolationWorkflow(): Promise<void>
	{
		const createViolationWorkflowAction:
			Function = async() =>
			{
				const newRuleViolationWorkflowActionDefinition:
						IRuleViolationWorkflowActionDefinition =
						<IRuleViolationWorkflowActionDefinition>
						{
							definitionId: this.ruleDefinition.id,
							actionTypeId: 1,
							workflowActionDefinitionId:
								this.commonTableContext.source
									.selectedItem.actionDefinitionId
						};

				const newViolationWorkflowId: number =
					await this
						.ruleViolationWorkflowActionDefinitionApiService
						.create(newRuleViolationWorkflowActionDefinition);

				this.router.navigate(
					[
						'admin/entity/ruleWorkflow/edit',
						this.entityDefinitionId
					],
					{
						queryParams:
							{
								routeData:
									ObjectHelper.mapRouteData(
										{
											ruleWorkflowDefinitionId:
												newViolationWorkflowId
										})
							}
					});
			};

		const canDeactivate:
			boolean | Observable<boolean> =
			this.appCanDeactivateGuard.canDeactivate(this);

		if (canDeactivate === false)
		{
			this.commonTableContext.source.toggleCreateDisplay();

			return;
		}

		await this.activityService.handleActivity(
			new Activity(
				createViolationWorkflowAction(),
				'<strong>Creating Violation Workflow</strong>',
				'<strong>Created Violation Workflow</strong>',
				'Violation Workflow was successfully created.',
				'Violation Workflow was not created.'));
	}

	/**
	 * Deletes an existing violation workflow.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async deleteViolationWorkflow(): Promise<void>
	{
		const deleteViolationWorkflow: Function =
			async() =>
			{
				await this.ruleViolationWorkflowActionDefinitionApiService
					.delete(this.commonTableContext.source.selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteViolationWorkflow(),
				'<strong>Deleting Violation Workflow</strong>',
				'<strong>Deleted Violation Workflow</strong>',
				'Violation Workflow was successfully deleted.',
				'Violation Workflow was not deleted.'));
	}

	/**
	 * Creates a new rule presentation definition.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async createPresentationDefinition(): Promise<void>
	{
		const selectedItem: any =
			this.presentationLogicCommonTableContext.source.selectedItem;

		const newPresentationDefinitionAction:
			Function = async() =>
			{
				const presentationLogic: IRulePresentationLogicDefinition =
					await this.rulePresentationLogicDefinitionApiService
						.get(selectedItem.presentationLogicDefinitionId);
				const presentationLogicJsonData: string =
					presentationLogic.jsonData;

				const presentationLogicDefinitionJsonDataObject =
					JSON.parse(presentationLogicJsonData);

				const presentationDefinitionDataObject =
					{
						data: {}
					};

				for (const schema of
					presentationLogicDefinitionJsonDataObject.schema)
				{
					presentationDefinitionDataObject.data[schema] =
						AppConstants.empty;
				}

				const newRulePresentationDefinition:
						IRulePresentationDefinition =
						<IRulePresentationDefinition>
						{
							definitionId: this.ruleDefinition.id,
							presentationLogicDefinitionId:
								selectedItem
									.presentationLogicDefinitionId,
							jsonData: `${JSON.stringify(
								presentationDefinitionDataObject,
								undefined,
								AppConstants.jsonTabIndent)}`,
							dataKey: AppConstants.empty,
							eventType:
								this.rulePresentationService
									.eventTypes.onChange,
							order: ((this.presentationLogicCommonTableContext
								.source
								.virtualData[
									this.presentationLogicCommonTableContext
										.source
										.virtualData.length - 1]
								?.order ?? 0) + 10)
						};

				const newRulePresentationId: number =
					await this.rulePresentationDefinitionApiService
						.create(newRulePresentationDefinition);

				this.router.navigate(
					[
						'admin/entity/rulePresentation/edit',
						this.entityDefinitionId
					],
					{
						queryParams:
							{
								routeData:
									ObjectHelper.mapRouteData(
										{
											rulePresentationDefinitionId:
												newRulePresentationId
										})
							}
					});
			};

		const canDeactivate:
			boolean | Observable<boolean> =
			this.appCanDeactivateGuard.canDeactivate(this);

		if (canDeactivate === false)
		{
			this.presentationLogicCommonTableContext
				.source.toggleCreateDisplay();

			return;
		}

		await this.activityService.handleActivity(
			new Activity(
				newPresentationDefinitionAction(),
				'<strong>Creating Presentation Logic</strong>',
				'<strong>Created Presentation Logic</strong>',
				'Presentation Logic was successfully created.',
				'Presentation Logic was not created.'));
	}

	/**
	 * Deletes an existing rule presentation definition.
	 *
	 * @async
	 * @memberof EntityRuleDefinitionComponent
	 */
	private async deletePresentationDefinition(): Promise<void>
	{
		const deletePresentationDefinition: Function =
			async() =>
			{
				await this.rulePresentationDefinitionApiService
					.delete(
						this.presentationLogicCommonTableContext
							.source.selectedItem.id);

				this.presentationLogicCommonTableContext
					.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deletePresentationDefinition(),
				'<strong>Deleting Presentation Logic</strong>',
				'<strong>Deleted Presentation Logic</strong>',
				'Presentation Logic was successfully deleted.',
				'Presentation Logic was not deleted.'));
	}

	/**
	 * Updates the order index of the selected row item
	 * up or down based on the indexOperator.
	 *
	 * @async
	 * @param {any} selectedItem
	 * The selected item to update the index of.
	 * @param {number} indexReference
	 * The index reference to add or substract to the current
	 * selected order index.
	 * @memberof EntityRulesComponent
	 */
	private async updateOrderIndex(
		selectedItem: any,
		indexReference: number): Promise<void>
	{
		this.presentationLogicCommonTableContext.source
			.loadingNextDataset = true;
		this.presentationLogicCommonTableContext.source.selectedItem =
			selectedItem;

		const updateOrderIndex: Function =
			async() =>
			{
				const orderedRequisites: any[] =
					this.presentationLogicCommonTableContext.source.virtualData
						.filter((data) => data !== undefined)
						.sort((itemOne: any, itemTwo: any) =>
							itemOne.order - itemTwo.order);

				const neighborOrderIndex: number =
						this.findSelectedChildIndex(
							orderedRequisites,
							selectedItem) + indexReference;

				const neighbor: any =
					orderedRequisites[neighborOrderIndex];

				await this.rulePresentationDefinitionApiService
					.update(
						neighbor.id,
						<IRulePresentationDefinition>
						{
							id: neighbor.id,
							definitionId: this.ruleDefinition.id,
							presentationLogicDefinitionId:
								neighbor.presentationLogicDefinitionId,
							jsonData: neighbor.jsonData,
							dataKey: neighbor.dataKey,
							eventType: neighbor.eventType,
							order: 1000
						});

				await this.rulePresentationDefinitionApiService
					.update(
						selectedItem.id,
						<IRulePresentationDefinition>
						{
							id: selectedItem.id,
							definitionId: this.ruleDefinition.id,
							presentationLogicDefinitionId:
								selectedItem.presentationLogicDefinitionId,
							jsonData: selectedItem.jsonData,
							dataKey: selectedItem.dataKey,
							eventType: selectedItem.eventType,
							order: neighbor.order
						});

				await this.rulePresentationDefinitionApiService
					.update(
						neighbor.id,
						<IRulePresentationDefinition>
						{
							id: neighbor.id,
							definitionId: this.ruleDefinition.id,
							presentationLogicDefinitionId:
								neighbor.presentationLogicDefinitionId,
							jsonData: neighbor.jsonData,
							dataKey: neighbor.dataKey,
							eventType: neighbor.eventType,
							order: selectedItem.order
						});

				this.presentationLogicCommonTableContext
					.source.virtualData[neighborOrderIndex] =
					{
						...neighbor,
						order: selectedItem.order
					};
				this.presentationLogicCommonTableContext.source.virtualData[
					neighborOrderIndex - indexReference] =
					{
						...this.presentationLogicCommonTableContext
							.source.selectedItem,
						order: neighbor.order
					};

				this.sortCommonTableByOrder();
				this.presentationLogicCommonTableContext
					.source.loadingNextDataset = false;
			};

		await this.activityService.handleActivity(
			new Activity(
				updateOrderIndex(),
				'<strong>Updating Presentation Logic Order</strong>',
				'<strong>Updated Presentation Logic Order</strong>',
				'Presentation Logic Order was successfully updated.',
				'Presentation Logic Order was not updated.'),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Finds the selected child index.
	 *
	 * @param {any[]} children
	 * The children object array.
	 * @param {any} selectedChild
	 * The selected child.
	 * @returns {number}
	 * The child index selected.
	 * @memberof EntityRuleDefinitionComponent
	 */
	private findSelectedChildIndex(
		children: any[],
		selectedChild: any): number
	{
		for (let index = 0; index < children.length; index++)
		{
			if (children[index].id === selectedChild.id
				&& children[index].order === selectedChild.order)
			{
				return index;
			}
		}

		return -1;
	}

	/**
	 * Sorts the common table context data by order.
	 *
	 * @memberof EntityRuleDefinitionComponent
	 */
	private sortCommonTableByOrder(): void
	{
		this.commonTableContext.source.virtualData
			.sort((itemOne: any, itemTwo: any) =>
				itemOne.order - itemTwo.order);
		this.commonTableContext.source.virtualData =
			[
				...this.commonTableContext.source.virtualData
			];
	}
}