/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * The storage types that the server supports.
 */
export enum StorageType
{
	/**
	 * The file is saved to a drive or other strage service
	 * like Azure Blob Storage
	 */
	Persisted = 'Persisted',

	/**
	 * The file bytes are encoded and embedded into a property
	 * in the enity instance.
	 */
	Embedded = 'Embedded',

	/**
	 * The file is referenced. No bytes are stored by
	 * the application, only a reference that is verified.
	 */
	Referenced = 'Referenced'
}