/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ActivityService
} from '@shared/services/activity.service';

/**
 * A class representing a generic context level operation.
 *
 * @export
 * @class ContextOperationAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class ContextOperationAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a ContextOperationAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof ContextOperationAction
	 */
	public constructor(
		protected activityService: ActivityService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation display name.
	 *
	 * @type {string}
	 * @memberof ContextOperationAction
	 */
	public operationDisplayName: string = AppConstants.empty;

	/**
	 * Gets or sets the context operation to execute as a raw string.
	 *
	 * @type {string}
	 * @memberof ContextOperationAction
	 */
	public contextOperationPromise: string = AppConstants.empty;

	/**
	 * Gets or sets the success message.
	 *
	 * @type {string}
	 * @memberof ContextOperationAction
	 */
	public successMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof ContextOperationAction
	 */
	public get operationName(): string
	{
		return this.operationDisplayName;
	}

	/**
	 * Gets the message to be displayed when this operation
	 * has completed successfully.
	 *
	 * @type {string}
	 * @memberof ContextOperationAction
	 */
	public get operationSuccessMessage(): string
	{
		return AnyHelper.isNullOrEmpty(this.successMessage)
			? `Operation Complete: ${this.operationName}.`
			: this.successMessage;
	}

	/**
	 * Gets the message to be displayed when this operation
	 * has failed.
	 *
	 * @type {string}
	 * @memberof ContextOperationAction
	 */
	public get operationFailureMessage(): string
	{
		return `Unable to perform the operation ${this.operationName}.`;
	}

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof ContextOperationAction
	 */
	public async execute(): Promise<void>
	{
		await this.createOperationPromise(
			this.contextOperationPromise);
	}
}