/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	ISecurityItemDto
} from '@api/interfaces/security/security-item.dto.interface';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	IEntitySecurityConfiguration
} from '@shared/interfaces/entities/entity-security-configuration.interface';
import {
	IIterableFormly
} from '@shared/interfaces/formly/formly-iterable-configuration.interface';

/* eslint-enable max-len */

/**
 * A class representing the available methods (business logic) for an
 * entity security.
 *
 * @export
 * @class EntitySecurity
 */
export class EntitySecurity
{
	/**
	 * Gets the entity section title identifier.
	 *
	 * @type {string}
	 * @memberof EntitySecurity
	 */
	private readonly sectionTitle: string =
		FormlyConstants.customControls.customSectionTitle;

	/**
	 * Gets the entity repeater identifier.
	 *
	 * @type {string}
	 * @memberof EntitySecurity
	 */
	private readonly repeater: string =
		FormlyConstants.customControls.customRepeater;

	/**
	 * Gets the entity tab content identifier.
	 *
	 * @type {string}
	 * @memberof EntitySecurity
	 */
	private readonly tabContent: string =
		FormlyConstants.customControls.customTabContent;

	/**
	 * Gets the entity tab content identifier.
	 *
	 * @type {string}
	 * @memberof EntitySecurity
	 */
	private readonly emptyWrapper: string =
		FormlyConstants.customControls.customEmptyWrapper;

	/**
	 * Gets the Scrubbed formly layout. This will remove from the layout those
	 * fields, groups and sections when the access policy is not true.
	 *
	 * @param {FormlyFieldConfig[]} formlyLayout
	 * The formly layout.
	 * @param {ISecurityDefinitionDto[]} securityPermissions
	 * The security access policies.
	 * @returns {Promise<FormlyFieldConfig[]>}
	 * A promise containing the scrubbed formly layout.
	 * @async
	 * @memberof EntityLayout
	 */
	public async getScrubbedFormlyLayout(
		formlyLayout: FormlyFieldConfig[],
		securityPermissions: ISecurityItemDto[],
		context: any): Promise<FormlyFieldConfig[]>
	{
		if (AnyHelper.isNull(securityPermissions)
			|| securityPermissions.length === 0)
		{
			EventHelper.dispatchBannerEvent(
				'Something went wrong.',
				'Check your security access policies or contact support.',
				AppConstants.activityStatus.error);

			return [];
		}

		const securityLayout: FormlyFieldConfig[] =
			this.getGenericScrubbedLayout(
				formlyLayout,
				{
					securityScrub: (iterableFormly: IIterableFormly) =>
					{
						if (!AnyHelper.isNullOrEmpty(
							iterableFormly.formlyField.wrappers)
							&& iterableFormly.formlyField.wrappers
								.indexOf(this.emptyWrapper) >= 0)
						{
							return;
						}

						iterableFormly.formlyField.props
							.securityAccessPolicy =
							(!AnyHelper.isNull(securityPermissions)
								|| securityPermissions?.length > 0)
								&& !AnyHelper.isNull(iterableFormly.formlyField
									.props?.attributes)
								? securityPermissions[
									this.getSecurityIndex(
										iterableFormly.formlyField,
										securityPermissions)]
								: null;
					}
				});

		const scrubbedFieldsLayout: FormlyFieldConfig[] =
			this.getGenericScrubbedLayout(
				securityLayout,
				{
					repeaterCondition: (iterableFormly: IIterableFormly) =>
						iterableFormly.formlyField.props
							?.securityAccessPolicy?.rights.read === true,
					fieldCondition: (iterableFormly: IIterableFormly) =>
						iterableFormly.formlyField.props
							?.securityAccessPolicy?.rights.read === true
							|| AnyHelper.isNullOrWhitespace(
								iterableFormly.formlyField.key)
				});

		const scrubbedSectionsLayout: FormlyFieldConfig[] =
			this.getGenericScrubbedLayout(
				scrubbedFieldsLayout,
				{
					fieldCondition: (iterableFormly: IIterableFormly) =>
						(iterableFormly.formlyField.type === this.sectionTitle
							&& !AnyHelper.isNull(
								iterableFormly.formlyLayout[
									iterableFormly.fieldIndex + 1])
							&& (iterableFormly.formlyLayout[
								iterableFormly.fieldIndex + 1].type
								!== this.sectionTitle
								&& iterableFormly.formlyLayout[
									iterableFormly.fieldIndex + 1].type
									!== this.repeater))
							|| iterableFormly.formlyField.type
								!== this.sectionTitle
				});

		const scrubbedRepeatersLayout: FormlyFieldConfig[] =
			this.getGenericScrubbedLayout(
				scrubbedSectionsLayout,
				{
					repeaterCondition: (iterableFormly: IIterableFormly) =>
					{
						const fieldArray: any =
							iterableFormly.formlyField.fieldArray;

						return (!AnyHelper.isNull(fieldArray.fieldGroup)
							&& fieldArray.fieldGroup.length > 0);
					}
				});

		const scrubbedTabsLayout: FormlyFieldConfig[] =
			this.getGenericScrubbedLayout(
				scrubbedRepeatersLayout,
				{
					tabCondition: (iterableFormly: IIterableFormly) =>
						(!AnyHelper.isNull(
							iterableFormly.formlyField.fieldGroup)
							&& iterableFormly.formlyField.fieldGroup.length > 0)
				});

		return this.getScrubbedVisibleTabs(
			scrubbedTabsLayout,
			context);
	}

	/**
	 * Gets the generic scrubbed layout.
	 *
	 * @param {FormlyFieldConfig[]} initialLayout
	 * The initial formly layout.
	 * @param {IEntitySecurityConfiguration} entitySecurityConfiguration
	 * The security configuration.
	 * @returns {FormlyFieldConfig[]}
	 * The scrubbed layout.
	 * @memberof EntityLayout
	 */
	private getGenericScrubbedLayout(
		initialLayout: FormlyFieldConfig[],
		entitySecurityConfiguration: IEntitySecurityConfiguration):
		FormlyFieldConfig[]
	{
		return this.getNestedFormlyLayout(
			initialLayout,
			entitySecurityConfiguration);
	}

	/**
	 * Gets the scrubbed layout.
	 *
	 * @param {IIterableFormly} iterableFormly
	 * The iterable formly.
	 * @param {IEntitySecurityConfiguration} entitySecurityConfiguration
	 * The security configuration.
	 * @returns {FormlyFieldConfig[]}
	 * The scrubbed layout.
	 * @memberof EntityLayout
	 */
	private getScrubbedLayout(
		iterableFormly: IIterableFormly,
		entitySecurityConfiguration: IEntitySecurityConfiguration):
		FormlyFieldConfig[]
	{
		const scrubbedLayout: FormlyFieldConfig[] = [];
		let getScrubbedFormlyField:
			(iterableFormly: IIterableFormly,
				entitySecurityConfiguration: IEntitySecurityConfiguration) =>
				FormlyFieldConfig =
				this.getScrubbedField.bind(this);

		if (!AnyHelper.isNullOrEmpty(iterableFormly.formlyField.wrappers)
			&& iterableFormly.formlyField.wrappers
				.indexOf(this.tabContent) >= 0)
		{
			getScrubbedFormlyField = this.getScrubbedTab.bind(this);
		}
		else if (iterableFormly.formlyField.type === this.repeater)
		{
			getScrubbedFormlyField = this.getScrubbedRepeater.bind(this);
		}

		this.setGenericScrubbedFormlyField(
			scrubbedLayout,
			getScrubbedFormlyField,
			iterableFormly,
			entitySecurityConfiguration);

		return scrubbedLayout;
	}

	/**
	 * Gets the scrubbed tab.
	 *
	 * @param {IIterableFormly} iterableFormly
	 * The iterable formly.
	 * @param {IEntitySecurityConfiguration} entitySecurityConfiguration
	 * The security configuration.
	 * @returns {FormlyFieldConfig}
	 * The scrubbed tab.
	 * @memberof EntityLayout
	 */
	private getScrubbedTab(
		iterableFormly: IIterableFormly,
		entitySecurityConfiguration: IEntitySecurityConfiguration):
		FormlyFieldConfig
	{
		const conditionMet: boolean =
			!AnyHelper.isNull(entitySecurityConfiguration.tabCondition)
				? entitySecurityConfiguration
					.tabCondition(iterableFormly)
				: true;

		if (conditionMet !== true)
		{
			return null;
		}

		iterableFormly.formlyField.fieldGroup =
			this.getNestedFormlyLayout(
				iterableFormly.formlyField.fieldGroup,
				entitySecurityConfiguration);

		return iterableFormly.formlyField;
	}

	/**
	 * Gets the scrubbed repeater.
	 *
	 * @param {IIterableFormly} iterableFormly
	 * The iterable formly.
	 * @param {IEntitySecurityConfiguration} entitySecurityConfiguration
	 * The security configuration.
	 * @returns {FormlyFieldConfig}
	 * The scrubbed repeater.
	 * @memberof EntityLayout
	 */
	private getScrubbedRepeater(
		iterableFormly: IIterableFormly,
		entitySecurityConfiguration: IEntitySecurityConfiguration):
		FormlyFieldConfig
	{
		const conditionMet: boolean =
			!AnyHelper.isNull(entitySecurityConfiguration.repeaterCondition)
				? entitySecurityConfiguration
					.repeaterCondition(iterableFormly)
				: true;

		if (conditionMet !== true)
		{
			return null;
		}

		const fieldArray: any =
			iterableFormly.formlyField.fieldArray;
		fieldArray.fieldGroup =
			this.getNestedFormlyLayout(
				fieldArray.fieldGroup,
				entitySecurityConfiguration);

		if (!AnyHelper.isNull(entitySecurityConfiguration.securityScrub))
		{
			entitySecurityConfiguration.securityScrub(iterableFormly);
		}

		return iterableFormly.formlyField;
	}

	/**
	 * Gets the scrubbed field.
	 *
	 * @param {IIterableFormly} iterableFormly
	 * The iterable formly.
	 * @param {IEntitySecurityConfiguration} entitySecurityConfiguration
	 * The security configuration.
	 * @returns {FormlyFieldConfig}
	 * The scrubbed field.
	 * @memberof EntityLayout
	 */
	private getScrubbedField(
		iterableFormly: IIterableFormly,
		entitySecurityConfiguration: IEntitySecurityConfiguration):
		FormlyFieldConfig
	{
		const conditionMet =
			!AnyHelper.isNull(entitySecurityConfiguration.fieldCondition)
				? entitySecurityConfiguration
					.fieldCondition(iterableFormly)
				: true;

		if (conditionMet !== true)
		{
			return null;
		}

		if (!AnyHelper.isNull(entitySecurityConfiguration.securityScrub))
		{
			entitySecurityConfiguration.securityScrub(iterableFormly);
		}

		// Disable if no update permissions.
		if (!AnyHelper.isNull(iterableFormly.formlyField.type)
			&& iterableFormly.formlyField.type !== 'custom-table-display')
		{
			const props: any =
				AnyHelper.isNull(iterableFormly.formlyField.props)
					? {}
					: iterableFormly.formlyField.props;

			const canUpdate: boolean =
				AnyHelper.isNull(props?.securityAccessPolicy?.rights)
					? false
					: props?.securityAccessPolicy?.rights.update;

			props.disabled = !canUpdate;

			iterableFormly.formlyField.props = props;
		}

		return iterableFormly.formlyField;
	}

	/**
	 * Sets the scrubbed formly field.
	 *
	 * @param {FormlyFieldConfig[]} scrubbedLayout
	 * The scrubbed layout.
	 * @param {(iterableFormly: IIterableFormly,
	 * entitySecurityConfiguration: IEntitySecurityConfiguration) =>
	 * FormlyFieldConfig} getScrubbedFormlyField
	 * The method passed to get the scrubbed formly field.
	 * @param {IIterableFormly} iterableFormly
	 * The iterable formly.
	 * @param {IEntitySecurityConfiguration} entitySecurityConfiguration
	 * The security configuration.
	 * @memberof EntityLayout
	 */
	private setGenericScrubbedFormlyField(
		scrubbedLayout: FormlyFieldConfig[],
		getScrubbedFormlyField:
			(iterableFormly: IIterableFormly,
				entitySecurityConfiguration: IEntitySecurityConfiguration) =>
				FormlyFieldConfig,
		iterableFormly: IIterableFormly,
		entitySecurityConfiguration: IEntitySecurityConfiguration): void
	{
		const scrubbedField: FormlyFieldConfig =
			getScrubbedFormlyField(
				iterableFormly,
				entitySecurityConfiguration);

		if (!AnyHelper.isNullOrEmpty(scrubbedField))
		{
			scrubbedLayout.push(scrubbedField);
		}
	}

	/**
	 * Gets the visible tabs scrubbed layout.
	 *
	 * @param {FormlyFieldConfig[]} scrubbedLayout
	 * The scrubbed layout.
	 * @returns {Promise<FormlyFieldConfig[]>}
	 * A promise containing the visible tabs scrubbed layout.
	 * @async
	 * @memberof EntityLayout
	 */
	private async getScrubbedVisibleTabs(
		scrubbedLayout: FormlyFieldConfig[],
		context: any):
		Promise<FormlyFieldConfig[]>
	{
		let tabAdded: boolean = false;

		const tabs: FormlyFieldConfig[] = scrubbedLayout.map(
			(field: FormlyFieldConfig) =>
			{
				if (FormlyHelper.isTabWrapper(
					field) === true)
				{
					field.props.visible = !tabAdded;

					if (tabAdded === false)
					{
						tabAdded = true;
					}
				}

				return field;
			});

		const visibleTabs = [];

		for (const item of tabs)
		{
			const visible: boolean =
				await FormlyHelper.visible(
					item,
					context);

			if (visible)
			{
				visibleTabs.push(item);
			}
		}

		return visibleTabs;
	}

	/**
	 * Gets the security access policy index.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field.
	 * @param {ISecurityPermission[]} securityPermissions
	 * The security access policies permissions.
	 * @returns {number}
	 * The security access policy index.
	 * @memberof EntityLayout
	 */
	private getSecurityIndex(
		field: FormlyFieldConfig,
		securityPermissions: ISecurityItemDto[]): number
	{
		const fieldKey: string =
			`${AppConstants.nestedDataKeyPrefix}`
				+ `${field.props.attributes[
					FormlyConstants.attributeKeys.dataKey]}`;

		return SecurityHelper
			.getSecurityPermissionIndex(
				fieldKey,
				securityPermissions);
	}

	/**
	 * Gets the nested formly layout.
	 *
	 * @param {FormlyFieldConfig[]} fieldGroup
	 * The formly field group.
	 * @param {ISecurityPermission[]} entitySecurityConfiguration
	 * The entity security configuration.
	 * @returns {FormlyFieldConfig[]}
	 * The nested formly layout.
	 * @memberof EntityLayout
	 */
	private getNestedFormlyLayout(
		fieldGroup: FormlyFieldConfig[],
		entitySecurityConfiguration: IEntitySecurityConfiguration):
		FormlyFieldConfig[]
	{
		let layout: FormlyFieldConfig[] = [];
		fieldGroup.forEach(
			(nestedField: FormlyFieldConfig,
				nestedIndex: number,
				nestedLayout: FormlyFieldConfig[]) =>
			{
				const nestedIterableFormly: IIterableFormly =
					<IIterableFormly>
					{
						formlyField: nestedField,
						fieldIndex: nestedIndex,
						formlyLayout: nestedLayout
					};

				layout = [
					... layout,
					... this.getScrubbedLayout(
						nestedIterableFormly,
						entitySecurityConfiguration)
				];
			});

		return layout;
	}
}