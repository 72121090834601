/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ViewChild
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	InputMask
} from 'primeng/inputmask';

@Component({
	selector: 'custom-masked-input',
	templateUrl: './custom-masked-input.component.html'
})

/**
 * A component representing an instance of a Custom Masked Input.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomMaskedInputComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
export class CustomMaskedInputComponent
	extends ExtendedCustomControlDirective
	implements AfterViewInit
{
	/** Initializes a new instance of the CustomMaskedInputComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomMaskedInputComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets masked input control.
	 *
	 * @type {InputMask}
	 * @memberof CustomMaskedInputComponent
	 */
	@ViewChild('maskedInput')
	public maskedInput: InputMask;

	/**
	 * On after view initialization event.
	 * Sets the hidden input value for this masked input to match the data
	 * found on load. Once this is hooked in, all further form interactions
	 * are the same as other inputs.
	 *
	 * @memberof CustomMaskedInputComponent
	 */
	public ngAfterViewInit(): void
	{
		// Work around for not using NGModel for the masked input
		// and using the form control directly as formly requires.
		this.maskedInput.inputViewChild.nativeElement.value =
			this.maskedInput.value;

		const existingValue: string = this.field.formControl.value;
		this.field.formControl.reset();
		this.field.formControl.setValue(existingValue);
		this.validateControl();
	}
}