
<div class="list-item-container ui-g ui-g-12 no-padding"
	*ngIf="context.data?.entityInstance?.id === null">
	<span class="panel-sub-title">
		No Data
	</span>
</div>
<div class="list-item-container ui-g ui-g-12 no-padding"
	*ngIf="context.data?.entityInstance?.id != null">
	<ul>
		<ng-template
			[ngIf]="(data?.length || 0) > 0"
			ngFor
			let-item
			[ngForOf]="data">
			<li *ngIf="item.type != null"
				class="section-container">
				<span class="data-value">
					<p-divider align="left">
						<div>
							<span class="fa {{item.iconClass}}">
							</span>
							<span class="panel-sub-title">
								{{getDisplaySubTitle(item.type)}}
							</span>
						</div>
					</p-divider>

					<ng-template [ngIf]="item.displayProperties != null"
						[ngIfElse]="Default"
						ngFor
						let-property
						[ngForOf]="item.displayProperties">
							<span *ngIf="property.label != null"
								class="property-asset">
								<strong>
									{{property.label}}
								</strong>
								{{property.value}}
								<br>
							</span>
					</ng-template>

					<ng-template #Default>
						<ng-template [ngIf]="item.characteristics?.name?.type === 'Organization'"
							[ngIfElse]="Individual">
							<span class="data-value">
								<strong>Name:</strong>
								{{item.characteristics?.name?.legalName}}
								<br />
								<ng-template [ngIf]="item.characteristics.addresses != null"
									[ngIfElse]="FallBack">
									<strong>Address:</strong>
									<span *ngIf="item.characteristics.addresses[0] != null">
										<span *ngIf="item.characteristics.addresses[0].address != null
											&& item.characteristics.addresses[0].address != ''">
											{{item.characteristics.addresses[0].address}},
										</span>
										<span *ngIf="item.characteristics.addresses[0].city != null
											&& item.characteristics.addresses[0].city != ''">
											{{item.characteristics.addresses[0].city}},
										</span>
										{{item.characteristics.addresses[0].state}}
										{{item.characteristics.addresses[0].postalCode}}
									</span>
								</ng-template>

								<ng-template #FallBack>
									<strong>Description:</strong>
									{{item.characteristics.description}}
								</ng-template>

							</span>
						</ng-template>
						<ng-template #Individual>
							<span class="data-value">
								<span *ngIf="item.characteristics?.name?.firstName != null
									&& item.characteristics?.name?.lastName != null">
									<strong>Name:</strong>
									{{item.characteristics.name.firstName}}
									{{item.characteristics.name.lastName}}
									<br *ngIf="item.subType != null">
								</span>

								<span class="ui-g-12 no-padding sub-type-padding"
									*ngIf="item.subType != null">
									<strong>Sub-type:</strong>
									{{item.subType}}
								</span>
							</span>
						</ng-template>
					</ng-template>
				</span>
				<br />
			</li>
		</ng-template>
	</ul>
</div>