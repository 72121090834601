/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	CommonDashboardComponent
} from '@shared/components/common-dashboard/common-dashboard.component';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	CommissionDisbursementListExpandComponent
} from './commission-disbursement-list-expand/commission-disbursement-list-expand.component';

/* eslint-enable max-len */

@Component({
	selector: 'commission-disbursement-list',
	templateUrl: './commission-disbursement-list.component.html'
})

/**
 * A component representing a commissions disbursment list component.
 *
 * @export
 * @class CommissionDisbursementListComponent
 * @extends CommonTablePageDirective
 * @implements {IDynamicComponent<Component, any>}
 * @implements {OnInit}
 */
export class CommissionDisbursementListComponent
	extends CommonTablePageDirective
	implements OnInit, IDynamicComponent<CommonDashboardComponent, any>
{
	/**
	 * Initializes an instance of the commission disbursement list component.
	 *
	 * @param {ResolverService} resolver
	 * The resolver service used in this component.
	 * @memberof CommissionDisbursementListComponent
	 */
	public constructor(
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonDashboardComponent, any>}
	 * @memberof CommissionDisbursementListComponent
	 */
	public context: IDynamicComponentContext<CommonDashboardComponent, any>;

	/**
	 * Gets or sets the common table.
	 *
	 * @type {ICommonTable}
	 */
	public commonTable: ICommonTable;

	/**
	 * Gets or sets the selected table filter.
	 *
	 * @type {string}
	 * @memberof CommissionDisbursementListComponent
	 */
	public tableFilter: string = 'All';

	/**
	 * Gets or sets the list of commission disbursements.
	 *
	 * @type {any[]}
	 * @memberof CommissionDisbursementListComponent
	 */
	public disbursements: any[] = [];

	/**
	 * Gets or sets the list of filtered disbursements.
	 *
	 * @type {any[]}
	 * @memberof CommissionDisbursementListComponent
	 */
	public filteredDisbursements: any[] = [];

	/**
	 * Gets or sets the selected row count..
	 *
	 * @type {number}
	 * @memberof CommissionDisbursementListComponent
	 */
	public selectedRowCount: number = 5;

	/**
	 * Gets or sets the selected agency.
	 *
	 * @type {any}
	 * @memberof CommissionDisbursementListComponent
	 */
	public selectedAgency: any;

	/**
	 * Gets the set of quick filters for this component.
	 *
	 * @type {object}
	 * @memberof CommissionDisbursementListComponent
	 */
	private readonly quickfilters:
		{
			all: string;
			positiveBalance: string;
			negativeBalance: string;
			zeroBalance: string;
		} = {
			all: 'All',
			positiveBalance: 'Positive Balance',
			negativeBalance: 'Negative Balance',
			zeroBalance: 'Zero Balance'
		};

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof CommissionDisbursementListComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.data =
			this.context.source.entityContext.data.entityInstance;
		this.disbursements =
			this.context.data.data.disbursements;

		this.setupTableDefinitions();
		this.setfilteredDisbursements();

		this.loadingTableDefinitions = false;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @memberof CommissionDisbursementListComponent
	 */
	public setupTableDefinitions(): void
	{
		this.availableColumns =
			<ICommonTableColumn[]>
			[
				{
					dataKey: 'organizationName.displayName',
					columnHeader: 'Agency',
					displayOrder: 1
				},
				{
					dataKey: 'total',
					columnHeader: 'Amount',
					dataFormatType: 'Currency',
					displayOrder: 2
				}
			];
		this.selectedColumns =
			[
				...this.availableColumns
			];

		this.commonTable =
			{
				tableTitle: 'Agencies',
				expandTitle: () => 'View Commission Detail',
				dataKey: 'organizationId',
				tableHeight: {
					virtualPageSizeBased: true,
					minimumDrawerItemHeight: 9
				},
				actions: {
					view: {
						component: CommissionDisbursementListExpandComponent,
						items: []
					},
					filter: {
						quickFilters:
							[
								{
									label: this.quickfilters.all,
									value: this.quickfilters.all
								},
								{
									label: this.quickfilters.positiveBalance,
									value: this.quickfilters.positiveBalance
								},
								{
									label: this.quickfilters.negativeBalance,
									value: this.quickfilters.negativeBalance
								},
								{
									label: this.quickfilters.zeroBalance,
									value: this.quickfilters.zeroBalance
								}
							],
						selectedFilterValue: this.tableFilter
					}
				},
				objectSearch: {
					filter: this.tableFilter,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					() =>
					{
						this.setfilteredDisbursements();

						return Promise.resolve(this.filteredDisbursements);
					},
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext:
					(commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
					{
						this.commonTableContext = commonTableContext;
						this.selectedAgency = commonTableContext.data;
					}
			};
	}

	/**
	 * Sets the filtered commission disbursements.
	 *
	 * @memberof CommissionDisbursementListComponent
	 */
	private setfilteredDisbursements(): void
	{
		switch (this.commonTable.objectSearch.filter)
		{
			case this.quickfilters.positiveBalance:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement => disbursement.total > 0);
				break;
			case this.quickfilters.negativeBalance:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement => disbursement.total < 0);
				break;
			case this.quickfilters.zeroBalance:
				this.filteredDisbursements =
					this.disbursements.filter(
						disbursement => disbursement.total === 0);
				break;
			default:
				this.filteredDisbursements = this.disbursements;
		}
	}
}