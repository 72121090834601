/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * An enum for designating commission disbursement status.
 */
export enum CommissionDisbursementStatus {
	/**
	 * The commission disbursement is pending.
	 *
	 * @type {string}
	 * @memberof CommissionDisbursementStatus
	 */
	Pending = 'Pending',

	/**
	* The commission disbursement is approved.
	*
	* @type {string}
	* @memberof CommissionDisbursementStatus
   */
	Approved = 'Approved',

	/**
	 * The commission disbursement is rejected.
	 *
	 * @type {string}
	 * @memberof CommissionDisbursementStatus
	 */
	Rejected = 'Rejected',
}