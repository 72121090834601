/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Pipe,
	PipeTransform
} from '@angular/core';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';

@Pipe({
	name: 'dateTimeToTrueLocal'
})

/**
 * A class representing a date time pipe.
 * This will calculate and the date time value in a true local time.
 *
 * @export
 * @class DateTimeToTrueLocalPipe
 * @implements {PipeTransform}
 */
export class DateTimeToTrueLocalPipe implements PipeTransform
{
	/**
	 * Returns the date time shifted over to match the client's true local
	 * time as opposed to a system time. The expected string should be an input
	 * of a system DateTime.
	 *
	 * @param {string} value
	 * The value piped in, representing a system based DateTime.
	 * @memberof DateTimeToTrueLocalPipe
	 */
	public transform(
		value: DateTime): DateTime
	{
		if (AnyHelper.isNullOrWhitespace(value))
		{
			return null;
		}

		return DateHelper.convertToTrueLocal(value);
	}
}