/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {
	EntityEventParameterConstants
} from '@entity/shared/entity-event-parameter.constants';
import {
	EntityEventConstants
} from '@entity/shared/entity-event.constants';
import {
	BaseOperationGroupDirective
} from '@operation/directives/base-operation-group.directive';
import {
	OperationButtonTypeConstants
} from '@operation/shared/operation-button-type.constants';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	MouseEventConstants
} from '@shared/constants/mouse-event.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	MenuItem
} from 'primeng/api';
import {
	Subject,
	debounceTime
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'operation-button-bar',
	templateUrl: './operation-button-bar.component.html',
	styleUrls: ['./operation-button-bar.component.scss'],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of an operation
 * button bar.
 *
 * @export
 * @class OperationButtonBarComponent
 * @extends {BaseOperationGroupDirective}
 * @implements {OnChange}
 * @implements {OnDestroy}
 * @extends {BaseOperationGroupComponent}
 */
export class OperationButtonBarComponent
	extends BaseOperationGroupDirective
	implements OnChanges, OnDestroy
{
	/**
	 * Gets or sets the value used to specify whether this component
	 * is currently loading.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonBarComponent
	 */
	@Input() public loading: boolean = true;

	/**
	 * Gets or sets the reserve bottom right value of the base page header.
	 * This is primarily used for the filter parameter button and will reserve
	 * thirty two pixels at the bottom right. At desktop content size or larger,
	 * this will reserve the right bottom half of content for more details.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonBarComponent
	 */
	@Input() public reserveHeaderBottomRight: boolean = false;

	/**
	 * Gets or sets the fixed overlay location which if sent will
	 * override location calculations.
	 *
	 * @type {string}
	 * @memberof OperationButtonBarComponent
	 */
	@Input() public fixedOverlayLocation: string;

	/**
	 * Gets or sets the event that will be emitted to all listening components
	 * when operations are loaded.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof OperationButtonBarComponent
	 */
	@Output() public loadingOperations: EventEmitter<object> =
		new EventEmitter<object>();

	/**
	 * Gets or sets the event that will be emitted to all listening components
	 * the number of loaded operations.
	 *
	 * @type {EventEmitter<number>}
	 * @memberof OperationButtonBarComponent
	 */
	@Output() public loadedOperationsCountChanged: EventEmitter<number> =
		new EventEmitter<number>();

	/**
	 * Gets or sets the element reference for the set of button bars.
	 *
	 * @type {ElementRef}
	 * @memberof OperationButtonBarComponent
	 */
	@ViewChild('OperationContainer', { read: ElementRef })
	public operationContainer: ElementRef;

	/**
	 * Gets or sets the operation button display observer used to debounce
	 * operation display calculations.
	 *
	 * @type {Subject<void>}
	 * @memberof AppComponent
	 */
	public operationDisplayChange: Subject<void> = new Subject<void>();

	/**
	 * Gets or sets the save entity menu item if it exists.
	 *
	 * @type {MenuItem}
	 * @memberof OperationButtonBarComponent
	 */
	public saveEntityMenuItem: MenuItem;

	/**
	 * Gets or sets the singular ellipsis menu item menu item if it exists.
	 * This will always be displayed last in the button bar.
	 *
	 * @type {MenuItem}
	 * @memberof OperationButtonBarComponent
	 */
	public ellipsisMenuItem: MenuItem;

	/**
	 * Gets or sets the exexuting command flag.
	 * This is used to disable the operation button menu while the command
	 * is being executed.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonBarComponent
	 */
	public executingCommand: boolean = false;

	/**
	 * Gets or sets the boolean value that will fire a redraw of an ellipsis
	 * menu so the nested item changes are refreshed.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonBarComponent
	 */
	public redrawEllipsis: boolean = false;

	/**
	 * Gets or sets the the first visible button index which can be used
	 * for left side rounding.
	 *
	 * @type {number}
	 * @memberof OperationButtonBarComponent
	 */
	 public firstVisibleButtonIndex: number = -1;

	/**
	 * Gets or sets the the last visible button index which can be used
	 * for right side rounding.
	 *
	 * @type {number}
	 * @memberof OperationButtonBarComponent
	 */
	public lastVisibleButtonIndex: number = -1;

	/**
	 * Gets or sets a value defining whether or not there is a single ellipsis
	 * button currently displayed.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonBarComponent
	 */
	public standaloneEllipsisDisplayed: boolean = false;

	/**
	 * Gets or sets the ellipsis menu item that will be displayed when we
	 * are collapsing button sets and no ellipsis is already defined.
	 *
	 * @type {MenuItem}
	 * @memberof OperationButtonBarComponent
	 */
	public ellipsisOperationPlaceholder: MenuItem =
		<MenuItem>
		{
			icon: 'fa fa-fw fa-ellipsis',
			id: OperationButtonTypeConstants.ellipsisButton,
			styleClass: AppConstants.cssClasses.pButtonOutlined,
			items: [],
			label: 'Ellipsis Collapse Button'
		};

	/**
	 * Gets the value used by the UI to define at which content
	 * width we should switch to a two column page header layout.
	 *
	 * @type {number}
	 * @memberof OperationButtonBarComponent
	 */
	public readonly twoColumnHeaderBreakpoint: number =
		AppConstants.layoutBreakpoints.desktop;

	/**
	 * Gets the case sensitive constant used to specify if this group
	 * is displayed via the ellipsis button menu.
	 *
	 * @type {string}
	 * @memberof OperationButtonBarComponent
	 */
	private readonly ellipsisOperationIdentifier: string =
		'Ellipsis';

	/**
	 * Gets the case sensitive constant used to specify if this operation
	 * handles the save entity event.
	 *
	 * @type {string}
	 * @memberof OperationButtonBarComponent
	 */
	private readonly saveEntityOperationIdentifier: string =
		'SaveEntity';

	/**
	 * Gets the case sensitive constant used to specify if this operation
	 * emits the save entity event.
	 *
	 * @type {string}
	 * @memberof OperationButtonBarComponent
	 */
	private readonly emitSaveEntityOperationIdentifier: string =
		'EmitSaveEntityEvent';

	/**
	 * Gets the debounce delay before hiding or showing operations.
	 *
	 * @type {number}
	 * @memberof OperationButtonBarComponent
	 */
	private readonly operationChangeDebounceDelay: number = 50;

	/**
	 * Gets the boolean value that will enable or disable the
	 * save entity button if sent in the operation group.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonBarComponent
	 */
	private saveButtonDisabled: boolean = false;

	/**
	 * Handles the site layout change event.
	 * This will calculate if an ellipsis menu should be populated to keep
	 * the button bar as a single row.
	 *
	 * @memberof OperationButtonBarComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.operationDisplayChange.next();
	}

	/**
	 * Handles the enable/disable save entity event. This will
	 * fire via form based entity validation.
	 *
	 * @param {boolean} enabled
	 * If true, this will enable the entity save button otherwise
	 * it will be disabled.
	 * @memberof OperationButtonBarComponent
	 */
	@HostListener(
		EntityEventConstants.enableEntitySaveEvent,
		[EntityEventParameterConstants.enabled])
	public async enableEntitySave(
		enabled: boolean): Promise<void>
	{
		this.saveButtonDisabled = !enabled;

		if (!AnyHelper.isNullOrEmpty(this.saveEntityMenuItem))
		{
			this.saveEntityMenuItem.disabled = this.saveButtonDisabled;
		}
	}

	/**
	 * On component changes event.
	 * This method is used to look for changes in the reserve bottom right
	 * value.
	 *
	 * @param {SimpleChanges} simpleChanges
	 * The altered values that fired this on change event.
	 * @memberof OperationButtonBarComponent
	 */
	public ngOnChanges(
		simpleChanges: SimpleChanges): void
	{
		if (!AnyHelper.isNull(
			simpleChanges.reserveHeaderBottomRight?.currentValue)
			&& this.reserveHeaderBottomRight !==
				simpleChanges.reserveHeaderBottomRight.currentValue)
		{
			this.operationDisplayChange.next();
		}
	}

	/**
	 * Handles the on destroy event.
	 * This will complete the display change subject.
	 *
	 * @memberof OperationButtonBarComponent
	 */
	public ngOnDestroy(): void
	{
		this.operationDisplayChange.complete();
	}

	/**
	 * This will handle the tap mobile only event on the tooltip icon
	 * and toggle the display of the tooltip.
	 *
	 * @param {number} elementIndex
	 * The element index that has captured a tooltip toggle event.
	 * @memberof OperationButtonBarComponent
	 */
	public mobileTooltipToggle(
		elementIndex: number): void
	{
		for (let index: number = 0;
			index < this.operationContainer?.nativeElement?.children?.length;
			index++)
		{
			const tooltipElement: any =
				this.operationContainer.nativeElement.children[index];
			tooltipElement.dispatchEvent(
				new Event(index === elementIndex
					? MouseEventConstants.mouseEnter
					: MouseEventConstants.mouseLeave));
		}
	}

	/**
	 * This method will remove the auto focus click event attached to
	 * primeNg tooltips.
	 *
	 * @param {MouseEvent} event
	 * The click event to be captured and halted.
	 * @param {string} tooltipMessage
	 * If sent, this will be the tooltip message signifying that a tooltip
	 * should be displayed. If this is null, the nested click command
	 * will be ran.
	 * @memberof OperationButtonBarComponent
	 */
	public preventDefault(
		event: MouseEvent,
		tooltipMessage: string): void
	{
		if (AnyHelper.isNull(tooltipMessage))
		{
			return;
		}

		event.preventDefault();
		event.stopImmediatePropagation();
	}

	/**
	 * Handles calculations and display based on site width to ensure we can
	 * always show a single line operation button bar. If insufficient room
	 * exists these operations are pushed into an ellipsis item. If enough
	 * room exists and an ellipsis item was previously pushed into an ellipsis
	 * item, this will also add that item back until the original operation
	 * group is displayed.
	 *
	 * @memberof OperationButtonBarComponent
	 */
	public handleOperationButtonDisplay(): void
	{
		if (this.loading === true
			|| AnyHelper.isNull(this.model)
			|| AnyHelper.isNull(this.operationContainer))
		{
			return;
		}
		const operationContainerWidth: number =
			this.operationContainer.nativeElement.scrollWidth;

		const utilizedFilterWidth: number =
			this.reserveHeaderBottomRight === true
				? AppConstants.staticLayoutSizes.headerBottomRightIconWidth
				: 0;
		const utilizedTabletWidth: number =
			AppConstants.staticLayoutSizes.utilityMenuWidth
				+ AppConstants.staticLayoutSizes.collapsedContextMenuWidth
				+ AppConstants.staticLayoutSizes.nestedContentPadding * 3
				+ utilizedFilterWidth;
		const utilizedDesktopWidth: number =
			utilizedTabletWidth
				+ AppConstants.staticLayoutSizes.slimMenuWidth
				+ AppConstants.staticLayoutSizes.nestedContentPadding * 1;
		const utilizedWidth: number =
			this.siteLayoutService.contentWidth
				- (this.siteLayoutService.displayTabletView === true
					? utilizedTabletWidth
					: utilizedDesktopWidth);

		// If we are showing parameters and chips, split this width.
		const availableBarDisplayWidth: number =
			this.reserveHeaderBottomRight === true
				&& this.siteLayoutService.contentWidth >
					this.twoColumnHeaderBreakpoint
				? (this.siteLayoutService.contentWidth
						- utilizedDesktopWidth) / 2
				: utilizedWidth;

		const tooSmall: boolean =
			operationContainerWidth > availableBarDisplayWidth;
		const hiddenMenuItems: MenuItem[] =
			this.ellipsisMenuItem?.items?.filter(
				(menuItem: MenuItem) =>
					menuItem.id ===
						OperationButtonTypeConstants.button
							|| menuItem.id ===
								OperationButtonTypeConstants.groupButton) || [];

		const hiddenMenuItemWidth: number =
			hiddenMenuItems?.length > 0
				? this.getOperationButtonWidth(hiddenMenuItems[0])
				: this.siteLayoutService.contentWidth;
		const canDisplayAnotherItem: boolean =
			availableBarDisplayWidth >
				operationContainerWidth + hiddenMenuItemWidth;

		if (tooSmall === true || canDisplayAnotherItem === true)
		{
			if (AnyHelper.isNull(this.ellipsisMenuItem))
			{
				this.ellipsisMenuItem = this.ellipsisOperationPlaceholder;
				this.model.push(this.ellipsisMenuItem);
			}

			let changeMade: boolean = false;

			if (tooSmall === true
				&& this.model.length > 1)
			{
				const shuffleItems: MenuItem[] =
					this.model.filter(
						(menuItem: MenuItem) =>
							menuItem.id !==
								OperationButtonTypeConstants.ellipsisButton);
				const shuffleItem: MenuItem =
					shuffleItems[shuffleItems.length - 1];

				this.ellipsisMenuItem.items =
					[
						shuffleItem,
						...this.ellipsisMenuItem.items
					];
				this.model.splice(
					this.model.lastIndexOf(shuffleItem),
					1);

				this.calculateEllipsisDisplay();
				changeMade = true;
			}
			else if (canDisplayAnotherItem === true)
			{
				this.ellipsisMenuItem.items.splice(0, 1);
				this.model.push(hiddenMenuItems[0]);

				if (this.ellipsisMenuItem.items.length === 0)
				{
					this.model =
						this.model.filter(
							(menuItem: MenuItem) =>
								menuItem.id !==
									OperationButtonTypeConstants
										.ellipsisButton);
					this.ellipsisMenuItem = null;
				}

				this.calculateEllipsisDisplay();
				changeMade = true;
			}

			if (changeMade === true)
			{
				this.redrawEllipsis = true;
				setTimeout(() =>
				{
					this.redrawEllipsis = false;
				});
				this.operationDisplayChange.next();
			}
		}

		this.firstVisibleButtonIndex = this.getFirstVisibleButtonIndex();
		this.lastVisibleButtonIndex = this.getLastVisibleButtonIndex();
	}

	/**
	 * Calculates and returns the expected button width of a sent menu item
	 * when displayed in this button bar.
	 *
	 * @param {MenuItem} menuItem
	 * The menu item to perform a display width calculation for.
	 * @returns {number}
	 * The width in pixels of the sent menu item.
	 * @memberof OperationButtonBarComponent
	 */
	public getOperationButtonWidth(
		menuItem: MenuItem): number
	{
		const buttonPaddingAndMargin: number =
			AppConstants.staticLayoutSizes.nestedContentPadding * 2;
		const displayedIconWidth: number = 20;
		const iconWidth: number =
			AnyHelper.isNullOrWhitespace(menuItem.icon)
				&& menuItem.id !== OperationButtonTypeConstants.groupButton
				? 0
				: displayedIconWidth;

		if (AnyHelper.isNullOrWhitespace(menuItem?.label))
		{
			return iconWidth + buttonPaddingAndMargin;
		}

		const textElement: HTMLSpanElement =
			document.createElement('span');
		document.body.appendChild(textElement);

		textElement.style.fontFamily =
			AppConstants.cssValues.fontFamily;
		textElement.style.fontSize =
			`${AppConstants.staticLayoutSizes.fontSize}px`;
		textElement.style.height = 'auto';
		textElement.style.width = 'auto';
		textElement.style.position = 'absolute';
		textElement.style.whiteSpace = 'no-wrap';
		textElement.innerHTML = menuItem.label;

		const width = Math.ceil(textElement.clientWidth)
			+ iconWidth
			+ buttonPaddingAndMargin;
		document.body.removeChild(textElement);

		return width;
	}

	/**
	 * Executes a menu item command from an action
	 * in the button bar.
	 *
	 * @async
	 * @param {Function} command
	 * The command to be executed.
	 * @param {boolean} useCommandPromise
	 * The user interpolated command.
	 * @memberof OperationButtonBarComponent
	 */
	public async executeCommand(
		command: string | Function,
		useCommandPromise: boolean = false): Promise<void>
	{
		this.executingCommand = true;

		const excecutableCommand: Function =
			useCommandPromise === true
				? StringHelper.transformToFunction(
					StringHelper.interpolate(
					<string>command,
					this.pageContext),
					this.pageContext)
				: <Function>command;

		let commandException: string;

		try
		{
			await excecutableCommand(
				this,
				this.pageContext);
		}
		catch (exception)
		{
			commandException = exception;

			// Display handled exception for developer support.
			console.error(
				'Exception thrown in nested command: ',
				exception);
		}
		finally
		{
			this.loadingOperations.emit(
				{
					loadingOperations: false,
					exception: commandException
				});

			this.executingCommand = false;
		}
	}

	/**
	 * Calculates the index that should be a rounded left side button.
	 *
	 * @returns {number}
	 * A value representing the index of the first visible button.
	 * @memberof OperationButtonBarComponent
	 */
	 public getFirstVisibleButtonIndex(): number
	 {
		return this.model?.findIndex(
			(menuItem: MenuItem) =>
				menuItem.visible !== false
					|| menuItem.items?.length > 0);
	 }

	/**
	 * Calculates the index that should be a rounded right side button.
	 *
	 * @returns {number}
	 * A value representing the index of the last visible button.
	 * @memberof OperationButtonBarComponent
	 */
	public getLastVisibleButtonIndex(): number
	{
		if (!AnyHelper.isNull(this.ellipsisMenuItem))
		{
			return AppConstants.negativeIndex;
		}

		const lastVisibleItemIndex: number =
			[...this.model]
				?.reverse()
				.findIndex(
					(menuItem: MenuItem) =>
						menuItem.visible !== false
							|| menuItem.items?.length > 0);

		return (this.model?.length - 1) - lastVisibleItemIndex;
	}

	/**
	 * Performs actions post operation group load.
	 *
	 * @memberof OperationButtonBarComponent
	 */
	public performPostOperationLoadActions(): void
	{
		this.model?.forEach((menuItem: MenuItem) =>
		{
			if (menuItem.items == null
				|| menuItem.items.length === 0)
			{
				if (menuItem.id?.indexOf(
					this.saveEntityOperationIdentifier) === 0
					|| menuItem.id?.indexOf(
						this.emitSaveEntityOperationIdentifier) === 0)
				{
					this.saveEntityMenuItem = menuItem;
					this.saveEntityMenuItem.disabled = this.saveButtonDisabled;
					this.saveEntityMenuItem.styleClass =
						AppConstants.cssClasses.pButtonPrimary;
				}

				menuItem.id =
					OperationButtonTypeConstants.button;
			}
			else
			{
				if (!AnyHelper.isNullOrEmpty(menuItem.id)
					&& menuItem.id.indexOf(
						this.ellipsisOperationIdentifier) !== -1)
				{
					menuItem.id =
						OperationButtonTypeConstants.ellipsisButton;
					menuItem.styleClass =
						AppConstants.cssClasses.pButtonOutlined;
					this.ellipsisMenuItem = menuItem;
				}
				else
				{
					menuItem.items.forEach((item: any) =>
					{
						if (item.items != null
							&& item.items.length > 0)
						{
							// Note: This check can be removed if we want
							// to allow nested operation displays on
							// all operation button bar components.
							throw new Error(
								`The operation group of ${menuItem.label} is `
								+ 'not valid for this action set. '
								+ 'Please use the available Ellipsis display '
								+ 'for multi-level nested actions.');
						}
					});

					menuItem.id =
						OperationButtonTypeConstants.groupButton;
				}
			}

			if (AnyHelper.isNullOrEmpty(menuItem.styleClass))
			{
				menuItem.styleClass =
					AppConstants.cssClasses.pButtonOutlined;
			}
		});

		if (!AnyHelper.isNull(this.model)
			&& this.model.length > 0)
		{
			this.operationDisplayChange.pipe(
				debounceTime(this.operationChangeDebounceDelay))
				.subscribe(() =>
				{
					this.handleOperationButtonDisplay();
				});
			this.operationDisplayChange.next();
		}

		this.calculateEllipsisDisplay();
		this.loadedOperationsCountChanged.emit(this.model?.length || 0);
		this.loading = false;
	}

	/**
	 * Calculates ellipsis based display values.
	 *
	 * @memberof OperationButtonBarComponent
	 */
	private calculateEllipsisDisplay(): void
	{
		this.standaloneEllipsisDisplayed =
			this.model?.length === 1
			&& this.model[0] === this.ellipsisMenuItem;
	}
}