/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	Directive,
	Input,
	OnInit
} from '@angular/core';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonListDirective
} from '@shared/directives/common-list-directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';

@Directive({
	selector: '[CommonChildListDirective]'
})

/**
 * A class representing the common code for a component displaying a list of
 * entity children.
 *
 * @export
 * @class CommonChildListDirective
 * @extends {CommonListDirective<IEntityInstance, IEntityInstanceDto>}
 * @implements {OnInit}
 */
export class CommonChildListDirective
	extends CommonListDirective<IEntityInstance, IEntityInstanceDto>
	implements OnInit
{
	/**
	 * Initializes a new instance of the CommonChildListDirective. This
	 * directive is used to help with loading and displaying
	 * an entity based child list.
	 *
	 * @param {EntityService} entityService
	 * The entity service for this directive.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service for this directive.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service for this directive.
	 * @memberof CommonChildListDirective
	 */
	public constructor(
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService)
	{
		super();
	}

	/**
	 * Gets or sets the wildcard child filter that be used to identify a
	 * singular exact type or a wild card child type such as 'WorkItem.*'.
	 *
	 * @type {string}
	 * @memberof CommonChildListDirective
	 */
	@Input() public wildcardChildFilter: string = AppConstants.empty;

	/**
	 * Gets or sets the security definitions.
	 *
	 * @type {ISecurityEntityTypeDefinition[]}
	 * @memberof CommonChildListDirective
	 */
	@Input() public securityDefinitions: ISecurityEntityTypeDefinition[] = [];

	/**
	 * Gets or sets the supported entity types for this common child list.
	 *
	 * @type {string}
	 * @memberof CommonChildListDirective
	 */
	public supportedEntityTypes: EntityType[];

	/**
	 * Gets or sets the return to use when you wish to hide an item from
	 * display in this list.
	 *
	 * @type {ICommonListItem<any>}
	 * @memberof CommonChildListDirective
	 */
	public readonly hiddenCommonListItem: ICommonListItem<any> =
		<ICommonListItem<any>>
		{
			item: null,
			descriptionLineFormat: AppConstants.empty,
			informationLineFormat: AppConstants.empty,
			informationIcons: [],
			actions: []
		};

	/**
	 * Handles the on initialization event.
	 * This method will find all of the supported entity types of the context
	 * displayed entity and load all of those types into a common list.
	 *
	 * @async
	 * @memberof CommonChildListDirective
	 */
	public async ngOnInit(): Promise<void>
	{
		const childEntityTypes: string[] =
			ApiFilterHelper.getWildcardEntityTypes(
				(<EntityInstanceComponent>this.context.source)
					.entityDefinition
					.supportedChildTypes,
				this.wildcardChildFilter);

		const namesFromList: EntityType[] =
			await this.entityService
				.getEntityTypesFromNameList(childEntityTypes);

		this.supportedEntityTypes = namesFromList
			.filter((entityType: EntityType) =>
				entityType.name.startsWith(this.wildcardChildFilter));

		this.sorter =
			this.sorter || this.sorters[0];

		await this.setSecurityDefinitions();

		this.refreshCommonListContext(
			this.loadItems(
				this.enabledFilters,
				this.sorter),
			this);
	}

	/**
	 * This method will return a primary filter that will be used for all list
	 * displays regardless of the selected filters.
	 *
	 * @returns {string}
	 * A primary filter for all queries in this child list.
	 * @memberof CommonChildListDirective
	 */
	public getPrimaryFilter(): string
	{
		return ApiFilterHelper.getEntityTypeFilter(this.supportedEntityTypes);
	}

	/**
	 * Loads all of the associated items to be displayed in this child common
	 * list based on the sent filters and sorters.
	 *
	 * @async
	 * @param {ICommonListFilter[]} filters
	 * The additional filters array above and beyond the primary filter used
	 * to further limit the items result set.
	 * @param {ICommonListSort} sorter
	 * The sort order to load these items in.
	 * @returns {Promise<IEntityInstance[]>}
	 * An awaitable promise that will contain all of the entity instances
	 * matching the wildcard filter with additional filtering and sorts
	 * based on user interface selections.
	 * @memberof CommonChildListDirective
	 */
	public async loadItems(
		filters?: ICommonListFilter[],
		sorter?: ICommonListSort): Promise<IEntityInstance[]>
	{
		let filter: string =
			this.getPrimaryFilter();

		if (this.supportedEntityTypes.length === 0)
		{
			return Promise.resolve(<IEntityInstance[]>[]);
		}

		if (AnyHelper.isNull(filters) === false)
		{
			filters.forEach(
				(item: ICommonListFilter) =>
				{
					filter = AnyHelper.isNullOrWhitespace(filter)
						? item.value
						: `(${filter}) and (${item.value})`;
				});
		}

		let orderBy: string;
		if (AnyHelper.isNull(sorter) === false)
		{
			orderBy = `${sorter.value} ${sorter.direction}`;
		}

		if (this.context.source instanceof EntityInstanceComponent)
		{
			return this.entityService.getFullHierarchyDataSet(
				this.context.source.id,
				this.context.source.entityTypeGroup,
				filter,
				orderBy);
		}

		return null;
	}

	/**
	 * Gets a collection of strings representing note types filtered from the
	 * list of input types.
	 *
	 * @protected
	 * @returns {Promise<void>}
	 * A collection of suppported and authorized entity types.
	 * @memberof CommonChildListDirective
	 */
	protected async setSecurityDefinitions():
		Promise<void>
	{
		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;

		this.securityDefinitions =
			await SecurityHelper
				.getSupportedChildPermissions(
					entityInstanceComponent.id,
					entityInstanceComponent.entityType.group,
					this.wildcardChildFilter,
					entityInstanceComponent.entityDefinition,
					entityInstanceComponent.entityInstanceApiService,
					entityInstanceComponent.entityTypeApiService);
	}
}