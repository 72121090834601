<p-scrollPanel styleClass="rule-container">
	<div class="ui-g-12 rule-content-container text-left">

		<div class="ui-g-12 no-padding nested-display-subtitle text-left">
			<div class="ellipsis-text">
				View: Rule Override Details
			</div>
		</div>

		<div class="ui-g-12 no-horizontal-padding">
			<app-dynamic-formly
				[dataSet]="selectedItem"
				[layoutSchema]="overrideFormlyLayout">
			</app-dynamic-formly>
		</div>

		<div class="ui-g-12 no-padding text-right button-set">
			<a class="text-link theme-color secondary-action-button"
				(click)="cancel()">
				Cancel
			</a>
		</div>

	</div>
</p-scrollPanel>