/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	passwordValidator,
	passwordValidatorMessage
} from '@entity/entity-formly-definitions';
import {
	IValidator
} from '@shared/interfaces/application-objects/validator.interface';
import {
	BaseRuleValidator
} from '@shared/validators/base-rule-validator.validator';

/**
 * A class representing a password validator.
 *
 * @export
 * @class PasswordValidator
 * @extends {BaseRuleValidator}
 */
export class PasswordValidator
	extends BaseRuleValidator
{
	/**
	 * Creates and returns a unique instance of a password validator.
	 *
	 * @returns {IValidator}
	 * A validator ready for use in a Formly layout.
	 * @memberof PasswordValidator
	 */
	public create(): IValidator
	{
		return <IValidator>
			{
				expression: passwordValidator,
				message: passwordValidatorMessage
			};
	}
}