/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityLayout
} from '@shared/interfaces/entities/entity-layout.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-entity-layouts',
	templateUrl: './entity-layouts.component.html',
	styleUrls: [
		'./entity-layouts.component.scss'
	]
})

/**
 * A component representing an instance of the system entity layouts
 * component.
 *
 * @export
 * @class EntityLayoutsComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class EntityLayoutsComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Creates an instance of an EntityLayoutsComponent.
	 *
	 * @param {EntityLayoutApiService} entityLayoutApiService
	 * The api service used to get the entity layout data.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The api service used to get the entity layout type data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity instance service used to populate the entity definition data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The entity instance service used to populate the entity version data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router service.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityLayoutsComponent
	 */
	public constructor(
		public entityLayoutApiService: EntityLayoutApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public route: ActivatedRoute,
		public router: Router,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntityLayoutsComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * Gets or sets the entity layout types.
	 *
	 * @type {IEntityLayoutType[]}
	 * @memberof EntityLayoutsComponent
	 */
	public entityLayoutTypes: IEntityLayoutType[];

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {IEntityType}
	 * @memberof EntityLayoutsComponent
	 */
	public entityType: IEntityType;

	/**
	 * Gets or sets the entity version.
	 *
	 * @type {IEntityVersion}
	 * @memberof EntityLayoutsComponent
	 */
	public entityVersion: IEntityVersion;

	/**
	 * Gets or sets the table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityLayoutsComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the layout type name
	 * dropdown options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof EntityLayoutsComponent
	 */
	public layoutTypeNameOptions: IDropdownOption[];

	/**
	 * Gets or sets the entity definition id.
	 *
	 * @type {number}
	 * @memberof EntityLayoutsComponent
	 */
	public entityDefinitionId: number;

	/**
	 * Initializes the component to set the page variables
	 * and setup the table definitions.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		this.setupTableDefinitions();
	}

	/**
	 * Sets the page variables needed for this component.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.entityVersion =
			await this.entityVersionApiService
				.get(this.entityDefinition.versionId);

		this.tableFilterQuery =
			`${AppConstants.commonProperties.typeId}`
				+ ` eq ${this.entityDefinition.typeId}`
				+ ` and ${AppConstants.commonProperties.versionId}`
				+ ` eq ${this.entityDefinition.versionId}`;

		this.entityLayoutTypes =
			await this.entityLayoutTypeApiService
				.query(
					AppConstants.empty,
					AppConstants.empty);

		this.availableColumns =
			[
				{
					dataKey: 'layoutTypeName',
					columnHeader: 'Layout Type',
					displayOrder: 1
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets the common table definitions needed for this component.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.tableDefinitions =
			{
				tableTitle: 'Entity Layouts',
				hideTableTitle: true,
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Entity Layout'),
				objectSearch: {
					filter: this.tableFilterQuery,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const dataPromise: object[] = [];

						const entityLayout: IEntityLayout[] =
							await this.entityLayoutApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						for (const layout of entityLayout)
						{
							dataPromise.push(
								this.decorateViewModel(layout));
						}

						return dataPromise.sort((
							layoutTypeOne: IDropdownOption,
							layoutTypeTwo: IDropdownOption) =>
							ObjectHelper.sortByPropertyValue(
								layoutTypeOne,
								layoutTypeTwo,
								'layoutTypeName'));
					},
				decorateViewModel:
					(model: any) =>
						this.decorateViewModel(model),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					create: {
						layout: [
							{
								key: 'layoutTypeId',
								type:
									FormlyConstants.customControls.customSelect,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Layout Type',
									disabled: false,
									required: true,
									showClear: true,
									placeholder: AppConstants.placeholders
										.selectAnOption,
									appendTo:
										FormlyConstants.appendToTargets.body,
									options: this.entityLayoutTypes
										?.map((type:
											IEntityType) =>
												<IDropdownOption>
												{
													label: type.name,
													value: type.id
												})
										.sort((
											optionOne: IDropdownOption,
											optionTwo: IDropdownOption) =>
											ObjectHelper.sortByPropertyValue(
												optionOne,
												optionTwo,
												'label')),
								},
								asyncValidators: {
									uniqueLayoutType: {
										expression: (
											control: UntypedFormControl) =>
											this.uniqueLayoutType(control),
										message: 'Existing Layout Type Name.'
									}
								}
							}
						],
						items: [
							{
								label: 'Create',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.createLayout()
							}]
					},
					update: {
						disabledExpandItem: true,
						items: [
							{
								command: () =>
								{
									const layoutId: string =
										this.commonTableContext.source
											.selectedItem.id;

									this.router.navigate(
										[
											'admin/entity/'
												+ 'layoutDefinition/edit',
											this.entityDefinitionId
										],
										{
											queryParams:
												{
													routeData:
														ObjectHelper
															.mapRouteData(
																{
																	layoutId:
																		layoutId
																})
												}
										});
								}
							}
						]
					},
					delete: {
						items: [
							{
								label: 'Confirm',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: async() => this.deleteLayout(),
							}],
						deleteStatement: () => this.deleteStatement(),
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Decorates the view model for the object.
	 *
	 * @param {IEntityLayout} model
	 * The object to be decorated as a view model.
	 * @returns {any}
	 * The mapped view model for display in this component.
	 * @memberof EntityManagerSearchComponent
	 */
	public decorateViewModel(
		model: IEntityLayout): any
	{
		const layoutType: IEntityLayoutType =
			this.entityLayoutTypes.find(
				(type: IEntityLayoutType) =>
					type.id === model.layoutTypeId);

		const viewModel: any =
			{
				id: model.id,
				layoutTypeName:
					layoutType.name,
				layoutTypeId:
					layoutType.id
			};

		return viewModel;
	}

	/**
	 * creates a new entity layout.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	private async createLayout(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const createLayoutAction:
			Function = async() =>
			{
				const newEntityLayout: IEntityLayout =
					<IEntityLayout>
					{
						typeId: this.entityType.id,
						layoutTypeId: selectedItem.layoutTypeId,
						versionId: this.entityVersion.id,
						jsonData: AppConstants.emptyArray
					};

				const newEntityLayoutId: number =
					await this.entityLayoutApiService
						.create(newEntityLayout);

				this.router.navigate(
					[
						'admin/entity/layoutDefinition/edit',
						this.entityDefinitionId
					],
					{
						queryParams:
							{
								routeData:
									ObjectHelper.mapRouteData(
										{
											layoutId:
												newEntityLayoutId
										})
							}
					});
			};

		await this.activityService.handleActivity(
			new Activity(
				createLayoutAction(),
				'<strong>Creating Layout</strong>',
				'<strong>Created Layout</strong>',
				`Layout Type Id ${selectedItem.layoutTypeId}`
					+ ' was added.',
				`Layout Type Id ${selectedItem.layoutTypeId}`
					+ ' was not added.'));
	}

	/**
	 * Gets the common table delete statement.
	 *
	 * @async
	 * @returns {string}
	 * The delete statement
	 * @memberof EntityLayoutsComponent
	 */
	private deleteStatement(): string
	{
		return 'Confirm to delete Layout Type'
			+ ` ${this.commonTableContext.source.selectedItem.layoutTypeName}`
			+ ` for ${this.entityType.name}`;
	}

	/**
	 * Deletes an existing entity layout.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	private async deleteLayout(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const deleteFunction: Function =
			async() =>
			{
				await this.entityLayoutApiService
					.delete(selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteFunction(),
				'<strong>Deleting Layout</strong>',
				'<strong>Deleted Layout</strong>',
				`Layout Type ${selectedItem.layoutTypeName}`
					+ ' was deleted.',
				`Layout Type ${selectedItem.layoutTypeName}`
					+ ' was not deleted.'));
	}

	/**
	 * Validates the layout type is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Form Control used to get the input value
	 * @returns {Promise<boolean>}
	 * The Validator promise result.
	 * @memberof EntityLayoutsComponent
	 */
	private async uniqueLayoutType(
		control: UntypedFormControl):
		Promise<boolean>
	{
		const existingLayout: IEntityLayout[] =
			await this.entityLayoutApiService
				.query(
					`${AppConstants.commonProperties.typeId}`
						+ ` eq ${this.entityDefinition.typeId}`
						+ ` and ${AppConstants.commonProperties.versionId}`
						+ ` eq ${this.entityDefinition.versionId}`
						+ ` and ${AppConstants.commonProperties.layoutTypeId}`
						+ ` eq ${control.value}`,
					AppConstants.empty);

		return Promise.resolve(existingLayout.length === 0);
	}
}