/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Directive,
	Input
} from '@angular/core';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	BasePageDirective
} from '@shared/directives/base-page.directive';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IFormlyDisplay
} from '@shared/interfaces/application-objects/formly-display.interface';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	isEqual
} from 'lodash-es';

/* eslint-enable max-len */

@Directive({
	selector: '[AppDisplayComponentParameter]'
})

/**
 * A directive representing shared logic for a component interacting
 * with a display component parameter object.
 *
 * @export
 * @class EntitySearchDirective
 * @extends {BasePageDirective}
 */
export class DisplayComponentParameterDirective
	extends BasePageDirective
{
	/**
	 * Creates an instance of a display component parameter directive.
	 * This directive is used when displaying and interacting with display
	 * component parameters.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this directive.
	 * @param {DisplayComponentService} displayComponentService
	 * The service used to load and gather display component data.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The factory used to generate display component interfaces.
	 * @memberof DisplayComponentParameterDirective
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public displayComponentService: DisplayComponentService,
		public displayComponentFactory: DisplayComponentFactory)
	{
		super(
			siteLayoutService,
			displayComponentService,
			displayComponentFactory);
	}

	/**
	 * Gets or sets the page context used to interact with nested display
	 * components.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof DisplayComponentParameterDirective
	 */
	@Input() public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the parent parameter layout data for child components.
	 *
	 * @type {any}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public parentParameterLayoutData: any;

	/**
	 * Gets or sets the active settings display value, used to define
	 * if a dashboard widget item is currently displaying a display settings
	 * component.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterDirective
	 */
	public settingsActive: boolean = false;

	/**
	 * Handles the click of apply parameters sent from the contained
	 * display component parameter object.
	 * This is a stubbed method signature and implementing classes should
	 * handle this logic individually.
	 *
	 * @async
	 * @memberof DisplayComponentParameterDirective
	 */
	public async applyParameters(): Promise<void>
	{
		throw new Error(
			'This must be implemented in the component instance');
	}

	/**
	 * Handles a click for the display and hide action of
	 * parameter components.
	 *
	 * @memberof DisplayComponentParameterDirective
	 */
	public settingsClicked(): void
	{
		this.settingsActive = !this.settingsActive;
	}

	/**
	 * Handles a cancel click from a parameter component.
	 *
	 * @memberof DisplayComponentParameterDirective
	 */
	public cancelClicked(): void
	{
		setTimeout(
			() =>
			{
				this.settingsActive = false;
			});
	}

	/**
	 * Handles child parameter data by ensuring that matching value in
	 * child parameter based display components also receive the updated
	 * value in the parent.
	 *
	 * @param {IFormlyDisplay[]} displayArray
	 * The display array of child components used to interact with data
	 * objects in children.
	 * @param {FormlyFieldConfig[]} parameterSchema
	 * The layout schema currently displayed at this level in the display
	 * component hierarchy.
	 * @param {any} parameterLayoutData
	 * The layout data currently displayed at this level in the display
	 * component hierarchy. These values will be populated into child display
	 * components.
	 * @returns {boolean}
	 * If true, this signifies that a child parameter object has had it's
	 * data altered and should be updated.
	 * @memberof DisplayComponentParameterDirective
	 */
	public handleChildParameterData(
		displayArray: IFormlyDisplay[],
		parameterSchema: FormlyFieldConfig[],
		parameterLayoutData: any): boolean
	{
		let parentChanged: boolean = false;
		displayArray.forEach(
			(displayChild: IFormlyDisplay, _index: number) =>
			{
				if (AnyHelper.isNull(displayChild.parameterLayoutSchema))
				{
					return;
				}

				parameterSchema
					.forEach((formlyFieldConfig: FormlyFieldConfig) =>
					{
						let objectChanged: boolean = false;
						const dataClone: any =
							{...displayChild.parameterLayoutData};

						displayChild.parameterLayoutSchema
							.forEach(
								(nestedFormlyFieldConfig: FormlyFieldConfig) =>
								{
									const cleanedDataKey: string =
										StringHelper.clearNestedDataIdentifier(
											formlyFieldConfig.key
												.toString());

									if (formlyFieldConfig.key ===
										nestedFormlyFieldConfig.key
										&& !isEqual(
											dataClone.data[
												cleanedDataKey],
											parameterLayoutData.data[
												cleanedDataKey]))
									{
										objectChanged = true;

										dataClone.data[
											cleanedDataKey] =
											parameterLayoutData.data[
												cleanedDataKey];
									}
								});

						if (objectChanged)
						{
							parentChanged = true;
							displayChild.parameterLayoutData =
								{...dataClone};
							displayChild.dataChanged = true;
						}
					});
			});

		return parentChanged;
	}
}