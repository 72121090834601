<div *ngIf="loadingTableDefinitions"
	class="text-center ui-g-12">
	<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
	</span>
</div>

<div *ngIf="!loadingTableDefinitions"
	class="ui-g-12 no-padding table-container">
	<app-common-table
		[tableDefinitions]="commonTable">
	</app-common-table>
</div>