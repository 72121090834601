/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Location
} from '@angular/common';
import {
	AfterContentChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	ActivatedRoute,
	Params,
	Router,
	UrlCreationOptions
} from '@angular/router';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	BooleanFadeAnimation
} from '@shared/app-animations';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardStepMenuItem
} from '@shared/interfaces/application-objects/wizard-step-menu-item.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IWizardStep
} from '@shared/interfaces/dynamic-interfaces/wizard-step-context.interface';
import {
	IReportDataDetails
} from '@shared/interfaces/reports/power-bi/power-bi-report.details.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	isEqual
} from 'lodash-es';
import {
	Observable,
	Subscription
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'app-dynamic-wizard',
	templateUrl: './dynamic-wizard.component.html',
	styleUrls: [
		'./dynamic-wizard.component.scss'
	],
	animations: [
		BooleanFadeAnimation
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})

/**
 * A component representing a context level dynamic step wizard.
 *
 * @export
 * @class DynamicWizardComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {AfterContentChecked}
 * @implements {IDynamicComponent<SessionComponent, IWizardContext>}
 * @implements {AppCanDeactivateGuard}
 */
export class DynamicWizardComponent
implements OnInit, AfterViewInit, AfterContentChecked,
		IDynamicComponent<Component, IWizardContext>,
		AppCanDeactivateGuard
{
	/**
	 * Creates in instance of a dynamic wizard component.
	 *
	 * @param {ActivatedRoute} route
	 * The route used in this component.
	 * @param {Router} router
	 * The router used in this component.
	 * @param {Location} location
	 * The Angular common location service used for url interaction.
	 * @param {ResolverService} resolver
	 * The resolver service used for generic lookups.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The factory used to generate display component interfaces.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The change detector reference.
	 * @memberof DynamicWizardComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public router: Router,
		public location: Location,
		public resolver: ResolverService,
		public displayComponentFactory: DisplayComponentFactory,
		public siteLayoutService: SiteLayoutService,
		public sessionService: SessionService,
		public changeDetectorReference: ChangeDetectorRef)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IWizardContext>}
	 * @memberof DynamicWizardComponent
	 */
	@Input() public context:
		IDynamicComponentContext<Component, IWizardContext>;

	/**
	 * Gets or sets the element reference for the wizard viewport.
	 *
	 * @type {ElementRef}
	 * @memberof DynamicWizardComponent
	 */
	@ViewChild('WizardViewport', { read: ElementRef })
	public wizardViewport: ElementRef;

	/**
	 * Gets or sets the page source that contains this dynamic component.
	 *
	 * @type {Component}
	 * @memberof DynamicWizardComponent
	 */
	public pageSource: Component;

	/**
	 * Gets or sets the active step index for the step display.
	 *
	 * @type {number}
	 * @memberof DynamicWizardComponent
	 */
	public activeStepIndex: number = 0;

	/**
	 * Gets or sets the valid value of the contained dynamic form.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public isValid: boolean = false;

	/**
	 * Gets or sets the changed value of the form data. This is
	 * used to define if the next button is available due to new data.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public valueChanged: boolean = false;

	/**
	 * Gets or sets the redraw value of the nested dynamic form. This will
	 * cause a redraw and run validation on the form.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public redraw: boolean = false;

	/**
	 * Gets or sets a truthy defining if the wizard has most recently stepped
	 * forward.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public forwardStepDirection: boolean = true;

	/**
	 * Gets or sets the initial data value of this wizard. This is used for
	 * can deactivate guard logic.
	 *
	 * @type {{ data: object } }
	 * @memberof DynamicWizardComponent
	 */
	public initialData: { data: object } = { data: {} };

	/**
	 * Gets or sets the display value for the left mask.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public displayLeftMask: boolean = false;

	/**
	 * Gets or sets the display value for the right mask.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public displayRightMask: boolean = false;

	/**
	 * Gets or sets the subscriptions used in this component.
	 *
	 * @type {Subscription}
	 * @memberof DynamicWizardComponent
	 */
	public subscriptions: Subscription = new Subscription();

	/**
	 * Gets or sets the mapped route data.
	 *
	 * @type {any}
	 * @memberof DynamicWizardComponent
	 */
	public mappedRouteData: any;

	/**
	 * Gets or sets the allowed to continue.
	 * If false will display the not allowed message,
	 * and not allow to continue processing the active wizard.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public allowedToContinue: boolean;

	/**
	 * Gets or sets the report Data info.
	 *
	 * @type {IReportDataDetails}
	 * @memberof DynamicWizardComponent
	 */
	public reportData: IReportDataDetails;

	/**
	 * Gets or sets the pageContext info.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof DynamicWizardComponent
	 */
	public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the base page display component instance.
	 *
	 * @type {DisplayComponentInstance}
	 * @memberof GenericBasePageComponent
	 */
	public basePageInstance: DisplayComponentInstance;

	/**
	 * Gets or sets the display component instance.
	 *
	 * @type {DisplayComponentInstance}
	 * @memberof GenericBasePageComponent
	 */
	public displayComponentInstance: DisplayComponentInstance;

	/**
	 * Gets the active menu item.
	 *
	 * @type {IWizardStepMenuItem}
	 * @memberof DynamicWizardComponent
	 */
	public get activeMenuItem(): IWizardStepMenuItem
	{
		return this.context.data.displayedWizardMenuItems[this.activeStepIndex];
	}

	/**
	 * Gets the active wizard display component.
	 *
	 * @type {IWizardStepMenuItem}
	 * @memberof DynamicWizardComponent
	 */
	public get activeWizardStep(): IWizardStep
	{
		return this.context.data.displayedWizardSteps[this.activeStepIndex];
	}

	/**
	 * Gets or the draggable value on whether or nor additional
	 * content exists and is not displayed.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public get draggable(): boolean
	{
		return this.displayLeftMask === true
			|| this.displayRightMask === true;
	}

	/**
	 * Gets the step loading value of this wizard.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public get wizardStepLoading()
	{
		return this._wizardStepLoading;
	}

	/**
	 * Sets the step loading value of this wizard.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	public set wizardStepLoading(
		value: boolean)
	{
		this._wizardStepLoading = value;

		this.changeDetectorReference.detectChanges();
	}

	/**
	 * Gets or sets the private step loading value of this wizard.
	 *
	 * @type {boolean}
	 * @memberof DynamicWizardComponent
	 */
	private _wizardStepLoading: boolean = true;

	/**
	 * Gets the debounce delay for allowing a loaded
	 * view and calculating the masks.
	 *
	 * @type {number}
	 * @memberof DynamicWizardComponent
	 */
	private readonly afterViewInitDebounceDelay: number = 250;

	/**
	 * Gets the debounce delay for validating proper arrow displays
	 * following layout changes.
	 *
	 * @type {number}
	 * @memberof DynamicWizardComponent
	 */
	private readonly layoutChangedDebounceDelay: number = 25;

	/**
	 * Gets the multiplier for the drag scroll event.
	 *
	 * @type {number}
	 * @memberof DynamicWizardComponent
	 */
	private readonly scrollMultiplier: number = 2.5;

	/**
	 * Gets the identifier used to style the wizard component step.
	 *
	 * @type {string}
	 * @memberof DynamicWizardComponent
	 */
	private readonly stepIdentifier: string = '.p-menuitem-link';

	/**
	 * Gets the identifier used to store the current step into the page data.
	 *
	 * @type {string}
	 * @memberof DynamicWizardComponent
	 */
	private readonly currentStepLabelIdentifier: string = 'currentStepLabel';

	/**
	 * Handles the before unload event sent from the current window based
	 * on any action that will change the page.
	 *
	 * @returns {Observable<boolean> | boolean}
	 * The value that will allow the router to know if the data in this form
	 * is altered or not saved to the database. This implements the
	 * AppCanDeactivateGuard interface.
	 * @memberof DynamicWizardComponent
	 */
	@HostListener(
		WindowEventConstants.beforeUnloadEvent)
	public canDeactivate(): Observable<boolean> | boolean
	{
		// Ensure auto step changes do not count as a blocking change.
		const cleanedInitialData: { data: object } =
			{ data: {...this.initialData.data} };
		delete cleanedInitialData.data[this.currentStepLabelIdentifier];

		// Ensure non-selections on a new step do not halt deactivation.
		const cleanedData: { data: object } = { data: {} };
		Object.keys(this.activeMenuItem.currentData.data)
			.forEach(
				(key: string) =>
				{
					const currentValue: any =
						this.activeMenuItem.currentData.data[key];
					if (!AnyHelper.isNull(currentValue)
						&& key !== this.currentStepLabelIdentifier)
					{
						cleanedData.data[key] = currentValue;
					}
				});

		return isEqual(
			cleanedInitialData.data,
			cleanedData.data);
	}

	/**
	 * Handles the site layout change event.
	 * This will set the scroll masks on layout changes and hide the
	 * overlay.
	 *
	 * @memberof DynamicWizardComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		if (!AnyHelper.isNullOrEmpty(this.wizardViewport)
			&& !AnyHelper.isNull(this.wizardViewport.nativeElement))
		{
			this.scrollWizardViewportTo(
				this.wizardViewport.nativeElement.scrollLeft);

			setTimeout(
				() =>
				{
					this.setScrollMasks(
						this.wizardViewport.nativeElement.scrollLeft);
				},
				this.layoutChangedDebounceDelay);
		}
	}

	/**
	 * Handles the on initialization event.
	 * This will re-align the sent context to have a source of this wizard
	 * component and continue offering the calling source as the page source
	 * property.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public ngOnInit(): void
	{
		this.pageSource = this.context.source;
		this.context.source = <Component>this;

		if (!AnyHelper.isNull(this.route.queryParams))
		{
			this.subscriptions.add(
				this.route.queryParams.subscribe((parameters: Params) =>
				{
					this.mappedRouteData =
							ObjectHelper.mapFromRouteData(
								parameters);
				}));
		}

		if (!AnyHelper.isNull(this.context.data.label)
			&& !AnyHelper.isNull(this.activeMenuItem)
			&& !AnyHelper.isNull(this.activeMenuItem.currentData))
		{
			this.context.data.label =
				StringHelper.interpolate(
					this.context.data.label,
					this.activeMenuItem.currentData.data);
		}

		this.displayComponentFactory
			.initializeWizardStepFunctions(
				this.context.data.wizardSteps,
				this.context);
		this.calculateWizardStepDisplayValues();

		if (this.context.data.wizardSteps.length === 0
			|| this.context.data.wizardMenuItems.length === 0
			|| this.context.data.wizardSteps.every(
				(wizardStep: IWizardStep) =>
					wizardStep.stepDisplay() === false))
		{
			const pageSource: GenericBasePageComponent =
				<GenericBasePageComponent>this.pageSource;

			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					pageSource.displayComponentName,
					pageSource.displayComponentInstance.name,
					...pageSource
						.displayComponentInstance
						.displayComponentDefinition
						.jsonDefinition
						.wizardSteps.map(
							(wizardStep: any) =>
								wizardStep.displayComponent)
				]);

			return;
		}

		this.updateGuardComparisonData();

		if (this.activeWizardStep.alwaysVerify === true)
		{
			this.handleStepLayoutRefresh();
		}
	}

	/**
	 * Handles the after view init event.
	 * This will set the scroll masks on initial load.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public ngAfterViewInit(): void
	{
		setTimeout(
			() =>
			{
				this.setScrollMasks(
					this.wizardViewport.nativeElement.scrollLeft);
			},
			this.afterViewInitDebounceDelay);
	}

	/**
	 * Handles the after content checked event.
	 * This will allow the local change detector to handle changes without
	 * firing an expression changes after checked error.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public ngAfterContentChecked(): void
	{
		this.changeDetectorReference.detectChanges();
	}

	/**
	 * Udpates the wizard initial data which is used for comparisons when
	 * refreshing the page. This value should be called when the initial
	 * data of the page is completely set.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public updateGuardComparisonData(): void
	{
		this.initialData.data =
			<{ data: any }>
			{
				...this.activeMenuItem.currentData.data
			};
	}

	/**
	 * Sets the current active step data to include the sent data definitions.
	 *
	 * @param {object} data
	 * The object data to append to the current step's data.
	 * @memberof DynamicWizardComponent
	 */
	public addOrUpdateStepData(
		data: object): void
	{
		this.activeMenuItem.currentData.data =
			{
				...this.activeMenuItem.currentData.data,
				...data
			};
	}

	/**
	 * Adds the current wizard data into the url query parameters when
	 * steps are altered.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public storeData(
		currentData: { data: any }): void
	{
		this.location
			.replaceState(
				this.router
					.createUrlTree(
						[],
						<UrlCreationOptions>
						{
							relativeTo: this.route,
							replaceUrl: true,
							queryParams: {
								routeData:
									ObjectHelper.mapRouteData(
										currentData)
							}
						})
					.toString());
	}

	/**
	 * Updates the displayed wizard label.
	 *
	 * @param {string} updatedLabel
	 * The desired wizard label, or the value to append to the existing wizard
	 * label based on the append to current parameter.
	 * @param {boolean} appendToCurrent
	 * If sent this will add the sent updated label to the existing wizard
	 * label. This value defaults to false.
	 * @memberof DynamicWizardComponent
	 */
	public updateWizardLabel(
		updatedLabel: string,
		appendToCurrent: boolean = false): void
	{
		this.context.data.label =
			appendToCurrent === true
				? `${this.context.data.label
					.replace(
						updatedLabel,
						AppConstants.empty)} `
					+ updatedLabel
				: updatedLabel;
	}

	/**
	 * Adds a component method to the current validation set for the active
	 * step.
	 *
	 * @param {Function} functionToAdd
	 * The truthy valued function to add when running validation on the step.
	 * @memberof DynamicWizardComponent
	 */
	public addToValidator(
		functionToAdd: Function): void
	{
		const translatedValidationAction: Function =
			AnyHelper.isNullOrWhitespace(
				this.activeWizardStep.validatorRaw)
				? () => true
				: StringHelper.transformToFunction(
					this.activeWizardStep.validatorRaw,
					this.context);
		this.activeWizardStep.validator =
			() =>
				functionToAdd() === true
					&& translatedValidationAction() === true;
	}

	/**
	 * Adds a component method to the next function for the active step.
	 *
	 * @param {Function} functionToAdd
	 * The component function to also perform when hitting next.
	 * @memberof DynamicWizardComponent
	 */
	public addToNext(
		functionToAdd: Function): void
	{
		const translatedNextAction: Function =
			AnyHelper.isNullOrWhitespace(
				this.activeWizardStep.nextActionRaw)
				? () => {
					// No implementation.
				}
				: StringHelper.transformToFunction(
					this.activeWizardStep.nextActionRaw,
					this.context);
		this.activeWizardStep.nextAction =
			async() =>
			{
				await functionToAdd();
				translatedNextAction();
			};
	}

	/**
	 * Handles the back action sent from the wizard step display.
	 *
	 * @async
	 * @memberof DynamicWizardComponent
	 */
	public async back(): Promise<void>
	{
		setTimeout(() =>
		{
			this.wizardStepLoading = true;
		});

		await this.activeWizardStep.backAction();
	}

	/**
	 * Handles the next action sent from the wizard step display.
	 *
	 * @async
	 * @memberof DynamicWizardComponent
	 */
	public async next(): Promise<void>
	{
		setTimeout(() =>
		{
			this.wizardStepLoading = true;
		});

		await this.activeWizardStep.nextAction();
	}

	/**
	 * Handles the step to command used to navigate to a step by it's display
	 * name in the wizard menu.
	 *
	 * @async
	 * @param {string} stepName
	 * The step name to navigate to.
	 * @memberof DynamicWizardComponent
	 */
	public stepTo(
		stepName: string): void
	{
		const targetIndex: number =
			this.context.data.wizardMenuItems.findIndex(
				(matchingWizardStepMenuItem: IWizardStepMenuItem) =>
					matchingWizardStepMenuItem.label === stepName);

		const targetWizardMenuItem: IWizardStepMenuItem =
			this.context.data.wizardMenuItems[targetIndex];
		if (targetIndex < this.activeStepIndex)
		{
			// Reset this to last contact data.
			this.activeMenuItem.currentData.data =
				{
					...this.activeMenuItem.lastContactData.data
				};
			// Initialize the target component.
			targetWizardMenuItem.currentData.data =
				{
					...targetWizardMenuItem.lastContactData.data
				};
			// Reset to a valid next action to match reset data.
			this.forwardStepDirection = false;
			this.isValid = true;
		}
		else
		{
			// Store this as last contact data.
			this.activeMenuItem.lastContactData.data =
				{
					...this.activeMenuItem.currentData.data
				};
			// Initialize the target component.
			targetWizardMenuItem.lastContactData.data =
				{
					...this.activeMenuItem.currentData.data
				};
			targetWizardMenuItem.currentData.data =
				{
					...targetWizardMenuItem.lastContactData.data
				};
			// Require the next action to set it's validity.
			this.forwardStepDirection = true;
			this.isValid = false;
		}

		this.displayComponentFactory
			.initializeWizardStepFunctions(
				this.context.data.wizardSteps,
				this.context);
		this.calculateWizardStepDisplayValues();
		this.activeStepIndex =
			this.context.data.displayedWizardMenuItems.findIndex(
				(matchingWizardStepMenuItem: IWizardStepMenuItem) =>
					matchingWizardStepMenuItem.label === stepName);
		this.addOrUpdateStepData(
			<object>
			{
				currentStepLabel: stepName
			});
		this.storeData(
			targetWizardMenuItem.currentData);
		this.scrollToActiveStep();

		this.handleStepLayoutRefresh();
	}

	/**
	 * Handles the scroll left button click event.
	 * This will translate the x position of the div 100%
	 * to the right.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public scrollLeft(): void
	{
		const horizontalScroll: number =
			this.wizardViewport.nativeElement.scrollLeft
				- this.getHorizontalScrollWidth();

		this.scrollWizardViewportTo(horizontalScroll);
	}

	/**
	 * Handles the scroll right button click event.
	 * This will translate the x position of the div 100%
	 * to the left.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public scrollRight(): void
	{
		const horizontalScroll: number =
			this.wizardViewport.nativeElement.scrollLeft
				+ this.getHorizontalScrollWidth();

		this.scrollWizardViewportTo(horizontalScroll);
	}

	/**
	 * Handles the press event sent from a hammerjs touch event.
	 * This will set the active class on the wizard viewport.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public press(): void
	{
		if (this.draggable === true)
		{
			this.wizardViewport.nativeElement
				.classList.add(AppConstants.cssClasses.active);
		}
	}

	/**
	 * Handles the press up event sent from a hammerjs touch event.
	 * This will remove the active class on the wizard viewport.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public pressUp(): void
	{
		if (this.draggable === true)
		{
			this.wizardViewport.nativeElement
				.classList.remove(AppConstants.cssClasses.active);
		}
	}

	/**
	 * Handles the swipe event sent from a hammerjs touch event.
	 * This will calculate the direction and velocity of this
	 * swipe action and scroll to that location.
	 *
	 * @param {any} event
	 * The sent swipe event.
	 * @memberof DynamicWizardComponent
	 */
	public swipe(
		event: any): void
	{
		if (this.draggable === true)
		{
			this.scrollWizardViewportTo(
				this.wizardViewport.nativeElement.scrollLeft
					- (Math.abs(event.deltaX)
						* event.overallVelocityX
						* this.scrollMultiplier));

			this.pressUp();
		}
	}

	/**
	 * Returns the horizontal scroll width of the wizard component.
	 *
	 * @returns {number}
	 * The calculated scroll width of the wizard items.
	 * @memberof DynamicWizardComponent
	 */
	public getHorizontalScrollWidth(): number
	{
		return this.wizardViewport.nativeElement
			.getBoundingClientRect().width;
	}

	/**
	 * Performs a smooth scroll to animation on the wizard viewport.
	 *
	 * @param {number} horizontalScroll
	 * The x translation to perform on the current wizard.
	 * This is a bounds safe action and will only fire if a scroll is
	 * available to this sent location.
	 * @memberof DynamicWizardComponent
	 */
	public scrollWizardViewportTo(
		horizontalScroll: number): void
	{
		this.wizardViewport.nativeElement
			.scrollTo(
				{
					left: horizontalScroll,
					behavior: 'smooth'
				});

		this.setScrollMasks(horizontalScroll);
	}

	/**
	 * Sets the display values for the left and right mask based
	 * on available scroll translations in the wizard.
	 *
	 * @param {number} horizontalScroll
	 * The x translation recently scrolled to.
	 * @memberof DynamicWizardComponent
	 */
	public setScrollMasks(
		horizontalScroll: number): void
	{
		this.displayLeftMask =
			horizontalScroll > 0;
		this.displayRightMask =
			this.wizardViewport.nativeElement.scrollWidth >
				horizontalScroll
					+ this.wizardViewport.nativeElement
						.getBoundingClientRect().width + 1;
	}

	/**
	 * Scrolls the wizard to a centered wizard step and recalculates mask
	 * and scroll displays.
	 *
	 * @memberof DynamicWizardComponent
	 */
	public scrollToActiveStep(): void
	{
		if (this.draggable === false)
		{
			return;
		}

		const availableSteps: HTMLAnchorElement[] =
			this.wizardViewport.nativeElement
				.querySelectorAll(this.stepIdentifier);

		const wizardStepContainer: DOMRect =
			availableSteps[this.activeStepIndex].getBoundingClientRect();
		const horizontalScroll: number =
			wizardStepContainer.x
				- (wizardStepContainer.width / 2)
				- AppConstants.staticLayoutSizes.nestedContentPadding * 2;

		this.scrollWizardViewportTo(
			horizontalScroll);
	}

	/**
	 * Runs the wizard steps display method to set the display value of each
	 * step based on current context data.
	 *
	 * @param {IWizardStep[]} wizardSteps
	 * The wizard steps that require display calculations.
	 * @param {IDynamicComponentContext<Component, any>} dynamicComponentContext
	 * The dynamic component context for the wizard component.
	 * @memberof DynamicWizardComponent
	 */
	public calculateWizardStepDisplayValues(): void
	{
		const displayedWizardSteps: IWizardStep[] = [];
		const displayedWizardMenuItems: IWizardStepMenuItem[] = [];
		for (let index: number = 0;
			index < this.context.data.wizardSteps.length;
			index ++)
		{
			if (this.context.data.wizardSteps[index].stepDisplay() === true)
			{
				displayedWizardSteps.push(
					this.context.data.wizardSteps[index]);
				displayedWizardMenuItems.push(
					this.context.data.wizardMenuItems[index]);
			}
		}

		this.context.data.displayedWizardSteps = displayedWizardSteps;
		this.context.data.displayedWizardMenuItems = displayedWizardMenuItems;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof DynamicWizardComponent
	 */
	public async validStepChanged(
		isValid: boolean): Promise<void>
	{
		this.isValid =
			!AnyHelper.isNull(this.activeWizardStep.validator)
				&& AnyHelper.isFunction(this.activeWizardStep.validator)
				? this.activeWizardStep.validator()
				: isValid;

		this.allowedToContinue =
			await this.activeWizardStep.allowedToContinue;

		if (this.forwardStepDirection === true
			&& this.activeWizardStep.alwaysVerify === false
			&& this.isValid === true
			&& this.allowedToContinue !== false)
		{
			setTimeout(
				() =>
				{
					this.activeWizardStep.nextAction();
				},
				AppConstants.time.fiftyMilliseconds);
		}
		else if (!AnyHelper.isNullOrWhitespace(
			this.activeWizardStep.stepLayout)
			&& this.allowedToContinue === false)
		{
			this.wizardStepLoading = false;
		}
	}

	/**
	 * Handles the step data changed event sent from the child dynamic
	 * formly component. This will notify the component of changes to
	 * data in the formly display.
	 *
	 * @param {boolean} changed
	 * The changed truthy of the current displayed data set.
	 * @memberof DynamicWizardComponent
	 */
	public stepDataChanged(
		changed: boolean): void
	{
		this.valueChanged = changed;
	}

	/**
	 * Handles the step layout refresh. This will notify the wizard step
	 * loading process.
	 *
	 * @memberof DynamicWizardComponent
	 */
	private handleStepLayoutRefresh()
	{
		if (!AnyHelper.isNullOrWhitespace(this.activeWizardStep.stepLayout))
		{
			this.redraw = true;
			setTimeout(
				() =>
				{
					this.wizardStepLoading = false;
					this.redraw = false;

					this.changeDetectorReference.detectChanges();
				});
		}
	}
}