/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component,
	Input
} from '@angular/core';

@Component({
	selector: 'app-file-progress',
	templateUrl: './file-progress.component.html',
	styleUrls: ['./file-progress.component.scss']
})

/**
 * A class for displaying server operation progress
 * on files.
 */
export class FileProgressComponent
{
	/**
	 * Gets or sets a header message.
	 *
	 * @type {string}
	 * @memberof FileProgressComponent
	 */
	@Input()
	public header: string;

	/**
	 * Gets or sets the display Mode
	 *
	 * @type {string}
	 * @memberof FileProgressComponent
	 */
	@Input()
	public mode: string;

	/**
	 * Gets or sets the progress amount
	 *
	 * @type {number}
	 * @memberof FileProgressComponent
	 */
	@Input()
	public progress: number;

	/**
	 * Gets or sets the progress message
	 *
	 * @type {string}
	 * @memberof FileProgressComponent
	 */
	@Input()
	public message: string;

	/**
	 * Gets or sets the error message
	 *
	 * @type {string}
	 * @memberof FileProgressComponent
	 */
	@Input()
	public errorMessage: string;
}