/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	HttpClient
} from '@angular/common/http';
import {
	Inject, Injectable
} from '@angular/core';
import {
	IOperationTypeDto
} from '@api/interfaces/operations/operation-type.dto.interface';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CacheService
} from '@shared/services/cache.service';

/**
 * A class representing the logic and services of the operation
 * type controller.
 *
 * @export
 * @class OperationTypeApiService
 * @extends {BaseEntityApiService<IOperationTypeDto>}
 */
@Injectable()
export class OperationTypeApiService
	extends BaseEntityApiService<IOperationTypeDto>
{
	/**
	 * Creates an instance of an OperationTypeApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof OperationTypeApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.operationTypes;
	}
}
