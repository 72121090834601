/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	animate,
	state,
	style,
	transition,
	trigger
} from '@angular/animations';
import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	ViewChild
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	PermissionConstants
} from '@shared/constants/permission.constants';
import {
	ReportConstants
} from '@shared/constants/report.constants';
import {
	DisplayComponentParameterDirective
} from '@shared/directives/display-component-parameter.directive';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	User
} from '@shared/implementations/users/user';
import {
	IDashboardWidget
} from '@shared/interfaces/application-objects/dashboard-widget.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-dashboard-widget',
	templateUrl: './dashboard-widget.component.html',
	styleUrls: [
		'./dashboard-widget.component.scss',
		'./dashboard-widget-sizes.component.scss'
	],
	animations: [
		ContentAnimation,
		trigger('cardFlip', [
			state(
				'default',
				style({
					transform: 'none'
				})
			),
			state(
				'flipped',
				style({
					transform: 'rotateY(180deg)'
				})
			),
			state(
				'matched',
				style({
					visibility: 'false',
					transform: 'scale(0.05)',
					opacity: 0
				})
			),
			transition('default => flipped', [animate('400ms')]),
			transition('flipped => default', [animate('400ms')]),
			transition('* => matched', [animate('400ms')])
		])
	]
})

/**
 * A component representing a dashboard widget.
 *
 * @export
 * @class DashboardWidgetComponent
 * @extends {DisplayComponentParameterDirective}
 * @implements {AfterViewInit}
 * @implements {AfterViewChecked}
 */
export class DashboardWidgetComponent
	extends DisplayComponentParameterDirective
	implements AfterViewInit, AfterViewChecked
{
	/**
	 * Creates an instance of a dashboard widget component.
	 *
	 * @param {Router} router
	 * The router used for navigation to linked pages.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this directive.
	 * @param {DisplayComponentService} displayComponentService
	 * The service used to load and gather display component data.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The factory used to generate display component interfaces.
	 * @memberof DashboardWidgetComponent
	 */
	public constructor(
		public router: Router,
		public siteLayoutService: SiteLayoutService,
		public displayComponentService: DisplayComponentService,
		public displayComponentFactory: DisplayComponentFactory,
		public changeDetectorReference: ChangeDetectorRef)
	{
		super(
			siteLayoutService,
			displayComponentService,
			displayComponentFactory);
	}

	/**
	 * Gets or sets the input value sent from the dashboard
	 * that all sections and widgets have been displayed.
	 *
	 * @type {number}
	 * @memberof DashboardWidgetComponent
	 */
	@Input() public parameterDisplayReady: boolean = false;

	/**
	 * Gets or sets the configuration for this dashboard widget.
	 *
	 * @type {IDashboardWidget}
	 * @memberof DashboardWidgetComponent
	 */
	@Input() public widget: IDashboardWidget;

	/**
	 * Gets or sets the event emitter that will bubble up to listening
	 * components the changed widget value.
	 *
	 * @type {EventEmitter<IDashboardWidget>}
	 * @memberof DashboardWidgetComponent
	 */
	@Output() public widgetChange: EventEmitter<IDashboardWidget> =
		new EventEmitter();

	/**
	 * Gets or sets the event emitter that will bubble up to listening
	 * components that this widget has been displayed.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DashboardWidgetComponent
	 */
	@Output() public widgetDisplayed: EventEmitter<void> =
		new EventEmitter();

	/**
	 * Gets or sets the event emitter that will bubble up to listening
	 * components of an applied changed parameter set.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DashboardWidgetComponent
	 */
	@Output() public widgetParametersApplied: EventEmitter<void> =
		new EventEmitter();

	/**
	 * Gets or sets the primary display element reference.
	 *
	 * @type {ElementRef}
	 * @memberof DashboardWidgetComponent
	 */
	@ViewChild('PrimaryDisplay')
	public primaryDisplayElement: ElementRef;

	/**
	 * Gets or sets the secondary overlay element reference.
	 *
	 * @type {ElementRef}
	 * @memberof DashboardWidgetComponent
	 */
	@ViewChild('SecondaryOverlay')
	public secondaryOverlayElement: ElementRef;

	/**
	 * Gets or sets the flag that will signify if an edit option
	 * should be displayed.
	 *
	 * @type {boolean}
	 * @memberof DashboardWidgetComponent
	 */
	public displayEditOption: boolean = false;

	/**
	 * Gets or sets the active secondary display value, used to define
	 * if a dashboard widget item is currently displaying
	 * a secondary view display component.
	 *
	 * @type {boolean}
	 * @memberof DashboardWidgetComponent
	 */
	public secondaryDisplayActive: boolean = false;

	/**
	 * Gets or sets the flip state.
	 *
	 * @type {string}
	 * @memberof DashboardWidgetComponent
	 */
	public flipState: string = 'default';

	/**
	 * Gets the value defining if an overlay display is currently
	 * active and visible.
	 *
	 * @type {boolean}
	 * @memberof DashboardWidgetComponent
	 */
	public get displayingOverlay(): boolean
	{
		return this.useOverlay === true &&
			(this.settingsActive === true
				|| this.secondaryDisplayActive === true);
	}

	/**
	 * Gets the value defining if a secondary overlay display is
	 * currently active and visible.
	 *
	 * @type {boolean}
	 * @memberof DashboardWidgetComponent
	 */
	public get displayingSecondaryOverlay(): boolean
	{
		return this.secondaryDisplayActive === true
			&& this.useOverlay === true;
	}

	/**
	 * Gets the value defining if the overlay displays should be used
	 * on a parameter settings or secondary component display.
	 *
	 * @type {boolean}
	 * @memberof DashboardWidgetComponent
	 */
	public get useOverlay(): boolean
	{
		return (this.secondaryDisplayActive === true
			&& !AnyHelper.isNull(this.widget.secondaryHeight)
			&& (this.widget.height < this.widget.secondaryHeight
				|| this.widget.width < this.widget.secondaryWidth))
			|| (this.secondaryDisplayActive === true
				&& this.widget.forceSecondaryOverlay === true)
			|| (this.secondaryDisplayActive === false
				&& !AnyHelper.isNull(this.widget.height)
				&& (this.widget.height < this.minimumParameterDisplaySize
					|| this.widget.width < this.minimumParameterDisplaySize));
	}

	/**
	 * Gets the value defining if the icon set is floating or if it
	 * displays in a top panel.
	 *
	 * @type {boolean}
	 * @memberof DashboardWidgetComponent
	 */
	public get useFixedIcon(): boolean
	{
		return this.widget.floatingIcon === false
			|| (this.secondaryDisplayActive === true
				&& this.useOverlay === false
				&& this.widget.secondaryFloatingIcon === false);
	}

	/**
	 * Gets the value that signifies by what amount the width should be
	 * multiplied if the widget definition holds this value. This value
	 * will only alter widths on tablet sizes or less and allows 3's that
	 * become 6's for example to instead show as 12 to avoid whitespace.
	 *
	 * @type {number}
	 * @memberof DashboardWidgetComponent
	 */
	public get responsiveWidthMultiplier(): number
	{
		return this.siteLayoutService.displayTabletView === true
			? this.widget.responsiveWidthMultiplier
			: 1;
	}

	/**
	 * Gets the debounce value used to ensure clicking on the
	 * secondary icon when overlaid will close the secondary overlay.
	 *
	 * @type {boolean}
	 * @memberof DashboardWidgetComponent
	 */
	private readonly clickOutsideDebounce: number = 50;

	/**
	 * Gets the minimum display height or width to enforce displaying
	 * parameter sets in an overlay.
	 *
	 * @type {number}
	 * @memberof DashboardWidgetComponent
	 */
	private readonly minimumParameterDisplaySize: number = 6;

	/**
	 * Gets the flip default state.
	 *
	 * @type {string}
	 * @memberof DashboardWidgetComponent
	 */
	private readonly flipDefaultState: string = 'default';

	/**
	 * Gets the flip flipped state.
	 *
	 * @type {string}
	 * @memberof DashboardWidgetComponent
	 */
	private readonly flipFlippedtate: string = 'flipped';

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof DashboardWidgetComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		if (this.secondaryDisplayActive === true
			&& this.useOverlay === true)
		{
			this.secondaryDisplayActive = false;
		}

		this.decorateWidgetActions();
	}

	/**
	 * On component after view initialization event.
	 * This method is used to emit to listening components that
	 * it is fully loaded and displayed.
	 *
	 * @memberof DashboardWidgetComponent
	 */
	public ngAfterViewInit(): void
	{
		setTimeout(
			() =>
			{
				this.decorateWidgetActions();
				this.widgetDisplayed.emit();
			},
			AppConstants.time.quarterSecond);
	}

	/**
	 * On after view checked event.
	 * In cases where outside parameter changes have been applied from the
	 * section or the dashboard level, this will capture that change and redraw.
	 *
	 * @memberof DashboardWidgetComponent
	 */
	public ngAfterViewChecked(): void
	{
		if (this.widget.dataChanged !== true)
		{
			return;
		}

		this.applyParameters();
		this.widget.dataChanged = false;
	}

	/**
	 * Handles a widget click event if a settings display is defined.
	 * This will switch between the primary view and the settings display.
	 *
	 * @memberof DashboardWidgetComponent
	 */
	public settingsDisplayClicked(): void
	{
		if (this.widget.parameterLayoutSchema === null)
		{
			return;
		}

		this.settingsClicked();
	}

	/**
	 * Handles a widget click event if a secondary display is defined.
	 * This will switch between the primary view and the secondary display.
	 *
	 * @memberof DashboardWidgetComponent
	 */
	public clickOnDisplay()
	{
		if (AnyHelper.isNullOrEmpty(this.widget.secondaryDynamicComponent)
			|| this.widget.useClickOnDisplay !== true)
		{
			return;
		}

		this.secondaryDisplayClicked();
	}

	/**
	 * Handles a click for the expand and collapse action of
	 * parameter components.
	 *
	 * @memberof DashboardWidgetComponent
	 */
	public secondaryDisplayClicked(
		clickOutsideEvent: boolean = false): void
	{
		if (clickOutsideEvent === true
			&& this.secondaryDisplayActive === true
			&& this.useOverlay === true)
		{
			setTimeout(
				() =>
				{
					this.secondaryDisplayActive = false;
					this.changeDetectorReference.detectChanges();
				},
				this.clickOutsideDebounce);

			return;
		}

		if (this.widget.flipCard === true)
		{
			this.flipCard();
		}
		else
		{
			const primaryContainer: DOMRect =
				this.primaryDisplayElement.nativeElement
					.getBoundingClientRect();
			const sectionContainer: DOMRect =
				this.primaryDisplayElement.nativeElement
					.parentElement.parentElement
					.parentElement.parentElement
					.getBoundingClientRect();

			const topOffset: number =
				primaryContainer.bottom
					+ AppConstants.staticLayoutSizes.standardPadding
					- sectionContainer.top;

			this.secondaryOverlayElement.nativeElement
				.setAttribute(
					'style',
					`top: ${Math.round(topOffset)}px !important;`);
		}

		this.secondaryDisplayActive = !this.secondaryDisplayActive;
	}

	/**
	 * Handles a click event from the display edit view action.
	 *
	 * @memberof DashboardWidgetComponent
	 */
	public editDisplayClicked(): void
	{
		// Add this dashboard url to the history to keep back
		// functionality.
		history.pushState(
			null,
			AppConstants.empty,
			this.router.url
		);

		this.router.navigate(
			[
				AppConstants.moduleNames.bi,
				AppConstants.moduleNames.profile,
				AppConstants.route.display,
				`${AppConstants.displayComponentTypes.basePage}.`
					+ this.widget.widgetDisplayComponentInstance.name,
				AppConstants.viewTypes.edit
			],
			{
				replaceUrl: true
			});
	}

	/**
	 * Handles an apply click from the widget parameter component.
	 *
	 * @override
	 * @async
	 * @memberof DashboardWidgetComponent
	 */
	public async applyParameters(): Promise<void>
	{
		this.widget =
			await this.displayComponentFactory.dashboardWidget(
				this.widget.displayComponentInstance,
				<IDynamicComponentContext<Component, any>>
				{
					source: this.pageContext.source,
					data: this.widget.parameterLayoutData.data
				});

		this.widgetChange.emit(this.widget);
		this.widgetParametersApplied.emit();

		setTimeout(
			() =>
			{
				this.settingsActive = false;
			});
	}

	/**
	 * Handles logic that will decorate additional actions in the widget
	 * header based on business rules.
	 * Rules:
	 * PowerBI Reports will have an edit action for Support and Admin users
	 * if they are not in the standard workspace.
	 *
	 * @memberof DashboardWidgetComponent
	 */
	public decorateWidgetActions(): void
	{
		const jsonDisplayComponentDefinition: any =
			this.widget.widgetDisplayComponentInstance
				.displayComponentDefinition
				.jsonDefinition;
		const reportType: string =
			jsonDisplayComponentDefinition.externalReportType;

		if (!AnyHelper.isNull(reportType))
		{
			switch (reportType)
			{
				case ReportConstants.externalReportTypes.powerBi:
					setTimeout(() =>
					{
						this.displayEditOption =
							jsonDisplayComponentDefinition.workspaceType !==
							ReportConstants.powerBiWorkspaceTypes.standard
							&& new User((<GenericDashboardComponent>
								this.pageContext.source)
								.sessionService.user)
								.hasMembership(
									PermissionConstants
										.editPowerBiReportRoles);
					});
					break;
			}
		}
	}

	/**
	 * Handles flip state changes of a card.
	 *
	 * @memberof DashboardWidgetComponent
	 */
	public flipCard(): void
	{
		this.flipState =
			this.flipState === this.flipDefaultState
				? this.flipFlippedtate
				: this.flipDefaultState;
	}
}