/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A constant library used to defined the types of buttons used in
 * the operation button bar interface.
 *
 * @export
 * @const OperationButtonTypeConstants
 */
export const OperationButtonTypeConstants:
{
	button: string;
	groupButton: string;
	ellipsisButton: string;
	iconActions: string;
} = {
	button: 'BUTTON',
	groupButton: 'GROUPBUTTON',
	ellipsisButton: 'ELLIPSISBUTTON',
	iconActions: 'ICONACTIONS'
};