/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Location
} from '@angular/common';
import {
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	IReportListItem
} from '@bi/interfaces/report-list-item';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	BIReportSettingDirective
} from '@shared/directives/bi-report-setting.directive';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';
import {
	IDisplayComponentType
} from '@shared/interfaces/display-components/display-component-type.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IPowerBiCommandArguments
} from '@shared/interfaces/reports/power-bi/power-bi-command-arguments.interface';
import {
	IPowerBiReportDefinition
} from '@shared/interfaces/reports/power-bi/power-bi-report-definition.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	isEqual
} from 'lodash';

/* eslint-enable max-len */

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: [
		'./reports.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing a list of display component
 * defined reports.
 *
 * @export
 * @class ReportsComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 * @implements {IOwnershipGuardComponent}
 */
export class ReportsComponent
	extends CommonTablePageDirective
	implements OnInit, IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the reports component.
	 *
	 * @param {DisplayComponentService} displayComponentService
	 * The display component service used to populate full data sets.
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The display component instance api service used for list lookups.
	 * @param {DisplayComponentTypeApiService} displayComponentTypeApiService
	 * The display component type api service used for list lookups.
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The display component definition api service used for list lookups.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service user for created by lookups.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used for display.
	 * @param {Router} router
	 * The router used in this component for site navigation.
	 * @param {ActivatedRoute} route
	 * The site layout service to use for responsive layouts.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {ActivityService} activityService
	 * The activity service used in this component.
	 * @param {PowerBiApiService} powerBiApiService
	 * The power bi api service used in this component.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @param {Location} location
	 * The location used for route mapping.
	 * @param {BIReportSettingDirective} bIReportSettingDirective
	 * The location used for route mapping.
	 * @memberof ReportsComponent
	 */
	public constructor(
		public displayComponentService: DisplayComponentService,
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public displayComponentTypeApiService:
			DisplayComponentTypeApiService,
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public router: Router,
		public route: ActivatedRoute,
		public sessionService: SessionService,
		public siteLayoutService: SiteLayoutService,
		public activityService: ActivityService,
		public powerBiApiService: PowerBiApiService,
		public resolver: ResolverService,
		public location: Location,
		public bIReportSettingDirective: BIReportSettingDirective)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the tooltip element reference.
	 *
	 * @type {ElementRef}
	 * @memberof ReportsComponent
	 */
	@ViewChild('Tooltip')
	public tooltip: ElementRef;

	/**
	 * Gets or sets the search report filter element.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public SearchReportsFilter: string = AppConstants.empty;

	/**
	 * Gets or sets the search input element.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public searchInput: string = AppConstants.empty;

	/**
	 * Gets or sets the layout schema object to be used in the
	 * dynamic formly form fields.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof ReportsComponent
	 */
	public layoutSchema: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the validity of the form.
	 *
	 * @type {boolean}
	 * @memberof ReportsComponent
	 */
	public validForm: boolean = true;

	/**
	 * Gets or sets the search report options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof ReportsComponent
	 */
	public searchReportsOptions: IDropdownOption[];

	/**
	 * icon action id constants.
	 *
	 * @type {{navigateToEditReport: string;
	 *	navigateToViewReport: string;
	 *	deleteReport: string;}}
	 *
	 * @memberof ReportsComponent
	 */
	public iconActionIds: {
		navigateToEditReport: string;
		navigateToViewReport: string;
		deleteReport: string;
		} = {
			navigateToEditReport: 'NavigateToEditReport',
			navigateToViewReport: 'NavigateToViewReport',
			deleteReport: 'DeleteReport'
		};

	/**
	 * Gets or sets the loading value of this table display.
	 *
	 * @type {boolean}
	 * @memberof ReportsComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ReportsComponent
	 */
	public tableDefinition: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ReportsComponent
	 */
	public availableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ReportsComponent
	 */
	public selectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the selected delete row action item.
	 *
	 * @type {IReportListItem[]}
	 * @memberof ReportsComponent
	 */
	public itemsInDeleteMode: IReportListItem[] = [];

	/**
	 * Gets or sets the selected filter criteria.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public selectedFilterCriteria: string = AppConstants.empty;

	/**
	 * Gets or sets the selected filter criteria.
	 *
	 * @type {number}
	 * @memberof ReportsComponent
	 */
	public selectedRowCount: number = 15;

	/**
	 * Gets or sets the selected reports Filter.
	 *
	 * @type {IDropdownOption}
	 * @memberof ReportsComponent
	 */
	public selectedReports: IDropdownOption;

	/**
	 * Gets or sets the all Types Filter.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public allTypesFilter: string;

	/**
	 * Gets or sets the custom Type Filter.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public customTypeFilter: string;

	/**
	 * Gets or sets the standard Type Filter.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public standardTypeFilter: string;

	/**
	 * Gets or sets the route url entity order by parameter.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public entityOrderByParam: string;

	/**
	 * Gets or sets the search input value.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public searchInputValue: string = AppConstants.empty;

	/**
	 * Gets or sets the search report filter value.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public searchReportFilterValue: string = AppConstants.empty;

	/**
	 * Captures validity changes sent from the dynamic formly component.
	 *
	 * @param {boolean} valid
	 * Whether or not the current form is valid.
	 * @memberof ReportsComponent
	 */
	public formValidityChange(
		valid: boolean): void
	{
		this.validForm = valid;
	}

	/**
	 * Sets the row Selected Info data.
	 *
	 * @param {string} displayComponentInstanceName
	 * The display component instance name.
	 * @param {string} navigateAction
	 * Provided navigation action, null as default value
	 * Whether or not the current form is valid.
	 * @returns {IPowerBiCommandArguments}
	 * @memberof ReportsComponent
	 */
	public getItemSelectedInfo(
		displayComponentInstanceName: string,
		navigateAction: string = null): IPowerBiCommandArguments
	{
		return {
			selectedItem:
				{
					displayComponentInstanceName: displayComponentInstanceName
				},
			item:
				{
					id: AnyHelper.isNullOrEmpty(navigateAction)
						? this.iconActionIds.navigateToViewReport
						: navigateAction
				}
		};
	}

	/**
	 * This will handle the change event in the search reports filter.
	 *
	 * @param {string} filterValue
	 * Whether or not the current form is valid.
	 * @memberof ReportsComponent
	 */
	public change(
		filterValue: string): void
	{
		this.searchInput =
			AnyHelper.isNullOrEmpty(
				this.layoutSchema[1].form.value.data.filter)
				? AppConstants.empty
				: this.layoutSchema[1].form.value.data.filter;

		this.searchCriteria();

		this.SearchReportsFilter =
			AnyHelper.isNullOrEmpty(filterValue)
				? this.allTypesFilter
				: filterValue;

		if (this.SearchReportsFilter ===
			this.selectedFilterCriteria)
		{
			return;
		}

		this.selectedFilterCriteria = this.SearchReportsFilter;
		this.commonTableContext.source.filterCriteriaChanged(
			this.selectedFilterCriteria);
	}

	/**
	 * Gets the delete statement.
	 *
	 * @returns {string}
	 * The delete statement string.
	 * @memberof ReportsComponent
	 */
	public getDeleteStatement(): string
	{
		return `Confirm you want to delete Report
			${this.commonTableContext.source.selectedItem.reportName}.`;
	}

	/**
	 * Get filtered Report list by Keyword.
	 *
	 * @param {IReportListItem[]} reports
	 * Provides report list item.
	 * @param {string} keyword
	 * Provides keyword.
	 *
	 * @returns {IReportListItem[]}
	 * @memberof ReportsComponent
	 */
	public searchReportsByKeyword(
		reports: IReportListItem[],
		keyword: string): IReportListItem[]
	{
		const lowerKeyword: string = keyword.toLowerCase();

		return reports.filter(
			report => (
				[
					report.reportName,
					report.reportType,
					report.workspaceType,
					report.createdByName
				]
					.map(field =>
						(typeof field === AppConstants.propertyTypes.string
							? field
							: AppConstants.empty)
							.toLowerCase())
					.join(AppConstants.characters.space)
					.includes(lowerKeyword)));
	}

	/**
	 * Searches for the input criteria value from the
	 * dynamic form search field.
	 *
	 * @memberof ReportsComponent
	*/
	public searchCriteria(): void
	{
		if (this.searchInput ===
			this.searchInputValue)
		{
			return;
		}

		this.searchInputValue = this.searchInput;
		this.commonTableContext.source.filterCriteriaChanged(
			this.selectedFilterCriteria);
	}

	/**
	 * Handles the Enter key press event to excecution the search accion.
	 *
	 * @param {FormlyFieldConfig} field
	 * The key press event data object.
	 * @param {KeyboardEvent} event
	 * The key press event data object.
	 * @memberof ReportsComponent
	 */
	public searchOnEnterKeyPress(
		field: FormlyFieldConfig,
		event: KeyboardEvent): void
	{
		if (event.key !== AppConstants.keyBoardKeyConstants.enter)
		{
			return;
		}

		this.searchInput =
			AnyHelper.isNullOrEmpty(field.form.value.data.filter)
				? AppConstants.empty
				: field.form.value.data.filter;

		this.searchCriteria();
	}

	/**
	 * Handles the on initialization event.
	 * This will setup the table definitions for the reports search page.
	 *
	 * @async
	 * @memberof ReportsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					AppConstants.displayComponentTypes.reportPowerBiStandard,
					AppConstants.displayComponentTypes.reportPowerBiCustom
				]);

			return;
		}

		await this.setFilterTypes();

		this.searchReportsOptions =
			[
				{
					label: 'All Reports',
					value: this.allTypesFilter
				},
				{
					label: 'Custom Reports',
					value: this.customTypeFilter
				},
				{
					label: 'Standard Reports',
					value: this.standardTypeFilter
				}
			];

		this.setupTableDefinitions();
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate page ownership permissions.
	 *
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this page.
	 * @async
	 * @memberof ReportsComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const displayTypes: IDisplayComponentType[] =
			await this.displayComponentTypeApiService
				.query(
					'Name eq \''
						+ AppConstants.displayComponentTypes
							.reportPowerBiStandard
						+ '\' or Name eq \''
						+ AppConstants.displayComponentTypes
							.reportPowerBiCustom
						+ '\'',
					AppConstants.empty);

		if (displayTypes.length === 0)
		{
			return false;
		}

		const displayDefinitions: IDisplayComponentDefinition[] =
			await this.displayComponentDefinitionApiService.query(
				this.getTypeFilter(
					displayTypes),
				AppConstants.empty);

		return displayDefinitions.length !== 0;
	}

	/**
	 * Sets the filter types.
	 *
	 * @async
	 * @memberof ReportsComponent
	 */
	public async setFilterTypes(): Promise<void>
	{
		const displayTypes: IDisplayComponentType[] =
			await this.displayComponentTypeApiService
				.query(
					'Name.StartsWith(\'Report\') eq true',
					AppConstants.empty);
		this.allTypesFilter =
			this.getTypeFilter(
				displayTypes);
		this.customTypeFilter =
			this.getTypeFilter(
				displayTypes,
				AppConstants.displayComponentTypes.reportPowerBiCustom);
		this.standardTypeFilter =
			this.getTypeFilter(
				displayTypes,
				AppConstants.displayComponentTypes.reportPowerBiStandard);

		this.selectedFilterCriteria = this.allTypesFilter;

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'reportName',
					columnHeader: 'Report Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'reportType',
					columnHeader: 'Report Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'workspaceType',
					columnHeader: 'Workspace',
					displayOrder: displayOrder
				},
				{
					dataKey: 'createdByName',
					columnHeader: 'Created By',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns =
			this.availableColumns;
	}

	/**
	 * Sets up the list column definitions for the current reports object
	 * list.
	 *
	 * @memberof ReportsComponent
	 */
	public setupTableDefinitions(): void
	{
		this.entityOrderByParam =
			this.entityOrderByParam
			?? `${AppConstants.commonProperties.name} `
					+ `${AppConstants.sortDirections.ascending}`;

		this.tableDefinition =
			{
				tableTitle: 'Reports',
				fullPageReservedHeight:
					AppConstants.staticLayoutSizes.tableSearchHeight,
				objectSearch: {
					filter: this.selectedFilterCriteria,
					orderBy: `${AppConstants.commonProperties.name} `
						+ `${AppConstants.sortDirections.ascending}`,
					offset: 0,
					limit: 200,
					virtualIndex: 0,
					virtualPageSize: this.selectedRowCount
				},
				apiPromise:
					async(objectSearch: IObjectSearch) =>
					{
						const data: IDisplayComponentInstance[] =
							<IDisplayComponentInstance[]>
							await this.displayComponentInstanceApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						if (AnyHelper.isNullOrEmpty(data)
							|| data.length === 0)
						{
							return [];
						}

						const initialPromiseArray: Promise<any>[] = [];
						data.forEach(
							(iDisplayComponentInstance:
										IDisplayComponentInstance) =>
							{
								initialPromiseArray.push(
									this.displayComponentService
										.populateDisplayComponentInstance(
											iDisplayComponentInstance.name,
											this.pageContext,
											null,
											iDisplayComponentInstance,
											true));
							});
						const displayComponentInstances:
							DisplayComponentInstance[] =
								await Promise.all(
									initialPromiseArray);

						const mappedData: IReportListItem[] =
							await this.mapDisplayComponents(
								displayComponentInstances);

						let filteredReports: IReportListItem[] =
							mappedData;

						if (!AnyHelper.isNullOrWhitespace(
							this.searchInputValue))
						{
							filteredReports =
								this.searchReportsByKeyword(
									mappedData,
									this.searchInputValue);
						}

						return filteredReports;
					},
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					view: {
						disabledExpandItem: true,
						items: [
							{
								command: () => {
									const selectedItem: any =
										this.commonTableContext
											.source.selectedItem;
									const displayComponentInstanceName: string =
										selectedItem
											.displayComponentInstanceName;

									this.bIReportSettingDirective
										.navigateToDisplay(
											this.router,
											this.getItemSelectedInfo(
												displayComponentInstanceName));
								}
							}
						]
					}
				}
			};

		this.setDynamicFormlyDefinitions();
		this.loadingTableDefinitions = false;
	}

	/**
	 * Maps a list of display component instances into the expected
	 * set of report list items.
	 *
	 * @param {DisplayComponentInstance[]} displayComponentInstances
	 * The set of display component instances to map into the expected
	 * list item type.
	 * @returns {Promise<IReportListItem[]>}
	 * The mapped set of list items to display in this common table.
	 * @async
	 * @memberof ReportsComponent
	 */
	public async mapDisplayComponents(
		displayComponentInstances:
			DisplayComponentInstance[]): Promise<IReportListItem[]>
	{
		const creatorIds: string[] =
			[...new Set(
				displayComponentInstances
					.map(
						(displayComponent: IDisplayComponentInstance) =>
							displayComponent.createdById.toString()))];

		const createdByFilter: string =
			AppConstants.commonProperties.id
				+ ' in ('
				+ ApiFilterHelper.commaSeparatedStringValues(
					creatorIds,
					AppConstants.empty)
				+ ')';

		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
		const creators: IEntityInstance[] =
			await ApiHelper.getFullDataSet(
				this.entityInstanceApiService,
				createdByFilter,
				AppConstants.empty);

		const mappedData: IReportListItem[] = [];
		for (const displayComponent of displayComponentInstances)
		{
			const creator: IEntityInstance =
				creators.filter(
					(user: IEntityInstance) =>
						user.id === displayComponent.createdById)[0];

			const reportDefinition: any =
				displayComponent.displayComponentDefinition.jsonDefinition;
			mappedData.push(
				<IReportListItem>
				{
					id: displayComponent.id,
					displayComponentDefinition:
						displayComponent.displayComponentDefinition,
					displayComponentInstance: displayComponent,
					displayComponentInstanceName: displayComponent.name,
					reportDefinition: reportDefinition,
					reportName: reportDefinition.reportName,
					reportType: reportDefinition.reportType,
					workspaceType: reportDefinition.workspaceType,
					createdByName:
						StringHelper.toNameString(
							creator.data.firstName,
							creator.data.lastName)
				});
		}

		return mappedData;
	}

	/**
	 * Deletes a report from this list of available reports.
	 *
	 * @async
	 * @memberof ReportsComponent
	 */
	public async deleteReport(): Promise<void>
	{
		const selectedItem: any = this.commonTableContext.source.selectedItem;

		const basePageReportDisplayComponentName: string =
			`${AppConstants.displayComponentTypes.basePage}.`
				+ selectedItem.displayComponentInstanceName;

		this.itemsInDeleteMode.push(selectedItem);

		const reportDisplayComponentInstance: IDisplayComponentInstance =
		selectedItem.displayComponentInstance;
		const reportDefinition: IPowerBiReportDefinition =
			{
				...selectedItem.reportDefinition
			};

		const basePageReportDisplayComponentInstance:
			IDisplayComponentInstance =
				<IDisplayComponentInstance>
				await this.displayComponentInstanceApiService
					.getSingleQueryResult(
						`Name Eq '${basePageReportDisplayComponentName}'`,
						`${AppConstants.commonProperties.id}`);

		const basePageReportDisplayComponentInstanceId: number =
			basePageReportDisplayComponentInstance.id;
		const reportDisplayComponentInstanceId: number =
			reportDisplayComponentInstance.id;

		const basePageSecurityGroupIdentifiers: number[] =
			await this.displayComponentInstanceApiService
				.getSecurityGroups(basePageReportDisplayComponentInstanceId);
		const reportSecurityGroupIdentifiers: number[] =
			await this.displayComponentInstanceApiService
				.getSecurityGroups(reportDisplayComponentInstanceId);

		const initialPromiseArray: Promise<any>[] = [];
		basePageSecurityGroupIdentifiers.forEach(
			(securityGroupId: number) =>
			{
				initialPromiseArray.push(
					this.displayComponentInstanceApiService
						.deleteSecurityGroupMap(
							basePageReportDisplayComponentInstanceId,
							securityGroupId));
			});
		reportSecurityGroupIdentifiers.forEach(
			(securityGroupId: number) =>
			{
				initialPromiseArray.push(
					this.displayComponentInstanceApiService
						.deleteSecurityGroupMap(
							reportDisplayComponentInstanceId,
							securityGroupId));
			});

		await this.activityService.handleActivity(
			new Activity(
				new Promise<void>(
					async(resolve) =>
					{
						await Promise.all(
							initialPromiseArray);

						await this.displayComponentInstanceApiService
							.delete(reportDisplayComponentInstanceId);
						await this.displayComponentInstanceApiService
							.delete(basePageReportDisplayComponentInstanceId);

						const tableContextSource: CommonTableComponent =
								this.commonTableContext.source;
						await this.powerBiApiService
							.deleteReport(
								reportDefinition);

						const persistedData: any =
							await tableContextSource.tableDefinitions
								.apiPromise(
									tableContextSource
										.tableDefinitions.objectSearch);

						const lastItem: IReportListItem =
							persistedData[
								tableContextSource.tableData.length - 1];
						const lastItemExists: boolean =
							tableContextSource.tableData.filter(
								(reportListItem: IReportListItem) =>
									!isEqual(
										reportListItem,
										lastItem)).length > 0;

						tableContextSource.tableData =
							[
								...this.filterListItems(
									tableContextSource.tableData,
									reportDefinition)
							];
						tableContextSource.virtualData =
							[
								...this.filterListItems(
									tableContextSource.virtualData,
									reportDefinition)
							];

						if (!lastItemExists)
						{
							tableContextSource.virtualData
								.push(lastItem);
							tableContextSource.tableData
								.push(lastItem);
						}

						tableContextSource.scrollTableToTop();

						this.itemsInDeleteMode =
							[
								...this.filterListItems(
									this.itemsInDeleteMode,
									reportDefinition)
							];
						resolve();
					}),
				'<strong>Deleting Report</strong>',
				'<strong>Deleted A Report</strong>',
				`${reportDefinition.reportName} was deleted.`,
				`${reportDefinition.reportName} was not deleted.`));
	}

	/**
	 * Creates a filter for use in api calls using specific type ids. If a
	 * specific display type is sent, this will only include matches of display
	 * component types with that same name.
	 *
	 * @param {IDisplayComponentType[]} displayTypes
	 * The array of available display types to create filter for.
	 * @param {string} displayType
	 * If sent this will return a filter for matching only the sent specific
	 * display type. This defaults to null and will return a filter for the
	 * full display types array.
	 * @returns {string}
	 * A string for use as a display type filter when querying display
	 * component instances.
	 * @memberof ReportsComponent
	 */
	public getTypeFilter(
		displayTypes: IDisplayComponentType[],
		displayType: string = null): string
	{
		return ApiFilterHelper.getEnumerationFilter(
			AppConstants.commonProperties.typeId,
			displayTypes
				.filter((displayComponentType: IDisplayComponentType) =>
					AnyHelper.isNullOrWhitespace(displayType)
						|| displayComponentType.name === displayType)
				.map((displayComponentType: IDisplayComponentType) =>
					`${displayComponentType.id}`));
	}

	/**
	 * Returns a list of filtered list items with the sent report list item
	 * with a matching report definition removed.
	 *
	 * @param {IReportListItem[]} arrayToClean
	 * The array of report list items that will be filtered.
	 * @param {IPowerBiReportDefinition} reportDefinitionToRemove
	 * The report definition to match for list item removal.
	 * @returns {IReportListItem[]}
	 * The list of items with the filtered list item removed.
	 * @memberof ReportsComponent
	 */
	private filterListItems(
		arrayToClean: IReportListItem[],
		reportDefinitionToRemove: IPowerBiReportDefinition): IReportListItem[]
	{
		return arrayToClean.filter(
			(reportListItem: IReportListItem) =>
				reportListItem.reportDefinition.reportName !==
					reportDefinitionToRemove.reportName
					|| reportListItem.reportDefinition.groupId !==
						reportDefinitionToRemove.groupId);
	}

	/**
	 * Defines the dynamic formly properties.
	 *
	 * @memberof ReportsComponent
	*/
	private setDynamicFormlyDefinitions(): void
	{
		this.layoutSchema =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.search',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						placeholder: 'Select a Category',
						options: this.searchReportsOptions,
						change: (field: FormlyFieldConfig) =>
						{
							this.change(field.formControl.value);
						},
						attributes: {
							label: 'searchCategories'
						}
					}
				},
				{
					key: 'data.filter',
					type: FormlyConstants.customControls.input,
					id: 'Search',
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						placeholder: 'Filter',
						attributes: {
							title: AppConstants.commonTableActions.filterInput
						},
						keypress:
							(_field: FormlyFieldConfig,
								event: KeyboardEvent) =>
							{
								this.searchOnEnterKeyPress(
									_field,
									event);
							}
					}
				}
			];
	}
}