/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Injectable
} from '@angular/core';
import {
	Message
} from 'primeng/api';

/**
 * A class representing logic for login level messaging.
 *
 * @export
 * @class LoginMessageService
 */
@Injectable({
	providedIn: 'root'
})
export class LoginMessageService
{
	/**
	 * Gets or sets a collection of {Message}.
	 *
	 * @type {Message[]}
	 * @memberof LoginMessageService
	 */
	public messages: Message[] = [];

	/**
	 * Adds a message to the login messages.
	 *
	 * @param {(object | Message)} message
	 * @memberof LoginMessageService
	 */
	public add(message: object | Message): void
	{
		const messageObject: Message = message;

		if (!messageObject.id)
		{
			this.messages.push(message);
		}

		if (!this.messages.find(item => item.id === messageObject.id))
		{
			this.messages.push(message);
		}
	}

	/**
	 * Clears all messages from the login level messages.
	 *
	 * @memberof LoginMessageService
	 */
	public clear(): void
	{
		this.messages = [];
	}
}