/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component,
	ElementRef,
	Injectable,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormGroup
} from '@angular/forms';
import {
	ApiError
} from '@api/errors/api.error';
import {
	LoginComponent
} from '@appComponents/login/login.component';
import {
	ShakeAnimation
} from '@shared/app-animations';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	SessionService
} from '@shared/services/session.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	passwordValidatorReactiveForms
} from '@entity/entity-formly-definitions';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
	selector: 'app-login-change-password',
	templateUrl: './login-change-password.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		ShakeAnimation
	]
})

/**
 * A class representing an instance of the login change password component.
 *
 * @export
 * @class LoginChangePasswordComponent
 * @implements {OnInit}
 */
@Injectable()
export class LoginChangePasswordComponent
implements OnInit
{
	/**
	 * Creates an instance of LoginChangePasswordComponent.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {SessionService} sessionService
	 * The sessions service used in this login component.
	 * @param {Router} router
	 * The router used in this login component.
	 * @param {LoginComponent} loginComponent
	 * The primary login component.
	 * @param {FormBuilder} formBuilder
	 * Builds a form object.
	 * @memberof LoginChangePasswordComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public loginComponent: LoginComponent,
		public readonly formBuilder: UntypedFormBuilder)
	{
	}

	/**
	 * Gets or sets the new password element.
	 *
	 * @type {ElementRef}
	 * @memberof LoginChangePasswordComponent
	 */
	@ViewChild('newPassword', { static: false })
	public newPasswordElement: ElementRef;

	/**
	 * Gets or sets the confirm password element.
	 *
	 * @type {ElementRef}
	 * @memberof LoginChangePasswordComponent
	 */
	@ViewChild('confirmPassword', { static: false })
	public confirmPasswordElement: ElementRef;

	/**
	 * Gets or sets the user logged in.
	 *
	 * @type {IUser}
	 * @memberof LoginChangePasswordComponent
	 */
	public user: IUser;

	/**
	 * Gets or sets the system security object.
	 *
	 * @type {any}
	 * @memberof LoginChangePasswordComponent
	 */
	public systemSecurity: any;

	/**
	 * Gets or sets the system security object.
	 *
	 * @type {any}
	 * @memberof LoginChangePasswordComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the change password form group.
	 *
	 * @type {FormGroup}
	 * @memberof LoginChangePasswordComponent
	 */
	public changePasswordForm: UntypedFormGroup;

	/**
	 * Gets or sets a value indicating whether a form submit is in progress.
	 *
	 * @public
	 * @type {boolean}
	 * @memberof LoginChangePasswordComponent
	 */
	public submitInProgress: boolean = false;

	/**
	 * Gets a {AbstractControl} representing the new password from the form.
	 *
	 * @public
	 * @type {AbstractControl}
	 * @memberof LoginChangePasswordComponent
	 */
	public get newPassword(): AbstractControl
	{
		return this.changePasswordForm?.controls?.newPassword;
	}

	/**
	 * Gets a {AbstractControl} representing the confirm password from the form.
	 *
	 * @type {AbstractControl}
	 * @memberof LoginChangePasswordComponent
	 */
	public get confirmPassword(): AbstractControl
	{
		return this.changePasswordForm?.controls?.confirmPassword;
	}

	/**
	 * Gets the system security restrictions to define a password.
	 *
	 * @type {any}
	 * @memberof LoginChangePasswordComponent
	 */
	public getSecurityRestrictions(): any
	{
		return SecurityHelper.getSecurityRestrictions(
			this.systemSecurity);
	}

	/**
	 * Gets the allowed password special characters.
	 *
	 * @type {any}
	 * @memberof LoginChangePasswordComponent
	 */
	public getPasswordSpecialCharacters(): any
	{
		return AppConstants.passwordSpecialCharacters;
	}

	/**
	 * Determines if the new password control has an invalid password value.
	 *
	 * @memberof LoginChangePasswordComponent
	 */
	public hasInvalidPassword(): boolean
	{
		return this.newPassword?.hasError('invalidPassword')
			&& this.newPassword?.touched;
	}

	/**
	 * Determines id the confirm button should be disabled.
	 *
	 * @type {boolean}
	 * @memberof LoginChangePasswordComponent
	 */
	public confirmIsDisabled(): boolean
	{
		return !this.changePasswordForm.valid
			|| this.submitInProgress
			|| this.newPassword?.value !== this.confirmPassword?.value;
	}

	/**
	 * Gets a {string} value representing the userName from the user logged in.
	 *
	 * @type {string}
	 * @memberof LoginChangePasswordComponent
	 */
	public userName(): string
	{
		return this.user.data.userName;
	}

	/**
	 * On initialization event.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof LoginChangePasswordComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.user =
			this.sessionService.user;

		await this.setSystemSecurity();

		this.changePasswordForm = this.formBuilder.group(
			{
				'newPassword':
					[
						'',
						[
							passwordValidatorReactiveForms(
								this.getSecurityRestrictions())
						]
					],
				'confirmPassword':
					[
						''
					]
			});

		this.loading = false;

		setTimeout(
			() =>
			{
				this.newPasswordElement.nativeElement.focus();
			},
			AppConstants.time.fiftyMilliseconds);
	}

	/**
	 * On form cancel event.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof LoginChangePasswordComponent
	 */
	public async cancel(): Promise<void>
	{
		this.sessionService.logOut();
		this.loginComponent.displayLogin(this.userName());
	}

	/**
	 * On form submit event.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof LoginChangePasswordComponent
	 */
	public async changePassword(): Promise<void>
	{
		try
		{
			this.submitInProgress = true;

			await this.sessionService.changePassword(
				this.newPassword.value);

			this.entityInstanceApiService.entityInstanceTypeGroup =
				AppConstants.typeGroups.users;

			const user: IEntityInstance =
				await this.entityInstanceApiService.get(
					this.sessionService.user.id);

			this.sessionService.logOut();

			this.loginComponent.displayLogin(
				user.data.userName,
				'Your change password request was successful.');
		}
		catch (exception)
		{
			if (exception.status === 404)
			{
				this.loginComponent.addMessage(
					AppConstants.messageLevel.warn,
					AppConstants.loginStatus.invalid,
					`Username '${this.userName}' not found.`);

				this.changePasswordForm.controls.newPassword.reset();
				this.changePasswordForm.controls.confirmPassword.reset();

				this.newPasswordElement.nativeElement.focus();
			}
			else if (exception instanceof ApiError)
			{
				this.loginComponent.addMessage(
					AppConstants.messageLevel.error,
					exception.message,
					exception.messages[0].messages[0]?.description);
			}
			else
			{
				this.loginComponent.addMessage(
					AppConstants.messageLevel.error,
					AppConstants.loginStatus.failure,
					exception.message);
			}

			return;
		}
		finally
		{
			this.submitInProgress = false;
		}
	}

	/**
	 * Sets the system security information.
	 *
	 * @async
	 * @returns {Promise<any>}
	 * @memberof LoginChangePasswordComponent
	 */
	public async setSystemSecurity(): Promise<void>
	{
		if (AnyHelper.isNull(this.systemSecurity))
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				AppConstants.typeGroups.systems;

			const systemEntityInstance: IEntityInstance =
				await this.entityInstanceApiService
					.get(
						parseInt(
							AppConstants.systemId,
							AppConstants.parseRadix));

			this.systemSecurity =
				systemEntityInstance.data.security;
		}
	}
}