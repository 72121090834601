/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SecurityRightCategory
} from '@shared/constants/enums/security-right-category.enum';
import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';
import {
	WorkItemConstants
} from '@shared/constants/work-item-constants';
import {
	CommonChildListDirective
} from '@shared/directives/common-child-list-directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ISecureMenuItem
} from '@shared/interfaces/secure-menu-item.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';
import {
	IWorkItem
} from '@shared/interfaces/workItems/work-item.interface';
import {
	SessionService
} from '@shared/services/session.service';
import {
	DateTime
} from 'luxon';
import {
	MenuItem
} from 'primeng/api';

@Component({
	selector: 'app-work-item-list',
	templateUrl: './work-item-list.component.html',
	styleUrls: [
		'./work-item-list.component.scss'
	]
})

/**
 * A component representing context level work item list view.
 *
 * @export
 * @class WorkItemListComponent
 * @extends {CommonChildListDirective}
 * @implements {IDynamicComponent<Component, any>}
 */
export class WorkItemListComponent
	extends CommonChildListDirective
	implements IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the work item list component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @param {SessionService} sessionService
	 * The session service.

	 * @memberof WorkItemListComponent
	 */
	public constructor(
		public entityservice: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
		super(
			entityservice,
			entityTypeApiService,
			entityInstanceApiService);
	}

	/**
	 * Gets or sets the list of sorts available in this list component.
	 *
	 * @type {ICommonListSort[]}
	 * @memberof WorkItemListComponent
	 */
	public sorters: ICommonListSort[] =
		<ICommonListSort[]>
		[
			<ICommonListSort>
			{
				name: 'Create Date',
				value: AppConstants.commonProperties.id,
				direction: AppConstants.sortDirections.descending,
				iconAsc: AppConstants.cssClasses.sortAscending,
				iconDesc: AppConstants.cssClasses.sortDescending
			},
			<ICommonListSort>
			{
				name: 'Due Date',
				value: 'keyDates.dueDate',
				direction: AppConstants.sortDirections.ascending,
				iconAsc: AppConstants.cssClasses.sortAscending,
				iconDesc: AppConstants.cssClasses.sortDescending
			},
			<ICommonListSort>
			{
				name: 'Status',
				value: 'status',
				direction: AppConstants.sortDirections.ascending,
				iconAsc: AppConstants.cssClasses.sortAscending,
				iconDesc: AppConstants.cssClasses.sortDescending
			}
		];

	/**
	 * Gets or sets the list of status values that are considered still
	 * in progress.
	 *
	 * @type {string[]}
	 * @memberof WorkItemListComponent
	 */
	private readonly activeStatusValues: string[] =
		[
			WorkItemConstants.workItemStatus.active,
			WorkItemConstants.workItemStatus.overdue
		];

	/**
	 * Generates a common list context that can be consumed by a common list
	 * displayed in this component.
	 *
	 * @param {ICommonListItem<IEntityInstance>[]} data
	 * The collection of data that will be displayed in this list.
	 * @returns {ICommonListContext<IEntityInstance>}
	 * The common list context to display.
	 * @memberof WorkItemListComponent
	 */
	public generateCommonListContext(
		data: ICommonListItem<IEntityInstance>[]):
		ICommonListContext<IEntityInstance>
	{
		const listFilters: ICommonListFilter[] =
			<ICommonListFilter[]>
			[
				{
					name: 'My Work Items',
					value: 'assignedTo eq ' +
						`'${this.sessionService.user.data.userName}'`
				},
				{
					name: 'Active',
					value: 'status eq \'active\''
				},
				{
					name: 'Blocked',
					value: 'status eq \'blocked\''
				},
				{
					name: 'Done',
					value: 'status eq \'done\''
				},
				{
					name: 'Ignored',
					value: 'status eq \'ignored\''
				},
				{
					name: 'High Priority',
					value: 'priority eq \'high\''
				},
				{
					name: 'Normal Priority',
					value: 'priority eq \'normal\''
				},
				{
					name: 'Low Priority',
					value: 'priority eq \'low\''
				}
			];

		const actions: ISecureMenuItem[] = [];

		if (this.canCreate())
		{
			actions.push(
				<ISecureMenuItem>
				{
					icon: 'fa fa-plus-circle',
					command: (event: any) => {
						this.changeDisplayMode.emit(
							AppConstants.displayMode.create);

						event.stopPropagation();
					},
					securityRightCategory: SecurityRightCategory.Action,
					securityRightPath: AppConstants.apiMethods.create
				});
		}

		const generatedCommonListContext:
			ICommonListContext<IEntityInstance> =
				<ICommonListContext<IEntityInstance>>
				{
					data: data,
					supportMarkdown: true,
					searchable: true,
					sortable: true,
					sorters: this.sorters,
					actions: actions,
					filters: listFilters,
					onFilterChange: (source, filters) =>
						this.handleFilterChange(source, filters),
					onSortChange: (source, sorter) =>
						this.handleSortingChange(source, sorter)
				};

		return generatedCommonListContext;
	}

	/**
	 * Maps and returns a list item that represents a work item entity instance.
	 *
	 * @param {IEntityInstance} entityInstance
	 * The entity instance to be mapped into a list item.
	 * @returns {ICommonListItem<IEntityInstance>}
	 * The common list item to display that represents this entity instance.
	 * @memberof WorkItemListComponent
	 */
	public mapToListItem(
		entityInstance: IEntityInstance):
		ICommonListItem<IEntityInstance>
	{
		const dueDate: string =
			DateHelper.formatDate(
				DateTime.fromISO(
					entityInstance.data.keyDates.dueDate),
				DateHelper.presetFormats.shortDateFormat);

		let statusColor: string = AppConstants.empty;
		switch (entityInstance.data.status)
		{
			case WorkItemConstants.workItemStatus.done:
				statusColor = AppConstants.cssClasses.textSuccessColor;
				break;
			case WorkItemConstants.workItemStatus.ignored:
				statusColor = AppConstants.cssClasses.textSecondaryColor;
				break;
			case WorkItemConstants.workItemStatus.overdue:
				statusColor = AppConstants.cssClasses.textErrorColor;
				break;
		}

		const priorityIcon: string =
			(this.activeStatusValues.indexOf(entityInstance.data.status) !== -1
				&& entityInstance.data.priority ===
					WorkItemConstants.workItemPriority.high)
				? `<i class="${AppConstants.cssClasses.fontAwesomePrefix}`
					+ `${AppConstants.cssIcons.exclamation} `
					+ 'item-description-icon"></i>'
				:  AppConstants.empty;

		let displayType: string = entityInstance.entityType;

		displayType = StringHelper
			.beforeCapitalSpaces(displayType
				.replace('WorkItem.', AppConstants.empty)
				.replaceAll('.', AppConstants.empty));

		const authorizedActions: MenuItem[] = [];

		// Authorized to Read
		if (SecurityHelper.HasRight(
			SecurityRightType.read,
			entityInstance.entityType,
			this.securityDefinitions)) {
			authorizedActions.push(
				<MenuItem>
				{
					icon: 'fa fa-info-circle',
					command: (event: any) => {
						this.changeSelectedItem.emit(entityInstance);
						this.changeDisplayMode.emit(
							AppConstants.displayMode.view);
						event.stopPropagation();
					}
				});
		}

		// Authorized to Edit?
		if (SecurityHelper.actionAuthorized(
			entityInstance.entityType,
			AppConstants.apiMethods.update,
			this.securityDefinitions)) {
			authorizedActions.push(
				<MenuItem>{
					icon: 'fa fa-pencil',
					command: (event: any) => {
						this.changeSelectedItem.emit(entityInstance);
						this.changeDisplayMode.emit(
							AppConstants.displayMode.update);
						event.stopPropagation();
					}
				});
		}

		return <ICommonListItem<IWorkItem>>
			{
				item: entityInstance,
				descriptionLineFormat:
					`<i class="fa fa-fw fa-calendar-check-o ${statusColor} `
						+ 'item-description-icon"></i>'
						+ priorityIcon
						+ `**${displayType}** <br>`
						+ `${entityInstance.data.description} `
						+ '<span class="visually-hidden">'
						+ `|${WorkItemConstants
							.workItemIdentifiers.workItemIdentifier}:`
							+ `${entityInstance.id}|`
						+ '</span>',
				informationLineFormat:
					`Status: ${entityInstance.data.status} - `
						+ `Priority: ${entityInstance.data.priority} `
						+ (!AnyHelper.isNullOrWhitespace(dueDate)
							? ` - Due Date: ${dueDate}`
							: AppConstants.empty),
				informationIcons: [],
				actions: authorizedActions
			};
	}

	/**
	 * Determines if the user can create any items.
	 *
	 * @returns {boolean}
	 * The result true if user can create.
	 * @memberof WorkItemListComponent
	 */
	private canCreate(): boolean
	{
		const createableTypes = this.securityDefinitions
			.filter((definition: ISecurityEntityTypeDefinition) =>
				SecurityHelper.actionAuthorized(
					definition.entityTypeName,
					AppConstants.apiMethods.create,
					[definition]))
			.map((definition: ISecurityEntityTypeDefinition) =>
				definition.entityTypeName);

		return  this.supportedEntityTypes
			.filter((entityType: EntityType) =>
				createableTypes.includes(entityType.name))
			.length > 0;
	}
}