/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	EntityManagerDirective
} from '@admin/directives/entity-manager.directive';
import {
	Component,
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	ActivatedRoute
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	DateTime
} from 'luxon';

@Component({
	selector: 'app-entity-definitions',
	templateUrl: './entity-definitions.component.html',
	styleUrls: [
		'./entity-definitions.component.scss'
	]
})

/**
 * A component representing an instance of the entity definitions
 * component.
 *
 * @export
 * @class EntityDefinitionsComponent
 * @extends {EntityManagerDirective}
 */
export class EntityDefinitionsComponent
	extends EntityManagerDirective
{
	/**
	 * Creates an instance of an EntityDefinitionsComponent. This component
	 * will display a filtered list of entities for navigation.
	 *
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type service used to populate get the entity type data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity instance service used to populate the entity definition data.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The entity instance service used to populate the entity version data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityDefinitionsComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public entityTypeApiService: EntityTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityVersionApiService: EntityVersionApiService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(
			route,
			activityService,
			resolver);
	}

	/**
	 * Gets the first level key properties.
	 *
	 * @type {{key: string, type: string}[]}
	 * @memberof EntityDefinitionsComponent
	 */
	private readonly firstLevelKeyProperties:
		{
			key: string;
			type: string;
		}[] =
			[
				{
					key: '$id',
					type: AppConstants.propertyTypes.string
				},
				{
					key: 'title',
					type: AppConstants.propertyTypes.string
				},
				{
					key: 'additionalProperties',
					type: AppConstants.propertyTypes.boolean
				},
				{
					key: 'properties',
					type: AppConstants.propertyTypes.object
				},
				{
					key: 'required',
					type: AppConstants.propertyTypes.object
				},
				{
					key: 'supportedChildTypes',
					type: AppConstants.propertyTypes.object
				},
				{
					key: 'requiresParent',
					type: AppConstants.propertyTypes.boolean
				}
			];

	/**
	 * Gets the property level key properties.
	 *
	 * @type {string[]}
	 * @memberof EntityDefinitionsComponent
	 */
	private readonly propertyLevelKeyProperties: string[] =
		[
			'id',
			'data',
			'entityType',
			'resourceIdentifier',
			'versionNumber',
			'createDate',
			'changeDate',
			'changedById'
		];

	/**
	 * Sets the Formly Definitions.
	 *
	 * @async
	 * @memberof EntityManagerDirective
	 */
	public async setFormlyDefinitions(): Promise<void>
	{
		await this.setContextData();
		this.updateLastSavedData();
		await this.setLayoutFields();

		this.loadingDefinitions = false;
	}

	/**
	 * Saves the updated entity data.
	 *
	 * @async
	 * @memberof EntityDefinitionsComponent
	 */
	public async saveAction(): Promise<void>
	{
		const entityDefinitionDataObject: IEntityDefinition =
			<IEntityDefinition>
			{
				id: this.entityDefinition.id,
				typeId: this.entityDefinition.typeId,
				versionId: this.entityDefinition.versionId,
				jsonData: this.contextData.data.definition,
				createDate: this.entityDefinition.createDate,
				startDate: this.entityDefinition.startDate,
				endDate: this.entityDefinition.endDate
			};

		const entityVersionDataObject: IEntityVersion =
			<IEntityVersion>
			{
				id: this.entityVersion.id,
				typeId: this.entityVersion.typeId,
				number: this.entityVersion.number,
				description: this.entityVersion.description,
				startDate: this.contextData.data.startDate,
				endDate: this.contextData.data.endDate,
				enabled: this.contextData.data.enabled,
				public: this.contextData.data.versionData.public,
				ownershipSecurityGroupId:
					this.contextData.data.versionData.ownershipSecurityGroupId,
				createdById: this.entityVersion.createdById
			};

		const entityTypeDataObject: IEntityType =
			<IEntityType>
			{
				id: this.entityType.id,
				name: this.entityType.name,
				group: this.entityType.group,
				public: this.contextData.data.public,
				ownershipSecurityGroupId:
					this.contextData.data.ownershipSecurityGroupId,
				createdById: this.entityType.createdById
			};

		await this.entityDefinitionApiService
			.update(
				this.entityDefinition.id,
				entityDefinitionDataObject);

		await this.entityVersionApiService
			.update(
				this.entityVersion.id,
				entityVersionDataObject);

		await this.entityTypeApiService
			.update(
				this.entityType.id,
				entityTypeDataObject);
	}

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityDefinitionsComponent
	 */
	public async setContextData(): Promise<void>
	{
		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityVersion =
			await this.entityVersionApiService.
				get(this.entityDefinition.versionId);

		this.entityType =
			await this.entityTypeApiService.
				get(this.entityDefinition.typeId);

		this.contextData =
			<any>
			{
				data: {
					name: this.entityType.name,
					group: this.entityType.group,
					version: this.entityVersion.number,
					enabled: this.entityVersion.enabled,
					startDate: this.entityVersion.startDate,
					endDate: this.entityVersion.endDate,
					definition: this.entityDefinition.jsonData,
					public: this.entityType.public,
					ownershipSecurityGroupId:
						this.entityType.ownershipSecurityGroupId,
					versionData: {
						public: this.entityVersion.public,
						ownershipSecurityGroupId:
							this.entityVersion.ownershipSecurityGroupId
					}
				}
			};

		this.saveTitle = 'Entity Definition';
		this.saveContent = `Entity Definition ${this.contextData.data.name}`;
	}

	/**
	 * Sets the formly layout fields.
	 *
	 * @async
	 * @memberof EntityDefinitionsComponent
	 */
	public async setLayoutFields(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions();

		this.layoutFields =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.name',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Name',
						disabled: true
					}
				},
				{
					key: 'data.group',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Group',
						disabled: true
					}
				},
				{
					key: 'data.version',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Version',
						disabled: true
					}
				},
				{
					key: 'data.enabled',
					type: FormlyConstants.customControls.customInputSwitch,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Enabled',
						required: true,
						default: false
					}
				},
				{
					...CommonFormlyFieldConstants
						.publicField,
					props: {
						...CommonFormlyFieldConstants
							.publicField
							.props,
						label: 'Type '
							+ CommonFormlyFieldConstants
								.publicField
								.props
								.label
					}
				},
				{
					...CommonFormlyFieldConstants
						.ownershipSecurityGroupField,
					props: {
						...CommonFormlyFieldConstants
							.ownershipSecurityGroupField
							.props,
						options: securityGroupOptions,
						label: 'Type '
							+ CommonFormlyFieldConstants
								.ownershipSecurityGroupField
								.props
								.label
					}
				},
				{
					...CommonFormlyFieldConstants
						.publicField,
					key: 'data.versionData.public',
					props: {
						...CommonFormlyFieldConstants
							.publicField
							.props,
						label: 'Version '
							+ CommonFormlyFieldConstants
								.publicField
								.props
								.label
					}
				},
				{
					...CommonFormlyFieldConstants
						.ownershipSecurityGroupField,
					key: 'data.versionData.ownershipSecurityGroupId',
					props: {
						...CommonFormlyFieldConstants
							.ownershipSecurityGroupField
							.props,
						options: securityGroupOptions,
						label: 'Version '
							+ CommonFormlyFieldConstants
								.ownershipSecurityGroupField
								.props
								.label
					}
				},
				{
					key: 'data.startDate',
					type: FormlyConstants.customControls.customCalendar,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Start Date',
						required: true,
						change: (field: any) =>
						{
							setTimeout(
								() =>
									field.formControl.parent.controls.endDate
										.updateValueAndValidity(),
								AppConstants.time.fiftyMilliseconds);
						}
					}
				},
				{
					key: 'data.endDate',
					type: FormlyConstants.customControls.customCalendar,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'End Date'
					},
					validators: {
						validEndDate: {
							expression: ((
								control: UntypedFormControl) =>
								this.validEndDate(
									control)),
							message: 'Can not be equal or less than Start Date.'
						}
					},
				},
				{
					key: 'data.definition',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Definition',
						rows: FormlyConstants.textAreaRowSizes.large,
						required: true
					},
					validators: {
						validDefinition: {
							expression: ((
								control: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.definitionValidator(
									control,
									field)),
							message:
								AppConstants.empty
						}
					}
				}
			];
	}

	/**
	 * Validates the definition is a correct input.
	 * This will check first, second and third level
	 * properties required are existing, the input is a valid
	 * JSON object and each property required is the expected type.
	 *
	 * @param {FormControl} control
	 * The form control to get the input value.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof EntityDefinitionsComponent
	 */
	public definitionValidator(
		control: UntypedFormControl,
		field: FormlyFieldConfig): boolean
	{
		let jsonDefinition: any;

		// Checks if the input is a valid JSON object.
		try
		{
			jsonDefinition = JSON.parse(control.value);
		}
		catch
		{
			field.validators.validDefinition.message =
				'Not a valid JSON object.';

			return false;
		}

		// Checks that this has all the first level required keys.
		for (const firstLevelProperty of this.firstLevelKeyProperties)
		{
			let isExistingKey: boolean = false;

			for (const definition of Object.keys(jsonDefinition))
			{
				if (firstLevelProperty.key === definition)
				{
					isExistingKey = true;

					if (typeof jsonDefinition[definition]
						!== firstLevelProperty.type)
					{
						field.validators.validDefinition.message =
							`Property '${definition}'`
								+ ' is not a valid'
								+ ` ${firstLevelProperty.type}`
								+ ' format.';

						return false;
					}
				}
			}

			if (isExistingKey === false)
			{
				field.validators.validDefinition.message =
					'The first level key property'
						+ ` '${firstLevelProperty.key}' `
						+ 'is missing.';

				return false;
			}
		}

		// Checks that this has all property level required keys.
		for (const propertyLevelKey of this.propertyLevelKeyProperties)
		{
			let isExistingKey: boolean = false;
			for (const property of Object.keys(jsonDefinition.properties))
			{
				if (propertyLevelKey === property)
				{
					isExistingKey = true;

					if (typeof jsonDefinition.properties[property]
						!== AppConstants.propertyTypes.object)
					{
						field.validators.validDefinition.message =
							`Property '${property}'`
								+ ' is not a valid'
								+ ` ${AppConstants.propertyTypes.object} `
								+ 'format.';

						return false;
					}
				}
			}

			if (isExistingKey === false)
			{
				field.validators.validDefinition.message =
					'The property level key property'
						+ ` '${propertyLevelKey}' `
						+ 'is missing.';

				return false;
			}
		}

		// Checks that each definition.property has the type key as required.
		for (const property of Object.keys(jsonDefinition.properties))
		{
			if (AnyHelper.isNullOrEmpty(
				jsonDefinition.properties[property].type))
			{
				field.validators.validDefinition.message =
					`${property} is missing the property 'Type'.`;

				return false;
			}

			if (typeof jsonDefinition.properties[property].type
				!== AppConstants.propertyTypes.string)
			{
				field.validators.validDefinition.message =
					`${property}.type must be a valid string format`;

				return false;
			}
		}

		return true;
	}

	/**
	 * Validates the end date is greater than start date.
	 *
	 * @param {FormControl} control
	 * The form control to get the input value.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof EntityDefinitionsComponent
	 */
	public validEndDate(
		control: UntypedFormControl): boolean
	{
		return AnyHelper.isNull(control.value)
			|| DateTime.fromISO(this.contextData.data.startDate)
				< DateTime.fromISO(control.value);
	}
}