/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	TimeUnit
} from 'chart.js';
import {
	DateTime
} from 'luxon';

/**
 * A class containing static helper methods for a chart interface.
 *
 * @export
 * @class ChartHelper
 */
export class ChartHelper
{
	/**
	 * Returns an array of time based labels signifying the sent day's available
	 * hours for data sets. This will run until the sent date's hour so a full
	 * day set would require a date with an hour of 23:00.
	 *
	 * @static
	 * @param {DateTime} day
	 * The date to get the current days hour labels for. This will be the
	 * 'final' hour value in this label display based on the existing date.
	 * @returns {DateTime[]}
	 * A predefined set of time based labels signifying the sent day values
	 * available hours of data. If the current time is sent, this will add
	 * a label for the current hour as the final label.
	 * @memberof ObjectArrayHelper
	 */
	public static getHourLabelsByDay(
		day: DateTime): DateTime[]
	{
		return ChartHelper.getTimeBasedLabels(
			day.startOf(
				ChartConstants.timeUnits.day),
			day.endOf(
				ChartConstants.timeUnits.hour)
				.plus({ second: 1 }),
			ChartConstants.timeUnits.hour,
			1);
	}

	/**
	 * Returns an array of time based labels signifying the 30 days previous
	 * to the sent day value, with a time unit of 'day' and a step size of 1.
	 *
	 * @static
	 * @param {DateTime} day
	 * The date to get the last 30 days of labels for. This will be the
	 * 'final' date value in this label display.
	 * @param {number} numberOfDays
	 * The number amount of days.
	 * @returns {DateTime[]}
	 * A predefined set of time based labels signifying the last 30 days
	 * from the sent day value with a step time unit of 'day' and a step
	 * size of 1.
	 * @memberof ObjectArrayHelper
	 */
	public static getLastNumberOfDayLabels(
		day: DateTime,
		numberOfDays: number): DateTime[]
	{
		const initialDayDateTime: DateTime =
			day.startOf(
				ChartConstants.timeUnits.day)
				.plus({ day: -numberOfDays });

		return this.getTimeBasedLabels(
			initialDayDateTime,
			initialDayDateTime
				.plus({ day: numberOfDays }),
			ChartConstants.timeUnits.day,
			1);
	}

	/**
	 * Returns an array of time based labels ready for consumption in a time
	 * based chart.
	 *
	 * @static
	 * @param {DateTime} initialTime
	 * The initial time value to begin gathering time based labels for.
	 * @param {DateTime} finalTime
	 * The final time value to finish gathering time based labels for.
	 * @param {TimeUnit} timeUnit
	 * The unit of time that each label should split down to.
	 * @see ChartConstants.timeUnits
	 * @param {number} timeUnitStepSize
	 * The step size per unit of time to increment for each label.
	 * Ie: '1' 'day' or '24' 'hour's.
	 * @returns {DateTime[]}
	 * A predefined set of time based labels starting from the initial time
	 * and running until the final time. Label 'steps' are defined by the time
	 * unit and step size.
	 * @memberof ObjectArrayHelper
	 */
	public static getTimeBasedLabels(
		initialTime: DateTime,
		finalTime: DateTime,
		timeUnit: TimeUnit,
		timeUnitStepSize: number): DateTime[]
	{
		let nextDateTime: DateTime =
			initialTime.toLocal()
				.startOf(
					timeUnit);
		const finalDateTime: DateTime =
			finalTime.toLocal()
				.startOf(
					timeUnit);

		const labelSet: DateTime[] = [];
		while (nextDateTime <
			finalDateTime.startOf(timeUnit))
		{
			labelSet.push(nextDateTime);
			nextDateTime =
				nextDateTime
					.plus(
						JSON.parse(
							`{ "${timeUnit}": ${timeUnitStepSize} }`));
		}
		labelSet.push(finalDateTime);

		return labelSet;
	}
}