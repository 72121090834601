<div class="list-item-container ui-g ui-g-12">
	<div>
		<span class="data-value ellipsis-text">
			<label class="data-label">
				Name:
			</label>
			<span class="navigation-link cursor-pointer theme-color"
				(click)="navigateToOrganization()">
				{{context.data?.entityInstance.data?.name?.legalName}}
			</span>
		</span>
		<br />
		<span class="data-value ellipsis-text">
			<label class="data-label">
				Corporation Type:
			</label>
			{{context.data?.entityInstance.data?.taxInformation?.type}}
		</span>
		<br/>
		<span class="data-value ellipsis-text">
			<label class="data-label">
				Alias:
			</label>
			{{context.data?.entityInstance.data?.name?.doingBusinessAs}}
		</span>
		<br/>
		<span class="data-value ellipsis-text">
			<label class="data-label">
				Location:
			</label>
			{{context.data?.entityInstance.data?.domicileState}}
		</span>
	</div>
</div>