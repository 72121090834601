/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import {
	DisplayComponentParameterDirective
} from '@shared/directives/display-component-parameter.directive';
import {
	IDashboardSection
} from '@shared/interfaces/application-objects/dashboard-section.interface';
import {
	IDashboardWidget
} from '@shared/interfaces/application-objects/dashboard-widget.interface';

@Component({
	selector: 'app-dashboard-section',
	templateUrl: './dashboard-section.component.html',
	styleUrls: [
		'./dashboard-section.component.scss'
	]
})

/**
 * A component representing a dashboard section.
 *
 * @export
 * @class DashboardSectionComponent
 * @extends {DisplayComponentParameterDirective}
 */
export class DashboardSectionComponent
	extends DisplayComponentParameterDirective
	implements OnChanges
{
	/**
	 * Gets or sets the input value sent from the dashboard
	 * that all sections and widgets have been displayed.
	 *
	 * @type {number}
	 * @memberof DashboardSectionComponent
	 */
	@Input() public dashboardDisplayCompleted: boolean = false;

	/**
	 * Gets or sets the configuration for this dashboard section.
	 *
	 * @type {IDashboardSection}
	 * @memberof DashboardSectionComponent
	 */
	@Input() public section: IDashboardSection;

	/**
	 * Gets or sets the event emitter that will bubble up to listening
	 * components the applied parameters event.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DashboardSectionComponent
	 */
	@Output() public sectionParametersApplied: EventEmitter<void> =
		new EventEmitter();

	/**
	 * Gets or sets the event emitter that will bubble up to listening
	 * components that all widgets in this section have been displayed.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DashboardSectionComponent
	 */
	@Output() public sectionFullyDisplayed: EventEmitter<void> =
		new EventEmitter();

	/**
	 * Gets or sets the count of currently displayed widgets in this
	 * section.
	 *
	 * @type {number}
	 * @memberof DashboardSectionComponent
	 */
	private displayedWidgetCount: number = 0;

	/**
	 * Handles the on changes event.
	 * This event will watch for changes to the authorized widget count of this
	 * section and check if all widgets are displayed with the new value.
	 *
	 *
	 * @param {SimpleChanges} changes
	 * The change event sent to this on changes handler.
	 * @memberof DashboardSectionComponent
	 */
	public ngOnChanges(
		changes: SimpleChanges): void
	{
		if (changes.section?.previousValue?.authorizedWidgetCount
			!== changes.section?.currentValue?.authorizedWidgetCount)
		{
			setTimeout(
				() =>
				{
					this.widgetComponentDisplayed();
				});
		}
	}

	/**
	 * Handles a click from the section parameter component.
	 *
	 * @override
	 * @async
	 * @memberof DashboardSectionComponent
	 */
	public async applyParameters(): Promise<void>
	{
		this.handleChildParameterData(
			this.section.widgets,
			this.section.parameterLayoutSchema,
			this.section.parameterLayoutData);

		this.bubbleAppliedWidgetParameters();
	}

	/**
	 * Emits the section parameters applied event to the listening
	 * dashboard component.
	 *
	 * @memberof DashboardSectionComponent
	 */
	public bubbleAppliedWidgetParameters(): void
	{
		this.sectionParametersApplied.emit();
	}

	/**
	 * Handles the widget component's displayed message and when
	 * all widgets of this section are displayed this will emit
	 * to the listening component that the section has been
	 * displayed.
	 *
	 * @memberof DashboardSectionComponent
	 */
	public widgetComponentDisplayed(): void
	{
		if (++this.displayedWidgetCount ===
			this.section.authorizedWidgetCount)
		{
			this.sectionFullyDisplayed.emit();
		}
	}

	/**
	 * Handles a change in a section widget by updating the existing array
	 * to point to this new value.
	 *
	 * @param {IDashboardWidget} dashboardWidget
	 * The altered dashboard widget.
	 * @memberof DashboardSectionComponent
	 */
	public widgetChanged(
		dashboardWidget: IDashboardWidget): void
	{
		const existingIndex: number =
			this.section.widgets.findIndex(
				(sectionWidget: IDashboardWidget) =>
					sectionWidget.displayComponentInstance.id ===
						dashboardWidget.displayComponentInstance.id);

		this.section.widgets[existingIndex] =
			{...dashboardWidget};
	}
}