<div *ngIf="loadingTableDefinition"
	class="text-center ui-g-12">
	<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
	</span>
</div>

<div class="ui-g-12 no-padding"
	*ngIf="!loadingTableDefinition">
	<ng-template
		[ngIf]="context.source.selectedItem.commissions.length > 0"
		[ngIfElse]="NoCommissions">
		<div class="ui-g-12 table-container">
			<app-common-table [tableDefinitions]="commonTable">
			</app-common-table>
		</div>
	</ng-template>
	<ng-template #NoCommissions>
		<div class="ui-g-12 panel-sub-title text-center">
			No commissions for this disbursement.
		</div>
	</ng-template>
</div>