<ng-template #SingleLevelView
	[ngIf]="tabItems.length === 0"
	[ngIfElse]="MultiLevelView">
	<p-tabMenu
		[model]="sectionItems"
		[activeItem]="activeSectionItem">
		<ng-template
			pTemplate="sectionItems"
			let-sectionItem
			let-i="index">
			<div (click)="sectionMenuItemClicked()">
				<div class="p-menuitem-icon double-font-size"
					[ngClass]="sectionItem.icon"
					*ngIf="sectionItem.icon">
				</div>
				<a class="btn btn-link"
					[ngClass]="{
						'active-tab': (activeSectionItem != null && sectionItem.label === activeSectionItem.label)
					}">
					{{sectionItem.label}}
				</a>
			</div>
		</ng-template>
	</p-tabMenu>
</ng-template>
<ng-template #MultiLevelView>
	<p-tabView
		(onChange)="tabSelected($event)">
		<ng-template ngFor
			let-tabItem
			[ngForOf]="tabItems">
			<p-tabPanel [header]="tabItem.label"
				[selected]="tabItem === activeTabItem">
				<p-tabMenu
					[model]="tabItem.items"
					[activeItem]="activeSectionItem">
					<ng-template
						pTemplate="sectionItems"
						let-sectionItem
						let-i="index">
						<div (click)="sectionMenuItemClicked()">
							<div class="p-menuitem-icon double-font-size"
								[ngClass]="sectionItem.icon"
								*ngIf="sectionItem.icon">
							</div>
							<a class="btn btn-link"
								[ngClass]="{
									'active-tab': (activeSectionItem != null && sectionItem.label === activeSectionItem.label)
								}">
								{{sectionItem.label}}
							</a>
						</div>
					</ng-template>
				</p-tabMenu>
			</p-tabPanel>
		</ng-template>
	</p-tabView>
</ng-template>