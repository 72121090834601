/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'create-transaction',
	templateUrl: './create-transaction.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard step for creating a policy transaction.
 *
 * @export
 * @class CreateTransactionComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class CreateTransactionComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the create transaction component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used to lookup insurance modules.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityLayoutApiService} entityLayoutApiService
	 * The entity layout API service.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The entity layout type API service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof CreateTransactionComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public insuranceService: InsuranceService,
		public entityService: EntityService,
		public entityLayoutApiService: EntityLayoutApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof CreateTransactionComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof CreateTransactionComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the entity type to be created.
	 *
	 * @type {IEntityType}
	 * @memberof CreateTransactionComponent
	 */
	public entityCreationType: IEntityType;

	/**
	 * Gets or sets a string representing the parent type group.
	 *
	 * @type {string}
	 * @memberof CreateTransactionComponent
	 */
	private parentTypeGroup: string;

	/**
	 * Gets or sets a number representing the parent id.
	 *
	 * @type {number}
	 * @memberof CreateTransactionComponent
	 */
	private parentId: number;

	/**
	 * Gets or sets the product name associated to this transaction.
	 *
	 * @type {string}
	 * @memberof CreateTransactionComponent
	 */
	private productName: string;

	/**
	 * Gets or sets the transaction entity group associated to the current data.
	 *
	 * @type {string}
	 * @memberof CreateTransactionComponent
	 */
	private transactionEntityGroup: string;

	/**
	 * Gets or sets the transaction entity name associated to the current data.
	 *
	 * @type {string}
	 * @memberof CreateTransactionComponent
	 */
	private transactionEntityName: string;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof CreateTransactionComponent
	 */
	private currentData: any;

	/**
	 * Gets or sets the transaction id.
	 *
	 * @type {number}
	 * @memberof CreateTransactionComponent
	 */
	private transactionId: number;

	/**
	 * Implements the on initialization interface.
	 *
	 * @memberof CreateTransactionComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.setParentEntityInformation();

		this.context.source
			.addToNext(
				this.create.bind(this));

		if (this.context.data?.displayedWizardSteps[1]?.alwaysVerify != null) {
			this.context.data.displayedWizardSteps[1].alwaysVerify = true;
		}

		this.context.source.activeWizardStep.validator =
			() => (!AnyHelper.isNullOrEmpty(
				this.dynamicFormlyLayout[0]?.formControl?.value)
					&& !AnyHelper.isNullOrEmpty(
						this.dynamicFormlyLayout[1]?.formControl?.value)
					&& !AnyHelper.isNullOrEmpty(
						this.dynamicFormlyLayout[2]?.formControl?.value)
					&& !AnyHelper.isNullOrEmpty(
						this.dynamicFormlyLayout[3]?.formControl?.value));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof CreateTransactionComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the transaction creation event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof CreateTransactionComponent
	 */
	public async create(): Promise<void>
	{

		this.productName =
			this.currentData.data.productName;

		this.transactionEntityGroup =
			`PolicyTermTransaction.${this.productName}`;

		this.transactionEntityName =
			`PolicyTermTransaction.${this.productName}`;

		this.entityCreationType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Group eq '${this.transactionEntityGroup}' AND Name eq `
					+ `'${this.transactionEntityName}'`,
					AppConstants.empty);

		const applicantType =
			AnyHelper.isNullOrWhitespace(this.currentData.data.type)
				? 'Individual'
				: this.currentData.data.type;

		const transactionData: IEntityInstance =
			this.createTransactionData(
				this.entityCreationType.name,
				applicantType,
				this.currentData.data?.legalName,
				this.currentData.data?.legalStructure,
				this.currentData.data?.prefix,
				this.currentData.data?.firstName,
				this.currentData.data?.middleName,
				this.currentData.data?.lastName,
				this.currentData.data?.suffix,
				this.currentData.data?.effectiveDate,
				this.currentData.data?.termLength);

		this.transactionId =
			await this.createEntity(
				this.entityCreationType,
				transactionData);

		await this.navigateToQuickQuote(
			this.transactionId,
			this.entityCreationType.group);
	}

	/**
	 * Creates the Transaction entity intance data based on the collected
	 * data. This data will be saved on the creation process.
	 *
	 * @param {string} entityType
	 * The entity type to be created.
	 * @param {string} applicantType
	 *  The applicant type (Individual/Organization).
	 * @param {string} legalName
	 * The organization legal name.
	 * @param {string} legalStructure
	 *  The organization legal structure:
	 * (Corporation/Limited Liability Company/Sole Proprietorship/Non-Profit).
	 * @param {string} prefix
	 * The prefix.
	 * @param {string} firstName
	 *  The first name.
	 * @param {string} middleName
	 * The middle name.
	 * @param {string} lastName
	 *  The last name.
	 * @param {string} suffix
	 * The suffix.
	 * @param {string} effectiveDate
	 * The effective date.
	 * @param {string} termLength
	 * The term length.
	 * @memberof CreateTransactionComponent
	 */
	private createTransactionData(
		entityType: string,
		applicantType: string,
		legalName: string,
		legalStructure: string,
		prefix: string,
		firstName: string,
		middleName: string,
		lastName: string,
		suffix: string,
		effectiveDate: string,
		termLength: string): IEntityInstance
	{
		const transactionData: IEntityInstance =
			<IEntityInstance>
			{
				id: 0,
				entityType: entityType,
				versionNumber: null,
				data: {
					effectiveDate: effectiveDate,
					interests: [
						{
							type: 'NamedInsured',
							subType: 'Primary',
							characteristics: {
								name: {
									type: applicantType,
									legalName: applicantType === 'Organization'
										? legalName ?? null
										: null,
									legalStructure: applicantType ===
										'Organization'
										? legalStructure ?? null
										: null,
									prefix: applicantType === 'Individual'
										? prefix ?? null
										: null,
									firstName: applicantType === 'Individual'
										? firstName ?? null
										: null,
									middleName: applicantType === 'Individual'
										? middleName ?? null
										: null,
									lastName: applicantType === 'Individual'
										? lastName ?? null
										: null,
									suffix: applicantType === 'Individual'
										? suffix ?? null
										: null
								}
							}
						}
					],
					termLength: parseInt(
						termLength,
						AppConstants.parseRadix)
				}
			};

		return transactionData;
	}

	/**
	 * This will send the entity creation event.
	 *
	 * @param {IEntityType} entityCreationType
	 * The entity type to be created.
	 * @param {IEntityInstance} entityInstanceData
	 *  The entity instance data to be saved.
	 * @async
	 * @memberof CreateTransactionComponent
	 */
	private async createEntity(
		entityCreationType: IEntityType,
		entityInstanceData: IEntityInstance): Promise<number>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		const displayName: string =
			new EntityType(entityCreationType)
				.displayName;

		const newEntityId: number =
			await this.activityService.handleActivity<number>(
				new Activity<number>(
					this.createEntityInstance(
						entityCreationType,
						entityInstanceData),
					'<strong>Creating transaction</strong>',
					'<strong>Transaction created</strong>',
					`${displayName} has been created.`,
					`${displayName} has not been created.`));

		return newEntityId;
	}

	/**
	 * Creates an entity instance and all entity relationships.
	 *
	 * @param {IEntityType} entityCreationType
	 * The entity type to be created.
	 * @param {IEntityInstance} entityInstanceData
	 *  The entity instance data to be saved.
	 * @returns {Promise<number>}
	 * The id of the newly created entity.
	 * @memberof CreateTransactionComponent
	 */
	private async createEntityInstance(
		entityCreationType: IEntityType,
		entityInstanceData: IEntityInstance): Promise<number>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				entityCreationType.group;

		const createdEntityId: number =
			await this.entityInstanceApiService
				.createEntityInstance(
					entityInstanceData,
					this.parentTypeGroup,
					this.parentId,
					{
						productName: this.productName,
						accountNumber: this.currentData.data.accountNumber
					});

		return createdEntityId;
	}

	/**
	 * This will navigate to the quick quote using the entity id provided.
	 *
	 * @param {number} entityId
	 * The entity id to navigate.
	 * @param {string} group
	 *  The entity group associated to the navigation.
	 * @async
	 * @memberof CreateTransactionComponent
	 */
	private async navigateToQuickQuote(
		entityId: number,
		group: string)
	{
		this.moduleService.name =
			await this.entityService.getContextMenuModule(
				this.entityCreationType.name);

		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.context.source.storeData(
			this.currentData);

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.quickQuote
							})
				}
			});
	}

	// /**
	//  * Sets the parent entity information.
	//  *
	//  * @memberof CreateTransactionComponent
	//  */
	private setParentEntityInformation()
	{
		this.parentTypeGroup = 'Organization.Agencies';
		this.parentId =
			this.currentData.data.agencyId;
	}

	/**
	 * Validates products for a state.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @return {Promise<boolean>}
	 * The state validity.
	 * @async
	 * @memberof CreateTransactionComponent
	 */
	private async validState(field: FormlyFieldConfig): Promise<boolean>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.products;

		const products: any[] =
			await this.insuranceService.getActiveProductNamesByState(
				this.context.source.activeMenuItem.currentData.data.agencyId,
				field.formControl.value);

		if (AnyHelper.isNullOrEmptyArray(products))
		{
			field.asyncValidators.stateValidator.message =
				'No products exist for this state.';
		}

		return Promise.resolve(!AnyHelper.isNullOrEmptyArray(products));
	}

	/**
	 * Validates term lengths for a product.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @return {Promise<boolean>}
	 * The product validity
	 * @async
	 * @memberof CreateTransactionComponent
	 */
	private async validTermLength(
		field: FormlyFieldConfig): Promise<boolean>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.products;

		const termLengths: any[] =
			!AnyHelper.isNullOrEmpty(field.formControl.value)
				? await this.entityInstanceApiService.query(
					`name eq '${field.formControl.value}'`,
					AppConstants.empty)
				: [];

		if (AnyHelper.isNullOrEmptyArray(termLengths))
		{
			field.asyncValidators.productValidator.message =
				'No term lengths exist for this product.';
		}

		return Promise.resolve(!AnyHelper.isNullOrEmptyArray(termLengths));
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof CreateTransactionComponent
	 */
	private async performPostInitActions(): Promise<void>
	{

		const defaultEffectiveDateFunction: string =
			'return function () { return this.source.resolver.resolveStatic('
			+ '\"DateHelper\", \"startOf\", [ this.source.resolver.'
			+ 'resolveStatic(\"DateHelper\", \"getSystemDateTime\",'
			+ ' [ true ]), \"day\" ]).toISO(); }';

		const statePromise: string =
			'return async function() {return await this.source.resolver.'
			+'resolveInsurance(\'InsuranceService\')'
			+ '.getUniqueStates(this.source.activeMenuItem.'
			+ 'currentData.data.agencyId);}';

		const productPromise: string =
			'return async function(field){let stateControl='
			+ 'field.parent.fieldGroup[1];let options=[];'
			+ 'if(stateControl.formControl.value!=null)'
			+ '{options = await this.source.resolver.resolveInsurance('
			+ '\'InsuranceService\').getActiveProductNamesByState(this.source'
			+ '.activeMenuItem.currentData.data.agencyId, stateControl.'
			+ 'formControl.value);if(options.length==0){field.props.'
			+ 'disabled=true;field.props.placeholder=\"No Available Options\";'
			+ 'field.formControl.updateValueAndValidity();}'
			+ 'else if(options.length==1){field.props.disabled=false;'
			+ 'field.formControl.setValue(options[0]);'
			+ 'field.props.disabled=true;field.formControl.'
			+ 'updateValueAndValidity();field.props.change(field, event);}'
			+ 'else{field.props.disabled=false;field.props.placeholder='
			+ '\"Select an Option\";field.formControl.updateValueAndValidity();'
			+ '}}else{field.props.disabled = true;field.props.placeholder='
			+ '\"No Available Options\";}return options;}';

		const termLengthPromise: string =
			'return async function(field){let stateControl = field.parent.'
			+ 'fieldGroup[1];let productControl=field.parent.fieldGroup[2];'
			+ 'let options=[];if(productControl.formControl.value!=null'
			+ ' && stateControl.formControl.value != null){options = '
			+ 'await this.source.resolver.resolveInsurance('
			+ '\'InsuranceService\').getProductByName(productControl'
			+ '.formControl.value);if(options.data?.termLengths?.length==0)'
			+ '{field.props.disabled=true;field.props.placeholder='
			+ '\"No Available Options\";field.formControl.'
			+ 'updateValueAndValidity();}else if(options.data?.termLengths?'
			+ '.length==1){field.props.disabled=false;field.formControl.'
			+ 'setValue(String(options.data.termLengths[0].minutes));'
			+ 'field.props.disabled=true;field.formControl.'
			+ 'updateValueAndValidity();this.source.validStepChanged('
			+ 'this.source.activeWizardStep.validator());}else{'
			+ 'field.props.disabled=false;field.props.placeholder='
			+ '\"Select an Option\";field.formControl.'
			+ 'updateValueAndValidity();}return options.data.termLengths;}'
			+ 'else{field.props.disabled=true;field.props.placeholder='
			+ '\"No Available Options\";return options;}}';

		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.effectiveDate',
					type: FormlyConstants.customControls.customCalendar,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Effective Date',
						required: true,
						useDefaultFunction: true,
						default: defaultEffectiveDateFunction
					}
				},
				{
					key: 'data.state',
					type: FormlyConstants.customControls.customDataSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'State',
						description: 'The property state.',
						placeholder: AppConstants.placeholders.selectAnOption,
						showClear: true,
						required: true,
						dataPromise: statePromise,
						labelFunction: 'return function (item)'
							+' { return item; }',
						valueTemplate: '#{item}',
						change: () =>
						{
							const productControl =
								this.dynamicFormlyLayout[2];
							const termLengthControl =
								this.dynamicFormlyLayout[3];
							if (productControl?.props?.initializeDataOptions !=
								null
									&& termLengthControl?.props
										?.initializeDataOptions !=
											null)
							{
								termLengthControl
									.formControl
									.setValue(null);
								termLengthControl
									.formControl
									.updateValueAndValidity();
								productControl
									.formControl
									.setValue(null);
								productControl
									.formControl
									.updateValueAndValidity();
								productControl
									.props.disabled = true;
								productControl
									?.props
									?.initializeDataOptions();
								termLengthControl
									?.props
									?.initializeDataOptions();
							}
						}
					},
					asyncValidators: {
						stateValidator: {
							expression: (
								_formControl: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.validState(field),
							message:
								AppConstants.empty
						}
					}
				},
				{
					key: 'data.productName',
					type: FormlyConstants.customControls.customDataSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Product',
						placeholder: AppConstants.placeholders.selectAnOption,
						required: true,
						showClear: true,
						dataPromise: productPromise,
						labelFunction: 'return function (item)'
							+' { return item; }',
						valueTemplate: '#{item}',
						change: () =>
						{
							const termLengthControl =
								this.dynamicFormlyLayout[3];
							if (termLengthControl
								?.props
								?.initializeDataOptions !=
									null)
							{
								termLengthControl
									.formControl
									.setValue(null);
								termLengthControl
									.formControl
									.updateValueAndValidity();
								termLengthControl
									?.props
									?.initializeDataOptions();
							}
						}
                	},
					asyncValidators: {
						productValidator: {
							expression: (
								_formControl: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.validTermLength(field),
							message:
								AppConstants.empty
						}
					}
				},
				{
					key: 'data.termLength',
					type: FormlyConstants.customControls.customDataSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Term Length',
						placeholder: AppConstants.placeholders.selectAnOption,
						required: true,
						showClear: true,
                    	dataPromise: termLengthPromise,
                    	labelTemplate: '${item.name}',
                    	valueTemplate: '${item.minutes}'
                	}
            	}
			];
	}
}