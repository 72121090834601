/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	animate,
	state,
	style,
	transition,
	trigger
} from '@angular/animations';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	BaseOperationMenuDisplayDirective
} from '@operation/directives/base-operation-menu-display.directive';
import {
	DisplayAnimation
} from '@shared/app-animations';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	MenuItem
} from 'primeng/api';
import {
	ScrollPanel
} from 'primeng/scrollpanel';
import {
	Subject,
	debounceTime,
	distinctUntilChanged
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'operation-button-menu',
	templateUrl: './operation-button-menu.component.html',
	styleUrls: [
		'./operation-button-menu.component.scss'
	],
	animations: [
		DisplayAnimation,
		trigger('easeAnimation', [
			state('hidden', style({
				transform: 'translate(-50%, 5%)',
				opacity: 0
			})),
			state('visible', style({
				transform: 'translate(-50%, 0)',
				opacity: 1
			})),
			transition('visible => hidden',
				animate('195ms ease-out')),
			transition('hidden => visible',
				animate('195ms ease-in'))
		]),
		trigger('rotationAnimation', [
			state('default', style({
				transform: 'rotate(0)'
			})),
			state('rotated', style({
				transform: 'rotate(-180deg)'
			})),
			transition('rotated => default',
				animate('195ms ease-out')),
			transition('default => rotated',
				animate('195ms ease-in'))
		])
	]
})

/**
 * A component representing an instance of a dropdown
 * operation menu component.
 * @note This component was built to replicate the primeNG
 * split button component and will be consistent with the
 * split button display.
 *
 * @export
 * @class OperationButtonMenuComponent
 * @extends {BaseOperationMenuDisplayComponent}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class OperationButtonMenuComponent
	extends BaseOperationMenuDisplayDirective
	implements OnInit, OnDestroy
{
	/**
	 * Initializes a new instance of the operation button menu
	 * component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used for responsive layouts.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The change detector ref service.
	 * @memberof OperationButtonMenuComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetectorReference: ChangeDetectorRef)
	{
		super(siteLayoutService);
	}

	/**
	 * Gets or sets the use ellipsis value that when set as true will
	 * display the operation menu with the ellipsis button.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonMenuComponent
	 */
	@Input()
	public useEllipsis: boolean;

	/**
	 * Gets or sets the fixed overlay location which if sent will
	 * override location calculations.
	 *
	 * @type {string}
	 * @memberof OperationButtonMenuComponent
	 */
	@Input()
	public fixedOverlayLocation: string;

	/**
	 * Gets or sets the if wants to invert the colors.
	 *
	 * @type {string}
	 * @memberof OperationButtonMenuComponent
	 */
	@Input()
	public invertedColors: boolean;

	/**
	 * Gets if show the ellipsis in small size version.
	 *
	 * @type {string}
	 * @memberof OperationButtonMenuComponent
	 */
	@Input()
	public smallEllipsis: boolean;

	/**
	 * Gets or sets the menu item that will be displayed in the
	 * dropdown menu.
	 *
	 * @type {MenuItem}
	 * @memberof OperationButtonMenuComponent
	 */
	@Input()
	public operationGroup: MenuItem;

	/**
	 * Gets or sets the rounded right border truthy that can be applied to this
	 * button display.
	 *
	 * @type {MenuItem}
	 * @memberof OperationButtonMenuComponent
	 */
	@Input()
	public roundedRightBorder: boolean = false;

	/**
	 * Gets or sets the rounded left border truthy that can be applied to this
	 * button display.
	 *
	 * @type {MenuItem}
	 * @memberof OperationButtonMenuComponent
	 */
	@Input()
	public roundedLeftBorder: boolean = false;

	/**
	 * Gets or sets the dynamic component context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof OperationButtonMenuComponent
	 */
	 @Input()
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the operation button menu element reference.
	 *
	 * @type {ElementRef}
	 * @memberof OperationButtonMenuComponent
	 */
	@ViewChild('OperationButtonMenu', {static: true})
	 public operationMenu: ElementRef;

	/**
	 * Gets or sets the ellipsis button element reference.
	 *
	 * @type {ElementRef}
	 * @memberof OperationButtonMenuComponent
	 */
	@ViewChild('ellipsisButton')
	public ellipsisButton: ElementRef;

	/**
	 * Gets or sets the scroll panel element reference.
	 *
	 * @type {ElementRef}
	 * @memberof OperationButtonMenuComponent
	 */
	@ViewChild('ScrollPanel')
	public scrollPanel: ScrollPanel;

	/**
	 * Gets or sets the value used to specify whether the
	 * dropdown menu is currently active and displayed.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonMenuComponent
	 */
	public active: boolean = false;

	/**
	 * Gets or sets the calculated overlay location.
	 *
	 * @type {string}
	 * @memberof OperationButtonMenuComponent
	 */
	public calculatedOverlayLocation: string;

	/**
	 * Gets or sets the debounced value used to specify whether the
	 * dropdown menu is currently active. This debounce is used to
	 * handle delaying animation displays until it has confirmed
	 * the event was not a click on the dropdown menu.
	 *
	 * @type {Subject<boolean>}
	 * @memberof OperationButtonMenuComponent
	 */
	public activeChanged: Subject<boolean> = new Subject<boolean>();

	/**
	 * Gets or sets the value used to specify whether the
	 * dsiplayed operation menu should be reset.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonMenuComponent
	 */
	public resetMenu: boolean = false;

	/**
	 * Gets or sets the previous content width which is used when
	 * calculating overlay locations.
	 *
	 * @type {boolean}
	 * @memberof OperationButtonMenuComponent
	 */
	public previousContentWidth: number = 0;

	/**
	 * Gets or sets the active menu item of this operation menu.
	 *
	 * @type {MenuItem}
	 * @memberof OperationButtonMenuComponent
	 */
	public activeMenuItem: MenuItem = null;

	/**
	 * Gets or sets the displayed operation menu width. This is
	 * used in display based calculations.
	 *
	 * @type {number}
	 * @memberof OperationButtonMenuComponent
	 */
	private readonly overlayMenuWidth: number = 250;

	/**
	 * Gets or sets the debounce delay used for checking
	 * display values of the operation menu.
	 *
	 * @type {number}
	 * @memberof OperationButtonMenuComponent
	 */
	private readonly debounceDelay: number = 25;

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof OperationButtonMenuComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.setCalculatedOverlayLocation();
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close this list when an associated menu is
	 * opened, such as a navigation menu.
	 *
	 * @param {string} controlIdentifier
	 * The identifier of the control sending the close associated menus
	 * event.
	 * @memberof OperationButtonMenuComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(
		controlIdentifer: string): void
	{
		if (this.active === true
			&& controlIdentifer !== 'ellipsis-menu')
		{
			this.closeList();
		}
	}

	/**
	 * Implements the onInit interface.
	 * This method is used to set a debounce on the active
	 * changed value.
	 *
	 * @memberof OperationButtonMenuComponent
	 */
	public ngOnInit()
	{
		this.activeChanged.pipe(
			debounceTime(this.debounceDelay),
			distinctUntilChanged())
			.subscribe((newValue: boolean) =>
			{
				if (this.active === true
					&& newValue === false)
				{
					this.resetMenu = true;

					setTimeout(() =>
					{
						this.resetMenu = false;
					},
					this.debounceDelay);
				}

				this.handleSmallEllipsisLogic(newValue);
				this.active = newValue;
				this.changeDetectorReference.detectChanges();
			});
	}

	/**
	 * Handles logic for displaying a small ellipsis.
	 * This method is used to set the container to show at the top level
	 * if required.
	 *
	 * @memberof OperationButtonMenuComponent
	 */
	public handleSmallEllipsisLogic(
		active: boolean): void
	{
		if (this.smallEllipsis === true)
		{
			let parentElement: HTMLElement =
				this.ellipsisButton.nativeElement.parentElement;
			while (!AnyHelper.isNull(parentElement))
			{
				if (parentElement.classList.contains('action-column'))
				{
					parentElement.style.setProperty(
						'z-index',
						active === true
							? '1'
							: '0',
						'important');
					parentElement = null;
				}
				else
				{
					parentElement = parentElement.parentElement;
				}
			}
		}
	}

	/**
	 * Implements the on destroy interface.
	 * Completes any active subscriptions.
	 *
	 * @memberof OperationButtonMenuComponent
	 */
	public ngOnDestroy()
	{
		this.activeChanged.complete();
	}

	/**
	 * Handles the button click command which is sent when the
	 * dropdown button is clicked directly.
	 *
	 * @param {any} event
	 * The event that fired this.
	 * @memberof OperationButtonMenuComponent
	 */
	public buttonClick(
		event: any): void
	{
		this.operationGroup.items.forEach(
			item => item.expanded = false);

		if (!AnyHelper.isNull(event)
			&& this.active === false)
		{
			this.handleSmallEllipsisLogic(!this.active);
		}

		this.setCalculatedOverlayLocation(!this.active);
		this.activeChanged.next(!this.active);
	}

	/**
	 * Handles the menu item clicked on the operation menu button. This event is
	 * used to calculate the scroll panel height.
	 *
	 * @param {MenuItem} menuItem
	 * The clicked menu item on the operation menu button.
	 * @memberof OperationButtonMenuComponent
	 */
	public itemClicked(
		menuItem: MenuItem): void
	{
		if (!AnyHelper.isNull(this.activeMenuItem)
			&& this.activeMenuItem.id !== menuItem.id)
		{
			this.activeMenuItem.expanded = false;
		}

		this.activeMenuItem = menuItem;

		this.setOverlayScrollPanel();
	}

	/**
	 * Handles the close list event sent from the operation menu
	 * component.
	 *
	 * @memberof OperationButtonMenuComponent
	 */
	public closeList(): void
	{
		this.activeChanged.next(false);
	}

	/**
	 * Handles the close overlay event sent from the operation menu
	 * component. This is sent when an action item is clicked.
	 *
	 * @memberof OperationButtonMenuComponent
	 */
	public closeOverlays(): void
	{
		this.activeChanged.next(false);
	}

	/**
	 * Calculates the available screen space and defines the expected
	 * overlay location wne a button menu is clicked.
	 *
	 * @param {boolean} forceCalculation
	 * If sent and true, this will refresh and calculate a newly opened panel.
	 * This value defaults to false.
	 * @memberof OperationButtonMenuComponent
	 */
	public setCalculatedOverlayLocation(
		forceCalculation: boolean = false): void
	{
		if (!AnyHelper.isNullOrWhitespace(this.fixedOverlayLocation))
		{
			this.calculatedOverlayLocation = this.fixedOverlayLocation;
			this.setOverlayScrollPanel();

			return;
		}

		if (forceCalculation === false
			&& (this.previousContentWidth ===
				this.siteLayoutService.contentWidth
				|| AnyHelper.isNull(this.operationMenu)
				|| AnyHelper.isNull(this.operationMenu.nativeElement)))
		{
			return;
		}

		let calculatedOverlayLocation: string;
		const operationButtonClientContainer: DOMRect =
			this.operationMenu.nativeElement.getBoundingClientRect();

		const availableLeftDisplay: number =
			operationButtonClientContainer.right
				- AppConstants.staticLayoutSizes.standardPadding
				- (this.siteLayoutService.displayTabletView === true
					&& this.siteLayoutService.contentCssClass
						!== AppConstants.responsiveClasses.smallPhoneContent
					? AppConstants.staticLayoutSizes.collapsedContextMenuWidth
					: AppConstants.staticLayoutSizes.expandedContextMenuWidth)
				- (this.siteLayoutService.displayTabletView === true
					&& this.siteLayoutService.contentCssClass
						!== AppConstants.responsiveClasses.smallPhoneContent
					? 0
					: AppConstants.staticLayoutSizes.slimMenuWidth);
		const availableRightDisplay: number =
			this.siteLayoutService.contentWidth
				- operationButtonClientContainer.left
				- AppConstants.staticLayoutSizes.nestedContentPadding
				- AppConstants.staticLayoutSizes.standardPadding
				- AppConstants.staticLayoutSizes.utilityMenuWidth;
		const availableTopDisplay: number =
			operationButtonClientContainer.top
				- (this.siteLayoutService.displayTabletView === true
					? AppConstants.staticLayoutSizes.mobileHeaderHeight
					: 0);
		const availableBottomDisplay: number =
			this.siteLayoutService.windowHeight
				- operationButtonClientContainer.bottom;
		const useTopDisplay: boolean =
			availableTopDisplay > availableBottomDisplay;

		// Prioritize right
		if (availableRightDisplay > this.overlayMenuWidth)
		{
			calculatedOverlayLocation =
				useTopDisplay === true
					? AppConstants.overlayLocations.topRight
					: AppConstants.overlayLocations.bottomRight;
		}
		// Fall back to left
		else if (availableLeftDisplay > this.overlayMenuWidth)
		{
			calculatedOverlayLocation =
				useTopDisplay === true
					? AppConstants.overlayLocations.topLeft
					: AppConstants.overlayLocations.bottomLeft;
		}
		// Force center
		else
		{
			calculatedOverlayLocation =
				useTopDisplay === true
					? AppConstants.overlayLocations.topCenter
					: AppConstants.overlayLocations.bottomCenter;
		}

		this.calculatedOverlayLocation = calculatedOverlayLocation;
		this.previousContentWidth = this.siteLayoutService.contentWidth;
		this.setOverlayScrollPanel();
	}

	/**
	 * Sets the display overlay scroll panel value of this component.
	 *
	 * @memberof OperationButtonMenuComponent
	 */
	public setOverlayScrollPanel(): void
	{
		const additionalItems: number =
			this.activeMenuItem?.expanded === true
				? this.activeMenuItem.items?.length ?? 0
				: 0;

		const availableHeight: number =
			Math.floor(this.siteLayoutService.windowHeight
				* AppConstants.staticLayoutSizes
					.scrollPanelOverlayHeightPercent);

		const visibleItemsLength: number =
			this.operationGroup.items.filter((item: any) =>
				item.visible === undefined || item.visible).length;

		const requiredHeight: number =
			Math.ceil(
				(visibleItemsLength
					+ (additionalItems ?? 0))
					* AppConstants.staticLayoutSizes.operationMenuItemHeight);

		if (!AnyHelper.isNull(this.scrollPanel) &&
	 		availableHeight < requiredHeight)
		{
			const dynamicHeightContainer: HTMLElement =
				this.scrollPanel.el.nativeElement.querySelector(
					'.operation-button-menu-container');

			if (AnyHelper.isNull(dynamicHeightContainer))
			{
				return;
			}

			dynamicHeightContainer.style.setProperty(
				'height',
				availableHeight < requiredHeight
					? availableHeight + 'px'
					: requiredHeight + 'px',
				'important');
		}
	}
}