/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * An enum for designating file progress types.
 */
export enum FileProgressType
{
	/**
	 * An unhandled file action process.
	 *
	 * @type {string}
	 * @memberof FileProgressType
	 */
	Unhandled = 'Unhandled',

	/**
	 * A file is being created.
	 */
	CreatingEntity = 'CreatingEntity',

	/**
	 * A file entity has been created and a new id returned.
	 */
	EntityCreated = 'EntityCreated',

	/**
	 * A file entity is being updated.
	 */
	UpdatingEntity = 'UpdatingEntity',

	/**
	 * A file entity has been updataed (just the data).
	 */
	EntityUpdated = 'EntityUpdated',

	/**
	 * An upload has been initiated.
	 */
	UploadStarted = 'UploadStarted',

	/**
	 * Some or all bytes have been upload.
	 */
	UploadProgress = 'UploadProgress',

	/**
	 * a download of data has started.
	 */
	DownloadStarted = 'DownloadStarted',

	/**
	 * Some or all bytes have been downloaded..
	 */
	DownloadProgress = 'DownloadProgress',

	/**
	 * The file is being processed by the server.
	 */
	Processing = 'Processing',

	/**
	 * A success, filaure, etc... status has been returned.
	 */
	ResponseStatus = 'ResponseStatus',

	/**
	 * A final response has been returned.
	 */
	Response = 'Response',

	/**
	 *	The file operation is complete.
	 */
	Complete = 'Complete',

	/**
	 * There has been one or more errors.
	 */
	Error = 'Error'
}