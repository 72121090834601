<div *ngIf="loading" class="text-center ui-g-12">
	<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
	</span>
</div>

<div class="ui-g-12 no-padding text-center history-container"
	*ngIf="!loading">

	<ng-template #BackNavigation
		[ngIf]="displayMode === displayModes.view">
		<div class="ui-g-12 no-padding nested-display-header">
			<div class="ui-g-3 no-padding text-left back-container">
				<span class="text-link theme-color nested-display-back-container"
					(click)="displayMode = displayModes.timeline"
					*ngIf="displayMode === displayModes.view">
					<i class="fa fa-arrow-left nested-display-back-icon">
					</i>
					<span class="nested-display-back">
						Back
					</span>
				</span>
			</div>
		</div>
	</ng-template>

	<ng-template #Invalid
		[ngIf]="isValid() === false
			|| isDisplayModeAvailable() === false
			|| isOwnershipAllowed === false"
		[ngIfElse]="Content">
		<div class="ui-g-12 text-center">
			<div *ngIf="isOwnershipAllowed === true">
				This view is not currently available.
			</div>
			<div *ngIf="isOwnershipAllowed === false">
				<app-access-denied-content
					[drawerDisplay]="true"
					drawerName="History"
					[sessionIdentifier]="sessionIdentifier"
					[accessDeniedUrl]="accessDeniedUrl"
					[resources]="resources"
					[clientMessage]="clientMessage">
				</app-access-denied-content>
			</div>
		</div>
	</ng-template>

	<ng-template #Content>
		<ng-template #TimeLine
			[ngIf]="displayMode === displayModes.timeline">
			<app-history-timeline
				[keyDateEvents]="keyDateEvents"
				[keyActivityEvents]="keyActivityEvents"
				[changeEvents]="changeEvents"
				(itemSelected)="itemSelected($event)">
			</app-history-timeline>
		</ng-template>

		<ng-template #ViewTimelineItem
			[ngIf]="displayMode === displayModes.view">
			<div class="ui-g-12 no-vertical-padding">
				<ng-template #ViewDifferences
					[ngIf]="selectedItem.item?.previousHistoryRecord != null"
					[ngIfElse]="ViewKeyActivity">
					<div class="view-title nested-display-subtitle text-left ellipsis-text">
						View: Differences
					</div>
					<div class="secondary-title-display text-left ellipsis-text">
						Updated by {{selectedItem.item.updatedByUser}}
					</div>
					<app-differences-display
						[useMinimumWidth]="true"
						[objectName]="comparisonObjectName"
						[context]="context"
						[initialObject]="selectedItem.item.previousHistoryRecord"
						[comparisonObject]="selectedItem.item"
						[entityDefinition]="entityDefinition"
						[excludedDifferenceProperties]="excludedDifferenceProperties">
					</app-differences-display>
				</ng-template>
				<ng-template #ViewKeyActivity>
					<div class="view-title nested-display-subtitle text-left ellipsis-text">
						View: Key Activity
					</div>
					<div class="ui-g-12 no-padding text-left">
						<div class="panel-sub-title">
							{{ selectedItem.summary }}
						</div>
						<div>
							{{ selectedItem.reference }}
						</div>
					</div>
				</ng-template>
			</div>
		</ng-template>
	</ng-template>

</div>