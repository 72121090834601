/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'approve-claim-payment-claim',
	templateUrl: './approve-claim-payment-claim.component.html'
})

/**
 * A component representing a wizard step for approving
 * a claim payment.
 *
 * @export
 * @class ApproveClaimPaymentClaimComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class ApproveClaimPaymentClaimComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the approve claim payment claim component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {ClaimsService} claimsService
	 * The claims service used in this component.
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public claimsService: ClaimsService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the list of exposures that are displayed.
	 *
	 * @type {object[]}
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	public exposures: object[];

	/**
	 * Gets or sets the claim payment.
	 *
	 * @type {IEntityInstance}
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	public claimPayment: IEntityInstance;

	/**
	 * Gets or sets the claim payment id.
	 *
	 * @type {number}
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	private claimPaymentId: number;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	private currentData: any;

	/**
	 * Implements the on initialization interface.
	 *
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.claimPaymentId =
			this.currentData.data.id;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimPayments;

		this.claimPayment =
			await this.entityInstanceApiService.get(this.claimPaymentId);

		await this.setDisplayData();

		this.context.source.addToNext(this.confirmApproval.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * This will send the approve claim payment event and navigate to
	 * the claim payment.
	 *
	 * @async
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	public async confirmApproval(): Promise<void>
	{
		await this.approveClaimPayment();
		await this.navigateToClaimPayment(
			this.claimPaymentId,
			ClaimConstants.claimEntityTypeGroups.claimPayments);
	}

	/**
	 * Executes the approve claim payment process by executing the
	 * ApproveClaimPayment workflow action to update the claim payment status.
	 *
	 * @async
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	public async approveClaimPayment(): Promise<void>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimPayments;

		return this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimPaymentId,
					ClaimConstants.workflowActions.approveClaimPayment,
					null,
					null),
				'<strong>Approving Claim Payment</strong>',
				'<strong>Claim Payment Approved</strong>',
				'Claim Payment has been approved.',
				'Claim Payment has not been approved.'));
	}

	/**
	 * Loads the list of exposures that will be displayed.
	 *
	 * @async
	 * @returns {Promise<object[]>}
	 * A Promise to handle the completion of
	 * the asynchronous call.
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	public async loadExposures(): Promise<object[]>
	{
		if (this.exposures != null)
		{
			return this.exposures;
		}

		if (AnyHelper.isNullOrEmptyArray(
			this.claimPayment.data?.paymentItems))
		{
			return [];
		}

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimPayments;

		const claim: IEntityInstance[] =
			await this.entityInstanceApiService.getParents(
				this.claimPaymentId,
				AppConstants.empty,
				AppConstants.empty,
				null,
				1,
				ClaimConstants.claimEntityTypeGroups.claims);

		const ledger: IEntityInstance =
			await this.claimsService.getLedger(claim[0].id);

		const ledgerTransactionClaims: IEntityInstance[] =
			await this.claimsService.getLedgerTransactions(
				ledger.id);

		const paymentItems: any = this.claimPayment.data.paymentItems;

		this.exposures = [];

		for await (const item of paymentItems)
		{
			const paymentItemType: string = item.type;

			const exposureData: any =
				await claim[0].data.exposures.find(
					(exposure: any) =>
						exposure.resourceIdentifier === item.exposureId);

			const exposureIndex: number =
					await claim[0].data.exposures.findIndex(
						(exposure: any) =>
							exposure.resourceIdentifier === item.exposureId);

			const outstandingReserves: number =
				ledgerTransactionClaims.filter(
					transaction =>
						transaction.data.metadata.exposureId === item.exposureId
							&& transaction.data.type === paymentItemType)
					.reduce((sum: any, transaction: any) =>
						 sum + transaction.data.amount, 0);

			this.exposures.push(
				{
					exposureIndex: exposureIndex,
					exposure: exposureData.coverage,
					type: this.claimsService.getFriendlyTransactionTypeName(
						paymentItemType),
					approvedAmount: item.amount,
					remainingReserves:
						outstandingReserves - item.amount
				});
		}

		return this.exposures;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will set necessary display data.
	 *
	 * @async
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	private async setDisplayData(): Promise<void>
	{
		this.currentData.data.payee =
			this.claimPayment.data.payee;

		this.currentData.data.additionalPayees =
			this.claimPayment.data?.additionalPayees;

		this.currentData.data.totalPayment =
			this.claimPayment.data.paymentItems
				.reduce((sum: any, item: any) => sum + item.amount, 0);
	}

	/**
	 * This will navigate to the claim payment layout using the
	 * claim payment id provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim payment entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	private async navigateToClaimPayment(
		entityId: number,
		group: string): Promise<void>
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof ApproveClaimPaymentClaimComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					type: FormlyConstants.customControls.customTableDisplay,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						loadingTableDefinitions: false,
						tableDefinitions: {
							tableTitle: 'Exposures',
							hideSettings: true,
							objectSearch: {
								filter: AppConstants.empty,
								orderBy: 'exposureIndex desc',
								offset: 0,
								limit: 100,
								virtualIndex: 0,
								virtualPageSize: 5
							},
							availableColumns: [
								{
									dataKey: 'exposure',
									columnHeader: 'Exposure',
									displayOrder: 1
								},
								{
									dataKey: 'type',
									columnHeader: 'Type',
									displayOrder: 2
								},
								{
									dataKey: 'approvedAmount',
									columnHeader: 'Approved Amount',
									dataFormatType: 'Currency',
									displayOrder: 3
								},
								{
									dataKey: 'remainingReserves',
									columnHeader: 'Remaining Reserves',
									dataFormatType: 'Currency',
									displayOrder: 4
								}
							],
							apiPromise: this.loadExposures.bind(this)
						},
						gridColumns: 12
					}
				},
				<FormlyFieldConfig>
				{
					type: FormlyConstants.customControls.customDashboard,
					props: {
						dashboardDisplayComponentInstanceName:
							'ClaimApproveClaimPaymentDashboard'
					}
				}
			];
	}
}