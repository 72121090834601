/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */

import {
	Component,
	Injectable
} from '@angular/core';
import {
	IOperationDefinitionDto
} from '@api/interfaces/operations/operation-definition.dto.interface';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	IOperationAction
} from '@operation/actions/interfaces/operation-action.interface';
import {
	IOperationDefinition
} from '@operation/interfaces/operation-definition.interface';
import {
	IOperationGroupRelationship
} from '@operation/interfaces/operation-group-relationship.interface';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	DeactivateGuardComponent
} from '@shared/interfaces/guards/deactivate-guard-component.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	Observable
} from 'rxjs';

/* eslint-enable max-len */

/**
 * A class representing the common code for
 * all operation actions.
 *
 * @export
 * @class BaseOperationAction
 * @implements {IOperationAction}
 */
@Injectable()
export abstract class BaseOperationAction
implements IOperationAction
{
	/**
	 * Defines the constructor used for
	 * of the BaseOperationAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof BaseOperationAction
	 */
	public constructor(
		protected activityService: ActivityService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
	}

	/**
	 * Gets or sets the label for this operation action.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public label: string = '';

	/**
	 * Gets or sets the icon for this operation action.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public icon: string = '';

	/**
	 * Gets or sets the order of this operation in it's
	 * respective level.
	 *
	 * @type {number}
	 * @memberof BaseOperationAction
	 */
	public order: number = 0;

	/**
	 * Gets or sets the page context of the action.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof BaseOperationAction
	 */
	public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the raw badge promise if this operation implements a badge
	 * display.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public badgePromise: string = AppConstants.empty;

	/**
	 * Gets or sets the raw visible promise if this operation holds the visible
	 * parameter.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public visible: string = AppConstants.empty;

	/**
	 * Gets or sets the raw enabled promise if this operation holds the enabled
	 * parameter.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public enabled: string = AppConstants.empty;

	/**
	 * Gets or sets the raw disabled message promise if this operation
	 * holds the disabledMessagePromise parameter.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public disabledMessagePromise: string = AppConstants.empty;

	/**
	 * Gets or sets the post operation action by name to perform when
	 * an action is completed.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public postOperationAction: string = AppConstants.empty;

	/**
	 * Gets or sets the post operation promise sent as a string based
	 * function to be performed when an action is completed.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public postOperationPromise: string = AppConstants.empty;

	/**
	 * Gets or sets the allowed parameter entries
	 * as a comma delimited string. If set these
	 * can be tested with the validateParameters
	 * method.
	 *
	 * @type {string[]}
	 * @memberof BaseOperationAction
	 */
	public allowedEntries: string[];

	/**
	 * Gets or sets the prompt can deactivate guard.
	 *
	 * @type {boolean}
	 * @memberof BaseOperationAction
	 */
	public promptCanDeactivateGuard: boolean | string = false;

	/**
	 * Gets the array display delimiter for array based messages.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public readonly arrayDisplayDelimiter: string = '\', \'';

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public abstract operationName: string;

	/**
	 * Gets the message to be displayed when the operation
	 * has been completed successfully. If this value is null,
	 * no message will be displayed upon completion.
	 * This property should be defined in the implemented class if
	 * post operation messages are required.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public get operationSuccessMessage(): string
	{
		return null;
	}

	/**
	 * Gets the message to be displayed when the operation
	 * has not completed successfully. If this value is null,
	 * no message will be displayed upon completion.
	 * This property should be defined in the implemented class if
	 * post operation failure messages are required.
	 *
	 * @type {string}
	 * @memberof BaseOperationAction
	 */
	public get operationFailureMessage(): string
	{
		return null;
	}

	/**
	 * Gets the can deactivate operation.
	 *
	 * @type {boolean}
	 * @memberof BaseOperationAction
	 */
	public get canDeactivateOperation(): boolean
	{
		this.promptCanDeactivateGuard =
			!AnyHelper.isNullOrWhitespace(this.promptCanDeactivateGuard)
				? JSON.parse(<string>this.promptCanDeactivateGuard)
				: null;

		if (this.promptCanDeactivateGuard !== true
			|| AnyHelper.isNull(this.pageContext))
		{
			return true;
		}

		const pageSource: EntityInstanceComponent =
			<EntityInstanceComponent>
			this.pageContext.source;

		if (AnyHelper.isNull(pageSource.entityFormComponent))
		{
			return true;
		}

		const canDeactivate: boolean | Observable<boolean> =
			this.appCanDeactivateGuard
				.canDeactivate(
					<DeactivateGuardComponent>
					pageSource.entityFormComponent);

		return <boolean>canDeactivate;
	}

	/**
	 * Gets or Sets block interface.
	 *
	 * @type {boolean}
	 * @memberof BaseOperationAction
	 */
	public get blockInterface()
	{
		return this._blockInterface;
	}
	public set blockInterface(block: boolean | string)
	{
		this._blockInterface =
			!AnyHelper.isNullOrWhitespace(block)
				? JSON.parse(<string>block)
				: null;
	}

	/**
	 * Gets or sets the block interface.
	 *
	 * @type {boolean}
	 * @memberof BaseOperationAction
	 */
	private _blockInterface: boolean = null;

	/**
	 * Gets or Sets the unblock interface.
	 *
	 * @type {boolean}
	 * @memberof BaseOperationAction
	 */
	public get unblockInterface()
	{
		return this._unblockInterface;
	}
	public set unblockInterface(unblock: boolean | string)
	{
		this._unblockInterface =
			!AnyHelper.isNullOrWhitespace(unblock)
				? JSON.parse(<string>unblock)
				: null;
	}

	 /**
	 * Gets or sets the unblock interface.
	 *
	 * @type {boolean}
	 * @memberof BaseOperationAction
	 */
	private _unblockInterface: boolean = null;

	/**
	 * Executes post operation actions. This method
	 * can be defined in the implemented class if
	 * post operation actions are required.
	 *
	 * @async
	 * @memberof BaseOperationAction
	 */
	public async executePostOperationActions(): Promise<void>
	{
		if (!AnyHelper.isNullOrWhitespace(this.postOperationAction))
		{
			const operationDefinition: IOperationDefinitionDto =
				await this.operationDefinitionApiService
					.getSingleQueryResult(
						`${AppConstants.commonProperties.name} eq `
							+ `'${this.postOperationAction}'`,
						`${AppConstants.commonProperties.id} `
							+ `${AppConstants.sortDirections.descending}`);

			const postOperationDefinition: IOperationDefinition =
				await this.operationService
					.populateOperationDefinition(
						<IOperationDefinition>operationDefinition,
						<IOperationGroupRelationship>{},
						this.pageContext);

			await this.operationExecutionService
				.executeMappedOperation(
					postOperationDefinition);
		}
		else if (!AnyHelper.isNullOrWhitespace(this.postOperationPromise))
		{
			await this.createOperationPromise(
				this.postOperationPromise);
		}
	}

	/**
	 * Creates a context based operation promise from a raw string input.
	 *
	 * @param {string} promiseAsString
	 * The raw string value to be ran as an awaited promise.
	 * @memberof BaseOperationAction
	 */
	public createOperationPromise(
		promiseAsString: string): Promise<any>
	{
		const interpolation: string =
			StringHelper.interpolate(
				promiseAsString,
				this.pageContext);

		return StringHelper.transformToDataPromise(
			interpolation,
			this.pageContext);
	}

	/**
	 * Executes an operation action as defined in the
	 * implemented class.
	 *
	 * @returns {Promise<any>}
	 * This implementation can be overloaded to return a promise set of data.
	 * @memberof BaseOperationAction
	 */
	public abstract execute(): Promise<any>;

	/**
	 * Searches the allowed entries array to define
	 * if a parameter sent is not in the allowed
	 * collection. Throws an error if the input
	 * is not acceptable.
	 *
	 * @param {string} validationInput
	 * The input to be checked against the allowed entries.
	 * @memberof BaseOperationAction
	 */
	protected validateParameters(
		validationInput: string): void
	{
		if (this.allowedEntries != null
			&& this.allowedEntries.length > 0
			&& this.allowedEntries.indexOf(validationInput) === -1)
		{
			throw new Error(
				`'${validationInput}' is not an allowed parameter `
				+ `set for the action '${this.operationName}'. `
				+ 'The allowed parameter sets for this operation are ['
				+ `'${this.allowedEntries.join(this.arrayDisplayDelimiter)}'`
				+ ']');
		}
	}

	/**
	 * Validates the supplied input is not null or empty
	 * and throws an error if the input is not acceptable.
	 *
	 * @param {string} input
	 * The input to be checked for null or empty.
	 * @param {string} operationName
	 * The operation friendly name using this shared method.
	 * @param {string} label
	 * The label displayed for the null or empty property.
	 * @memberof BaseOperationAction
	 */
	protected validateNonEmptyParameter(
		input: string,
		operationName: string,
		label: string): void
	{
		if (AnyHelper.isNullOrEmpty(input))
		{
			throw new Error(
				`Unable to create the ${operationName} action with an empty `
				+ `${label}.`);
		}
	}
}