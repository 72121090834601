/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	JsonSchemaHelper
} from '@shared/helpers/json-schema.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IKeyValuePair
} from '@shared/interfaces/application-objects/key-value-pair.interface';
import {
	ISummaryDataPath
} from '@shared/interfaces/application-objects/summary-data-path';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	get
} from 'lodash-es';

/**
 * A class representing the available methods (business logic) for an
 * entity definition.
 *
 * @export
 * @class EntityDefinition
 * @implements {IEntityDefinition}
 */
export class EntityDefinition
implements IEntityDefinition
{
	/**
	 * Creates an instance of an EntityDefinition.
	 *
	 * @param {IEntityDefinition} iEntityDefinition
	 * The entity definition interface to create this new
	 * object from.
	 * @memberof EntityDefinition
	 */
	public constructor(
		public iEntityDefinition: IEntityDefinition)
	{
		Object.assign(this, iEntityDefinition);
	}

	/**
	 * Gets or sets the id.
	 *
	 * @type {number}
	 * @memberof EntityDefinition
	 */
	public id: number;

	/**
	 * Gets or sets the typeId.
	 *
	 * @type {number}
	 * @memberof EntityDefinition
	 */
	public typeId: number;

	/**
	 * Gets or sets the versionId.
	 *
	 * @type {number}
	 * @memberof EntityDefinition
	 */
	public versionId: number;

	/**
	 * Gets or sets the jsonData.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	public jsonData: string;

	/**
	 * Gets or sets the createDate.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	public createDate: string;

	/**
	 * Gets or sets the startDate.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	public startDate: string;

	/**
	 * Gets or sets the endDate.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	public endDate: string;

	/**
	 * Returns the parsed json object from the entity definition
	 * json data.
	 *
	 * @type {any}
	 * The json parsed json data object.
	 * @memberof EntityDefinition
	 */
	public get jsonEntityDefinition(): any
	{
		return JSON.parse(this.jsonData);
	}

	/**
	 * Returns the operation group name for the entity definition.
	 *
	 * @type {string}
	 * The json parsed value defining the primary operation menu
	 * button set.
	 * @memberof EntityDefinition
	 */
	public get operationGroupName(): string
	{
		return this.jsonEntityDefinition[
			this.operationGroupNameKey];
	}

	/**
	 * Returns the operation group name for the page level context
	 * menu.
	 *
	 * @type {string}
	 * The json parsed value defining the page level context menu
	 * navigation group.
	 * @memberof EntityDefinition
	 */
	public get pageContextOperationGroupName(): string
	{
		return this.jsonEntityDefinition[
			this.pageContextOperationGroupNameKey];
	}

	/**
	 * Returns the operation group name for the page level utility menu.
	 *
	 * @type {string}
	 * The json parsed value defining the page level utility menu group.
	 * @memberof EntityDefinition
	 */
	public get utilityMenuOperationGroupName(): string
	{
		return this.jsonEntityDefinition[
			this.utilityMenuOperationGroupNameKey];
	}

	/**
	 * Returns the operation group name for an action menu.
	 *
	 * @type {string}
	 * The json parsed value defining the action operation menu
	 * button set.
	 * @memberof EntityDefinition
	 */
	public get actionMenuOperationGroupName(): string
	{
		return this.jsonEntityDefinition[
			this.actionMenuOperationGroupNameKey];
	}

	/**
	 * Returns the information menu display component name for the entity
	 * definition.
	 *
	 * @type {string}
	 * The json parsed value defining the information menu display
	 * component.
	 * @memberof EntityDefinition
	 */
	public get informationMenuDisplayComponentInstanceName(): string
	{
		return this.jsonEntityDefinition[
			this.informationMenuDisplayComponentInstanceNameKey];
	}

	/**
	 * Returns the module associated with this entity
	 * definition.
	 *
	 * @type {string}
	 * The json parsed value defining the context menu module.
	 * @memberof EntityDefinition
	 */
	public get contextMenuModule(): string
	{
		return this.jsonEntityDefinition[
			this.contextMenuModuleKey];
	}

	/**
	 * Gets the supported child types of this definition.
	 *
	 * @type {string[]}
	 * The array of entity type names that are supported as children
	 * of this definition.
	 * @memberof EntityDefinition
	 */
	public get supportedChildTypes(): string[]
	{
		return this.jsonEntityDefinition.supportedChildTypes;
	}

	/**
	 * Gets the parent required value of this entity definition.
	 *
	 * @type {boolean}
	 * The truthy that defines if creating this entity requires a
	 * selected parent.
	 * @memberof EntityDefinition
	 */
	public get requiresParent(): boolean
	{
		return this.jsonEntityDefinition.requiresParent;
	}

	/**
	 * Gets the title function of this entity definition if set.
	 *
	 * @type {string}
	 * If defined this will return the title data promise which is used
	 * in page headers and other very descriptive entity locations.
	 * @memberof EntityDefinition
	 */
	public get titleFunction(): string
	{
		return this.jsonEntityDefinition.titleFunction;
	}

	/**
	 * Gets the title function of this entity definition if set.
	 *
	 * @type {string}
	 * If defined this will return the title data promise which is used
	 * in page headers and other very descriptive entity locations.
	 * @memberof EntityDefinition
	 */
	public get titlePromise(): string
	{
		return this.jsonEntityDefinition.titlePromise;
	}

	/**
	 * Gets the final title of this entity definition, this is
	 * defined as the final string following all '.' notation
	 * in the JSON title property.
	 *
	 * @type {string}
	 * The title property of this entity definition.
	 * @memberof EntityDefinition
	 */
	public get displayTitle(): string
	{
		return StringHelper.beforeCapitalSpaces(
			StringHelper.getLastSplitValue(
				this.jsonEntityDefinition.title,
				AppConstants.characters.period));
	}

	/**
	 * Gets the chat data promise if set.
	 *
	 * @type {string}
	 * If defined this will return the chat data promise which is used
	 * when opening the chat window.
	 * @memberof EntityDefinition
	 */
	public get chatDataPromise(): string
	{
		return this.jsonEntityDefinition.chatDataPromise;
	}

	/**
	 * Gets the summary data paths.
	 *
	 * @type {ISummaryDataPath[]}
	 * If defined this will return the set of summary data paths in the
	 * definition.
	 * @memberof EntityDefinition
	 */
	public get summaryDataPaths(): ISummaryDataPath[]
	{
		return this.jsonEntityDefinition.summaryDataPaths;
	}

	/**
	 * Gets the excluded difference properties.
	 *
	 * @type {string[]}
	 * If defined this will return the set of excluded differences to use
	 * when displaying history difference records.
	 * @memberof EntityDefinition
	 */
	public get excludedDifferenceProperties(): string[]
	{
		return this.jsonEntityDefinition.excludedDifferenceProperties;
	}

	/**
	 * Gets the defined properties as a key value pair object
	 * in sorted order. This data is a merge of the definition
	 * and data properties.
	 *
	 * @type {IKeyValuePair[]}
	 * The sorted key value pair definition of properties of this
	 * entity definition.
	 * @memberof EntityDefinition
	 */
	public get properties(): IKeyValuePair[]
	{
		return JsonSchemaHelper.getSchemaProperties(
			this.dereferencedDataProperties);
	}

	/**
	 * Gets the data properties object available in the
	 * entity definiton json. This uses the JRef Library
	 * to dereference JSON Schema #ref.
	 * https://libraries.io/npm/jref
	 *
	 * @type {object}
	 * A dereferenced json schema definition with resolved ref pointers as
	 * a flattened object.
	 * @memberof EntityDefinition
	 */
	public get dereferencedDataProperties(): object
	{
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const jref = require('jref');

		return jref.dereference(this.jsonEntityDefinition)
			.properties.data.properties;
	}

	/**
	 * Gets the key used to find the operation group name
	 * from the entity definition json.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	private readonly operationGroupNameKey: string = 'operationGroupName';

	/**
	 * Gets the key used to find the page context level operation group name
	 * from the entity definition json.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	private readonly pageContextOperationGroupNameKey: string =
		'contextMenuPage';

	/**
	 * Gets the key used to find the page context level operation group name
	 * from the entity definition json.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	private readonly utilityMenuOperationGroupNameKey: string =
		'utilityMenuOperationGroupName';

	/**
	 * Gets the key used to find the action menu operation group name
	 * from the entity definition json.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	private readonly actionMenuOperationGroupNameKey: string =
		'actionMenuOperationGroupName';

	/**
	 * Gets the key used to find the information menu display component name
	 * from the entity definition json.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	private readonly informationMenuDisplayComponentInstanceNameKey: string =
		'informationMenuDisplayComponentInstanceName';

	/**
	 * Gets the key used to find the module that this entity definition
	 * is associated with.
	 *
	 * @type {string}
	 * @memberof EntityDefinition
	 */
	private readonly contextMenuModuleKey: string =
		'contextMenuModule';

	/**
	 * Gets a per entity definition title for created items.
	 * This will use the summary value if available but fall back to
	 * a type and id display if not found.
	 *
	 * @param {IEntityIstance} item
	 * The entity instance to gather a summary defined title for.
	 * @returns {string}
	 * The created item title property of this entity definition.
	 * @memberof EntityDefinition
	 */
	public getItemTitle(item: IEntityInstance): string
	{
		const summaryTitle: string =
			this.summaryDataPaths?.length > 0
				? get(
					item,
					this.summaryDataPaths[0].path
						.replace(
							AppConstants.nestedDataKeyPrefix,
							AppConstants.empty))
				: null;

		return AnyHelper.isNullOrWhitespace(summaryTitle)
			? `${this.displayTitle} ${item.id}`
			: summaryTitle;
	}

	/**
	 * Gets the key activities description promise for this entity definition.
	 *
	 * @returns {string}
	 * The key activities description promise for the sent entity definition.
	 * @memberof JsonSchemaHelper
	 */
	public getKeyActivitiesDescriptionPromise(): string
	{
		return (<any>this.dereferencedDataProperties)
			?.keyActivities?.descriptionPromise;
	}

	/**
	 * Gets a property definition at the sent path.
	 *
	 * @param {string} path
	 * The property definition path.
	 * @returns {any}
	 * The property definition matching the sent path.
	 * @memberof EntityDefinition
	 */
	 public getPropertyDefinition(
		path: string): any
	{
		return this.properties
			.find(
				(item: IKeyValuePair) =>
					item.key === path);
	}

	/**
	 * Gets the summary data values.
	 *
	 * @param {IEntityInstance} entityInstance
	 * The entity instance of this definition type to get summary data values
	 * for.
	 * @returns {any[]}
	 * The set of summary data values matching the sent entity instance
	 * and this implementation of an entity definition.
	 * @memberof EntityDefinition
	 */
	 public getSummaryDataValues(
		entityInstance: IEntityInstance): any[]
	{
		const summaryDataValues: any[] = [];
		for (const dataPath of this.jsonEntityDefinition.summaryDataPaths)
		{
			summaryDataValues.push(
				get(
					entityInstance,
					dataPath.path.replace(
						AppConstants.nestedDataKeyPrefix,
						AppConstants.empty)));
		}

		return summaryDataValues;
	}

	/**
	 * Gets the summary data value.
	 *
	 * @param {IEntityInstance} entityInstance
	 * The entity instance of this definition type to get summary data values
	 * for.
	 * @param {number} summaryIndex
	 * The index to get a summary data value for.
	 * @returns {any}
	 * The summary data value matching the sent entity instance
	 * and this implementation of an entity definition at the sent
	 * summary index.
	 * @memberof EntityDefinition
	 */
	public getSummaryDataValue(
		entityInstance: IEntityInstance,
		summaryIndex: number): any
	{
		return get(
			entityInstance,
			this.jsonEntityDefinition.summaryDataPaths[summaryIndex]
				.path.replace(
					AppConstants.nestedDataKeyPrefix,
					AppConstants.empty));
	}
}