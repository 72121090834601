/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Directive
} from '@angular/core';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */

@Directive({
	selector: '[PageContext]'
})

/**
 * A directive representing shared logic for a component that will have a page
 * context available.
 *
 * @export
 * @class PageContextDirective
 */
export class PageContextDirective
{
	/**
	 * Creates an instance of a page context containing component.
	 *
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof PageContextDirective
	 */
	public constructor(
		public resolver: ResolverService)
	{
	}

	/**
	 * Gets the page context of this component.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof PageContextDirective
	 */
	public get pageContext(): IDynamicComponentContext<Component, any>
	{
		return <IDynamicComponentContext<Component, any>>
			{
				source: this,
				data: {}
			};
	}
}