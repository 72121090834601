/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'claim-payment-additional-payees',
	templateUrl: './claim-payment-additional-payees.component.html',
	styleUrls: [
		'./claim-payment-additional-payees.component.scss'
	]
})

/**
 * A component representing a claim payment additional payees.
 *
 * @export
 * @class ClaimPaymentAdditionalPayeesComponent
 * @implements {IDynamicComponent<Component, any>}
 * @implements {OnInit}
 */
export class ClaimPaymentAdditionalPayeesComponent
implements IDynamicComponent<Component, any>, OnInit
{
	/**
	 * Creates an instance of an claim payment additional payees
	 * component.
	 *
	 * @param {ClaimsService} claimsService
	 * The claims service for this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service for this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service for this component.
	 * @memberof ClaimPaymentAdditionalPayeesComponent
	 */
	public constructor(
		public claimsService: ClaimsService,
		public entityInstanceApiService: EntityInstanceApiService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ClaimPaymentAdditionalPayeesComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the claim payment instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof ClaimPaymentAdditionalPayeesComponent
	 */
	public claimPaymentInstance: IEntityInstance;

	/**
	 * Gets or sets the claim payment id.
	 *
	 * @type {number}
	 * @memberof ClaimPaymentAdditionalPayeesComponent
	 */
	public claimPaymentId: number;

	/**
	 * Gets or sets the additional payee names.
	 *
	 * @type {string[]}
	 * @memberof ClaimPaymentAdditionalPayeesComponent
	 */
	public additionalPayeeNames: string[] = [];

	/**
	 * Gets or sets the value signifying whether or not this component is
	 * loading.
	 *
	 * @type {boolean}
	 * @memberof ClaimPaymentAdditionalPayeesComponent
	 */
	public loading: boolean = true;

	/**
	 * Initiates the component to get the data object.
	 *
	 * @async
	 * @memberof ClaimPaymentAdditionalPayeesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.claimPaymentId =
			(<any>this.context.source)
				.entityContext?.source.activeMenuItem
				.currentData.data.id;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimPayments;

		this.claimPaymentInstance =
			await this.entityInstanceApiService.get(this.claimPaymentId);

		const additionalPayees: any =
			this.claimPaymentInstance.data?.additionalPayees;

		for (const payee of additionalPayees)
		{
			this.additionalPayeeNames.push(payee?.name);
		}

		this.loading = false;
		(<any>this.context.source).finishedLoading?.emit();
	}
}