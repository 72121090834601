<div class="ui-g-12 section-title"
	[attr.data-key]="field.key"
	[ngClass]="{
		'repeater-title': (field.fieldGroup),
		'top-section-title': topSectionTitle
	}"
	[id]="cleanLabelIdentifier">
	<span class="panel-sub-title section-title-text">
		{{field.props.label}}
		<span *ngIf="field.fieldGroup">
			({{getRepeaterItemCount()}})
		</span>
		<ng-container
			*ngIf="field.props.tooltip != null && field.props.tooltip.length > 0">
			<app-common-icon-tooltip
				[content]="field.props.tooltip"
				[field]="field">
			</app-common-icon-tooltip>
		</ng-container>
	</span>

	<span *ngIf="field.fieldGroup && !field.props.disabled"
		(click)="addItemAction()"
		class="text-link float-right section-title-action">
		<a>
			<i class="fa fa-plus-circle double-font-size">
			</i>
			<span class="section-title-action-text">
				Add {{field.props.singular}}
			</span>
		</a>
	</span>
</div>