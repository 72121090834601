/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */

import {
	HttpHeaders
} from '@angular/common/http';
import {
	Injectable,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	AuthenticateApiService
} from '@api/services/security/authenticate.api.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppTimeSpan
} from '@shared/app-timespan';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SystemServiceConstants
} from '@shared/constants/system-service.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	IChatSettings
} from '@shared/interfaces/application-objects/chat-settings';
import {
	IKnowledgeBaseSettings
} from '@shared/interfaces/application-objects/knowledge-base-settings';
import {
	INameValuePair
} from '@shared/interfaces/application-objects/name-value-pair.interface';
import {
	ISystemServiceConfiguration
} from '@shared/interfaces/application-objects/system-service-configuration';
import {
	ISystemSettings
} from '@shared/interfaces/application-objects/system-settings';
import {
	IWalkthroughSettings
} from '@shared/interfaces/application-objects/walkthrough-settings';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	ISecuritySession
} from '@shared/interfaces/security/security-session.interface';
import {
	IChatService
} from '@shared/interfaces/services/chat-service';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	UserService
} from '@shared/services/user.service';
import {
	Settings
} from 'luxon';
import {
	AppConfig
} from 'src/app/app.config';

/**
 * A class representing a Session Service.
 *
 * @export
 * @class SessionService
 * @implements {OnInit}
 */
@Injectable({
	providedIn: 'root'
})
export class SessionService
implements OnInit
{
	/**
	 * Creates an instance of SessionService.
	 *
	 * @param {ResolverService} resolver
	 * The resolver service for dependency injection.
	 * @param {AuthenticateApiService} authenticateApiService
	 * The authentication api service.
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The security group api service.
	 * @param {SecuritySessionApiService} securitySessionApiService
	 * The security session api service.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {DisplayComponentService} displayComponentService
	 * The display component service.
	 * @param {OperationService} operationService
	 * The operation service.
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {RuleService} ruleService
	 * The rule service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @param {Router} router
	 * The router used for navigation.
	 * @param {UserService} userService
	 * The user service.
	 * @memberof SessionService
	 */
	public constructor(
		public resolver: ResolverService,
		private readonly authenticateApiService: AuthenticateApiService,
		private readonly securityGroupApiService: SecurityGroupApiService,
		private readonly securitySessionApiService: SecuritySessionApiService,
		private readonly siteLayoutService: SiteLayoutService,
		private readonly displayComponentService: DisplayComponentService,
		private readonly operationService: OperationService,
		private readonly entityService: EntityService,
		private readonly ruleService: RuleService,
		private readonly entityInstanceApiService: EntityInstanceApiService,
		private readonly router: Router,
		private readonly userService: UserService)
	{
	}

	/**
	 * Gets or sets a value defining whether all operation local storage values
	 * are currently set.
	 *
	 * @type {boolean}
	 * @memberof SessionService
	 */
	public operationGroupsStored: boolean = false;

	/**
	 * Gets othe default client side service configuration used when no
	 * configurations exist.
	 *
	 * @type {ISystemServiceConfiguration}
	 * @memberof SessionService
	 */
	private readonly defaultServiceConfiguration: ISystemServiceConfiguration =
		{
			name: AppConstants.empty,
			settings: []
		};

	/**
	 * Gets a {string} representing the system time zone.
	 *
	 * @type {string}
	 * @memberof SessionService
	 */
	public get systemTimeZone(): string
	{
		const localSystemTimeZone: string =
			localStorage.getItem(
				AppConstants.storage.systemTimeZone);

		return (AnyHelper.isNullOrEmpty(localSystemTimeZone))
			? AppConstants.empty
			: localSystemTimeZone;
	}

	/**
	 * Sets a {string} representing the system time zone.
	 *
	 * @memberof SessionService
	 */
	public set systemTimeZone(
		value: string)
	{
		localStorage.setItem(
			AppConstants.storage.systemTimeZone,
			!AnyHelper.isNull(value)
				? value
				: null);
	}

	/**
	 * Gets an {ISystemSettings} representing the overall system settings
	 * object.
	 *
	 * @type {ISystemSettings}
	 * @memberof SessionService
	 */
	public get systemSettings(): ISystemSettings
	{
		const localSystemSettings: string =
			localStorage.getItem(
				AppConstants.storage.systemSettings);

		return (AnyHelper.isNullOrEmpty(localSystemSettings))
			? null
			: JSON.parse(
				localSystemSettings);
	}

	/**
	 * Sets an {ISystemSettings} representing the overall system settings
	 * object.
	 *
	 * @memberof SessionService
	 */
	public set systemSettings(
		value: ISystemSettings)
	{
		localStorage.setItem(
			AppConstants.storage.systemSettings,
			!AnyHelper.isNull(value)
				? JSON.stringify(value)
				: null);
	}

	/**
	 * Gets a value indicating whether the session is valid.
	 *
	 * @type {boolean}
	 * @memberof SessionService
	 */
	public get isValid(): boolean
	{
		return 'true' === (
			localStorage.getItem(
				AppConstants.storage.sessionValidKey)
			|| 'false');
	}

	/**
	 * Sets a value indicating whether the session is valid.
	 *
	 * @memberof SessionService
	 */
	public set isValid(
		value: boolean)
	{
		localStorage.setItem(
			AppConstants.storage.sessionValidKey,
			value.toString());
	}

	/**
	 * Gets an {IUser} of the logged in session user.
	 *
	 * @type {IUser}
	 * @memberof SessionService
	 */
	public get user(): IUser
	{
		const userStringified: string
			= localStorage.getItem(
				AppConstants.storage.securityUserKey);

		if (AnyHelper.isNullOrEmpty(userStringified))
		{
			return null;
		}

		return <IUser | null>JSON.parse(userStringified);
	}

	/**
	 * Sets an {IUser} to the session user.
	 *
	 * @memberof SessionService
	 */
	public set user(
		value: IUser)
	{
		localStorage.setItem(
			AppConstants.storage.securityUserKey,
			value ? JSON.stringify(value) : null);
	}

	/**
	 * Gets a {Date} value representing the expiry date and time of the session.
	 *
	 * @type {Date}
	 * @memberof SessionService
	 */
	public get expiryDate(): Date
	{
		const localExpiry: string =
			localStorage.getItem(
				AppConstants.storage.sessionExpiryKey);

		if (AnyHelper.isNullOrEmpty(localExpiry))
		{
			return new Date();
		}

		return new Date(localExpiry);
	}

	/**
	 * Sets a {Date} value representing the expiry date and time of the session.
	 *
	 * @memberof SessionService
	 */
	public set expiryDate(
		value: Date)
	{
		localStorage.setItem(
			AppConstants.storage.sessionExpiryKey,
			value
				? value.toString()
				: null);
	}

	/**
	 * Gets a {TimeSpan} representing the duration a session is valid.
	 *
	 * @type {AppTimeSpan}
	 * @memberof SessionService
	 */
	public get expiry(): AppTimeSpan
	{
		return new AppTimeSpan(
			localStorage.getItem(
				AppConstants.storage.securityExpiryKey)
			|| AppConstants.empty);
	}

	/**
	 * Sets a {TimeSpan} representing the duration a session is valid.
	 *
	 * @memberof SessionService
	 */
	public set expiry(
		value: AppTimeSpan)
	{
		localStorage.setItem(
			AppConstants.storage.securityExpiryKey,
			value ? value.toString() : null);
	}

	/**
	 * Gets a {string} representing the session security token.
	 *
	 * @type {string}
	 * @memberof SessionService
	 */
	public get token(): string
	{
		const localToken = localStorage.getItem(
			AppConstants.storage.securityTokenKey);

		return (AnyHelper.isNullOrEmpty(localToken))
			? AppConstants.empty
			: localToken;
	}

	/**
	 * Sets a {string} representing the session security token.
	 *
	 * @memberof SessionService
	 */
	public set token(
		value: string)
	{
		localStorage.setItem(
			AppConstants.storage.securityTokenKey,
			value ? value : null);
	}

	/**
	 * Gets a {string} representing the session id.
	 *
	 * @type {string}
	 * @memberof SessionService
	 */
	public get sessionId(): string
	{
		const localSessionId =
			localStorage.getItem(
				AppConstants.storage.sessionIdKey);

		return (AnyHelper.isNullOrEmpty(localSessionId))
			? AppConstants.empty
			: localSessionId;
	}

	/**
	 * Sets a {string} representing the session id.
	 *
	 * @memberof SessionService
	 */
	public set sessionId(
		value: string)
	{
		localStorage.setItem(
			AppConstants.storage.sessionIdKey,
			value ? value : null);
	}

	/**
	 * Gets a {string} representing the multi-factor authentication method.
	 *
	 * @type {string}
	 * @memberof SessionService
	 */
	public get multiFactorMethod(): string
	{
		const localMultiFactorMethod = localStorage.getItem(
			AppConstants.storage.securityMultiFactorAuthenticationMethodKey);

		return (AnyHelper.isNullOrEmpty(localMultiFactorMethod))
			? AppConstants.empty
			: localMultiFactorMethod;
	}

	/**
	 * Sets a {string} representing the multi-factor authentication method.
	 *
	 * @memberof SessionService
	 */
	public set multiFactorMethod(
		value: string)
	{
		localStorage.setItem(
			AppConstants.storage.securityMultiFactorAuthenticationMethodKey,
			value ? value : null);
	}

	/**
	 * Gets a {boolean} value indicating whether multi-factor authentication is
	 * enabled.
	 *
	 * @type {boolean}
	 * @memberof SessionService
	 */
	public get isMultiFactorEnabled(): boolean
	{
		return 'true' === (
			localStorage.getItem(
				AppConstants.storage
					.securityMultiFactorAuthenticationEnabledKey)
			|| 'false');
	}

	/**
	 * Sets a {boolean} value indicating whether multi-factor authentication is
	 * enabled.
	 *
	 * @memberof SessionService
	 */
	public set isMultiFactorEnabled(
		value: boolean)
	{
		localStorage.setItem(
			AppConstants.storage.securityMultiFactorAuthenticationEnabledKey,
			value.toString());
	}

	/**
	 * Gets a {boolean} value indicating whether the session is logged in.
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof SessionService
	 */
	public get isLoggedIn(): boolean
	{
		if (AnyHelper.isNullOrEmpty(this.user)
			|| AnyHelper.isNullOrEmpty(this.token))
		{
			return false;
		}

		return true;
	}

	/**
	 * Gets a {string} representing the application token.
	 *
	 * @readonly
	 * @type {string}
	 * @memberof SessionService
	 */
	public get applicationToken(): string
	{
		const token: string = AppConfig.settings.webApi.applicationToken;

		return AnyHelper.isNullOrEmpty(token)
			? null
			: token;
	}

	/**
	 * Gets a value indicating whether the user data is fully
	 * ready for application use.
	 *
	 * @type {boolean}
	 * A combination of status of whether the user is logged in,
	 * is valid, and security groups are populated.
	 * @memberof SessionService
	 */
	public get isValidLoggedInAndReady(): boolean
	{
		return this.isLoggedIn && this.isValid
			&& this.user.accessibleSecurityGroups != null
			&& !AnyHelper.isNullOrWhitespace(this.systemTimeZone)
			&& !this.user.data.security?.forcePasswordChange;
	}

	/**
	 * Gets a value indicating whether the user data is fully
	 * ready for application use but the user needs to change its password.
	 *
	 * @type {boolean}
	 * A combination of status of whether the user is logged in,
	 * is valid, and security groups are populated and force password change.
	 * @memberof SessionService
	 */
	public get shouldChangePassword(): boolean
	{
		return this.isLoggedIn && this.isValid
			&& this.user.accessibleSecurityGroups != null
			&& !AnyHelper.isNullOrWhitespace(this.systemTimeZone)
			&& this.user.data.security?.forcePasswordChange;
	}

	/**
	 * On initialization of service.
	 *
	 * @memberof SessionService
	 */
	public ngOnInit(): void
	{
		this.clear();
	}

	/**
	 * Performs an async login request to the api service implementing
	 * the /Authenticate method.
	 *
	 * @param {string} userName
	 * A string representing the user name to login as.
	 * @param {string} password
	 * A string representing the password for the user name.
	 * @returns {Promise<IUser>}
	 * The logged in user.
	 * @memberof AuthenticateService
	 */
	public async login(
		userName: string,
		password: string): Promise<IUser>
	{
		const response: any
			= await this.authenticateApiService.login(
				userName,
				password,
				this.applicationToken);

		this.setFromHeaders(response.headers);
		this.user = response.user;
		this.siteLayoutService.setFromUserSettings(this.user);

		this.isValid =
			this.isMultiFactorEnabled
				? false
				: true;

		if (this.isValid)
		{
			await this.decorateSystemSettings();
			await this.setSessionId();
			await this.setStoredVariables();
			await this.decorateUserSecurityGroups(this.user);
		}

		return this.user;
	}

	/**
	 * Performs an async verification request to the api service implementing
	 * the /Authenticate/Verify method.
	 *
	 * @param {string} token
	 * A string representing the security access token to verify.
	 * @param {string} code
	 * A string representing the verification code.
	 * @returns {Promise<void>}
	 * @memberof AuthenticateService
	 */
	public async verify(
		token: string,
		code: string): Promise<void>
	{
		await this.authenticateApiService.verify(
			token,
			code);

		await this.decorateSystemSettings();
		await this.setSessionId();
		await this.setStoredVariables();
		await this.decorateUserSecurityGroups(this.user);

		this.isValid = true;
	}

	/**
	 * Performs an async verification request to the api service implementing
	 * the /Authenticate/Reset method.
	 *
	 * @param {string} userName
	 * A string representing the user name to reset.
	 * @returns {Promise<void>}
	 * @memberof AuthenticateService
	 */
	public async resetUser(
		userName: string): Promise<void>
	{
		await this.authenticateApiService.reset(userName);
	}

	/**
	 * Performs an async update of the provided user.
	 *
	 * @param {string} newPassword
	 * The new user's password.
	 * @returns {Promise<any>}
	 * @memberof AuthenticateService
	 */
	public async changePassword(
		newPassword: string): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;

		const userEntityInstance: IEntityInstance =
			await this.entityInstanceApiService.get(
				this.user.id);

		const newEntityInstance: any =
			{
				id: userEntityInstance.id,
				entityType: userEntityInstance.entityType,
				versionNumber: userEntityInstance.versionNumber,
				data: {
					email: userEntityInstance.data.email,
					userName: userEntityInstance.data.userName,
					password: newPassword
				}
			};

		const updateEntityInstance: Function =
			async () =>
			{
				await this.entityInstanceApiService
					.update(
						newEntityInstance.id,
						newEntityInstance);
			};

		await updateEntityInstance();
	}

	/**
	 * Sets the security token in local storage from the headers.
	 *
	 * @param {Headers} header
	 * @memberof SessionService
	 */
	public setFromHeaders(
		header: HttpHeaders): void
	{
		this.token = header.get(AppConstants.webApi.tokenKey);

		if (header.has(AppConstants.webApi.multiFactorKey))
		{
			const raw: string
				= header.get(AppConstants.webApi.multiFactorKey);

			const parts: string[] = raw.split(AppConstants.characters.comma);
			const enabled: boolean = parts[0].toLocaleLowerCase() === 'true';
			const method: string = parts[1];

			this.isMultiFactorEnabled = enabled;
			this.multiFactorMethod = method;
		}

		if (header.has(AppConstants.webApi.tokenExpiryKey))
		{
			this.expiry = new AppTimeSpan(
				header.get(AppConstants.webApi.tokenExpiryKey));

			this.resetExpiryDate();
		}
	}

	/**
	 * Resets the session expiry date.
	 *
	 * @memberof SessionService
	 */
	public resetExpiryDate(): void
	{
		const date: Date = new Date();
		date.setMilliseconds(
			date.getMilliseconds()
			+ this.expiry.totalMilliSeconds);

		this.expiryDate = date;
	}

	/**
	 * Clears the session from local storage.
	 *
	 * @memberof SessionService
	 */
	public clear(): void
	{
		this.isValid = false;
		this.user = null;
		this.expiryDate = new Date();
		this.expiry = new AppTimeSpan('0:15:0');
		this.token = null;
		this.multiFactorMethod = 'email';
		this.isMultiFactorEnabled = false;
		this.sessionId = null;
		this.systemTimeZone = null;
	}

	/**
	 * Logs the user session out.
	 *
	 * @memberof SessionService
	 */
	public logOut(): void
	{
		// Close out any existing chat sessions.
		const chatService: IChatService =
			this.resolver.resolveShared(
				this.systemSettings
					.chat
					.modalService);

		chatService.resetChat()
			.then(
				() =>
				{
					this.reloadPage();

					// Run this outside of the main thread so the local
					// settings are reset on reload.
					setTimeout(
						() =>
						{
							this.clear();
						});
				});
	}

	/**
	 * Sets stored variables on any singleton storage classes on login.
	 *
	 * @async
	 * @memberof SessionService
	 */
	public async setStoredVariables(): Promise<void>
	{
		this.operationService.clearStoredVariables();
		this.displayComponentService.clearStoredVariables();
		this.entityService.clearStoredVariables();
		this.ruleService.clearStoredVariables();

		setTimeout(
			async() =>
			{
				await this.displayComponentService.setStoredVariables();
			},
			AppConstants.time.halfSecond);

		setTimeout(
			async() =>
			{
				await this.entityService.setStoredVariables();
			},
			AppConstants.time.twoSeconds);

		setTimeout(
			async() =>
			{
				await this.ruleService.setStoredVariables();
			},
			AppConstants.time.twoSeconds);

		await this.operationService.setStoredVariables();
	}

	/**
	 * Gets and sets the security session id matching this user session.
	 *
	 * @async
	 * @memberof AuthenticateService
	 */
	public async setSessionId(): Promise<void>
	{
		const securitySession: ISecuritySession =
			await this.securitySessionApiService.getSingleQueryResult(
				`token eq '${this.token}'`,
				AppConstants.empty);

		this.sessionId = securitySession.id.toString();
	}

	/**
	 * Decorates the security groups that the user has access to
	 * into the session user object.
	 *
	 * @async
	 * @param {IUser} user
	 * The logged in user.
	 * @returns {Promise<IUser>}
	 * The user with user security group values set and ready for use.
	 * @memberof SessionService
	 */
	public async decorateUserSecurityGroups(
		user: IUser): Promise<IUser>
	{
		if (this.isValid)
		{
			const accessibleSecurityGroups: ISecurityGroup[] =
				await ApiHelper.getFullDataSet(
					this.securityGroupApiService,
					AppConstants.empty,
					AppConstants.empty);

			const membershipFilter: string =
				'SecurityGroupEntityInstances.Any('
					+ `EntityInstanceId eq ${user.id})`;

			const membershipSecurityGroups: ISecurityGroup[] =
				await ApiHelper.getFullDataSet(
					this.securityGroupApiService,
					membershipFilter,
					AppConstants.empty);

			await this.userService.setUserSecurityGroups(
				user,
				accessibleSecurityGroups,
				membershipSecurityGroups);
		}
		else
		{
			user.accessibleSecurityGroups = [];
			user.membershipSecurityGroups = [];
		}

		this.user = user;

		return user;
	}

	/**
	 * Decorates system settings for data handling and display site wide.
	 *
	 * @async
	 * @memberof SessionService
	 */
	public async decorateSystemSettings(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.systems;
		const systemInstance: IEntityInstance =
			await this.entityInstanceApiService.get(
				parseInt(
					AppConstants.systemId,
					AppConstants.parseRadix));

		this.decorateSystemTimeZone(
			systemInstance);
		this.decorateChatConfiguration(
			systemInstance);
		this.decorateKnowledgeBaseConfiguration(
			systemInstance);
		this.decorateWalkthroughConfiguration(
			systemInstance);
	}

	/**
	 * Decorates system settings for data handling and display site wide.
	 *
	 * @param {IEntityInstance} systemInstance
	 * The system entity instance.
	 * @memberof SessionService
	 */
	public decorateSystemTimeZone(
		systemInstance: IEntityInstance): void
	{
		const systemTimeZone: string =
			systemInstance.data.settings
				?.systemTimeZone
				?.internetAssignedNumbersAuthority;

		if (AnyHelper.isNullOrWhitespace(systemTimeZone))
		{
			this.logOut();

			EventHelper.dispatchLoginMessageEvent(
				'System Time Zone Undefined',
				'Unable to calculate a system time zone. '
					+ 'Please ensure the system time zone is set in the System '
					+ 'entity instance.',
				AppConstants.messageLevel.error);
		}

		this.systemTimeZone = systemTimeZone;
		Settings.defaultZone = systemTimeZone;
	}

	/**
	 * Decorates chat configuration settings.
	 *
	 * @param {IEntityInstance} systemInstance
	 * The system entity instance.
	 * @memberof SessionService
	 */
	public decorateChatConfiguration(
		systemInstance: IEntityInstance): void
	{
		const systemService: ISystemServiceConfiguration =
			ObjectArrayHelper
				.findByProperty<ISystemServiceConfiguration>(
					systemInstance.data.settings?.services,
					AppConstants.commonProperties.name,
					SystemServiceConstants.chat)
					?? this.defaultServiceConfiguration;

		this.systemSettings =
			<ISystemSettings>
			{
				...this.systemSettings,
				chat:
					<IChatSettings>
					{
						enabled:
							JSON.parse(
								ObjectArrayHelper
									.findByProperty<INameValuePair>(
										systemService.settings,
										AppConstants.commonProperties.name,
										SystemServiceConstants.enabled)
									?.value ?? 'false'),
						componentName:
							ObjectArrayHelper
								.findByProperty<INameValuePair>(
									systemService.settings,
									AppConstants.commonProperties.name,
									SystemServiceConstants.componentName)
								?.value,
						modalService:
							ObjectArrayHelper
								.findByProperty<INameValuePair>(
									systemService.settings,
									AppConstants.commonProperties.name,
									SystemServiceConstants.modalService)
								?.value,
						deploymentId:
							ObjectArrayHelper
								.findByProperty<INameValuePair>(
									systemService.settings,
									AppConstants.commonProperties.name,
									SystemServiceConstants.deploymentId)
								?.value,
						environment:
							ObjectArrayHelper
								.findByProperty<INameValuePair>(
									systemService.settings,
									AppConstants.commonProperties.name,
									SystemServiceConstants.environment)
								?.value,
					}
			};
	}

	/**
	 * Decorates knowledge base configuration settings.
	 *
	 * @param {IEntityInstance} systemInstance
	 * The system entity instance.
	 * @memberof SessionService
	 */
	public decorateKnowledgeBaseConfiguration(
		systemInstance: IEntityInstance): void
	{
		const systemService: ISystemServiceConfiguration =
			ObjectArrayHelper
				.findByProperty<ISystemServiceConfiguration>(
					systemInstance.data.settings?.services,
					AppConstants.commonProperties.name,
					SystemServiceConstants.knowledgeBase)
					?? this.defaultServiceConfiguration;

		this.systemSettings =
			<ISystemSettings>
			{
				...this.systemSettings,
				knowledgeBase:
					<IKnowledgeBaseSettings>
					{
						enabled:
							JSON.parse(
								ObjectArrayHelper
									.findByProperty<INameValuePair>(
										systemService.settings,
										AppConstants.commonProperties.name,
										SystemServiceConstants.enabled)
									?.value ?? 'false'),
						componentName:
							ObjectArrayHelper
								.findByProperty<INameValuePair>(
									systemService.settings,
									AppConstants.commonProperties.name,
									SystemServiceConstants.componentName)
								?.value,
						knowledgeBaseUrl:
							ObjectArrayHelper
								.findByProperty<INameValuePair>(
									systemService.settings,
									AppConstants.commonProperties.name,
									SystemServiceConstants.knowledgeBaseUrl)
								?.value,
					}
			};
	}

	/**
	 * Decorates walkthrough configuration settings.
	 *
	 * @param {IEntityInstance} systemInstance
	 * The system entity instance.
	 * @memberof SessionService
	 */
	public decorateWalkthroughConfiguration(
		systemInstance: IEntityInstance): void
	{
		const systemService: ISystemServiceConfiguration =
			ObjectArrayHelper
				.findByProperty<ISystemServiceConfiguration>(
					systemInstance.data.settings?.services,
					AppConstants.commonProperties.name,
					SystemServiceConstants.walkthrough)
					?? this.defaultServiceConfiguration;

		this.systemSettings =
			<ISystemSettings>
			{
				...this.systemSettings,
				walkthrough:
					<IWalkthroughSettings>
					{
						enabled:
							JSON.parse(
								ObjectArrayHelper
									.findByProperty<INameValuePair>(
										systemService.settings,
										AppConstants.commonProperties.name,
										SystemServiceConstants.enabled)
									?.value ?? 'false'),
						widgetId:
							ObjectArrayHelper
								.findByProperty<INameValuePair>(
									systemService.settings,
									AppConstants.commonProperties.name,
									SystemServiceConstants.widgetId)
								?.value,
					}
			};
	}

	/**
	 * Adds a local storage event listener.
	 *
	 * @memberof SessionService
	 */
	public addLocalStorageEventListener(): void
	{
		window.addEventListener(
			WindowEventConstants.storage,
			(storageEvent: any) =>
				this.handleStorageEvent(storageEvent));
	}

	/**
	 * Handles the storage event.
	 *
	 * @memberof SessionService
	 */
	public handleStorageEvent(event: any): void
	{
		if (event.key !== AppConstants.storage.securityUserKey)
		{
			return;
		}

		const previousUserName: string =
			!AnyHelper.isNull(event.oldValue)
				? JSON.parse(event.oldValue).data.userName
				: null;
		const currentUserName: string =
			!AnyHelper.isNull(event.newValue)
				? JSON.parse(event.newValue).data.userName
				: null;

		if (previousUserName !== currentUserName
			&& this.router.url !== '/login')
		{
			window.location.reload();
			window.removeEventListener(
				WindowEventConstants.storage,
				(storageEvent: any) =>
					this.handleStorageEvent(storageEvent));
		}
	}

	/**
	 * Reloads the page.
	 *
	 * @memberof SessionService
	 */
	private reloadPage(): void
	{
		window.location.reload();
	}
}