/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	TransactionEndorseIssueSummaryComponent
} from '@insurance/components/wizard-steps/policy/transaction-endorse-issue-summary/transaction-endorse-issue-summary.component';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	IInsurancePaymentSummary
} from '@insurance/interfaces/insurance-payment-summary.interface';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	IDifferenceDefinition
} from '@shared/interfaces/application-objects/difference-definition.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	RuleService
} from '@shared/services/rule.service';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-endorse-issue-differences',
	templateUrl: './transaction-endorse-issue-differences.component.html'
})

/**
 * A component representing a wizard step for issuing an endorsement on
 * a policy transaction.
 *
 * @export
 * @class TransactionEndorseIssueDifferencesComponent
 */
export class TransactionEndorseIssueDifferencesComponent implements OnInit

{
	/**
	 * Creates a new instance of the transaction endorse issue differences
	 * component.
	 *
	 * @param {RuleService} ruleService
	 * The rule service used to check for the wizard final action being allowed.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition api service used in this component.
	 * @param {InsuranceService} insuranceService
	 * The insurance service.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The change detector references used to update the view when differences
	 * are displayed and loaded.
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public constructor(
		public ruleService: RuleService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public insuranceService: InsuranceService,
		public changeDetectorReference: ChangeDetectorRef)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, IWizardContext>}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the existing transaction instance that is being endorsed.
	 *
	 * @type {IEntityInstance}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public existingTransactionInstance: IEntityInstance;

	/**
	 * Gets or sets the pending endorse transaction instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public endorseTransactionInstance: IEntityInstance;

	/**
	 * Gets or sets the entity definition that is used for schema based display
	 * definitions.
	 *
	 * @type {EntityDefinition}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public entityDefinition: EntityDefinition;

	/**
	 * Gets or sets the difference definitions that will be displayed in this
	 * component.
	 *
	 * @type {IDifferenceDefinition[]}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public differences: IDifferenceDefinition[];

	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets the comparison object friendly name.
	 *
	 * @type {string}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public readonly comparisonObjectName: string =
		'Policy';

	/**
	 * Gets or sets the excluded difference properties. If sent as a partial
	 * all instances that contain this will be removed otherwise exact
	 * property location matches will be excluded.
	 *
	 * @type {string}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public readonly excludedDifferenceProperties: string[] =
		<string[]>
		[
			// First level data.
			`${this.comparisonObjectName}.`
				+ AppConstants.commonProperties
					.id,
			`${this.comparisonObjectName}.`
				+ AppConstants.commonProperties
					.createDate,
			`${this.comparisonObjectName}.`
				+ AppConstants.commonProperties
					.changeDate,
			`${this.comparisonObjectName}.`
				+ AppConstants.commonProperties
					.changedById,
			`${this.comparisonObjectName}.`
				+ AppConstants.commonProperties
					.createdById,

			// Nested level data.
			`${this.comparisonObjectName}.`
				+ AppConstants.nestedDataIdentifier
				+ InsuranceConstants.commonProperties
					.effectiveDate,
			`${this.comparisonObjectName}.`
				+ AppConstants.nestedDataIdentifier
				+ InsuranceConstants.commonProperties
					.transactionNumber,
			`${this.comparisonObjectName}.`
				+ AppConstants.nestedDataIdentifier
				+ InsuranceConstants.commonProperties
					.basedOnTransactionNumber,
			`${this.comparisonObjectName}.`
				+ AppConstants.nestedDataIdentifier
				+ AppConstants.commonProperties
					.type,
			`${this.comparisonObjectName}.`
				+ AppConstants.nestedDataIdentifier
				+ AppConstants.commonProperties
					.status,
			`${this.comparisonObjectName}.`
				+ AppConstants.nestedDataIdentifier
				+ InsuranceConstants.commonProperties
					.reasons,

			// Remove at all levels.
			InsuranceConstants.commonProperties
				.accounting,
			AppConstants.commonProperties
				.resourceIdentifier
		];

	/**
	 * Gets the blocking rule message if this wizard can not complete.
	 *
	 * @type {string}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	private readonly blockingRuleMessage: string =
		'There are rule violations blocking an issue of this endorsement.';

	/**
	 * Gets the no difference found message if this wizard can not
	 * complete.
	 *
	 * @type {string}
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	private readonly noDifferenceFoundMessage: string =
		'There are no differences found which makes this endorsement invalid.';

	/**
	 * Implements the on initialization interface.
	 * This will set up the data for the differences display.
	 *
	 * @async
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.source.validStepChanged(false);
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			currentData.entityTypeGroup;
		this.endorseTransactionInstance =
			await this.entityInstanceApiService
				.get(currentData.entityId);

		if (await this.isWizardStepValidForDisplay() === false)
		{
			this.clientMessage =
				'Endorsement Issue is not allowed '
				+ 'from the current policy state.';

			this.context.source.wizardStepLoading = false;

			return;
		}

		const basedOnTransactionNumber: string =
			this.endorseTransactionInstance.data.basedOnTransactionNumber;
		this.existingTransactionInstance =
			await this.entityInstanceApiService
				.getSingleQueryResult(
					`${InsuranceConstants.commonProperties.transactionNumber} `
						+ `eq '${basedOnTransactionNumber}'`,
					`${AppConstants.commonProperties.id} `
						+ AppConstants.sortDirections.descending);

		if (AnyHelper.isNull(this.existingTransactionInstance))
		{
			throw new Error(
				'Unable to find the original transaction with a transaction '
					+ `number of '${basedOnTransactionNumber}'.`);
		}

		await this.setPolicyNumber();

		const isBlocked: boolean =
			await this.ruleService.isActionBlocked(
				currentData.entityId,
				TransactionEndorseIssueSummaryComponent.workflowActionName);
		if (isBlocked === true)
		{
			this.clientMessage = this.blockingRuleMessage;
			this.context.source.wizardStepLoading = false;

			return;
		}

		await this.setDifferenceData();
		if (this.differences.length === 0)
		{
			this.clientMessage = this.noDifferenceFoundMessage;
			this.context.source.wizardStepLoading = false;

			return;
		}

		await this.setEntityDefinition();

		// Generate the draft payment plan for the next step here so
		// the data is readily available to be shared between components.
		const actionResponse: any =
			await this.insuranceService.generateDraftPaymentPlanSchedule(
				currentData.entityId,
				currentData.entityTypeGroup,
				null,
				null,
				null,
				null,
				this.endorseTransactionInstance.data.effectiveDate,
				true);
		const generatedPayments: IInsurancePaymentSummary[] =
			await this.insuranceService.mapPaymentSchedulePaymentSummary(
				currentData.entityId,
				currentData.entityTypeGroup,
				actionResponse?.body?.value.payments);

		this.context.source.addOrUpdateStepData(
			{
				basedOnTransactionNumber: basedOnTransactionNumber,
				basedOnEntityId: this.existingTransactionInstance.id,
				generatedPayments: generatedPayments
			});

		this.context.source.updateGuardComparisonData();
		this.context.source.validStepChanged(true);
		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Detects changes in the component view.
	 *
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public detectChanges(): void
	{
		this.changeDetectorReference.detectChanges();
	}

	/**
	 * Sets the differences property value for use in the differences display.
	 *
	 * @async
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public async setDifferenceData(): Promise<void>
	{
		// Flip the order of transaction data.
		this.endorseTransactionInstance.data =
			this.orderDataValues(
				this.endorseTransactionInstance.data);
		this.existingTransactionInstance.data =
			this.orderDataValues(
				this.existingTransactionInstance.data);

		// Ensure differences exist.
		this.differences =
			ObjectHelper.getBusinessLogicDifferences(
				this.comparisonObjectName,
				this.existingTransactionInstance,
				this.endorseTransactionInstance,
				this.excludedDifferenceProperties);
	}

	/**
	 * Loads and sets the entity definition property that is sent to the
	 * differences component.
	 *
	 * @async
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public async setEntityDefinition(): Promise<void>
	{
		const entityType: IEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq `
						+ `'${this.existingTransactionInstance.entityType}'`,
					AppConstants.empty);
		const entityDefinition: IEntityDefinition =
			await this.entityDefinitionApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.typeId} eq `
						+ `${entityType.id}`,
					`${AppConstants.commonProperties.versionId} desc`);
		this.entityDefinition =
			new EntityDefinition(entityDefinition);
	}

	/**
	 * Gathers policy data related to the sent policy term and sets the policy
	 * number as context data in the wizard.
	 *
	 * @async
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public async setPolicyNumber(): Promise<void>
	{
		const policyTermParents: IEntityInstance[] =
			await this.entityInstanceApiService.getParents(
				this.existingTransactionInstance.id,
				AppConstants.empty,
				AppConstants.empty,
				0,
				1,
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms;
		const policyParents: IEntityInstance[] =
			await this.entityInstanceApiService.getParents(
				policyTermParents[0].id,
				AppConstants.empty,
				AppConstants.empty,
				0,
				1,
				InsuranceConstants.insuranceEntityTypeGroups.policies);

		const policyNumber: string =
			policyParents[0].data.policyNumber;
		this.context.source.addOrUpdateStepData(
			{
				policyNumber: policyNumber
			});

		this.context.source.updateWizardLabel(
			this.context.source.activeMenuItem.currentData.data.policyNumber,
			true);

		this.context.source.updateGuardComparisonData();
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * An awaitable promise that returns a value signifying whether or not
	 * the wizard step is valid for display.
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	public async isWizardStepValidForDisplay(): Promise<boolean>
	{
		return this.endorseTransactionInstance.data.status ===
			InsuranceConstants.transactionStatusTypes.pending
			&& this.endorseTransactionInstance.data.type
				=== InsuranceConstants.transactionTypes.endorsement;
	}

	/**
	 * Given a data object found in a transaction entity instance, this will
	 * ensure that the data differences are displayed with assets prior to
	 * interests.
	 *
	 * @param {any} data
	 * The transaction data object that will be displayed in the differences
	 * component.
	 * @returns {any}
	 * A set of business logic ordered data values.
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	private orderDataValues(
		data: any): any
	{
		const orderedData: any = {...data};

		const assets: any = orderedData.assets;
		const interests: any = orderedData.interests;

		delete orderedData.assets;
		delete orderedData.interests;

		orderedData.assets = assets;
		orderedData.interests = interests;

		return orderedData;
	}
}