<div class="topbar-wrapper">
	<div class="topbar clearfix">
		<div class="topbar-left">
			<img src="assets/layout/images/company-logo.png"
				alt="Company logo"
				class="topbar-logo" />
		</div>

		<div class="topbar-right">
			<a id="menu-button"
				class="cursor-pointer"
				(click)="overlayMenuButtonClicked($event)"
				[ngClass]="{
					'menu-button-rotate': rotateMenuButton
				}">
				<i class="fa fa-angle-left">
				</i>
			</a>
		</div>
	</div>
</div>

