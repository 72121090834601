/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component,
	OnInit
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';

@Component({
	selector: 'custom-input-number',
	templateUrl: './custom-input-number.component.html',
	styleUrls: [
		'./custom-input-number.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Input Number.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomInputNumberComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {OnInit}
 */
export class CustomInputNumberComponent
	extends ExtendedCustomControlDirective
	implements OnInit
{
	/** Initializes a new instance of the CustomInputNumberComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomInputNumberComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/** Gets or sets the display value for the percent input control.
	 *
	 * @type {string}
	 * @memberof CustomInputNumberComponent
	 */
	public percentDisplay: string = AppConstants.empty;

	/** Gets the default maximum percent value.
	 *
	 * @type {number}
	 * @memberof CustomInputNumberComponent
	 */
	private readonly defaultMaximumPercent: number = 100;

	/** Gets the default maximum percent decimal precision.
	 *
	 * @type {number}
	 * @memberof CustomInputNumberComponent
	 */
	private readonly defaultMaximumPercentDecimalPrecision: number = 2;

	/** Implements the OnInit interface.
	 * This method Initializes the component for use percent based logic.
	 *
	 * @memberof CustomInputNumberComponent
	 */
	public ngOnInit(): void
	{
		if (this.field.props.usePercent !== true)
		{
			return;
		}

		this.field.props.maxPercent =
			this.field.props.maxPercent ?? this.defaultMaximumPercent;
		this.field.props.maxPercentDecimalPrecision =
			this.field.props.maxPercentDecimalPrecision
				?? this.defaultMaximumPercentDecimalPrecision;

		if (!AnyHelper.isNullOrWhitespace(this.field.formControl.value))
		{
			this.percentDisplay =
				(parseFloat(this.field.formControl.value) * 100)
					.toFixed(this.field.props.maxPercentDecimalPrecision)
					.toString();
		}
	}

	/** Handles the keydown event sent from the input number control when
	 * using the percent display.
	 *
	 * @param {KeyboardEvent} event
	 * The event sent from the keydown handler.
	 * @memberof CustomInputNumberComponent
	 */
	public onPercentKeyDown(
		event: KeyboardEvent): void
	{
		const inputElement: HTMLInputElement =
			event.target as HTMLInputElement;

		if (!this.confirmAllowedKeyPress(
			event))
		{
			event.preventDefault();

			return;
		}

		const newValue: string =
			this.getKeyDownExpectedValue(
				inputElement,
				event);
		const formattedValue: string =
			this.filterDigitsAndFirstPeriod(
				newValue);
		const parsedNumber: number =
			parseFloat(
				formattedValue);

		// Only allow valid numbers and decimals.
		if (isNaN(parsedNumber))
		{
			event.preventDefault();

			return;
		}

		// Only allow less than the max percent.
		if (parsedNumber > this.field.props.maxPercent)
		{
			event.preventDefault();

			return;
		}

		// Only allow a fixed decimal precision.
		if(parseFloat(
			parsedNumber
				.toFixed(
					this.field.props.maxPercentDecimalPrecision))
			.toString() !== parsedNumber.toString())
		{
			event.preventDefault();
		}
	}

	/** Confirms the keydown event and that the key being sent is an allowed
	 * input for percent displays.
	 *
	 * @param {KeyboardEvent} event
	 * The event sent from the keydown handler.
	 * @memberof CustomInputNumberComponent
	 */
	public confirmAllowedKeyPress(
		event: KeyboardEvent): boolean
	{
		const allowedKeys: string[] =
			[
				'Backspace',
				'ArrowLeft',
				'ArrowRight',
				'Tab',
				'Delete'
			];
		const isDigit: boolean =
			event.key >= '0' && event.key <= '9';
		const isValidPeriod: boolean =
			event.key === AppConstants.characters.period
				&& !this.percentDisplay.includes(
					AppConstants.characters.period);

		return isDigit
			|| isValidPeriod
			|| allowedKeys.includes(event.key);
	}

	/** Calculates what the keydown event will set for an expected value. This
	 * is used to determine if the keydown event should be allowed.
	 *
	 * @param {HTMLInputElement} inputElement
	 * The input element to get the expected value.
	 * @param {KeyboardEvent} event
	 * The event sent from the keydown handler.
	 * @returns {string}
	 * The expected value after the keydown event.
	 * @memberof CustomInputNumberComponent
	 */
	public getKeyDownExpectedValue(
		inputElement: HTMLInputElement,
		event: KeyboardEvent): string
	{
		let newValue: string;

		// Replace highlighted text.
		if (inputElement.selectionStart > 0 && inputElement.selectionEnd > 0)
		{
			const start: number =
				inputElement.selectionStart;
			const end: number =
				inputElement.selectionEnd;
			const valueBefore: string =
				inputElement.value.substring(
					0,
					start);
			const valueAfter: string =
				inputElement.value.substring(
					end);
			newValue =
				valueBefore
					+ event.key
					+ valueAfter;
		}
		else
		{
			// Else concatenate the new character.
			newValue =
				inputElement.value
					+ event.key;
		}

		return newValue;
	}

	/** Handles the keyup event sent from the input number control when
	 * using the percent display. This will be used to set the data
	 * model value as a 0-1 based percent from the displayed whole number
	 * percent values.
	 *
	 * @param {KeyboardEvent} event
	 * The event sent from the keyup handler.
	 * @memberof CustomInputNumberComponent
	 */
	public setPercentDisplay(
		event: KeyboardEvent): void
	{
		this.field.formControl.setValue(
			parseFloat(this.percentDisplay) / 100);

		this.validateControl();
		if (!AnyHelper.isNull(this.field.props.change))
		{
			this.field.props.change(
				this.field,
				event);
		}
	}

	/** Handles the onInput event sent from the input number control.
	 *
	 * @param {any} event
	 * The event sent from the onInput handler.
	 * @memberof CustomInputNumberComponent
	 */
	public onChange(
		event: any)
	{
		if (event.originalEvent?.code ===
			AppConstants.keyBoardKeyConstants.backspace
				&& event.value === 0)
		{
			this.field.formControl.setValue(null);
		}
		else
		{
			this.field.formControl.setValue(event.value);
		}

		this.validateControl();

		if (!AnyHelper.isNull(this.field.props.change))
		{
			this.field.props.change(
				this.field,
				event);
		}
	}

	/** Handles the onBlur event when losing focus
	 * from the input number control.
	 *
	 * @memberof CustomInputNumberComponent
	 */
	public onBlur()
	{
		if (!AnyHelper.isNullOrEmpty(
			this.field.props?.roundToNearest))
		{
			const currentValue: number =
				this.field.formControl.value;

			const roundToNearest: number =
				this.field.props.roundToNearest;

			const roundedValue: number =
				Math.round(currentValue / roundToNearest) * roundToNearest;

			setTimeout(
				() =>
				{
					this.field.formControl.setValue(roundedValue);
					this.validateControl();
				},
				AppConstants.time.quarterSecond);
		}
	}

	/** Filters the digits and the first period from the value.
	 *
	 * @param {string} value
	 * The value to filter.
	 * @returns {string}
	 * The filtered value.
	 * @memberof CustomInputNumberComponent
	 */
	private filterDigitsAndFirstPeriod(
		value: string): string
	{
		// Remove all non-digit characters.
		let digitDecimalValue: string =
			value.replace(
				/[^\d.]/g,
				AppConstants.empty);
		// Remove all periods except the first one.
		digitDecimalValue =
			digitDecimalValue.replace(
				/(?!^)(?<=\..*)\./g,
				AppConstants.empty);

		return digitDecimalValue;
	}
}