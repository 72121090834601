/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Directive
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';

@Directive({
	selector: '[EntityTable]'
})

/**
 * A directive representing shared logic for displaying a
 * component with the entity table.
 *
 * @export
 * @class EntityTableDirective
 */
export class EntityTableDirective
{
	/**
	 * Gets or sets the filter value.
	 *
	 * @type {string}
	 * @memberof EntityTableDirective
	 */
	public filterValue: string;

	/**
	 * Gets or sets the category label.
	 *
	 * @type {string}
	 * @memberof EntityTableDirective
	 */
	public categoryLabel: string;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityTableDirective
	 */
	public selectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets a value signifying whether or not the keyword based messsage
	 * should be displayed if zero keywords are found.
	 *
	 * @type {boolean}
	 * @memberof EntityTableDirective
	 */
	public displayKeywordMessage: boolean = false;

	/**
	 * Gets or sets the loading table definitions state.
	 *
	 * @type {boolean}
	 * @memberof EntityTableDirective
	 */
	public loadingTableDefinitions: boolean = true;

	/**
	 * Gets the table redraw debounce delay.
	 *
	 * @type {number}
	 * @memberof EntityTableDirective
	 */
	private readonly tableRedrawDebonceDelay: number = 25;

	/**
	 * Sets string representing the filter value to be used to find matching
	 * entity types based on the filter.
	 *
	 * @param {string} filterQuery
	 * The most up to date search criteria filter query.
	 * @memberof EntityTableDirective
	 */
	public setFilterKeywordString(filterQuery: string): void
	{
		this.filterValue = (AnyHelper.isNullOrEmpty(filterQuery))
			? AppConstants.empty
			: filterQuery.split('\'')[1];
	}

	/**
	 * Returns a string representing the query to be used to find matching
	 * entity types based on the filter.
	 *
	 * @param {string} filter
	 * The most up to date search criteria as defined by the UI.
	 * @memberof EntityTableDirective
	 * @returns {string}
	 * The filter to be used when calling to the entity instance query api
	 * interface.
	 */
	public getQueryStringContainsFilter(filter: string): string
	{
		let query: string;

		if (AnyHelper.isNullOrEmpty(filter))
		{
			query = AppConstants.empty;
		}
		else
		{
			let searchArray: string[] = filter.split(' ');
			searchArray = searchArray.map(
				(word: string) =>
					`keywords.Contains('${word}') eq true`);

			query = searchArray.join(' and ');
		}

		return query;
	}

	/**
	 * Sets the category label from the loaded entity type group
	 *
	 * @param {string} categoryGroup
	 * The category group to be formatted as a category label.
	 * @memberof EntityTableDirective
	 */
	public setCategoryLabel(categoryGroup: string): void
	{
		this.categoryLabel =
			StringHelper.beforeCapitalSpaces(
				StringHelper.getLastSplitValue(
					categoryGroup,
					AppConstants.characters.period));
	}

	/**
	 * Sets up the table definitions for the current entity page.
	 * This must be overrode in the implementing class.
	 *
	 * @memberof EntityTableDirective
	 */
	public setupTableDefinitions(): void
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Restores table definitions on demand.
	 * This is used to update the table and load it with changed
	 * definitions in the ui.
	 *
	 * @memberof EntityTableDirective
	 */
	public restoreTableDefinition(): void
	{
		this.loadingTableDefinitions = true;
		setTimeout(
			() =>
			{
				this.setupTableDefinitions();
			},
			this.tableRedrawDebonceDelay);
	}
}