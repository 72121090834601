<div class="operation-button-bar">
	<ul class="layout-menu fadeInDown loading-panel"
		*ngIf="loading"
		[@contentAnimation]="state">
		<ul (click)="stopPropogation($event)"
			operation-subgroup
			[item]="model"
			root="true"
			class="layout-menu fadeInDown"
			[reset]="reset"
			visible="true"
			parentActive="false">
		</ul>
	</ul>

	<span #OperationContainer
		class="operation-container ui-g-12 no-padding"
		[ngClass]="{
			'ui-lg-6': (reserveHeaderBottomRight === true
				&& siteLayoutService.contentWidth > twoColumnHeaderBreakpoint)
		}"
		*ngIf="!loading">

		<ng-template #TooltipContainer
			ngFor let-item let-i="index" [ngForOf]="model">

			<span [pTooltip]="item.tooltipOptions?.tooltipLabel"
				tooltipStyleClass="button-bar-tooltip"
				(tap)="mobileTooltipToggle(i)"
				(click)="preventDefault($event, item.tooltipOptions?.tooltipLabel)">
				<button
					*ngIf="item.visible !== false && item.id.indexOf('BUTTON') === 0"
					pButton
					role="button"
					[label]="item.label"
					[icon]="item.icon"
					[disabled]="item.disabled || executingCommand"
					[class]="item.styleClass"
					[ngClass]="{
						'left-border-radius': (i === firstVisibleButtonIndex),
						'right-border-radius': (i === lastVisibleButtonIndex)
					}"
					(click)="executeCommand(item.command, item.useCommandPromise)">
				</button>

				<operation-button-menu
					*ngIf="item.id.indexOf('GROUPBUTTON') === 0"
					[operationGroup]="item"
					[roundedLeftBorder]="i === firstVisibleButtonIndex"
					[roundedRightBorder]="i === lastVisibleButtonIndex"
					[fixedOverlayLocation]="fixedOverlayLocation"
					[context]="pageContext">
				</operation-button-menu>
			</span>

		</ng-template>

		<span *ngIf="ellipsisMenuItem != null"
			[ngClass]="{
				'standalone-ellipsis': (standaloneEllipsisDisplayed === true)
			}">
			<ng-template [ngIf]="redrawEllipsis === false"
				[ngIfElse]="RedrawWithoutFlash">
				<operation-button-menu
					[operationGroup]="ellipsisMenuItem"
					[fixedOverlayLocation]="fixedOverlayLocation"
					useEllipsis="true">
				</operation-button-menu>
			</ng-template>
			<ng-template #RedrawWithoutFlash>
				<operation-button-menu
					[operationGroup]="ellipsisMenuItem"
					[fixedOverlayLocation]="fixedOverlayLocation"
					useEllipsis="true">
				</operation-button-menu>
			</ng-template>
		</span>
	</span>
</div>