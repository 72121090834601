<div class="summary-card-container">

	<div #SummaryCard
		class="ui-g-12 summary-card text-center"
		[ngClass]="{
			'cursor-pointer': (overlayDynamicComponent != null),
			'active': (active),
			'theme-box-shadow': (active),
			'padding-radial-gauge': chartDefinition?.chartRadialGaugeChart === true,
			'vertical-centered': chartDefinition?.chartRadialGaugeChart === true && (summaryDisplay === 'No Data' || summaryDisplay === '$0' || summaryDisplay === '!') && summaryCardDisplay !== true
		}"
		(click)="summaryCardClicked(SummaryCard)">

		<span class="summary-card-main-item">
			<ng-template
				[ngIf]="summaryCardLoading && chartDefinition == null"
				[ngIfElse]="Summary">
				<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
				</i>
			</ng-template>

			<ng-template #Summary>
				<div class="ui-g-12 no-padding chart-container"
					*ngIf="!summaryCardLoading && chartDefinition != null">
					<app-chart
						[chartConfiguration]="chartDefinition.chartConfiguration"
						[chartColors]="chartDefinition.chartColors"
						[groupByCount]="chartDefinition.groupByCount"
						[data]="data"
						[pivotProperty]="chartDefinition.chartPivotProperty"
						[pivotProperties]="chartDefinition.chartPivotProperties"
						[radialGaugeChart]="chartDefinition.chartRadialGaugeChart"
						[summaryCardDisplay]="summaryCardDisplay"
						[squareCardDisplay]="squareCardDisplay"
						(chartDisplayReady)="chartReadyForDisplay($event)">
					</app-chart>
				</div>

				<div class="ui-g-12 no-vertical-padding summary-text ellipsis-text"
					[ngClass]="{
						'theme-color': (overlayDynamicComponent != null),
						'radial-gauge-chart-text': chartDefinition?.chartRadialGaugeChart && summaryCardDisplay && (data?.length > 0 || data != null),
						'no-data-information-card-radial-gauge': chartDefinition?.chartRadialGaugeChart === true && (data == null || data?.length === 0)
					}"
					*ngIf="chartDefinition == null || chartDefinition?.chartRadialGaugeChart">
					<span class="ellipsis-text"
						[innerHTML]="summaryDisplay">
					</span>
				</div>
			</ng-template>
		</span>

		<div class="summary-card-main-description summary-font-size ellipsis-text"
			[ngClass]="{
				'radial-gauge-information-card': chartDefinition?.chartRadialGaugeChart && summaryCardDisplay && (data?.length > 0 || data != null)
			}">
			{{titleDisplay}}
		</div>

	</div>
</div>