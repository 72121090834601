/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	IApplicationMessage
} from '@shared/interfaces/application-messages/application-message.interface';

/**
 * A class representing a client message detail for use in client message
 * based displays.
 *
 * @export
 * @class ClientMessageDetail
 */
export class ClientMessageDetail
{
	/**
	 * Initializes a new instance of the client message detail class.
	 *
	 * @param {string} description
	 * The primary message of this message detail class.
	 * @param {IApplicationMessage[]} messages
	 * The set of nested applications messages in this detail.
	 * @memberof ClientMessageDetail
	 */
	public constructor(
		description: string,
		messages: IApplicationMessage[] = [])
	{
		this.description = description;
		this.messages = messages;
	}

	/**
	 * Gets or sets the description or primary display string.
	 *
	 * @type {string}
	 * @memberof ClientMessageDetail
	 */
	public description: string;

	/**
	 * Gets or sets the application messages associated with this client
	 * message detail.
	 *
	 * @type {string}
	 * @memberof ClientMessageDetail
	 */
	public messages: IApplicationMessage[] = [];
}