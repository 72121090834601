/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Injectable,
	Injector
} from '@angular/core';
import {
	AbstractControl,
	ValidationErrors
} from '@angular/forms';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	FormlyFieldConfig,
	FormlyFieldProps
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IValidator
} from '@shared/interfaces/application-objects/validator.interface';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IRulePresentationDefinition
} from '@shared/interfaces/rules/rule-presentation-definition.interface';
import {
	IRuleViolationWorkflowActionDefinition
} from '@shared/interfaces/rules/rule-violation-workflow-action-definition.interface';
import {
	RuleTokenLookup
} from '@shared/rule-token.lookup';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	BaseRuleValidator
} from '@shared/validators/base-rule-validator.validator';
import {
	Observable
} from 'rxjs';

/**
 * A singleton service representing shared logic for decorating presentation
 * rules.
 *
 * @export
 * @class RulePresentationService
 */
@Injectable({
	providedIn: 'root'
})
export class RulePresentationService
{
	/**
	 * Creates an instance of a rule presentation service.
	 *
	 * @param {Injector} injector
	 * The injector that can be used for to instantiate provided instances.
	 * @param {RuleService} ruleService
	 * The rule service used for data loads in this service.
	 * @memberof RulePresentationService
	 */
	public constructor(
		public injector: Injector,
		public ruleService: RuleService)
	{
	}

	/**
	 * Gets the event types supported for the rule presentation service.
	 *
	 * @type {
	 *	onChange: string;
	 *	onChangeValidation: string;
	 *	onChangeValidationAsync: string;
	 *	OnFormDisplay: string;
	 * }
	 * @memberof RulePresentationService
	 */
	public readonly eventTypes:
	{
		onChange: string;
		onChangeValidation: string;
		onChangeValidationAsync: string;
		OnFormDisplay: string;
	} = {
			onChange: 'OnChange',
			onChangeValidation: 'OnChangeValidation',
			onChangeValidationAsync: 'OnChangeValidationAsync',
			OnFormDisplay: 'OnFormDisplay'
		};

	/**
	 * Gets the identifier used for the template options disabled expression
	 * property name.
	 *
	 * @type {string}
	 * @memberof RulePresentationService
	 */
	private readonly propertyDisabledIdentifier: string =
		'props.disabled';

	/**
	 * Gets the identifier used for overall form presentation logic.
	 *
	 * @type {string}
	 * @memberof RulePresentationService
	 */
	private readonly formIdentifier: string = 'form';

	/**
	 * Based on the send entity type and entity version, this will decorate
	 * the formly entity layout with presentation logic matching the
	 * presentation definition per field.
	 *
	 * @async
	 * @param {number} entityTypeId
	 * The entity type for entity instance rule setups.
	 * @param {number} entityVersionId
	 * The entity version for entity instance rule setups.
	 * @param {FormlyFieldConfig[]} formlyLayout
	 * The formly layout ready for entity instance rule presentation setups.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @returns {Promise<FormlyFieldConfig[]>}
	 * The decorated formly field config array with instantiated rule
	 * presentation logic.
	 * @memberof RulePresentationService
	 */
	public async applyEntityInstanceRules(
		entityTypeId: number,
		entityVersionId: number,
		formlyLayout: FormlyFieldConfig[],
		context: IDynamicComponentContext<Component, any>): Promise<FormlyFieldConfig[]>
	{
		if (formlyLayout.length === 0)
		{
			return formlyLayout;
		}

		const rulePresentationDefinitions: IRulePresentationDefinition[] =
			await this.ruleService.getPopulatedRulePresentationDefinitions(
				entityTypeId,
				entityVersionId);

		if (rulePresentationDefinitions.length === 0)
		{
			return formlyLayout;
		}

		let formattedLayout: FormlyFieldConfig[] = formlyLayout;
		const formRules: IRulePresentationDefinition[] =
			rulePresentationDefinitions.filter(
				(rulePresentationDefinition: IRulePresentationDefinition) =>
					rulePresentationDefinition.dataKey === this.formIdentifier);

		if (formRules.length > 0)
		{
			formattedLayout =
				this.mapForm(
					formattedLayout,
					formRules,
					context);
		}

		return formattedLayout.map(
			(field: FormlyFieldConfig) =>
			{
				this.mapField(
					rulePresentationDefinitions,
					field,
					context);

				return field;
			});
	}

	/**
	 * For a sent formly field this will add an on change method that will
	 * notify the set of rule violations that this violation may have altered
	 * due to client entered values.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field to add the on change notification event to.
	 * @memberof RulePresentationService
	 */
	public decorateAssociatedRuleChangeNotification(
		field: FormlyFieldConfig): void
	{
		const updatedChangeFunction: Function =
			AnyHelper.isNull(field.props.change)
				? (_field: FormlyFieldConfig,
					_event: any) =>
				{
					// Do nothing.
				}
				: field.props.change;

		field.props.change =
			(changeField: FormlyFieldConfig,
				event: any) =>
			{
				updatedChangeFunction(
					changeField,
					event);

				if (AnyHelper.isNull(event))
				{
					return;
				}

				const associatedRules: string =
					changeField.props.attributes[
						FormlyConstants.attributeKeys.associatedRules].toString();
				const associatedRuleDefinitions: string[] =
					associatedRules.split(
						AppConstants.characters.comma);

				// Currently not used
				// Can become a private method when implemented.
				// This is the likely method however of notifying the
				// rules drawer of possible rule changes.
				console.log(
					'Associated Rules Attribute Notification Available',
					associatedRuleDefinitions);
			};
	}

	/**
	 * Maps rules meant to be ran against the entire form.
	 *
	 * @param {FormlyFieldConfig[]} formlyLayout
	 * The formly layout that should be altered based on form based presentation
	 * definitions.
	 * @param {IRulePresentationDefinition[]} formRulePresentationDefinitions
	 * The set of rule presentation definitions pointing to the form.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @returns {FormlyFieldConfig[]}
	 * The set of formly field configs after being altered to handled the form
	 * based presentation logic.
	 * @memberof RulePresentationService
	 */
	public mapForm(
		formlyLayout: FormlyFieldConfig[],
		formRulePresentationDefinitions: IRulePresentationDefinition[],
		context: IDynamicComponentContext<Component, any>): FormlyFieldConfig[]
	{
		let jsonData: any;
		formRulePresentationDefinitions.forEach(
			(formRulePresentationDefinition: IRulePresentationDefinition) =>
			{
				if (formRulePresentationDefinition.eventType ===
					this.eventTypes.OnFormDisplay)
				{
					jsonData =
						this.validateAndReturnRequiredParameterData(
							formRulePresentationDefinition,
							this.eventTypes.OnFormDisplay,
							'Form Logic');

					const disabled: boolean =
						StringHelper.transformToFunction(
							StringHelper.interpolate(
								jsonData.function,
								context),
							context)();

					if (disabled === true)
					{
						FormlyHelper.disableAllFields(
							formlyLayout,
							disabled);
					}
				}
			});

		return formlyLayout;
	}

	/**
	 * Recursively maps children into a converted formly rule field.
	 *
	 * @param {IRulePresentationDefinition[]} matchingRulePresentationDefinitions
	 * The set of rule presentation definitions associated with this field.
	 * @param {FormlyFieldConfig} field
	 * The formly field to be decorated with rule presentation definitions.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @memberof RulePresentationService
	 */
	private mapField(
		rulePresentationDefinitions: IRulePresentationDefinition[],
		field: FormlyFieldConfig,
		context: IDynamicComponentContext<Component, any>): void
	{
		if (AnyHelper.isNull(field.key)
			&& !FormlyHelper.isTabWrapper(field))
		{
			return;
		}

		this.convertToRuleField(
			this.getMatchingRulePresentationDefinitions(
				rulePresentationDefinitions,
				field),
			field,
			context);

		const fieldGroups: any[] =
			(field.fieldGroup || [])
				.concat((<any>field.fieldArray)?.fieldGroup || []);
		fieldGroups.forEach(
			(fieldGroup: FormlyFieldConfig) =>
			{
				this.convertToRuleField(
					this.getMatchingRulePresentationDefinitions(
						rulePresentationDefinitions,
						fieldGroup),
					fieldGroup,
					context);

				this.mapField(
					rulePresentationDefinitions,
					fieldGroup,
					context);
			});
	}

	/**
	 * For a sent formly field this will apply rule presentation definitions
	 * matching the field.
	 *
	 * @param {IRulePresentationDefinition[]} matchingRulePresentationDefinitions
	 * The set of rule presentation definitions associated with this field.
	 * @param {FormlyFieldConfig} field
	 * The formly field to be decorated with rule presentation definitions.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @returns {FormlyFieldConfig}
	 * A decorated formly field configuration containing applied rule
	 * presentation definitions.
	 * @memberof RulePresentationService
	 */
	private convertToRuleField(
		matchingRulePresentationDefinitions: IRulePresentationDefinition[],
		field: FormlyFieldConfig,
		context: IDynamicComponentContext<Component, any>): FormlyFieldConfig
	{
		matchingRulePresentationDefinitions.forEach(
			(rulePresentationDefinition: IRulePresentationDefinition) =>
			{
				// Existing change function.
				const existingChangeFunction: Function =
					AnyHelper.isNull(field.props?.change)
						? (_field: FormlyFieldConfig,
							_event: any) =>
						{
							// Do nothing.
						}
						: field.props.change;

				// Existing disable function.
				field.expressions =
					field.expressions || {};
				const existingDisableFunction: any =
					AnyHelper.isNull(field.expressions[
						this.propertyDisabledIdentifier])
						? (_field: FormlyFieldConfig) =>
							false
						: field.expressions[
							this.propertyDisabledIdentifier];

				// Existing validators.
				field.validators = field.validators || {};
				field.asyncValidators = field.asyncValidators || {};

				switch (
					rulePresentationDefinition.eventType)
				{
					case this.eventTypes.onChange:
						this.decorateOnChangeRule(
							field,
							rulePresentationDefinition,
							context,
							existingChangeFunction,
							existingDisableFunction);
						break;
					case this.eventTypes.onChangeValidation:
						this.decorateOnChangeValidationRule(
							field,
							rulePresentationDefinition,
							context);
						break;
					case this.eventTypes.onChangeValidationAsync:
						this.decorateOnChangeValidationAsyncRule(
							field,
							rulePresentationDefinition,
							context);
						break;
					default:
						throw new Error(
							`${rulePresentationDefinition.eventType} `
								+ 'requires a convert to rule switch case.');
				}

				this.decorateAssociatedRuleDefinitionAttribute(
					field,
					rulePresentationDefinition);
			});

		return field;
	}

	/**
	 * For a sent formly field this will decorate the on change method to
	 * match the sent rule presentation definition as well as keeping any
	 * existing behaviors.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field to be decorated with the rule presentation definition.
	 * @param {IRulePresentationDefinition} rulePresentationDefinition
	 * The rule presentation definition to add to this field.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @param {Function} existingChangeFunction
	 * The existing change function, this function will be called prior to
	 * calling the new on change rule.
	 * @param {Function} existingDisableFunction
	 * The existing disabled function, this function will be called prior to
	 * calling the new on change disable rule.
	 * @memberof RulePresentationService
	 */
	private decorateOnChangeRule(
		field: FormlyFieldConfig,
		rulePresentationDefinition: IRulePresentationDefinition,
		context: IDynamicComponentContext<Component, any>,
		existingChangeFunction: Function,
		existingDisableFunction: Function): void
	{
		const classReference: string =
			rulePresentationDefinition
				.rulePresentationLogicDefinition
				.classReference;
		let jsonData: any;

		switch (classReference)
		{
			case 'Associate':
				jsonData =
					this.validateAndReturnRequiredParameterData(
						rulePresentationDefinition,
						this.eventTypes.onChangeValidation,
						classReference);

				field.props.change =
					(changeField: FormlyFieldConfig,
						event: any) =>
					{
						existingChangeFunction(
							changeField,
							event);

						if (AnyHelper.isNull(event))
						{
							return;
						}

						setTimeout(
							() =>
							{
								jsonData.associatedDataKeys
									.forEach(
										(dataKey: string) =>
										{
											const associatedFields: FormlyFieldConfig[] =
												(<EntityInstanceComponent>
												context.source)
													.getMatchingFieldConfigurations(dataKey);

											for (const associatedField of associatedFields)
											{
												associatedField?.formControl
													?.updateValueAndValidity();

												if (!AnyHelper.isNullOrWhitespace(
													associatedField.props
														.change))
												{
													associatedField.props
														.change(
															associatedField,
															event);
												}
											}
										});
							},
							AppConstants.time.fiftyMilliseconds);
					};
				break;

			case 'Disable':
				jsonData =
					this.validateAndReturnRequiredParameterData(
						rulePresentationDefinition,
						this.eventTypes.onChangeValidation,
						classReference);

				field.expressions =
					{
						...field.expressions,
						'props.disabled':
							(disableField: FormlyFieldConfig) =>
							{
								const disabled: boolean =
									Function(
										FormlyConstants.formlyMethodParameters
											.field,
										jsonData.function)
										.bind(context)(
											disableField)();

								const clearOnDisableValue: any =
									jsonData.clearOnDisableValue
										?? AppConstants.empty;

								if (disabled === true
									&& jsonData.clearOnDisable === true
									&& disableField.formControl.value
										!== clearOnDisableValue)
								{
									disableField.formControl
										.setValue(clearOnDisableValue);
								}

								return existingDisableFunction(
									disableField)
									|| disabled;
							}
					};
				break;
			default:
				if (rulePresentationDefinition.jsonData === undefined
					|| rulePresentationDefinition.jsonData === null)
				{
					break;
				}

				jsonData =
					this.validateAndReturnRequiredParameterData(
						rulePresentationDefinition,
						this.eventTypes.onChangeValidation);

				if (jsonData.function === undefined
					|| jsonData.function === null)
				{
					break;
				}

				field.props.change =
					(changeField: FormlyFieldConfig,
						event: any) =>
					{
						existingChangeFunction(
							changeField,
							event);

						Function(
							FormlyConstants.formlyMethodParameters
								.field,
							FormlyConstants.formlyMethodParameters
								.event,
							jsonData.function)
							.bind(context)(
								changeField,
								event)();
					};
				break;
		}
	}

	/**
	 * For a sent formly field this will decorate the on change validation
	 * to match the sent rule presentation definition as well as keeping
	 * any existing validations in place.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field to be decorated with the rule presentation definition.
	 * @param {IRulePresentationDefinition} rulePresentationDefinition
	 * The rule presentation definition to add to this field.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @memberof RulePresentationService
	 */
	private decorateOnChangeValidationRule(
		field: FormlyFieldConfig,
		rulePresentationDefinition: IRulePresentationDefinition,
		context: IDynamicComponentContext<Component, any>): void
	{
		const classReference: string =
			rulePresentationDefinition
				.rulePresentationLogicDefinition
				.classReference;
		let jsonData: any;

		switch (classReference)
		{
			case 'MinimumLength':
			case 'MaximumLength':
				jsonData =
					this.validateAndReturnRequiredParameterData(
						rulePresentationDefinition,
						this.eventTypes.onChangeValidation,
						classReference);

				field.props =
					classReference === 'MinimumLength'
						? <FormlyFieldProps>
							{
								...field.props,
								minimumLength: jsonData.minimumLength
							}
						: <FormlyFieldProps>
							{
								...field.props,
								maximumLength: jsonData.maximumLength
							};
				break;
			case 'MinimumNumber':
			case 'MaximumNumber':
				jsonData =
						this.validateAndReturnRequiredParameterData(
							rulePresentationDefinition,
							this.eventTypes.onChangeValidation,
							classReference);

				field.props =
						classReference === 'MinimumNumber'
							? <FormlyFieldProps>
								{
									...field.props,
									minimumNumber: jsonData.minimumNumber
								}
							: <FormlyFieldProps>
								{
									...field.props,
									maximumNumber: jsonData.maximumNumber
								};
				break;
			case 'RegularExpressionFormat':
				jsonData =
					this.validateAndReturnRequiredParameterData(
						rulePresentationDefinition,
						this.eventTypes.onChangeValidation,
						classReference);

				field.props =
					<FormlyFieldProps>
					{
						...field.props,
						regularExpression: jsonData.regularExpression
					};
				break;
			default:
				if (rulePresentationDefinition.jsonData === undefined
					|| rulePresentationDefinition.jsonData === null)
				{
					break;
				}

				jsonData =
					this.validateAndReturnRequiredParameterData(
						rulePresentationDefinition,
						this.eventTypes.onChangeValidation);

				if (jsonData.name === undefined
					|| jsonData.name === null
					|| jsonData.function === undefined
					|| jsonData.function === null)
				{
					break;
				}

				field.validators[
					`${jsonData.name}_Rule`
						+ rulePresentationDefinition.ruleDefinition.id] =
					{
						expression:
							(control: AbstractControl,
								dynamicValidatorField: FormlyFieldConfig) =>
								this.isRuleFiltered(
									rulePresentationDefinition,
									this.getContainerResourceIdentifier(
										control),
									context)
									|| !this.isRuleBlocking(
										rulePresentationDefinition,
										this.getContainerResourceIdentifier(
											control),
										context)
									? { nonBlocking: true }
									: Function(
										FormlyConstants.formlyMethodParameters
											.control,
										FormlyConstants.formlyMethodParameters
											.field,
										jsonData.function)
										.bind(context)(
											control,
											dynamicValidatorField)(),
						message: (error: any,
							dynamicMessageField: FormlyFieldConfig) =>
							this.getDynamicMessage(
								error,
								dynamicMessageField,
								jsonData.message,
								context)
					};

				return;
		}

		const validator: any =
			this.injector.get<BaseRuleValidator>(
				RuleTokenLookup.tokens[classReference]);
		const validatorInstance: IValidator =
			validator.create();

		const mappedExpression: (
			control: AbstractControl,
			field: FormlyFieldConfig) =>
				ValidationErrors = validatorInstance.expression;
		validatorInstance.expression =
			(control: AbstractControl,
				validatorField: FormlyFieldConfig) =>
				this.isRuleFiltered(
					rulePresentationDefinition,
					this.getContainerResourceIdentifier(
						control),
					context)
					|| !this.isRuleBlocking(
						rulePresentationDefinition,
						this.getContainerResourceIdentifier(
							control),
						context)
					? { nonBlocking: true }
					: mappedExpression(
						control,
						validatorField);

		const existingMessage: string | Function =
			validatorInstance.message;
		const existingMessageIsAFunction: boolean =
			AnyHelper.isFunction(<object>existingMessage);
		validatorInstance.message = (
			error: any,
			dynamicMessageField: FormlyFieldConfig) =>
		{
			const messageAsFunctionDefinition =
				existingMessageIsAFunction === true
					? (<any>existingMessage)(
						error,
						dynamicMessageField)
					: existingMessage;

			return AnyHelper.isNullOrWhitespace(jsonData?.message)
				? messageAsFunctionDefinition
				: this.getDynamicMessage(
					error,
					dynamicMessageField,
					jsonData.message,
					context);
		};

		field.validators[
			`${classReference}_Rule${rulePresentationDefinition.id}`] =
			validatorInstance;
	}

	/**
	 * For a sent formly field this will decorate the on change async validation
	 * to match the sent rule presentation definition as well as keeping
	 * any existing async validations in place.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field to be decorated with the rule presentation definition.
	 * @param {IRulePresentationDefinition} rulePresentationDefinition
	 * The rule presentation definition to add to this field.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @memberof RulePresentationService
	 */
	private decorateOnChangeValidationAsyncRule(
		field: FormlyFieldConfig,
		rulePresentationDefinition: IRulePresentationDefinition,
		context: IDynamicComponentContext<Component, any>): void
	{
		const jsonData: any =
			this.validateAndReturnRequiredParameterData(
				rulePresentationDefinition,
				this.eventTypes.onChangeValidation);

		field.asyncValidators[
			`${jsonData.name}_Rule`
				+ rulePresentationDefinition.ruleDefinition.id] =
			{
				expression:
					async(control: AbstractControl,
						validatorField: FormlyFieldConfig) =>
						await
						this.isRuleFilteredAsync(
							rulePresentationDefinition,
							this.getContainerResourceIdentifier(
								control),
							context)
							|| !this.isRuleBlocking(
								rulePresentationDefinition,
								this.getContainerResourceIdentifier(
									control),
								context)
							? { nonBlocking: true }
							: Function(
								FormlyConstants.formlyMethodParameters
									.control,
								FormlyConstants.formlyMethodParameters
									.field,
								jsonData.function)
								.bind(context)(
									control,
									validatorField)(),
				message: (error: any,
					dynamicMessageField: FormlyFieldConfig) =>
					this.getDynamicMessage(
						error,
						dynamicMessageField,
						jsonData.message,
						context)
			};
	}

	/**
	 * If a rule filter is sent as a validation parameter, this will create that
	 * method and return the filtered value of that function. If a rule is
	 * filtered, the client side presentation should not fire.
	 *
	 * @param {IRulePresentationDefinition} rulePresentationDefinition
	 * The rule presentation definition to check for a filtered validation.
	 * @param {string} containerResourceIdentifier
	 * The container resource identifier associated with this rule and
	 * validation value. This can be an entity or array item identifier.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context sent to this service.
	 * @returns {boolean}
	 * A boolean value that defines whether or not we will filter this rule
	 * presentation based on the sent compiled rule filter method.
	 * @memberof RulePresentationService
	 */
	private isRuleFiltered(
		rulePresentationDefinition: IRulePresentationDefinition,
		containerResourceIdentifier: string,
		context: IDynamicComponentContext<Component, any>): boolean
	{
		if (AnyHelper.isNullOrWhitespace(rulePresentationDefinition.jsonData))
		{
			return false;
		}

		const ruleFilter: string =
			JSON.parse(
				rulePresentationDefinition.jsonData)?.data?.ruleFilter;

		if (!AnyHelper.isNullOrWhitespace(ruleFilter)
			&& !AnyHelper.isNullOrWhitespace(containerResourceIdentifier))
		{
			return StringHelper.transformToFunction(
				StringHelper.interpolate(
					ruleFilter,
					context),
				context)(containerResourceIdentifier);
		}

		return false;
	}

	/**
	 * If a rule filter is sent as a validation parameter, this will create that
	 * method and return the filtered value of that function. If a rule is
	 * filtered, the client side presentation should not fire.
	 * @note If the rule is being filtered asynchronously, the rule validation
	 * definition must also be asynchronous to allow for awaits.
	 *
	 * @async
	 * @param {IRulePresentationDefinition} rulePresentationDefinition
	 * The rule presentation definition to check for a filtered validation.
	 * @param {string} containerResourceIdentifier
	 * The container resource identifier associated with this rule and
	 * validation value. This can be an entity or array item identifier.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context sent to this service.
	 * @returns {boolean}
	 * An awaitable boolean value that defines whether or not we will
	 * filter this rule presentation based on the sent compiled rule filter
	 * method.
	 * @memberof RulePresentationService
	 */
	private async isRuleFilteredAsync(
		rulePresentationDefinition: IRulePresentationDefinition,
		containerResourceIdentifier: string,
		context: IDynamicComponentContext<Component, any>): Promise<boolean>
	{
		const ruleFilter: string =
			JSON.parse(
				rulePresentationDefinition.jsonData)?.data?.ruleFilter;

		if (!AnyHelper.isNullOrWhitespace(ruleFilter))
		{
			return new Promise(
				function(resolve: any)
				{
					resolve(
						Function(ruleFilter)()
							.bind(context)(containerResourceIdentifier));
				}.bind(context));
		}

		return false;
	}

	/**
	 * Based on all currently existing entity instance rule violations loaded
	 * in the source context, this will return a value that defines whether
	 * or not we should show client side validations.
	 *
	 * @param {IRulePresentationDefinition} rulePresentationDefinition
	 * The rule presentation definition to check for a blocking violation
	 * action.
	 * @param {string} resourceIdentifier
	 * The resource identifier associated with this rule.
	 * @param {IDynamicComponentContext<Component, any>} context
	 * The context sent to this service.
	 * @returns {boolean}
	 * A boolean value that defines whether or not we should show client side
	 * validations.
	 * @memberof RulePresentationService
	 */
	private isRuleBlocking(
		rulePresentationDefinition: IRulePresentationDefinition,
		resourceIdentifier: string,
		context: IDynamicComponentContext<Component, any>): boolean
	{
		const entityInstanceRuleViolationsIdentifier: string =
			'entityInstanceRuleViolations';
		const entityInstanceRuleViolations: IEntityInstanceRuleViolation[] =
			(<IEntityInstanceRuleViolation[]>context.source)[
				entityInstanceRuleViolationsIdentifier];

		if (AnyHelper.isNull(entityInstanceRuleViolations))
		{
			throw new Error(
				'This context source has not been implemented for rules.');
		}

		const matchingRuleViolations: IEntityInstanceRuleViolation[] =
			entityInstanceRuleViolations.filter(
				(entityInstanceRuleViolation: IEntityInstanceRuleViolation) =>
					entityInstanceRuleViolation.ruleDefinitionId ===
						rulePresentationDefinition.ruleDefinition.id
							&& entityInstanceRuleViolation.resourceIdentifier ===
								resourceIdentifier);

		if (matchingRuleViolations.length === 1)
		{
			const blockingActions: IRuleViolationWorkflowActionDefinition[] =
				matchingRuleViolations[0]
					.ruleDefinition
					.ruleViolationWorkflowActionDefinitions
					.filter(
						(ruleViolationWorkflowAction:
							IRuleViolationWorkflowActionDefinition) =>
							ruleViolationWorkflowAction.blocked === true);

			// Either no blocked workflow action is associated or
			// at least one blocking action is not overridden.
			return blockingActions.length === 0
				|| blockingActions.filter(
					(ruleViolationWorkflowAction:
						IRuleViolationWorkflowActionDefinition) =>
						ruleViolationWorkflowAction.overridable !== true
								|| ruleViolationWorkflowAction.overridden !== true).length > 0;
		}

		return true;
	}

	/**
	 * For a sent formly field this will add a property that will be
	 * available as an attribute on this field's form control.
	 *
	 * @param {FormlyFieldConfig} field
	 * The formly field to be decorated with the rule presentation definition
	 * association attribute.
	 * @param {IRulePresentationDefinition} rulePresentationDefinition
	 * The rule presentation definition to mark as associated to this field.
	 * @memberof RulePresentationService
	 */
	private decorateAssociatedRuleDefinitionAttribute(
		field: FormlyFieldConfig,
		rulePresentationDefinition: IRulePresentationDefinition): void
	{
		const ruleDefinitionId: string =
			rulePresentationDefinition.definitionId.toString();

		const existingAssociatedRuleDefinitionsValue: string =
			field.props.attributes[
				FormlyConstants.attributeKeys.associatedRules]?.toString()
					|| AppConstants.empty;
		const existingAssociatedRuleDefinitions: string[] =
			existingAssociatedRuleDefinitionsValue.split(
				AppConstants.characters.comma);

		if (existingAssociatedRuleDefinitions.indexOf(
			ruleDefinitionId) !== -1)
		{
			return;
		}

		field.props.attributes[
			FormlyConstants.attributeKeys.associatedRules] =
			AnyHelper.isNullOrWhitespace(
				existingAssociatedRuleDefinitionsValue)
				? ruleDefinitionId
				: `${existingAssociatedRuleDefinitionsValue},${ruleDefinitionId}`;
	}

	/**
	 * For a sent formly field and a set of rule presentation definitions
	 * this will filter and return the rule presentations definitions
	 * associated with this field.
	 *
	 * @param {IRulePresentationDefinition[]} rulePresentationDefinitions
	 * The set of rule presentation definitions associated to the layout.
	 * @param {FormlyFieldConfig} field
	 * The formly field to be decorated with rule presentation definitions.
	 * @returns {IRulePresentationDefinition[]}
	 * The set of rule presentation definitions associated to the sent field.
	 * @memberof RulePresentationService
	 */
	private getMatchingRulePresentationDefinitions(
		rulePresentationDefinitions: IRulePresentationDefinition[],
		field: FormlyFieldConfig): IRulePresentationDefinition[]
	{
		const fieldKey: string =
			AnyHelper.isNull(field.props?.attributes)
				? null
				: field.props.attributes[
					FormlyConstants.attributeKeys.dataKey]?.toString();

		if (AnyHelper.isNullOrWhitespace(fieldKey))
		{
			return [];
		}

		return rulePresentationDefinitions
			.filter(
				(rulePresentationDefinition: IRulePresentationDefinition) =>
					rulePresentationDefinition.dataKey
						=== fieldKey);
	}

	/**
	 * For a sent rule presentation definition requiring json data, this method
	 * will validate that the json data value does exist and throw a common
	 * error if null or whitespace.
	 *
	 * @param {IRulePresentationDefinition} rulePresentationDefinition
	 * The rule presentation definition to check for the existence of json data.
	 * @param {string} eventType
	 * The event type that requires json data.
	 * @param {string} classReference
	 * The class reference being checked. If not sent this value will default
	 * to 'Compiled Logic'.
	 * @memberof RulePresentationService
	 */
	private validateAndReturnRequiredParameterData(
		rulePresentationDefinition: IRulePresentationDefinition,
		eventType: string,
		classReference: string = 'Compiled Logic'): void
	{
		if (AnyHelper.isNullOrWhitespace(
			rulePresentationDefinition.jsonData))
		{
			throw new Error(
				'The rule presentation definition with id: '
					+ `${rulePresentationDefinition.id} for the event: `
					+ `'${eventType}' and presentation logic of `
					+ `'${classReference}' requires json parameter data. `
					+ (AnyHelper.isNullOrWhitespace(
						rulePresentationDefinition
							?.rulePresentationLogicDefinition
							?.jsonData)
						? AppConstants.empty
						: 'Required parameters: '
							+ JSON.parse(
								rulePresentationDefinition
									.rulePresentationLogicDefinition
									.jsonData).schema
							+ AppConstants.characters.period));
		}

		return JSON.parse(
			rulePresentationDefinition.jsonData).data;
	}

	/**
	 * Creates and returns a message to display for fields that are not valid.
	 *
	 * @param {any} error
	 * The error object sent by formly validation.
	 * @param {FormlyFieldConfig} dynamicMessageField
	 * The formly field that will use this message.
	 * @param {string} dynamicMessage
	 * The sring based function that is used to get a message ready for display.
	 * @param {object} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @returns {string | Observable<string>}
	 * A message ready for display for validations on the sent field.
	 * @memberof RulePresentationService
	 */
	private getDynamicMessage(
		error: any,
		dynamicMessageField: FormlyFieldConfig,
		dynamicMessage: string,
		context: object): string | Observable<string>
	{
		return Function(
			FormlyConstants.formlyMethodParameters
				.error,
			FormlyConstants.formlyMethodParameters
				.field,
			dynamicMessage)
			.bind(context)(
				error,
				dynamicMessageField)();
	}

	/**
	 * Traverses the model structure from the current control until the nearest
	 * parent resource identifier is found and returns that value.
	 *
	 * @param {AbstractControl} control
	 * The error object sent by formly validation.
	 * @returns {string}
	 * The nearest parent resource identifier in this control hierarchy.
	 * @memberof RulePresentationService
	 */
	private getContainerResourceIdentifier(
		control: AbstractControl): string
	{
		let traverseControl: AbstractControl = control.parent;

		// If the control is hidden in the UI, no presentation rule is required.
		if (AnyHelper.isNull(traverseControl?.parent)
			&& AnyHelper.isNull(traverseControl?.value?.resourceIdentifier))
		{
			return null;
		}

		let currentLevel: number = 1;
		const maxLevel: number = 25;
		while (AnyHelper.isNull(
			traverseControl?.value?.resourceIdentifier)
			&& currentLevel <= maxLevel)
		{
			traverseControl = traverseControl.parent;
			currentLevel++;
		}

		return traverseControl?.value?.resourceIdentifier;
	}
}