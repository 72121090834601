
<div class="list-item-container ui-g ui-g-12 no-padding"
	*ngIf="context.data === null">
	<span class="panel-sub-title">
		No Data
	</span>
</div>

<div class="list-item-container ui-g ui-g-12 no-padding"
	*ngIf="context.data != null">
	<ul>

		<li class="section-container">
			<span class="data-value">
				<p-divider align="left">
					<div>
						<span class="fa fa-user">
						</span>
						<span class="panel-sub-title">
							Insured
						</span>
					</div>
				</p-divider>

				<span class="data-value"
					*ngIf="context.data.keyContacts.primaryInsured != null">
					<strong>Name:</strong>
					<span>
						{{context.data.keyContacts.primaryInsured.name}}
					</span>
					<br/>
					<strong>Type:</strong>
					<span>
						{{context.data.keyContacts.primaryInsured.type}}
					</span>
					<br/>
					<strong>Phone:</strong>
					<span>
						{{context.data.keyContacts.primaryInsured.phone}}
					</span>
					<br/>
					<strong>Email:</strong>
					<span>
						{{context.data.keyContacts.primaryInsured.email}}
					</span>
					<br/>
					<strong>Address:</strong>
					<span>
						{{context.data.keyContacts.primaryInsured.address}}
					</span>
				</span>
			</span>
		</li>

		<li class="section-container">
			<span class="data-value">
				<p-divider align="left">
					<div>
						<span class="fa fa-users">
						</span>
						<span class="panel-sub-title">
							Agent
						</span>
					</div>
				</p-divider>

				<span class="data-value"
					*ngIf="context.data.keyContacts.agencyInformation != null">
					<strong>Name:</strong>
					<span>
						{{context.data.keyContacts.agencyInformation.name}}
					</span>
					<br/>
					<strong>Agency:</strong>
					<span>
						{{context.data.keyContacts.agencyInformation.agency}}
					</span>
					<br/>
					<strong>Phone:</strong>
					<span>
						{{context.data.keyContacts.agencyInformation.phone}}
					</span>
					<br/>
					<strong>Email:</strong>
					<span>
						{{context.data.keyContacts.agencyInformation.email}}
					</span>
					<br/>
					<strong>Address:</strong>
					<span>
						{{context.data.keyContacts.agencyInformation.address}}
					</span>
				</span>
			</span>
		</li>

	</ul>
</div>