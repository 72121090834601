/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';

/* eslint-enable max-len */

/**
 * A class containing static helper methods for interacting with the common
 * table.
 *
 * @export
 * @class TableHelper
 */
export class TableHelper
{
	/**
	 * Maps and returns an expand title with the expected create or delete
	 * annotation if applicable.
	 *
	 * @static
	 * @param {IDynamicComponentContext<CommonTableComponent, any>}
	 * commonTableContext
	 * The common table context to check for conditional title displays.
	 * @param {string} staticTitle
	 * The static title usually defining an item type to display.
	 * @returns {string}
	 * A string representing the expand title for common table displays.
	 * @memberof TableHelper
	 */
	public static getExpandTitle(
		commonTableContext: IDynamicComponentContext<CommonTableComponent, any>,
		staticTitle: string): string
	{
		switch (commonTableContext.source.displayMode)
		{
			case AppConstants.displayMode.create:
				return `Create ${staticTitle}`;
			case AppConstants.displayMode.delete:
				return `Delete ${staticTitle}`;
			case AppConstants.displayMode.update:
				return `Edit ${staticTitle}`;
			default:
				return `View ${staticTitle}`;
		}
	}
}