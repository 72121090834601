<div class="ui-g-12 no-padding text-center"
	[ngClass]="{
		'no-margin': (displayMode === displayModes.list)
	}">

	<ng-template #BackNavigation
		[ngIf]="true">
		<div class="ui-g-12 no-padding nested-display-header">
			<div class="ui-g-3 no-padding text-left back-container">
				<span class="text-link theme-color nested-display-back-container"
					(click)="handleBackAction()"
					*ngIf="displayMode === displayModes.view || displayMode === displayModes.secondaryView">
					<i class="fa fa-arrow-left nested-display-back-icon">
					</i>
					<span class="nested-display-back">
						Back
					</span>
				</span>
			</div>
			<div class="ui-g-9 no-padding text-right nested-display-actions-container"
				*ngIf="displayMode !== displayModes.view && displayMode !== displayModes.secondaryView">
				<span class="nested-display-actions">
					<ng-template
						ngFor
						let-action
						[ngForOf]="itemActions">
						<span [ngClass]="{
								'selected-bottom-border': (displayMode === action.id || (displayMode === displayModes.view && action.id === displayModes.list) || (displayMode === displayModes.secondaryView && action.id === displayModes.secondaryList))
							}">
							<app-common-icon-tooltip
								content="{{action.label}}"
								iconClass="fa fa-fw {{action.icon}} theme-color cursor-pointer"
								position="left"
								allowTooltipActivation="false"
								(iconClick)="action.command($event)">
							</app-common-icon-tooltip>
						</span>
					</ng-template>
				</span>
			</div>
			<div class="ui-g-9 no-padding text-right nested-display-actions-container"
				*ngIf="displayMode === displayModes.view || displayMode === displayModes.secondaryView">
				<span class="nested-display-actions">
					<ng-template
						ngFor
						let-action
						[ngForOf]="nestedItemActions">
						<span [ngClass]="{
								'selected-bottom-border': (displayMode === action.id || (displayMode === displayModes.secondaryView && action.id === displayModes.view))
							}">
							<app-common-icon-tooltip
								content="{{getActionLabel()}}"
								iconClass="fa fa-fw {{action.icon}} theme-color cursor-pointer"
								position="left"
								allowTooltipActivation="false"
								(iconClick)="action.command($event)">
							</app-common-icon-tooltip>
						</span>
					</ng-template>
				</span>
			</div>
		</div>
	</ng-template>

	<ng-template
		#Invalid
		[ngIf]="isValid() === false
			|| isDisplayModeAvailable() === false
			|| isOwnershipAllowed === false"
		[ngIfElse]="Content">
		<div class="ui-g-12 text-center">
			<div *ngIf="isOwnershipAllowed === true">
				This view is not currently available.
			</div>
			<div *ngIf="isOwnershipAllowed === false">
				<app-access-denied-content
					[drawerDisplay]="true"
					drawerName="Rules"
					[sessionIdentifier]="sessionIdentifier"
					[accessDeniedUrl]="accessDeniedUrl"
					[resources]="resources"
					[clientMessage]="clientMessage">
				</app-access-denied-content>
			</div>
		</div>
	</ng-template>

	<ng-template
		#Content>
		<ng-template
			#ListRule
			[ngIf]="displayMode === displayModes.list">
			<app-rule-list
				[context]="context"
				(changeDisplayMode)="changeDisplayMode($event)"
				(changeSelectedItem)="changeSelectedItem($event)">
			</app-rule-list>
		</ng-template>

		<ng-template
			#ViewRule
			[ngIf]="displayMode === displayModes.view">
			<app-rule-override
				[context]="context"
				[selectedItem]="selectedItem"
				(changeDisplayMode)="changeDisplayMode($event)">
			</app-rule-override>
		</ng-template>

		<ng-template
			#SecondaryListRule
			[ngIf]="displayMode === displayModes.secondaryList">
			<app-rule-override-list
				[context]="context"
				(changeDisplayMode)="changeDisplayMode($event)"
				(changeSelectedItem)="changeSelectedItem($event)">
			</app-rule-override-list>
		</ng-template>

		<ng-template
			#SecondaryViewRule
			[ngIf]="displayMode === displayModes.secondaryView">
			<app-rule-override-details
				[context]="context"
				[selectedItem]="selectedItem"
				(changeDisplayMode)="changeDisplayMode($event)">
			</app-rule-override-details>
		</ng-template>
	</ng-template>

</div>