/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {
	EntityInstanceRuleViolationOverrideApiService
} from '@api/services/entities/entity-instance-rule-violation-override.api.service';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	RuleWorkflowDisplayDirective
} from '@shared/directives/rule-workflow-display.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstanceRuleViolationOverride
} from '@shared/interfaces/entities/entity-instance-rule-violation-override.interface';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IRuleViolationWorkflowActionDefinition
} from '@shared/interfaces/rules/rule-violation-workflow-action-definition.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	RuleService
} from '@shared/services/rule.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-rule-override',
	templateUrl: './rule-override.component.html',
	styleUrls: [
		'./rule-override.component.scss'
	]
})

/**
 * A component representing a context level rule override display component.
 *
 * @export
 * @class RuleOverrideComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @implements {IDynamicComponent<Component, any>}
 */
export class RuleOverrideComponent
	extends RuleWorkflowDisplayDirective
	implements OnInit, AfterViewInit, OnDestroy,
		IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the rule override component.
	 *
	 * @param {RuleService} ruleService
	 * The rule service used in this component.
	 * @param {ActivityService} activityService
	 * The activity service used in this component.
	 * @param {EntityInstanceRuleViolationOverrideApiService}
	 * entityInstanceRuleViolationOverrideApiService
	 * The entity instance rule violation override service used in this
	 * component.
	 * @memberof RuleOverrideComponent
	 */
	public constructor(
		public ruleService: RuleService,
		public activityService: ActivityService,
		public entityInstanceRuleViolationOverrideApiService:
			EntityInstanceRuleViolationOverrideApiService)
	{
		super();
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof RuleOverrideComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the selected item to be displayed in this override
	 * component.
	 *
	 * @type {IEntityInstanceRuleViolation}
	 * @memberof RuleOverrideComponent
	 */
	@Input() public selectedItem: IEntityInstanceRuleViolation;

	/**
	 * Gets or sets the event emitted used to notify listening components of
	 * a request to change the display mode.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof RuleOverrideComponent
	 */
	@Output() public changeDisplayMode: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets or sets the warning tooltip element reference.
	 *
	 * @type {ElementRef}
	 * @memberof RuleOverrideComponent
	 */
	@ViewChild('UndoTooltip')
	public undoTooltip: ElementRef;

	/**
	 * Gets or sets the data object used for the model in the displayed override
	 * form.
	 *
	 * @type {
	 *	reason: string
	 * }
	 * @memberof RuleOverrideComponent
	 */
	public overrideFormlyData: {
			reason: string;
		} = {
			reason: AppConstants.empty
		};

	/**
	 * Gets or sets the formly layout defining the displayed override form.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof RuleOverrideComponent
	 */
	public overrideFormlyLayout: FormlyFieldConfig[] =
		<FormlyFieldConfig[]>
		[
			{
				key: 'reason',
				type: FormlyConstants.customControls.customTextArea,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				props: {
					label: 'Reason',
					required: true,
					rows: 5,
					gridColumns: 12
				}
			}
		];

	/**
	 * Gets or sets the rule violation workflow actions to display.
	 *
	 * @type {IRuleViolationWorkflowActionDefinition[]}
	 * @memberof RuleOverrideComponent
	 */
	public ruleViolationWorkflowActions:
		IRuleViolationWorkflowActionDefinition[] = [];

	/**
	 * Gets or sets the initial rule violation workflow actions used to check
	 * if values have changed via user selections.
	 *
	 * @type {IRuleViolationWorkflowActionDefinition[]}
	 * @memberof RuleOverrideComponent
	 */
	public archivedRuleViolationWorkflowActions:
		IRuleViolationWorkflowActionDefinition[] = [];

	/**
	 * Gets or sets the changed value of workflow action definition overrides.
	 *
	 * @type {boolean}
	 * @memberof RuleOverrideComponent
	 */
	public isChanged: boolean = false;

	/**
	 * Gets or sets the valid form value of the formly layout holding only
	 * a reason.
	 *
	 * @type {boolean}
	 * @memberof RuleOverrideComponent
	 */
	public validReason: boolean = false;

	/**
	 * Gets or sets the saving value of workflow action definition overrides.
	 *
	 * @type {boolean}
	 * @memberof RuleOverrideComponent
	 */
	public saving: boolean = false;

	/**
	 * Gets or sets the override all display mode currently displayed.
	 *
	 * @type {boolean}
	 * @memberof RuleOverrideComponent
	 */
	public overrideAllDisplayMode: boolean = false;

	/**
	 * Gets the message to display for the undo changes icon.
	 *
	 * @type {string}
	 * @memberof RuleOverrideComponent
	 */
	public readonly undoTooltipMessage: string =
		'Reset these selections to their original values.';

	/**
	 * Handles the on initialization event.
	 * This will load the data needed to display an overridable rule item and
	 * set the value for our archived override workflow action state.
	 *
	 * @memberof RuleOverrideComponent
	 */
	public ngOnInit(): void
	{
		this.ruleViolationWorkflowActions =
			this.selectedItem
				.ruleDefinition
				.ruleViolationWorkflowActionDefinitions;

		this.overrideAllowed = this.getOverrideAllowed();
		this.syncArchivedValues();
	}

	/**
	 * Handles the on destroy event.
	 * This will ensure operations such as back and closing the overlay will
	 * resync the local model to database values.
	 *
	 * @memberof RuleOverrideComponent
	 */
	public ngOnDestroy(): void
	{
		this.resetValues();
	}

	/**
	 * Handles the after view initialization event.
	 * This will set the tooltip array value defined in the implemented tooltip
	 * directive.
	 *
	 * @memberof RuleOverrideComponent
	 */
	public ngAfterViewInit(): void
	{
		this.tooltips =
			[
				this.blockingTooltip,
				this.undoTooltip,
				this.warningTooltip
			];
	}

	/**
	 * Handles a change event from the set of checkboxes associated
	 * with the displayed rule workflow action table.
	 * This action will set the override all display mode as false, and
	 * calculate the changed value of the override selections.
	 *
	 * @memberof RuleOverrideComponent
	 */
	public overrideSelectionChanged(): void
	{
		this.overrideAllDisplayMode = false;

		this.isChanged = this.changed();
	}

	/**
	 * Handles the override all event sent from the column level checkbox.
	 * This action will override all overridable rules.
	 *
	 * @memberof RuleOverrideComponent
	 */
	public overrideAll(): void
	{
		this.ruleViolationWorkflowActions
			.forEach(
				(ruleViolationWorkflowAction:
					IRuleViolationWorkflowActionDefinition) =>
				{
					ruleViolationWorkflowAction.overridden =
						ruleViolationWorkflowAction.overridable;
				});

		this.overrideAllDisplayMode = true;
		this.isChanged = this.changed();
	}

	/**
	 * Handles the reset values event from the undo icon in the select all
	 * location.
	 * This action will reset all of the values of overridden to the values
	 * initially displayed prior to user input.
	 *
	 * @param {any} event
	 * The event that sent this reset values action.
	 * @memberof RuleOverrideComponent
	 */
	public resetValues(
		event: any = null): void
	{
		this.ruleViolationWorkflowActions
			.forEach(
				(ruleViolationWorkflowAction:
					IRuleViolationWorkflowActionDefinition,
				index: number) =>
				{
					ruleViolationWorkflowAction.overridden =
						this.archivedRuleViolationWorkflowActions[index]
							.overridden;
				});

		this.overrideAllDisplayMode = false;
		this.isChanged = this.changed();

		if (AnyHelper.isNull(event))
		{
			return;
		}

		this.preventDefault(event);
	}

	/**
	 * Handles the sync violations event sent from the override pages primary
	 * action button.
	 * Given the current set of selections displayed in the rule workflow
	 * action table for overrides, this will synchronize the database with
	 * the matching override selection including possible override removal.
	 *
	 * @async
	 * @memberof RuleOverrideComponent
	 */
	public async syncViolations(): Promise<void>
	{
		const entityInstanceId: number =
			(<EntityInstanceComponent>this.context.source).entityInstance.id;

		const promiseArray: Promise<any>[] = [];
		for (let index: number = 0;
			index < this.ruleViolationWorkflowActions.length;
			index++)
		{
			const ruleViolationWorkflowAction:
				IRuleViolationWorkflowActionDefinition =
					this.ruleViolationWorkflowActions[index];
			const previousOverriddenValue: boolean =
				this.archivedRuleViolationWorkflowActions[index].overridden;

			if (ruleViolationWorkflowAction.overridden
				!== previousOverriddenValue)
			{
				if (ruleViolationWorkflowAction.blocked === true
					&& ruleViolationWorkflowAction.overridden === true
					&& previousOverriddenValue === false)
				{
					promiseArray.push(
						this.entityInstanceRuleViolationOverrideApiService
							.create(
								<IEntityInstanceRuleViolationOverride>
								{
									instanceId:
										entityInstanceId,
									ruleDefinitionId:
										ruleViolationWorkflowAction
											.definitionId,
									workflowActionDefinitionId:
										ruleViolationWorkflowAction
											.workflowActionDefinitionId,
									reason:
										this.overrideFormlyData.reason,
									resourceIdentifier:
										this.selectedItem.resourceIdentifier
								}));

					continue;
				}

				// Does not get here due to continue.
				const matchingViolationOverride:
					IEntityInstanceRuleViolationOverride =
						await this
							.entityInstanceRuleViolationOverrideApiService
							.getSingleQueryResult(
								'RuleDefinitionId eq '
									+ `${this.selectedItem
										.ruleDefinitionId} `
									+ 'and InstanceId eq '
										+ `${this.selectedItem
											.instanceId} `
									+ 'and WorkflowActionDefinitionId eq '
										+ `${ruleViolationWorkflowAction
											.workflowActionDefinitionId} `
									+ 'and ResourceIdentifier eq '
										+ `'${this.selectedItem
											.resourceIdentifier}' `,
								AppConstants.empty,
								true);

				if (!AnyHelper.isNull(matchingViolationOverride))
				{
					promiseArray.push(
						this.entityInstanceRuleViolationOverrideApiService
							.delete(matchingViolationOverride.id));
				}
			}
		}

		await this.activityService.handleActivity(
			new Activity(
				Promise.all(promiseArray),
				'<strong>Updating Rule Overrides</strong>',
				'<strong>Updated Rule Overrides</strong>',
				'Updated rule overrides for '
					+ this.selectedItem.ruleDefinition.displayName
					+ AppConstants.characters.period,
				'Unable to update rule overrides for '
					+ this.selectedItem.ruleDefinition.displayName
					+ AppConstants.characters.period));

		if (this.context.source instanceof EntityInstanceComponent)
		{
			const component: EntityInstanceComponent =
				(<EntityInstanceComponent>this.context.source);
			await component.setEntityInstanceViolations();
			component.resetOperationButtonBar();
			FormlyHelper.fireViewCheck(
				component.formlyEntityLayout);
			EventHelper.dispatchRefreshBadgePromiseEvent(
				DynamicComponentLookup.supportedTypes.rulesComponent,
				DynamicComponentLookup.targetComponents.utilityMenuComponent);
		}

		this.changeDisplayMode.emit(
			AppConstants.displayMode.list);
	}

	/**
	 * Handles the cancel action by restting to the last saved value set
	 * and displaying the rules list.
	 *
	 * @memberof RuleOverrideComponent
	 */
	public cancel(): void
	{
		this.resetValues();
		this.changeDisplayMode.emit(AppConstants.displayMode.list);
	}

	/**
	 * Handles the reasons layout validity changes to ensure the required reason
	 * field value holds data.
	 *
	 * @memberof RuleOverrideComponent
	 */
	public validityChanged(
		isValid: boolean): void
	{
		this.validReason = isValid;
	}

	/**
	 * Synchronizes the current archived values array of rule violation workflow
	 * actions to the current set of rule violation workflow actions.
	 *
	 * @memberof RuleOverrideComponent
	 */
	private syncArchivedValues(): void
	{
		this.archivedRuleViolationWorkflowActions = [];

		this.ruleViolationWorkflowActions
			.forEach(
				(ruleViolationWorkflowAction:
					IRuleViolationWorkflowActionDefinition) =>
				{
					this.archivedRuleViolationWorkflowActions.push(
						{
							...ruleViolationWorkflowAction
						});
				});
	}

	/**
	 * Calculates and returns a truthy signifying if the selections of overrides
	 * have altered from the original override set.
	 *
	 * @returns {boolean}
	 * The changed value of all override selections displayed in the violation
	 * workflow action definition table.
	 * @memberof RuleOverrideComponent
	 */
	private changed(): boolean
	{
		return !this.ruleViolationWorkflowActions
			.every(
				(ruleViolationWorkflow:
					IRuleViolationWorkflowActionDefinition,
				index: number) =>
					ruleViolationWorkflow.overridden ===
						this.archivedRuleViolationWorkflowActions[index]
							.overridden);
	}
}