<ng-template
	ngFor
	let-message
	[ngForOf]="clientMessages">

	<div class="banner"
		[ngStyle]="{
			'right': getBannerXPosition()
		}"
		[ngClass]="{
			'info-shadow': (message.status === 'Info'),
			'success-shadow': (message.status === 'Complete'),
			'danger-shadow': (message.status === 'Error')
		}"
		[@dropInAnimation]="'in'"
		[@booleanFadeAnimation]="message.display">
		<p-card>
			<div class="ui-g banner-text no-vertical-padding"
				(mouseenter) ="bannerMouseIn(message)"
				(mouseleave) ="bannerMouseOut(message)"
				pButton
				[ngClass]="{
					'p-button-primary': (message.status === 'Info'),
					'p-button-success': (message.status === 'Complete'),
					'p-button-danger': (message.status === 'Error'),
					'not-expandable-banner': !message.extendedDetailsExist
				}">
				<div class="ui-g-11"
					[ngClass]="{
						'no-padding': message.extendedDetailsExist}">
					<div class="banner-title">
						<span class="message-container ellipsis-text"
							[innerHTML]="message.safeTitle">
						</span>
						<span class="extended-detail-icon"
							*ngIf="message.extendedDetailsExist">
							<i class="theme-color-dark-gray fa fa-angle-up">
							</i>
							<i class="theme-color-dark-gray fa fa-angle-down">
							</i>
						</span>
					</div>
					<div class="ellipsis-text">
						<span class="banner-content"
							[innerHTML]="message.safeContent">
						</span>
						<span class="banner-content banner-additional-details-available"
							*ngIf="message.extendedDetailsExist">
							Additional Details available on hover or click.
						</span>
					</div>
					<div *ngIf="message.extendedDetailsExist"
						class="extended-details-div">
						<p-scrollPanel styleClass="extended-details-container">
							<ul class="extended-details-scrollpanel-container">
								<ng-template
									ngFor
									let-detail
									[ngForOf]="message.extendedDetails">
									<li>
										<app-banner-detail
											[extendedDetail]="detail">
										</app-banner-detail>
									</li>
								</ng-template>
							</ul>
						</p-scrollPanel>
					</div>
				</div>
				<div class="ui-g-1 no-padding dismiss-container">
					<div class="dismiss-icon"
						(click)="dismissBanner(message, $event)">
					</div>
				</div>
			</div>
		</p-card>
	</div>
</ng-template>