/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	IApplicationMessage
} from '@shared/interfaces/application-messages/application-message.interface';

/**
 * A class representing an instance of an api error.
 *
 * @export
 * @class ApiError
 * @extends {Error}
 */
export class ApiError
	extends Error
{
	/**
	 * Creates an instance of an ApiError.
	 *
	 * @param {string} title
	 * The title for this error.
	 * @param {IApplicationMessage[]} messages
	 * The array of application messages returned
	 * for this api error.
	 * @memberof ApiError
	 */
	public constructor(
		public title: string,
		public messages: IApplicationMessage[] = [])
	{
		super(title);

		this.messages = messages;
		if (messages.length > 0)
		{
			this.message = this.messages[0].description;
		}
	}

	/**
	 * Gets or sets the status code of the api error.
	 *
	 * @type {number}
	 * @memberof ApiError
	 */
	public status?: number = null;
}