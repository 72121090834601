/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	EntityFormComponent
} from '@entity/components/entity-form/entity-form.component';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';

/**
 * A class representing the action to delete an entity instance from the page
 * context.
 *
 * @export
 * @class DeleteEntityAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class DeleteEntityAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a DeleteEntityAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof DeleteEntityAction
	 */
	public constructor(
		protected activityService: ActivityService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof DeleteEntityAction
	 */
	public operationName: string =
		'Delete';

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof DeleteEntityAction
	 */
	public async execute(): Promise<void>
	{
		if (!(this.pageContext.source instanceof EntityInstanceComponent))
		{
			throw new Error(
				'This delete entity source has not been implemented.');
		}

		const entityFormComponent: EntityFormComponent =
			this.pageContext.source.entityFormComponent;

		entityFormComponent.entityInstanceApiService.entityInstanceTypeGroup =
			entityFormComponent.entityType.group;
		await entityFormComponent.activityService.handleActivity(
			new Activity(
				entityFormComponent.entityInstanceApiService.delete(
					entityFormComponent.entityInstance.id),
				'<strong>Deleting</strong> '
					+ `${entityFormComponent.entityType.displayName}`,
				'<strong>Deleted</strong> '
					+ `${entityFormComponent.entityType.displayName}`,
				`${entityFormComponent.entityIdentifier} was deleted.`,
				`${entityFormComponent.entityIdentifier} was not deleted.`));

		if (AnyHelper.isNullOrWhitespace(this.postOperationAction)
			&& AnyHelper.isNullOrWhitespace(this.postOperationPromise))
		{
			entityFormComponent.location.back();
		}
	}
}