/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * A class containing static methods for mapping data
 * with string interpolation
 *
 * @memberof InterpolationHelper
 */
export class InterpolationHelper
{
	/**
	 * an object to hold template parser functions
	 * so that duplicates are not created if iterating.
	 *
	 * @static
	 * @type {object}
	 * @memberof InterpolationHelper
	 */
	private static parserCache: object = {};

	/**
	 * Gets the parsing function from the cache object
	 * or creates a new one to return and saves to cache.
	 *
	 * @static
	 * @param {string} template
	 * @returns {Function}
	 * @memberof InterpolationHelper
	 */
	public static getTemplateParser(template: string): Function
	{
		const parserCache: Function = this.parserCache[template];

		this.parserCache[template] =
			parserCache || this.createParser(template);

		return this.parserCache[template];
	}

	/**
	 * Creates the parsing function that takes
	 * an array of any and applies it to the template.
	 *
	 * @static
	 * @param {string} template
	 * @returns {Function}
	 * @memberof InterpolationHelper
	 */
	private static createParser(template: string): Function
	{
		// Ensure single level objects can utilize
		// this mapping intuitively
		const formattedTemplate: string =
			template.replace(
				/\${(.*?)\}/gm,
				(match: string, cleanMatch: string) =>
				{
					if (cleanMatch.indexOf('.') === -1)
					{
						return '${' + `data.${cleanMatch}` + '}';
					}

					return match;
				});

		const dataName: string =
			this.getDataName(formattedTemplate);

		return Function(
			dataName ?? 'context',
			`return \`${formattedTemplate}\``);
	}

	/**
	 * Gets the name of the data object to
	 * use in function call.
	 *
	 * @static
	 * @param {string} template
	 * @returns {string}
	 * @memberof InterpolationHelper
	 */
	private static getDataName(template: string): string
	{
		const dataExpressionMatches: string[]
			= template.match(/\$\{([\s]*[^;\s\{]+[\s]*)\}/g);

		if (!dataExpressionMatches)
		{
			return null;
		}

		const expressions: string[] = [];

		dataExpressionMatches
			.forEach(match =>
			{
				expressions.push(match
					.replace('${', '')
					.replace('}', ''));
			});

		return expressions[0]
			.split('.')[0]
			.split('[')[0];
	}
}