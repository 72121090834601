/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';

/**
 * A class representing the available methods (business logic) for a
 * session user.
 *
 * @export
 * @class User
 * @implements {IUser}
 */
export class User implements IUser
{
	/**
	 * Creates an instance of a User.
	 *
	 * @param {IUser} iUser
	 * The yser interface to create this new
	 * object from.
	 * @memberof IUser
	 */
	public constructor(
		public iUser: IUser)
	{
		Object.assign(this, iUser);
	}

	/**
	 * Gets or sets the id.
	 *
	 * @type {number}
	 * @memberof IUser
	 */
	public id: number;

	/**
	 * Gets or sets the data.
	 *
	 * @type {any}
	 * @memberof IUser
	 */
	public data: any;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {string}
	 * @memberof IUser
	 */
	public entityType: string;

	/**
	 * Gets or sets the security groups
	 * the user has access to.
	 *
	 * @type {ISecurityGroup[]}
	 * @memberof IUser
	 */
	public accessibleSecurityGroups: ISecurityGroup[];

	/**
	 * Gets or sets the security groups the user is a member of.
	 *
	 * @type {ISecurityGroup[]}
	 * @memberof IUser
	 */
	public membershipSecurityGroups: ISecurityGroup[];

	/**
	 * Checks against the set list of security groups and returns
	 * true if the current user holds a membership to one of the
	 * sent roles.
	 *
	 * @param {string[]} securityGroups
	 * The string array of allowed security groups.
	 * @returns {boolean}
	 * A boolean value representing user membership in one of the sent
	 * security roles.
	 * @memberof IUser
	 */
	public hasMembership(
		securityGroups: string[]): boolean
	{
		const mappedUserGroups: string[] =
			this.membershipSecurityGroups.map(
				(securityGroup: ISecurityGroup) =>
					securityGroup.name);

		return mappedUserGroups.filter(
			(mappedGroup: string) =>
				securityGroups.includes(
					mappedGroup)).length > 0;
	}
}