<ng-template [ngIf]="settingsActive === true">
	<div class="ui-g-12 parameter-container {{siteLayoutService.contentCssClass}}"
		[ngClass]="{
			'theme-box-shadow': useOverlay === true,
			'display-top-center': (displayAbove === true)
		}"
		clickOutside
		(clickOutside)="cancelClick()"
		[contentDisplayed]="settingsActive === true"
		[ignoreAppendedBodyPanelClicks]="true"
		(click)="containerClicked($event)">

		<div class="ui-g-12 no-vertical-padding parameter-container-title">
			{{displayComponentInstance.title}}
		</div>

		<ng-template
			[ngIf]="useScrollPanel === true"
			[ngIfElse]="FullHeightForm">
			<div #ScrollPanel class="scroll-panel"
				[ngClass]="{
					'fixed-height-scroll-panel': (useFixedHeightScrollPanel === true)
				}">
				<p-scrollPanel styleClass="parameter-form-container">
					<app-dynamic-formly
						[dataSet]="parameterLayoutData"
						[initialData]="archivedParameterLayoutData"
						[layoutSchema]="parameterLayoutSchema"
						[context]="pageContext"
						(validityChanged)="validParametersChanged($event)"
						(valuesChanged)="parametersChanged($event)">
					</app-dynamic-formly>
				</p-scrollPanel>
			</div>
		</ng-template>
		<ng-template #FullHeightForm>
			<div #FullHeightFormContainer class="full-height-form-container">
				<app-dynamic-formly
					[dataSet]="parameterLayoutData"
					[initialData]="archivedParameterLayoutData"
					[layoutSchema]="parameterLayoutSchema"
					[context]="pageContext"
					(validityChanged)="validParametersChanged($event)"
					(valuesChanged)="parametersChanged($event)">
				</app-dynamic-formly>
			</div>
		</ng-template>

		<div class="ui-g-12 text-right button-set">
			<a id="cancelParameterChanges"
				*ngIf="alwaysDisplayCancel ==true || valueChanged === true"
				class="text-link theme-color secondary-action-button"
				(click)="cancelClick($event)">
				Cancel
			</a>
			<button
				pButton
				type="button"
				label="Apply"
				class="no-margin-right"
				(click)="applyClick($event)"
				[disabled]="isValid === false || valueChanged === false">
			</button>
		</div>
	</div>
</ng-template>