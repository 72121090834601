/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	WorkflowActionInstancesApiService
} from '@api/services/workflow/workflow-action-instances.api.service';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	PageContextDirective
} from '@shared/directives/page-context.directive';
import {
	ChartFactory
} from '@shared/factories/chart-factory';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	ChartHelper
} from '@shared/helpers/chart.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IChartDefinition
} from '@shared/interfaces/application-objects/chart-definition.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IChartContext
} from '@shared/interfaces/dynamic-interfaces/chart-context.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	IWorkflowActionInstances
} from '@shared/interfaces/workflow/workflow-action-instances.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	DateTime
} from 'luxon';
import {
	SelectItem
} from 'primeng/api';
import {
	ActionHistoryExpandComponent
} from './action-history-expand/action-history-expand.component';

/* eslint-enable max-len */

@Component({
	selector: 'app-action-execution-history',
	templateUrl: './action-execution-history.component.html',
	styleUrls: [
		'./action-execution-history.component.scss'
	]
})

/**
 * A component representing an instance of the workflow engine action
 * execution history component.
 *
 * @export
 * @class ActionExecutionHistoryComponent
 * @extends {PageContextDirective}
 */
export class ActionExecutionHistoryComponent
	extends PageContextDirective
	implements OnInit
{
	/**
	 * Initializes a new instance of the ActionDefinitionComponent class.
	 *
	 * @param {WorkflowActionInstancesApiService}
	 * workflowActionInstancesApiService
	 * The api service used to gather action instance data.
	 * @param {WorkflowActionDefinitionsApiService}
	 * workflowActionDefinitionsApiService
	 * The api service used to gather action definition data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to gather entity type data.
	 * @param {ChartFactory} chartFactory
	 * The service to use chart configurations.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ActionExecutionHistoryComponent
	 */
	public constructor(
		public workflowActionInstancesApiService:
			WorkflowActionInstancesApiService,
		public workflowActionDefinitionsApiService:
			WorkflowActionDefinitionsApiService,
		public entityTypeApiService: EntityTypeApiService,
		public chartFactory: ChartFactory,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the display management context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public actionHistoryContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {object}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public availableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public selectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the table filter.
	 *
	 * @type {string}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public tableFilter: string = AppConstants.empty;

	/**
	 * Gets or sets the array of information menu items.
	 *
	 * @type {IInformationMenuItem<IAggregate[]>[]}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public informationMenuItems: IInformationMenuItem<IAggregate[]>[] = [];

	/**
	 * Gets or sets the string used to filter by the last thirty days.
	 *
	 * @type {string}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public lastThirtyDaysFilter: string;

	/**
	 * Gets or sets the string used to filter by the last seven days.
	 *
	 * @type {string}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public lastSevenDaysFilter: string;

	/**
	 * Gets or sets the label array to display in a last thirty day
	 * chart splitout.
	 *
	 * @type {DateTime[]}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public thirtyDayLabels: DateTime[] = [];

	/**
	 * Gets or sets the label array to display in a last seven day
	 * chart splitout.
	 *
	 * @type {DateTime[]}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public sevenDayLabels: DateTime[] = [];

	/**
	 * Gets or sets the string used to filter by todays instances. The current
	 * filter that will limit the query to instances createdDate
	 * greater than current date at 0:00:0.0 utc time and less than
	 * current date time.
	 *
	 * @type {string}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public todayFilter: string;

	/**
	 * Gets or sets the string used to filter by create date instances from
	 * seven days ago. The current filter that will limit the query to
	 * instances createdDate greater than 7 days ago at 0:00:0.0 utc time and
	 * less than current date time.
	 *
	 * @type {string}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public sevenDayFilter: string;

	/**
	 * Gets or sets the action name filter options.
	 *
	 * @type {SelectItem[]}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public actionNames: IWorkflowActionDefinitions[];

	/**
	 * Gets or sets the action definition.
	 *
	 * @type {IWorkflowActionDefinitions[]}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public actionDefinitions: IWorkflowActionDefinitions[];

	/**
	 * Sets the data chunk limit.
	 *
	 * @type {string}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public dataChunkLimit: number = 100;

	/**
	 * Gets or sets the query params.
	 *
	 * @type {IObjectSearch}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public queryParams: IObjectSearch =
		{
			filter: AppConstants.empty,
			orderBy: `Id ${AppConstants.sortDirections.ascending}`,
			offset: null,
			limit: this.dataChunkLimit
		};

	/**
	 * Gets or sets the definition name filter options.
	 *
	 * @type {SelectItem[]}
	 * @memberof ActionExecutionHistoryComponent
	 */
	public definitionNameFilterOptions: SelectItem[];

	/**
	 * Gets or sets the table row count.
	 *
	 * @type {number}
	 * @memberof AdminPageDirective
	 */
	public tableRowCount: number = 15;

	/**
	 * Gets or sets the loading table definitions state.
	 *
	 * @type {boolean}
	 * @memberof AdminPageDirective
	 */
	public loadingTableDefinitions: boolean = true;

	/**
	 * Gets or sets the common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof AdminPageDirective
	 */
	public commonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Sets the actions last thirty days string literal.
	 *
	 * @type {string}
	 * @memberof AdminPageDirective
	 */
	private readonly actionsLastThirtyDays: string = 'Actions Last 30 Days';

	/**
	 * Sets the number of action instances string literal.
	 *
	 * @type {string}
	 * @memberof AdminPageDirective
	 */
	private readonly numberOfActionInstances: string =
		'Number of Action Instances';

	/**
	 * Sets the action last seven days string literal.
	 *
	 * @type {string}
	 * @memberof AdminPageDirective
	 */
	private readonly actionsLastSevenDays: string = 'Actions Last 7 Days';

	/**
	 * Initializes the ActionExecution History Component page setup.
	 *
	 * @async
	 * @memberof AdminPageDirective
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		this.setupInformationMenuItems();
		this.setupTableDefinitions();
	}

	/**
	 * Sets up the table definitions for the standard table
	 *
	 * @memberof ActionExecutionHistoryComponent
	 */
	public setupTableDefinitions(): void
	{
		this.actionHistoryContext =
			<IDynamicComponentContext<Component, any>>
			{
				source: this
			};

		this.tableDefinitions = {
			tableTitle: 'Performed Actions',
			expandTitle: 'View Workflow History',
			objectSearch: {
				filter: this.tableFilter,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise:
				async (objectSearch: IObjectSearch) =>
				{
					const workflowActionInstances: IWorkflowActionInstances[] =
						await this.workflowActionInstancesApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);

					return workflowActionInstances;
				},
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			actions: {
				view: {
					customContext: this.actionHistoryContext,
					component: ActionHistoryExpandComponent,
					items: []
				},
				filter: {
					quickFilters: this.definitionNameFilterOptions,
					selectedFilterValue: this.tableFilter
				}
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Sets up variables used in this component.
	 *
	 * @async
	 * @memberof ActionExecutionHistoryComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		this.actionNames =
			await this.populateNames(
				{
					queryReturn: async(queryParams: any) =>
						this.workflowActionDefinitionsApiService
							.query(
								queryParams.filter,
								queryParams.orderBy,
								queryParams.offset,
								queryParams.limit),
					queryParams: this.queryParams
				});

		this.definitionNameFilterOptions = await this.mapDefinitionNames();

		const currentDate: DateTime = DateTime.local();

		const currentUtcDateTime: string =
			currentDate.toISO();

		const currentInitialDateTime: string =
			DateHelper.startOf(currentDate)
				.toISO();

		const sevenDaysAgoFromCurrentDateTime: string =
			DateHelper.startOf(currentDate)
				.minus({ days: 7 })
				.toISO();

		this.sevenDayFilter =
			`createDate > '${sevenDaysAgoFromCurrentDateTime}' AND `
				+ `createDate < '${currentUtcDateTime}'`;

		this.todayFilter =
			`createDate > '${currentInitialDateTime}' AND `
				+ `createDate < '${currentUtcDateTime}'`;

		this.lastThirtyDaysFilter =
			ApiFilterHelper.getLastNumberOfDaysFilter(
				'CreateDate',
				currentDate,
				AppConstants.days.thirtyDays);

		this.lastSevenDaysFilter =
			ApiFilterHelper.getLastNumberOfDaysFilter(
				'CreateDate',
				currentDate,
				AppConstants.days.sevenDays);

		this.thirtyDayLabels =
			ChartHelper.getLastNumberOfDayLabels(
				currentDate,
				AppConstants.days.thirtyDays);

		this.sevenDayLabels =
			ChartHelper.getLastNumberOfDayLabels(
				currentDate,
				AppConstants.days.sevenDays);

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'entityInstanceId',
					columnHeader: 'Entity Instance Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'actionDefinitionId',
					columnHeader: 'Action Definition Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'userId',
					columnHeader: 'User Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'createDate',
					dataFormatType: AppConstants.dataFormatTypes.date,
					columnHeader: 'Create Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'changeDate',
					dataFormatType: AppConstants.dataFormatTypes.date,
					columnHeader: 'Change Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'stateId',
					columnHeader: 'State Id',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up information menu items displayed in this component.
	 *
	 * @memberof ActionExecutionHistoryComponent
	 */
	public setupInformationMenuItems(): void
	{
		const primaryGroupByValues: string =
			'CreateDate.ConvertToSystemTime().Year, '
				+ 'CreateDate.ConvertToSystemTime().Month, '
				+ 'CreateDate.ConvertToSystemTime().Day';

		this.informationMenuItems =
		<IInformationMenuItem<IAggregate[]>[]>
		[
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise:
							this.workflowActionInstancesApiService.aggregate(
								AppConstants.aggregateMethods.count,
								null,
								this.lastThirtyDaysFilter,
								primaryGroupByValues),
						chartConfiguration: this.chartFactory.timeLineChart(
							this.actionsLastThirtyDays,
							this.thirtyDayLabels,
							[],
							this.numberOfActionInstances,
							ChartConstants.timeUnits.day,
							1,
							ChartConstants.formats.day)
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data:
							<IChartContext<IAggregate[]>>
							{
								chartDefinition:
									<IChartDefinition<IAggregate[]>>
									{
										dataPromise: this
											.workflowActionInstancesApiService
											.aggregate(
												AppConstants.aggregateMethods
													.count,
												null,
												this.lastThirtyDaysFilter,
												primaryGroupByValues),
										chartConfiguration:
											this.chartFactory.timeLineChart(
												this.actionsLastThirtyDays,
												this.thirtyDayLabels,
												[],
												this.numberOfActionInstances,
												ChartConstants.timeUnits.day,
												1,
												ChartConstants.formats.day),
										chartColors: [
											ChartConstants.themeColors.primary
										]
									},
								data: [],
								fillMissingDataSets: true,
								summaryCardDisplay: false
							},
						source: this
					},
				titleTemplate: this.actionsLastThirtyDays,
				width: AppConstants.sizeIdentifiers.extraLarge
			},
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise: this.workflowActionInstancesApiService
							.aggregate(
								AppConstants.aggregateMethods.count,
								null,
								this.lastSevenDaysFilter,
								primaryGroupByValues),
						chartConfiguration: this.chartFactory.timeLineChart(
							this.actionsLastSevenDays,
							this.sevenDayLabels,
							[],
							this.numberOfActionInstances,
							ChartConstants.timeUnits.day,
							1,
							ChartConstants.formats.day)
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data:
							<IChartContext<IAggregate[]>>
							{
								chartDefinition:
									<IChartDefinition<IAggregate[]>>
									{
										dataPromise: this
											.workflowActionInstancesApiService
											.aggregate(
												AppConstants.aggregateMethods
													.count,
												null,
												this.lastSevenDaysFilter,
												primaryGroupByValues),
										chartConfiguration:
											this.chartFactory.timeLineChart(
												this.actionsLastSevenDays,
												this.sevenDayLabels,
												[],
												this.numberOfActionInstances,
												ChartConstants.timeUnits.day,
												1,
												ChartConstants.formats.day),
										chartColors: [
											ChartConstants.themeColors.primary
										]
									},
								data: [],
								fillMissingDataSets: true,
								summaryCardDisplay: false
							},
						source: this
					},
				titleTemplate: this.actionsLastSevenDays,
				width: AppConstants.sizeIdentifiers.extraLarge
			},
			{
				dataPromise: this.workflowActionInstancesApiService.aggregate(
					AppConstants.aggregateMethods.count,
					null,
					this.todayFilter,
					null),
				summaryTemplate: '${data[0].value}',
				titleTemplate: 'Actions Today',
				width: AppConstants.sizeIdentifiers.large
			},
			<IInformationMenuItem<any>>
			{
				dataPromise: new Promise(async (resolve) =>
				{
					const actionInstances: IWorkflowActionInstances[] =
						await this.workflowActionInstancesApiService
							.query(
								`${this.sevenDayFilter}`,
								AppConstants.empty);

					let averageResponse: number = 0;

					actionInstances.forEach(
						(actionInstance: IWorkflowActionInstances) =>
						{
							averageResponse +=
								DateTime.fromISO(
									actionInstance.changeDate)
									.diff(
										DateTime.fromISO(
											actionInstance.createDate))
									.milliseconds;
						});

					const totalAverageTime: number =
						averageResponse / actionInstances.length;

					resolve(
						{
							averageDuration:
								`${this.getAverageTimeDuration(
									totalAverageTime)}`
						}
					);
				}),
				summaryTemplate: '${data.averageDuration}',
				titleTemplate: '7 Day Avg Duration',
				width: AppConstants.sizeIdentifiers.large,
				summaryCardDisplay: true,
				squareCardDisplay: false
			},
			<IInformationMenuItem<any>>
			{
				dataPromise: new Promise(async (resolve) =>
				{
					const actionInstances: IWorkflowActionInstances[] =
						await this.workflowActionInstancesApiService
							.query(
								`${this.todayFilter}`,
								AppConstants.empty);

					let averageResponse: number = 0;

					actionInstances.forEach(
						(actionInstance: IWorkflowActionInstances) =>
						{
							averageResponse +=
								DateTime.fromISO(
									actionInstance.changeDate)
									.diff(
										DateTime.fromISO(
											actionInstance.createDate))
									.milliseconds;
						});

					const totalAverageTime: number =
						averageResponse / actionInstances.length;

					resolve(
						{
							averageDuration:
								`${this.getAverageTimeDuration(
									totalAverageTime)}`
						}
					);
				}),
				summaryTemplate: '${data.averageDuration}',
				titleTemplate: 'Today Avg Duration',
				width: AppConstants.sizeIdentifiers.large,
				summaryCardDisplay: true,
				squareCardDisplay: false
			}
		];
	}

	/**
	 * Sets up the formly layout definitions.
	 *
	 * @param {number} averageTime
	 * The average time in miliseconds.
	 * @returns {string}
	 * The average time duration string in
	 * format mm:ss.ms
	 * @memberof ActionExecutionHistoryComponent
	 */
	public getAverageTimeDuration(
		averageTime: number): string
	{
		const dateTimeFromEpoch: DateTime =
			DateTime.fromMillis(averageTime);

		return !dateTimeFromEpoch.isValid
			? AppConstants.timeSpan.zeroMinutes
			: `${dateTimeFromEpoch.minute}:`
				+ `${dateTimeFromEpoch.second}.`
				+ `${dateTimeFromEpoch.millisecond}`;
	}

	/**
	 * Maps the filter options.
	 *
	 * @returns {Promise<SelectItem[]>}
	 * A select item array promise.
	 * @memberof ActionExecutionHistoryComponent
	 */
	public async mapDefinitionNames(): Promise<SelectItem[]>
	{
		const filterOptions =
			[
				{
					label: 'All',
					value: AppConstants.empty
				}
			];

		const entityTypes: IEntityType[] =
			await ApiHelper.getFullDataSet(
				this.entityTypeApiService,
				AppConstants.empty,
				AppConstants.empty);

		for (const actionName of this.actionNames)
		{
			for (const entityType of entityTypes)
			{
				if (entityType.id === actionName.entityTypeId)
				{
					filterOptions.push(
						{
							label:
								`${actionName.name} (${entityType.name})`,
							value:
								`ActionDefinitionId eq ${actionName.id}`
						});
				}
			}
		}

		return filterOptions
			.sort((
				optionOne: any,
				optionTwo: any) =>
				ObjectHelper.sortByPropertyValue(
					optionOne,
					optionTwo,
					'label'));
	}

	/**
	 * Populates the names object with all the existing.
	 *
	 * @async
	 * @param {any} actionDefinitions
	 * The existing action definitions
	 * @returns {Promise<any>}
	 * The awaitable promise data query.
	 * @memberof ActionExecutionHistoryComponent
	 */
	public async populateNames(actionDefinitions: any): Promise<any[]>
	{
		let dataChunk =
			await actionDefinitions
				.queryReturn(actionDefinitions.queryParams);

		let operations =
			[
				...dataChunk
			];

		while (dataChunk.length === this.dataChunkLimit)
		{
			actionDefinitions.queryParams.filter =
				`Id gt ${dataChunk[dataChunk.length - 1].id}`;

			dataChunk =
				await actionDefinitions
					.queryReturn(actionDefinitions.queryParams);

			operations =
				[
					...operations,
					...dataChunk
				];
		}

		return operations;
	}
}