/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A constant library used to defined names used in the system service
 * settings.
 *
 * @export
 * @const SystemServiceConstants
 */
export const SystemServiceConstants:
{
	chat: string;
	chatData: string;
	componentName: string;
	deploymentId: string;
	enabled: string;
	environment: string;
	knowledgeBase: string;
	knowledgeBaseUrl: string;
	modalService: string;
	offline: string;
	offlineMessage: string;
	walkthrough: string;
	widgetId: string;
} =
	{
		chat: 'chat',
		chatData: 'chatData',
		componentName: 'componentName',
		deploymentId: 'deploymentId',
		enabled: 'enabled',
		environment: 'environment',
		knowledgeBase: 'knowledgeBase',
		knowledgeBaseUrl: 'knowledgeBaseUrl',
		modalService: 'modalService',
		offline: 'offline',
		offlineMessage: 'offlineMessage',
		walkthrough: 'walkthrough',
		widgetId: 'widgetId'
	};