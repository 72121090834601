/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';

/* eslint-enable max-len */

@Component({
	selector: 'app-management',
	templateUrl: './management.component.html'
})

/**
 * A component representing a work items management view.
 *
 * @export
 * @class ManagementComponent
 */
export class ManagementComponent
{
	/**
	 * Gets or sets the loading value of this management component.
	 *
	 * @type {boolean}
	 * @memberof ManagementComponent
	 */
	public loading: boolean = false;
}