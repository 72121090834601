/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	AbstractControl
} from '@angular/forms';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IInputGroupItem
} from '@shared/interfaces/application-objects/input-group-item.interface';

@Component({
	selector: 'custom-input-radio-button',
	templateUrl: './custom-input-radio-button.component.html',
	styleUrls: [
		'./custom-input-radio-button.component.scss'
	],
})

/**
 * A component representing an instance of a Custom Input Radio Button.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomInputRadioButtonComponent
 * @extends {ExtendedCustomControlDirective}
 */
export class CustomInputRadioButtonComponent
	extends ExtendedCustomControlDirective
{
	/** Initializes a new instance of the CustomInputRadioButtonComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomInputRadioButtonComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Handles the focused radio button action
	 * to set the form control value.
	 * @param {IInputGroupItem} inputGroupItem
	 * The focused input group item.
	 * @memberof CustomInputRadioButtonComponent
	 */
	public focusedRadioButton(
		inputGroupItem: IInputGroupItem): void
	{
		this.field.formControl
			.setValue(inputGroupItem.key);

		this.validateControl();

		this.setInput(inputGroupItem);

		this.field.props.inputGroupItems.forEach(
			(inputItem: IInputGroupItem) =>
			{
				inputItem.disabledInput = true;
			});

		inputGroupItem.disabledInput = ! inputGroupItem.allowEntry;
	}

	/**
	 * Sets the input value to the value key additional field.
	 * @param {IInputGroupItem} inputGroupItem
	 * The selected input group item.
	 * @memberof CustomInputRadioButtonComponent
	 */
	public setInput(
		inputGroupItem: IInputGroupItem): void
	{
		if (!AnyHelper.isNullOrWhitespace(this.field.props.valueKey))
		{
			const valueField: AbstractControl =
				this.field.form.get(
					AppConstants.nestedDataIdentifier
						+ this.field.props.valueKey);

			if (!AnyHelper.isNull(valueField))
			{
				valueField.setValue(inputGroupItem.optionValue);

				this.validateControl();
			}
		}
	}
}