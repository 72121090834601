/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component,
	Input
} from '@angular/core';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';

@Component({
	selector: 'field-validation-message',
	templateUrl: './field-validation-message.component.html',
	styleUrls: [
		'./field-validation-message.component.scss'
	]
})

/**
 * A component representing an instance of a field validation message.
 *
 * @export
 * @class FieldValidationMessageComponent
 */
export class FieldValidationMessageComponent
{
	/**
	 * Gets or sets the field that is being validated.
	 *
	 * @type {FormlyFieldConfig}
	 * @memberof FieldValidationMessageComponent
	 */
	@Input() public field: FormlyFieldConfig;
}