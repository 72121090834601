/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';

@Component({
	selector: 'custom-table-display',
	templateUrl: './custom-table-display.component.html'
})

/**
 * A component representing an instance of a Custom Table Display.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomTableDisplayComponent
 * @extends {FieldType}
 */
export class CustomTableDisplayComponent
	extends ExtendedCustomControlDirective
{
	/**
	 * Initializes a new instance of the CustomTableDisplayComponent. This
	 * explicit constructor was requested during an upgrade process and
	 * should be left as implemented.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomTableDisplayComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * On component initialization event.
	 * This method is used to extend the update value and validity method
	 * of this form control to include change detection and to match
	 * the expected table interface.
	 * @memberof CustomTableDisplayComponent
	 */
	public ngOnInit(): void
	{
		this.field.props.tableDefinitions.selectedColumns =
			this.field.props.tableDefinitions.availableColumns;

		super.ngOnInit();
	}

	/**
	 * Handles the row select event promise
	 * when clicking on a displayed table row
	 * which has the selectable properties enabled.
	 *
	 * @param {string} capturedSelectedItemEventPromise
	 * The captured selected row event promise from template options.
	 * @memberof CustomTableDisplayComponent
	 */
	public async itemSelected(
		capturedSelectedItemEventPromise: string): Promise<void>
	{
		this.field.props.tableDefinitions.expandTitle =
			!AnyHelper.isNullOrWhitespace(
				this.field.props.tableDefinitions.defaultExpandTitle)
				? TableHelper.getExpandTitle(
					this.field.props.tableDefinitions.getCommonTableContext(),
					this.field.props.tableDefinitions.defaultExpandTitle)
				: this.field.props.tableDefinitions.expandTitle;

		if (!AnyHelper.isNullOrWhitespace(
			capturedSelectedItemEventPromise))
		{
			await StringHelper.transformToDataPromise(
				capturedSelectedItemEventPromise,
				this.field.props.context);
		}
	}
}