<div class="ui-g-12 no-padding">
	<div class="ui-g-12 no-horizontal-padding wizard-content-title">
	</div>
	<ng-template
		[ngIf]="context.source.wizardStepLoading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12 no-horizontal-padding text-center"
			*ngIf="paymentMethodsData.length === 0">
			<span class="panel-sub-title">
				At least one Credit Card or ACH payment method must be available to make payments.
			</span>
		</div>
		<div class="ui-g-12 content-section"
			*ngIf="paymentMethodsData.length > 0">

			<div class="ui-g-12 no-padding">
				<app-common-dashboard
					[entityContext]="context"
					dashboardDisplayComponentInstanceName = "PolicyTermPaymentScheduleDashboard">
				</app-common-dashboard>
			</div>

			<div class="ui-g-6 no-padding"
				[ngClass]="{
					'ui-g-12': siteLayoutService.displayTabletView,
					'no-padding': siteLayoutService.displayTabletView
				}">
				<app-next-payment-installment-details
					[context]="context">
				</app-next-payment-installment-details>
			</div>

			<div class="ui-g-6 no-padding"
				[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView,
					'no-padding': siteLayoutService.displayTabletView}">
				<app-dynamic-formly
					[dataSet]="context.source.activeMenuItem.currentData"
					[layoutSchema]="dynamicFormlyLayout"
					[context]="context"
					(validityChanged)="validityChanged($event)">
				</app-dynamic-formly>
			</div>
		</div>

	</ng-template>
</div>