/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	OperationGroupCreateChildComponent
} from '@admin/components/user-interface/operations/operation-groups/operation-group-expand/operation-group-create/operation-group-create-child.component';
import {
	Component
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	IOperationGroupRelationship
} from '@operation/interfaces/operation-group-relationship.interface';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	cloneDeep
} from 'lodash-es';

/* eslint-enable max-len */

@Component({
	selector: 'app-operation-group',
	templateUrl: './operation-group.component.html',
	styleUrls: [
		'./operation-group.component.scss'
	]
})

/**
 * A component representing an instance of the
 * operation group expand component.
 *
 * @export
 * @class OperationGroupComponent
 * @extends {CommonTablePageDirective}
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 */
export class OperationGroupComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<CommonTableComponent, any>
{
	/**
	 * Initializes a new instance of the OperationGroupComponent class.
	 *
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The api service used to load operation group data.
	 * @param {OperationDefinitionApiService} operationGroupApiService
	 * The api service used to load operation definition data.
	 * @param {OperationTypeApiService} operationTypeApiService
	 * The api service used to load operation type data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component to get its data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof OperationGroupComponent
	 */
	public constructor(
		public operationGroupApiService: OperationGroupApiService,
		public operationDefinitionApiService: OperationDefinitionApiService,
		public activityService: ActivityService,
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof OperationGroupComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the table definitions for the
	 * children common  only view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationGroupComponent
	 */
	public childrenTableDefinitionsView: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationGroupComponent
	 */
	public childViewAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationGroupComponent
	 */
	public childViewSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the table definitions for the
	 * children common table update view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationGroupComponent
	 */
	public childrenTableDefinitionsUpdate: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationGroupComponent
	 */
	public childUpdateAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationGroupComponent
	 */
	public childUpdateSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Sets the data chunk limit.
	 *
	 * @type {string}
	 * @memberof OperationGroupComponent
	 */
	public dataChunkLimit: number = 100;

	/**
	 * Gets ir Sets the operation query params.
	 *
	 * @type {IObjectSearch}
	 * @memberof OperationGroupComponent
	 */
	public operationQueryParams: IObjectSearch =
		{
			filter: AppConstants.empty,
			orderBy: `Id ${AppConstants.sortDirections.ascending}`,
			offset: null,
			limit: this.dataChunkLimit
		};

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof OperationGroupComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;

		this.childViewAvailableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'type',
					columnHeader: 'Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'order',
					columnHeader: 'Order',
					displayOrder: displayOrder++
				}
			];

		this.childViewSelectedColumns = this.childViewAvailableColumns;

		this.childUpdateAvailableColumns =
			[
				...this.childViewAvailableColumns
			];
		this.childUpdateSelectedColumns = this.childUpdateAvailableColumns;
	}

	/**
	 * Sets up the table definitions for a common table
	 *
	 * @async
	 * @memberof OperationGroupComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.childrenTableDefinitionsView =
			{
				tableTitle: 'Children',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Operation Child'),
				nestedTable: true,
				objectSearch:
					{
						filter: AppConstants.empty,
						orderBy:
							`order ${AppConstants.sortDirections.ascending}`,
						offset: 0,
						limit: AppConstants.dataLimits.large,
						virtualIndex: 0,
						virtualPageSize: 5
					},
				apiPromise:
					async () =>
					{
						const children: IOperationGroupRelationship[] =
							await this.operationGroupApiService
								.getChildren(
									this.context.source.selectedItem.id);

						return this.decorateViewModelArray(
							children);
					},
				availableColumns: this.childViewAvailableColumns,
				selectedColumns: this.childViewSelectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				}
			};

		this.childrenTableDefinitionsUpdate =
			{
				...cloneDeep(this.childrenTableDefinitionsView),
				actions:
					{
						create: {
							customContext:
								<IDynamicComponentContext<Component, any>>
									this.context,
							component: OperationGroupCreateChildComponent,
							items: [
								{
									label: 'Save',
									styleClass:
										AppConstants.cssClasses.pButtonPrimary,
									command: async() => this.createChild()
								}
							]
						},
						updateIndex: [
							{
								id: 'updateIndexUp',
								command: async(
									selectedItem: any) =>
									this.updateOrderIndex(
										selectedItem,
										-1)
							},
							{
								id: 'updateIndexDown',
								command: async(
									selectedItem: any) =>
									this.updateOrderIndex(
										selectedItem,
										1)
							}
						],
						delete: {
							deleteStatement: () =>
							{
								const groupName: string =
									this.context.source.selectedItem.name;
								const childName: string =
									this.commonTableContext.source
										.selectedItem.name;

								return 'Confirm to remove child '
									+ `${childName} `
									+ 'from operation group '
									+ `${groupName}.`;
							},
							items: [
								{
									label: 'Remove',
									styleClass:
										AppConstants.cssClasses.pButtonDanger,
									command: () => this.deleteChild()
								}
							]
						}
					}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Decorates the view model array.
	 *
	 * @param {IOperationGroupRelationship[]} modoperationGroupRelationshipsel
	 * The array to be decorated as a view model.
	 * @returns {Promise<any[]>}
	 * The mapped view model array for display in this component.
	 * @memberof OperationGroupComponent
	 */
	public async decorateViewModelArray(
		operationGroupRelationships:
			IOperationGroupRelationship[]): Promise<any[]>
	{
		const childrenData = [];
		let baseIdCounter = 1;

		for (const child of operationGroupRelationships)
		{
			let operationName: string = AppConstants.empty;
			if (child.type === 'OperationDefinition')
			{
				operationName =
					(await this.operationDefinitionApiService
						.get(child.id)).name;
			}
			else if (child.type === 'OperationGroup')
			{
				operationName =
					(await this.operationGroupApiService
						.get(child.id)).name;
			}

			childrenData.push(
				{
					id: baseIdCounter,
					baseId: child.id,
					order: child.order,
					name: operationName,
					type: child.type
				});

			baseIdCounter++;
		}

		return childrenData;
	}

	/**
	 * Updates the order index of the selected row item
	 * up or down based on the indexOperator.
	 *
	 * @async
	 * @param {any} selectedItem
	 * The selected item to update the index of.
	 * @param {number} indexReference
	 * The index reference to add or substract to the current
	 * selected order index.
	 * @memberof OperationGroupComponent
	 */
	private async updateOrderIndex(
		selectedItem: any,
		indexReference: number): Promise<void>
	{
		this.commonTableContext.source.loadingNextDataset = true;
		this.commonTableContext.source.selectedItem = selectedItem;

		const updateOrderIndex: Function =
			async() =>
			{
				const children: IOperationGroupRelationship[] =
					await this.operationGroupApiService
						.getChildren(this.context.source.selectedItem.id);
				children.sort(
					(itemOne: any,
						itemTwo: any) =>
						itemOne.order - itemTwo.order);

				const orderedChildren: IOperationGroupRelationship[] =
					[
						...children
					];

				const neighborOrderIndex: number =
					this.findSelectedChildIndex(
						orderedChildren,
						selectedItem) + indexReference;

				await this.operationGroupApiService
					.updateChild(
						this.context.source.selectedItem.id,
						<IOperationGroupRelationship>
						{
							id: orderedChildren[neighborOrderIndex].id,
							type: orderedChildren[neighborOrderIndex].type,
							order: 1000
						});

				await this.operationGroupApiService
					.updateChild(
						this.context.source.selectedItem.id,
						<IOperationGroupRelationship>
						{
							id: selectedItem.baseId,
							type: selectedItem.type,
							order: orderedChildren[neighborOrderIndex].order
						});

				await this.operationGroupApiService
					.updateChild(
						this.context.source.selectedItem.id,
						<IOperationGroupRelationship>
						{
							id: orderedChildren[neighborOrderIndex].id,
							type: orderedChildren[neighborOrderIndex].type,
							order: selectedItem.order
						});

				this.commonTableContext.source.virtualData[
					neighborOrderIndex].order =
						selectedItem.order;
				this.commonTableContext.source.selectedItem.order =
					orderedChildren[neighborOrderIndex].order;

				this.sortCommonTableByOrder();
				this.commonTableContext.source.loadingNextDataset = false;
			};

		await this.activityService.handleActivity(
			new Activity(
				updateOrderIndex(),
				'<strong>Updating Child Order</strong>',
				'<strong>Updated Child Order</strong>',
				'Child Order was successfully updated.',
				'Child Order was not updated.'),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Finds the selected child index.
	 *
	 * @param {IOperationGroupRelationship} children
	 * The children object array.
	 * @param {any} selectedChild
	 * The selected child.
	 * @memberof OperationGroupComponent
	 */
	private findSelectedChildIndex(
		children: IOperationGroupRelationship[],
		selectedChild: any): number
	{
		for (let index = 0; index < children.length; index++)
		{
			if (children[index].id === selectedChild.baseId
				&& children[index].type === selectedChild.type
				&& children[index].order === selectedChild.order)
			{
				return index;
			}
		}

		return -1;
	}

	/**
	 * Sorts the common table context data by order.
	 *
	 * @memberof OperationGroupComponent
	 */
	private sortCommonTableByOrder(): void
	{
		this.commonTableContext.source.virtualData
			.sort((itemOne: any, itemTwo: any) =>
				itemOne.order - itemTwo.order);
		this.commonTableContext.source.virtualData =
			[
				...this.commonTableContext.source.virtualData
			];
	}

	/**
	 * Creates a new child to the operation group.
	 *
	 * @async
	 * @memberof OperationGroupComponent
	 */
	private async createChild(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const createChild: Function =
			async() =>
			{
				await this.operationGroupApiService
					.createChild(
						this.context.source.selectedItem.id,
						{
							id: selectedItem.name,
							type: selectedItem.type,
							order: selectedItem.order
						});

				selectedItem.baseId = selectedItem.name;
				if (selectedItem.type === 'OperationDefinition')
				{
					selectedItem.name =
						(await this.operationDefinitionApiService
							.get(selectedItem.name)).name;
				}
				else if (selectedItem.type === 'OperationGroup')
				{
					selectedItem.name =
						(await this.operationGroupApiService
							.get(selectedItem.name)).name;
				}

				this.commonTableContext.source.addSelectedItem();
				this.sortCommonTableByOrder();
			};

		await this.activityService.handleActivity(
			new Activity(
				createChild(),
				'<strong>Creation Child Relationship</strong>',
				'<strong>Created Child Relationship</strong>',
				'Child Relationship was created.',
				'Child Relationship was not created.'));
	}

	/**
	 * Deletes an existing child to the operation group.
	 *
	 * @async
	 * @memberof OperationGroupComponent
	 */
	private async deleteChild(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const deleteChild: Function =
			async() =>
			{
				await this.operationGroupApiService
					.deleteChild(
						this.context.source.selectedItem.id,
						{
							id: selectedItem.baseId,
							type: selectedItem.type
						});

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteChild(),
				'<strong>Deleting Child Relationship</strong>',
				'<strong>Deleted Child Relationship</strong>',
				`Child Relationship ${selectedItem.baseId} ` +
					'was deleted.',
				`Child Relationship ${selectedItem.baseId} ` +
					'was not deleted.'));
	}
}