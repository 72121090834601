/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	NgModule
} from '@angular/core';
import {
	ScrollingModule
} from '@angular/cdk/scrolling';

@NgModule({
	imports: [
		ScrollingModule
	],
	exports: [
		ScrollingModule
	],
	providers: [],
	declarations: []
})

/**
 * A module to be shared accross the application used
 * to hold all angular cdk modules for import.
 *
 * @export
 * @class AngularCdkModule
 */
export class AngularCdkModule
{
}