<div class="ui-g-6 search-filter"
	[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView || noCategoryFilter}">
	<div>
		<app-dynamic-formly
			#DynamicFormlyComponent
			[dataSet]="dataSet"
			[layoutSchema]="layoutSchema">
		</app-dynamic-formly>
	</div>
	<button type="button"
		pButton
		icon="fa fa-search"
		styleClass="p-button-primary"
		(click)="searchCriteria($event)">
	</button>
	<div class="search-tooltip-container {{siteLayoutService.contentCssClass}}"
		[ngClass]="{'context-menu-displayed': siteLayoutService.contextMenuCapturedWidth > 0}">
		<app-common-icon-tooltip
			content="<div>Search terms entered here will filter down to only the items that hold all of the search terms.<ul><li>In order to search for dates, please use the following format 'YYYY-MM-DD'.</li><li>In order to search for numerical values, please enter the number without commas or format characters.</li></ul></div>"
			position="left"
			styleClass="label-tooltip"
			[escape]="false">
		</app-common-icon-tooltip>
	</div>
</div>