/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	Component
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-reasons',
	templateUrl: './transaction-reasons.component.html',
	styleUrls: [
		'./transaction-reasons.component.scss'
	]
})

/**
 * A component representing an associated transaction reasons used
 * in the associated entity list.
 *
 * @export
 * @class TransactionReasonsComponent
 * @implements {IDynamicComponent<Component, {
		entityInstance: IEntityInstance,
		entityListContext: IAssociatedEntityListContext
	}>}
 */
export class TransactionReasonsComponent
implements IDynamicComponent<Component, {
		entityInstance: IEntityInstance;
		entityListContext: IAssociatedEntityListContext;
	}>
{
	/**
	 * Initializes a new instance of the transaction reasons component.
	 *
	 * @param {Router} router
	 * The router used for policy dashboard navigation.
	 * @memberof TransactionReasonsComponent
	 */
	public constructor(
		public router: Router)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IEntityInstance>}
	 * @memberof TransactionReasonsComponent
	 */
	public context: IDynamicComponentContext<Component, {
			entityInstance: IEntityInstance;
			entityListContext: IAssociatedEntityListContext;
		}>;
}