/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Location
} from '@angular/common';
import {
	Component,
	EventEmitter,
	HostListener,
	Injector,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	ApiTokenLookup
} from '@api/api-token.lookup';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityEventParameterConstants
} from '@entity/shared/entity-event-parameter.constants';
import {
	EntityEventConstants
} from '@entity/shared/entity-event.constants';
import {
	FormlyFieldConfig,
	FormlyFormOptions
} from '@ngx-formly/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	EntityLayout
} from '@shared/implementations/entities/entity-layout';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	Observable
} from 'rxjs';

/* eslint-enable max-len */

@Component({
	selector: 'entity-form',
	templateUrl: './entity-form.component.html',
	styleUrls: ['./entity-form.component.scss'],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing the form view a formly displayed entity instance.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class EntityFormComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AppCanDeactivateGuard}
 */
export class EntityFormComponent
implements OnInit, AppCanDeactivateGuard
{
	/**
	 * Creates an instance of an EntityFormComponent.
	 *
	 * @param {Injector} injector
	 * The injector that can be used for methods in the layout.
	 * @param {ActivityService} activityService
	 * The activity service used to save or delete and notify the user.
	 * @param {Location} location
	 * The location used for navigation following a delete.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used to handle crud actions
	 * for the entity instance.
	 * @param {ResolverService} resolver
	 * The resolver service used for display component providers.
	 * @memberof EntityFormComponent
	 */
	public constructor(
		public injector: Injector,
		public activityService: ActivityService,
		public location: Location,
		public entityInstanceApiService: EntityInstanceApiService,
		public resolver: ResolverService)
	{
	}

	/**
	 * Gets or sets the page context sent to associated context utilities
	 * and menus.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof EntityFormComponent
	 */
	@Input() public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the entity display identifier. This is used
	 * for the page title and for messaging systems.
	 *
	 * @type {string}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityIdentifier: string;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {EntityType}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityType: EntityType;

	/**
	 * Gets or sets the entity version.
	 *
	 * @type {IEntityVersion}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityVersion: IEntityVersion;

	/**
	 * Gets or sets the entity type group.
	 *
	 * @type {string}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityTypeGroup: string;

	/**
	 * Gets or sets the formly form options.
	 *
	 * @type {FormlyFormOptions}
	 * @memberof EntityFormComponent
	 */
	@Input() public formlyOptions: FormlyFormOptions;

	/**
	 * Gets or sets the entity instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof EntityFormComponent
	 */
	@Input()public entityInstance: IEntityInstance;

	/**
	 * Gets or sets the entity definition.
	 *
	 * @type {EntityDefinition}
	 * @memberof EntityFormComponent
	 */
	@Input()public entityDefinition: EntityDefinition;

	/**
	 * Gets or sets the entity layout.
	 *
	 * @type {EntityLayout}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityLayout: EntityLayout;

	/**
	 * Gets or sets the entity instance rule violations.
	 *
	 * @type {IEntityInstanceRuleViolation[]}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityInstanceRuleViolations:
		IEntityInstanceRuleViolation[] = [];

	/**
	 * Gets or sets the formly entity layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof EntityFormComponent
	 */
	@Input() public formlyEntityLayout: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the data for the entity as it exists
	 * in the database.
	 *
	 * @type {IEntityInstance}
	 * @memberof EntityFormComponent
	 */
	@Input() public databaseEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the show content animation.
	 * Defaults true.
	 *
	 * @type {boolean}
	 * @memberof EntityFormComponent
	 */
	@Input() public showContentAnimation: boolean = true;

	/**
	 * Gets or sets active tab index.
	 *
	 * @type {number}
	 * @memberof EntityFormComponent
	 */
	@Input() public activeTabIndex: number;

	/**
	 * Gets or sets the event emitter to send this component instance to
	 * listening components.
	 *
	 * @type {EventEmitter<EntityFormComponent>}
	 * @memberof EntityFormComponent
	 */
	@Output() public componentInstance: EventEmitter<EntityFormComponent> =
		new EventEmitter<EntityFormComponent>();

	/**
	 * Gets or sets the shared api token lookup
	 * for injected services in formly layout functions.
	 *
	 * @type {ApiTokenLookup}
	 * @memberof EntityFormComponent
	 */
	public apiTokenLookup: ApiTokenLookup = ApiTokenLookup;

	/**
	 * Gets or sets the is saving value of this component.
	 *
	 * @type {boolean}
	 * @memberof EntityFormComponent
	 */
	public saving: boolean = false;

	/**
	 * Gets or sets the loading value of this component.
	 *
	 * @type {boolean}
	 * @memberof EntityFormComponent
	 */
	public loading: boolean = true;

	/**
	 * Handles the before unload event sent from the current window based
	 * on any action that will change the page.
	 *
	 * @memberof EntityFormComponent
	 * @returns {Observable<boolean> | boolean}
	 * The value that will allow the router to know if the data in this form
	 * is altered or not saved to the database. This implements the
	 * AppCanDeactivateGuard interface.
	 */
	@HostListener(
		WindowEventConstants.beforeUnloadEvent)
	public canDeactivate(): Observable<boolean> | boolean
	{
		return this.saving === false
			&& ObjectHelper.getBusinessLogicDifferences(
				'EntityFormCanDeactivate',
				this.databaseEntityInstance,
				this.entityInstance).length === 0;
	}

	/**
	 * Handles the enable/disable save entity event. This will
	 * fire via form based entity validation.
	 *
	 * @param {boolean} enabled
	 * If true, this will enable the entity save button otherwise
	 * it will be disabled.
	 * @memberof OperationButtonBarComponent
	 */
	@HostListener(
		EntityEventConstants.calculateEntityGridEvent,
		[
			EntityEventParameterConstants.gridColumns,
			EntityEventParameterConstants.sectionIndex

		])
	public calculateSectionGridColumns(
		gridColumns: string,
		sectionIndex: number): void
	{
		this.setNestedDynamicGridColumns(
			this.formlyEntityLayout,
			sectionIndex,
			gridColumns);

		this.componentInstance.emit(this);
	}

	/**
	 * On initialization event.
	 * Configures this components display based on the sent entity
	 * information from the consuming component.
	 *
	 * @memberof EntityFormComponent
	 */
	public ngOnInit(): void
	{
		if (!AnyHelper.isNullOrWhitespace(
			this.entityInstance.resourceIdentifier)
			&& this.formlyEntityLayout.length > 0
			&& this.formlyEntityLayout[0].type !==
				FormlyConstants.customControls.customResourceIdentifier)
		{
			this.formlyEntityLayout =
				[
					<FormlyFieldConfig>
					{
						key: AppConstants.commonProperties.resourceIdentifier,
						type: FormlyConstants.customControls
							.customResourceIdentifier,
						props: {}
					},
					...this.formlyEntityLayout
				];
		}

		this.componentInstance.emit(this);
		this.loading = false;

		// Allow Formly to implement defaults and display values.
		setTimeout(
			() =>
			{
				EventHelper.dispatchSiteLayoutChangedEvent();
			},
			AppConstants.time.quarterSecond);
	}

	/**
	 * Sets the nested dynamic grid columns.
	 *
	 * @param {FormlyFieldConfig[]} formlyLayout
	 * The formly layout.
	 * @param {number} sectionIndex
	 * The section index to be updated.
	 * @param {string} dynamicGridColumns
	 * The dynamic grid columns to be set.
	 * @memberof EntityFormComponent
	 */
	public setNestedDynamicGridColumns(
		formlyLayout: FormlyFieldConfig[],
		sectionIndex: number,
		dynamicGridColumns: string): void
	{
		if (formlyLayout.length === 0)
		{
			return;
		}

		formlyLayout.forEach(
			(formlyField: FormlyFieldConfig) =>
			{
				if ((!FormlyHelper.isCustomFieldWrapper(formlyField)
					&& FormlyHelper.hasNestedFieldGroup(formlyField))
					|| FormlyHelper.isTabWrapper(formlyField))
				{
					formlyField.fieldGroup.forEach(
						(nestedField: FormlyFieldConfig) =>
						{
							if (AnyHelper.isNullOrEmptyArray(
								nestedField.fieldGroup))
							{
								this.setDynamicGridColumns(
									nestedField,
									sectionIndex,
									dynamicGridColumns);

								return;
							}

							this.setNestedDynamicGridColumns(
								nestedField.fieldGroup,
								sectionIndex,
								dynamicGridColumns);
						});

					return;
				}

				this.setDynamicGridColumns(
					formlyField,
					sectionIndex,
					dynamicGridColumns);
			});
	}

	/**
	 * Sets the dynamic grid columns of a field on a determined
	 * section index.
	 *
	 * @param {FormlyFieldConfig} formlyField
	 * The formly field.
	 * @param {number} sectionIndex
	 * The section index that requires update.
	 * @param {string} dynamicGridColumns
	 * The dynamic grid columns to be set.
	 * @memberof EntityFormComponent
	 */
	private setDynamicGridColumns(
		formlyField: FormlyFieldConfig,
		sectionIndex: number,
		dynamicGridColumns: string): void
	{
		if (!AnyHelper.isNull(formlyField.props.attributes)
			&& formlyField.props.attributes[
				FormlyConstants.attributeKeys.sectionIndex] === sectionIndex
			&& AnyHelper.isNullOrEmpty(formlyField.props.gridColumns))
		{
			formlyField.props.dynamicGridColumns = dynamicGridColumns;
		}
	}
}