/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	OperationDefinitionComponent
} from '@admin/components/user-interface/operations/operation-definitions/operation-expand/operation-definition.component';
import {
	Component
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IOperationDefinitionParameter
} from '@operation/interfaces/operation-definition-parameter.interface';
import {
	IOperationDefinition
} from '@operation/interfaces/operation-definition.interface';
import {
	IOperationGroup
} from '@operation/interfaces/operation-group.interface';
import {
	IOperationTypeParameter
} from '@operation/interfaces/operation-type-parameter.interface';
import {
	IOperationType
} from '@operation/interfaces/operation-type.interface';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	cloneDeep
} from 'lodash-es';

/* eslint-enable max-len */

@Component({
	selector: 'app-operation-definitions',
	templateUrl: './operation-definitions.component.html',
	styleUrls: ['./operation-definitions.component.scss']
})

/**
 * A component representing an instance of the operation definitions component.
 *
 * @export
 * @class OperationDefinitionsComponent
 * @extends {CommonTablePageDirective}
 */
export class OperationDefinitionsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the logs component and sets up
	 * the list columns to be displayed in the logs table.
	 *
	 * @param {OperationService} operationService
	 * The api service used to load the operation data.
	 * @param {OperationDefinitionApiService} operationDefinitionsApiService
	 * The api service used to load the operation definition data.
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The api service used to load the operation group data.
	 * @param {SessionService} sessionService
	 * The session service used to load the session data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service to be displayed in the Activity List Component.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof OperationDefinitionsComponent
	 */
	public constructor(
		public operationService: OperationService,
		public operationDefinitionsApiService: OperationDefinitionApiService,
		public operationGroupApiService: OperationGroupApiService,
		public sessionService: SessionService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionsComponent
	 */
	public operationDefinitionsTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the security group options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public securityGroupOptions: IDropdownOption[] = [];

	/**
	 * Gets or sets the operation type options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public operationTypeOptions: IDropdownOption[] = [];

	/**
	 * Gets or sets the security groups from database.
	 *
	 * @type {any[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public securityGroups: ISecurityGroup[];

	/**
	 * Gets or sets the operation Types from database.
	 *
	 * @type {any[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public operationTypes: IOperationType[];

	/**
	 * Gets the field value debounce time.
	 *
	 * @type {number}
	 * @memberof OperationDefinitionsComponent
	 */
	private readonly fieldValueDebounceTime: number = 2000;

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof OperationDefinitionsComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;

		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'operationTypeName',
					columnHeader: 'Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'label',
					columnHeader: 'Label',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'icon',
					columnHeader: 'Icon',
					displayOrder: displayOrder
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the list column definitions for the current
	 * operation definition object list.
	 *
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		await this.operationService.setStoredVariables();
		this.securityGroups =
			this.sessionService.user.accessibleSecurityGroups;
		this.securityGroupOptions =
			await this.optionsFactory.getSecurityGroupOptions(
				this.sessionService.user.accessibleSecurityGroups);

		await this.operationService.setStoredVariables();
		this.operationTypeOptions = [];
		this.operationService.operationTypes.forEach(
			(operationType) =>
			{
				this.operationTypeOptions
					.push(
						{
							value: operationType.id,
							label: operationType.name
						});
			});

		const itemLayout: FormlyFieldConfig[] =
			[
				{
					key: 'name',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls
							.customFieldWrapper
					],
					props: {
						label: 'Name',
						required: true,
						maxLength: 64
					},
					asyncValidators: {
						uniqueName: {
							expression:
								async (control: UntypedFormControl) =>
									this.isExistingName(control),
							message: 'Existing definition Name.'
						}
					}
				},
				{
					key: 'typeId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls
							.customFieldWrapper
					],
					props: {
						label: 'Type',
						required: true,
						placeholder: 'Select an Operation Type',
						options: this.operationTypeOptions,
						showClear: true,
						appendTo: FormlyConstants.appendToTargets.body
					}
				},
				{
					key: 'label',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls
							.customFieldWrapper
					],
					props: {
						label: 'Label',
						maxLength: 64
					}
				},
				{
					key: 'description',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls
							.customFieldWrapper
					],
					props: {
						label: 'Description',
						required: true,
						maxLength: 256
					}
				},
				{
					key: 'icon',
					type: FormlyConstants.customControls
						.customIconInput,
					wrappers: [
						FormlyConstants.customControls
							.customFieldWrapper
					],
					props: {
						label: 'Icon',
						externalLinkTooltip: 'Font Awesome Icons',
						externalLink: AppConstants
							.externalUrls.fontAwesomeIcons,
						maxLength: 256
					}
				},
				{
					key: 'securityGroups',
					type: FormlyConstants.customControls
						.customMultiSelect,
					wrappers: [
						FormlyConstants.customControls
							.customFieldWrapper
					],
					props: {
						label: 'Security Groups',
						placeholder: 'Select a Security Group',
						required: true,
						options: this.securityGroupOptions,
						appendTo: FormlyConstants.appendToTargets.body
					}
				},
				{
					...CommonFormlyFieldConstants
						.publicField
				},
				{
					...CommonFormlyFieldConstants
						.ownershipSecurityGroupField,
					props: {
						...CommonFormlyFieldConstants
							.ownershipSecurityGroupField
							.props,
						label: 'Definition Owner(s)',
						options: this.securityGroupOptions,
						appendTo: FormlyConstants.appendToTargets.body
					}
				}
			];

		const updateLayout: FormlyFieldConfig[] =
			[
				...cloneDeep(itemLayout).map(
					(layoutItem: FormlyFieldConfig) =>
						layoutItem.key === 'typeId'
							? {
								...layoutItem,
								asyncValidators: {
									allowedToChange: {
										expression:
											async(control:
												UntypedFormControl) =>
												this.allowedToChange(control),
										message:
											 'Existing definition Parameter.'
									}
								}
							}
							: layoutItem)
			];

		this.operationDefinitionsTableDefinitions =
			{
				tableTitle: 'Operations',
				objectSearch:
					{
						filter: AppConstants.empty,
						orderBy: `Id ${AppConstants.sortDirections.descending}`,
						offset: 0,
						limit: AppConstants.dataLimits.large,
						virtualIndex: 0,
						virtualPageSize: this.tableRowCount
					},
				apiPromise:
					async(objectSearch: IObjectSearch) =>
					{
						const operationDefinitions: IOperationDefinition[] =
							<IOperationDefinition[]>
							await this.operationDefinitionsApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						operationDefinitions.forEach(
							(operationDefinition) =>
							{
								this.decorateViewModel(operationDefinition);
							});

						return operationDefinitions;
					},
				decorateViewModel:
					(model: any) => this.decorateViewModel(model),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Operation Definition'),
				actions: {
					create: {
						component: OperationDefinitionComponent,
						layout:
							[
								...cloneDeep(itemLayout)
							],
						items:
							[
								{
									label: 'Create',
									icon: AppConstants.empty,
									id: 'createOperationDefinition',
									styleClass: 'ui-button-primary',
									command: () =>
										this.createOperationDefinition()
								}
							]
					},
					view: {
						component: OperationDefinitionComponent,
						layout:
							FormlyHelper.disableAllFields(
								[
									...cloneDeep(itemLayout)
								]),
						items: []
					},
					update: {
						component: OperationDefinitionComponent,
						layout:
							FormlyHelper.disableAllFields(
								[
									...cloneDeep(updateLayout)
								],
								false),
						items: [
							{
								label: 'Save',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () =>
									this.updateOperationDefinition()
							}]
					},
					delete: {
						component: OperationDefinitionComponent,
						items:
							[
								{
									label: 'Confirm',
									styleClass:
										AppConstants.cssClasses.pButtonDanger,
									disabled: false,
									command: async() =>
										this.deleteOperationDefinition()
								}
							],
						deleteStatement: () =>
							this.deleteStatementLogic()
					}
				},
				commonTableContext:
					(commonTableContext:
						IDynamicComponentContext<
							CommonTableComponent,
							any>) =>
					{
						this.commonTableContext = commonTableContext;
					}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Decorates the view model for the operation definition object.
	 *
	 * @param {any} model
	 * The operation definition object to be decorated as a view model.
	 * @returns {Promise<any>}
	 * The mapped view model for display in this component.
	 * @memberof OperationDefinitionsComponent
	 */
	public async decorateViewModel(
		model: any): Promise<any>
	{
		model.operationTypeName =
			this.getOperationTypeName(
				model);

		return model;
	}

	/**
	 * Returns a delete statement string
	 * based on the operation groups and
	 * parameters associated to an operation definition.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * The delete statement to be displayed on the component.
	 * @memberof OperationDefinitionsComponent
	 */
	private async deleteStatementLogic(): Promise<string>
	{
		await this.operationService.setStoredVariables();
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;
		const displayParameters = [];

		const operationTypeParameters =
			this.operationService.operationTypeParameters
				.filter(
					(operationTypeParameter: IOperationTypeParameter) =>
						operationTypeParameter.typeId === selectedItem.typeId);

		let operationDefinitionParameters = [];

		for (const operationTypeParameter of operationTypeParameters)
		{
			operationDefinitionParameters =
				[
					...this.operationService.operationDefinitionParameters
						.filter(
							(definitionParameter:
								IOperationDefinitionParameter) =>
								definitionParameter.typeParameterId ===
									operationTypeParameter.id
									&& definitionParameter.definitionId
										=== selectedItem.id)
				];

			operationDefinitionParameters
				.forEach(
					(operationDefinitionParameter) =>
					{
						displayParameters.unshift({
							id: operationDefinitionParameter.id,
							name: operationTypeParameter.name,
							value: operationDefinitionParameter.value});
					});
		}

		const childOperationGroupHierarchies: IOperationGroup[] =
			<IOperationGroup[]>
			await this.operationGroupApiService
				.query(
					'ChildOperationGroupHierarchies.Any(ChildDefinitionId eq '
						+ selectedItem.id + ')',
					AppConstants.empty);

		let deleteStatement: string;
		if (displayParameters.length > 0
			|| childOperationGroupHierarchies.length > 0)
		{
			this.operationDefinitionsTableDefinitions
				.actions.delete.items[0].disabled = true;

			deleteStatement =
				`Not allowed to delete Operation Definitions with
					associated Operation Groups or Parameters.
					Remove them first.`;
		}
		else
		{
			this.operationDefinitionsTableDefinitions
				.actions.delete.items[0].disabled = false;

			const operationDefinition: IOperationDefinition[] =
				this.operationService.operationDefinitions
					.filter(
						(definition: IOperationDefinition) =>
							definition.id ===
								selectedItem.id);

			deleteStatement =
				`Confirm you are about to delete Operation Definition
					${selectedItem.id}
					${operationDefinition[0].name}.`;
		}

		return deleteStatement;
	}

	/**
	 * Deletes an operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async deleteOperationDefinition(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		await this.activityService.handleActivity(
			new Activity(
				this.operationDefinitionsApiService
					.delete(selectedItem.id),
				'<strong>Deleting Operation Definition</strong>',
				'<strong>Deleted Operation Definition</strong>',
				`Operation Definition ${selectedItem.id} `
					+ 'was deleted.',
				`Operation Definition ${selectedItem.id} `
					+ 'was not deleted.'));

		this.commonTableContext.source.deleteSelectedItem();
	}

	/**
	 * Updates an operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async updateOperationDefinition(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const updateOperationDefinition =
			async () =>
			{
				const operationDefinitionData: IOperationDefinition =
					<IOperationDefinition>
					{
						id: selectedItem.id,
						name: selectedItem.name,
						description: selectedItem.description,
						label: selectedItem.label,
						icon: selectedItem.icon,
						typeId: selectedItem.typeId,
						public: selectedItem.public,
						ownershipSecurityGroupId:
							selectedItem.ownershipSecurityGroupId,
						createdById: selectedItem.createdById
					};

				await this.operationDefinitionsApiService
					.update(
						selectedItem.id,
						operationDefinitionData);

				const mappedSecurityGroups: number[] =
					await this.operationDefinitionsApiService
						.getSecurityGroups(
							selectedItem.id);

				mappedSecurityGroups.sort(
					(itemOne: number,
						itemTwo: number) =>
						(itemOne - itemTwo));

				selectedItem
					.securityGroups.sort(
						(itemOne: number,
							itemTwo: number) =>
							(itemOne - itemTwo));

				if (mappedSecurityGroups !==
					selectedItem.securityGroups)
				{
					for (const mappedSecurityGroup of mappedSecurityGroups)
					{
						await this.operationDefinitionsApiService
							.deleteSecurityGroupMap(
								selectedItem.id,
								mappedSecurityGroup);
					}

					for (const securityGroup of
						selectedItem.securityGroups)
					{
						await this.operationDefinitionsApiService
							.createSecurityGroupMap(
								this.commonTableContext.source.selectedItem.id,
								securityGroup);
					}
				}

				await this.commonTableContext.source.updateSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				updateOperationDefinition(),
				'<strong>Updating Operation Definition</strong>',
				'<strong>Updated Operation Definition</strong>',
				'Operation Definition '
					+ `${selectedItem.id} `
					+ 'was updated.',
				'Operation Definition '
					+ `${selectedItem.id} `
					+ 'was not updated.'),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Creates an operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async createOperationDefinition(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;
		await this.operationService.setStoredVariables();

		const createOperationDefinition =
			async () =>
			{
				const operationDefinitionData: IOperationDefinition =
					<IOperationDefinition>
					{
						id: selectedItem.id,
						name: selectedItem.name,
						description: selectedItem.description,
						label: selectedItem.label,
						icon: selectedItem.icon,
						typeId: selectedItem.typeId,
						public: selectedItem.public,
						ownershipSecurityGroupId:
							selectedItem.ownershipSecurityGroupId
					};

				const createdOperationId: number =
					await this.operationDefinitionsApiService
						.create(
							operationDefinitionData);
				selectedItem.id = createdOperationId;

				const promiseArray: Promise<number>[] = [];
				selectedItem.securityGroups.forEach(
					(securityGroupId: number) =>
					{
						this.securityGroups
							.forEach(
								(securityGroup: ISecurityGroup) =>
								{
									if (securityGroupId ===
										securityGroup.id)
									{
										promiseArray.push(
											this.operationDefinitionsApiService
												.createSecurityGroupMap(
													createdOperationId,
													securityGroup.id));
									}
								});
					});
				await Promise.all(promiseArray);

				// Reset this value to get the fresh created by id.
				this.decorateViewModel(selectedItem);
				this.commonTableContext.source.selectedItem =
					{
						...selectedItem,
						...await this.operationDefinitionsApiService.get(
							selectedItem.id)
					};

				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				createOperationDefinition(),
				'<strong>Creating Operation Definition</strong>',
				'<strong>Created Operation Definition</strong>',
				`Operation Definition ${selectedItem.name}
					was successfully created.`,
				`Operation Definition ${selectedItem.name}
					was not created.`));
	}

	/**
	 * Defines and validates if the value is existing or not.
	 * Note: This validation will not find matches if the user does not have
	 * query permissions for the operation definition entity with a
	 * matching name.
	 *
	 * @async
	 * @param {UntypedFormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationDefinitionsComponent
	 */
	private async isExistingName(
		control: UntypedFormControl): Promise<boolean>
	{
		const initialPromiseArray: Promise<any>[] =
			[
				this.operationDefinitionsApiService
					.query(
						`Name.ToLower() eq '${control.value.toLowerCase()}'`,
						AppConstants.empty)
			];

		initialPromiseArray.push(
			this.operationDefinitionsApiService
				.query(
					`Id eq ${this.commonTableContext.source.selectedItem.id}`,
					AppConstants.empty));

		return Promise.all(
			initialPromiseArray)
			.then(
				async(
					[
						typedOperationNames,
						existingOperations
					]) =>
				{
					if (typedOperationNames.length > 0
						&& typedOperationNames[0].name !==
							existingOperations[0]?.name)
					{
						return Promise.resolve(false);
					}

					return Promise.resolve(true);
				});
	}

	/**
	 * Defines and validates if the typeId is allowed to
	 * be modified depending on the existing parameter type.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationDefinitionsComponent
	 */
	private async allowedToChange(control: UntypedFormControl): Promise<boolean>
	{
		await this.operationService.setStoredVariables();
		let allowToChange: boolean = false;
		let operationDefinitionParameter: object[] = [];
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const existingParameters: IOperationDefinitionParameter[] =
			this.operationService.operationDefinitionParameters
				.filter(
					(definitionParameter:
						IOperationDefinitionParameter) =>
						definitionParameter.definitionId ===
							selectedItem.id);

		if (!AnyHelper.isNullOrEmpty(control.value))
		{
			const typeParameterDefinition =
				this.operationService.operationTypeParameters
					.filter(
						(operationTypeParameter: IOperationTypeParameter) =>
							operationTypeParameter.typeId === control.value);

			operationDefinitionParameter =
				typeParameterDefinition.length > 0
					? this.operationService.operationDefinitionParameters
						.filter(
							(definitionParameter:
								IOperationDefinitionParameter) =>
								definitionParameter.definitionId ===
									selectedItem.id
								&& definitionParameter.typeParameterId ===
									typeParameterDefinition[0].id)
					: [];
		}

		if (existingParameters.length === 0
			|| operationDefinitionParameter.length > 0)
		{
			allowToChange = true;
		}
		else
		{
			const persistedData: IOperationDefinition[] =
				this.operationService.operationDefinitions
					.filter(
						(operationDefinition: IOperationDefinition)	=>
							operationDefinition.id === selectedItem.id);

			if (control.value !== persistedData[0].typeId)
			{
				setTimeout(
					() =>
					{
						control.setValue(
							persistedData[0].typeId,
							{
								emitEvent: false,
								emitModelToViewChange: true,
								emitViewToModelChange: false
							});
					},
					this.fieldValueDebounceTime);
			}
		}

		return Promise.resolve(allowToChange);
	}

	/**
	 * Gets the operation type name based on the operation definition.
	 *
	 * @param {IOperationDefinition} operationDefinition
	 * The operation definition object.
	 * @returns {string}
	 * The operation type name.
	 * @memberof OperationDefinitionsComponent
	 */
	private getOperationTypeName(
		operationDefinition: IOperationDefinition): string
	{
		return this.operationTypeOptions
			.find(
				(option: IDropdownOption) =>
					option.value ===
						operationDefinition.typeId)
			?.label;
	}
}