<app-base-page pageTitle="Entity {{entityType?.name}} Overview"
	[pageContext]="pageContext"
	[loading]="loadingDefinitions"
	pageContextOperationGroupName="AdminEntity.PageContext"
	utilityMenuOperationGroupName="">

	<div *ngIf="loadingDefinitions"
		class="text-center ui-g-12">
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>

</app-base-page>