/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	HttpHeaders
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	isArray
} from 'lodash-es';

/**
 * A class representing an API Service.
 *
 * @export
 * @class BaseApiService
 */
@Injectable()
export abstract class BaseApiService
{
	/**
	 * Returns an http header set that includes a 'query-only-get' header value
	 * of true. This is used for the cache http interceptor to ensure unique
	 * post/put/patch calls are not added to the background sync task post
	 * update.
	 *
	 * @returns {HttpHeaders}
	 * A headers object available to be added to requests to ensure this does
	 * not call a background sync action on execute.
	 * @memberof BaseApiService
	 */
	public get queryOnlyGetHeaders(): HttpHeaders
	{
		let queryOnlyGetHeaders =
			new HttpHeaders();
		queryOnlyGetHeaders =
			queryOnlyGetHeaders.set(
				AppConstants.httpHeaders.queryOnlyGet,
				'true');

		return queryOnlyGetHeaders;
	}

	/**
	 * Builds a query string value given a url and an object.
	 *
	 * @param {string} url
	 * A string representing the base URL to prepend to the generated output.
	 * @param {object} data
	 * An {object} representing data to parse into query string parameters.
	 * @returns {string}
	 * A string representing a fully formed URL with parameters correctly
	 * formatted.
	 * @memberof BaseApiService
	 */
	public formUrlParam(
		url: string,
		data: object): string
	{
		let queryString: string = AppConstants.empty;

		for (const key in data)
		{
			if (data.hasOwnProperty(key)
				&& data[key] != null)
			{
				const dataValue: any = data[key];
				const dataIsAnArray: boolean = isArray(dataValue);

				queryString +=
					AnyHelper.isNullOrWhitespace(queryString)
						? AppConstants.characters.questionMark
						: AppConstants.characters.and;

				if (dataIsAnArray === false)
				{
					queryString +=
						`${key}=${this.encodeParameter(dataValue)}`;
				}
				else
				{
					queryString +=
						this.formArrayParameter(
							key,
							dataValue);
				}
			}
		}

		return url + queryString;
	}

	/**
	 * Builds a query string value given an array of values as a parameter.
	 *
	 * @param {string} key
	 * A string representing the key value of this array property.
	 * @param {any[]} data
	 * An {object} representing data array to parse into query string
	 * parameters.
	 * @returns {string}
	 * A string representing a fully formed URL with parameters correctly
	 * formatted for this array parameter.
	 * @memberof BaseApiService
	 */
	private formArrayParameter(
		key: string,
		data: any[]): string
	{
		let queryString: string = AppConstants.empty;

		for (let index: number = 0;
			index < data.length;
			index ++)
		{
			if (index !== 0)
			{
				queryString += AppConstants.characters.and;
			}

			queryString +=
				`${key}=${this.encodeParameter(data[index])}`;
		}

		return queryString;
	}

	/**
	 * Handles encoding that must occur outside of the standard
	 * encodeURIComponent method offered by Angular.
	 *
	 * @param {any} dataValue
	 * An {object} representing data that will become a url parameter.
	 * @returns {any}
	 * A parameter value that is safely encoded for a url request.
	 * @memberof BaseApiService
	 */
	private encodeParameter(
		dataValue: any): any
	{
		if (typeof(dataValue) !== AppConstants.propertyTypes.string)
		{
			return dataValue;
		}

		return dataValue.replace(/\+/gi, '%2B');
	}
}