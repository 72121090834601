/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	IChannelFeed
} from '@shared/interfaces/really-simple-syndication/channel-feed.interface';
import {
	IImageFeed
} from '@shared/interfaces/really-simple-syndication/image-feed.interface';
import {
	IItemFeed
} from '@shared/interfaces/really-simple-syndication/item-feed.interface';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	XMLParser
} from 'fast-xml-parser';
import {
	DateTime
} from 'luxon';

/* eslint-enable max-len */

/**
 * A singleton class representing the
 * really simple sindication feed reader service.
 *
 * @export
 * @class ReallySimpleSyndicationFeedReaderService
 */
@Injectable({
	providedIn: 'root'
})
export class ReallySimpleSyndicationFeedReaderService
{
	/** Initializes a new instance of the
	 * Really Simple Syndication Feed Reader Service.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service to get the feed xml data.
	 * @memberof ReallySimpleSyndicationFeedReaderService
	*/
	 public constructor(
		public entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Gets or Sets the feed channel content.
	 *
	 * @type {any}
	 * @memberof ReallySimpleSyndicationFeedReaderService
	*/
	private feedChannelContent: any;

	/**
	 * Gets or sets the access token expiry time.
	 *
	 * @type {DateTime}
	 * @memberof ReallySimpleSyndicationFeedReaderService
	*/
	private localDataExpiry: DateTime;

	/**
	 * Gets the number of minutes prior to the application
	 * access token expiring to call out for new data.
	 *
	 * @type {number}
	 * @memberof ReallySimpleSyndicationFeedReaderService
	*/
	private readonly minutesBeforeRefresh: number =
		AppConstants.time.thirtyMinutes;

	/**
	 * Gets a value representing the current expired value
	 * of this services access token.
	 *
	 * @type {boolean}
	 * @memberof PowerBiApiService
	*/
	private get expiredData(): boolean
	{
		return this.localDataExpiry <= DateTime.local();
	}

	/**
	 * An awaitable promise to read the rss feed content.
	 *
	 * @async
	 * @param {string} feedSourceUrl
	 * The feed source url to get the content from.
	 * @returns {Promise<IChannelFeed>}
	 * The feed content translated into a standardized view model.
	 * @memberof ReallySimpleSyndicationFeedReaderService
	*/
	public async readFeedContent(feedSourceUrl: string): Promise<IChannelFeed>
	{
		this.feedChannelContent =
			await this.getLocalData(
				this.feedChannelContent,
				feedSourceUrl,
				this.getReallySimpleSyndicationFeedResponse.bind(this));

		return this.convertfeedContentToDataModel(
			this.feedChannelContent);
	}

	/**
	 * Clears any stored variables.
	 * This will allow to get new data
	 * on the next data call.
	 *
	 * @memberof ReallySimpleSyndicationFeedReaderService
	 */
	 public clearStoredVariables(): void
	 {
		 this.feedChannelContent = null;
	 }

	/**
	 * Gets the weather forecast mapped data object.
	 *
	 * @param {any} cachedData
	 * The local cached data.
	 * @param {string} feedSourceUrl
	 * The feed source url.
	 * @param {() => Promise<Response>} getFreshData
	 * Gets new data for the local cache.
	 * @returns {Promise<Response>}
	 * The local data either fresh or cached.
	 * If data is null or the time has expired then
	 * it will get fresh data, otherwise will use cached.
	 * @memberof ReallySimpleSyndicationFeedReaderService
	*/
	private async getLocalData(
		cachedData: any,
		feedSourceUrl: string,
		getFreshData: (feedSourceUrl: string) =>
			Promise<Response>): Promise<Response>
	{
		if (AnyHelper.isNull(cachedData)
			|| this.expiredData === true)
		{
			this.localDataExpiry =
				DateHelper.addTimeUnit(
					DateTime.local(),
					this.minutesBeforeRefresh,
					DateHelper.timeUnits.minute);

			return Promise.resolve(getFreshData(feedSourceUrl));
		}
		else
		{
			return cachedData;
		}
	}

	/**
	 * Converts the rss feed data response into an
	 * standardized and generic view model.
	 *
	 * @param {any} feedChannelContent
	 * This is the actual rss feed content response.
	 * @returns {IChannelFeed}
	 * The feed content converted into a standardized view model.
	 * @memberof ReallySimpleSyndicationFeedReaderService
	 */
	private convertfeedContentToDataModel(
		feedChannelContent: any): IChannelFeed
	{
		const feedItems: IItemFeed[] =
			this.getFeedItems(feedChannelContent.item);

		const channelFeed: IChannelFeed =
			<IChannelFeed>
			{
				title: feedChannelContent.title,
				description: feedChannelContent.description,
				image:
				<IImageFeed>
				{
					height: feedChannelContent.image.height,
					width: feedChannelContent.image.width,
					link: feedChannelContent.image.link,
					title: feedChannelContent.image.title,
					url: feedChannelContent.image.url
				},
				items: feedItems,
				mainLink: feedChannelContent.link,
				updateFrequency: feedChannelContent['sy:updateFrequency'],
				updatePeriod: feedChannelContent['sy:updatePeriod'],
				generator: feedChannelContent.generator,
				lastBuildDate: feedChannelContent.lastBuildDate,
				language: feedChannelContent.language
			};

		return channelFeed;
	}

	/**
	 * Gets the standardized feed items.
	 *
	 * @param {any[]} feedItems
	 * The feed items from the rss response.
	 * @returns {IItemFeed[]}
	 * The standardized feed items.
	 * @memberof ReallySimpleSyndicationFeedReaderService
	*/
	private getFeedItems(feedItems: any[]): IItemFeed[]
	{
		const channelFeedItems: IItemFeed[] = [];
		for (const feedItem of feedItems)
		{
			channelFeedItems.push({
				title: feedItem.title,
				description: feedItem.description,
				link: feedItem.link,
				publicationDate: feedItem.pubDate,
				categorizationTaxonomy: feedItem.category,
				globallyUniqueid: feedItem.guid
			});
		}

		return channelFeedItems;
	}

	/**
	* Gets the really simple syndication feed response data.
	*
	* @async
	* @param {string} feedSourceUrl
	* This is the rss feed source url used to get the data response.
	* @returns {Promise<any>}
	* The feed reponse data.
	* @memberof ReallySimpleSyndicationFeedReaderService
	*/
	private async getReallySimpleSyndicationFeedResponse(
		feedSourceUrl: string): Promise<any>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
				AppConstants.typeGroups.systems;
		const syndicationFeed: IActionResponse =
			await this.entityInstanceApiService
				.getReallySimpleSyndicationFeed(
					parseInt(
						AppConstants.systemId,
						AppConstants.parseRadix),
					feedSourceUrl);
		const xmlContentOutput =
			new XMLParser().parse(syndicationFeed.value.data.feed);

		return xmlContentOutput.rss.channel;
	}
}