/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ApplicationRef,
	Injectable
} from '@angular/core';
import {
	SwUpdate,
	UnrecoverableStateEvent,
	VersionReadyEvent
} from '@angular/service-worker';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IIntervalService
} from '@shared/interfaces/application-objects/interval-service.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	Observable,
	Subscriber,
	concat,
	filter,
	first,
	interval
} from 'rxjs';

/**
 * A singleton class that monitors for application updates.
 *
 * @export
 * @class UpdateService
 */
@Injectable({
	providedIn: 'root'
})
export class UpdateService implements IIntervalService
{
	/**
	 * Creates an instance of UpdateService
	 *
	 * @param {ApplicationRef} appReference
	 * The application reference to check for stability.
	 * @param {SwUpdate} swUpdate
	 * The SwUpdate that communicates withthe service worker.
	 * @param {Activity} activityService
	 * The activity service to handle update activities and benners.
	 * @memberof UpdateService
	 */
	public constructor(
		public appReference: ApplicationRef,
		public swUpdate: SwUpdate,
		public activityService: ActivityService)
	{
	}

	/**
	 * Gets or sets the update check subscriber
	 *
	 * @type {Subscriber<number | boolean>}
	 * @memberof UpdateService
	 */
	private checkForUpdatesSubscriber: Subscriber<number | boolean>;

	/**
	 * Gets or sets the update available subscriber
	 *
	 * @type {Subscriber<any>}
	 * @memberof UpdateService
	 */
	private updateFoundSubscriber: Subscriber<VersionReadyEvent>;

	/**
	 * Gets or sets the unrecoverable state subscriber
	 *
	 * @type {Subscriber<UnrecoverableStateEvent>}
	 * @memberof UpdateService
	 */
	private unrecoverableStateSubscriber: Subscriber<UnrecoverableStateEvent>;

	/**
	 * Starts the service
	 *
	 * @param {number} serviceInterval
	 * The interval in milliseconds to wait between
	 * checking for updates.
	 * @memberof UpdateService
	 */
	public start(
		serviceInterval: number): void
	{
		if (this.swUpdate.isEnabled)
		{
			this.unrecoverableStateSubscriber = this
				.watchForUnrecoverableState();

			this.updateFoundSubscriber = this
				.watchForUpdatesFound();

			this.checkForUpdatesSubscriber = this
				.checkForUpdatesEvery(serviceInterval);
		}
	}

	/**
	 * Stops the service.
	 *
	 * @memberof UpdateService
	 */
	public stop(): void
	{
		if (this.swUpdate.isEnabled)
		{
			this.checkForUpdatesSubscriber.unsubscribe();
			this.updateFoundSubscriber.unsubscribe();
			this.unrecoverableStateSubscriber.unsubscribe();
		}
	}

	/**
	 * checks to see if the current
	 * page is a login page.
	 *
	 * @returns {boolean}
	 * The result of the check. True if on the login page.
	 * @memberof UpdateService
	 */
	public onLoginPage(): boolean
	{
		return window
			.location
			.href
			.includes('/login');
	}

	/**
	 * Refreshes the page.
	 *
	 * @memberof UpdateService
	 */
	public refresh(): void
	{
		window.location.reload();
	}

	/**
	 * Watches for application updates and alerts the user.
	 *
	 * @returns {any}
	 * An any is required here due to the fact that the
	 * return type of subscribe() is @see {Subscription}, however
	 * at runtime it returns a @see {Subscriber<VersionReadyEvent>}.
	 * @memberof UpdateService
	 */
	private watchForUpdatesFound(): any
	{
		const updatesAvailable: any =
			this.swUpdate
				.versionUpdates
				.pipe(
					filter(
						(event: any): event is VersionReadyEvent =>
							event.type === 'VERSION_READY'));

		return updatesAvailable
			.subscribe(
				async(event: VersionReadyEvent) =>
				{
					if (this.onLoginPage())
					{
						const updateActivated: boolean =
							await this.swUpdate
								.activateUpdate();

						if (updateActivated === true)
						{
							if (this.onLoginPage())
							{
								this.refresh();
							}
							else
							{
								EventHelper
									.dispatchBannerEvent(
										'Page Refresh Required',
										'New application activated.',
										AppConstants.activityStatus.error,
										event);
							}
						}

						return;
					}

					EventHelper
						.dispatchBannerEvent(
							'Application Update Found',
							'Refresh to get new application version.',
							AppConstants.activityStatus.error,
							event);

					const updateFound: Activity<void> =
						new Activity<void>(
							null,
							'Application Update Available',
							'Application Update Available',
							'Application Update Available',
							'Update Available Error');

					await this.activityService
						.generateActivityBanner(
							updateFound,
							AppConstants.activityStatus.info,
							'Application Update Found');
				});
	}

	/**
	 * Checks for application updates on an repeating interval
	 * of the supplied milliseconds
	 *
	 * @param timeInMilliSeconds
	 * The time in milliseconds to wait to check again.
	 * @returns {any}
	 * An any is required here due to the fact that the
	 * return type of subscribe() is @see {Subscription}, however
	 * at runtime it returns a @see {Subscriber<number|boolean>}.
	 * @memberof UpdateService
	 */
	private checkForUpdatesEvery(
		timeInMilliSeconds: number ): any
	{
		const stabilityObservable: Observable<boolean> = this
			.appReference
			.isStable
			.pipe(first(isStable =>
				isStable === true));

		const intervalObservable = interval(timeInMilliSeconds);

		const checkForUpdates = concat(
			stabilityObservable,
			intervalObservable);

		return checkForUpdates
			.subscribe(
				async () =>
				{
					await this.swUpdate
						.checkForUpdate();
				});
	}

	/**
	 * Watches for unrecoverable service worker state and
	 * allertgs the user to refresh.
	 *
	 * @returns {any}
	 * An any is required here due to the fact that the
	 * return type of subscribe() is @see {Subscription}, however
	 * at runtime it returns a @see {Subscriber<UnrecoverableStateEvent>}.
	 * @memberof UpdateService
	 */
	private watchForUnrecoverableState(): any
	{
		return this.swUpdate
			.unrecoverable
			.subscribe((event: UnrecoverableStateEvent) =>
			{
				EventHelper
					.dispatchBannerEvent(
						'Please <a onclick="window.location.reload(true);" '
							+ 'class="text-link banner-text-link '
							+ 'standard-hover">'
							+ 'refresh</a> your page.',
						event.reason,
						AppConstants.activityStatus.error,
						event);
			});
	}
}