/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 *  An enum for security right types.
 *
 * @export
 */
export enum SecurityRightType
{
	/**
	 * Designates create rights.
	 *
	 * @type {string}
	 * @memberof SecurityRightType
	 */
	create = 'create',

	/**
	 * Designates read rights.
	 *
	 * @type {string}
	 * @memberof SecurityRightType
	 */
	read = 'read',

	/**
	 * Designates update rights.
	 *
	 * @type {string}
	 * @memberof SecurityRightType
	 */
	update = 'update',

	/**
	 * Designates delete rights.
	 *
	 * @type {string}
	 * @memberof SecurityRightType
	 */
	delete = 'delete',

	/**
	 * Designates execute rights.
	 *
	 * @type {string}
	 * @memberof SecurityRightType
	 */
	execute = 'execute'
}