/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	HttpClient,
	HttpHeaders
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ReportConstants
} from '@shared/constants/report.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	IPowerBiDashboard
} from '@shared/interfaces/reports/power-bi/power-bi-dashboard.interface';
import {
	IPowerBiDataset
} from '@shared/interfaces/reports/power-bi/power-bi-dataset.interface';
import {
	IPowerBiReportDefinition
} from '@shared/interfaces/reports/power-bi/power-bi-report-definition.interface';
import {
	IPowerBiReportLookup
} from '@shared/interfaces/reports/power-bi/power-bi-report-lookup.interface';
import {
	IPowerBiReport
} from '@shared/interfaces/reports/power-bi/power-bi-report.interface';
import {
	IPowerBiTile
} from '@shared/interfaces/reports/power-bi/power-bi-tile.interface';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	DateTime
} from 'luxon';
import {
	IEmbedConfiguration,
	IVisualEmbedConfiguration,
	models
} from 'powerbi-client';
import {
	lastValueFrom,
	map
} from 'rxjs';

/* eslint-enable max-len */

/**
 * A singleton class representing a power bi api service.
 *
 * @export
 * @class PowerBiApiService
 */
@Injectable({
	providedIn: 'root'
})
export class PowerBiApiService
{
	/**
	 * Creates an instance of a helper class for interacting with
	 * the power bi rest api service.
	 * @see https://docs.microsoft.com/en-us/rest/api/power-bi/reports
	 *
	 * @param {HttpClient} httpClient
	 * The http client used when making rest api calls.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used for workflow action requests.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service for handling site layout.
	 * @memberof PowerBiApiService
	 */
	public constructor(
		public httpClient: HttpClient,
		public entityInstanceApiService: EntityInstanceApiService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the current access token promise
	 * if being called in parallel.
	 *
	 * @type {Promise<IActionResponse>}
	 * @memberof PowerBiApiService
	 */
	public accessTokenPromise: Promise<IActionResponse>;

	/**
	 * Gets the number of minutes prior to the application
	 * access token expiring to call out for new data.
	 *
	 * @type {string}
	 * @memberof PowerBiApiService
	 */
	public readonly minutesBeforeExpireToRenew: number = 5;

	/**
	 * Gets or sets the access token expiry time getter and setter.
	 *
	 * @type {DateTime}
	 * @memberof PowerBiApiService
	 */
	public get accessTokenExpiry(): DateTime
	{
		return this._accessTokenExpiry;
	}
	public set accessTokenExpiry(
		accessTokenExpiry: DateTime)
	{
		this._accessTokenExpiry = accessTokenExpiry;
	}

	/**
	 * Gets or sets the access token expiry time.
	 *
	 * @type {DateTime}
	 * @memberof PowerBiApiService
	 */
	private _accessTokenExpiry: DateTime;

	/**
	 * Gets or sets the access token variable.
	 *
	 * @type {string}
	 * @memberof PowerBiApiService
	 */
	private accessToken: string;

	/**
	 * Gets or sets the embed effective identity user name.
	 *
	 * @type {string}
	 * @memberof PowerBiApiService
	 */
	private embedUsername: string;

	/**
	 * Gets the available embed report roles for effective identity tokens.
	 *
	 * @type {object}
	 * @memberof PowerBiApiService
	 */
	private readonly embedReportRoles:
		{
			dataReader: string;
		} = {
			dataReader: 'DataReaders'
		};

	/**
	 * Gets the base power bi rest api url for all queries.
	 *
	 * @type {string}
	 * @memberof PowerBiApiService
	 */
	private readonly basePowerBiApiUrl: string =
		'https://api.powerbi.com/v1.0/myorg';

	/**
	 * Gets the generate token command used when requesting an access token.
	 *
	 * @type {string}
	 * @memberof PowerBiApiService
	 */
	private readonly generateTokenIdentifier: string = 'GenerateToken';

	/**
	 * Gets a value representing the current expired value
	 * of this services access token.
	 *
	 * @type {boolean}
	 * @memberof PowerBiApiService
	 */
	private get expiredToken(): boolean
	{
		return AnyHelper.isNull(this.accessToken)
			|| this.accessTokenExpiry <= DateTime.local();
	}

	/**
	 * Creates and returns authentication headers from the sent external
	 * authentication access token.
	 *
	 * @param {string} accessToken
	 * The access token received from external azure authentication.
	 * @returns {HttpHeaders}
	 * The set of authentication headers required to query the power
	 * bi rest api service.
	 * @memberof PowerBiApiService
	 */
	public getAuthenticationHeaders(
		accessToken: string): HttpHeaders
	{
		let authenticationHeaders =
			new HttpHeaders();
		authenticationHeaders =
			authenticationHeaders.set(
				AppConstants.webApi.authorizationKey,
				`Bearer ${accessToken}`);

		return authenticationHeaders;
	}

	/**
	 * Creates and returns an embed token that can be used for
	 * embed configurations.
	 *
	 * @async
	 * @param {string} reportId
	 * The report id this embed token will grant access to.
	 * @param {string} groupId
	 * The group id this embed token will grant access to.
	 * @param {string} datasetId
	 * The dataset id this embed token will grant access to.
	 * @param {string} reportType
	 * The report type this embed token will grant access to.
	 * This value defaults to a type of report.
	 * @param {string} dashboardTileId
	 * The dashboard tile id this embed token will grant access to.
	 * This value defaults to an empty string.
	 * @param {boolean} viewOnly
	 * If this is false, the report will display in edit mode. This
	 * value defaults to true.
	 * @returns {Promise<any>}
	 * An awaitable promise holding the embed token return from the
	 * power bi rest api service matching the server access token.
	 * @memberof PowerBiApiService
	 */
	public async generateEmbedToken(
		reportId: string,
		groupId: string,
		datasetId: string,
		datasetGroupId: string,
		reportType: string = ReportConstants.powerBiObjectTypes.report,
		dashboardTileId: string = AppConstants.empty,
		viewOnly: boolean = true): Promise<any>
	{
		const calculatedReportType: string =
			this.getCalculatedReportType(
				reportType);

		let url: string =
			`${this.basePowerBiApiUrl}/groups/`
				+ `${groupId}/${calculatedReportType}s/`
				+ `${reportId}/${this.generateTokenIdentifier}`;

		if (!AnyHelper.isNullOrWhitespace(dashboardTileId))
		{
			url = url.replace(
				this.generateTokenIdentifier,
				`tiles/${dashboardTileId}/${this.generateTokenIdentifier}`);
		}

		const queryParameters: any =
			<any>
			{
				accessLevel:
					viewOnly === true
						? AppConstants.viewTypes.view
						: AppConstants.viewTypes.edit
			};

		const dataSets: IPowerBiDataset[] =
			await this.groupLevelData<IPowerBiDataset>(
				datasetGroupId,
				ReportConstants.powerBiObjectTypes.dataset);
		const dataSet: IPowerBiDataset =
			dataSets.filter(
				(item: IPowerBiDataset) =>
					item.id === datasetId)[0];

		return this.generateToken(
			url,
			queryParameters,
			dataSet);
	}

	/**
	 * Creates and returns an create token that can be used to create a
	 * report from an existing dataset.
	 *
	 * @async
	 * @param {IPowerBiDataset} dataset
	 * The dataset this create token will grant access to.
	 * @returns {Promise<any>}
	 * An awaitable promise holding the create token return from the
	 * power bi rest api service matching the server access token.
	 * @memberof PowerBiApiService
	 */
	public async generateCreateToken(
		dataSet: IPowerBiDataset): Promise<any>
	{
		const url: string =
			`${this.basePowerBiApiUrl}/${this.generateTokenIdentifier}`;

		const queryParameters: any =
			<any>
			{
				accessLevel: models.Permissions.ReadWrite,
				datasets: [
					{
						id: dataSet.id
					}
				]
			};

		return this.generateToken(
			url,
			queryParameters,
			dataSet);
	}

	/**
	 * Queries and returns base level data from the rest power bi api
	 * service.
	 *
	 * @async
	 * @param {string} dataType
	 * The url based data type to gather base level power bi data for.
	 * The default value is app.
	 * @returns {Promise<TDataType[]}
	 * An awaitable promise holding a returned array of the sent
	 * data type from the power bi rest api service.
	 * @memberof PowerBiApiService
	 */
	public async baseLevelData<TDataType>(
		dataType: string = 'app'): Promise<TDataType[]>
	{
		const url: string =
			`${this.basePowerBiApiUrl}/${dataType}s`;

		return this.getData<TDataType>(
			url);
	}

	/**
	 * Queries and returns group level data from the rest power bi api
	 * service.
	 *
	 * @async
	 * @param {string} groupId
	 * The group id to gather group level data for.
	 * @param {string} dataType
	 * The url based data type to gather group level power bi data for.
	 * The default value is report.
	 * @returns {Promise<TDataType[]}
	 * An awaitable promise holding a returned array of the sent
	 * data type from the power bi rest api service.
	 * @memberof PowerBiApiService
	 */
	public async groupLevelData<TDataType>(
		groupId: string,
		dataType: string = ReportConstants.powerBiObjectTypes.report):
		Promise<TDataType[]>
	{
		const calculatedReportType: string =
			this.getCalculatedReportType(
				dataType);

		const url: string =
			`${this.basePowerBiApiUrl}/groups/`
				+ `${groupId}/${calculatedReportType}s`;

		return this.getData<TDataType>(
			url);
	}

	/**
	 * Queries and returns grouped report level data from the rest power bi api
	 * service.
	 *
	 * @async
	 * @param {string} groupId
	 * The group id to gather grouped report level data for.
	 * @param {string} reportId
	 * The report id to gather grouped report level data for.
	 * @param {string} reportType
	 * The url based report type to gather grouped report level power bi data
	 * for.
	 * The default value is report.
	 * @param {string} reportDataType
	 * The url based report data type to gather grouped report level power bi
	 * data for. The default value is page.
	 * @returns {Promise<TDataType[]}
	 * An awaitable promise holding a returned array of the sent
	 * data type from the power bi rest api service.
	 * @memberof PowerBiApiService
	 */
	public async reportLevelData<TDataType>(
		groupId: string,
		reportId: string,
		reportType: string = ReportConstants.powerBiObjectTypes.report,
		reportDataType: string = ReportConstants.powerBiObjectTypes.page):
		Promise<TDataType[]>
	{
		const calculatedReportType: string =
			this.getCalculatedReportType(
				reportType);

		const url: string =
			`${this.basePowerBiApiUrl}/groups/`
				+ `${groupId}/${calculatedReportType}s/`
				+ `${reportId}/${reportDataType}s`;

		return this.getData<TDataType>(
			url);
	}

	/**
	 * Queries and returns data from the rest power bi api service.
	 *
	 * @async
	 * @param {string} url
	 * The power bi rest api url used to query for data.
	 * @returns {Promise<TDataType[]}
	 * An awaitable promise holding a returned array of the sent
	 * data type from the power bi rest api service.
	 * @memberof PowerBiApiService
	 */
	public async getData<TDataType>(
		url: string): Promise<TDataType[]>
	{
		const accessToken: string =
			await this.getAccessToken();

		return lastValueFrom(
			this.httpClient.get(
				url,
				{
					headers: this.getAuthenticationHeaders(
						accessToken)
				})
				.pipe(
					map((response: any) =>
						response.value.map(
							(item: TDataType) =>
								item))));
	}

	/**
	 * Loads and populates an external report configuration ready to be
	 * embedded via the power bi embedded service.
	 * @see https://microsoft.github.io/PowerBI-JavaScript/interfaces
	 *
	 * @async
	 * @param {IPowerBiReportDefinition} componentDefinition
	 * A power bi report definition holding all of the necessary
	 * inputs for configuration creation.
	 * @returns {IEmbedConfiguration}
	 * An awaitable power bi configuration ready for display.
	 * @memberof PowerBiApiService
	 */
	public async populatePowerBiEmbedConfiguration(
		componentDefinition: IPowerBiReportDefinition):
		Promise<IEmbedConfiguration | IVisualEmbedConfiguration>
	{
		const powerBiBaseUrl: string =
			ReportConstants.powerBiEmbedEndpoint;
		const embedToken: any =
			await this.generateEmbedToken(
				componentDefinition.reportId,
				componentDefinition.groupId,
				componentDefinition.datasetId,
				componentDefinition.datasetGroupId,
				componentDefinition.reportType,
				componentDefinition.tileId,
				componentDefinition.viewOnly);

		const embedConfiguration: IEmbedConfiguration =
			<IEmbedConfiguration>
			{
				id: componentDefinition.reportId,
				type: componentDefinition.reportType,
				tokenType: models.TokenType.Embed,
				accessToken: embedToken.token,
				permissions: models.Permissions.Read,
				viewMode: models.ViewMode.View,
				settings: {
					bars: {
						actionBar: {
							visible: false
						}
					},
					panes: {
						bookmarks: {
							expand: false,
							visible: false
						},
						fields: {
							expanded: false,
							visible: false
						},
						filters: {
							expanded: false,
							visible: false
						},
						pageNavigation: {
							visible: false
						},
						selection: {
							visible: false
						},
						syncSlicers: {
							visible: false
						},
						visualizations: {
							expanded: false,
							visible: false
						}
					}
				}
			};

		if (componentDefinition.viewOnly === false)
		{
			embedConfiguration.permissions =
				models.Permissions.ReadWrite;
			embedConfiguration.viewMode =
				models.ViewMode.Edit;

			embedConfiguration.settings
				.bars.actionBar.visible = true;
			embedConfiguration.settings.panes
				.bookmarks.visible = true;
			embedConfiguration.settings.panes
				.fields.visible = true;
			embedConfiguration.settings.panes
				.filters.visible = true;
			embedConfiguration.settings.panes
				.pageNavigation.visible = true;
			embedConfiguration.settings.panes
				.visualizations.visible = true;
		}

		if (this.siteLayoutService.windowWidth
			<= AppConstants.layoutBreakpoints.phone)
		{
			embedConfiguration.settings.layoutType =
				(this.siteLayoutService.windowWidth
					< this.siteLayoutService.windowHeight)
					? models.LayoutType.MobilePortrait
					: models.LayoutType.MobileLandscape;
		}

		let visualEmbedConfiguration: IVisualEmbedConfiguration;

		switch (componentDefinition.reportType)
		{
			case ReportConstants.powerBiObjectTypes.visual:
				visualEmbedConfiguration =
					<IVisualEmbedConfiguration> embedConfiguration;
				visualEmbedConfiguration.pageName =
					componentDefinition.pageName;
				visualEmbedConfiguration.visualName =
					componentDefinition.visualName;
			/* falls through */
			case ReportConstants.powerBiObjectTypes.report:
				const embedUrl: string =
					`${powerBiBaseUrl}/reportEmbed`
						+ `?reportId=${componentDefinition.reportId}`
						+ `&groupId=${componentDefinition.groupId}`
						+ componentDefinition.reportFilter;
				if (componentDefinition.reportType ===
					ReportConstants.powerBiObjectTypes.visual)
				{
					visualEmbedConfiguration.id =
						componentDefinition.reportId;
					visualEmbedConfiguration.embedUrl = embedUrl;
				}
				else
				{
					embedConfiguration.id =
						componentDefinition.reportId;
					embedConfiguration.embedUrl = embedUrl;
				}
				break;
			case ReportConstants.powerBiObjectTypes.dashboard:
				embedConfiguration.dashboardId =
					componentDefinition.reportId;
				embedConfiguration.embedUrl =
					`${powerBiBaseUrl}/dashboardEmbed`
						+ `?dashboardId=${componentDefinition.reportId}`
						+ `&groupId=${componentDefinition.groupId}`
						+ componentDefinition.reportFilter;
				break;
			case ReportConstants.powerBiObjectTypes.tile:
				embedConfiguration.id =
					componentDefinition.tileId;
				embedConfiguration.dashboardId =
					componentDefinition.reportId;
				embedConfiguration.embedUrl =
					`${powerBiBaseUrl}/embed`
						+ `?dashboardId=${componentDefinition.reportId}`
						+ `&tileid=${componentDefinition.tileId}`
						+ `&groupId=${componentDefinition.groupId}`
						+ componentDefinition.reportFilter;
				break;
			default:
				throw new Error(
					`'${componentDefinition.reportType}' embeds require `
						+ AppConstants.messages.switchCaseNotHandled);
		}

		return AnyHelper.isNull(visualEmbedConfiguration)
			? embedConfiguration
			: visualEmbedConfiguration;
	}

	/**
	 * Loads and populates an external report configuration ready to be
	 * used to create a new report in the power bi embed service.
	 * @see https://microsoft.github.io/PowerBI-JavaScript/interfaces
	 *
	 * @async
	 * @param {IPowerBiReportDefinition} componentDefinition
	 * A power bi report definition holding all of the necessary
	 * inputs for a create report configuration.
	 * @param {string} dataset
	 * The dataset this created report will point to.
	 * @returns {IEmbedConfiguration}
	 * An awaitable power bi configuration ready for creating a new report.
	 * @memberof PowerBiApiService
	 */
	public async populatePowerBiCreateConfiguration(
		componentDefinition: IPowerBiReportDefinition,
		dataSet: IPowerBiDataset): Promise<IEmbedConfiguration>
	{
		const embedToken: any =
			await this.generateCreateToken(
				dataSet);

		const createConfiguration: IEmbedConfiguration =
			<IEmbedConfiguration>
			{
				datasetId: dataSet.id,
				type: componentDefinition.reportType,
				tokenType: models.TokenType.Embed,
				accessToken: embedToken.token,
				embedUrl: dataSet.createReportEmbedURL,
				permissions: models.Permissions.ReadWrite,
				viewMode: models.ViewMode.Edit,
				settings: {
					bars: {
						actionBar: {
							visible: true
						}
					},
					panes: {
						bookmarks: {
							expanded: false,
							visible: true
						},
						fields: {
							expanded: false,
							visible: true
						},
						filters: {
							expanded: false,
							visible: true
						},
						pageNavigation: {
							visible: false
						},
						selection: {
							visible: false
						},
						syncSlicers: {
							visible: false
						},
						visualizations: {
							expanded: false,
							visible: true
						}
					}
				}
			};

		return createConfiguration;
	}

	/**
	 * Queries and returns a cloned report based on the sent power bi report.
	 *
	 * @async
	 * @param {IPowerBiReport} report
	 * The power bi report to be cloned.
	 * @param {string} reportGroupId
	 * The report group id of the existing report to be cloned.
	 * @param {string} newReportName
	 * The report name of the new cloned report.
	 * @param {string} newDatasetId
	 * If sent and true, this will clone the report with a new dataset. This
	 * value defaults to null and will keep the existing report's dataset.
	 * @param {string} newGroupId
	 * If sent and true, this will clone the report into an existing group. This
	 * value defaults to null and will keep the existing report's group.
	 * @returns {Promise<IPowerBiReport>}
	 * An awaitable promise holding a cloned power bi report used
	 * to define report and dataset identifiers.
	 * @memberof PowerBiApiService
	 */
	public async cloneExistingReport(
		report: IPowerBiReport,
		reportGroupId: string,
		newReportName: string,
		newDatasetId: string = null,
		newGroupId: string = null): Promise<IPowerBiReport>
	{
		const accessToken: string =
			await this.getAccessToken();
		const url: string =
			`${this.basePowerBiApiUrl}/groups/`
				+ `${reportGroupId}/`
				+ `${ReportConstants.powerBiObjectTypes.report}s/`
				+ `${report.id}/Clone`;
		const queryParameters: any =
			<any>
			{
				name: newReportName
			};

		if (!AnyHelper.isNullOrWhitespace(newDatasetId))
		{
			queryParameters.targetModelId = newDatasetId;
		}
		if (!AnyHelper.isNullOrWhitespace(newGroupId))
		{
			queryParameters.targetWorkspaceId = newGroupId;
		}

		return lastValueFrom(
			this.httpClient.post<IPowerBiReport>(
				url,
				queryParameters,
				{
					headers: this.getAuthenticationHeaders(
						accessToken)
				}));
	}

	/**
	 * Deletes an existing report based on the sent power bi report and group
	 * identifiers.
	 *
	 * @async
	 * @param {IPowerBiReportDefinition} reportDefinition
	 * The power bi report definition to be deleted.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof PowerBiApiService
	 */
	public async deleteReport(
		reportDefinition: IPowerBiReportDefinition): Promise<object>
	{
		const accessToken: string =
			await this.getAccessToken();
		const powerBiReportLookup: IPowerBiReportLookup =
			await this.lookupReportData(reportDefinition);
		const url: string =
			`${this.basePowerBiApiUrl}/groups/`
				+ `${reportDefinition.groupId}/`
				+ `${ReportConstants.powerBiObjectTypes.report}s/`
				+ `${powerBiReportLookup.reportId}`;

		return lastValueFrom(
			this.httpClient.delete(
				url,
				{
					headers: this.getAuthenticationHeaders(
						accessToken)
				}));
	}

	/**
	 * Queries and returns report data from the rest power bi api service
	 * based on the send component definitions report name value.
	 *
	 * @async
	 * @param {IPowerBiReportDefinition} componentDefinition
	 * The report definition for this power bi report.
	 * @returns {Promise<IPowerBiReportLookup>}
	 * An awaitable promise holding a power bi report lookup used
	 * to define report and dataset identifiers.
	 * @memberof PowerBiApiService
	 */
	public async lookupReportData(
		componentDefinition: IPowerBiReportDefinition):
		Promise<IPowerBiReportLookup>
	{
		const reportLookup: IPowerBiReportLookup =
			<IPowerBiReportLookup>
			{
				reportId: null,
				datasetId: null
			};

		switch (componentDefinition.reportType)
		{
			case ReportConstants.powerBiObjectTypes.visual:
			case ReportConstants.powerBiObjectTypes.report:
				const reports: IPowerBiReport[] =
					await this.groupLevelData<IPowerBiReport>(
						componentDefinition.groupId);

				const apiReport: IPowerBiReport =
					reports.filter(
						(item: IPowerBiReport) =>
							item.name
								=== componentDefinition.reportName)[0];

				reportLookup.reportId = apiReport.id;
				reportLookup.datasetId = apiReport.datasetId;
				break;
			case ReportConstants.powerBiObjectTypes.dashboard:
			case ReportConstants.powerBiObjectTypes.tile:
				const dashboards: IPowerBiDashboard[] =
					await this.groupLevelData<IPowerBiDashboard>(
						componentDefinition.groupId,
						componentDefinition.reportType);
				const apiDashboard: IPowerBiDashboard =
					dashboards.filter(
						(item: IPowerBiDashboard) =>
							item.displayName
								=== componentDefinition.reportName)[0];

				reportLookup.reportId = apiDashboard.id;

				const tiles: IPowerBiTile[] =
					await this.reportLevelData<IPowerBiTile>(
						componentDefinition.groupId,
						reportLookup.reportId,
						ReportConstants.powerBiObjectTypes.dashboard,
						ReportConstants.powerBiObjectTypes.tile);

				reportLookup.datasetId =
					componentDefinition.reportType !==
						ReportConstants.powerBiObjectTypes.tile
						? tiles[0].datasetId
						: tiles.filter(
							(item: IPowerBiTile) =>
								item.id
								=== componentDefinition.tileId)[0].datasetId;
				break;
			default:
				throw new Error(
					`'${componentDefinition.reportType}' lookups require `
						+ AppConstants.messages.switchCaseNotHandled);
		}

		return reportLookup;
	}

	/**
	 * Calculates and returns the expected api report types matching
	 * embed report types when querying for power bi rest api data.
	 *
	 * @async
	 * @param {string} reportType
	 * The embed report type to be calculated into queryable api report tpye.
	 * @returns {string}
	 * A string holding the proper queryable value for the embed report type.
	 * @memberof PowerBiApiService
	 */
	private getCalculatedReportType(
		reportType: string): string
	{
		switch (reportType)
		{
			case ReportConstants.powerBiObjectTypes.visual:
				return ReportConstants.powerBiObjectTypes.report;
			case ReportConstants.powerBiObjectTypes.tile:
				return ReportConstants.powerBiObjectTypes.dashboard;
			default:
				return reportType;
		}
	}

	/**
	 * Gets the current access token for this api service. If this value
	 * has expired a new access token will be returned.
	 *
	 * @returns {string}
	 * A string representing the current azure based access token that can be
	 * used for power bi api service calls and embed configurations.
	 * @memberof PowerBiApiService
	 */
	private async getAccessToken(): Promise<string>
	{
		if (AnyHelper.isNullOrWhitespace(this.accessToken)
			|| this.expiredToken === true)
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				AppConstants.typeGroups.systems;

			// Ensure parallel calls await the same value.
			this.accessTokenPromise =
				AnyHelper.isNull(this.accessTokenPromise)
					? this.entityInstanceApiService
						.getExternalReportConfiguration(
							parseInt(
								AppConstants.systemId,
								AppConstants.parseRadix),
							ReportConstants.externalReportTypes.powerBi)
					: this.accessTokenPromise;

			const castResponse: any =
				(<any>await this.accessTokenPromise).value.data;

			if (AnyHelper.isNullOrWhitespace(
				castResponse.accessToken)
				|| AnyHelper.isNullOrWhitespace(
					castResponse.accessTokenExpiry))
			{
				throw new Error(
					'Unable to create an external PowerBI token from '
						+ 'the external report configuration action.');
			}

			this.accessToken =
				castResponse.accessToken;
			this.accessTokenExpiry =
				DateHelper.addTimeUnit(
					DateHelper.fromUtcIso(
						castResponse.accessTokenExpiry),
					-this.minutesBeforeExpireToRenew,
					DateHelper.timeUnits.minute);
			this.embedUsername = castResponse.clientEmbedUsername;

			return this.accessToken;
		}
		else
		{
			this.accessTokenPromise = null;

			return this.accessToken;
		}
	}

	/**
	 * Creates and returns an embed token matching the sent url and
	 * query parameters.
	 *
	 * @async
	 * @param {string} url
	 * The url this embed token will grant access to.
	 * @param {any} queryParameters
	 * The query parameters to send in this access token request.
	 * @param {dataSet} dataset
	 * The dataset this embed token will grant access to.
	 * @returns {Promise<any>}
	 * An awaitable promise holding the embed token return from the
	 * power bi rest api service matching the server access token.
	 * @memberof PowerBiApiService
	 */
	private async generateToken(
		url: string,
		queryParameters: any,
		dataSet: IPowerBiDataset): Promise<any>
	{
		const accessToken: string =
			await this.getAccessToken();

		if (dataSet.isEffectiveIdentityRequired === true)
		{
			queryParameters.identities = [
				{
					username: this.embedUsername,
					roles: [
						this.embedReportRoles.dataReader
					],
					datasets: [
						dataSet.id
					]
				}
			];
		}

		return lastValueFrom(
			this.httpClient.post(
				url,
				queryParameters,
				{
					headers: this.getAuthenticationHeaders(
						accessToken)
				}));
	}
}