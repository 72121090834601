/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';
import {
	IDisplayComponentType
} from '@shared/interfaces/display-components/display-component-type.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	DisplayManagementExpandComponent
} from './display-management-expand/display-management-expand.component';

/* eslint-enable max-len */

@Component({
	selector: 'app-display-management-component',
	templateUrl: './display-management.component.html',
	styleUrls: [
		'./display-management.component.scss'
	]
})

/**
 * A component representing an instance of the workflow engine action
 * execution history component.
 *
 * @export
 * @class DisplayManagementComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class DisplayManagementComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Initializes a new instance of the DisplayManagementComponent class.
	 *
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The api service used to load display component definition data.
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The api service used to load display component instance data.
	 * @param {DisplayComponentTypeApiService} displayComponentTypeApiService
	 * The api service used to load display component type data.
	 * @param {DisplayComponentService} displayComponentService
	 * The api service used to load display component data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The api service used to get security group data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof DisplayManagementComponent
	 */
	public constructor(
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public displayComponentTypeApiService: DisplayComponentTypeApiService,
		public displayComponentService: DisplayComponentService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof DisplayManagementComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Sets the data chunk limit.
	 *
	 * @type {number}
	 * @memberof DisplayManagementComponent
	 */
	public dataChunkLimit: number = 100;

	/**
	 * Gets or sets the operation query params.
	 *
	 * @type {IObjectSearch}
	 * @memberof DisplayManagementComponent
	 */
	public operationQueryParams: IObjectSearch =
		{
			filter: AppConstants.empty,
			orderBy: `Id ${AppConstants.sortDirections.ascending}`,
			offset: null,
			limit: this.dataChunkLimit
		};

	/**
	 * Gets or sets the display component type options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof DisplayManagementComponent
	 */
	public displayComponentTypeOptions: IDropdownOption[];

	/**
	 * Gets or sets the display component definition options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof DisplayManagementComponent
	 */
	public displayComponentDefinitionOptions: IDropdownOption[];

	/**
	 * Gets or sets the security group options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof DisplayManagementComponent
	 */
	public securityGroupOptions: IDropdownOption[];

	/**
	 * Gets or sets the display management context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof DisplayManagementComponent
	 */
	public displayManagementContext: IDynamicComponentContext<Component, any>;

	/**
	 * Initializes the display management component by seting up page variables
	 * and table definitions.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		await this.setupTableDefinitions();
	}

	/**
	 * Sets up the page variables.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		await this.displayComponentService.setStoredVariables();

		this.displayComponentTypeOptions =
			this.getDropdownOptions(
				this.displayComponentService.displayComponentTypes,
				'name',
				'id');

		this.displayComponentDefinitionOptions =
			this.getDropdownOptions(
				this.displayComponentService.displayComponentDefinitions,
				'componentName',
				'id');

		this.securityGroupOptions =
			await this.optionsFactory.getSecurityGroupOptions();

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'displayType.name',
					columnHeader: 'Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'displayDefinition.componentName',
					columnHeader: 'Definition',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.displayManagementContext =
			<IDynamicComponentContext<Component, any>>
			{
				source: this
			};

		this.tableDefinitions =
			{
				tableTitle: 'Management',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Display Component Instance'),
				objectSearch: {
					filter: AppConstants.empty,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
					{
						const displayComponentInstances:
							IDisplayComponentInstance[] =
							<IDisplayComponentInstance[]>
							await this.displayComponentInstanceApiService
								.query(
									objectSearch.filter,
									objectSearch.orderBy,
									objectSearch.offset,
									objectSearch.limit);

						let dataReturn = [];
						const promiseArray: Promise<any>[] = [];
						for (const apiDefinition of displayComponentInstances)
						{
							promiseArray.push(
								this.decorateViewModel(
									apiDefinition));
						}

						await Promise.all(promiseArray)
							.then(
								(decoratedDisplayManagement: any[]) =>
								{
									dataReturn = decoratedDisplayManagement;
								});

						return dataReturn;
					},
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					create: {
						component: DisplayManagementExpandComponent,
						customContext: this.displayManagementContext,
						items: [
							{
								label: 'Create',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.createDisplayInstance()
							}]
					},
					view: {
						setAdditionalSelectedItemData: async() =>
							this.setAdditionalSelectedItemData(),
						customContext: this.displayManagementContext,
						layout: [
							{
								key: 'typeId',
								type:
									FormlyConstants.customControls.customSelect,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Type',
									disabled: true,
									options: this.displayComponentTypeOptions,
									appendTo:
										FormlyConstants.appendToTargets.body
								}
							},
							{
								key: 'definitionId',
								type:
									FormlyConstants.customControls.customSelect,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Definition',
									disabled: true,
									options:
										this.displayComponentDefinitionOptions,
									appendTo:
										FormlyConstants.appendToTargets.body
								}
							},
							{
								key: 'name',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Name',
									disabled: true
								}
							},
							{
								key: 'securityGroups',
								type: FormlyConstants.customControls
									.customMultiSelect,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									appendTo:
										FormlyConstants.appendToTargets.body,
									label: 'Security Groups',
									showClear: true,
									disabled: true,
									placeholder:
										AppConstants.placeholders
											.selectAnOption,
									options: this.securityGroupOptions
								}
							},
							{
								...CommonFormlyFieldConstants
									.publicField,
								props: {
									...CommonFormlyFieldConstants
										.publicField
										.props,
									disabled: true
								}
							},
							{
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField,
								props: {
									...CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.props,
									label: 'Instance Owner(s)',
									options: this.securityGroupOptions,
									disabled: true
								}
							},
							{
								key: 'description',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Description',
									disabled: true,
								}
							},
							{
								type: FormlyConstants.customControls
									.customTextDisplay,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									title: 'Definition',
									useCodeBlock: true,
									usePanelDisplay: true,
									expanded: false,
									codeBlockType:
										AppConstants.markdownLanguages.json,
									content: AppConstants.empty
								},
								expressions: {
									'props.content':
										'`${model.displayDefinition.jsonData}`'
								}
							},
							{
								type: FormlyConstants.customControls
									.customTextDisplay,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Attributes',
									useCodeBlock: true,
									usePanelDisplay: false,
									codeBlockType:
										AppConstants.markdownLanguages.json,
									content: AppConstants.empty
								},
								expressions: {
									'props.content':
										'`${model.jsonData}`'
								}
							}
						],
						items: []
					},
					update: {
						setAdditionalSelectedItemData: async() =>
							this.setAdditionalSelectedItemData(),
						component: DisplayManagementExpandComponent,
						customContext: this.displayManagementContext,
						items: [
							{
								label: 'Save',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: () => this.updateDisplayDefinition()
							}]
					},
					delete: {
						items: [
							{
								label: 'Confirm',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								disabled: false,
								command: () => this.deleteDisplayInstance()
							}],
						deleteStatement: () => this.getDeleteStatement(),
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Decorates the view model for the display component definition object.
	 *
	 * @param {IDisplayComponentInstance} model
	 * The display component instance object to be decorated as a view model.
	 * @returns {Promise<any>}
	 * The mapped view model for display in this component.
	 * @memberof DisplayManagementComponent
	 */
	public async decorateViewModel(
		model: IDisplayComponentInstance): Promise<any>
	{
		await this.displayComponentService.setStoredVariables();

		return <IDisplayComponentInstance>
			{
				...model,
				displayType:
					this.displayComponentService.displayComponentTypes
						.find((displayComponentType: IDisplayComponentType) =>
							displayComponentType.id === model.typeId),
				displayDefinition:
					this.displayComponentService.displayComponentDefinitions
						.find((displayComponentDefinition:
							IDisplayComponentDefinition) =>
							displayComponentDefinition.id ===
								model.definitionId)
			};
	}

	/**
	 * Creates the display definition.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	private async createDisplayInstance(): Promise<void>
	{
		const createDisplayInstance: Function =
			async () =>
			{
				const selectedItem: any =
					this.commonTableContext.source.selectedItem;

				const displayComponentInstanceId: number =
					await this.displayComponentInstanceApiService
						.create(
							<IDisplayComponentInstance>
							{
								id: selectedItem.id,
								name: selectedItem.name,
								description: selectedItem.description,
								typeId: parseInt(
									selectedItem.typeId,
									AppConstants.parseRadix),
								definitionId: parseInt(
									this.commonTableContext.source
										.selectedItem.definitionId,
									AppConstants.parseRadix),
								jsonData: selectedItem.jsonData ?? {},
								public: selectedItem.public,
								ownershipSecurityGroupId:
									selectedItem.ownershipSecurityGroupId
							});

				selectedItem.id = displayComponentInstanceId;

				for (let index = 0;
					index <
						selectedItem
							.securityGroups.length;
					index++)
				{
					await this.displayComponentInstanceApiService
						.createSecurityGroupMap(
							displayComponentInstanceId,
							parseInt(
								selectedItem
									.securityGroups[index],
								AppConstants.parseRadix));
				}

				// Reset this value to get the fresh created by id.
				this.commonTableContext.source.selectedItem =
					{
						...this.commonTableContext.source.selectedItem,
						...await this.displayComponentInstanceApiService.get(
							selectedItem.id)
					};
				await this.commonTableContext.source.addSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				createDisplayInstance(),
				'<strong>Creating Display Instance</strong>',
				'<strong>Created Display Instance</strong>',
				`Display Instance
					${this.commonTableContext.source.selectedItem.name}
					was successfully created.`,
				`Display Instance
					${this.commonTableContext.source.selectedItem.name}
					was not created.`));
	}

	/**
	 * Updates the display definition.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	private async updateDisplayDefinition(): Promise<void>
	{
		const updateDisplayInstance: Function =
			async () =>
			{
				const selectedItem: any =
					this.commonTableContext.source.selectedItem;

				await this.displayComponentInstanceApiService
					.update(
						selectedItem.id,
						<IDisplayComponentInstance>
						{
							id: selectedItem.id,
							name: selectedItem.name,
							description: selectedItem.description,
							typeId: parseInt(
								selectedItem.typeId,
								AppConstants.parseRadix),
							definitionId: parseInt(
								selectedItem.definitionId,
								AppConstants.parseRadix),
							jsonData: selectedItem.jsonData,
							public: selectedItem.public,
							ownershipSecurityGroupId:
								selectedItem.ownershipSecurityGroupId,
							createdById: selectedItem.createdById
						});

				const existingSecurityGroups: number[] =
					await this.displayComponentInstanceApiService
						.getSecurityGroups(
							selectedItem.id);

				if (existingSecurityGroups.length
					!== selectedItem.securityGroups.length)
				{
					for (const existingSecurityGroup of existingSecurityGroups)
					{
						await this.displayComponentInstanceApiService
							.deleteSecurityGroupMap(
								selectedItem.id,
								existingSecurityGroup);
					}

					for (let index = 0;
						index < selectedItem.securityGroups.length;
						index++)
					{
						await this.displayComponentInstanceApiService
							.createSecurityGroupMap(
								selectedItem.id,
								parseInt(
									selectedItem
										.securityGroups[index],
									AppConstants.parseRadix));
					}
				}

				await this.commonTableContext.source.updateSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				updateDisplayInstance(),
				'<strong>Updating Display Instance</strong>',
				'<strong>Updated Display Instance</strong>',
				`Display Instance
					${this.commonTableContext.source.selectedItem.name}
					was successfully updated.`,
				`Display Instance
					${this.commonTableContext.source.selectedItem.name}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Gets the delete statement.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * The delete statement string.
	 * @memberof DisplayManagementComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		return `Confirm you are about to delete Display Component Instance
			${this.commonTableContext.source.selectedItem.id}
			${this.commonTableContext.source.selectedItem.name}.`;
	}

	/**
	 * Deletes display definition.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	private async deleteDisplayInstance(): Promise<void>
	{
		const deleteDisplayComponentInstance: Function =
			async() =>
			{
				this.displayComponentInstanceApiService
					.delete(
						this.commonTableContext.source.selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteDisplayComponentInstance(),
				'<strong>Deleting the Display Instance</strong>',
				'<strong>Deleted the Display Instance</strong>',
				`Display Instance
					${this.commonTableContext.source.selectedItem.componentName}
					was successfully deleted.`,
				`Display Instance
					${this.commonTableContext.source.selectedItem.componentName}
					was not deleted.`));
	}

	/**
	 * Sets up the page variables.
	 *
	 * @param {any} apiData
	 * The api resolved with data for the dropdown options.
	 * @param {string} label
	 * The dropdown label to map with the data promise results.
	 * @param {string} value
	 * The dropdown value to map with the data promise results.
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	private getDropdownOptions(
		apiData: any,
		label: string,
		value: string): IDropdownOption[]
	{
		return (apiData)
			.map((item: any) =>
				<IDropdownOption>
				{
					label: item[label],
					value: item[value]
				});
	}

	/**
	 * Gets the additional selected item data.
	 *
	 * @async
	 * @returns {Promise<any>}
	 * The additional selected item data object.
	 * @memberof DisplayManagementComponent
	 */
	private async setAdditionalSelectedItemData(): Promise<any>
	{
		const securityGroups: number[] =
			this.commonTableContext.source.selectedItem.id === 0
				? []
				: await this.displayComponentInstanceApiService
					.getSecurityGroups(
						this.commonTableContext.source.selectedItem.id);

		this.commonTableContext.source.selectedItem.securityGroups =
 			securityGroups;
	}
}