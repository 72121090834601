/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Directive,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FileCategory
} from '@dynamicComponents/files/file-category/file-category';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	SecurityRightType
} from '@shared/constants/enums/security-right-type.enum';
import {
	StorageType
} from '@shared/constants/enums/storage-type.enum';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	FileHelper
} from '@shared/helpers/file.helper';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	DateTime
} from 'luxon';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

@Directive({
	selector: '[File]'
})

/**
 * A directive representing shared logic for a component interacting
 * with files.
 *
 * @export
 * @class FileDirective
 * @extends {CommonTablePageDirective}
 */
export class FileDirective
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the file directive.
	 *
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof FileDirective
	 */
	public constructor(
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the selected file entity.
	 *
	 * @type {IFileEntity}
	 * @memberof FileDirective
	 */
	@Input() public fileEntity: IFileEntity;

	/**
	 * Gets or sets the categories.
	 *
	 * @type {FileCategory[]}
	 * @memberof FileDirective
	 */
	@Input() public categories: FileCategory[];

	/**
	 * Gets or sets the sub types.
	 *
	 * @type {string[]}
	 * @memberof FileDirective
	 */
	@Input() public subTypes: string[] = [];

	/**
	 * Gets or sets the security definitions.
	 *
	 * @type {ISecurityEntityTypeDefinition[]}
	 * @memberof FileDirective
	 */
	@Input() public securityDefinitions: ISecurityEntityTypeDefinition[];

	/**
	 * Gets or sets the navigate event
	 *
	 * @type {EventEmitter<string>}
	 * @memberof FileDirective
	 */
	@Output() public changeDisplayMode: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets the menu items.
	 *
	 * @type {MenuItem[]}
	 * @memberof FileDirective
	 */
	public itemActions: MenuItem[];

	/**
	 * Gets or sets the loading table definitions state.
	 *
	 * @type {boolean}
	 * @memberof FileDirective
	 */
	public loadingTableDefinitions: boolean = true;

	/**
	 * Gets or sets the table definitions for the print and mail history
	 * standard table view.
	 *
	 * @type {object}
	 * @memberof FileDirective
	 */
	public printAndMailHistoryTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the children entity type group.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public childrenEntityTypeGroup: string =
		'DistributionRequest.PrintAndMails';

	/**
	 * Gets or sets a value indictaing whether to show
	 * the progress component.
	 *
	 * @type {boolean}
	 * @memberof FileDirective
	 */
	public progressVisible: boolean = false;

	/**
	 * Gets or sets the display mode of the progress component.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public progressMode: string = 'spinner';

	/**
	 * Gets or sets the progress amount.
	 *
	 * @type {number}
	 * @memberof FileDirective
	 */
	public progressAmount: number = 0;

	/**
	 * Gets or sets the display message for the progress component errors.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public errorMessage: string;

	/**
	 * Gets the set of available item view modes.
	 *
	 * @type {object}
	 * @memberof FileDirective
	 */
	public readonly viewModes:
	{
		add: string;
		download: string;
		details: string;
		edit: string;
		list: string;
		remove: string;
	} = {
			add: 'add',
			download: 'download',
			details: 'details',
			edit: 'edit',
			remove: 'remove',
			list: 'list'
		};

	/**
	 * Gets the default file extension.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public readonly defaultFileExtension: string = 'ext';

	/**
	 * Gets the default required value message.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public readonly requiredValueMessage: string =
		AppConstants.messages.requiredValueMessage;

	/**
	 * Gets the default required value custom metadata.
	 *
	 * @type {any}
	 * @memberof FileDirective
	 */
	public customMetadata: any[];

	/**
	 * Gets the storage type
	 *
	 * @type {StorageType}
	 * @memberof FileDirective
	 */
	public get storageType(): StorageType
	{
		return this.fileEntity
			.data
			.storage
			.storageType;
	}

	/**
	 * Gets the file category.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	 public get category(): string
	 {
		 return FileHelper
			 .getCategoryFromType(
				this.fileEntity.entityType);
	 }

	/**
	 * Gets the file extension.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get fileExtension(): string
	{
		return this.fileEntity
			.data?.metadata?.extension
				|| this.defaultFileExtension;
	}

	/**
	 * Gets the file entity type group.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get fileTypeGroup(): string
	{
		return this.categories
			.find((element: FileCategory) =>
				element.value === this.fileEntity.entityType)
			.entityType
			.group;
	}

	/**
	 * Gets the file category.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	 public get fileCategory(): string
	 {
		 return this.categories
			 .find((element: FileCategory) =>
				 element.value === this.fileEntity .entityType)
			 .label;
	 }

	/**
	 * Gets the file sub type.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get subType(): string
	{
		return AnyHelper.isNullOrWhitespace(this.fileEntity?.data?.subType)
			? 'None'
			: this.fileEntity.data.subType;
	}

	/**
	 * Gets the file size in bytes.
	 *
	 * @type {number}
	 * @memberof FileDirective
	 */
	public get sizeInBytes(): number
	{
		return this.fileEntity
			.data
			.metadata
			.sizeInBytes;
	}

	/**
	 * Gets thesize in bytes formatted to Bytes,
	 * Kilobytes, or Megabytes.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get sizeFormatted(): string
	{
		if (AnyHelper.isNullOrEmpty(this.sizeInBytes))
		{
			return '0 Bytes';
		}
		if (this.sizeInBytes < FileHelper.oneKilobyte)
		{
			return `${this.sizeInBytes} Bytes`;
		}

		if (this.sizeInBytes < FileHelper.oneMegabyte)
		{
			return `${(this.sizeInBytes / FileHelper.oneKilobyte)
				.toFixed(1)} KB`;
		}

		return `${(this.sizeInBytes / FileHelper.oneMegabyte)
			.toFixed(2)} MB`;
	}

	/**
	 * Gets a the create date.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get createDate(): string
	{
		const date: DateTime =
			DateTime.fromISO(this.fileEntity.createDate);

		return DateHelper.formatDate(
			date,
			'ccc MMM dd yyyy');
	}

	/**
	 * Handles initialization event.
	 * Sets the custom metadata.
	 *
	 * @memberof FileDirective
	 */
	public ngOnInit(): void
	{
		this.setCustomMetadata();
		this.setupPageVariables();
		this.setupTableDefinitions();
		this.setItemActions();
	}

	/**
	 * Sets the page variables needed for this component.
	 *
	 * @async
	 * @memberof FileDirective
	 */
	public setupPageVariables(): void
	{
		this.availableColumns =
			[
				{
					dataKey: 'createDate',
					columnHeader: 'Request Date',
					dataFormatType: 'ShortDate',
					displayOrder: 1
				},
				{
					dataKey: 'data.result.date',
					columnHeader: 'Printed Date',
					dataFormatType: 'ShortDate',
					displayOrder: 2
				},
				{
					dataKey: 'data.status',
					columnHeader: 'Status',
					displayOrder: 3
				}
			];

		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the distribution request print and mail hitory table definitions
	 *
	 * @memberof FileDirective
	 */
	public setupTableDefinitions(): void
	{
		this.printAndMailHistoryTableDefinitions =
			{
				tableTitle: 'Print and Mail History',
				hideTableTitle: true,
				hideSettings: true,
				objectSearch: {
					filter: this.tableFilterQuery,
					orderBy: `Id ${AppConstants.sortDirections.descending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					async(_objectSearch: IObjectSearch) =>
					{
						const entityType: IEntityType =
							await this.entityTypeApiService
								.getSingleQueryResult(
									`name eq '${this.fileEntity.entityType}'`,
									`name ${AppConstants
										.sortDirections.ascending}`);

						this.entityInstanceApiService.entityInstanceTypeGroup =
							entityType.group;
						const entityInstances: IEntityInstance[] =
							await this.entityInstanceApiService
								.getChildren(
									this.fileEntity.id,
									AppConstants.empty,
									AppConstants.empty,
									0,
									AppConstants.dataLimits.large,
									this.childrenEntityTypeGroup);

						return entityInstances;
					},
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Gets a collection of strings representing note types filtered from the
	 * list of input types.
	 *
	 * @protected
	 * @param {number} parentId
	 * The parent id of the note.
	 * @param {string} parentTypeGroup
	 * the parent type group.
	 * @param {EntityDefinition} parentEntityDefinition
	 * The parent Entity Definition.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * the entity instance API service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The Entity Type API service.
	 * @returns {Promise<void>}
	 * A collection of suppported and authorized entity types notes.
	 * @memberof FileDirective
	 */
	protected async setSecurityDefinitions(
		parentId: number,
		parentTypeGroup: string,
		parentEntityDefinition: EntityDefinition,
		entityInstanceApiService: EntityInstanceApiService,
		entityTypeApiService: EntityTypeApiService):
		Promise<void>
	{
		this.securityDefinitions =
			await SecurityHelper
				.getSupportedChildPermissions(
					parentId,
					parentTypeGroup,
					'File.*',
					parentEntityDefinition,
					entityInstanceApiService,
					entityTypeApiService);
	}

	/**
	 * Sets the authorized item actions.
	 *
	 * @memberof FileDirective
	 */
	private setItemActions(): void
	{
		/**
		 * For consistency, this method should be refactored to use
		 * ISecureMenuItem with SecurityHelper.scrubMenuItems
		 * (see other drawers).
		 */

		const authorizedActions: MenuItem[] = [];

		if (SecurityHelper.HasRight(
			SecurityRightType.read,
			this.fileEntity.entityType,
			this.securityDefinitions))
		{
			authorizedActions.push(
				<MenuItem>{
					icon: 'fa-info-circle',
					id: this.viewModes.details,
					command: (event: any) => {
						this.changeDisplayMode.emit(
							this.viewModes.details);
						event.stopPropagation();
					}
				});
		}

		if (SecurityHelper.actionAuthorized(
			this.fileEntity.entityType,
			AppConstants.apiMethods.update,
			this.securityDefinitions))
		{
			authorizedActions.push(
				<MenuItem>
				{
					icon: 'fa-pencil',
					id: this.viewModes.edit,
					command: (event: any) => {
						this.changeDisplayMode.emit(
							this.viewModes.edit);
						event.stopPropagation();
					}
				});
		}

		if (SecurityHelper.actionAuthorized(
			this.fileEntity.entityType,
			AppConstants.apiMethods.delete,
			this.securityDefinitions))
		{
			authorizedActions.push(
				<MenuItem>
				{
					icon: 'fa-trash',
					id: this.viewModes.remove,
					command: (event: any) => {
						this.changeDisplayMode.emit(
							this.viewModes.remove);
						event.stopPropagation();
					}
				});
		}

		if (SecurityHelper.actionAuthorized(
			this.fileEntity.entityType,
			AppConstants.workflowActions.fileDownload,
			this.securityDefinitions))
		{
			authorizedActions.push(
				<MenuItem>
				{
					icon: 'fa-cloud-download',
					id: this.viewModes.download,
					command: (event: any) => {
						this.changeDisplayMode.emit(
							this.viewModes.download);
						event.stopPropagation();
					}
				});
		}

		this.itemActions = authorizedActions;
	}

	/**
	 * Sets the custom metadata.
	 *
	 * @memberof FileDirective
	 */
	private setCustomMetadata(): void
	{
		this.customMetadata = FileHelper
			.getCustomMetadata(
				this.fileEntity,
				null);
	}
}