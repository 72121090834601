<ng-template #Loading
	[ngIf]="loading">
	<div class="ui-g-12 no-horizontal-padding text-center">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>
</ng-template>

<div #CloudChat
	class="ui-g-12 text-center cloud-chat-container">
	<div *ngIf="chatOffline === true">
		{{chatOfflineMessage}}<br/>
		{{chatOfflineSecondaryMessage}}
	</div>
</div>