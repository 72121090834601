/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	AssociatedTransactionDirective
} from '@insurance/directives/associated-transaction.directive';
import {
	AnyHelper
} from '@shared/helpers/any.helper';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-assets',
	templateUrl: './transaction-assets.component.html',
	styleUrls: [
		'./transaction-assets.component.scss'
	]
})

/**
 * A component representing an associated company used
 * in the associated entity list.
 *
 * @export
 * @class TransactionAssetsComponent
 * @extends AssociatedTransactionDirective
 * @implements OnInit
 */
export class TransactionAssetsComponent
	extends AssociatedTransactionDirective
	implements OnInit
{
	/**
	 * Sets the address icon class.
	 *
	 * @type {string}
	 * @memberof TransactionAssetsComponent
	 */
	private readonly addressIconClass: string = 'fa-home';

	/**
	 * Sets the asset property name.
	 *
	 * @type {string}
	 * @memberof TransactionAssetsComponent
	 */
	private readonly assetPropertyName: string = 'assets';

	/**
	 * Initiates the component to get the data object.
	 *
	 * @memberof TransactionAssetsComponent
	 */
	public ngOnInit(): void
	{
		if (AnyHelper.isNullOrEmpty(
			this.context.data.entityInstance))
		{
			return;
		}

		this.setData(this.assetPropertyName);
	}

	/**
	 * Sets the icon class.
	 *
	 * @param {any} property
	 * The mapping property.
	 *
	 * @memberof TransactionAssetsComponent
	 */
	public setIconClass(property: any): void
	{
		property.iconClass =
			!AnyHelper.isNullOrEmptyArray(property.characteristics.addresses)
				? this.addressIconClass
				: null;
	}
}