<div class="ui-g-12 no-padding">
	<div class="ui-g-12 no-horizontal-padding wizard-content-title">
	</div>
	<ng-template
		[ngIf]="context.source.wizardStepLoading || dynamicFormlyLayout == null"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12 no-padding">
			<div class="ui-g-12 no-padding dynamic-form-content">
				<app-dynamic-formly
					[dataSet]="context.source.activeMenuItem.currentData"
					[layoutSchema]="dynamicFormlyLayout"
					[context]="context"
					(validityChanged)="validityChanged($event)">
				</app-dynamic-formly>
			</div>
		</div>
	</ng-template>
</div>