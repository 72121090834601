/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';

/* eslint-enable max-len */

@Component({
	selector: 'rescind-non-renew-policy',
	templateUrl: './rescind-non-renew-policy.component.html'
})

/**
 * A component representing a wizard step for binding a policy term transaction.
 *
 * @export
 * @class RescindNonRenewPolicyComponent
 * @extends {StatusReasonsDirective}
 */
export class RescindNonRenewPolicyComponent
	extends StatusReasonsDirective
{
	/**
	 * Gets or sets true/false if the current policy is allowing
	 * rescind renew process.
	 *
	 * @type {boolean}
	 * @memberof NonRenewPolicyComponent
	 */
	public allowedRescindNonRenew: boolean;

	/**
	 * Gets or sets the client message displayed if not allowed
	 * to rescind renew.
	 * process.
	 *
	 * @type {string}
	 * @memberof NonRenewPolicyComponent
	 */

	public clientMessage: string =
		'Rescind Non-Renew is not allowed from the current policy state.';

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @memberof RescindNonRenewPolicyComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.allowedRescindNonRenew =
			this.rescindNonRenewIsAllowed();
		this.workflowActionName = 'PolicyRescindNonRenew';

		this.context.source.addOrUpdateStepData(
			{
				comments: AppConstants.empty
			});

		this.context.source.addToNext(this.rescindNonRenew.bind(this));
	}

	/**
	 * This will send the policy rescind non-renew event and navigate to the
	 * policy dashboard.
	 *
	 * @async
	 * @memberof RescindNonRenewPolicyComponent
	 */
	public async rescindNonRenew(): Promise<void>
	{
		await this.policyRescindNonRenew();
		await this.navigateToPolicyDashboard();
	}

	/**
	 * Executes the policy rescind non-renew process by executing the
	 * policyRescindNonRenew workflow action to update the associated Policy
	 * entity data with the collected data from the Rescind Non-Renew Wizard.
	 *
	 * @async
	 * @memberof RescindNonRenewPolicyComponent
	 */
	public async policyRescindNonRenew(): Promise<void>
	{
		const displayName: string =
			new EntityType(this.entityType)
				.displayName;
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		return this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, _reject: any) =>
				{
					const queryString: string =
						`?comments=${currentData.comments}`;

					await this.executeWorkflowAction(
						this.entityType,
						this.entityInstance,
						queryString);

					resolve();
				}),
				'<strong>Rescinding non-renewal</strong>',
				'<strong>Non-renewal rescinded</strong>',
				`Non-renewal has been rescinded for ${displayName}.`,
				`Non-renewal has not been rescinded for ${displayName}.`));
	}

	/**
	 * This will navigate to the policy dashboard using the policy term id
	 * provided.
	 *
	 * @async
	 * @memberof RescindNonRenewPolicyComponent
	 */
	private async navigateToPolicyDashboard()
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				this.entityInstanceTypeGroup,
				AppConstants.viewTypes.edit,
				this.entityInstanceId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * This will validate if the non-renew process is allowed by calculating
	 * the expiration date and the allowed days to non-renew the policy.
	 *
	 * @returns {boolean}
	 * True if the non-renew process is allowed, otherwise will return false.
	 * @memberof NonRenewPolicyComponent
	 */
	private rescindNonRenewIsAllowed(): boolean
	{
		return this.entityInstance.data.isRenewable === false
			&& this.entityInstance.data.status
				=== InsuranceConstants.policyStatusTypes.active;
	}
}