/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
@Component({
	selector: 'custom-dashboard',
	templateUrl: './custom-dashboard.component.html'
})

/**
 * A component representing an instance of a Custom Text Display.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomDashboardComponent
 * @extends {FieldType}
 */
export class CustomDashboardComponent
	extends ExtendedCustomControlDirective
{
	/**
	 * Initializes a new instance of the CustomRepeaterComponent. This
	 * explicit constructor was requested during an upgrade process and
	 * should be left as implemented.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomDashboardComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Calls the fireChanges on a set time out
	 * to avoid any dashboard locked view on not detecting
	 * the loading false state upon changes.
	 *
	 * @memberof CustomDashboardComponent
	 */
	public customDashboardChanges()
	{
		setTimeout(
			() =>
			{
				this.fireChanges();
			},
			AppConstants.time.oneSecond);
	}
}