/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Injectable
} from '@angular/core';
import {
	IBaseEntity
} from '@api/interfaces/base/base-entity.interface';
import {
	OperationDefinitionParameterApiService
} from '@api/services/operations/operation-definition-parameter.api.service';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationGroupHierarchyApiService
} from '@api/services/operations/operation-group-hierarchy.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	OperationTypeParameterApiService
} from '@api/services/operations/operation-type-parameter.api.service';
import {
	OperationTypeApiService
} from '@api/services/operations/operation-type.api.service';
import {
	IOperationDefinitionParameter
} from '@operation/interfaces/operation-definition-parameter.interface';
import {
	IOperationDefinition
} from '@operation/interfaces/operation-definition.interface';
import {
	IOperationGroupHierarchy
} from '@operation/interfaces/operation-group-hierarchy.interface';
import {
	IOperationGroupRelationship
} from '@operation/interfaces/operation-group-relationship.interface';
import {
	IOperationGroup
} from '@operation/interfaces/operation-group.interface';
import {
	IOperationTypeParameter
} from '@operation/interfaces/operation-type-parameter.interface';
import {
	IOperationType
} from '@operation/interfaces/operation-type.interface';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IStoredVariableDefinition
} from '@shared/interfaces/application-objects/stored-variable-definition';
import {
	BaseStoredVariableService
} from '@shared/services/base/base-stored-variable.service';

/* eslint-enable max-len */

/**
 * A class representing logic for handling common
 * operation based logic.
 *
 * @export
 * @class OperationService
 * @extends {BaseStoredVariableService}
 */
@Injectable({
	providedIn: 'root'
})
export class OperationService
	extends BaseStoredVariableService
{
	/**
	 * Creates an instance of an OperationService.
	 *
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The service used for operation definitions.
	 * @param {OperationDefinitionParameterApiService}
	 * operationDefinitionParameterApiService
	 * The service used for operation definitions.
	 * @param {operationGroupApiService} operationGroupApiService
	 * The service used for operation groups.
	 * @param {OperationGroupHierarchyApiService}
	 * operationGroupHierarchyApiService
	 * The service used for operation group hierarchies.
	 * @param {OperationTypeApiService} operationTypeApiService
	 * The service used for operation types.
	 * @param {OperationTypeParameterApiService}
	 * operationTypeParameterApiService
	 * The service used for operation type parameters.
	 * @param {OperationExecutionService} operationExecutionService
	 * The service used to execute fully populated operation definitions.
	 * @memberof OperationService
	 */
	public constructor(
		private readonly operationDefinitionApiService:
			OperationDefinitionApiService,
		private readonly operationDefinitionParameterApiService:
			OperationDefinitionParameterApiService,
		private readonly operationGroupApiService:
			OperationGroupApiService,
		private readonly operationGroupHierarchyApiService:
			OperationGroupHierarchyApiService,
		private readonly operationTypeApiService:
			OperationTypeApiService,
		private readonly operationTypeParameterApiService:
			OperationTypeParameterApiService)
	{
		super();

		this.storedVariables =
			<IStoredVariableDefinition[]>
			[
				{
					storageProperty:
						AppConstants.apiControllers.operationDefinitions,
					apiService: this.operationDefinitionApiService
				},
				{
					storageProperty:
						AppConstants.apiControllers
							.operationDefinitionParameters,
					apiService: this.operationDefinitionParameterApiService
				},
				{
					storageProperty:
						AppConstants.apiControllers.operationGroups,
					apiService: this.operationGroupApiService
				},
				{
					storageProperty:
						AppConstants.apiControllers.operationGroupHierarchies,
					apiService: this.operationGroupHierarchyApiService
				},
				{
					storageProperty:
						AppConstants.apiControllers.operationTypes,
					apiService: this.operationTypeApiService
				},
				{
					storageProperty:
						AppConstants.apiControllers.operationTypeParameters,
					apiService: this.operationTypeParameterApiService
				}
			];

		this.storedVariables.forEach(
			(storedVariable: IStoredVariableDefinition) =>
			{
				storedVariable.apiService.operationService = this;
			});
	}

	/**
	 * Gets or sets the operation definitions.
	 *
	 * @type {IOperationDefinition[]}
	 * @memberof OperationService
	 */
	public operationDefinitions: IOperationDefinition[] = [];

	/**
	 * Gets or sets the operation definition parameters.
	 *
	 * @type {IOperationDefinitionParameter[]}
	 * @memberof OperationService
	 */
	public operationDefinitionParameters: IOperationDefinitionParameter[] = [];

	/**
	 * Gets or sets the operation groups.
	 *
	 * @type {IOperationGroup[]}
	 * @memberof OperationService
	 */
	public operationGroups: IOperationGroup[] = [];

	/**
	 * Gets or sets the operation group hieararchies.
	 *
	 * @type {IOperationGroupHierarchy[]}
	 * @memberof OperationService
	 */
	public operationGroupHierarchies: IOperationGroupHierarchy[] = [];

	/**
	 * Gets or sets the operation types.
	 *
	 * @type {IOperationType[]}
	 * @memberof OperationService
	 */
	public operationTypes: IOperationType[] = [];

	/**
	 * Gets or sets the operation type parameters.
	 *
	 * @type {IOperationTypeParameter[]}
	 * @memberof OperationService
	 */
	public operationTypeParameters: IOperationTypeParameter[] = [];

	/**
	 * Gets or sets the storage variables that will be stored in this
	 * singleton service.
	 *
	 * @type {IStoredVariableDefinition[]}
	 * @memberof OperationService
	 */
	public storedVariables: IStoredVariableDefinition[] = [];

	/**
	 * Gets a populated operation group with the full child relationship
	 * tree.
	 *
	 * @async
	 * @param {string} name
	 * The name of the operation group to be loaded.
	 * @param {boolean} throwErrorIfNull
	 * A value that signifies whether or not an error should be thrown if the
	 * operation group is not shown.
	 * @returns {Promise<IOperationGroup>}
	 * A populated operation group matching the id with the full child
	 * relationship tree.
	 * @memberof OperationService
	 */
	public async populateOperationGroup(
		name: string,
		throwErrorIfNull: boolean = false): Promise<IOperationGroup>
	{
		await this.setStoredVariables();

		const operationGroups: IOperationGroup[] =
			this.operationGroups.filter(
				(group: IOperationGroup) =>
					group.name === name);

		if (operationGroups == null
			|| operationGroups.length !== 1)
		{
			if (throwErrorIfNull === true)
			{
				throw new Error(
					`The operation group: '${name}' can not be found `
						+ 'in the database. Please ensure this data exists.');
			}

			return null;
		}

		const operationGroup: IOperationGroup = operationGroups[0];

		operationGroup.childRelationships =
			this.populateChildRelationships(
				operationGroups[0].id);

		operationGroup.childRelationships =
			this.cleanGroupsWithoutChildren(
				operationGroup.childRelationships);

		return operationGroup;
	}

	/**
	 * Gets a populated operation definition with the full
	 * data set required to perform an operation.
	 *
	 * @async
	 * @param {IOperationDefinition} operationDefinition
	 * The operation definition to be populated.
	 * @param {IOperationGroupRelationship} operationGroupRelationship
	 * The operation group relationship holding this definition.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The page context to execute this operation against.
	 * @returns {Promise<IOperationDefinition>}
	 * A populated operation definition matching the id with
	 * the full data set required to perform an operation.
	 * @memberof OperationService
	 */
	public async populateOperationDefinition(
		operationDefinition: IOperationDefinition,
		operationGroupRelationship: IOperationGroupRelationship,
		pageContext: IDynamicComponentContext<
			Component, any>): Promise<IOperationDefinition>
	{
		await this.setStoredVariables();

		operationDefinition.operationType =
			this.operationTypes.find(
				(type: IOperationType) =>
					type.id === operationDefinition.typeId);

		const parameters: IOperationTypeParameter[] =
			this.operationTypeParameters.filter(
				(typeParameter: IOperationTypeParameter) =>
					typeParameter.typeId === operationDefinition.typeId);

		operationDefinition.order =
			operationGroupRelationship.order;
		operationDefinition
			.operationTypeParameters = [];

		if (parameters.length > 0)
		{
			operationDefinition.operationTypeParameters = parameters;

			const definitionTypeParameters: number[] =
				operationDefinition.operationTypeParameters.map(
					(item: IBaseEntity) =>
						item.id);

			const operationDefinitionParameters:
				IOperationDefinitionParameter[] =
					this.operationDefinitionParameters.filter(
						(definitionParameter: IOperationDefinitionParameter) =>
							definitionParameter.definitionId ===
								operationDefinition.id
								&& definitionTypeParameters.indexOf(
									definitionParameter
										.typeParameterId) !== -1);

			operationDefinition
				.operationTypeParameters
				.forEach(
					(operationTypeParameter) =>
					{
						const operationParameter =
							operationDefinitionParameters.find(
								(parameterValue) =>
									parameterValue.typeParameterId ===
										operationTypeParameter.id);

						operationTypeParameter.definitionParameterValue =
							operationParameter
								? operationParameter.value
								: null;
					});
		}

		operationDefinition.pageContext = pageContext;

		return operationDefinition;
	}

	/**
	 * Gets a populated operation group with the full child relationship
	 * tree given an existing operation group.
	 *
	 * @param {IOperationGroup} operationGroup
	 * The existing operation group to be loaded.
	 * @returns {IOperationGroup}
	 * A populated operation group matching the id with the full child
	 * relationship tree.
	 * @memberof OperationService
	 */
	private populateChildOperationGroup(
		operationGroup: IOperationGroup): IOperationGroup
	{
		operationGroup.childRelationships =
			this.populateChildRelationships(
				operationGroup.id);

		return operationGroup;
	}

	/**
	 * Gets an operation groups child relationships by id.
	 *
	 * @param {number} id
	 * The id of the operation group to load the children of.
	 * @returns {IOperationGroupRelationship[]}
	 * A populated full child relationship tree of the operation
	 * group matching the id.
	 * @memberof OperationService
	 */
	private populateChildRelationships(
		id: number): IOperationGroupRelationship[]
	{
		const childRelationships: IOperationGroupRelationship[] =
			this.operationGroupHierarchies
				.filter(
					(hierarchy: IOperationGroupHierarchy) =>
						hierarchy.parentGroupId === id
							&& (!AnyHelper.isNull(
								hierarchy.childGroupId)
								|| !AnyHelper.isNull(
									hierarchy.childDefinitionId)))
				.sort((itemOne: IOperationGroupHierarchy,
					itemTwo: IOperationGroupHierarchy) =>
					ObjectHelper.sortByPropertyValue(
						itemOne,
						itemTwo,
						AppConstants.commonProperties.order))
				.map(
					(hierarchy: IOperationGroupHierarchy) =>
						AnyHelper.isNull(hierarchy.childDefinitionId)
							? <IOperationGroupRelationship>
								{
									operationGroup:
										this.operationGroups.find(
											(group: IOperationGroup) =>
												group.id ===
													hierarchy.childGroupId),
									type: AppConstants.operationTypes
										.operationGroup,
									order: hierarchy.order
								}
							: <IOperationGroupRelationship>
								{
									operationDefinition:
										this.operationDefinitions.find(
											(definition:
												IOperationDefinition) =>
												definition.id ===
													hierarchy
														.childDefinitionId),
									type: AppConstants.operationTypes
										.operationDefinition,
									order: hierarchy.order
								});

		this.populateChildGroups(
			this.getRelationshipsByType(
				childRelationships,
				AppConstants.operationTypes.operationGroup));

		this.populateChildDefinitions(
			this.getRelationshipsByType(
				childRelationships,
				AppConstants.operationTypes.operationDefinition));

		return childRelationships;
	}

	/**
	 * Gets a set of child definitions and populates this into
	 * the matching supplied operation child relationships.
	 *
	 * @param {IOperationGroupRelationship[]} childDefinitionRelationships
	 * The set of child relationships of type 'OperationDefinition'
	 * to be populated.
	 * @returns {IOperationGroupRelationship[]}
	 * A populated full set of child definition relationships.
	 * @memberof OperationService
	 */
	private populateChildDefinitions(
		childDefinitionRelationships:
			IOperationGroupRelationship[]): IOperationGroupRelationship[]
	{
		if (childDefinitionRelationships.length === 0)
		{
			return childDefinitionRelationships;
		}

		const childRelationshipIds: number[] =
			childDefinitionRelationships
				.filter(
					(relationship: IOperationGroupRelationship) =>
						!AnyHelper.isNull(
							relationship.operationDefinition?.id))
				.map(
					(relationship: IOperationGroupRelationship) =>
						relationship.operationDefinition.id);
		const operationDefinitions: IOperationDefinition[] =
			this.operationDefinitions.filter(
				(definition: IOperationDefinition) =>
					childRelationshipIds.indexOf(definition.id) !== -1);

		childDefinitionRelationships.forEach(
			(relationship) =>
			{
				relationship.operationDefinition =
					operationDefinitions.find(
						(definition) =>
							definition.id ===
								relationship.operationDefinition?.id);
			});

		return childDefinitionRelationships;
	}

	/**
	 * Gets a set of child groups and populates this into
	 * the matching supplied operation child relationships.
	 *
	 * @param {IOperationGroupRelationship[]} childGroupRelationships
	 * The set of child relationships of type 'OperationGroup'
	 * to be populated.
	 * @returns {IOperationGroupRelationship[]}
	 * A populated full set of child group relationships as well as a
	 * populated full child relationship tree for each child group.
	 * @memberof OperationService
	 */
	private populateChildGroups(
		childGroupRelationships:
			IOperationGroupRelationship[]): IOperationGroupRelationship[]
	{
		if (childGroupRelationships.length === 0)
		{
			return childGroupRelationships;
		}

		const childRelationshipIds: number[] =
			childGroupRelationships.map(
				(relationship: IOperationGroupRelationship) =>
					relationship.operationGroup.id);
		const operationGroups: IOperationGroup[] =
			this.operationGroups.filter(
				(group: IOperationGroup) =>
					childRelationshipIds.indexOf(group.id) !== -1);

		childGroupRelationships.forEach(
			(relationship) =>
			{
				relationship.operationGroup =
					operationGroups.find(
						(group: IOperationGroup) =>
							group.id === relationship.operationGroup.id);

				// Recursively populate each child operation group.
				this.populateChildOperationGroup(
					relationship.operationGroup);
			});

		return childGroupRelationships;
	}

	/**
	 * Gets a set of child groups that have an operation definition
	 * defined at the lowest tree level and removes any groups
	 * without a definition as its lowest leaf node.
	 *
	 * @param {IOperationGroupRelationship[]} childGroupRelationships
	 * The set of child relationships found via a populated
	 * operation group.
	 * @returns {IOperationGroupRelationship[]}
	 * A populated full set of child relationships with the structure
	 * cleaned of empty group into definition paths.
	 * @memberof OperationService
	 */
	private cleanGroupsWithoutChildren(
		childRelationships:
			IOperationGroupRelationship[]): IOperationGroupRelationship[]
	{
		childRelationships.forEach(
			(relationship: IOperationGroupRelationship) =>
			{
				if (relationship.operationGroup != null
					&& relationship.operationGroup
						.childRelationships.length > 0)
				{
					relationship.operationGroup.childRelationships =
							this.cleanGroupsWithoutChildren(
								relationship.operationGroup.childRelationships);
				}
			});

		return childRelationships.filter(
			(relationship: IOperationGroupRelationship) =>
				relationship.operationDefinition != null
					|| (relationship.operationGroup != null
						&& relationship.operationGroup
							.childRelationships.length > 0));
	}

	/**
	 * Gets the set of child relationships that match the supplied
	 * type.
	 *
	 * @param {IOperationGroupRelationship[]} childRelationships
	 * The set of child relationships to be filtered on type.
	 * @returns {IOperationGroupRelationship[]}
	 * A populated full set of child relationships of the matching type.
	 * @memberof OperationService
	 */
	private getRelationshipsByType(
		childRelationships: IOperationGroupRelationship[],
		type: string): IOperationGroupRelationship[]
	{
		return childRelationships.filter(
			(relationship: IOperationGroupRelationship) =>
				relationship.type === type);
	}
}