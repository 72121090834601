/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ChangeDetectorRef,
	Component,
	OnInit
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';

@Component({
	selector: 'custom-icon-input',
	templateUrl: './custom-icon-input.component.html',
	styleUrls: [
		'./custom-icon-input.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Input Switch.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomIconInputComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {OnInit}
 */
export class CustomIconInputComponent
	extends ExtendedCustomControlDirective
	implements OnInit
{
	/** Initializes a new instance of the CustomIconInputComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomIconInputComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets the html icon class name.
	 *
	 * @type {string}
	 * @memberof CustomIconInputComponent
	 */
	public htmlIconClassName: string;

	/**
	 * Gets or sets the input value.
	 *
	 * @type {string}
	 * @memberof CustomIconInputComponent
	 */
	public inputValue: string;

	/**
	 * Gets or sets the data model.
	 *
	 * @type {object}
	 * @memberof CustomIconInputComponent
	 */
	public dataModel: object;

	/**
	 * Gets or sets spinner icon class name.
	 *
	 * @private
	 * @type {string}
	 * @memberof CustomIconInputComponent
	 */
	private readonly spinnerIconClassName: string =
		'fa fa-fw fa-spin fa-spinner';

	/**
	 * Gets or sets spinner icon class name.
	 *
	 * @private
	 * @type {number}
	 * @memberof CustomIconInputComponent
	 */
	private readonly loadingIconDebounceTime: number = 400;

	/**
	 * Handles the on initialization interface.
	 * This will setup the icon class name if any existing.
	 *
	 * @memberof CustomIconInputComponent
	 */
	public ngOnInit(): void
	{
		this.setIconClassName();
		super.ngOnInit();
	}

	/**
	 * Sets up html icon class name string
	 *
	 * @memberof CustomIconInputComponent
	 */
	public setIconClassName(): void
	{
		this.htmlIconClassName = `fa fa-${this.field.formControl.value}`;
		this.fireChanges();
	}

	/**
	 * Handles the data on input change.
	 *
	 * @memberof CustomIconInputComponent
	 */
	public inputChange(): void
	{
		this.htmlIconClassName = this.spinnerIconClassName;
		setTimeout(
			() =>
			{
				this.setIconClassName();
			},
			this.loadingIconDebounceTime);

		this.validateControl();
	}
}