
/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	IValidator
} from '@shared/interfaces/application-objects/validator.interface';

/**
 * An abstract class representing a base presentation rule validator.
 *
 * @export
 * @abstract
 * @class BaseRuleValidator
 */
export abstract class BaseRuleValidator
{
	/**
	 * Creates and returns a unique instance of a validator.
	 *
	 * @returns {IValidator}
	 * An validator ready for use in a Formly layout.
	 * @memberof BaseRuleValidator
	 */
	public abstract create(): IValidator;
}