/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	SecurityAccessPolicyDefinitionApiService
} from '@api/services/security/security-access-policy-definition.api.service';
import {
	SecurityAccessPolicyApiService
} from '@api/services/security/security-access-policy.api.service';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	ISecurityAccessPolicyDefinition
} from '@shared/interfaces/security/security-access-policy-definition.interface';
import {
	ISecurityAccessPolicy
} from '@shared/interfaces/security/security-access-policy.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ResolverService
} from '@shared/services/resolver.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-entity-access-policies',
	templateUrl: './entity-access-policies.component.html'
})

/**
 * A component representing an instance of the system entity access policies
 * component.
 *
 * @export
 * @class EntityAccessPoliciesComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class EntityAccessPoliciesComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Creates an instance of an EntityAccessPoliciesComponent.
	 *
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The api service used to get the entity definition data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The api service used to get entity version data.
	 * @param {SecurityAccessPolicyApiService}
	 * securityAccessPolicyApiService
	 * The api service used to get security access policy data.
	 * @param {SecurityAccessPolicyDefinitionApiService}
	 * securityAccessPolicyDefinitionApiService
	 * The api service used to get security access policy definition data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router service.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for page context functions.
	 * @memberof EntityAccessPoliciesComponent
	 */
	public constructor(
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public securityAccessPolicyApiService: SecurityAccessPolicyApiService,
		public securityAccessPolicyDefinitionApiService:
			SecurityAccessPolicyDefinitionApiService,
		public route: ActivatedRoute,
		public router: Router,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntityAccessPoliciesComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {IEntityType}
	 * @memberof EntityAccessPoliciesComponent
	 */
	public entityType: IEntityType;

	/**
	 * Gets or sets the entity version.
	 *
	 * @type {IEntityVersion}
	 * @memberof EntityAccessPoliciesComponent
	 */
	public entityVersion: IEntityVersion;

	/**
	 * Gets or sets the table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityAccessPoliciesComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the entity definition id.
	 *
	 * @type {number}
	 * @memberof EntityAccessPoliciesComponent
	 */
	public entityDefinitionId: number;

	/**
	 * Initializes the component to set the page variables
	 * and setup the table definitions.
	 *
	 * @async
	 * @memberof EntityAccessPoliciesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		await this.setupTableDefinitions();
	}

	/**
	 * Sets the page variables needed for this component.
	 *
	 * @async
	 * @memberof EntityAccessPoliciesComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.entityVersion =
			await this.entityVersionApiService
				.get(this.entityDefinition.versionId);

		this.tableFilterQuery = 'EntityTypeId'
			+ ` eq ${this.entityDefinition.typeId}`;

		this.availableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: 1
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets the common table definitions needed for this component.
	 *
	 * @async
	 * @memberof EntityAccessPoliciesComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.tableDefinitions =
			{
				tableTitle: 'Access Policies',
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						'Access Policy'),
				hideTableTitle: true,
				objectSearch: {
					filter: this.tableFilterQuery,
					orderBy: `Name ${AppConstants.sortDirections.ascending}`,
					offset: 0,
					limit: AppConstants.dataLimits.large,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount
				},
				apiPromise:
					async (objectSearch: IObjectSearch) =>
						this.securityAccessPolicyApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				actions: {
					create: {
						layout: [
							{
								key: 'name',
								type: FormlyConstants.customControls.input,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								props: {
									label: 'Access Policy Name',
									disabled: false,
									required: true
								},
								asyncValidators: {
									uniqueName: {
										expression: (
											control: UntypedFormControl) =>
											this.uniqueName(control),
										message: 'Existing Access Policy Name.'
									}
								}
							}
						],
						items: [
							{
								label: 'Create',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command: async() => this.createAction()

							}]
					},
					update: {
						disabledExpandItem: true,
						items: [
							{
								command: () =>
								{
									const updateRouteData: string =
										ObjectHelper.mapRouteData(
											{
												accessPolicyId:
													this.commonTableContext
														.source.selectedItem.id
											});

									this.router.navigate(
										[
											'admin/entity/'
												+ 'accessPolicyDefinition/edit',
											this.entityDefinitionId
										],
										{
											queryParams:
												{
													routeData:
														updateRouteData
												}
										});
								}
							}
						]
					},
					delete: {
						items: [
							{
								label: 'Confirm',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: async() => this.deleteAction()
							}
						],
						deleteStatement: () => this.getDeleteStatement()
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * creates a new entity layout.
	 *
	 * @async
	 * @memberof EntityAccessPoliciesComponent
	 */
	private async createAction(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const createAccessPolicy: Function =
			async() =>
			{
				const newAccessPolicy: ISecurityAccessPolicy =
					<ISecurityAccessPolicy>
					{
						entityTypeId: this.entityType.id,
						name: selectedItem.name
					};

				const createdAccessPolicyId: number =
						await this.securityAccessPolicyApiService
							.create(newAccessPolicy);

				const newAccessPolicyDefinition:
					ISecurityAccessPolicyDefinition =
						<ISecurityAccessPolicyDefinition>
						{
							accessPolicyId: createdAccessPolicyId,
							entityVersionId: this.entityVersion.id,
							jsonData: '{}'
						};

				await this.securityAccessPolicyDefinitionApiService
					.create(newAccessPolicyDefinition);

				this.router.navigate(
					[
						'admin/entity/accessPolicyDefinition/edit',
						this.entityDefinitionId
					],
					{
						queryParams:
						{
							routeData:
								ObjectHelper.mapRouteData(
									{
										accessPolicyId:
											createdAccessPolicyId
									})
						}
					});
			};

		await this.activityService.handleActivity(
			new Activity(
				createAccessPolicy(),
				'<strong>Creating Access Policy</strong>',
				'<strong>Created Access Policy</strong>',
				`Access Policy ${selectedItem.name}`
					+ ` was created to Entity ${this.entityType.name}.`,
				`Access Policy ${selectedItem.name}`
					+ ` was not created to Entity ${this.entityType.name}.`));
	}

	/**
	 * Gets the common table delete statement.
	 *
	 * @async
	 * @returns {string}
	 * The delete statement
	 * @memberof EntityLayoutsComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const existingAccessPolicyDefinitions:
			ISecurityAccessPolicyDefinition[] =
			await this.securityAccessPolicyDefinitionApiService
				.query(
					`AccessPolicyId eq ${selectedItem.id}`
						+ ` and EntityVersionId eq ${this.entityVersion.id} `,
					AppConstants.empty);

		const instanceAccessPoliciesFilter: string =
			'SecurityGroupEntityInstanceAccessPolicies.Any('
				+ `AccessPolicyId eq ${selectedItem.id})`;
		const securityGroupEntityInstanceAccessPolicies =
			await this.securityAccessPolicyApiService
				.query(
					instanceAccessPoliciesFilter,
					AppConstants.empty);

		if (existingAccessPolicyDefinitions.length > 1
			|| securityGroupEntityInstanceAccessPolicies.length > 0)
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[0].disabled = true;

			return 'Unable to delete Security Access Policy '
				+ 'due to existence of multiple Access Policy Definitions '
				+ 'or an existing dependency.';
		}
		else
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[0].disabled = false;

			return `Confirm you are about to delete Security Access Policy
				${selectedItem.id}
				${selectedItem.name}.`;
		}
	}

	/**
	 * Deletes an existing entity access policy.
	 *
	 * @async
	 * @memberof EntityAccessPoliciesComponent
	 */
	private async deleteAction(): Promise<void>
	{
		const selectedItem: any =
			this.commonTableContext.source.selectedItem;

		const deleteAccessPolicy: Function =
			async() =>
			{
				const securityAccessPolicyFilter: string =
					`AccessPolicyId eq ${selectedItem.id}`
						+ ` and EntityVersionId eq ${this.entityVersion.id}`;
				const accessDefinition: ISecurityAccessPolicyDefinition[] =
					await this.securityAccessPolicyDefinitionApiService
						.query(
							securityAccessPolicyFilter,
							AppConstants.empty);

				if (accessDefinition.length > 0)
				{
					await this.securityAccessPolicyDefinitionApiService
						.delete(accessDefinition[0].id);
				}

				await this.securityAccessPolicyApiService
					.delete(selectedItem.id);

				this.commonTableContext.source.deleteSelectedItem();
			};

		await this.activityService.handleActivity(
			new Activity(
				deleteAccessPolicy(),
				'<strong>Deleting Access Policy</strong>',
				'<strong>Deleted Access Policy</strong>',
				`Access Policy ${selectedItem.name}`
					+ ` was deleted from Entity ${this.entityType.name}.`,
				`Access Policy ${selectedItem.name}`
					+ ` was not deleted from Entity ${this.entityType.name}.`));
	}

	/**
	 * Validates the name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Form Control used to get the input value
	 * @returns {Promise<boolean>}
	 * The Validator promise result.
	 * @memberof EntityAccessPoliciesComponent
	 */
	private async uniqueName(control: UntypedFormControl): Promise<boolean>
	{
		const existingActions: ISecurityAccessPolicy[] =
			await this.securityAccessPolicyApiService
				.query(
					'entityTypeId'
						+ ` eq ${this.entityDefinition.typeId}`
						+ ' and name'
						+ ` eq '${control.value}'`,
					AppConstants.empty);

		return Promise.resolve(
			existingActions.length === 0);
	}
}