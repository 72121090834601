/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	TransactionStatusEffectiveDateDirective
} from '@insurance/directives/transaction-status-effective-date.directive';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

@Component({
	selector: 'transaction-endorse-status-effective-date',
	templateUrl: './transaction-endorse-status-effective-date.component.html',
	styleUrls: [
		'./transaction-endorse-status-effective-date.component.scss'
	]
})

/**
 * A component representing a wizard step for setting the effective date
 * of a policy transaction status change.
 *
 * @export
 * @class TransactionEndorseStatusEffectiveDateComponent
 * @extends {TransactionStatusEffectiveDateDirective}
 */
export class TransactionEndorseStatusEffectiveDateComponent
	extends TransactionStatusEffectiveDateDirective
{
	/**
	 * Gets or sets the message that will be set if the valid wizard step check
	 * returns false.
	 *
	 * @type {string}
	 * @memberof TransactionEndorseStatusEffectiveDateComponent
	 */
	public invalidWizardStepMessage: string =
		'Endorse is not allowed on a non-active policy term, '
			+ 'when the last transaction is an issued cancellation, or '
			+ 'when pending transactions exist in this policy term.';

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof TransactionEndorseStatusEffectiveDateComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.context.source.addToNext(
			this.matchEffectiveDateToTransaction.bind(this));

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * An awaitable promise that returns a value signifying whether or not
	 * the wizard step is valid for display.
	 * @memberof TransactionEndorseStatusEffectiveDateComponent
	 */
	public async isWizardStepValidForDisplay(): Promise<boolean>
	{
		const policy: IEntityInstance =
			await this.insuranceService.getPolicyByPolicyTermId(
				this.policyTerm.id);

		if (AnyHelper.isNull(policy))
		{
			return false;
		}

		const latestTransaction: IEntityInstance =
			await this.insuranceService
				.getLatestPolicyTermTransactionByPolicyTerm(
					this.policyTerm.id);

		const termStatusNotAllowed: boolean =
			this.policyTerm.data.status !==
				InsuranceConstants.policyTermStatusTypes.active
				&& (this.policyTerm.data.status !==
					InsuranceConstants.policyTermStatusTypes.pending
					|| (this.policyTerm.data.status ===
						InsuranceConstants.policyTermStatusTypes.pending
							&& this.policyTerm.data.renewalTerm === 0));
		const result: boolean =
			!(termStatusNotAllowed
				|| latestTransaction.data.type ===
					InsuranceConstants.transactionTypes.cancellation
				|| (latestTransaction.data.status !==
					InsuranceConstants.transactionStatusTypes.issued
					&& latestTransaction.data.status !==
						InsuranceConstants.transactionStatusTypes.declined));

		this.clientMessage =
			result === true
				? AppConstants.empty
				: this.invalidWizardStepMessage;

		return result;
	}
}