/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	emailValidator,
	emailValidatorMessage
} from '@entity/entity-formly-definitions';
import {
	IValidator
} from '@shared/interfaces/application-objects/validator.interface';
import {
	BaseRuleValidator
} from '@shared/validators/base-rule-validator.validator';

/**
 * A class representing an email validator.
 *
 * @export
 * @class EmailValidator
 * @extends {BaseRuleValidator}
 */
export class EmailValidator
	extends BaseRuleValidator
{
	/**
	 * Creates and returns a unique instance of an email validator.
	 *
	 * @returns {IValidator}
	 * A validator ready for use in a Formly layout.
	 * @memberof EmailValidator
	 */
	public create(): IValidator
	{
		return <IValidator>
			{
				expression: emailValidator,
				message: emailValidatorMessage
			};
	}
}