/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Directive,
	OnInit
} from '@angular/core';
import {
	CommonDashboardComponent
} from '@shared/components/common-dashboard/common-dashboard.component';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';

/* eslint-enable max-len */

@Directive({
	selector: '[AssociatedTransaction]'
})

/**
 * A component representing a shared display associated transaction
 * components
 *
 * @export
 * @class AssociatedTransactionDirective
 * @implements IDynamicComponent<Component, {
		entityInstance: IEntityInstance;
		entityListContext: IAssociatedEntityListContext;
		associationDefinition: any;
	}>, OnInit
 */
export class AssociatedTransactionDirective
implements IDynamicComponent<Component, {
		entityInstance: IEntityInstance;
		entityListContext: IAssociatedEntityListContext;
		associationDefinition: any;
	}>, OnInit
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IEntityInstance>}
	 * @memberof AssociatedTransactionDirective
	 */
	public context: IDynamicComponentContext<Component, {
			entityInstance: IEntityInstance;
			entityListContext: IAssociatedEntityListContext;
			associationDefinition: any;
		}>;

	/**
	 * Gets or sets the data to iterate within this component.
	 *
	 * @type {any[]}
	 * @memberof AssociatedTransactionDirective
	 */
	public data: any[];

	/**
	 * Initiates the component to get the data object.
	 *
	 * @memberof AssociatedTransactionDirective
	 */
	public ngOnInit(): void
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets the data object used in this component.
	 *
	 * @param {string} dataProperty
	 * The property name to get the data from.
	 * @memberof AssociatedTransactionDirective
	 */
	public setData(dataProperty: string): void
	{
		if (AnyHelper.isNullOrEmpty(
			this.context.data.entityInstance.data[dataProperty]))
		{
			return;
		}

		this.data = this.context.data.entityInstance.data[dataProperty];
		this.setDefinitionData();

		if (!AnyHelper.isNull(
			(<CommonDashboardComponent>this.context.source).finishedLoading))
		{
			(<CommonDashboardComponent>this.context.source)
				.finishedLoading.emit(
					true);
		}
	}

	/**
	 * Gets the formatted type title.
	 *
	 * @param {string} value
	 * The root value to format.
	 * @returns {string}
	 * The display title.
	 * @memberof AssociatedTransactionDirective
	 */
	public getDisplaySubTitle(value: string): string
	{
		return StringHelper.beforeCapitalSpaces(
			StringHelper.toProperCase(value));
	}

	/**
	 * Sets the definition data.
	 *
	 * @memberof AssociatedTransactionDirective
	 */
	public setDefinitionData(): void
	{
		if(AnyHelper.isNullOrEmpty(this.data))
		{
			return;
		}

		for(const item of this.data)
		{
			this.setIconClass(item);

			if(!AnyHelper.isNullOrEmptyArray(
				this.context.data.associationDefinition))
			{
				this.context.data.associationDefinition.forEach(
					(definition: any) =>
					{
						this.setDisplayProperties(
							item,
							definition);
					});
			}
		}
	}

	/**
	 * Sets the icon class.
	 *
	 * @param {any} _property
	 * The mapping property.
	 *
	 * @memberof AssociatedTransactionDirective
	 */
	public setIconClass(_property: any): void
	{
		// This must be overrode in the implementing class if unique
		// values are desired
	}

	/**
	 * Sets the display properties
	 *
	 * @param {any} item
	 * The object item.
	 * @param {any} definition
	 * The definition object.
	 * @memberof AssociatedTransactionDirective
	 */
	private setDisplayProperties(
		item: any,
		definition: any): void
	{
		if (item.type.toLowerCase()
			=== definition.type.toLowerCase())
		{
			const displayProperties: any[] = [];
			item.iconClass = definition.iconClass;

			definition.displayProperties.forEach(
				(property: any) =>
				{
					if(!AnyHelper.isNullOrEmpty(
						item.characteristics[
							StringHelper.toCamelCase(property)]))
					{
						displayProperties.push(
							{
								label: StringHelper.toProperCase(property),
								value: item.characteristics[property]
							});
					}
				});

			item.displayProperties = displayProperties;
		}
	}
}