/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Injectable
} from '@angular/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IWeatherForecast
} from '@shared/interfaces/weather/weather-forecast.interface';
import {
	DateTime
} from 'luxon';
import {
	AppConfig
} from 'src/app/app.config';

/* eslint-enable max-len */

/**
 * A singleton class representing the weather forecast api service.
 *
 * @export
 * @class PowerBiApiService
 */
@Injectable({
	providedIn: 'root'
})
export class WeatherForecastService
{
	/**
	 * Sets the one call api resource.
	 *
	 * @type {string}
	 * @memberof WeatherForecastService
	 */
	private readonly oneCallApiResource: string = 'data/2.5/onecall?';

	 /**
	 * Sets the reverseGeocoding api resource.
	 *
	 * @type {string}
	 * @memberof WeatherForecastService
	 */
	private readonly reverseGeocodingApiResource: string = 'geo/1.0/reverse?';

	 /**
	 * Sets the base open weather map url api resource.
	 *
	 * @type {string}
	 * @memberof WeatherForecastService
	 */
	private readonly baseOpenWeatherApiUrl: string =
		'https://api.openweathermap.org/';

	 /**
	 * Gets or sets coordinates substring.
	 *
	 * @type {string}
	 * @memberof WeatherForecastService
	 */
	private coordinatesSubstring: string;

	/**
	 * Gets or sets one call open weather data.
	 *
	 * @type {any}
	 * @memberof WeatherForecastService
	 */
	private oneCallOpenWeatherData: any;

	/**
	 * Gets or sets the access token expiry time.
	 *
	 * @type {DateTime}
	 * @memberof WeatherForecastService
	 */
	private localDataExpiry: DateTime;

	/**
	 * Gets or sets the geographic location local storage.
	 *
	 * @type {any}
	 * @memberof WeatherForecastService
	 */
	private geographicLocation: any;

	/**
	 * Gets or sets the browser name.
	 *
	 * @type {string}
	 * @memberof WeatherForecastService
	 */
	private browserName: string;

	/**
	 * Gets or sets the custom location permission state.
	 *
	 * @type {string}
	 * @memberof WeatherForecastService
	 */
	private cachedLocationPermissionState: string;

	/**
	 * Sets the miles per hour unit string.
	 *
	 * @type {string}
	 * @memberof WeatherForecastService
	 */
	private readonly milesPerHour: string = 'm/h';

	/**
	 * Sets the base open weather image url api resource.
	 *
	 * @type {string}
	 * @memberof WeatherForecastService
	 */
	private readonly baseOpenWeatherImageUrl: string =
		'https://openweathermap.org/img/wn/';

	/**
	 * Gets the number of minutes prior to the application
	 * access token expiring to call out for new data.
	 *
	 * @type {string}
	 * @memberof WeatherForecastService
	 */
	private readonly minutesBeforeRefresh: number =
		AppConstants.time.thirtyMinutes;

	/**
	 * Gets a value representing the current expired value
	 * of this services access token.
	 *
	 * @type {boolean}
	 * @memberof WeatherForecastService
	*/
	private get expiredData(): boolean
	{
		return this.localDataExpiry <= DateTime.local();
	}

	/**
	 * Gets the weather forecast mapped data object.
	 *
	 * @async
	 * @param {string} state
	 * The state status.
	 * @returns {Promise<any>}
	 * The geolocation permissions.
	 * @memberof WeatherForecastService
	 */
	public async getGeolocationPermissions(state: string): Promise<any>
	{
		this.browserName = this.getBrowserName();
		this.cachedLocationPermissionState =
			AnyHelper.isNullOrEmpty(this.cachedLocationPermissionState)
			|| this.cachedLocationPermissionState
				=== AppConstants.navigatorPermissionStates.unknown
				? state
				: this.cachedLocationPermissionState;

		return this.browserName === AppConstants.navigatorBrowserNames.firefox
			|| AnyHelper.isNull(navigator.permissions)
			? this.cachedLocationPermissionState
			: this.getlocationPermissionsState();
	}

	/**
	 * Gets the location permission state.
	 *
	 * @async
	 * @returns {Promise<any>}
	 * The location permission state.
	 * @memberof WeatherForecastService
	*/
	public async getlocationPermissionsState(): Promise<any>
	{
		const geolocationPermissions =
			await this.queryGeolocationPermissions();

		return geolocationPermissions.state;
	}

	/**
	 * Queries the location permissions.
	 *
	 * @async
	 * @returns {Promise<any>}
	 * The location permission response.
	 * @memberof WeatherForecastService
	*/
	public async queryGeolocationPermissions(): Promise<any>
	{
		return new Promise(
			(resolve) =>
			{
				navigator.permissions.query(
					{
						name: AppConstants.navigatorPermissionTypes.geolocation
					})
					.then(
						(permissions: any) =>
						{
							resolve(permissions);
						});
			});
	}

	/**
	 * Gets the current browser name.
	 *
	 * @returns {string}
	 * The browser name from the current user agent.
	 * @memberof WeatherForecastService
	*/
	public getBrowserName(): string
	{
		const browserUserAgent = navigator.userAgent;
		const splitBrowserHeader =
			browserUserAgent.split(AppConstants.characters.space);
		const browserIdentifier =
			splitBrowserHeader[splitBrowserHeader.length - 1];

		if (browserIdentifier.includes(AppConstants.navigatorBrowserNames.edge))
		{
			return AppConstants.navigatorBrowserNames.edge;
		}
		else if (browserIdentifier
			.includes(AppConstants.navigatorBrowserNames.firefox))
		{
			return AppConstants.navigatorBrowserNames.firefox;
		}
		else if (browserIdentifier
			.includes(AppConstants.navigatorBrowserNames.safari))
		{
			return (browserUserAgent
				.indexOf(AppConstants.navigatorBrowserNames.chrome) > 0)
				? AppConstants.navigatorBrowserNames.chrome
				: AppConstants.navigatorBrowserNames.safari;
		}

		return AppConstants.navigatorBrowserNames.other;
	}

	/**
	 * Clears any stored variables.
	 * This will allow to get new data
	 * on the next data call.
	 *
	 * @memberof WeatherForecastService
	 */
	public clearStoredVariables(): void
	{
		this.oneCallOpenWeatherData = null;
		this.geographicLocation = null;
	}

	/**
	 * Gets the current weather forecast.
	 *
	 * @async
	 * @returns {Promise<IWeatherForecast>}
	 * The view model object containing
	 * the data for the current weather forecast.
	 *
	 * @memberof WeatherForecastService
	 */
	public async getCurrentForecast(): Promise<IWeatherForecast>
	{
		this.oneCallOpenWeatherData =
			await this.getLocalData(
				this.oneCallOpenWeatherData,
				this.getOneCallOpenWeatherResponse.bind(this));
		const currentData: any = this.oneCallOpenWeatherData.current;

		return this.getWeatherForecastObject(currentData);
	}

	 /**
	 * Gets the hourly weather forecast.
	 *
	 * @async
	 * @returns {Promise<IWeatherForecast[]>}
	 * The view model object array containing
	 * the data for the current and following
	 * 48 hour weather forecast.
	 *
	 * @memberof WeatherForecastService
	 */
	public async getHourlyForecast(): Promise<IWeatherForecast[]>
	{
		this.oneCallOpenWeatherData =
			await this.getLocalData(
				this.oneCallOpenWeatherData,
				this.getOneCallOpenWeatherResponse.bind(this));
		const hourlyOneCallForecastData: any =
			this.oneCallOpenWeatherData.hourly;
		const hourlyForecast: IWeatherForecast[] = [];

		for (const hourlyData of hourlyOneCallForecastData)
		{
			hourlyForecast.push(this.getWeatherForecastObject(hourlyData));
		}

		return hourlyForecast;
	}

	 /**
	 * Gets the daily weather forecast.
	 *
	 * @async
	 * @returns {Promise<IWeatherForecast[]>}
	 * The view model object array containing
	 * the data for the current and following
	 * 7 day weather forecast.
	 *
	 * @memberof WeatherForecastService
	 */
	public async getDailyForecast(): Promise<IWeatherForecast[]>
	{
		this.oneCallOpenWeatherData =
			await this.getLocalData(
				this.oneCallOpenWeatherData,
				this.getOneCallOpenWeatherResponse.bind(this));
		const dailyOneCallForecastData: any =
			this.oneCallOpenWeatherData.daily;
		const dailyForecast: IWeatherForecast[] = [];
		for (const dailyData of dailyOneCallForecastData)
		{
			dailyForecast.push(this.getWeatherForecastObject(dailyData));
		}

		return dailyForecast;
	}

	 /**
	 * Gets the geographic location.
	 *
	 * @async
	 * @returns {Promise<any>}
	 * Geographic location data.
	 * @memberof WeatherForecastService
	 */
	public async getGeographicLocation(): Promise<any>
	{
		this.geographicLocation =
			await this.getLocalData(
				this.geographicLocation,
				this.getGeographicLocationResponse.bind(this));

		return this.geographicLocation;
	}

	 /**
	 * Gets the open weather icon source.
	 *
	 * @param {string} iconId
	 * The icon identifier.
	 * @param {string} size
	 * The size of the icon.
	 * The options are small, medium and large.
	 * @note Default is small.
	 * @returns {string}
	 * The icon image source url.
	 * @memberof WeatherForecastService
	 */
	public getWeatherIconSource(
		iconId: string,
		size: string = AppConstants.sizeIdentifiers.small): string
	{
		let iconSize = AppConstants.empty;
		if (size === AppConstants.sizeIdentifiers.large)
		{
			iconSize = '@4x';
		}
		else if (size === AppConstants.sizeIdentifiers.medium)
		{
			iconSize = '@2x';
		}

		return `${this.baseOpenWeatherImageUrl}`
			+ `${iconId}`
			+ `${iconSize}.png`;
	}

	 /**
	 * Gets the coordinates substring, latitude and longitude
	 * from the browser navigation geolocation current position.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * The coordinates substring formatted as needed
	 * for the weather api service calls.
	 * @memberof WeatherForecastService
	 */
	private async getCoordinatesSubstring(): Promise<string>
	{
		const coordinates: any =
			await this.getGeolocationPositionCoordinates();

		return `lat=${coordinates.latitude}`
			+ `&lon=${coordinates.longitude}`;
	}

	 /**
	 * An awaitable promise to obtain the geolocation
	 * position coordinates.
	 *
	 * @returns {Promise<any>}
	 * The latitude and longitude values in an object literal.
	 * @memberof WeatherForecastService
	 */
	private getGeolocationPositionCoordinates(): Promise<any>
	{
		return new Promise(
			(resolve, reject) =>
			{
				const getCoordinatesSuccess =
					(position: GeolocationPosition) =>
					{
						resolve({
							latitude: position.coords.latitude,
							longitude: position.coords.longitude
						});
					};

				const getCoordinatesError =
					(exception: GeolocationPositionError) =>
					{
						const exceptionMessage: string =
							`${exception.message}.`;

						EventHelper.dispatchBannerEvent(
							'Location unavailable.',
							exceptionMessage,
							AppConstants.activityStatus.info,
							exception.code);

						reject();
					};

				navigator.geolocation.getCurrentPosition(
					getCoordinatesSuccess,
					getCoordinatesError,
					{
						enableHighAccuracy: true
					});
			});
	}

	 /**
	 * Gets the reverse geocoding data response.
	 *
	 * @async
	 * @returns {Promise<Response>}
	 * The geographic location response containing the current
	 * location information by city, and country based on the
	 * geolocation coordinates.
	 * https://openweathermap.org/api/geocoding-api#reverse
	 * @memberof WeatherForecastService
	 */
	private async getGeographicLocationResponse(): Promise<Response>
	{
		this.coordinatesSubstring =
			AnyHelper.isNullOrEmpty(this.coordinatesSubstring)
				? await this.getCoordinatesSubstring()
				: this.coordinatesSubstring;
		const apiUrl: string =
			`${this.baseOpenWeatherApiUrl}`
				+ `${this.reverseGeocodingApiResource}`
				+ `${this.coordinatesSubstring}`
				+ '&limit=1'
				+ `&appid=${AppConfig.settings.openWeatherApiKey}`;

		const geocodingData: Response = await fetch(apiUrl);

		return geocodingData.json();
	}

	/**
	 * Gets the weather forecast mapped data object.
	 *
	 * @param {any} cachedData
	 * The local cached data.
	 * @param {() => Promise<Response>} getFreshData
	 * Gets new data for the local cache.
	 * @returns {Promise<Response>}
	 * The local data either fresh or cached.
	 * If data is null or the time has expired then
	 * it will get fresh data, otherwise will use cached.
	 * @memberof WeatherForecastService
	*/
	private async getLocalData(
		cachedData: any,
		getFreshData: () => Promise<Response>): Promise<Response>
	{
		if (AnyHelper.isNull(cachedData)
			|| this.expiredData === true)
		{
			this.localDataExpiry =
				DateHelper.addTimeUnit(
					DateTime.local(),
					this.minutesBeforeRefresh,
					DateHelper.timeUnits.minute);

			return Promise.resolve(getFreshData());
		}
		else
		{
			return cachedData;
		}
	}

	 /**
	 * Gets the weather forecast mapped data object.
	 *
	 * @param {any} forecastData
	 * The base forecast data used to be mapped and converted into
	 * a weather forecast view model.
	 * @returns {IWeatherForecast}
	 * The weather forecast view model object.
	 * @memberof WeatherForecastService
	 */
	private getWeatherForecastObject(forecastData: any): IWeatherForecast
	{
		return <IWeatherForecast>
			{
				timezone: this.oneCallOpenWeatherData.timezone,
				currentUtcDateTime:
					DateHelper.convertUnixToUTCDateTime(
						forecastData.dt)
						?.toISO(),
				sunriseDateTime:
					DateHelper.convertUnixToUTCDateTime(
						forecastData.sunrise)
						?.toISO(),
				sunsetDateTime:
					DateHelper.convertUnixToUTCDateTime(
						forecastData.sunset)
						?.toISO(),
				temperature: forecastData.temp,
				humanPerceptionTemperature: forecastData.feels_like,
				atmosphericTemperature: forecastData.dew_point,
				pressure: `${forecastData.pressure}hPa`,
				humidity: `${forecastData.humidity}%`,
				cloudiness: `${forecastData.clouds}%`,
				uvIndex: forecastData.uvi,
				visibility: forecastData.visibility,
				windSpeed: `${forecastData.wind_speed + this.milesPerHour}`,
				windDireccion: forecastData.wind_deg,
				weatherId: forecastData.weather[0].id,
				weatherName: forecastData.weather[0].main,
				weatherDescription: forecastData.weather[0].description,
				weatherIcon: forecastData.weather[0].icon
			};
	}

	 /**
	 * Gets the one call open weather response.
	 *
	 * @async
	 * @returns {Promise<Response>}
	 * The one call response containing the current,
	 * minutely, hourly and daily weather forecast data.
	 * https://openweathermap.org/api/one-call-api
	 * @memberof WeatherForecastService
	 */
	private async getOneCallOpenWeatherResponse(): Promise<Response>
	{
		this.coordinatesSubstring =
			AnyHelper.isNullOrEmpty(this.coordinatesSubstring)
				? await this.getCoordinatesSubstring()
				: this.coordinatesSubstring;

		const apiUrl: string =
			`${this.baseOpenWeatherApiUrl}`
				+ `${this.oneCallApiResource}`
				+ `${this.coordinatesSubstring}`
				+ `&units=${AppConstants.unitsOfMeasurement.imperial}`
				+ `&appid=${AppConfig.settings.openWeatherApiKey}`;
		const weatherData: Response = await fetch(apiUrl);

		return weatherData.json();
	}
}