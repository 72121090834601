
<ng-template
	[ngIf]="loading === true"
	[ngIfElse]="Content">
	<div class="flex-center">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>
</ng-template>

<ng-template #Content>
	<div class="summary-card-container"
		*ngIf="entityInstanceId === null">
		<div class="ui-g-12 summary-card text-center">
			<div class="summary-card-main-description summary-font-size ellipsis-text">
				No Data
			</div>
		</div>
	</div>
	<div class="summary-card-container"
		*ngIf="entityInstanceId != null">
		<div class="ui-g-12 summary-card text-center">
			<div class="summary-card-main-description summary-font-size ellipsis-text">
				<span>
					Outstanding Reserves
				</span>
			</div>
			<div class="section-container">
				<div class="ui-g-12 no-padding">
					<div class="ui-g-2 panel-sub-title">
					</div>
					<div class="ui-g-4 panel-sub-title ellipsis-text">
						Loss:
					</div>
					<div class="ui-g-4 panel-sub-title ellipsis-text">
						{{loss | currency}}
					</div>
					<div class="ui-g-2 panel-sub-title">
					</div>
				</div>
				<div class="ui-g-12 no-padding">
					<div class="ui-g-2 panel-sub-title">
					</div>
					<div class="ui-g-4 panel-sub-title ellipsis-text">
						AO:
					</div>
					<div class="ui-g-4 panel-sub-title ellipsis-text">
						{{adjustingAndOther | currency}}
					</div>
					<div class="ui-g-2 panel-sub-title">
					</div>
				</div>
				<div class="ui-g-12 no-padding">
					<div class="ui-g-2 panel-sub-title">
					</div>
					<div class="ui-g-4 panel-sub-title ellipsis-text">
						DCC:
					</div>
					<div class="ui-g-4 panel-sub-title ellipsis-text">
						{{defenseAndCostContainment | currency}}
					</div>
					<div class="ui-g-2 panel-sub-title">
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>