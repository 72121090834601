<div class="overlay-drawer"
	*ngIf="overlayDrawerItem != null"
	[@drawerAnimation]="overlayDrawerItem == null"
	[ngClass]="{
		'drawer-one-open-overlay': (pinnedDrawerItems.length === 1),
		'drawer-two-open-overlay': (pinnedDrawerItems.length === 2),
		'drawer-three-open-overlay': (pinnedDrawerItems.length === 3),
		'drawer-four-open-overlay': (pinnedDrawerItems.length === 4),
		'drawer-five-open-overlay': (pinnedDrawerItems.length === 5)
	}">
	<app-drawer
		[pageContext]="pageContext"
		[drawerMenuItem]="overlayDrawerItem"
		(drawerCloseButtonClicked)="closeDrawerClick($event)"
		(drawerPinButtonClicked)="pinDrawerClick($event)">
	</app-drawer>
</div>

<div class="drawers"
	[ngClass]="{
		'drawer-two-open': (pinnedDrawerItems.length === 2),
		'drawer-three-open': (pinnedDrawerItems.length === 3),
		'drawer-four-open': (pinnedDrawerItems.length === 4),
		'drawer-five-open': (pinnedDrawerItems.length === 5)
	}">

	<ng-template ngFor let-drawerMenuItem
		[ngForOf]="pinnedDrawerItems">

		<div class="div"
			[@listDrawerAnimation]="state">
			<app-drawer
				[pageContext]="pageContext"
				[drawerMenuItem]="drawerMenuItem"
				(drawerCloseButtonClicked)="closeDrawerClick($event)"
				(drawerPinButtonClicked)="pinDrawerClick($event)">
			</app-drawer>
		</div>

	</ng-template>
</div>