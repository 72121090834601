<div class="list-item-container ui-g ui-g-12">
	<div class="data-column">
		<span class="data-value ellipsis-text">
			<label class="data-label">
				Name:
			</label>
			<span class="theme-color">
				{{context.data?.entityInstance.data.name}}
			</span>
		</span>
		<br/>
		<span class="data-value ellipsis-text">
			<label class="data-label">
				Description:
			</label>
			{{context.data?.entityInstance.data.description}}
		</span>
	</div>
</div>